// data extracted from
// http://api.countrylayer.com/v2/all?fields=name;alpha2Code&access_key=4d2fe13e6703c7ad005c874e55db9714
// username: thanhpk@live.com, password: gJ%KiG7!w76Me
// a.map(i => { delete i.alpha3Code})

module.exports = [
	{name: 'Afghanistan', alpha2Code: 'AF', callingCodes: ['93'], region: 'Asia'},
	{name: 'Åland Islands', alpha2Code: 'AX', callingCodes: ['358'], region: 'Europe'},
	{name: 'Albania', alpha2Code: 'AL', callingCodes: ['355'], region: 'Europe'},
	{name: 'Algeria', alpha2Code: 'DZ', callingCodes: ['213'], region: 'Africa'},
	{name: 'American Samoa', alpha2Code: 'AS', callingCodes: ['1'], region: 'Oceania'},
	{name: 'Andorra', alpha2Code: 'AD', callingCodes: ['376'], region: 'Europe'},
	{name: 'Angola', alpha2Code: 'AO', callingCodes: ['244'], region: 'Africa'},
	{name: 'Anguilla', alpha2Code: 'AI', callingCodes: ['1'], region: 'Americas'},
	{name: 'Antarctica', alpha2Code: 'AQ', callingCodes: ['672'], region: 'Polar'},
	{name: 'Antigua and Barbuda', alpha2Code: 'AG', callingCodes: ['1'], region: 'Americas'},
	{name: 'Argentina', alpha2Code: 'AR', callingCodes: ['54'], region: 'Americas'},
	{name: 'Armenia', alpha2Code: 'AM', callingCodes: ['374'], region: 'Asia'},
	{name: 'Aruba', alpha2Code: 'AW', callingCodes: ['297'], region: 'Americas'},
	{name: 'Australia', alpha2Code: 'AU', callingCodes: ['61'], region: 'Oceania'},
	{name: 'Austria', alpha2Code: 'AT', callingCodes: ['43'], region: 'Europe'},
	{name: 'Azerbaijan', alpha2Code: 'AZ', callingCodes: ['994'], region: 'Asia'},
	{name: 'Bahamas', alpha2Code: 'BS', callingCodes: ['1'], region: 'Americas'},
	{name: 'Bahrain', alpha2Code: 'BH', callingCodes: ['973'], region: 'Asia'},
	{name: 'Bangladesh', alpha2Code: 'BD', callingCodes: ['880'], region: 'Asia'},
	{name: 'Barbados', alpha2Code: 'BB', callingCodes: ['1'], region: 'Americas'},
	{name: 'Belarus', alpha2Code: 'BY', callingCodes: ['375'], region: 'Europe'},
	{name: 'Belgium', alpha2Code: 'BE', callingCodes: ['32'], region: 'Europe'},
	{name: 'Belize', alpha2Code: 'BZ', callingCodes: ['501'], region: 'Americas'},
	{name: 'Benin', alpha2Code: 'BJ', callingCodes: ['229'], region: 'Africa'},
	{name: 'Bermuda', alpha2Code: 'BM', callingCodes: ['1'], region: 'Americas'},
	{name: 'Bhutan', alpha2Code: 'BT', callingCodes: ['975'], region: 'Asia'},
	{name: 'Bolivia (Plurinational State of)', alpha2Code: 'BO', callingCodes: ['591'], region: 'Americas'},
	{name: 'Bonaire, Sint Eustatius and Saba', alpha2Code: 'BQ', callingCodes: ['599'], region: 'Americas'},
	{name: 'Bosnia and Herzegovina', alpha2Code: 'BA', callingCodes: ['387'], region: 'Europe'},
	{name: 'Botswana', alpha2Code: 'BW', callingCodes: ['267'], region: 'Africa'},
	{name: 'Bouvet Island', alpha2Code: 'BV', callingCodes: ['47'], region: ''},
	{name: 'Brazil', alpha2Code: 'BR', callingCodes: ['55'], region: 'Americas'},
	{name: 'British Indian Ocean Territory', alpha2Code: 'IO', callingCodes: ['246'], region: 'Africa'},
	{name: 'United States Minor Outlying Islands', alpha2Code: 'UM', callingCodes: ['1'], region: 'Americas'},
	{name: 'Virgin Islands (British)', alpha2Code: 'VG', callingCodes: ['1'], region: 'Americas'},
	{name: 'Virgin Islands (U.S.)', alpha2Code: 'VI', callingCodes: ['1 340'], region: 'Americas'},
	{name: 'Brunei Darussalam', alpha2Code: 'BN', callingCodes: ['673'], region: 'Asia'},
	{name: 'Bulgaria', alpha2Code: 'BG', callingCodes: ['359'], region: 'Europe'},
	{name: 'Burkina Faso', alpha2Code: 'BF', callingCodes: ['226'], region: 'Africa'},
	{name: 'Burundi', alpha2Code: 'BI', callingCodes: ['257'], region: 'Africa'},
	{name: 'Cambodia', alpha2Code: 'KH', callingCodes: ['855'], region: 'Asia'},
	{name: 'Cameroon', alpha2Code: 'CM', callingCodes: ['237'], region: 'Africa'},
	{name: 'Canada', alpha2Code: 'CA', callingCodes: ['1'], region: 'Americas'},
	{name: 'Cabo Verde', alpha2Code: 'CV', callingCodes: ['238'], region: 'Africa'},
	{name: 'Cayman Islands', alpha2Code: 'KY', callingCodes: ['1'], region: 'Americas'},
	{name: 'Central African Republic', alpha2Code: 'CF', callingCodes: ['236'], region: 'Africa'},
	{name: 'Chad', alpha2Code: 'TD', callingCodes: ['235'], region: 'Africa'},
	{name: 'Chile', alpha2Code: 'CL', callingCodes: ['56'], region: 'Americas'},
	{name: 'China', alpha2Code: 'CN', callingCodes: ['86'], region: 'Asia'},
	{name: 'Christmas Island', alpha2Code: 'CX', callingCodes: ['61'], region: 'Oceania'},
	{name: 'Cocos (Keeling) Islands', alpha2Code: 'CC', callingCodes: ['61'], region: 'Oceania'},
	{name: 'Colombia', alpha2Code: 'CO', callingCodes: ['57'], region: 'Americas'},
	{name: 'Comoros', alpha2Code: 'KM', callingCodes: ['269'], region: 'Africa'},
	{name: 'Congo', alpha2Code: 'CG', callingCodes: ['242'], region: 'Africa'},
	{name: 'Congo (Democratic Republic of the)', alpha2Code: 'CD', callingCodes: ['243'], region: 'Africa'},
	{name: 'Cook Islands', alpha2Code: 'CK', callingCodes: ['682'], region: 'Oceania'},
	{name: 'Costa Rica', alpha2Code: 'CR', callingCodes: ['506'], region: 'Americas'},
	{name: 'Croatia', alpha2Code: 'HR', callingCodes: ['385'], region: 'Europe'},
	{name: 'Cuba', alpha2Code: 'CU', callingCodes: ['53'], region: 'Americas'},
	{name: 'Curaçao', alpha2Code: 'CW', callingCodes: ['599'], region: 'Americas'},
	{name: 'Cyprus', alpha2Code: 'CY', callingCodes: ['357'], region: 'Europe'},
	{name: 'Czech Republic', alpha2Code: 'CZ', callingCodes: ['420'], region: 'Europe'},
	{name: 'Denmark', alpha2Code: 'DK', callingCodes: ['45'], region: 'Europe'},
	{name: 'Djibouti', alpha2Code: 'DJ', callingCodes: ['253'], region: 'Africa'},
	{name: 'Dominica', alpha2Code: 'DM', callingCodes: ['1'], region: 'Americas'},
	{name: 'Dominican Republic', alpha2Code: 'DO', callingCodes: ['1'], region: 'Americas'},
	{name: 'Ecuador', alpha2Code: 'EC', callingCodes: ['593'], region: 'Americas'},
	{name: 'Egypt', alpha2Code: 'EG', callingCodes: ['20'], region: 'Africa'},
	{name: 'El Salvador', alpha2Code: 'SV', callingCodes: ['503'], region: 'Americas'},
	{name: 'Equatorial Guinea', alpha2Code: 'GQ', callingCodes: ['240'], region: 'Africa'},
	{name: 'Eritrea', alpha2Code: 'ER', callingCodes: ['291'], region: 'Africa'},
	{name: 'Estonia', alpha2Code: 'EE', callingCodes: ['372'], region: 'Europe'},
	{name: 'Ethiopia', alpha2Code: 'ET', callingCodes: ['251'], region: 'Africa'},
	{name: 'Falkland Islands (Malvinas)', alpha2Code: 'FK', callingCodes: ['500'], region: 'Americas'},
	{name: 'Faroe Islands', alpha2Code: 'FO', callingCodes: ['298'], region: 'Europe'},
	{name: 'Fiji', alpha2Code: 'FJ', callingCodes: ['679'], region: 'Oceania'},
	{name: 'Finland', alpha2Code: 'FI', callingCodes: ['358'], region: 'Europe'},
	{name: 'France', alpha2Code: 'FR', callingCodes: ['33'], region: 'Europe'},
	{name: 'French Guiana', alpha2Code: 'GF', callingCodes: ['594'], region: 'Americas'},
	{name: 'French Polynesia', alpha2Code: 'PF', callingCodes: ['689'], region: 'Oceania'},
	{name: 'French Southern Territories', alpha2Code: 'TF', callingCodes: ['262'], region: 'Africa'},
	{name: 'Gabon', alpha2Code: 'GA', callingCodes: ['241'], region: 'Africa'},
	{name: 'Gambia', alpha2Code: 'GM', callingCodes: ['220'], region: 'Africa'},
	{name: 'Georgia', alpha2Code: 'GE', callingCodes: ['995'], region: 'Asia'},
	{name: 'Germany', alpha2Code: 'DE', callingCodes: ['49'], region: 'Europe'},
	{name: 'Ghana', alpha2Code: 'GH', callingCodes: ['233'], region: 'Africa'},
	{name: 'Gibraltar', alpha2Code: 'GI', callingCodes: ['350'], region: 'Europe'},
	{name: 'Greece', alpha2Code: 'GR', callingCodes: ['30'], region: 'Europe'},
	{name: 'Greenland', alpha2Code: 'GL', callingCodes: ['299'], region: 'Americas'},
	{name: 'Grenada', alpha2Code: 'GD', callingCodes: ['1'], region: 'Americas'},
	{name: 'Guadeloupe', alpha2Code: 'GP', callingCodes: ['590'], region: 'Americas'},
	{name: 'Guam', alpha2Code: 'GU', callingCodes: ['1'], region: 'Oceania'},
	{name: 'Guatemala', alpha2Code: 'GT', callingCodes: ['502'], region: 'Americas'},
	{name: 'Guernsey', alpha2Code: 'GG', callingCodes: ['44'], region: 'Europe'},
	{name: 'Guinea', alpha2Code: 'GN', callingCodes: ['224'], region: 'Africa'},
	{name: 'Guinea-Bissau', alpha2Code: 'GW', callingCodes: ['245'], region: 'Africa'},
	{name: 'Guyana', alpha2Code: 'GY', callingCodes: ['592'], region: 'Americas'},
	{name: 'Haiti', alpha2Code: 'HT', callingCodes: ['509'], region: 'Americas'},
	{name: 'Heard Island and McDonald Islands', alpha2Code: 'HM', callingCodes: ['672'], region: ''},
	{name: 'Holy See', alpha2Code: 'VA', callingCodes: ['379'], region: 'Europe'},
	{name: 'Honduras', alpha2Code: 'HN', callingCodes: ['504'], region: 'Americas'},
	{name: 'Hong Kong', alpha2Code: 'HK', callingCodes: ['852'], region: 'Asia'},
	{name: 'Hungary', alpha2Code: 'HU', callingCodes: ['36'], region: 'Europe'},
	{name: 'Iceland', alpha2Code: 'IS', callingCodes: ['354'], region: 'Europe'},
	{name: 'India', alpha2Code: 'IN', callingCodes: ['91'], region: 'Asia'},
	{name: 'Indonesia', alpha2Code: 'ID', callingCodes: ['62'], region: 'Asia'},
	{name: "Côte d'Ivoire", alpha2Code: 'CI', callingCodes: ['225'], region: 'Africa'},
	{name: 'Iran (Islamic Republic of)', alpha2Code: 'IR', callingCodes: ['98'], region: 'Asia'},
	{name: 'Iraq', alpha2Code: 'IQ', callingCodes: ['964'], region: 'Asia'},
	{name: 'Ireland', alpha2Code: 'IE', callingCodes: ['353'], region: 'Europe'},
	{name: 'Isle of Man', alpha2Code: 'IM', callingCodes: ['44'], region: 'Europe'},
	{name: 'Israel', alpha2Code: 'IL', callingCodes: ['972'], region: 'Asia'},
	{name: 'Italy', alpha2Code: 'IT', callingCodes: ['39'], region: 'Europe'},
	{name: 'Jamaica', alpha2Code: 'JM', callingCodes: ['1'], region: 'Americas'},
	{name: 'Japan', alpha2Code: 'JP', callingCodes: ['81'], region: 'Asia'},
	{name: 'Jersey', alpha2Code: 'JE', callingCodes: ['44'], region: 'Europe'},
	{name: 'Jordan', alpha2Code: 'JO', callingCodes: ['962'], region: 'Asia'},
	{name: 'Kazakhstan', alpha2Code: 'KZ', callingCodes: ['76', '77'], region: 'Asia'},
	{name: 'Kenya', alpha2Code: 'KE', callingCodes: ['254'], region: 'Africa'},
	{name: 'Kiribati', alpha2Code: 'KI', callingCodes: ['686'], region: 'Oceania'},
	{name: 'Kuwait', alpha2Code: 'KW', callingCodes: ['965'], region: 'Asia'},
	{name: 'Kyrgyzstan', alpha2Code: 'KG', callingCodes: ['996'], region: 'Asia'},
	{name: "Lao People's Democratic Republic", alpha2Code: 'LA', callingCodes: ['856'], region: 'Asia'},
	{name: 'Latvia', alpha2Code: 'LV', callingCodes: ['371'], region: 'Europe'},
	{name: 'Lebanon', alpha2Code: 'LB', callingCodes: ['961'], region: 'Asia'},
	{name: 'Lesotho', alpha2Code: 'LS', callingCodes: ['266'], region: 'Africa'},
	{name: 'Liberia', alpha2Code: 'LR', callingCodes: ['231'], region: 'Africa'},
	{name: 'Libya', alpha2Code: 'LY', callingCodes: ['218'], region: 'Africa'},
	{name: 'Liechtenstein', alpha2Code: 'LI', callingCodes: ['423'], region: 'Europe'},
	{name: 'Lithuania', alpha2Code: 'LT', callingCodes: ['370'], region: 'Europe'},
	{name: 'Luxembourg', alpha2Code: 'LU', callingCodes: ['352'], region: 'Europe'},
	{name: 'Macao', alpha2Code: 'MO', callingCodes: ['853'], region: 'Asia'},
	{name: 'Macedonia (the former Yugoslav Republic of)', alpha2Code: 'MK', callingCodes: ['389'], region: 'Europe'},
	{name: 'Madagascar', alpha2Code: 'MG', callingCodes: ['261'], region: 'Africa'},
	{name: 'Malawi', alpha2Code: 'MW', callingCodes: ['265'], region: 'Africa'},
	{name: 'Malaysia', alpha2Code: 'MY', callingCodes: ['60'], region: 'Asia'},
	{name: 'Maldives', alpha2Code: 'MV', callingCodes: ['960'], region: 'Asia'},
	{name: 'Mali', alpha2Code: 'ML', callingCodes: ['223'], region: 'Africa'},
	{name: 'Malta', alpha2Code: 'MT', callingCodes: ['356'], region: 'Europe'},
	{name: 'Marshall Islands', alpha2Code: 'MH', callingCodes: ['692'], region: 'Oceania'},
	{name: 'Martinique', alpha2Code: 'MQ', callingCodes: ['596'], region: 'Americas'},
	{name: 'Mauritania', alpha2Code: 'MR', callingCodes: ['222'], region: 'Africa'},
	{name: 'Mauritius', alpha2Code: 'MU', callingCodes: ['230'], region: 'Africa'},
	{name: 'Mayotte', alpha2Code: 'YT', callingCodes: ['262'], region: 'Africa'},
	{name: 'Mexico', alpha2Code: 'MX', callingCodes: ['52'], region: 'Americas'},
	{name: 'Micronesia (Federated States of)', alpha2Code: 'FM', callingCodes: ['691'], region: 'Oceania'},
	{name: 'Moldova (Republic of)', alpha2Code: 'MD', callingCodes: ['373'], region: 'Europe'},
	{name: 'Monaco', alpha2Code: 'MC', callingCodes: ['377'], region: 'Europe'},
	{name: 'Mongolia', alpha2Code: 'MN', callingCodes: ['976'], region: 'Asia'},
	{name: 'Montenegro', alpha2Code: 'ME', callingCodes: ['382'], region: 'Europe'},
	{name: 'Montserrat', alpha2Code: 'MS', callingCodes: ['1'], region: 'Americas'},
	{name: 'Morocco', alpha2Code: 'MA', callingCodes: ['212'], region: 'Africa'},
	{name: 'Mozambique', alpha2Code: 'MZ', callingCodes: ['258'], region: 'Africa'},
	{name: 'Myanmar', alpha2Code: 'MM', callingCodes: ['95'], region: 'Asia'},
	{name: 'Namibia', alpha2Code: 'NA', callingCodes: ['264'], region: 'Africa'},
	{name: 'Nauru', alpha2Code: 'NR', callingCodes: ['674'], region: 'Oceania'},
	{name: 'Nepal', alpha2Code: 'NP', callingCodes: ['977'], region: 'Asia'},
	{name: 'Netherlands', alpha2Code: 'NL', callingCodes: ['31'], region: 'Europe'},
	{name: 'New Caledonia', alpha2Code: 'NC', callingCodes: ['687'], region: 'Oceania'},
	{name: 'New Zealand', alpha2Code: 'NZ', callingCodes: ['64'], region: 'Oceania'},
	{name: 'Nicaragua', alpha2Code: 'NI', callingCodes: ['505'], region: 'Americas'},
	{name: 'Niger', alpha2Code: 'NE', callingCodes: ['227'], region: 'Africa'},
	{name: 'Nigeria', alpha2Code: 'NG', callingCodes: ['234'], region: 'Africa'},
	{name: 'Niue', alpha2Code: 'NU', callingCodes: ['683'], region: 'Oceania'},
	{name: 'Norfolk Island', alpha2Code: 'NF', callingCodes: ['672'], region: 'Oceania'},
	{name: "Korea (Democratic People's Republic of)", alpha2Code: 'KP', callingCodes: ['850'], region: 'Asia'},
	{name: 'Northern Mariana Islands', alpha2Code: 'MP', callingCodes: ['1'], region: 'Oceania'},
	{name: 'Norway', alpha2Code: 'NO', callingCodes: ['47'], region: 'Europe'},
	{name: 'Oman', alpha2Code: 'OM', callingCodes: ['968'], region: 'Asia'},
	{name: 'Pakistan', alpha2Code: 'PK', callingCodes: ['92'], region: 'Asia'},
	{name: 'Palau', alpha2Code: 'PW', callingCodes: ['680'], region: 'Oceania'},
	{name: 'Palestine, State of', alpha2Code: 'PS', callingCodes: ['970'], region: 'Asia'},
	{name: 'Panama', alpha2Code: 'PA', callingCodes: ['507'], region: 'Americas'},
	{name: 'Papua New Guinea', alpha2Code: 'PG', callingCodes: ['675'], region: 'Oceania'},
	{name: 'Paraguay', alpha2Code: 'PY', callingCodes: ['595'], region: 'Americas'},
	{name: 'Peru', alpha2Code: 'PE', callingCodes: ['51'], region: 'Americas'},
	{name: 'Philippines', alpha2Code: 'PH', callingCodes: ['63'], region: 'Asia'},
	{name: 'Pitcairn', alpha2Code: 'PN', callingCodes: ['64'], region: 'Oceania'},
	{name: 'Poland', alpha2Code: 'PL', callingCodes: ['48'], region: 'Europe'},
	{name: 'Portugal', alpha2Code: 'PT', callingCodes: ['351'], region: 'Europe'},
	{name: 'Puerto Rico', alpha2Code: 'PR', callingCodes: ['1'], region: 'Americas'},
	{name: 'Qatar', alpha2Code: 'QA', callingCodes: ['974'], region: 'Asia'},
	{name: 'Republic of Kosovo', alpha2Code: 'XK', callingCodes: ['383'], region: 'Europe'},
	{name: 'Réunion', alpha2Code: 'RE', callingCodes: ['262'], region: 'Africa'},
	{name: 'Romania', alpha2Code: 'RO', callingCodes: ['40'], region: 'Europe'},
	{name: 'Russian Federation', alpha2Code: 'RU', callingCodes: ['7'], region: 'Europe'},
	{name: 'Rwanda', alpha2Code: 'RW', callingCodes: ['250'], region: 'Africa'},
	{name: 'Saint Barthélemy', alpha2Code: 'BL', callingCodes: ['590'], region: 'Americas'},
	{name: 'Saint Helena, Ascension and Tristan da Cunha', alpha2Code: 'SH', callingCodes: ['290'], region: 'Africa'},
	{name: 'Saint Kitts and Nevis', alpha2Code: 'KN', callingCodes: ['1'], region: 'Americas'},
	{name: 'Saint Lucia', alpha2Code: 'LC', callingCodes: ['1'], region: 'Americas'},
	{name: 'Saint Martin (French part)', alpha2Code: 'MF', callingCodes: ['590'], region: 'Americas'},
	{name: 'Saint Pierre and Miquelon', alpha2Code: 'PM', callingCodes: ['508'], region: 'Americas'},
	{name: 'Saint Vincent and the Grenadines', alpha2Code: 'VC', callingCodes: ['1'], region: 'Americas'},
	{name: 'Samoa', alpha2Code: 'WS', callingCodes: ['685'], region: 'Oceania'},
	{name: 'San Marino', alpha2Code: 'SM', callingCodes: ['378'], region: 'Europe'},
	{name: 'Sao Tome and Principe', alpha2Code: 'ST', callingCodes: ['239'], region: 'Africa'},
	{name: 'Saudi Arabia', alpha2Code: 'SA', callingCodes: ['966'], region: 'Asia'},
	{name: 'Senegal', alpha2Code: 'SN', callingCodes: ['221'], region: 'Africa'},
	{name: 'Serbia', alpha2Code: 'RS', callingCodes: ['381'], region: 'Europe'},
	{name: 'Seychelles', alpha2Code: 'SC', callingCodes: ['248'], region: 'Africa'},
	{name: 'Sierra Leone', alpha2Code: 'SL', callingCodes: ['232'], region: 'Africa'},
	{name: 'Singapore', alpha2Code: 'SG', callingCodes: ['65'], region: 'Asia'},
	{name: 'Sint Maarten (Dutch part)', alpha2Code: 'SX', callingCodes: ['1'], region: 'Americas'},
	{name: 'Slovakia', alpha2Code: 'SK', callingCodes: ['421'], region: 'Europe'},
	{name: 'Slovenia', alpha2Code: 'SI', callingCodes: ['386'], region: 'Europe'},
	{name: 'Solomon Islands', alpha2Code: 'SB', callingCodes: ['677'], region: 'Oceania'},
	{name: 'Somalia', alpha2Code: 'SO', callingCodes: ['252'], region: 'Africa'},
	{name: 'South Africa', alpha2Code: 'ZA', callingCodes: ['27'], region: 'Africa'},
	{name: 'South Georgia and the South Sandwich Islands', alpha2Code: 'GS', callingCodes: ['500'], region: 'Americas'},
	{name: 'Korea (Republic of)', alpha2Code: 'KR', callingCodes: ['82'], region: 'Asia'},
	{name: 'South Sudan', alpha2Code: 'SS', callingCodes: ['211'], region: 'Africa'},
	{name: 'Spain', alpha2Code: 'ES', callingCodes: ['34'], region: 'Europe'},
	{name: 'Sri Lanka', alpha2Code: 'LK', callingCodes: ['94'], region: 'Asia'},
	{name: 'Sudan', alpha2Code: 'SD', callingCodes: ['249'], region: 'Africa'},
	{name: 'Suriname', alpha2Code: 'SR', callingCodes: ['597'], region: 'Americas'},
	{name: 'Svalbard and Jan Mayen', alpha2Code: 'SJ', callingCodes: ['47'], region: 'Europe'},
	{name: 'Swaziland', alpha2Code: 'SZ', callingCodes: ['268'], region: 'Africa'},
	{name: 'Sweden', alpha2Code: 'SE', callingCodes: ['46'], region: 'Europe'},
	{name: 'Switzerland', alpha2Code: 'CH', callingCodes: ['41'], region: 'Europe'},
	{name: 'Syrian Arab Republic', alpha2Code: 'SY', callingCodes: ['963'], region: 'Asia'},
	{name: 'Taiwan', alpha2Code: 'TW', callingCodes: ['886'], region: 'Asia'},
	{name: 'Tajikistan', alpha2Code: 'TJ', callingCodes: ['992'], region: 'Asia'},
	{name: 'Tanzania, United Republic of', alpha2Code: 'TZ', callingCodes: ['255'], region: 'Africa'},
	{name: 'Thailand', alpha2Code: 'TH', callingCodes: ['66'], region: 'Asia'},
	{name: 'Timor-Leste', alpha2Code: 'TL', callingCodes: ['670'], region: 'Asia'},
	{name: 'Togo', alpha2Code: 'TG', callingCodes: ['228'], region: 'Africa'},
	{name: 'Tokelau', alpha2Code: 'TK', callingCodes: ['690'], region: 'Oceania'},
	{name: 'Tonga', alpha2Code: 'TO', callingCodes: ['676'], region: 'Oceania'},
	{name: 'Trinidad and Tobago', alpha2Code: 'TT', callingCodes: ['1'], region: 'Americas'},
	{name: 'Tunisia', alpha2Code: 'TN', callingCodes: ['216'], region: 'Africa'},
	{name: 'Turkey', alpha2Code: 'TR', callingCodes: ['90'], region: 'Asia'},
	{name: 'Turkmenistan', alpha2Code: 'TM', callingCodes: ['993'], region: 'Asia'},
	{name: 'Turks and Caicos Islands', alpha2Code: 'TC', callingCodes: ['1'], region: 'Americas'},
	{name: 'Tuvalu', alpha2Code: 'TV', callingCodes: ['688'], region: 'Oceania'},
	{name: 'Uganda', alpha2Code: 'UG', callingCodes: ['256'], region: 'Africa'},
	{name: 'Ukraine', alpha2Code: 'UA', callingCodes: ['380'], region: 'Europe'},
	{name: 'United Arab Emirates', alpha2Code: 'AE', callingCodes: ['971'], region: 'Asia'},
	{
		name: 'United Kingdom of Great Britain and Northern Ireland',
		alpha2Code: 'GB',
		callingCodes: ['44'],
		region: 'Europe',
	},
	{name: 'United States of America', alpha2Code: 'US', callingCodes: ['1'], region: 'Americas'},
	{name: 'Uruguay', alpha2Code: 'UY', callingCodes: ['598'], region: 'Americas'},
	{name: 'Uzbekistan', alpha2Code: 'UZ', callingCodes: ['998'], region: 'Asia'},
	{name: 'Vanuatu', alpha2Code: 'VU', callingCodes: ['678'], region: 'Oceania'},
	{name: 'Venezuela (Bolivarian Republic of)', alpha2Code: 'VE', callingCodes: ['58'], region: 'Americas'},
	{name: 'Viet Nam', alpha2Code: 'VN', callingCodes: ['84'], region: 'Asia'},
	{name: 'Wallis and Futuna', alpha2Code: 'WF', callingCodes: ['681'], region: 'Oceania'},
	{name: 'Western Sahara', alpha2Code: 'EH', callingCodes: ['212'], region: 'Africa'},
	{name: 'Yemen', alpha2Code: 'YE', callingCodes: ['967'], region: 'Asia'},
	{name: 'Zambia', alpha2Code: 'ZM', callingCodes: ['260'], region: 'Africa'},
	{name: 'Zimbabwe', alpha2Code: 'ZW', callingCodes: ['263'], region: 'Africa'},
]
