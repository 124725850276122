var sb = require('@sb/util')
const flow = require('@subiz/flow')
const lo = require('lodash')
import store from '@sb/store'

import OverdueTasks from '@sb/com/noti_overdue_tasks.js'
import TaskReminderNoti from './commons/task_reminder_noti.js'
import Fragment from '@sb/com/fragment'

const NOTI_OVERDUE_TASK_HIDDEN_TIME = 900000 // 15 minutes

export default {
	name: 'instant_noti',
	data() {
		return {
			show: false,
			popupNotis: [],
			showOverdueTask: false,
		}
	},

	async mounted() {
		store.onAccount(this, () => {
			if (this.isOffNoti()) {
				this.popupNotis = [] // clean
			}
			this.$forceUpdate()
		})
		store.onUser(this, () => this.$forceUpdate())
		store.onInstantNoti(this, (newnoti) => this.doNew(newnoti))
		this.showOverdueTask =
			Date.now() - lo.get(store.me(), 'dashboard_setting.last_close_task_notification', 0) >
			NOTI_OVERDUE_TASK_HIDDEN_TIME
	},

	methods: {
		isOffNoti() {
			let setting = lo.get(store.matchSettingNotify(), 'setting') || {}
			if (sb.now() < sb.getMs(setting.instant_mute_until)) return true
			return (
				!lo.get(setting, 'web.user_returned') &&
				!lo.get(setting, 'web.user_created') &&
				!lo.get(setting, 'web.campaign_user_converted') &&
				!lo.get(setting, 'web.user_opened_email') &&
				!lo.get(setting, 'web.lead_created')
			)
		},

		async onMute15Mins() {
			this.popupNotis = []
			let setting = lo.get(store.matchSettingNotify(), 'setting') || {}
			if (!setting) return
			setting.instant_mute_until = sb.now() + 900000
			let {error} = await store.updateSettingNotify(setting)
			if (error) return this.$showError(this.$t('seting_not_saved'))
			this.popupNotis = []
		},

		onSettingClick() {
			this.$router.push({name: 'profile.setting-notification'})
		},

		async doNew(newnoti) {
			if (!newnoti) return this.$forceUpdate()
			if (!newnoti.data) return null
			let data = JSON.parse(newnoti.data) || {}
			let orderid
			if (newnoti.type == 'order_assigned' || newnoti.type === 'order_commented') {
				// dont show instant noti if agent is already viewing the order
				let viewing = store.getViewingOrder()
				if (viewing == data.order_id) return

				orderid = data.order_id
			}
			let uid
			if (newnoti.type == 'task_reminded') return
			if (newnoti.type == 'task_expired') return
			if (newnoti.type === 'lead_created' || newnoti.type === 'user_created' || newnoti.type === 'user_returned')
				uid = lo.get(data, 'user.id')
			if (newnoti.type === 'campaign_user_converted' || newnoti.type === 'user_opened_email') {
				uid = lo.get(data, 'user_id')
			}

			let user = null
			// try to fetch user to reduce ui update
			if (uid) {
				user = store.matchUser(uid)
				let start = sb.now()
				while (!user && sb.now() - start < 2000) {
					user = store.matchUser(uid)
					await flow.sleep(200)
				}
			}

			if (orderid) {
				let order = store.matchOrder(orderid)
				let start = sb.now()
				while (!order && sb.now() - start < 10000) {
					order = store.matchOrder(orderid)
					await flow.sleep(200)
				}
				if (!order) return
			}

			if (!newnoti.id) newnoti.id = newnoti.checkpoint + '-' + newnoti.topic
			if (this.popupNotis.find((noti) => noti.id === newnoti.id)) return
			this.popupNotis.unshift(newnoti)
			this.popupNotis = this.popupNotis.slice(0, 3)
			setTimeout(() => this.removePopupNoti(newnoti.id), 6000)
			this.$forceUpdate()
		},

		removePopupNoti(id) {
			this.popupNotis = this.popupNotis.filter((noti) => noti.id !== id)
		},

		async onNotiClick(noti, options = {}) {
			if (!noti.data) return null
			let data = JSON.parse(noti.data) || {}

			if (noti.type === 'order_commented' || noti.type === 'order_assigned') {
				this.removePopupNoti(noti.id)

				let orderid = data.order_id
				if (!orderid) return
				this.$router.push({name: 'order-list', query: {order_id: orderid}})
				return
			}

			let uid = lo.get(data, 'user.id')
			if (noti.type === 'campaign_user_converted' || noti.type === 'user_opened_email') uid = lo.get(data, 'user_id')
			this.removePopupNoti(noti.id)
			store.readNotification(noti.id)

			if (
				noti.type === 'task_status_updated' ||
				noti.type === 'task_title_updated' ||
				noti.type === 'task_note_updated' ||
				noti.type === 'task_due_date_updated' ||
				noti.type === 'task_assigned' ||
				noti.type === 'task_reminded' ||
				noti.type === 'task_expired' ||
				noti.type === 'task_mentioned' ||
				noti.type === 'task_commented'
			) {
				this.$root.$emit('open_tasks_modal', {task_id: data.task_id})
				return
			}
			if (noti.type === 'task_deleted') {
				this.$root.$emit('open_tasks_modal', {isTaskDeleted: true})
				return
			}
			this.$root.$emit('showQuickview', {user_id: uid, create_new: options.is_send_message})
		},

		async closeTaskNoti() {
			this.showOverdueTask = false
			let out = await store.fetchAgent(store.me().id)
			let me = lo.cloneDeep(out.body)
			if (!me || !me.id) return this.$showError(this.$t('something_wrong_retry_again'))
			let new_close_task_noti = Date.now()
			lo.set(me, 'dashboard_setting.last_close_task_notification', new_close_task_noti)
			let {error} = await store.updateAgent({...me, _update_fields: ['dashboard_setting']})
			this.$forceUpdate()
			setTimeout(() => {
				this.showOverdueTask = true
				this.$forceUpdate()
			}, NOTI_OVERDUE_TASK_HIDDEN_TIME)
		},
	},

	render() {
		let popupNotis = this.popupNotis.slice()
		let $items = lo.map(popupNotis, (noti) => {
			if (noti.is_read) return null
			return (
				<div class='instant_noti__popup_noti-item' key={noti.id}>
					<Icon
						name='x'
						size='16'
						stroke-width='2'
						class='btn__close btn__close_noti'
						style='position: absolute; right: -12px; top: -9px;'
						vOn:click_stop={(_) => this.removePopupNoti(noti.id)}
					/>
					<NotiItem noti={noti} vOn:click={this.onNotiClick} />
				</div>
			)
		})

		let last_close_task_notification = lo.get(store.me(), 'dashboard_setting.last_close_task_notification', 0)
		let $overdue = null
		if (last_close_task_notification === 0 || this.showOverdueTask)
			$overdue = <OverdueTasks vOn:close={this.closeTaskNoti} />

		return (
			<div class='instant_noti__popup'>
				{$overdue}
				<TaskReminderNoti />
				<transition-group name='instant_noti__popup_noti' tag='div'>
					{$items}
				</transition-group>
			</div>
		)
	},
}
