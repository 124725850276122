if (!Element.prototype.scrollIntoViewIfNeeded) {
	Element.prototype.scrollIntoViewIfNeeded = function(centerIfNeeded) {
		centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded

		var parent = this.parentNode
		var parentComputedStyle = window.getComputedStyle(parent, null)
		var parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'))
		var parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'))
		var overTop = this.offsetTop - parent.offsetTop < parent.scrollTop
		var overBottom =
			this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth >
			parent.scrollTop + parent.clientHeight
		var overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft
		var overRight =
			this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth >
			parent.scrollLeft + parent.clientWidth
		var alignWithTop = overTop && !overBottom

		if ((overTop || overBottom) && centerIfNeeded) {
			parent.scrollTop =
				this.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + this.clientHeight / 2
		}

		if ((overLeft || overRight) && centerIfNeeded) {
			parent.scrollLeft =
				this.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + this.clientWidth / 2
		}

		if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
			this.scrollIntoView(alignWithTop)
		}
	}
}

import ICON from './icon.js'
export const Icon = ICON

import AVATAR from './avatar.js'
export const Avatar = AVATAR

import AVATARGROUP from './avatarGroup.js'
export const AvatarGroup = AVATARGROUP

import INPUT2 from './input2.js'
export const Input2 = INPUT2

import TAG from './tag.js'
export const Tag = TAG

import SPINNER from './spinner.js'
export const Spinner = SPINNER

import TOAST from './toastermethod.js'
export const Toast = TOAST

import CONFIRMMETHOD from './confirmmethod.js'
export const ConfirmMethod = CONFIRMMETHOD

import UPDATE_CONTACT_METHOD from './update_contact_attribute_method.js'
export const UpdateContactMethod = UPDATE_CONTACT_METHOD

import CREATE_USER_METHOD from './create_user_method.js'
export const CreateUserMethod = CREATE_USER_METHOD

import TIME from './time.js'
export const Time = TIME

import EMOJI from './emoji.js'
export const Emoji = EMOJI

import MESSAGEEDITOR from './messageEditor.js'
export const MessageEditor = MESSAGEEDITOR

import PRIVATEMESSAGEEDITOR from './privateMessageEditor.js'
export const PrivateMessageEditor = PRIVATEMESSAGEEDITOR

import ONELINEMSG from './onelineMsg.js'
export const OnelineMsg = ONELINEMSG

import SWITCH from './Switch.js'
export const Switch = SWITCH

import FRAGMENT from '../commons/fragment.js'
export const Fragment = FRAGMENT

import INPUTSEARCH from '../commons/inputSearch.js'
export const InputSearch = INPUTSEARCH

import EMPTYSEARCHTABLE from '../commons/emptySearchTable.js'
export const EmptySearchTable = EMPTYSEARCHTABLE

import AGENTSPICKER from '../commons/agents_picker.js'
export const AgentsPicker = AGENTSPICKER

import CHANNELPICKER from '../commons/channel_picker.js'
export const ChannelPicker = CHANNELPICKER

import CHANNELSOURCEPICKER from '../commons/channel_source_picker.js'
export const ChannelSourcePicker = CHANNELSOURCEPICKER

import LOCATIONPICKER from '../commons/location_picker.js'
export const LocationPicker = LOCATIONPICKER

import DRAWER from '../commons/drawer.js'
export const Drawer = DRAWER

import BROWSERPICKER from '../commons/browser_picker.js'
export const BrowserPicker = BROWSERPICKER

import IMAGE from './image.js'
export const Image = IMAGE

import HIGHLIGHT_TEXT from './highlightText'
export const HighlightText = HIGHLIGHT_TEXT

import SIDEBAR_FOOTER from './sidebarFooter'
export const SidebarFooter = SIDEBAR_FOOTER

import ARROW_RIGHT from './arrow_right.js'
export const ArrowRight = ARROW_RIGHT

import USERLABEL from './label.js'
export const UserLabel = USERLABEL

import TAG_INPUT from './tag_input.js'
export const TagInput = TAG_INPUT
// good components:
// spinner, image, sw, confirm, fragment, time, avatar

// bad components:
// sidebar_footer, input search, empty search table, dropdown <> dropdownlist, highligh_text

import MULTIRANGE from './multirange.js'
export const MultiRange = MULTIRANGE

import PAGINATION from './pagiantion.js'
export const Pagination = PAGINATION

import ADDRESS_INPUT from './address_input.js'
export const AddressInput = ADDRESS_INPUT

import DATERANGEPICKER from './DateRangePicker.js'
export const DateRangePicker = DATERANGEPICKER

import DATEPICKER from './DatePicker.js'
export const DatePicker = DATEPICKER

import DROPDOWN from './dropdown.js'
export const Dropdown = DROPDOWN

import NOTIITEM from './noti_item.js'
export const NotiItem = NOTIITEM

import MONEY_INPUT from './money_input.js'
export const MoneyInput = MONEY_INPUT

import HOVER_DROPDOWN from './hover_dropdown'
export const HoverDropdown = HOVER_DROPDOWN

import PRODUCT_IMAGE from './product_image'
export const ProductAvatar = PRODUCT_IMAGE

import EMOJI_PICKER from './emoji_picker'
export const EmojiPicker = EMOJI_PICKER

import AUDIO_PLAYER from './audio_player'
export const AudioPlayer = AUDIO_PLAYER

import NUMBER from './number'
export const Numeric = NUMBER

import GLOBAL_AUDIO_METHOD from './global_audio_method'
export const GlobalAudioMethod = GLOBAL_AUDIO_METHOD

import AUTO_COMPLETE from './suggestion_input.js'
export const AutoComplete = AUTO_COMPLETE

import TASK_NOTI_COUNTER from './task_noti_counter.js'
export const TaskNotiCounter = TASK_NOTI_COUNTER

import AUDIO_WAVEFORM from './audio-waveform.js'
export const AudioWaveform = AUDIO_WAVEFORM

import TIME_PICKER from './time_picker.js'
export const TimePicker = TIME_PICKER
