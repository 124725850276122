import lo from 'lodash'

import store from '@sb/store'
import sb from '@sb/util'

export default {
	name: 'task_reminder_noti',

	data() {
		return {
			allTasks: [],
		}
	},

	mounted() {
		store.onTask(this, async () => {
			await this.fetchTasks()
			this.$forceUpdate()
		})
		store.onInstantNoti(this, (noti) => {
			if (noti.type === 'task_expired' || noti.type === 'task_reminded') {
				console.log('task_reminddddddddddddddd', noti)
				this.$forceUpdate()
			}
		})

		this.fetchTasks()
	},

	methods: {
		async fetchTasks() {
			let tasks = await store.listRemindedTasks()
			await store.fetchTasks(lo.map(tasks, (task) => task.id))
			this.allTasks = lo.map(tasks, (task) => store.matchTask(task.id) || task)
		},

		renderImage() {
			return (
				<img
					style='width: 55px; height: 55px; object-fit: cover; position: relative; top: 2px'
					class='mr-3'
					src={require('../assets/img/reminders.png')}
				/>
			)
		},

		getRemindedTask() {
			let tasks = this.allTasks
			//let tasks = store._listTasks({id: 'reminded_tasks', status: 'open', assignee: store.me().id})
			return lo.find(tasks, (task) => {
				let dueAt = task.due_at || 0
				let reminder = task.reminder || 0
				let member = lo.find(task.members, (mem) => mem.reminder_closed && mem.agent_id === store.me().id)
				let isClosed = member && member.reminder_closed > dueAt - reminder

				return dueAt && reminder && dueAt > Date.now() && reminder + Date.now() > dueAt && !isClosed
			})
		},

		onClickReminderNoti(task) {
			store.closeTaskReminder(task.id)
			this.$root.$emit('open_tasks_modal', {task_id: task.id})
		},
	},

	render() {
		let task = this.getRemindedTask()
		if (!task) return null

		let agentname = sb.getAgentDisplayName(store.me())
		agentname = lo.split(agentname, ' ')
		agentname = lo.last(agentname) || 'Agent'
		return (
			<div
				class='instant_noti__popup_noti-item mt-2'
				style='position: relative;'
				vOn:click={() => this.onClickReminderNoti(task)}
			>
				<div class='d-flex' style='overflow: hidden;'>
					{this.renderImage()}
					<div>
						<div>
							{`${agentname} ơi, nhắc việc`} <strong>{task.title}</strong>
							{' sẽ hết hạn trong'} <Time duration class='text__semibold' time={(task.due_at - Date.now()) / 1000} />
							{' tới'}. {'Đừng quên xử lý công việc nhé.'}
						</div>
						<div class='btn btn__sm btn__outline_primary mt-3'>{this.$t('resolve_now')}</div>
					</div>
				</div>
			</div>
		)
	},
}
