const ajax = require('@subiz/ajax/src/ajax.js')
const lo = require('lodash')
const sb = require('@sb/util')
const flow = require('@subiz/flow')
var api = require('./api.js')
var config = require('@sb/config')
import KV from './kv.js'
import InMemKV from './inmem_kv.js'
import common from './common.js'
import uuid from 'react-native-uuid'

const WebRTCConn = require('@subiz/wsclient/webrtc.js')
// import * as DOMPurify from 'dompurify'

function NewAccountStore(realtime, pubsub, parent) {
	let accKV = new KV(config.db_prefix + 'account')
	accKV.init()

	let {account_id, agent_id, access_token} = api.getCred()
	let memKV = new InMemKV()
	memKV.init()

	var me = {}

	me.destroy = () => accKV.destroy()

	let sbz_agents = {}
	me.setSubizAgent = (ags) => {
		lo.map(ags, (ag) => {
			sbz_agents[ag.id] = ag
		})
	}
	me.updatePromoteProduct = (val) => accKV.put('promote_product', val)
	me.matchPromoteProduct = () => accKV.match('promote_product')

	me.updateFromEmailCache = (email) => accKV.put('from_email_cache', email)
	me.getFromEmailCache = () => accKV.match('from_email_cache')

	me.matchSubizAgent = (id) => sbz_agents[id]
	me.searchUser = api.searchUser
	me.runBotForLeads = api.runBotForLeads

	me.loadScreen = (url, device) => api.load_screen(url, device)
	me.disablePushNotification = () => accKV.put('disable_push_notification', true)
	me.enablePushNotification = () => {
		pubsub.publish('enable_push_noti')
		accKV.put('disable_push_notification', false)
	}
	me.matchDisablePushNotification = () => accKV.match('disable_push_notification')

	me.setOpenOnlyFilter = (option) => accKV.put('convos_open_only', option)
	me.matchOpenOnlyFilter = () => accKV.match('convos_open_only')

	me.getFileDetail = api.getFileDetail
	me.beginLoadRoute = () => {
		if (me.routeLoading) return
		me.routeLoading = true
		// only publish while still loading
		// this would reduce number of time showing loading when loading time is too little
		// which reducing flashes
		setTimeout(() => {
			if (me.routeLoading) pubsub.publish('route')
		}, 100)
	}

	me.endLoadRoute = () => {
		if (!me.routeLoading) return
		me.routeLoading = false
		pubsub.publish('account')
	}

	me.matchRouteLoading = () => me.routeLoading

	me.updateFcmToken = async (token, platform) => {
		let uniphoneid = ''
		if (window.DeviceInfo) uniphoneid = await window.DeviceInfo.getUniqueId() // mobile
		return api.updateFcmToken(token, uniphoneid, platform)
	}

	me.deleteFcmToken = (token) => api.deleteFcmToken(token)
	me.lockLogin = (accid) => api.acc_lock_login(accid)
	me.unlockLogin = (accid) => api.acc_unlock_login(accid)
	me.updateCredit = (credit) => api.acc_update_credit(credit)

	// ACCOUNT
	let accdb = new Entity(realtime, pubsub, accKV, 'account')
	me.fetchAccount = (f) => accdb.list(f)
	me.updateAccount = (acc) => accdb.update(acc)
	me.deleteAccount = (accid) => api.acc_delete_account(accid)

	me.get_hellobar = (force) => api.get_hellobar(force)
	me.set_hellobar = (hellobar) => api.set_hellobar(hellobar)

	// INVOICES
	let invdb = new Entity(realtime, pubsub, accKV, 'invoice', 'id')
	me.fetchInvoices = () => invdb.list()
	me.matchInvoice = (_) => invdb.match()

	// PLAN
	let plandb = new Entity(realtime, pubsub, accKV, 'plan', 'name')
	me.fetchPlans = () => plandb.list()
	me.matchPlan = (_) => plandb.match()

	// EXCHANGE RATE
	let exchratedb = new Entity(realtime, pubsub, accKV, 'exchange_rate', 'id')
	me.fetchExchangeRate = () => exchratedb.list()
	me.matchExchangeRate = (_) => (exchratedb.match()['USD-VND'] || {}).exchange_rate || 21840

	me.fetchLanguage = (locale) => api.getLanguage(locale)
	me.updateLanguageMessage = (mes) => api.setLanguageMessage(mes)

	me.textToSpeech = (p) => api.text_to_speech(p)

	me.fetchDefLanguage = (locale) => api.getDefLanguage(locale)
	me.updateDefLanguageMessage = (mes) => api.setDefLanguageMessage(mes)

	me.matchCloseHelpWave = () => accKV.match('close_help_wave')
	me.closeHelpWave = (t) => accKV.put('close_help_wave', t)

	// CREDIT
	let creditdb = new Entity(realtime, pubsub, accKV, 'credit', 'id')
	me.fetchCredit = () => creditdb.list()
	me.matchCredit = (_) => creditdb.match()
	me.reportCredit = api.report_credit

	// SUBSCRIPTION
	let sudb = new Entity(realtime, pubsub, accKV, 'subscription', 'account_id')
	me.fetchSubscription = () => sudb.list()
	me.matchSubscription = (_) => sudb.match()[api.getCred().account_id]
	me.updateSubscription = (sub) => sudb.update(sub)

	// CONVO SETTING
	let cvsdb = new Entity(realtime, pubsub, accKV, 'convo_setting', 'account_id')
	me.fetchConvoSetting = () => cvsdb.list()
	me.matchConvoSetting = (_) => cvsdb.match()[api.getCred().account_id]
	me.updateConvoSetting = (setting) => cvsdb.update(setting)

	// FB FANPAGE SETTING
	let fbdb = new Entity(realtime, pubsub, accKV, 'facebook_setting', 'fanpage_id')
	me.fetchFacebookSetting = () => fbdb.list()
	me.matchFacebookSetting = () => fbdb.match()
	me.updateFacebookSetting = (setting) => fbdb.update(setting)

	// GOOGLE SETTING
	let ggdb = new Entity(realtime, pubsub, accKV, 'google_setting', 'business_location_id')
	me.fetchGoogleSetting = () => ggdb.list()
	me.matchGoogleSetting = () => ggdb.match()
	me.updateGoogleSetting = (setting) => ggdb.update(setting)

	// WIDGET SETTING
	let widgetSettingdb = new Entity(realtime, pubsub, accKV, 'widget_setting', 'account_id')
	me.fetchWidgetSetting = () => widgetSettingdb.list()
	me.matchWidgetSetting = (_) => widgetSettingdb.match()[api.getCred().account_id]
	me.updateWidgetSetting = (setting) => widgetSettingdb.update(setting)

	// DOMAIN
	let sitedb = new Entity(realtime, pubsub, accKV, 'site')
	realtime.subscribe([
		'bot_debug_end',
		'bot_debug_begin_action',
		'agent_presence_updated',
		'subiz_bill_updated',
		`login_session_updated.account.${account_id}.agent.${agent_id}`,
	]) // ignore result

	let cacheip = {}
	let geoipLock = {}

	// use to avoid batches request when render 30 users have same ip at the same time
	const geoip = async (ip, uid) => {
		if (!ip) return {}
		if (geoipLock[ip]) {
			await sb.sleep(100)
			return await geoip(ip, uid)
		}

		geoipLock[ip] = true

		if (cacheip[ip]) {
			geoipLock[ip] = false
			return cacheip[ip]
		}
		let {body, error} = await api.geoip(ip)
		geoipLock[ip] = false
		if (error) return {error}
		cacheip[ip] = body
		return body
	}
	me.geoip = geoip

	me.match_geoip = (ip) => cacheip[ip]

	me.fetchSites = (force) => sitedb.list(force)
	me.matchSite = (_) => sitedb.match()
	me.addSite = (domain) => sitedb.create(domain)
	me.removeSite = (domain) => sitedb.remove(domain)

	// POS
	let posdb = new Entity(realtime, pubsub, accKV, 'pos')
	me.fetchPOSes = (force) => posdb.list(force)
	me.matchPOS = (id) => posdb.match(id)
	me.createPOS = (pos) => posdb.create(pos)
	me.deletePOS = (id) => posdb.remove(id)
	me.updatePOS = (pos) => posdb.update(pos)

	me.updateShippingPolicy = api.update_shipping_policy
	me.createShippingPolicy = api.create_shipping_policy
	me.deleteShippingPolicy = api.delete_shipping_policy

	me.listProvinces = api.list_provices
	me.listDistricts = api.list_districts
	me.listWards = api.list_wards

	me.getSuggestedAddresses = api.suggest_address
	me.getSuggestedAddressDetail = api.get_address_detail

	me.listIntegratedShipping = () => api.list_integrated_shipping()
	me.createIntegratedShipping = api.create_integrated_shipping
	me.updateIntegratedShipping = api.update_integrated_shipping
	me.deleteIntegratedShipping = api.delete_integrated_shipping

	me.checkNumberConnection = api.check_number_connection
	me.sendGhnOTP = api.send_ghn_otp
	me.enterGhnOTP = api.enter_ghn_otp

	me.getShippingFee = api.get_shipping_fee
	me.sendOrderToShippingProvider = api.send_order_to_shipping_provider
	me.cancelShippingOrder = api.cancel_shipping_order
	me.printShippingOrders = api.print_shipping_orders

	// TAX
	let taxdb = new Entity(realtime, pubsub, accKV, 'tax')
	me.fetchTaxes = (force) => taxdb.list(force)
	me.matchTax = (id) => taxdb.match(id)
	me.createTax = (tax) => taxdb.create(tax)
	me.deleteTax = (id) => taxdb.remove(id)
	me.updateTax = (tax) => taxdb.update(tax)

	// CANCELLATION REASONS
	let ccdb = new Entity(realtime, pubsub, accKV, 'cancellation_code', 'code')
	me.fetchCancellationReasons = (force) => ccdb.list(force)
	me.matchCancellationReason = (code) => ccdb.match(code)
	me.createCancellationReason = (cc) => ccdb.create(cc)
	me.updateCancellationReason = (cc) => ccdb.update(cc)

	// PAYMENT METHOD
	let paymentmethoddb = new Entity(realtime, pubsub, accKV, 'payment_method')
	me.fetchPaymentMethods = (force) => paymentmethoddb.list(force)
	me.matchPaymentMethod = (id) => paymentmethoddb.match(id)
	me.createPaymentMethod = (pm) => paymentmethoddb.create(pm)
	me.deletePaymentMethod = (id) => paymentmethoddb.remove(id)
	me.updatePaymentMethod = (pm) => paymentmethoddb.update(pm)
	me.makeDefaultPaymentMethod = (id) => api.make_default_payment_method(id)

	let pipelinedb = new Entity(realtime, pubsub, accKV, 'pipeline')
	me.fetchPipelines = (force) => pipelinedb.list(force)
	me.matchPipeline = (id) => pipelinedb.match(id)
	me.createPipeline = (pm) => pipelinedb.create(pm)
	me.deletePipeline = (id) => pipelinedb.remove(id)
	me.updatePipeline = (pm) => pipelinedb.update(pm)
	me.makeDefaultPipeline = api.make_default_pipeline
	me.deletePipelineStage = api.delete_pipeline_stage
	me.makePipelinePreselect = api.make_pipeline_preselect

	// Lead view
	let leadviewdb = new Entity(realtime, pubsub, accKV, 'user_view')
	me.fetchUserView = (force) => leadviewdb.list(force)
	me.matchUserView = (id) => leadviewdb.match(id)
	me.addUserView = (lv) => leadviewdb.create(lv)
	me.removeUserView = (lvid) => leadviewdb.remove(lvid)
	me.updateUserView = (lv) => leadviewdb.update(lv)

	// PHONE DEVICES
	let phonedb = new Entity(realtime, pubsub, accKV, 'phone_device')
	me.fetchPhoneDevice = (force) => phonedb.list(force)
	me.getPhoneDeviceDetail = api.get_phone_device_detail
	me.matchPhoneDevice = (id) => phonedb.match(id)
	me.createPhoneDevice = (p) => phonedb.create(p)
	me.updatePhoneDevice = (p) => phonedb.update(p)
	me.deletePhoneDevice = (id) => phonedb.remove(id)

	// GREETING AUDIO
	let audiodb = new Entity(realtime, pubsub, accKV, 'greeting_audio')
	me.fetchGreetingAudio = (force) => audiodb.list(force)
	me.matchGreetingAudio = (id) => audiodb.match(id)
	me.createGreetingAudio = (p) => audiodb.create(p)
	me.updateGreetingAudio = (p) => audiodb.update(p)
	me.deleteGreetingAudio = (id) => audiodb.remove(id)

	let segmentdb = new Entity(realtime, pubsub, accKV, 'segment')
	me.fetchSegments = (force) => segmentdb.list(force)
	me.matchSegment = (id) => segmentdb.match(id)
	me.createSegment = (p) => segmentdb.create(p)
	me.updateSegment = (p) => segmentdb.update(p)
	me.deleteSegment = (id) => segmentdb.remove(id)
	me.addUserToSegment = api.add_user_to_segment
	me.removeUsersFromSegment = api.remove_user_from_segment

	let businessEmailAddressDb = new Entity(realtime, pubsub, accKV, 'business_email_address', 'address')
	me.fetchBusinessEmailAddresses = (force) => businessEmailAddressDb.list(force)
	me.matchBusinessEmailAddress = (id) => businessEmailAddressDb.match(id)
	me.createBusinessEmailAddress = (p) => businessEmailAddressDb.create(p)
	me.updateBusinessEmailAddress = (p, fields) => businessEmailAddressDb.update(p, fields)
	me.deleteBusinessEmailAddress = (id) => businessEmailAddressDb.remove(id)

	let campaigndb = new Entity(realtime, pubsub, accKV, 'campaign')
	me.fetchCampaign = (force) => campaigndb.list(force)
	me.matchCampaign = (id) => campaigndb.match(id)
	me.createCampaign = async (p) => {
		let {campaign, error} = await replaceCampaignBase64Images(p)
		if (error) return {error}
		p = campaign
		return campaigndb.create(p)
	}
	me.updateCampaign = async (p) => {
		let {campaign, error} = await replaceCampaignBase64Images(p)
		if (error) return {error}
		p = campaign
		return campaigndb.update(p)
	}
	me.deleteCampaign = (id) => campaigndb.remove(id)
	me.getCampaignReport = api.get_campaign_report
	me.listCampaignSendMessages = api.get_campaign_send_messages

	async function replaceCampaignBase64Images(campaign) {
		campaign = lo.cloneDeep(campaign)
		let channelMessages = campaign.messages || []

		for (let i = 0; i < channelMessages.length; i++) {
			let messages = lo.get(channelMessages, [i, 'messages'], [])
			for (let j = 0; j < messages.length; j++) {
				let message = messages[j]

				let {message: newMessage, error} = await replaceMessageBase64Images(message)
				if (error) {
					break
					return {error}
				}
				lo.set(campaign, ['messages', i, 'messages', j], newMessage)
			}
		}

		return {campaign}
	}

	function getAllCampaignBlobPaths(campaign) {
		let output = []
		lo.each(campaign.messages, (message, idx) => {
			let blobs = getAllMessageBlobPaths(message)
			blobs = lo.map(blobs, (blob) => {
				return {
					...blob,
					path: `messages.${idx}.${blob.path}`,
				}
			})
			output = [...output, ...blobs]
		})

		return output
	}

	let callsettingsdb = new Entity(realtime, pubsub, accKV, 'call_setting', 'number')
	me.fetchCallSettings = (force) => callsettingsdb.list(force)
	me.matchCallSetings = (id) => callsettingsdb.match(id)
	me.updateCallSettings = (p) => callsettingsdb.update(p)

	let znstemplatedb = new Entity(realtime, pubsub, accKV, 'zns_template', 'templateId')
	me.fetchZnsTemplate = (force) => znstemplatedb.list(force)
	me.matchZnsTemplate = (id) => znstemplatedb.match(id)

	// AGENT
	let agentdb = new Entity(realtime, pubsub, accKV, 'agent')
	me.fetchAgents = (f) => agentdb.list(f)
	me.fetchAgent = (id) => api.get_agent(id)
	me.matchAgent = (id) => {
		if (id == 'system' || id == 'subiz')
			return {
				id: id,
				fullname: 'Hệ thống',
			}

		if (!id) return agentdb.match()
		let ag = agentdb.match()[id]
		if (!ag) ag = {id: id, fullname: 'Không xác định'}
		return ag
	}

	me.updateAgent = (ag, fields) => agentdb.update(ag, fields)
	me.removeAgent = (id) => agentdb.remove(id)
	me.inviteAgent = (ag) => agentdb.create(ag)

	// NOTIFICATION
	me._noti_gate = new sb.Gate()
	realtime.onInterrupted(() => {
		me._noti_sync = false
		me.syncNoti()
	})
	me.syncNoti = async () => {
		me._noti_gate.close()
		// reset all noti category
		memKV.put('noti_order', {})
		memKV.put('noti_user', {})
		let topics = [
			`notification_created.account.${account_id}.agent.${agent_id}`,
			`notification_seen.account.${account_id}.agent.${agent_id}`,
		]
		if (process.env.ENV === 'desktop') {
			topics.push(`desktop_notification_pushed.account.${account_id}.agent.${agent_id}`)
		}
		await realtime.subscribe(topics)
		me.fetchNotis('order')
		me.fetchNotis('user')
		me._noti_sync = true
		me._noti_gate.open()
	}
	me.syncNoti()
	realtime.onEvent((ev) => {
		if (!me._noti_sync) return

		if (ev.type === 'desktop_notification_pushed') {
			// this.code is copy paste in push_notification_sw.js line 119
			//var eventData = ev.data
			const data = lo.get(ev, 'data.desktop_notification') || {}
			//if (data.account_id !== account_id || data.recipient_id !== agent_id) return true
			let noti = data
			let title = noti.title || 'Subiz'
			let body = noti.body || 'Bạn có cập nhật mới'
			//eventData.notification && eventData.notification.body
			//? eventData.notification.body
			//: ''
			var icon = noti.icon_url || ''
			data.url = (noti.data && noti.data.last_page_view_url) || ''
			let tag = data.account_id || '1'
			let requireInteraction = false
			if (data.type === 'message_sent') {
				tag = 'message_sent'
				requireInteraction = true
				title = `${title} gửi tin nhắn`
			} else if (data.type === 'conversation_invited') {
				tag = 'message_sent'
				requireInteraction = true
			} else if (data.type === 'user_campaign_converted') {
				tag = 'user_campaign_converted'
			} else if (data.type === 'user_returned' || data.type === 'user_first_visited') {
				tag = 'user_traffic'
			} else if (data.type === 'message_pong') {
				tag = 'message_pong'
			} else if (data.type === 'task_assigned') {
				tag = 'task_assigned'
				requireInteraction = true
			} else if (data.type === 'task_mentioned') {
				tag = 'task_mentioned'
				requireInteraction = true
			} else if (data.type === 'task_reminded') {
				tag = 'task_reminded'
				requireInteraction = true
			} else if (data.type === 'task_expired') {
				tag = 'task_expired'
				requireInteraction = true
			} else if (data.type == 'incoming_call') {
				tag = data.call_id
				title = 'Cuộc gọi tới: ' + data.caller_name
				body = data.caller_number
				icon = data.caller_avatar_url
				requireInteraction = true
			} else if (data.type == 'incoming_call_expired') {
				icon = data.caller_avatar_url
				title = 'Cuộc gọi kết thúc'
				body = data.caller_number
				requireInteraction = false
			}

			if (!icon) icon = 'https://vcdn.subiz-cdn.com/file/firntezftsyopuqcgmej-Group_6168_1.png'
			let notification = {
				title,
				tag: tag,
				body: body,
				icon: icon,
				data: data,
				requireInteraction: requireInteraction,
			}
			console.log('notification::::', ev, notification)
			if (window.electronAPI && window.electronAPI.showNoti && typeof window.electronAPI.showNoti === 'function') {
				window.electronAPI.showNoti(notification)
			}
		}

		if (ev.type === 'subiz_bill_updated') {
			console.log('subiz_bill_updated', ev)
			let bill = lo.get(ev, 'data.subiz_bill')
			if (!bill) return
			pubsub.publish('bill', bill)
			return
		}

		if (ev.type === 'notification_seen') {
			let category = lo.get(ev, 'data.notification.category')
			if (!category) return
			let noti = memKV.match('noti_' + category) || {}
			noti.unread = 0
			noti.last_seen = ev.created
			memKV.put('noti_' + category, noti)
			pubsub.publish('noti', category)
			return
		}

		if (ev.type === 'notification_created') {
			let notification = lo.get(ev, 'data.notification') || {}
			let category = notification.category || ''
			if (!category) return
			if (!notification.is_instant) {
				let noti = memKV.match('noti_' + category) || {}
				let oldlen = lo.size(noti.notifications)
				noti.notifications = me.joinNotis(noti.notifications, [notification])
				memKV.put('noti_' + category, noti)
				noti.unread = noti.unread || 0

				// estimated, not alway true
				if (lo.size(noti.notifications) > oldlen) noti.unread++
				pubsub.publish('noti', category)
			}
			let setting = lo.get(me.matchSettingNotify(), 'setting', {})
			if (sb.now() >= sb.getMs(setting.instant_mute_until)) {
				pubsub.publish('instant_noti', notification)
			}
		}
	})
	me.onNoti = (o, cb) => pubsub.on2(o, 'noti', cb)
	me.onBill = (o, cb) => pubsub.on2(o, 'bill', cb)
	me.matchNotis = (category) => memKV.match('noti_' + category)
	me.fetchNotis = async (category) => {
		if (!category) return
		await me._noti_gate.entry()
		let out = await api.listNotis(category, '')
		if (out.error) return {error: out.error}

		let body = out.body || {}
		let noti = memKV.match('noti_' + category) || {}
		if (!noti.next_anchor) noti.next_anchor = body.next_anchor

		noti.last_seen = body.last_seen
		noti.unread = body.unread
		noti.severity = body.severity
		noti.notifications = me.joinNotis(noti.notifications, out.body.notifications)
		memKV.put('noti_' + category, noti)
		pubsub.publish('noti', category)
		return out
	}
	me.fetchMoreNotis = async (category) => {
		if (!category) return
		await me._noti_gate.entry()

		let noti = memKV.match('noti_' + category) || {}
		let next_anchor = noti.next_anchor
		let out = await api.listNotis(category, next_anchor)
		if (out.error) return {error: out.error}

		// outdated
		noti = memKV.match('noti_' + category) || {}
		if (next_anchor != noti.next_anchor) return true

		let newnotis = lo.get(out.body, 'notifications') || []
		noti.next_anchor = lo.get(out.body, 'next_anchor', noti.next_anchor)
		noti.notifications = me.joinNotis(noti.notifications, newnotis)
		memKV.put('noti_' + category, noti)
		pubsub.publish('noti', category)
		return lo.size(out.body.notifications) > 0
	}

	me.seenNotis = (category) => api.seenNotis(category)
	me.joinNotis = (a, b) => {
		a = a || []
		b = b || []
		let notiM = {}
		lo.map(a.concat(b), (noti) => {
			if (!noti || !noti.created || !noti.checkpoint) return
			let key = noti.checkpoint + '-' + noti.topic
			let old = notiM[key] || {created: 0}
			if (noti.created < old.created) return
			notiM[key] = noti
		})

		return lo.orderBy(lo.map(notiM), ['created', 'topic'], ['desc', 'desc'])
	}

	me.readNotification = (category, checkpoint, topic) => api.readNoti(category, checkpoint, topic)

	// AGENT OWNER
	me.updateAgentOwner = (id) => api.update_agent_owner(id)

	me.getMemberViewing = (memberid, convoid) => {
		let now = Date.now()
		let found = lo.find(_viewingDb, (viewing, k) => {
			if (!k.startsWith(memberid + '.')) return false
			if (now - viewing.pinged > 12000) {
				delete _viewingDb[k] // keep viewingdb clean in long running tab
				return false
			}
			return viewing.last_seen_convo_id == convoid
		})
		if (found) return true

		// fallback to agent last_seen
		let ag = agentdb.match()[memberid] || {}
		let viewing = ag.last_seen || {}
		if (now - viewing.pinged > 11000) return false
		return viewing.last_seen_convo_id == convoid
	}

	let _viewingDb = {} // per tab
	realtime.onEvent((ev) => {
		if (ev.type === 'agent_presence_updated') {
			let agid = lo.get(ev, 'data.presence.user_id')
			let agent = me.matchAgent()[agid]
			if (!agent) return

			let lastconvo = ''
			if (agent.last_seen) lastconvo = agent.last_seen.last_seen_convo_id
			if (lastconvo) pubsub.publish('viewing_convo', lastconvo) // to stop it
			agent.last_seen = ev.data.presence
			let convoid = lo.get(ev, 'data.presence.last_seen_convo_id')
			let tabid = lo.get(ev, 'data.presence.browser_tab_id', '')
			let lastviewingconvo = lo.get(_viewingDb, [agid + '.' + tabid, 'last_seen_convo_id'], '')
			_viewingDb[agid + '.' + tabid] = lo.get(ev, 'data.presence')
			if (lastviewingconvo) pubsub.publish('viewing_convo', convoid) // to stop it

			if (convoid) {
				pubsub.publish('viewing_convo', convoid) // to stop it
				setTimeout(() => pubsub.publish('viewing_convo', convoid), 12000) // try to let client know to clear viewing
			}

			agentdb.justUpdate(agent)
			return
		}

		if (ev.type === 'login_session_updated') {
			let session = lo.get(ev, 'data.login_session') || {}
			if (session.state == 'ended' && session.access_token == access_token) {
				return parent.logout()
			}
			return pubsub.publish('loginsession')
		}
	})

	let current_call_id = ''
	me.getCurrentCall = () => {
		if (current_call_id) {
			let call = webrtcconn.matchCall(current_call_id)
			if (call && call.status != 'ended') return call
		}

		let myOutgoingCall = lo.find(
			webrtcconn.matchCall(),
			(call) =>
				(call.status == 'dialing' || call.status == 'active') &&
				(call.direction == 'outbound' || call.direction == 'outgoing'),
		)
		if (myOutgoingCall) {
			current_call_id = myOutgoingCall.call_id || ''
			return myOutgoingCall
		}

		let dialingIncomingCall = lo.find(
			webrtcconn.matchCall(),
			(call) => call.status == 'dialing' && (call.direction == 'incoming' || call.direction == 'inbound'),
		)
		if (dialingIncomingCall) {
			current_call_id = dialingIncomingCall.call_id || ''
			return dialingIncomingCall
		}

		current_call_id = ''
		return undefined
	}

	me.matchWebcallCall = (callid) => webrtcconn.matchCall(callid)

	me.onWebCallStatus = (o, cb) => pubsub.on2(o, 'call_status', cb) // dialing - hangup - answered
	me.onWebPhoneStatus = (o, cb) => pubsub.on2(o, 'webphone_status', cb) // not-ready

	me.sendDTMF = (key, callid) => webrtcconn.sendDtmf(key, callid)
	me.transferWebCall = async (number, callid) => webrtcconn.transferCall(number, callid)
	me.hangUpWebCall = (callid) => webrtcconn.hangupCall(callid)

	me.isMicAllowed = async () => {
		return await me.checkMic().result
	}
	me.getMicroStream = () => parent.micStream
	me.checkMic = () => {
		const timeout = new Promise((rs, rj) => setTimeout(rs, 500, 'Not_authorized'))
		let microPermission = parent.getMicroPermissions()
		return {
			timeout: Promise.race([timeout, microPermission]),
			result: parent.getMicroPermissions(),
		}
	}

	parent.micStream = undefined
	parent.getMicroPermissions = async () => {
		try {
			parent.micStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
		} catch (err) {
			console.log('REJECT VIDEO PERMISSSIONN', err)
			parent.micStream = undefined
			return undefined
		}
		return parent.micStream
	}

	me.matchWebcallCall = (callid) => webrtcconn.matchCall(callid)

	me.testMic = async () => {
		parent.micStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
	}

	// mobile only
	let beforeCall = async () => {
		if (!window.SoundMgr) return
		await window.SoundMgr.beforeStartCall()
	}

	me.makeWebCall = async (number, fromnumber, campaignid = '', outboundcallentryid = '') => {
		await beforeCall()

		// 11edc52b-2918-4d71-9058-f7285e29d894
		let callid = uuid.v4() // must be uuid or IOS callkeep wont work, see https://github.com/react-native-webrtc/react-native-callkeep/issues/125
		current_call_id = callid
		console.log('makeWebCall', campaignid, outboundcallentryid)
		let {body, error} = await webrtcconn.makeCall(
			number,
			fromnumber,
			new Promise(async (rs, rj) => {
				let stream = await parent.getMicroPermissions()
				if (stream && stream.error) return rj(stream.error)
				rs(stream)
			}),
			callid,
			campaignid,
			outboundcallentryid,
		)
		if (error) return {error}
		return body
	}

	me.answerWebCall = async (callid) => {
		await beforeCall()
		let stream = await parent.getMicroPermissions()
		current_call_id = callid
		let {body, error} = await webrtcconn.answerCall(callid, stream)
		if (error) return {error}
		return body
	}

	realtime.subscribe(['bot_debug_end', 'bot_debug_begin_action', 'agent_presence_updated']) // ignore result
	// AGENT GROUP
	let agentGroupDB = new Entity(realtime, pubsub, accKV, 'agent_group')
	me.fetchAgentGroups = (f) => agentGroupDB.list(f)
	me.matchAgentGroup = (_) => agentGroupDB.match()
	me.updateAgentGroup = (ag) => agentGroupDB.update(ag)
	me.removeAgentGroup = (id) => agentGroupDB.remove(id)
	me.addAgentGroup = (ag) => agentGroupDB.create(ag)

	// product collection
	let productCollectionDB = new Entity(realtime, pubsub, accKV, 'product_collection')
	me.fetchProductCollections = (f) => productCollectionDB.list(f)
	me.matchProductCollection = (_) => productCollectionDB.match()
	me.updateProductCollection = (pc) => productCollectionDB.update(pc)
	me.removeProductCollection = (id) => productCollectionDB.remove(id)
	me.addProductCollection = (pc) => productCollectionDB.create(pc)

	// RULE
	let ruledb = new Entity(realtime, pubsub, accKV, 'rule')
	me.fetchRules = () => ruledb.list()
	me.matchRule = (_) => ruledb.match()
	me.updateRule = (rule) => ruledb.update(rule)
	me.addRule = (rule) => ruledb.create(rule)
	me.removeRule = (id) => ruledb.remove(id)

	me.fetchApikeys = () => api.listApikey()
	me.createApikey = (apikey) => api.createApikey(apikey)
	me.fetchLoginSessions = () => api.listLoginSession()
	me.logoutSession = (id) => api.logoutSession(id)

	// Automation
	let automationDB = new Entity(realtime, pubsub, accKV, 'automation')
	me.fetchAutomations = () => automationDB.list()
	me.matchAutomation = (_) => automationDB.match()
	me.updateAutomation = (rule) => automationDB.update(rule)
	me.addAutomation = (rule) => automationDB.create(rule)
	me.removeAutomation = (id) => automationDB.remove(id)

	// TAG
	let tagdb = new Entity(realtime, pubsub, accKV, 'tag')
	me.fetchTags = () => tagdb.list()
	me.matchTag = (_) => tagdb.match()
	me.updateTag = (tag) => tagdb.update(tag)
	me.createTag = (tag) => tagdb.create(tag)
	me.removeTag = (id) => tagdb.remove(id)

	// ATTRIBUTE
	let attrdb = new Entity(realtime, pubsub, accKV, 'user_attribute', 'key')
	me.fetchUserAttributes = () => attrdb.list()
	me.matchUserAttribute = (_) => attrdb.match()
	me.updateUserAttribute = (attr) => attrdb.update(attr)
	me.addUserAttribute = (attr) => attrdb.create(attr)
	me.removeUserAttribute = (id) => attrdb.remove(id)

	// LABELS
	let labeldb = new Entity(realtime, pubsub, accKV, 'label', 'id')
	me.fetchUserLabels = (f) => labeldb.list(f)
	me.matchUserLabel = (_) => labeldb.match()
	me.updateUserLabel = (label) => labeldb.update(label)
	me.removeUserLabel = async (id) => {
		let {error: err} = await api.delete_label2(id)
		if (err) return {error: err}
		return {}
	}

	// FABIKON PAGES
	me.getCodeChallenge = () => api.get_zalo_challenge()

	// Incomming Email
	let incommingEmailDB = new Entity(realtime, pubsub, accKV, 'incomming_email', 'email')
	me.fetchIncommingEmails = () => incommingEmailDB.list()
	me.matchIncommingEmail = (_) => incommingEmailDB.match()
	me.addIncommingEmail = (page) => incommingEmailDB.create(page)
	me.removeIncommingEmail = (id) => incommingEmailDB.remove(id)

	// Conversation Modal
	let convoModalDB = new Entity(realtime, pubsub, accKV, 'conversation_modal')
	me.fetchConversationModals = () => convoModalDB.list()
	me.matchConversationModal = () => convoModalDB.match()
	me.createConversationModal = (p) => convoModalDB.create(p)
	me.updateConversationModal = (p) => convoModalDB.update(p)
	me.removeConversationModal = (id) => convoModalDB.remove(id)
	me.pickConversationModal = (cid, mid, text, uid) => api.pickConversationModal(cid, mid, text, uid)

	// Message Template
	let messageTemplateDB = new Entity(realtime, pubsub, accKV, 'message_template')
	me.applyMessage = (ev) => api.apply_message(ev)
	me.fetchMessageTemplates = (_) => messageTemplateDB.list()
	me.matchMessageTemplate = (_) => messageTemplateDB.match()
	me.addMessageTemplate = async (message) => {
		let {message: newMessage, error} = await replaceMessageBase64Images(message.message)
		if (error) return {error: 'invalid_attachment_url'}
		message.message = newMessage
		return messageTemplateDB.create(message)
	}
	me.updateMessageTemplate = async (message) => {
		let {message: newMessage, error} = await replaceMessageBase64Images(message.message)
		if (error) return {error: 'invalid_attachment_url'}
		message.message = newMessage

		return messageTemplateDB.update(message)
	}
	me.removeMessageTemplate = (id) => messageTemplateDB.remove(id)

	async function replaceMessageBase64Images(message) {
		message = lo.cloneDeep(message)
		let delta = sb.parseJSON(message.quill_delta)
		let blobs = getAllMessageBlobPaths(message)

		let error = ''
		if (message.format === 'html') {
			let htmlString = message.text || ''
			// dont need to sanitize, see https://stackoverflow.com/questions/37554623/can-a-new-domparser-parsefromstring-be-safer-than-createelement
			// htmlString = DOMPurify.sanitize(htmlString, {ALLOW_UNKNOWN_PROTOCOLS: true})

			const parser = new DOMParser()
			const dom = parser.parseFromString(htmlString, 'text/html')
			const $imgs = dom.querySelectorAll('img')

			let hasBlob = false
			for (let i = 0; i < lo.size($imgs); i++) {
				let $img = $imgs[i]
				let url = $img.getAttribute('src')
				if (!isBlobUrl(url) && !isDataUrl(url)) continue
				hasBlob = true
				let file
				if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
				if (isDataUrl(url)) file = await sb.dataURLToFile(url)
				let res = await common.uploadLocalFile(file)
				if (res.error) {
					error = res.error
				}

				$img.setAttribute('src', res.url)
				$img.setAttribute('subizimage', 'true')
			}
			if (hasBlob) {
				lo.set(message, 'text', dom.body.innerHTML)
			}
			console.log('uploaded base64 imgggggg', message.text)
		}

		await flow.map(blobs, 5, async (blob) => {
			let {url, path, type} = blob
			let file
			if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
			if (isDataUrl(url)) file = await sb.dataURLToFile(url)
			let res = await common.uploadLocalFile(file)
			if (res.error) {
				error = res.error
			}
			let newUrl = res.url
			if (type === 'attachment') {
				lo.set(message, path, newUrl)
			} else if (type === 'quill_delta') {
				lo.set(delta, `ops.${path}`, newUrl)
			}
		})
		if (delta) {
			lo.set(message, 'quill_delta', JSON.stringify(delta))
		}

		return {message, error}
	}

	function getAllMessageBlobPaths(message) {
		let output = []

		let attachments = lo.get(message, 'attachments')
		lo.each(attachments, (att, attIndex) => {
			if (att.type === 'generic') {
				lo.each(att.elements, (ele, eleIndex) => {
					if (isBlobUrl(ele.image_url) || isDataUrl(ele.image_url)) {
						output.push({
							path: `attachments.${attIndex}.elements.${eleIndex}.image_url`,
							url: ele.image_url,
							type: 'attachment',
						})
					}
				})
			} else {
				if (isBlobUrl(att.url) || isDataUrl(att.url)) {
					output.push({
						path: `attachments.${attIndex}.url`,
						url: att.url,
						type: 'attachment',
					})
				}
			}
		})

		let delta = sb.parseJSON(message.quill_delta) || {ops: []}
		lo.each(delta.ops, (op, idx) => {
			let img = lo.get(op, 'insert.image')
			if (!img) return // continue loop
			if (isBlobUrl(img) || isDataUrl(img)) {
				output.push({
					path: `${idx}.insert.image`,
					url: img,
					type: 'quill_delta',
				})
			}
		})
		return output
	}

	// WEBHOOK
	let webhookdb = new Entity(realtime, pubsub, accKV, 'webhook')
	me.fetchWebhooks = (force) => webhookdb.list(force)
	me.matchWebhook = (_) => webhookdb.match()
	me.updateWebhook = (wh) => webhookdb.update(wh)
	me.removeWebhook = (id) => webhookdb.remove(id)
	me.createWebhook = (wh) => webhookdb.create(wh)
	me.listWebhookDeliveries = (id) => api.list_webhook_deliveries(id)

	let webhookdeliverydb = new Entity(realtime, pubsub, accKV, 'webhook_delivery')
	me.fetchLastWebhookDeliveries = () => webhookdeliverydb.list(true)
	me.matchLastWebhookDeliveries = () => webhookdeliverydb.match()
	me.fetchDelivery = (id, did) => api.get_webhook_delivery(id, did)

	// Bot
	let botDB = new Entity(realtime, pubsub, accKV, 'bot')
	me.fetchBots = (force) => botDB.list(force)
	me.matchBot = (_) => botDB.match()
	me.createBot = (bot) => botDB.create(bot)
	me.updateBot = async (bot) => {
		let {bot: newBot, error} = await replaceBotBase64Images(bot)
		if (error) return {error: 'invalid_attachment_url'}
		bot = newBot
		return botDB.update(bot)
	}
	me.removeBot = (id) => botDB.remove(id)
	me.testBot = (p) => api.test_bot(p)
	me.testBot2 = (p) => api.test_bot_2(p)
	me.reportBot = (p) => api.report_bot(p)
	me.reportBotNew = api.report_bot_new
	me.reportBotActions = api.report_bot_actions
	me.updateBotState = async (p) => {
		let {body, error} = await api.update_bot_state(p)
		if (error) return {error}
		return botDB.justUpdate(body)
	}

	async function replaceBotBase64Images(bot) {
		bot = lo.cloneDeep(bot)
		let blobs = getAllBotBlobPaths(bot)

		if (isBlobUrl(bot.avatar_url) || isDataUrl(bot.avatar_url)) {
			blobs.push({path: 'avatar_url', url: bot.avatar_url})
		}
		let error = ''
		await flow.map(blobs, 5, async (blob) => {
			let {url, path} = blob
			let file
			if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
			if (isDataUrl(url)) file = await sb.dataURLToFile(url)
			let res = await common.uploadLocalFile(file)
			if (res.error) {
				error = res.error
			}
			let newUrl = res.url
			lo.set(bot, path, newUrl)
		})

		return {bot, error}
	}

	function getAllBotBlobPaths(bot) {
		let output = []
		let nodes = [{node: bot, currentActionPath: 'action'}]
		while (true) {
			let nexts = []
			lo.each(nodes, ({node, currentActionPath}) => {
				let resumeMessage = lo.get(node, 'action.ask_question.resume_message', {})
				lo.each(resumeMessage.attachments, (att) => {
					if (att.type === 'generic') {
						lo.each(att.elements, (ele, eleIndex) => {
							if (isBlobUrl(ele.image_url) || isDataUrl(ele.image_url)) {
								output.push({
									path: `${currentActionPath}.ask_question.resume_message.attachments.${attIndex}.elements.${eleIndex}.image_url`,
									url: ele.image_url,
								})
							}
						})
					} else {
						if (isBlobUrl(att.url) || isDataUrl(att.url)) {
							output.push({
								path: `${currentActionPath}.ask_question.messages.${mIdx}.attachments.${attIndex}.url`,
								url: att.url,
							})
						}
					}
				})

				let messages = lo.get(node, 'action.ask_question.messages')
				lo.each(messages, (message, mIdx) => {
					let attachments = lo.get(message, 'attachments')
					lo.each(attachments, (att, attIndex) => {
						if (att.type === 'generic') {
							lo.each(att.elements, (ele, eleIndex) => {
								if (isBlobUrl(ele.image_url) || isDataUrl(ele.image_url)) {
									output.push({
										path: `${currentActionPath}.ask_question.messages.${mIdx}.attachments.${attIndex}.elements.${eleIndex}.image_url`,
										url: ele.image_url,
									})
								}
							})
						} else {
							if (isBlobUrl(att.url) || isDataUrl(att.url)) {
								output.push({
									path: `${currentActionPath}.ask_question.messages.${mIdx}.attachments.${attIndex}.url`,
									url: att.url,
								})
							}
						}
					})
				})
				let action = node.action || {}
				lo.each(action.nexts, (next, nextIdx) => {
					nexts.push({node: next, currentActionPath: currentActionPath + `.nexts.${nextIdx}.action`})
				})
			})
			if (nexts.length === 0) break
			nodes = nexts
		}
		return output
	}

	function isBlobUrl(url = '') {
		return url.startsWith('blob')
	}

	function isDataUrl(url = '') {
		return url.startsWith('data:')
	}

	// Web checks
	let webcheckDB = new Entity(realtime, pubsub, accKV, 'webcheck')
	me.fetchWebcheck = async (f) => {
		await webcheckDB.list(f)
	}

	me.matchWebcheck = (_) => webcheckDB.match()
	me.createWebcheck = (wc) => webcheckDB.create(wc)
	me.updateWebcheck = (wc) => webcheckDB.update(wc)
	me.removeWebcheck = (id) => webcheckDB.remove(id)
	me.getDetailWebcheck = api.get_detail_webcheck
	me.getDailySummaryWebcheck = api.get_summary_webcheck

	// Web plugin template
	let webPluginTemplateDB = new Entity(realtime, pubsub, accKV, 'web_plugin_template')
	me.fetchWebPluginTemplates = (force) => webPluginTemplateDB.list(force)
	me.matchWebPluginTemplate = (_) => webPluginTemplateDB.match()
	me.updateWebPluginTemplate = (plugin) => webPluginTemplateDB.update(plugin)
	me.removeWebPluginTemplate = (id) => webPluginTemplateDB.remove(id)
	me.updateWebPluginState = async (p) => {
		let {body, error} = await api.update_web_plugin_state(p)
		if (error) return {error}
		return webPluginDB.justUpdate(body)
	}

	let notifProfileDB = new Entity(realtime, pubsub, accKV, 'notif_profiles', 'avatar_url')
	me.fetchNotifProfiles = (force) => notifProfileDB.list(force)
	me.matchNotifProfiles = (_) => notifProfileDB.match()

	// Web plugin
	let webPluginDB = new Entity(realtime, pubsub, accKV, 'web_plugin')
	me.fetchWebPlugins = (force) => webPluginDB.list(force)
	me.matchWebPlugin = (_) => webPluginDB.match()
	me.fetchWebPluginX = (accid) => api.list_web_plugin_x(accid)
	me.updateWebPluginX = (p) => api.update_web_plugin_x(p)

	me.createWebPlugin = async (plugin) => {
		let hasEmailNotif = lo.get(plugin, 'conversion_notification.enabled')
		if (!hasEmailNotif) return webPluginDB.create(plugin)

		let message = lo.get(plugin, 'conversion_notification.user_email', {})
		let {message: newMessage, error} = await replaceMessageBase64Images(message)
		if (error) return {error: 'invalid_attachment_url'}
		lo.set(plugin, 'conversion_notification.user_email', newMessage)
		return webPluginDB.create(plugin)
	}
	me.updateWebPlugin = async (plugin) => {
		let hasEmailNotif = lo.get(plugin, 'conversion_notification.enabled')
		if (!hasEmailNotif) return webPluginDB.update(plugin)

		let message = lo.get(plugin, 'conversion_notification.user_email', {})
		let {message: newMessage, error} = await replaceMessageBase64Images(message)
		if (error) return {error: 'invalid_attachment_url'}
		lo.set(plugin, 'conversion_notification.user_email', newMessage)
		return webPluginDB.update(plugin)
	}
	me.removeWebPlugin = (id) => webPluginDB.remove(id)
	me.reportWebplugin = (p) => api.report_web_plugin(p)
	me.getWebpluginConversions = api.get_web_plugin_conversions
	me.updateWebPluginState = async (p) => {
		let {body, error} = await api.update_web_plugin_state(p)
		if (error) return {error}
		return webPluginDB.justUpdate(body)
	}

	me.summaryCampaign = (id) => {
		let campaignSummary = accKV.match('campaignSummary_' + id) || {}
		let isExpired = lo.get(campaignSummary, 'expired', 0) < Date.now()
		if (!isExpired) return campaignSummary

		// so we dont call api multiple time
		campaignSummary.expired = Date.now() + 60000
		accKV.put('campaignSummary_' + id, campaignSummary)

		setTimeout(async () => {
			let {body, error} = await api.summary_campaign(id)
			if (error) {
				campaignSummary = accKV.match('campaignSummary_' + id) || {}
				campaignSummary.expired = 0
				accKV.put('campaignSummary_' + id, campaignSummary)
				return
			}

			campaignSummary = body
			campaignSummary.expired = Date.now() + 60000
			accKV.put('campaignSummary_' + id, campaignSummary)
		})

		return campaignSummary
	}

	// Uploaded image
	let uploadedImageDB = new Entity(realtime, pubsub, accKV, 'uploaded_image', 'url')
	me.fetchUploadedImages = (_) => uploadedImageDB.list()
	me.matchUploadedImage = (_) => uploadedImageDB.match()
	me.addUploadedImage = (url) => uploadedImageDB.create(url)
	me.removeUploadedImage = (url) => uploadedImageDB.remove(url)

	// integration
	let inteDb = new Entity(realtime, pubsub, accKV, 'integration')
	me.fetchIntegrations = () => inteDb.list(true)
	me.matchIntegration = (_) => inteDb.match()
	me.removeIntegration = (id) => inteDb.remove(id)
	me.updateIntegration = (inte) => inteDb.update(inte)
	me.validateEmailIntegration = api.validate_email_integration

	me.addFacebookPage = (accesstoken, pageids, comments) => api.update_facebook_page(accesstoken, pageids, comments)

	me.addInstagramPage = (accesstoken, pageids, comments) =>
		api.update_facebook_page(accesstoken, pageids, comments, true)

	me.getFbPagesFromAccessToken = async (accesstoken) => {
		let url =
			'https://graph.facebook.com/v16.0/me/accounts?limit=50&fields=id,name,picture,about,description,website,access_token,instagram_business_account%7Bid,username,profile_picture_url,name,biography,ig_id,followers_count,follows_count,media_count,website%7D&access_token=' +
			accesstoken
		let pages = []
		for (;;) {
			let out = await ajax.setParser('json').get(url)
			if (!out) break
			if (out.error) return {error: out.error}
			url = lo.get(out.body, 'paging.next')
			pages = pages.concat(out.body.data)
			if (!url) break
		}
		return {pages}
	}

	me.getIgPagesFromAccessToken = async (accesstoken) => {
		let out = await me.getFbPagesFromAccessToken(accesstoken)
		if (out.error) return out

		let pages = lo.filter(out.pages, (p) => p.instagram_business_account)
		return {pages}
	}

	// BLACK LIST IP
	let blackListIpDB = new Entity(realtime, pubsub, accKV, 'black_list_ip', 'ip')
	me.fetchBlackListIps = () => blackListIpDB.list()
	me.matchBlackListIp = (_) => blackListIpDB.match()
	me.addBlackListIp = (ip) => blackListIpDB.create(ip)
	me.removeBlackListIp = (ip) => blackListIpDB.remove(ip)

	// BLACK LIST USER
	let blackListUserDB = new Entity(realtime, pubsub, accKV, 'black_list_user', 'user_id')
	me.fetchBlackListUsers = () => blackListUserDB.list()
	me.matchBlackListUser = (_) => blackListUserDB.match()
	me.addBlackListUser = (ip) => blackListUserDB.create(ip)
	me.removeBlackListUser = (ip) => blackListUserDB.remove(ip)

	// WHITE LIST DOMAIN
	let whiteListDomainDB = new Entity(realtime, pubsub, accKV, 'white_list_domain', 'domain')
	me.fetchWhiteListDomains = () => whiteListDomainDB.list()
	me.matchWhiteListDomain = (_) => whiteListDomainDB.match()
	me.addWhiteListDomain = (domain) => whiteListDomainDB.create(domain)
	me.removeWhiteListDomain = (domain) => whiteListDomainDB.remove(domain)

	// SETTING NOTIFY
	let settingNotifyDB = new Entity(realtime, pubsub, accKV, 'setting_notify')
	me.fetchSettingNotify = () => settingNotifyDB.list()
	me.matchSettingNotify = (_) => settingNotifyDB.match()
	me.updateSettingNotify = (notify) => settingNotifyDB.update(notify)

	// BLOCK EMAIL
	let blockEmailDB = new Entity(realtime, pubsub, accKV, 'blocked_emails')
	me.fetchBlockEmails = (force) => blockEmailDB.list(force)
	me.matchBlockEmail = (_) => blockEmailDB.match()
	me.findBlockEmails = async (id) => {
		let {anchor, body, error} = await api['list_blocked_emails'](id)
		if (error) return {error}

		let objM = body
		let old = me.matchBlockEmail()
		await accKV.put('blocked_emails', objM)
		if (!lo.isEqual(old, objM)) pubsub.publish('account')
		return objM
	}
	me.createBlockEmail = (p) => blockEmailDB.create(p)
	me.removeBlockEmail = (id) => blockEmailDB.remove(id)

	// BLOCK EMAIL
	let bounceEmailDB = new Entity(realtime, pubsub, accKV, 'bounced_emails')
	me.fetchBounceEmails = (force) => bounceEmailDB.list(force)
	me.matchBounceEmail = (_) => bounceEmailDB.match()
	me.findBounceEmails = async (id) => {
		let {anchor, body, error} = await api['list_bounced_emails'](id)
		if (error) return {error}

		let objM = body
		let old = me.matchBounceEmail()
		await accKV.put('bounced_emails', objM)
		if (!lo.isEqual(old, objM)) pubsub.publish('account')
		return objM
	}
	me.removeBounceEmail = (id) => bounceEmailDB.remove(id)

	// BLOCK NUMBER
	let blockNumberDB = new Entity(realtime, pubsub, accKV, 'block_number', 'number')
	me.fetchBlockNumbers = () => blockNumberDB.list()
	me.matchBlockNumber = () => blockNumberDB.match()
	me.createBlockNumber = (p) => blockNumberDB.create(p)
	me.removeBlockNumber = (id) => blockNumberDB.remove(id)

	let convoAutomationDB = new Entity(realtime, pubsub, accKV, 'conversation_automation')
	me.fetchConvoAutomations = () => convoAutomationDB.list()
	me.matchConvoAutomation = () => convoAutomationDB.match()

	me.onViewingConvo = (o, cb) => pubsub.on2(o, 'viewing_convo', cb)
	me.onLoginSession = (o, cb) => pubsub.on2(o, 'loginsession', cb)
	me.onAccount = (o, cb) => pubsub.on2(o, 'account', cb)
	me.onAccount2 = (cb) => pubsub.on('account', cb)
	me.onRoute = (o, cb) => pubsub.on2(o, 'route', cb)
	me.onInstantNoti = (o, cb) => pubsub.on2(o, 'instant_noti', cb)
	me.onSettingHellobar = (o, cb) => pubsub.on2(o, 'setting_hellobar', cb)

	me.makeCall = (p) => {
		let param = lo.cloneDeep(p)
		param.touchpoint.id = param.touchpoint.id.replace(/\W/g, '')
		return api.make_call(param)
	}
	me.sendZaloRequestInfo = api.send_zalo_request_info
	me.checkUserAllowZccCall = api.check_user_allow_zcc_call
	me.sendZccCallPermission = api.send_allow_zcc_call

	let $audio = null
	// create audio tag to play webcall
	let createAudioTag = () => {
		if ($audio) return
		if (!document.createElement) return // mobile

		$audio = document.createElement('audio')
		$audio.id = 'web_call_audio'
		$audio.style = 'display: none'
		$audio.autoplay = 'autoplay'
		document.body.appendChild($audio)
	}
	createAudioTag()

	let webrtcconn = new WebRTCConn({
		accid: account_id,
		agid: agent_id,
		access_token: api.getCred().access_token,
		realtime,
		env: window,
		onEvent: async (ev) => {
			// publish call status event
			if ((ev.type || '').startsWith('call')) {
				let callid = lo.get(ev, 'data.call_info.call_id')
				if (!callid) return

				let direction = lo.get(ev, 'data.call_info.direction')
				let number = lo.get(ev, 'data.call_info.from_number', '')

				if (direction === 'outgoing' || direction == 'outbound') number = lo.get(ev, 'data.call_info.to_number')
				let info = (await store.fetchCallInfo(number)) || {}

				ev = lo.cloneDeep(ev)
				lo.set(ev, 'data.call_info.caller_info', info)
				pubsub.publish('call_status', ev)
			}
		},
		onTrack: (event) => {
			if (event.track.kind != 'audio') return
			if ($audio) $audio.srcObject = event.streams[0]
		},
		collect: (metric, callid, ts) => {
			if (metric == 'join_call' || metric == 'listen_call' || metric == 'transfer_call') {
				api.collect('webrtc_connection_time', ts)
			}
		},
	})
	window.webrtcconn = webrtcconn

	me.createUserAccessToken = api.createUserAccessToken

	me.recordSearch = (query) => {
		let qs = accKV.match('search_query') || {}
		qs[query] = {query, at: sb.now()}
		if (lo.size(qs) > 20) {
			// trim the last latest 20 queries
			let newqs = {}
			lo.take(lo.orderBy(lo.map(qs), ['at', 'query'], ['desc', 'asc']), 20).map((q) => {
				if (!q) return
				newqs[q.query] = q.at
			})
			qs = newqs
		}

		accKV.put('search_query', qs)
	}
	me.matchSearchQuery = () =>
		lo.map(
			lo.orderBy(
				lo.map(accKV.match('search_query')).filter((v) => !!v),
				'at',
				'desc',
			),
			'query',
		)

	me.me = () => {
		if (!api.getCred()) return {}
		let {agent_id, account_id} = api.getCred()

		let agent = me.matchAgent()[agent_id] || {agent_id, account_id, id: agent_id}
		agent.account = accdb.match()[account_id] || {id: account_id}
		return agent
	}

	me.canViewPopupReport = (plugin) => {
		const APPO_ACCOUNT_ID = 'acqpgfjvysssmqmahpkb' // temp hot fix for appo, they dont want agent see popup report of anotther

		if (!api.getCred()) return false
		let {agent_id, account_id} = api.getCred()
		if (account_id !== APPO_ACCOUNT_ID) return true

		let agent = me.matchAgent()[agent_id] || {id: agent_id}
		let scopes = agent.scopes || []
		let isAdmin = scopes.includes('account_manage') || scopes.includes('account_setting')

		if (isAdmin) return true
		let allAgents = lo.get(plugin, 'conversion_notification.all_agents')
		let assignees = lo.get(plugin, 'conversion_notification.agents', [])
		return allAgents || assignees.includes(agent_id)
	}

	me.search_sub = (keyword) => api.search_sub(keyword)
	me.fetch_sub = (accid) => api.fetch_sub(accid)
	me.acc_bank_transfer = (accid, credit_id, amount, description) =>
		api.acc_bank_transfer(accid, credit_id, amount, description)
	me.acc_delete_agent = (ag) => api.acc_delete_agent(ag)
	me.acc_update_invoice = api.acc_update_invoice
	me.create_invoice = (accid, invoice) => api.create_invoice(accid, invoice)
	me.acc_update_agent_state = (ag) => api.acc_update_agent_state(ag)
	me.acc_fetch_invoice = (accid, creditid) => api.acc_fetch_invoice(accid, creditid)
	me.acc_fetch_sites = (accid, creditid) => api.acc_fetch_sites(accid)
	me.sendZns = api.sendMsgEvent
	me.getFieldsByView = api.get_fields_by_view
	me.importLeads = api.import_leads
	me.acc_fetch_inte = (accid) => api.acc_fetch_inte(accid)
	me.convert_invoice_html = (accid, invoice) => api.convert_invoice_html(accid, invoice)
	me.list_invoice_comments = (accid, topic) => api.list_invoice_comments(accid, topic)
	me.download_invoice = (accid, invoiceid) => api.download_invoice(accid, invoiceid)
	me.post_invoice_comment = (accid, topic, content) => api.post_invoice_comment(accid, topic, content)
	me.acc_update_agent = (ag, fields) => api.update_agent(ag, fields)
	me.requestInvoice = api.request_invoice
	me.listBills = api.list_bills
	me.listCredits = api.list_credits
	me.shortenPayment = api.shorten_payment
	me.fetchCreditLogs = api.fetch_credit_logs
	me.fetchCreditReports = api.fetch_credit_reports

	me.isUserStageDisplayed = () => {
		return true
		const ACCOUNTIDS = ['acpxkgumifuoofoosble']
		return ACCOUNTIDS.includes(account_id)
	}
	return me
}

// handler: {
//  update:
//  create:
//  list:
//  onEvent: ev => {}
//}
function Entity(realtime, pubsub, kv, name, key) {
	if (!key) key = 'id'
	let syncStatus = 'offline' // 'online'
	let restinterval = 2000 // 60 sec

	let fetchQueue = new flow.batch(2000, restinterval, async () => {
		await this.list()
		return []
	})

	let updateTopic = name + '_updated'
	let createTopic = name + '_created'
	let removeTopic = name + '_deleted'
	realtime.onEvent((ev) => {
		let obj = lo.get(ev, ['data', name])
		if (!obj) return
		switch (ev.type) {
			case updateTopic:
			case createTopic:
				if (name === 'campaign') {
					console.log('onEventttttttttt', obj, ev.type)
				}
				return this.justUpdate(obj)
			case removeTopic:
				// just refetch
				return this.list(true)
		}
	})

	realtime.onInterrupted(() => {
		syncStatus = 'offline'
	})
	realtime.subscribe([updateTopic, removeTopic]) // ignore result

	this.match = () => {
		if (syncStatus !== 'online') fetchQueue.push()
		return kv.match(name) || {}
	}
	this.clearAnchor = async (obj) => {
		_anchor = ''
	}

	this.cleanMem = async (obj) => {
		let id = lo.get(obj, key)
		if (!id) return
		let old = kv.match(name) || {}
		delete old[id]
		await kv.put(name, old)
	}

	this.justUpdate = async (obj) => {
		let id = lo.get(obj, key)
		if (!id) return this.list()
		let old = kv.match(name) || {}
		old[id] = obj
		await kv.put(name, old)
		pubsub.publish('account', {object_type: name, data: obj})
		return obj
	}

	this.update = async (params, fields) => {
		let {body: obj, error: err} = await api['update_' + name](params, fields)
		if (err) return {error: err, body: obj}

		await this.justUpdate(obj)
		return lo.get(kv.match(name), lo.get(obj, key))
	}

	this.create = async (params) => {
		let {body: obj, error: err} = await api['create_' + name](params)
		if (err) return {error: err, body: obj}
		if (Array.isArray(obj)) return lo.map(obj, (o) => this.justUpdate(o))
		return {body: await this.justUpdate(obj)}
	}

	this.list = async (force) => {
		// try subscribe first
		if (!force && syncStatus === 'online') return kv.match(name)
		let {error: suberr} = await realtime.subscribe([updateTopic, removeTopic])
		let {anchor, body, error} = await api['list_' + name]()
		if (error) return {error}

		_anchor = anchor
		let objM = {}
		lo.map(body, (obj) => {
			if (!obj || !obj[key]) return
			objM[obj[key]] = obj
		})
		let old = kv.match(name)
		await kv.put(name, objM)
		if (!lo.isEqual(old, objM)) pubsub.publish('account')
		syncStatus = suberr ? 'offline' : 'online'
		return objM
	}

	let _anchor = ''
	this.fetchMore = async () => {
		let {body, error, anchor} = await api['list_' + name](_anchor)
		if (error) return {error}
		_anchor = anchor
		let old = lo.cloneDeep(kv.match(name)) || {}
		lo.map(body, (obj) => {
			if (!obj || !obj[key]) return
			old[obj[key]] = obj
		})
		await kv.put(name, old)
		pubsub.publish('account')
		return lo.size(body)
	}

	this.remove = async (id) => {
		let {error: err, body, code} = await api['delete_' + name](id)
		if (err) return {error: err, code, body}

		let old = kv.match(name) || {}
		delete old[id]
		await kv.put(name, old)
		pubsub.publish('account')
		return {}
	}
}

export default NewAccountStore
