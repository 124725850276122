import lo from 'lodash'
import store from '@sb/store'
var sb = require('@sb/util')
import MessageEditor from '../commons/privateMessageEditor.js'

export default {
	name: 'noti_overdue_tasks',

	data() {
		return {
			tasks_data: [],
			show: false,
		}
	},

	mounted() {
		store.onTask(this, () => this.$forceUpdate())
		store.onInstantNoti(this, (noti) => {
			if (noti.type === 'task_expired') {
				this.$forceUpdate()
			}
		})
	},

	methods: {
		async selectDelay(task, option) {
			if (option.id === -1) {
				this.$root.$emit('open_overdue_tasks', {})
				return
			}
			if (option.id === 0) {
				let done = await store.markTaskDone(task.id)
				this.$forceUpdate()
				return
			}
			let new_due_at = Date.now() + option.id
			store.updateTask({id: task.id, due_at: new_due_at}, ['due_at'])
			this.$forceUpdate()
			return
		},

		onDetailClick(task_id) {
			this.$root.$emit('open_tasks_modal', {task_id: task_id})
		},

		renderImage() {
			return (
				<img
					style='width: 55px; height: 55px; position: relative; top: 5px;'
					class='mr-3 no-shrink'
					src={require('../assets/img/overdue_task.png')}
				/>
			)
		},

		renderOverdueItem(item) {
			let delay_options = [
				{id: 0, label: this.$t('accomplished')},
				{id: 15 * 60000, label: this.$t('delay_for_15_mins')},
				{id: 3600000, label: this.$t('delay_for_1_hour')},
				{id: 86400000, label: this.$t('delay_until_tomorrow')},
			]

			return (
				<div class='d-flex'>
					{this.renderImage()}
					<div class='flex__1' style='overflow: hidden'>
						<div class='d-flex align-items-center'>
							<div class='text__muted' style='text-transform: uppercase; font-size: 12px; flex: 1;'>
								{this.$t('overdue_tasks')}
							</div>
							<div style='' vOn:click={(event) => event.stopPropagation()}>
								<Dropdown2
									style='float: right;'
									mode='custom'
									items={delay_options}
									dropdown_width={180}
									vOn:select={(option) => this.selectDelay(item, option)}
									right
								>
									<a class='d-flex pl-2 pr-2 link link__primary'>
										<p class='text__muted' style='font-size: 12px; margin: 0;'>
											{this.$t('options')}
										</p>
										<chevron-down-icon size='1x' class=' ml-2' />
									</a>
								</Dropdown2>
							</div>
						</div>
						<div class='text__truncate' style='font-weight: 600;'>
							{item.title}
						</div>
						<div class='text__truncate'>{parseHyperlinkDeltaToString(item.note_quill_delta || '')}</div>
					</div>
				</div>
			)
		},

		renderContent(overdueTasks) {
			if (lo.size(overdueTasks) === 1) return this.renderOverdueItem(overdueTasks[0])
			return this.renderOverdueList(overdueTasks)
		},

		renderOverdueList(overdueTasks) {
			return (
				<div class='d-flex'>
					{this.renderImage()}
					<div class='flex__1' style='overflow: hidden'>
						<div class='text__semibold'>{this.$t('you_have_count_overdue_tasks', [lo.size(overdueTasks)])}</div>
						<div class='mt-2'>
							<button class='btn btn__sm btn__outline_primary'>{this.$t('resolve_now')}</button>
						</div>
					</div>
				</div>
			)
		},

		onClickOverdueNoti(overdueTasks) {
			if (lo.size(overdueTasks) === 1) {
				return this.$root.$emit('open_tasks_modal', {task_id: lo.get(overdueTasks, '0.id')})
			}
			this.$root.$emit('open_overdue_tasks')
		},

		getOverdueTasks() {
			let overdueTasks = store.listOverdueTasks()
			overdueTasks = lo.map(overdueTasks, (task) => store.matchTask(task.id) || {})
			overdueTasks = lo.orderBy(overdueTasks, ['due_at'], ['asc'])

			return overdueTasks
		},
	},

	render() {
		let overdueTasks = this.getOverdueTasks()
		if (!lo.size(overdueTasks)) return null

		return (
			<div
				class='instant_noti__popup_noti-item mt-2'
				style='position: relative;'
				vOn:click={() => this.onClickOverdueNoti(overdueTasks)}
			>
				<Icon
					name='x'
					size='16'
					stroke-width='2'
					class='btn__close btn__close_noti'
					style='position: absolute; right: -12px; top: -9px; z-index: 1000;'
					vOn:click_stop={(_) => this.$emit('close')}
				/>
				{this.renderContent(overdueTasks)}
			</div>
		)
	},
}

function parseHyperlinkDeltaToString(delta) {
	delta = sb.parseJSON(delta) || {}
	let ops = delta.ops || []
	let result = ''

	lo.each(ops, (op) => {
		if (lo.get(op, 'insert.hyperlink')) {
			let cid = lo.get(op, 'insert.hyperlink.conversation.id', '')
			let uid = lo.get(op, 'insert.hyperlink.user.id', '')
			result += `https://${
				process.env.ENV === 'desktop' ? 'desktop.subiz.com.vn' : 'app.subiz.com.vn'
			}/convo?cid=${cid}&uid=${uid}`
		} else {
			result += op.insert
		}
	})

	return result
}
