var api = require('./api.js')
var config = require('@sb/config')

// download file from an url and upload to subiz server
// options to upload file to thumbnail like 50x50
// so you need options.size = 50, options.width and options.height is image widht height
// and offset is offset to crop image
//

async function upload(file, options = {}) {
	const MAX_SIZE = 25 * 1024 * 1024 // 25MB
	if (file.size > MAX_SIZE) return {error: 'file_so_large'}

	// signed filed
	var {body: signed, error} = await api.presignFile({
		description: 'image upload\n agent-client: web-app-dashboard',
		name: file.name.replace(/\s+/g, '_'),
		size: file.size,
		type: file.mimetype,
		width: options.width,
		height: options.height,
		avatar_size: options.size,
		avatar_offset: options.offset,
	})

	if (error) return {error}

	//let formData = new FormData()
	//formData.append('thefile', file._file)
	var {error} = await api.uploadFile(signed.signed_url, file._file, file.mimetype)
	if (error) return {error}

	// mark file uploaded
	var {body, error} = await api.finishUploadFile(signed.id)
	if (error) return {error}
	return {
		...body,
		url: body.url,
	}
}

const uploadLocalFile = (fileInfo, options) =>
	upload({_file: fileInfo, name: fileInfo.name, size: fileInfo.size, mimetype: fileInfo.type}, options)

export default {
	upload,
	uploadLocalFile,
}
