var lo = require('lodash')
import DB from './lsdb.js'

const ChainMaxSize = 50
// NOTE:
// index must be number and index must be positive
//
export default class {
	constructor(schema, inmem) {
		this.schema = schema + '_chain'
		this.dead = false
		this.db = new DB(this.schema, inmem)
		this.anchorCache = {}
		this.cache = {} // note: cache must be continuously from start
		this.initialized = false
	}

	init() {
		if (this.initialized) return
		let inmemItemM = {}

		let allitems = this.db.all('items')
		lo.map(allitems, (item) => {
			lo.set(inmemItemM, [item.chain, item.id], {
				id: item.id,
				chain: item.chain,
				full_id: item.full_id,
				index: item.index,
				value: item.value,
			})
		})

		lo.map(inmemItemM, (itemM, chain) => {
			if (lo.size(itemM) < ChainMaxSize) return
			let items = lo.orderBy(lo.map(itemM), ['index'], 'desc')
			let removekeys = []
			for (var i = ChainMaxSize; i < items.length; i++) {
				removekeys.push(items[i].full_id)
				delete itemM[items[i].id]
			}
			this.db.removes('items', removekeys)
			inmemItemM[chain] = itemM
		})

		this.cache = inmemItemM
		this.initialized = true
	}

	destroy() {
		// clear all data
		this.db.clear('items')

		this.dead = true
		this.cache = {}
		this.anchorCache = {}
		this.db = undefined
		this.initialized = false
	}

	// item: {id, index, value}
	// note: should only call update when db is synced
	put(chain, items, node_index, value) {
		if (this.dead) return
		// calling put(chainid, noteid, nodeindex, value)
		if (!Array.isArray(items)) items = [{id: items, index: node_index, value: value}]

		let inmemItemM = this.cache[chain] || {}
		let bottom = lo.minBy(lo.map(inmemItemM), 'index')
		let bottomIndex = lo.get(bottom, 'index', 'z')

		let dbitems = []
		lo.map(items, (item) => {
			item.chain = chain
			item.full_id = chain + ':' + item.id

			delete inmemItemM[item.id]

			if (bottomIndex > item.index + '') return
			inmemItemM[item.id] = item
			dbitems.push(item)
		})

		this.cache[chain] = inmemItemM
		lo.map(dbitems, (item) => this.db.put('items', item.full_id, item))
	}

	listM(chain) {
		if (this.dead) return {}
		let inmemItemM = this.cache[chain] || {}
		let out = {}
		lo.map(inmemItemM, (item, id) => {
			out[id] = item.value
		})
		return out
	}

	resetAnchor(chain) {
		this.anchorCache[chain] = ''
	}

	async fetchMore(chain, limit = 20, api) {
		if (this.dead) return {error: 'dead'}
		if (!api) return {error: 'no api'}

		let anchor = this.anchorCache[chain] || ''
		var [apiItems, newanchor, error] = await api(chain, anchor, limit)
		if (error) return {error}
		if (this.dead) return {error: 'dead'}

		let inmemItemM = this.cache[chain] || {}

		if (lo.size(apiItems) === 0) return {end: true, old_anchor: anchor}

		lo.map(apiItems, (item) => {
			item.chain = chain
			item.full_id = chain + ':' + item.id
			inmemItemM[item.id] = item
		})

		// save items fetch from api to the db
		lo.map(apiItems, (item) => this.db.put('items', item.full_id, item))
		this.anchorCache[chain] = newanchor
		this.cache[chain] = inmemItemM
		return {end: false, old_anchor: anchor, new_anchor: newanchor}
	}

	del(chain, id) {
		if (this.dead) return
		let inmemItemM = this.cache[chain] || {}
		let full_id = chain + ':' + id

		delete inmemItemM[id]
		this.db.removes('items', [full_id])
	}

	match(chain, id) {
		return this.listM(chain)[id]
	}

	async reload(chain, api) {
		if (this.dead) return

		let top = lo.maxBy(this.listM(chain), 'index')
		let topindex = lo.get(top, 'index')
		if (!topindex) topindex = Date.now()
		var lastanchor = ''
		var fetchedItems = {}
		for (;;) {
			if (this.dead) return
			let [newItems, newanchor, error] = await api(chain, lastanchor, 50)
			if (error) return
			if (this.dead) return
			lo.map(newItems, (item) => {
				fetchedItems[item.id] = Object.assign({}, item, {chain, full_id: chain + ':' + item.id})
			})
			if (lo.size(newItems) === 0 || newanchor === lastanchor) break // out of item
			lastanchor = newanchor
			// dont try to join
			let inmemItemM = this.cache[chain] || {}
			let removeids = []
			lo.map(inmemItemM, (item) => {
				if (item.chain !== chain) return
				removeids.push(item.full_id)
			})
			this.db.removes('items', removeids)
			this.cache[chain] = {}
			break
		}

		lo.map(fetchedItems, (item) => this.db.put('items', item.full_id, item))
		lo.map(fetchedItems, (item) => lo.set(this.cache, [chain, item.id], item))
		this.anchorCache[chain] = lastanchor
	}
}
