const sb = require('@sb/util')
const lo = require('lodash')

export default {
	name: 'audio-attachments',
	props: ['ev'],
	render () {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var audios = []
		lo.each(attachments, (att, i) => {
			if (!att) return
			if (lo.includes(att.mimetype, 'audio')) audios.push(att)
		})
		if (audios.length === 0) return null

		var $audios = lo.map(audios, (audio, index) => {
			return (
				<div key={index} class={`message_audio message_audio__${byType}`}>
					<audio class="message__audio" controls>
						<source src={sb.absURL(audio.url)} type={audio.mimetype} />
					</audio>
				</div>
			)
		})
		return <div class={`message_container message_container__${byType}`}>{$audios}</div>
	},
}
