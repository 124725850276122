import store from '@sb/store'
const lo = require('lodash')
const sb = require('@sb/util')
const emptymsgimg = require('../assets/img/integration-help-image.png')
const notfound = require('../assets/img/startchat.svg')
const convoemptyimg = require('../assets/img/convos_empty.svg')
import ChatItem from './chat_item.js'

export default {
	name: 'internal-chat-search',
	props: ['cid', 'showing'],

	data() {
		return {
			headerConvos: [],
			query: '',
			normQuery: '',

			searchResults: [],
			searchAnchor: '',
			endOfSearch: false,
			searching: false,

			convosearchResults: [],
			convosearchAnchor: '',
			convoendOfSearch: false,
			convosearching: false,
		}
	},

	mounted() {
		store.onConvo(this, (convoM) => this.$forceUpdate())
	},

	beforeDestroy() {
		clearInterval(this.seenInternal)
	},

	watch: {
		showing: function(showing) {
			if (showing) setTimeout(() => this.focusSearchInput(), 300)
		},
		cid: function() {
			this.query = ''
			this.normQuery = ''

			this.searchResults = []
			this.searchAnchor = ''
			this.endOfSearch = false
			this.searching = false
			this.convosearchResults = []
			this.convosearchAnchor = ''
			this.convoendOfSearch = false
			this.convosearching = false
		},
	},

	methods: {
		getConvoName() {
			let convo = store.matchConvo(this.cid)
			if (!convo) return ''

			if (convo.id.startsWith('csinternalcs')) {
				let refconvoid = convo.id.substr(10)
				let refconvo = store.matchConvo(refconvoid) || {id: refconvoid}
				let userid = sb.usersOfConvo(refconvo)[0] || '' // render avatar of the first user
				return refconvo.subject || sb.getUserDisplayName(store.matchUser(userid))
			}

			if (convo.id === 'csinternalall') return this.$t('general')
			if (convo.subject) return convo.subject
			let agids = lo.filter(convo.members, (m) => m.id != store.me().id).map((m) => m.id)
			if (agids.length == 0) return this.$t('me')
			if (agids.length > 0) return sb.getAgentDisplayName(store.matchAgent()[agids[0]])
		},

		onKeydown(e) {
			if (e.key === 'Escape') {
				// user want to exit search
				if (!this.query) return this.$root.$emit('showPrivateChat', 'list')

				// use want to clear search
				this.query = ''
				this.normQuery = ''
			}
		},

		doSearchHeader: lo.throttle(
			async function() {
				let out = await store.searchConvoHeader(this.normQuery)
				if (out.error) return
				this.headerConvos = lo.map(lo.get(out, 'body.conversations'), (convo) => convo.id)

				if (this.cid) {
					let out = await store.searchInternalConversations(this.normQuery, '', this.cid)
					if (out.error) return
					this.convoendOfSearch = false
					this.convosearchResults = lo.get(out, 'body.conversations', [])
					this.convosearchAnchor = lo.get(out, 'body.anchor', this.convosearchAnchor)
				}

				// must skip if there are too many result for convo search
				if (this.cid && lo.size(this.convosearchResults) > 14) return
				out = await store.searchInternalConversations(this.normQuery, '', undefined, this.cid && [this.cid])
				if (out.error) return
				this.endOfSearch = false
				this.searchResults = lo.get(out, 'body.conversations', [])
				this.searchAnchor = lo.get(out, 'body.anchor', this.searchAnchor)
			},
			400,
			{trailing: true},
		),

		focusSearchInput() {
			this.$refs.search_input && this.$refs.search_input.focus()
			setTimeout(() => this.$refs.search_input && this.$refs.search_input.focus(), 100)
		},

		onInputSearch(e) {
			this.query = e.target.value
			this.normQuery = this.query.replace(/\s+/g, ' ').trim()
			this.doSearchHeader()
		},

		onTabClick(tab) {
			this.currentTab = tab
		},

		async searchMoreAllConvo() {
			if (this.endOfSearch) return
			if (this.searching) return
			this.searching = true
			let query = this.normQuery
			let out = await store.searchInternalConversations(this.normQuery, this.searchAnchor, '', this.cid && [this.cid])
			if (query !== this.normQuery) return // outdated
			this.searching = false

			if (out.error) return

			let results = lo.get(out, 'body.conversations', [])
			this.endOfSearch = lo.size(results) === 0

			this.searchResults = this.searchResults.concat(results)
			this.searchAnchor = lo.get(out, 'body.anchor', this.searchAnchor)
			return
		},

		async searchMore() {
			if (this.cid) {
				if (this.convoendOfSearch) return this.searchMoreAllConvo()
				if (this.convosearching) return
				this.convosearching = true
				let query = this.normQuery
				let out = await store.searchInternalConversations(this.normQuery, this.searchAnchor, this.cid)
				if (query !== this.normQuery) return // outdated
				this.convosearching = false

				if (out.error) return

				let results = lo.get(out, 'body.conversations', [])
				this.convoendOfSearch = lo.size(results) === 0

				this.convosearchResults = this.convosearchResults.concat(results)
				this.convosearchAnchor = lo.get(out, 'body.anchor', this.convosearchAnchor)
				return
			}
			return this.searchMoreAllConvo()
		},

		onWheelScroll: lo.throttle(
			function(e) {
				let list = e.target
				let distToBottom = list.scrollHeight - list.scrollTop - list.clientHeight
				if (distToBottom < 500) this.searchMore()
			},
			200,
			{trailing: true},
		),
	},

	render() {
		// render agents
		let me = store.me()
		let agents = lo.filter(store.matchAgent(), (ag) => ag.type === 'agent')
		let normquery = sb.unicodeToAscii(this.normQuery).toLowerCase()
		agents = lo.filter(agents, (agent) => {
			if (
				sb
					.unicodeToAscii(agent.fullname)
					.toLowerCase()
					.trim()
					.indexOf(normquery) !== -1
			)
				return true
			if (
				sb
					.unicodeToAscii(agent.email)
					.toLowerCase()
					.trim()
					.indexOf(normquery) !== -1
			)
				return true
			if (
				sb
					.unicodeToAscii(agent.job_title)
					.toLowerCase()
					.trim()
					.indexOf(normquery) !== -1
			)
				return true
			return false
		})

		let $agents = lo.map(agents, (ag) => {
			let chatid = 'csdirect' + me.id + '_' + ag.id
			if (me.id > ag.id) chatid = 'csdirect' + ag.id + '_' + me.id
			return (
				<ChatItem
					convoid={chatid}
					is_read={true}
					vOn:selected={(_) => this.$root.$emit('showPrivateChat', 'chat:' + chatid)}
				/>
			)
		})

		let headerConvos = lo.orderBy(this.headerConvos)
		if (!this.normQuery) headerConvos = []
		let $convos = lo.map(headerConvos, (id) => (
			<ChatItem convoid={id} is_read={true} vOn:selected={(_) => this.$root.$emit('showPrivateChat', 'chat:' + id)} />
		))

		let $extraconvos = lo.map(this.searchResults, (convo) => {
			return (
				<ChatItem
					convoid={convo.id}
					is_read={true}
					vOn:selected={(_) => this.$root.$emit('showPrivateChat', 'chat:' + convo.id)}
					event={convo.matched_event}
				/>
			)
		})

		if (lo.size($extraconvos) > 0) {
			$extraconvos.unshift(
				<div style='border-top: 6px solid #eee;'>
					<div class='internal_chat__label' style='margin-left: 20px; margin-top: 10px'>
						{this.$t('message')}
					</div>
				</div>,
			)
		}

		// extra specific

		let $extraconvosmes = lo.map(this.convosearchResults, (convo) => {
			return (
				<ChatItem
					convoid={convo.id}
					is_read={true}
					vOn:selected={(_) => this.$root.$emit('showPrivateChat', 'chat:' + convo.id)}
					event={convo.matched_event}
				/>
			)
		})

		if (lo.size($extraconvosmes) > 0) {
			$extraconvosmes.unshift(
				<div style='border-top: 6px solid #eee;'>
					<div class='internal_chat__label' style='margin-left: 20px; margin-top: 10px'>
						{this.$t('message')} <span style='text-transform: lowercase'>{this.$t('from')}</span> {this.getConvoName()}
					</div>
				</div>,
			)
		}

		let $loadmore = null
		if (this.searching) {
			$loadmore = (
				<div class='d-flex align-items-center justify-content-center mt-4 mb-4'>
					<Spinner size='20' mode='blue' />
				</div>
			)
		}

		return (
			<div key='search'>
				<div class='internal_chat__header'>
					<div class='ml-4' style='flex: 1'>
						<div style='position: relative; flex:1;'>
							<input
								placeholder={this.$t('search')}
								ref='search_input'
								vOn:input={this.onInputSearch}
								vOn:keydown={this.onKeydown}
								value={this.query}
								type='text'
								class='internal_chat__search_input'
							/>
							<search-icon size='1.5x' class='link link__secondary' style='position: absolute; left: -1px; top: 2px' />
						</div>
					</div>
					<a href='#' class='ml-2 mr-3' vOn:click={(_) => this.$root.$emit('showPrivateChat', 'back')}>
						<XIcon size='1.5x' class='x-icon' />
					</a>
				</div>
				<div
					style='display: flex; flex-direction: column; flex: 1; overflow: auto'
					vOn:scroll={this.onWheelScroll}
					ref='result_container'>
					{$agents}
					{$convos}
					{$extraconvosmes}
					{$extraconvos}
					{$loadmore}
				</div>
			</div>
		)
	},
}
