var sb = require('@sb/util')
const lo = require('lodash')
const config = require('@sb/config')

export default {
	name: 'image-attachments',
	props: ['ev'],

	render() {
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var images = []
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'

		lo.each(attachments, (att, i) => {
			if (!att) return
			if (!lo.includes(att.mimetype, 'image')) return
			if (!att.url) return

			var uri = att.thumbnail_url || att.url
			if (
				!uri.startsWith('https://') &&
				!uri.startsWith('http://') &&
				!uri.startsWith('content://') &&
				!uri.startsWith('data:')
			) {
				att.url = config.FileUrl + uri
			}
			images.push(att)
		})

		if (images.length === 0) return null

		var $images = lo.map(images, (image, index) => (
			<div class={`message_image message_image__${byType}`}>
				<img2
					object_fit='cover'
					maxHeight='450px'
					class='message_image__img'
					src={sb.replaceFileUrl(image.thumbnail_url || image.url)}
					true_src={sb.replaceFileUrl(image.url)}
					clickable
				/>
			</div>
		))

		let gridColumns = calculateGridColumn(images)
		return (
			<div
				class={{
					message_container: true,
					message_container__image: true,
					[`message_container__${byType}`]: true,
					has_grid: gridColumns > 1,
					[`grid_columns_${gridColumns}`]: gridColumns > 1,
				}}
			>
				{$images}
			</div>
		)
	},
}

function calculateGridColumn(images) {
	if (lo.size(images) === 1) return 1
	if (lo.size(images) > 4 || lo.size(images) === 3) return 3
	if (lo.size(images) % 2 === 0) return 2
}
