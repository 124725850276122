import {marked} from 'marked'
import ShadowDomRoot from './shadowDomRoot.js'
import store from '@sb/store'
const lo = require('lodash')
var sb = require('@sb/util')

export default {
	name: 'text-message',
	props: ['ev', 'comment', 'task_comment', 'is_quote'],

	data() {
		return {
			detectUserInfo: '',
		}
	},

	render() {
		var format = lo.get(this.ev, 'data.message.format', 'plaintext')
		if (format === 'markdown') return this.renderMarkdown()
		if (format === 'delta') return this.renderDelta()

		var text = lo.get(this.ev, 'data.message.text')
		if (lo.size(text) === 0 && !this.is_quote) return null

		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		let cId = lo.get(this.ev, 'data.message.conversation_id')
		let convo = store.matchConvo(cId) || {}
		let user = lo.find(convo.members, (mem) => mem.type === 'user') || {}
		var $tags = sb.extractText(text).map((tag, i) => {
			if (tag.type === 'emoji') return <Emoji emojiCode={tag.text} byType={byType} />
			if (tag.type === 'link') {
				return (
					<a href={absLink(tag.text)} target='_blank'>
						{tag.text}
					</a>
				)
			}
			if (tag.type === 'phone') {
				let clsDetectPhone = `detect_user_info_from_message__container detect_user_info_from_message__container__${byType} `
				if (this.detectUserInfo === `${this.ev.id}_${i}` && byType == 'user') {
					clsDetectPhone +=
						' detect_user_info_from_message__container__visible detect_user_info_from_message__container__' +
						(this.popupgoup ? 'up' : 'down')
				}
				return (
					<a
						vOn:mouseover={(e) => this.onDetectInfoMouseOver(`${this.ev.id}_${i}`, e)}
						vOn:mouseleave={(_) => this.onDetectInfoMouseLeave()}
						href={'tel:' + tag.text}
					>
						{tag.text}
						<div class={clsDetectPhone} style='user-select: none'>
							<strong>
								{this.$t('update')} {this.$t('phone_number')}
							</strong>
							<p> {this.$t('update_info_for_user', [this.$t('phone_number')])}</p>
							<div class='d-flex justify-content-end'>
								<button
									class='btn btn__sm btn__primary detect_user_info_from_message__btn_submit'
									vOn:click_prevent_stop={(_) => this.updateUserInfo(user.id, {key: 'phones', text: tag.text})}
								>
									{this.$t('update')}
								</button>
							</div>
						</div>
					</a>
				)
			}
			if (tag.type === 'email') {
				let clsDetectEmail = `detect_user_info_from_message__container detect_user_info_from_message__container__${byType}`
				if (this.detectUserInfo === `${this.ev.id}_${i}`) {
					clsDetectEmail +=
						' detect_user_info_from_message__container__visible detect_user_info_from_message__container__' +
						(this.popupgoup ? 'up' : 'down')
				}

				return (
					<a
						class='detect_user_info_from_message'
						vOn:mouseover={(e) => this.onDetectInfoMouseOver(`${this.ev.id}_${i}`, e)}
						vOn:mouseleave={(_) => this.onDetectInfoMouseLeave()}
						href={'mailto:' + tag.text}
					>
						{tag.text}
						<div class={clsDetectEmail}>
							<strong>
								{this.$t('update')} {this.$t('email')}
							</strong>
							<p> {this.$t('update_info_for_user', [this.$t('email')])}</p>
							<div class='d-flex justify-content-end'>
								<button
									class='btn btn__sm btn__primary detect_user_info_from_message__btn_submit'
									vOn:click_prevent_stop={(_) => this.updateUserInfo(user.id, {key: 'emails', text: tag.text})}
								>
									{this.$t('update')}
								</button>
							</div>
						</div>
					</a>
				)
			}
			return tag.text
		})
		let cls = `message_text message_text__${byType}`
		if (this.task_comment) cls += ' text__sm'
		let isDelete = false
		lo.map(lo.get(this.ev, 'data.message.pongs', []), (pong) => {
			if (pong.type === 'delete') isDelete = true
		})

		if (isDelete) cls += ' message_text__deleted'
		let createdtime = new Date(sb.unixSec(this.ev.created) * 1000)

		let createdtimestr = ''
		if (!convo.is_private) createdtimestr = createdtime.toLocaleDateString() + ' ' + createdtime.toLocaleTimeString()

		let $sender = null
		if (this.comment) {
			let agentM = store.matchAgent()
			let ag = agentM[lo.get(this.ev, 'by.id')]
			let fullname = sb.getAgentDisplayName(ag)
			$sender = <div class='text__muted'>{fullname}</div>
		}
		if (this.task_comment) {
			let agentM = store.matchAgent()
			let ag = agentM[lo.get(this.ev, 'by.id')]
			let fullname = sb.getAgentDisplayName(ag)
			$sender = (
				<div class='text__sm'>
					<span class='text__semibold'>{fullname}</span> <Time class='text__muted' time={lo.get(this.ev, 'created')} />
				</div>
			)
		}

		let $img = null
		let $fallbackLink = null
		let $video = null
		let attachments = lo.get(this.ev, 'data.message.attachments', [])
		let attachmentIndex = sb.getMsgField(this.ev, 'attachment_index')
		let imageAttachment = lo.find(attachments, (att) => att.type === 'file' && (att.mimetype || '').startsWith('image'))
		let videoAttachment = lo.find(attachments, (att) => att.type === 'file' && (att.mimetype || '').startsWith('video'))
		let fallbackAttachment = lo.find(attachments, (att) => att.type === 'fallback')
		if (attachmentIndex) {
			imageAttachment = lo.get(attachments, attachmentIndex)
		}
		if (this.is_quote && imageAttachment) {
			$img = (
				<div class='mt-1'>
					<img2
						clickable
						src={lo.get(imageAttachment, 'url')}
						style='max-width: 100px; border-radius: 8px; overflow: hidden'
					/>
				</div>
			)
		}
		if (this.is_quote && videoAttachment) {
			$video = (
				<div class='mt-1'>
					<video
						src={sb.replaceFileUrl(videoAttachment.url)}
						style='border-radius: 8px'
						width='100%'
						height=''
						poster={sb.replaceFileUrl(videoAttachment.thumbnail_url)}
						preload='metadata'
						controls='controls'
					/>
				</div>
			)
		}
		if (fallbackAttachment && !text) {
			$fallbackLink = (
				<a href={fallbackAttachment.url} target='_blank'>
					{fallbackAttachment.title}
				</a>
			)
		}
		if (this.is_quote) {
			return (
				<div
					class={cls}
					style='background-color: #fff; filter: brightness(0.9); opacity: 0.6; position: relative; color: #212529; border-radius: 15px;'
				>
					{text}
					{$img}
					{$video}
					{$fallbackLink}
				</div>
			)
		}

		return (
			<div class={cls}>
				{$sender}
				{$tags}
				{$img}
			</div>
		)
	},

	methods: {
		onDetectInfoMouseOver(evId, e) {
			if (document.body.scrollHeight - e.clientY < 200) {
				this.popupgoup = true
			} else {
				this.popupgoup = false
			}
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectUserInfo = evId
			}, 500)
		},

		onDetectInfoMouseLeave() {
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectUserInfo = ''
			}, 500)
		},

		async updateUserInfo(uid, attr) {
			let user = store.matchUser(uid, true) || {}
			if (user.primary_id) {
				user = store.matchUser(user.primary_id) || {}
			}

			let currentText = sb.getUserAttr(user, attr.key, 'text')
			if (currentText === attr.text) return

			let userattr = lo.find(user.attributes, (cattr) => cattr.key === attr.key)
			let otherValues = lo.get(userattr, 'other_values', [])
			let newValue = currentText

			if (!currentText) {
				newValue = attr.text
			} else {
				otherValues.push(attr.text)
				otherValues = lo.uniq(otherValues)
			}
			attr = {
				key: attr.key,
				text: newValue,
				other_values: otherValues,
			}
			this.$updateContactAttribute({uid, attr})
		},

		renderDelta() {
			let text = lo.get(this.ev, 'data.message.quill_delta', '')
			if (!text) text = lo.get(this.ev, 'data.message.text')
			let quilldeltas = {}
			try {
				quilldeltas = JSON.parse(text)
			} catch (e) {}

			quilldeltas = sb.trimQuillDelta(quilldeltas)
			if (!quilldeltas || lo.size(quilldeltas.ops) === 0) return null
			let $deltas = lo.map(quilldeltas.ops, (delta) => {
				let html = ''
				let insert = delta.insert
				let attributes = delta.attributes

				if (insert.dynamicField) {
					return lo.get(insert, 'dynamicField.text', '')
				}

				if (insert.hyperlink) {
					let user = insert.hyperlink.user
					let convo = insert.hyperlink.conversation
					if (user) {
						user = store.matchUser(user.id) || {id: user.id}
						return (
							<span
								class='clickable'
								style='background: #dff1ff; padding: 0px 5px; border-radius: 4px'
								vOn:click={() => this.$emit('clickHyperlink', {uid: user.id, cid: lo.get(convo, 'id')})}
							>
								<Avatar user={user} size={16} /> {sb.getUserDisplayName(user)}
							</span>
						)
					}
					if (convo) {
						convo = store.matchConvo(convo.id) || {id: convo.id}
						let channel = lo.get(convo, 'touchpoint.channel')
						let $img = null
						if (channel === 'subiz' || !channel) {
							$img = <img width='16' height='16' src={require('../../assets/img/subiz_channel.svg')} />
						}
						if (channel === 'facebook') {
							$img = <img width='16' height='16' src={require('../../assets/img/messenger_channel.svg')} />
						}
						if (channel === 'facebook_comment') {
							$img = <img width='16' height='16' src={require('../../assets/img/facebook_channel.svg')} />
						}
						if (channel === 'instagram' || channel === 'instagram_comment') {
							$img = <img width='16' height='16' src={require('../../assets/img/instagram.svg')} />
						}
						if (channel === 'email') {
							$img = <img width='16' height='16' src={require('../../assets/img/email_channel.svg')} />
						}
						if (channel === 'zalo') {
							$img = <img width='16' height='16' src={require('../../assets/img/zalo_channel.svg')} />
						}
						if (channel === 'call') {
							$img = <img width='16' height='16' src={require('../../assets/img/phone_icon.svg')} />
						}

						return (
							<a
								style='background: #dff1ff; padding: 0px 5px; border-radius: 4px; text-decoration: none; color: initial !important'
								href={`${
									process.env.ENV === 'desktop' ? 'https://desktop.subiz.com.vn' : 'https://app.subiz.com.vn'
								}/convo?cid=${convo.id}`}
								target='_blank'
							>
								{$img} {convo.subject || <em>{this.$t('untitle')}</em>}
							</a>
						)
					}
				}
				if (insert.mention) {
					if (insert.mention.id == '*' || insert.mention.id == 'all') {
						return <span class='mention'>@{this.$t('all')}</span>
					}

					let id = lo.get(insert, 'mention.id', '')
					if (lo.get(insert, 'mention.type') === 'user') {
						let user = store.matchUser(id)
						let name = sb.getUserTextAttr(user) || lo.get(insert, 'mention.fullname', '') || sb.getUserDisplayName(user)
						return (
							<span class='mention' data-agent-id={id} data-agent-fullname={name}>
								@{name}
							</span>
						)
					}

					let ag = store.matchAgent(insert.mention.id)
					let name = sb.getAgentDisplayName(ag, insert.mention.fullname)
					return (
						<span class='mention' data-agent-id={ag.id} data-agent-fullname={name}>
							@{name}
						</span>
					)
				}
				if (insert.emoji) return <Emoji emojiCode={`:${insert.emoji}:`} />
				if (typeof insert === 'string') {
					let $tags = sb.extractText(insert).map((tag, i) => {
						if (tag.type === 'link') {
							return (
								<a href={absLink(tag.text)} target='_blank'>
									{tag.text}
								</a>
							)
						}
						return tag.text
					})

					if (attributes) {
						if (attributes.italic && attributes.bold)
							return <span style='font-weight: bold; font-style: italic'>{$tags}</span>
						if (attributes.italic) return <span style='font-style: italic'>{$tags}</span>
						if (attributes.bold) return <span style='font-weight: bold'>{$tags}</span>
					}
					return <span>{$tags}</span>
				}
				return null
			})
			let byType = lo.get(this.ev, 'by.type')
			if (byType !== 'user') byType = 'agent'

			let $sender = null
			let cls = `message_text message_text__${byType}`
			if (this.task_comment) {
				let agentM = store.matchAgent()
				let ag = agentM[lo.get(this.ev, 'by.id')]
				let fullname = sb.getAgentDisplayName(ag)
				$sender = (
					<div class='text__sm'>
						<span class='text__semibold'>{fullname}</span>{' '}
						<Time class='text__muted' time={lo.get(this.ev, 'created')} />
					</div>
				)
				cls += ' text__sm'
			}
			return (
				<div class={cls}>
					{$sender}
					{$deltas}
					{this.$slots.default}
				</div>
			)
		},

		renderMarkdown() {
			var text = lo.get(this.ev, 'data.message.text')
			if (lo.size(text) === 0) return null
			let byType = lo.get(this.ev, 'by.type')
			if (byType !== 'user') byType = 'agent'
			return (
				<div class={`message_markdown message_markdown__${byType}`}>
					<ShadowDomRoot html={marked(text)} />
				</div>
			)
		},
	},
}

function absLink(url) {
	if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('//')) return 'http://' + url
	return url
}
