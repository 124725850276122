const lo = require('lodash')

export default {
	name: 'search-no-results',
	props: ['colspan', 'lists', 'keysearch'],
	methods: {
		retry() {
			this.$emit('click')
		},
	},

	render() {
		if (lo.size(this.lists)) return null
		return (
			<tr class='table_blank'>
				<td colspan={this.colspan}>
					<Icon name='search' class='table_blank__icon' size='20' />
					<div>{this.$t('search_no_result_caption', {keysearch: this.keysearch})}</div>
					<p>
						<a vOn:click_prevent={this.retry} href='#'>
							{this.$t('retry')}
						</a>
					</p>
				</td>
			</tr>
		)
	},
}
