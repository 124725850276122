import store from '@sb/store'
const config = require('@sb/config')
const flow = require('@subiz/flow')
const lo = require('lodash')
const sb = require('@sb/util')
import ChatAvatar from './chat_avatar.js'
const emptymsgimg = require('../assets/img/integration-help-image.png')
const notfound = require('../assets/img/startchat.svg')
const convoemptyimg = require('../assets/img/convos_empty.svg')

const IMAGES_ACCEPT = 'image/*'

export default {
	name: 'internal_chat_detail',
	props: ['cid', 'showing'],

	data() {
		return {
			error: false,

			currentTab: 'image',

			outOfImage: false,
			loadingMoreImage: false,
			imageAnchor: '',
			imageEvents: [],

			outOfLink: false,
			loadingMoreLink: false,
			linkAnchor: '',
			linkEvents: [],

			outOfFile: false,
			loadingMoreFile: false,
			fileAnchor: '',
			fileEvents: [],

			selectedMembers: {},

			editMember: false,
			savingMember: false,

			groupName: '',
			editName: false,
			savingName: false,

			muted: false,
			offNoti: false,

			avatarUrl: '',
			uploading: false,
		}
	},

	mounted() {
		this.loadConvo(this.cid)
		// this.loadMoreImage()
		// this.loadMoreLink()
		// this.loadMoreFile()
		store.onConvo(this, (convoM) => {
			if (Object.keys(convoM).includes(this.cid)) {
				let convo = store.matchConvo(this.cid)
				lo.map(convo.members, (member) => {
					if (member.id === store.me().id) {
						this.muted = member.is_muted
						this.offNoti = member.off_notification
					}
				})
				this.$forceUpdate()
			}
		})
	},

	beforeDestroy() {
		// clearInterval(this.seenInternal)
	},

	watch: {
		cid(cid) {
			this.loadConvo(cid)
			this.loadMoreImage()
		},
	},

	methods: {
		async leaveGroup() {
			let confirm = await this.$confirm({
				title: this.$t('are_you_sure'),
				description: this.$t('leave_group_desc'),
				style: 'danger',
				hasIcon: true,
			})

			if (!confirm) return
			out = await store.unassignAgent(this.cid, {id: store.me().id, type: 'agent'})
			if (out.error) return this.$showError(this.$t('update_failed'))
			this.$root.$emit('showPrivateChat', 'back')
		},

		async changeOffNoti(v) {
			let convo = store.matchConvo(this.cid)
			let mem = lo.find(convo.members, (member) => member.id === store.me().id)
			mem.off_notification = !v
			this.offNoti = !v
			mem.conversation_id = this.cid
			let {error} = await store.updateMember(mem)
			if (error) return this.$showError(this.$t('update_failed'))
		},

		async changeMuted(v) {
			let convo = store.matchConvo(this.cid)
			let mem = lo.find(convo.members, (member) => member.id === store.me().id)
			mem.is_muted = !v
			this.muted = !v
			mem.conversation_id = this.cid
			let {error} = await store.updateMember(mem)
			if (error) return this.$showError(this.$t('update_failed'))
		},

		onChangeGroupName(e) {
			this.groupName = e.target.value
		},

		async saveMember() {
			if (this.savingMember) return
			this.savingMember = true

			let convo = store.matchConvo(this.cid)
			if (!convo) return null

			let news = []
			let removes = []
			lo.map(this.selectedMembers, (has, id) => {
				if (has) {
					let found = lo.find(convo.members, (mem) => mem.id === id)
					if (!found) news.push(id)
				}

				if (!has) {
					let found = lo.find(convo.members, (mem) => mem.id === id)
					if (found) removes.push(id)
				}
			})

			let err = undefined
			await flow.map(news, 4, async (id) => {
				let out = await store.assignAgent(convo.id, {id: id, type: 'agent'})
				if (out.error) err = out.error
			})

			await flow.map(removes, 4, async (id) => {
				let out = await store.unassignAgent(convo.id, {id: id, type: 'agent'})
				if (out.error) err = out.error
			})

			this.savingMember = false
			this.editMember = false
			if (err) return this.$showError(this.$t('update_failed'))
		},

		selectMember(agid) {
			this.selectedMembers[agid] = true
			this.$forceUpdate()
		},

		unselectMember(agid) {
			this.selectedMembers[agid] = false
			this.$forceUpdate()
		},

		openEditMember() {
			this.editMember = true
			let convo = store.matchConvo(this.cid)
			if (!convo) return null

			let agents = lo
				.map(store.matchAgent(), (ag) => (ag.type == 'agent' && ag.state === 'active' ? ag : null))
				.filter((ag) => ag)

			this.selectedMembers = {}
			lo.map(agents, (ag) => {
				let found = lo.find(convo.members, (member) => member.type === 'agent' && member.id === ag.id)
				if (found) this.selectedMembers[ag.id] = true
				else this.selectedMembers[ag.id] = false
			})
			this.avatarUrl = lo.get(convo, 'avatar_url', '')
		},

		async saveName() {
			if (this.savingName) return
			this.savingName = true
			let out = await store.updateConvo({id: this.cid, subject: this.groupName, avatar_url: this.avatarUrl})
			this.savingName = false
			if (out.error) return this.$showError(this.$t('update_failed'))
			this.editName = false
			this.groupName = ''
		},

		openEditName(convo) {
			this.editName = true
			this.groupName = convo.subject
		},

		cancelEditName() {
			this.editName = false
		},

		cancelEditMember() {
			this.editMember = false
		},

		async loadMoreImage() {
			if (this.outOfImage) return
			if (this.loadingMoreImage) return
			this.loadingMoreImage = true
			let out = await store.fetchMoreInternalChatEvent(this.cid, 'image', this.imageAnchor)
			this.loadingMoreImage = false
			if (out.error) return
			if (lo.size(out.body) > 0 && out.body.length > 0) {
				this.imageEvents = this.imageEvents.concat(out.body)
				this.imageAnchor = out.body[out.body.length - 1].id
				this.outOfImage = false
			} else {
				this.outOfImage = true
			}
			//this.loadingMore = false
		},

		async loadMoreLink() {
			if (this.outOfLink) return
			if (this.loadingMoreLink) return
			this.loadingMoreLink = true
			let out = await store.fetchMoreInternalChatEvent(this.cid, 'link', this.linkAnchor)
			this.loadingMoreLink = false
			if (out.error) return
			if (lo.size(out.body) > 0 && out.body.length > 0) {
				this.linkEvents = this.linkEvents.concat(out.body)
				this.linkAnchor = out.body[out.body.length - 1].id
				this.outOfLink = false
			} else {
				this.outOfLink = true
			}
			//this.loadingMore = false
		},

		async loadMoreFile() {
			if (this.outOfFile) return
			if (this.loadingMoreFile) return
			this.loadingMoreFile = true
			let out = await store.fetchMoreInternalChatEvent(this.cid, 'file', this.fileAnchor)
			this.loadingMoreFile = false
			if (out.error) return
			if (lo.size(out.body) > 0 && out.body.length > 0) {
				this.fileEvents = this.fileEvents.concat(out.body)
				this.fileAnchor = out.body[out.body.length - 1].id
				this.outOfFile = false
			} else {
				this.outOfFile = true
			}
			//this.loadingMore = false
		},

		renderActions() {
			return (
				<div class='pb-4 pr-4 pl-4' style='border-top: 6px solid #eee;'>
					<div class='internal_chat__label'>{this.$t('setting')}</div>
					<div class='mt-3'>
						<div class='d-flex' style='justify-content: space-between'>
							{this.$t('follow')}{' '}
							<Sw blue style='margin-top: 3px' checked={!this.muted} vOn:change={this.changeMuted} />
						</div>
						<div class='text__muted'>{this.$t('follow_desc')}</div>
					</div>
					<div class='mt-3'>
						<div class='d-flex' style='justify-content: space-between'>
							{this.$t('receive_noti')}
							<Sw blue style='margin-top: 3px' checked={!this.offNoti} vOn:change={this.changeOffNoti} />
						</div>
						<div class='text__muted'>{this.$t('receive_noti_desc')}</div>
					</div>
					<div class='link link__danger mt-3' vOn:click={this.leaveGroup}>
						{this.$t('leave_group')}
					</div>
				</div>
			)
		},

		renderMembers() {
			let convo = store.matchConvo(this.cid)
			if (!convo) return null

			let $agents = null
			if (this.editMember) {
				let $added = []
				let $unadded = []
				// added member
				let addedMembers = []
				let leftMembers = []
				lo.map(this.selectedMembers, (added, id) => (added ? addedMembers.push(id) : leftMembers.push(id)))
				addedMembers = lo.orderBy(addedMembers)
				leftMembers = lo.orderBy(leftMembers)

				lo.map(addedMembers, (id) => {
					let ag = store.matchAgent()[id]
					let found = lo.find(convo.members, (member) => member.id === ag.id)
					let $name = sb.getAgentDisplayName(ag)
					if (!found) $name = <b>{sb.getAgentDisplayName(ag)}</b>
					$added.push(
						<div style='display: flex; align-items: center; margin-bottom: 10px;'>
							<Avatar class='assign_agent__member_avatar' agent={ag} size='sm' />
							<div style='flex: 1;' class='ml-3 text__truncate'>
								{$name}
							</div>
							<minus-circle-icon size='1.5x' class='x-icon' vOn:click={(_) => this.unselectMember(ag.id)} />
						</div>,
					)
				})

				lo.map(leftMembers, (id) => {
					let ag = store.matchAgent()[id]
					$unadded.push(
						<div
							style='display: flex; align-items: center; margin-bottom: 10px; cursor: pointer;'
							vOn:click={(_) => this.selectMember(ag.id)}
						>
							<Avatar class='assign_agent__member_avatar' agent={ag} size='sm' />
							<div style='flex: 1;' class='ml-3 text__truncate'>
								{sb.getAgentDisplayName(ag)}
							</div>
							<plus-circle-icon size='1.5x' class='link link__primary' />
						</div>,
					)
				})

				if (lo.size($unadded) > 0) {
					$unadded.unshift(<div class='text__muted mb-3'>Thêm thành viên mới vào nhóm</div>)
					$unadded.unshift(<hr class='mt-4 mb-4' style='border-bottom: 1px solid #777;' />)
				}
				$agents = (
					<fragment>
						{$added}
						{$unadded}
					</fragment>
				)
			} else {
				let members = lo.filter(convo.members, (member) => member.type === 'agent')
				members = lo.orderBy(members, 'id')
				$agents = lo.map(members, (member) => {
					let ag = store.matchAgent(member.id)
					let $admin = null
					if (member.id == convo.initial_by)
						$admin = (
							<Icon
								name='shield'
								v-tooltip={this.$t('group_admin')}
								style='margin-top: -3px; fill: #ffeb3b; color: #ffc107'
								stroke-width='1.5'
								size='16'
							/>
						)
					return (
						<div
							style='display: flex; align-items: center; margin-bottom: 10px;'
							vOn:click={(_) => this.selectMember(ag.id)}
						>
							<Avatar class='assign_agent__member_avatar' agent={ag} size='sm' />
							<div style='flex: 1; ' class='ml-3 text__truncate'>
								{sb.getAgentDisplayName(ag)} {$admin}{' '}
							</div>
							<Time class='text__muted' ago time={member.seen_at} />
						</div>
					)
				})
			}

			let $action = (
				<div class='link link__primar' style='font-weight: 400' vOn:click={this.openEditMember}>
					{this.$t('edit')}
				</div>
			)

			if (this.editMember) {
				$action = (
					<div class='d-flex'>
						<div class='link link__primar' style='font-weight: 400' vOn:click={this.cancelEditMember}>
							{this.$t('cancel')}
						</div>
						<div class='text__muted ml-3 mr-3'>·</div>
						<div class='link link__primar' style='font-weight: 400' vOn:click={this.saveMember}>
							{this.$t('save')} <SaveIcon style='margin-left: 4px; margin-top: -3px' size='1x' />
						</div>
					</div>
				)
			}

			if (this.savingMember) {
				$action = (
					<div class='d-flex'>
						<div class='link link__primar' style='font-weight: 400'>
							{this.$t('saving')} <spinner mode='blue' size='15' style='margin-left: 4px; margin-top: -3px' />
						</div>
					</div>
				)
			}

			return (
				<div style='padding: 0 20px 10px 20px'>
					<div class='internal_chat__label' style='display: flex; justify-content: space-between;'>
						{this.$t('members')}
						{$action}
					</div>
					<div style='overflow: auto'>{$agents}</div>
				</div>
			)
		},

		renderImageTab() {
			if (this.currentTab !== 'image') return null
			let images = []
			lo.map(this.imageEvents, (ev) => {
				var attachments = lo.get(ev, 'data.message.attachments', [])
				lo.each(attachments, (att, i) => {
					if (!att) return
					if (!lo.includes(att.mimetype, 'image')) return
					if (!att.url) return
					var uri = att.url
					if (
						!uri.startsWith('https://') &&
						!uri.startsWith('http://') &&
						!uri.startsWith('content://') &&
						!uri.startsWith('data:')
					) {
						uri = config.FileUrl + uri
					}
					images.push(uri)
				})
			})

			let $images = lo.map(images, (url) => {
				return (
					<div style='margin-right: 10px; margin-bottom: 10px'>
						<img2 src={url} width='80' height='80' />
					</div>
				)
			})
			return (
				<div class='d-flex mt-4 ml-4' style='flex-wrap: wrap;'>
					{$images}
				</div>
			)
		},

		renderLinkTab() {
			if (this.currentTab !== 'link') return null
			let images = []
			lo.map(this.imageEvents, (ev) => {
				var attachments = lo.get(ev, 'data.message.attachments', [])
				lo.each(attachments, (att, i) => {
					if (!att) return
					if (!lo.includes(att.mimetype, 'image')) return
					if (!att.url) return
					var uri = att.url
					if (
						!uri.startsWith('https://') &&
						!uri.startsWith('http://') &&
						!uri.startsWith('content://') &&
						!uri.startsWith('data:')
					) {
						uri = config.FileUrl + uri
					}
					images.push(uri)
				})
			})

			let $images = lo.map(images, (url) => {
				return (
					<div style='margin-right: 10px; margin-bottom: 10px'>
						<img2 src={url} width='80' height='80' />
					</div>
				)
			})
			return (
				<div class='d-flex mt-4 ml-4' style='flex-wrap: wrap;'>
					{$images}
				</div>
			)
		},

		renderFileTab() {
			if (this.currentTab !== 'file') return null
			let images = []
			lo.map(this.imageEvents, (ev) => {
				var attachments = lo.get(ev, 'data.message.attachments', [])
				lo.each(attachments, (att, i) => {
					if (!att) return
					if (!lo.includes(att.mimetype, 'image')) return
					if (!att.url) return
					var uri = att.url
					if (
						!uri.startsWith('https://') &&
						!uri.startsWith('http://') &&
						!uri.startsWith('content://') &&
						!uri.startsWith('data:')
					) {
						uri = config.FileUrl + uri
					}
					images.push(uri)
				})
			})

			let $images = lo.map(images, (url) => {
				return (
					<div style='margin-right: 10px; margin-bottom: 10px'>
						<img2 src={url} width='80' height='80' />
					</div>
				)
			})
			return (
				<div class='d-flex mt-4 ml-4' style='flex-wrap: wrap;'>
					{$images}
				</div>
			)
		},

		renderInfo() {
			if (!this.cid) return null
			if (this.cid.startsWith('csdirect')) {
				let agids = this.cid.substr(8).split('_')
				let agid = agids[0]
				if (agid === store.me().id && agids[1]) agid = agids[1]
				let ag = store.matchAgent()[agid] || {}

				return (
					<div>
						<div class='d-flex pt-4 pl-4 pr-4 pb-4 align-items-center' style='border-top: 6px solid #eee;'>
							<Avatar agent={ag} size={80} nodot />
							<div class='ml-4'>
								<div style='font-size: 20px'>{ag.fullname}</div>
								<div>{ag.job_title}</div>
								<div class='text__muted'>
									{lo.get(ag, 'last_seen.pinged') ? <Time time={lo.get(ag, 'last_seen.pinged')} ago /> : <em>{'Chưa từng online'}</em>}
								</div>
							</div>
						</div>

						<div class='ml-4 mr-4 pt-4 pb-4 align-items-center' style='border-top: 1px solid #d5d5d5'>
							<div class='d-flex align-items-center'>
								<PhoneIcon size='1.5x' class='text__muted mr-3' /> {ag.phone}
							</div>
							<div class='d-flex mt-3 align-items-center'>
								<MailIcon size='1.5x' class='text__muted mr-3' /> {ag.email}
							</div>
						</div>
						<div style='border-top: 6px solid #eee; padding-top: 10px;'>{this.renderTabs()}</div>
						<div>{this.renderImageTab()}</div>
						<div>{this.renderLinkTab()}</div>
						<div>{this.renderFileTab()}</div>
					</div>
				)
			}
			if (this.cid === 'csinternalall') {
				return (
					<div>
						<div class='pl-4 pr-4' style='background: transparent; border-top: 6px solid #eee; padding-bottom: 20px;'>
							<div class='internal_chat__label'>{this.$t('description')}</div>
							{this.$t('general_desc')}
							này
						</div>
						<div>{this.renderActions()}</div>
						<div style='border-top: 6px solid #eee; padding-top: 10px'>{this.renderTabs()}</div>
						<div>{this.renderImageTab()}</div>
						<div>{this.renderLinkTab()}</div>
						<div>{this.renderFileTab()}</div>
					</div>
				)
			}

			if (this.cid.startsWith('csinternal')) {
				return (
					<div>
						<div
							class='pl-4 pr-4'
							style='background: transparent; border-top: 6px solid #eee; border-bottom: 6px solid #eee; padding-bottom: 20px;'
						>
							<div class='internal_chat__label'>{this.$t('description')}</div>
							Hội thoại hỗ trợ cho hội thoại gốc
						</div>

						{this.renderMembers()}
						{this.renderActions()}

						<div style='border-top: 6px solid #eee; padding-top: 10px;'>{this.renderTabs()}</div>
						<div>{this.renderImageTab()}</div>
						<div>{this.renderLinkTab()}</div>
						<div>{this.renderFileTab()}</div>
					</div>
				)
			}

			// normal group
			let convo = store.matchConvo(this.cid)
			if (!convo) return null
			let $action = (
				<div class='link link__primar' style='font-weight: 400' vOn:click={(_) => this.openEditName(convo)}>
					{this.$t('edit')}
				</div>
			)

			if (this.editName) {
				let style = 'font-weight: 400;'
				if (this.uploading) style += 'opacity: 0.5; pointer-events: none;'
				$action = (
					<div class='d-flex'>
						<div class='link link__primar' style='font-weight: 400' vOn:click={this.cancelEditName}>
							{this.$t('cancel')}
						</div>
						<div class='text__muted ml-3 mr-3'>·</div>
						<div class='link link__primar' style={style} vOn:click={this.saveName}>
							{this.$t('save')} <SaveIcon style='margin-left: 4px; margin-top: -3px' size='1x' />
						</div>
					</div>
				)
			}

			if (this.savingName) {
				$action = (
					<div class='d-flex'>
						<div class='link link__primar' style='font-weight: 400'>
							{this.$t('saving')} <spinner mode='blue' size='15' style='margin-left: 4px; margin-top: -3px' />
						</div>
					</div>
				)
			}

			let $editing = (
				<div class='d-flex align-items-center' style='font-size: 18px'>
					<ChatAvatar id={this.cid} />
					<div class='ml-3'>{convo.subject || this.$t('untitled')}</div>
				</div>
			)
			if (this.editName)
				$editing = (
					<Fragment>
						<div class='d-flex align-items-center'>
							<div class='internal_chat_avatar_edit_wrapper' vOn:click={(_) => this.$refs.avatar_input.click()}>
								{this.avatarUrl ? (
									<div style='width: 40px; height: 40px; flex-shrink:0; border-radius: 5px; oveflow: hidden'>
										<img src={this.avatarUrl} width='100%' style='object-fit: cover' />
									</div>
								) : (
									<ChatAvatar id={this.cid} style='flex-shrink: 0' />
								)}
								<div class='internal_chat_avatar_edit_icon' v-tooltip={'Đổi ảnh đai diện'}>
									<Icon name='camera' size='13' />
								</div>
								<input
									ref='avatar_input'
									type='file'
									vOn:click={this.clearFile}
									vOn:change={this.uploadChatAvatar}
									accept={IMAGES_ACCEPT}
									style='display: none;'
								/>
							</div>
							<input class='form-control ml-3' type='text' vOn:input={this.onChangeGroupName} value={this.groupName} />
						</div>
					</Fragment>
				)

			return (
				<div>
					<div
						class='pl-4 pr-4 pt-3'
						style='background: transparent; border-top: 6px solid #eee; border-bottom: 6px solid #eee; padding-bottom: 20px;'
					>
						<div class='internal_chat__label' style='display: flex; justify-content: space-between;'>
							<div>
								{this.$t('group')}
								{this.uploading && <Spinner size={12} mode='blue' class='ml-2' />}
							</div>
							{$action}
						</div>
						{$editing}
					</div>
					<div>{this.renderMembers()}</div>
					{this.renderActions()}
					<div style='border-top: 6px solid #eee; padding-top: 10px'>{this.renderTabs()}</div>
					<div>{this.renderImageTab()}</div>
					<div>{this.renderLinkTab()}</div>
					<div>{this.renderFileTab()}</div>
				</div>
			)
		},

		// trigger change for same file
		clearFile(e) {
			e.target.value = ''
		},

		async uploadChatAvatar(e) {
			let MAXIMUM_SIZE = 5 * 1024 * 1024
			if (this.uploading) return

			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (file.size > MAXIMUM_SIZE) {
				return this.$showError(this.$t('error_file_is_too_large'))
			}

			this.uploading = true
			let res = await store.uploadLocalFile(file)
			this.uploading = false

			if (res.error) return this.$showError(res.error)
			this.avatarUrl = res.url
		},

		isGroupConvo() {
			if (this.cid.startsWith('csinternalall')) return false
			if (this.cid.startsWith('csinternal')) return false
			if (this.cid.startsWith('csdirect')) return false

			let convo = store.matchConvo(this.cid)
			return convo.is_private
		},

		renderFile() {},

		onTabClick(tab) {
			this.currentTab = tab
		},

		renderTabs() {
			let imageviewcls = 'tab__item'
			let fileviewcls = 'tab__item'
			let linkviewcls = 'tab__item'

			if (this.currentTab === 'link') linkviewcls += ' tab__item__active'
			if (this.currentTab === 'file') fileviewcls += ' tab__item__active'
			if (this.currentTab === 'image') imageviewcls += ' tab__item__active'
			return (
				<div class='tab'>
					<div class={imageviewcls} vOn:click={(_) => this.onTabClick('image')} style='margin-left: 20px; flex: 1'>
						<CameraIcon size='1x' style=' margin-top: -5px' class='mr-3' /> {this.$t('image')}
					</div>
					<div class={linkviewcls} vOn:click={(_) => this.onTabClick('link')} style='flex: 1'>
						<LinkIcon size='1x' style=' margin-top: -5px' class='mr-3' /> Link
					</div>
					<div class={fileviewcls} vOn:click={(_) => this.onTabClick('file')} style='flex: 1'>
						<FileTextIcon size='1x' style=' margin-top: -5px' class='mr-3' />
						{this.$t('file')}
					</div>

					<div class='tab__item tab__item__last'></div>
				</div>
			)
		},

		async loadConvo(cid) {
			if (!cid) return
			let convo = store.matchConvo(this.cid) || {}
			lo.map(convo.members, (member) => {
				if (member.id === store.me().id) {
					this.muted = member.is_muted
					this.offNoti = member.off_notification
				}
			})
		},
	},

	render() {
		return <div style='display: flex; flex-direction: column; flex: 1; overflow: auto'>{this.renderInfo()}</div>
	},
}
