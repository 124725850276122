var sb = require('@sb/util')
const lo = require('lodash')

export default {
	name: 'video-attachments',
	props: ['ev'],
	render () {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var videos = []
		lo.each(attachments, (att, i) => {
			if (!att) return
			if (lo.includes(att.mimetype, 'video')) videos.push(att)
		})

		if (videos.length === 0) return null

		var $videos = lo.map(videos, (video, index) => {
			return (
				<div class={`message_video message_video__${byType}`}>
					<video
						class="message_video__video"
						src={sb.replaceFileUrl(video.url)}
						width="100%"
						height=""
						poster={sb.replaceFileUrl(video.thumbnail_url)}
						controls="controls"
						preload="metadata"
						onclick="this.play()"></video>
				</div>
			)
		})
		return <div class={`message_container message_container__${byType}`}>{$videos}</div>
	},
}
