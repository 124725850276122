var lo = require('lodash')
const sb = require('@sb/util')
var pubsub = new sb.Pubsub()
import store from '@sb/store'
import {addMinutes, subDays, format, formatDistanceStrict} from 'date-fns'
import {getDateFnsLocale, getCurrentLanguage} from '../languages.js'

let TZMin = getTimezoneMinutes()
let DF_DATE_FORMAT = getDefaultDateFormat()
setInterval(() => {
	pubsub.publish('time')
	TZMin = getTimezoneMinutes()
	DF_DATE_FORMAT = getDefaultDateFormat()
}, 20000)

setTimeout(() => {
	TZMin = getTimezoneMinutes()
	DF_DATE_FORMAT = getDefaultDateFormat()
	pubsub.publish('time')
}, 1000)

function addMiniutes(date, min) {
	return new Date(Date(date).getTime() + min * 60000)
}

function getDefaultDateFormat() {
	let fm = lo.get(store.me(), 'account.date_format') || 'dd/MM/yyyy'
	fm = fm.replace(/DD/g, 'dd')
	fm = fm.replace(/Y/g, 'y')

	return fm
}

function getTimezoneMinutes() {
	// 07:00 => 420
	let tz = sb.getTimezone()
	let accountTimezone = lo.get(store.me(), 'account.timezone')
	let agentTimezone = lo.get(store.me(), 'timezone')

	if (accountTimezone) tz = accountTimezone
	if (agentTimezone) tz = agentTimezone

	return sb.offsetToMin(tz)
}

// timezone aware
// 1. Full
//    Format: ${account_date_format} 04:44:24
//    Example: 2022-04-13 04:44:44
//
// 2. Default (default, should use most often)
//    Example: "15:45 Hom nay", "15:45 Hom qua" ; "05:54 24/04" ; "05:54 24/04/2022"
//
// 3. Date only
//    Format: ${account_date_format}
//    Example: 2022/04/24
//
// 4. Shorten
//    Example: 04:45 AM  ; Yesterday  ; Jun 9 ; Mar 7 ; 5/5/21
//
// 5. Ago (short, suffix)
//    Example: 30 giay ; 2 thang ; 1 nam ;
//    Example with suffix enabled: vua xong ; 30 giay ago; 2 thang truoc
//    Example with short enabled: 1s ; 3p ; 4g, 5n ; 3th ; 1n
//
// 6. Duration (short)
//    Example: 4 giay, 10 giay, 50 ngay
//    Example: 4s, 10p, 5h, 8n, 9t, 1n

export default {
	name: 'timea',
	// time must be server-date
	// server-date = client-date + diff
	// server-date = store.toServerMs(client-date)
	props: [
		'time',
		'ago',
		'short',
		'duration',
		'notooltip',
		'agox',
		'format',
		'full',
		'suffix',
		'date_only',
		'tooltip_prefix',
		'tooltip_suffix',
	],

	data() {
		return {now: 0}
	},

	created() {
		pubsub.on2(this, 'time', () => {
			if (this.ago) this.now++
			this.$forceUpdate()
		}) // force rerender
	},

	methods: {
		// since format(Date.now(), 'HH:mm') always return HH:mm in the current timezone of the OS
		// we dont want that, we want HH:mm in timezone of the agent (or account)
		// this function first convert HH:mm in OS timezone to HH:mm in UTC, then convert from UTC to correct tz
		//
		// eg: if you pass in 10:00 UTC and the OS timezone is +07:00, timezone of agent is 08:00
		// calling format(time, "HH:mm") would return 17:00
		// calling format(getTzTime(time), "HH:mm") would return 18:00
		getTzTime(date) {
			let utcDate = addMinutes(date, date.getTimezoneOffset())
			return addMinutes(utcDate, TZMin)
		},

		renderFull(ms, shorten) {
			let date = this.getTzTime(new Date(ms))
			let fm = DF_DATE_FORMAT + ' HH:mm:ss'

			// date only
			if (shorten) fm = DF_DATE_FORMAT
			return format(date, fm, {locale: getDateFnsLocale()})
		},

		isToday(date) {
			let tzNow = this.getTzTime(new Date())
			let tzDate = this.getTzTime(date)
			return (
				tzNow.getDate() === tzDate.getDate() &&
				tzNow.getMonth() === tzDate.getMonth() &&
				tzNow.getYear() === tzDate.getYear()
			)
		},

		isYesterday(date) {
			let yesterday = subDays(new Date(), 1)
			let tzNow = this.getTzTime(yesterday)
			let tzDate = this.getTzTime(date)
			return (
				tzNow.getDate() === tzDate.getDate() &&
				tzNow.getMonth() === tzDate.getMonth() &&
				tzNow.getYear() === tzDate.getYear()
			)
		},

		isSameYear(date) {
			let tzNow = this.getTzTime(new Date())
			let tzDate = this.getTzTime(date)

			return tzNow.getYear() === tzDate.getYear()
		},

		renderDateOnly(ms) {
			let date = new Date(ms)
			let datetz = this.getTzTime(date)

			let day = ''
			if (this.isToday(date)) day = this.$t('today')
			else if (this.isYesterday(date)) day = this.$t('yesterday')
			else if (this.isSameYear(date)) {
				let fm = DF_DATE_FORMAT

				// find weird charactor
				let delimiter = '/'
				for (var i = 0; i < fm.length; i++) {
					let c = fm.charAt(i)
					if (c < 'A' || c > 'z') {
						delimiter = c
						break
					}
				}
				fm = fm.replace(/y/g, '')
				fm = fm.replace(/Y/g, '')

				fm = fm
					.split(delimiter)
					.filter((a) => a)
					.join(delimiter)

				day = format(datetz, fm, {locale: getDateFnsLocale()})
			} else {
				day = format(datetz, DF_DATE_FORMAT, {locale: getDateFnsLocale()})
			}

			return day
		},

		renderDefault(ms) {
			let date = new Date(ms)
			let datetz = this.getTzTime(date)
			let hour = format(datetz, 'HH:mm')

			let day = ''
			if (this.isToday(date)) day = this.$t('today')
			else if (this.isYesterday(date)) day = this.$t('yesterday')
			else if (this.isSameYear(date)) {
				let fm = DF_DATE_FORMAT

				// find weird charactor
				let delimiter = '/'
				for (var i = 0; i < fm.length; i++) {
					let c = fm.charAt(i)
					if (c < 'A' || c > 'z') {
						delimiter = c
						break
					}
				}
				fm = fm.replace(/y/g, '')
				fm = fm.replace(/Y/g, '')

				fm = fm
					.split(delimiter)
					.filter((a) => a)
					.join(delimiter)

				// display more readable like, 8:00, 3 thg8, 9:00, Jun 1
				fm = 'MMM d'
				if (getCurrentLanguage() === 'vi') fm = 'd MMM'
				day = format(datetz, fm, {locale: getDateFnsLocale()})
			} else {
				day = format(datetz, DF_DATE_FORMAT, {locale: getDateFnsLocale()})
			}

			if (!day) return hour
			return hour + ', ' + day
		},

		renderShortDistance(distance) {
			const TOKENS = {
				second: {vi: 's', en: 's'},
				minute: {vi: 'p', en: 'm'},
				hour: {vi: 'g', en: 'h'},
				day: {vi: 'ng', en: 'd'},
				month: {vi: 'thg', en: 'mo'},
				year: {vi: 'n', en: 'y'},
			}
			let lang = getCurrentLanguage()

			if (distance < 60_000) {
				return Math.round(distance / 1000) + lo.get(TOKENS, `second.${lang}`)
			} else if (distance < 60 * 60_000) {
				return Math.round(distance / 60_000) + lo.get(TOKENS, `minute.${lang}`)
			} else if (distance < 24 * 60 * 60_000) {
				return Math.round(distance / (60 * 60_000)) + lo.get(TOKENS, `hour.${lang}`)
			} else if (distance < 30 * 24 * 60 * 60_000) {
				return Math.round(distance / (24 * 60 * 60_000)) + lo.get(TOKENS, `day.${lang}`)
			} else if (distance < 12 * 30 * 24 * 60 * 60_000) {
				return Math.round(distance / (30 * 24 * 60 * 60_000)) + lo.get(TOKENS, `month.${lang}`)
			} else {
				return Math.round(distance / (12 * 30 * 24 * 60 * 60_000)) + lo.get(TOKENS, `year.${lang}`)
			}
		},

		renderAgo(ms, shorten) {
			if (isNaN(ms)) return ''
			if (this.short) {
				return this.renderShortDistance(sb.now() - ms)
			}

			let text = formatDistanceStrict(ms, sb.now(), {addSuffix: this.suffix, locale: getDateFnsLocale()})
			if (sb.now() - ms < 30000) text = this.$t('time_ago_now') // recently
			return text
		},

		// 04:45 ; Yesterday  ; Jun 9 ; Mar 7 ; 5/5/21
		renderShorten(ms) {
			let date = new Date(ms)
			if (this.isToday(date)) {
				let datetz = this.getTzTime(date)
				return format(datetz, 'HH:mm', {locale: getDateFnsLocale()})
			}

			if (this.isYesterday(date)) return this.$t('yesterday')

			let datetz = this.getTzTime(date)
			if (this.isSameYear(date)) {
				let dateFormat = 'MMM d'
				if (getCurrentLanguage() === 'vi') dateFormat = 'd MMM'
				return format(datetz, dateFormat, {locale: getDateFnsLocale()})
			}
			return format(datetz, DF_DATE_FORMAT)
		},

		renderDuration(short) {
			if (short) {
				return this.renderShortDistance(parseInt(this.time) * 1000)
			}
			let unit = this.duration === true ? undefined : this.duration
			if (this.time <= 30) unit = 'second'
			return formatDistanceStrict(0, parseInt(this.time) * 1000, {
				locale: getDateFnsLocale(),
				includeSeconds: true,
				unit,
			})
		},

		renderTime(ms) {
			if (this.duration) return this.renderDuration(this.short)
			if (this.full) return this.renderFull(ms, this.short)
			if (this.ago || this.agox) return this.renderAgo(ms, this.short)
			if (this.date_only) return this.renderDateOnly(ms)

			if (this.short) return this.renderShorten(ms)
			return this.renderDefault(ms)
		},
	},

	render() {
		let _ = this.now // a trick to force rerender when this.now is changed

		let ms = this.time || 0
		if (typeof ms === 'number') ms = sb.getMs(ms)
		else ms = new Date(ms).getTime()
		if (ms < 0) ms = 0
		if (ms == 0) return ''
		if (isNaN(ms)) return ''

		let fulldate = new Date(ms)
		fulldate = this.getTzTime(fulldate) || 0
		let tooltip = format(fulldate, DF_DATE_FORMAT + ', HH:mm:ss')
		if (this.tooltip_prefix) tooltip = `${this.tooltip_prefix} ${tooltip}`
		if (this.tooltip_suffix) tooltip = `${tooltip} ${this.tooltip_suffix}`
		if (this.notooltip || this.full || this.duration) tooltip = ''
		return <span v-tooltip={tooltip}>{this.renderTime(ms)}</span>
	},
}
