import store from '@sb/store'
const lo = require('lodash')
const sb = require('@sb/util')

export default {
	name: 'internal-chat-avatar',
	props: ['id', 'sm', 'unread', 'nodot', 'pinned'],

	created() {
		// if (!this.id) return
		if (this.id.startsWith('csinternalcs')) {
			let refconvoid = this.id.substr(10)
			store.onConvo2(this, refconvoid, (_) => this.$forceUpdate())
		}
		store.onConvo2(this, this.id, (_) => this.$forceUpdate())
	},

	render() {
		if (!this.id) return
		let $unread = null
		if (this.unread)
			$unread = (
				<div
					class='dot dot__danger'
					style='border: 3px solid white; position: absolute; right: -4px; bottom: -4px; width: 16px; height: 16px'></div>
			)
		let size = 40
		if (this.sm) size = 26
		let convo = store.matchConvo(this.id) || {id: this.id}
		if (this.pinned) {
			return <div style={'position: relative; width: 25px;'}>{this.renderPinnedIcon()}</div>
		}

		if (convo.id === 'csinternalall') {
			return (
				<div style={'position: relative; width:' + size + 'px'}>
					{this.renderAllIcon()}
					{$unread}
				</div>
			)
		}

		if (convo.avatar_url) {
			return (
				<div style={`height: ${size}px; position: relative; width: ${size}px; border-radius: 5px; overflow: hidden;`}>
					<img src={convo.avatar_url} width='100%' />
				</div>
			)
		}

		if (convo.id.startsWith('csinternalcs')) {
			let refconvoid = convo.id.substr(10)
			let refconvo = store.matchConvo(refconvoid) || {id: refconvoid}
			let userid = sb.usersOfConvo(refconvo)[0] || '' // render avatar of the first user
			let user = store.matchUser(userid) || {id: userid}
			return (
				<div style='display: block;vertical-align: top; position: relative;'>
					<Avatar user={user} size={size} notooltip nodot={this.nodot} style='display: block' />
					{$unread}
				</div>
			)
		}

		// csdirect
		if (convo.id.startsWith('csdirect')) {
			let agids = convo.id.substr(8).split('_')
			let agid = agids[0]
			if (agid === store.me().id && agids[1]) agid = agids[1]

			let agent = store.matchAgent()[agid]
			return (
				<div style='display: block;vertical-align: top; position: relative'>
					<Avatar agent={agent} size={size} notooltip nodot={this.nodot} style='display: block' />
					{$unread}
				</div>
			)
		}

		let agids = lo.map(convo.members, (m) => m.id)
		if (agids.length > 1) {
			let agent1 = store.matchAgent()[agids[0]]
			let agent2 = store.matchAgent()[agids[1]]
			return (
				<div style={`height: ${size}px; position: relative; width: ${size}px`}>
					<Avatar
						agent={agent1}
						size={(size * 3) / 4}
						notooltip
						style='position: absolute; top: 0; right: 0; display: block'
						nodot={this.nodot}
					/>
					<Avatar
						agent={agent2}
						size={(size * 3) / 4}
						notooltip
						style='position: absolute; bottom: 0; left: 0; display: block'
						nodot={this.nodot}
					/>
					{$unread}
				</div>
			)
		}

		return (
			<div style={`position: relative; width: ${size}px`}>
				{this.renderUserIcon()}
				{$unread}
			</div>
		)
	},

	methods: {
		renderPinnedIcon() {
			return (
				<svg
					aria-hidden='true'
					focusable='false'
					data-prefix='fad'
					data-icon='bookmark'
					role='img'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 384 512'
					class='svg-inline--fa fa-bookmark fa-w-12 fa-3x'>
					<g class='fa-group'>
						<path
							fill='#ff938b'
							d='M384 48v464L192 400 0 512V48A48 48 0 0 1 48 0h32v326.11a16 16 0 0 0 23.16 14.31L192 296l88.84 44.42A16 16 0 0 0 304 326.11V0h32a48 48 0 0 1 48 48z'
							class='fa-secondary'></path>
						<path
							fill='#ff938b88'
							d='M304 0v326.11a16 16 0 0 1-23.16 14.31L192 296l-88.84 44.42A16 16 0 0 1 80 326.11V0z'
							class='fa-primary'></path>
					</g>
				</svg>
			)
		},

		renderAllIcon() {
			return (
				<svg
					aria-hidden='true'
					focusable='false'
					data-prefix='fad'
					data-icon='globe-asia'
					role='img'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 496 512'
					class='svg-inline--fa fa-globe-asia fa-w-16 fa-5x'>
					<g class='fa-group'>
						<path
							fill='#2196f332'
							d='M312,16.35V50.73a28,28,0,0,1-11.12,22.35l-41.41,31.27a8,8,0,0,0,.86,13.81l10.83,5.41A16,16,0,0,1,280,137.88V216a8,8,0,0,1-8,8h-3.06a8,8,0,0,1-7.15-4.42,4.47,4.47,0,0,0-1.72-1.86,4.42,4.42,0,0,0-6.06,1.54h0l-17.34,29A16,16,0,0,1,222.94,256h-.31a16,16,0,0,0-11.32,4.69l-5.66,5.66a8,8,0,0,0,0,11.31l5.66,5.66A16,16,0,0,1,216,294.63V304a16,16,0,0,1-16,16h-6.1a16,16,0,0,1-14.28-8.85L157,265.92a8,8,0,0,0-10.72-3.6h0a8.14,8.14,0,0,0-2.11,1.53l-19.47,19.46A16,16,0,0,1,113.38,288H2.05C17.74,409.88,121.84,504,248,504c137,0,248-111,248-248C496,141.13,418,44.56,312,16.35Zm96,342.08a16,16,0,0,1-4.69,11.31l-9.57,9.57A16,16,0,0,1,382.43,384H367.27a16,16,0,0,1-11.36-4.74l-13-13a26.78,26.78,0,0,0-25.42-7l-21.27,5.32a15.86,15.86,0,0,1-3.88.48H282a16,16,0,0,1-11.24-4.69l-11.91-11.91a8,8,0,0,1-2.34-5.66V332.6a8,8,0,0,1,5-7.43l39.34-15.74a26.35,26.35,0,0,0,5.59-3.05l23.71-16.89a8,8,0,0,1,4.64-1.48h12.14a8,8,0,0,1,7.39,4.93l5.35,12.85a4,4,0,0,0,3.69,2.46h3.8a4,4,0,0,0,3.84-2.88l4.16-14.49A4,4,0,0,1,379,288h6.06a4,4,0,0,1,4,4v13a8,8,0,0,0,2.34,5.66l11.91,11.91A16,16,0,0,1,408,333.83Z'></path>
						<path
							fill='#2196f399'
							d='M260.07,217.72a4.47,4.47,0,0,1,1.72,1.86,8,8,0,0,0,7.15,4.42H272a8,8,0,0,0,8-8V137.88a16,16,0,0,0-8.84-14.31l-10.83-5.41a8,8,0,0,1-.86-13.81l41.41-31.27A28,28,0,0,0,312,50.73V16.35A248.23,248.23,0,0,0,248,8C111,8,0,119,0,256a249.89,249.89,0,0,0,2.05,32H113.38a16,16,0,0,0,11.31-4.69l19.47-19.46A8,8,0,0,1,157,265.92l22.62,45.23A16,16,0,0,0,193.9,320H200a16,16,0,0,0,16-16v-9.37a16,16,0,0,0-4.69-11.31l-5.66-5.66a8,8,0,0,1,0-11.31l5.66-5.66A16,16,0,0,1,222.63,256h.31a16,16,0,0,0,13.72-7.77L254,219.28a4.42,4.42,0,0,1,6.05-1.57Zm143.24,104.8L391.4,310.61a8,8,0,0,1-2.34-5.66V292a4,4,0,0,0-4-4H379a4,4,0,0,0-3.84,2.88L371,305.37a4,4,0,0,1-3.84,2.88h-3.8a4,4,0,0,1-3.69-2.46l-5.35-12.85a8,8,0,0,0-7.39-4.93H334.79a8,8,0,0,0-4.64,1.48l-23.71,16.89a26.35,26.35,0,0,1-5.59,3.05l-39.34,15.74a8,8,0,0,0-5,7.43v10.2a8,8,0,0,0,2.34,5.66l11.91,11.91A16,16,0,0,0,282,365.06h10.34a15.86,15.86,0,0,0,3.88-.48l21.27-5.32a26.78,26.78,0,0,1,25.42,7l13,13A16,16,0,0,0,367.27,384h15.16a16,16,0,0,0,11.31-4.69l9.57-9.57A16,16,0,0,0,408,358.43v-24.6a16,16,0,0,0-4.69-11.31Z'></path>
					</g>
				</svg>
			)
		},

		renderUserIcon() {
			return (
				<svg
					aria-hidden='true'
					focusable='false'
					data-prefix='fad'
					data-icon='user-friends'
					role='img'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 640 512'
					class='svg-inline--fa fa-user-friends fa-w-20 fa-5x'>
					<g class='fa-group'>
						<path
							fill='#cce2cd'
							d='M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z'
							class='fa-secondary'></path>
						<path
							fill='#a6daa9'
							d='M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z'
							class='fa-primary'></path>
					</g>
				</svg>
			)
		},
	},
}
