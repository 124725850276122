const bowser = require('bowser')
import store from '@sb/store'
const lo = require('lodash')
const sb = require('@sb/util')

const limit = 8

export default {
	name: 'user-trace',
	props: ['user', 'refresh', 'true_user_id'],

	data() {
		return {
			showFull: false,
			loading: false,
			events: [],

			events_error: false,

			loadingScreen: false,
			screenUrl: require('../assets/img/default_image.png'),

			location: {},

			loadingMore: false,
		}
	},

	watch: {
		user(user, olduser) {
			if (lo.get(user, 'id') === lo.get(olduser, 'id')) return
			this.loadingMore = false
			this.loading = false
			this.loadUser()
		},
	},

	mounted() {
		this.loadUser()
		store.onUserEvent(this, (userid, evtype, ev) => {
			if (userid === this.true_user_id || lo.get(this.user, 'id') === userid) {
				let viewevent = lo.get(ev, 'data.event') || {}
				if (viewevent.type === 'content_viewed') {
					this.events = [viewevent, ...this.events]
					let screenUrl = lo.get(viewevent, 'data.product.url')
					this.reloadScreenshot(screenUrl)
				}
			}
		})
	},

	methods: {
		copyText(text) {
			if (!text) return
			sb.copyToClipboard(text)
			this.$showSuccess(this.$t('copied_to_clipboard') + ': ' + text)
		},

		extractHostname(text) {
			if (!text) return this.$t('unknown')
			let match = sb.extractHostname(text)
			return lo.get(match, '1', this.$t('unknown'))
		},

		async reloadScreenshot(screenUrl) {
			if (!screenUrl) return
			if (screenUrl === this.screenUrl) return

			let flag = sb.randomString(6) + Date.now()
			this._flag = flag
			this.loadingScreen = true
			let {body} = await store.loadScreen(screenUrl, this._currentPlatform)
			this.loadingScreen = false
			if (this._flag !== flag) return // prevent rat race render

			this.screenUrl =
				lo.get(body, this._currentPlatform + '_screenshoot_url') ||
				lo.get(body, 'desktop_screenshoot_url') ||
				require('../assets/img/default.jpg')
		},

		renderLocation() {
			let location = this.location || {}
			let country_code = location.country_code || ''
			country_code = country_code.toLowerCase()

			let $flag = <MapPinIcon size='18' stroke='gray' style='margin-top: -2px' />
			if (country_code) $flag = <i class={`flag-icon flag-icon-${country_code}`} style='margin-top: 0'></i>

			let $cityName = <div class='ml-2 text__muted'>{this.$t('unknown')}</div>
			if (location.city_name || location.country_name) {
				$cityName = <div class='ml-2 text__truncate'>{location.city_name}</div>
			}

			return (
				<div class='user_trace__location' vOn:click={() => this.copyText(location.ip)} v-tooltip={location.ip}>
					{$flag}
					{$cityName}
				</div>
			)
		},

		async loadUser() {
			if (!this.user) return
			await store.fetchUser(this.user.id)
			this.loadEvents()

			this.showFull = false
			this.events = null

			let USERID = this.user.id
			this.screenUrl = require('../assets/img/loading_static.svg')
			let screenUrl = lo.get(this.user, 'latest_content_view.data.product.url', '')
			if (!screenUrl) {
				this.loadingScreen = false
				this.screenUrl = require('../assets/img/default.jpg')
				return
			}
			let ua = lo.get(this.user, 'latest_content_view.by.device.user_agent')
			let browser = ua && bowser.getParser(ua)
			let platform = lo.get(browser, 'parsedResult.platform.type')
			if (platform && platform !== 'desktop') platform = 'mobile'
			this._currentPlatform = platform

			this.loadingScreen = true
			let {body} = await store.loadScreen(screenUrl, platform)
			this.loadingScreen = false

			let ip = lo.get(this.user, 'latest_content_view.by.device.ip')
			this.location = await store.geoip(ip)

			// avoid re renderring
			if (this.user.id !== USERID) return

			this.screenUrl =
				lo.get(body, platform + '_screenshoot_url') ||
				lo.get(body, 'desktop_screenshoot_url') ||
				require('../assets/img/default.jpg')

			// stale, should reget
			if (body.agent_confirmed) {
				await sb.sleep(6000)
				let {body} = await store.loadScreen(screenUrl)
				this.screenUrl =
					lo.get(body, platform + '_screenshoot_url') ||
					lo.get(body, 'desktop_screenshoot_url') ||
					require('../assets/img/default.jpg')
			}
		},

		renderScreenShoot() {
			if (this.loadingScreen)
				return (
					<div
						class='d-flex align-items-center no-shrink'
						style='background: whitesmoke;width: 160px; height: 100px; border: 1px solid lightgray; border-radius: 4px'
					>
						<Spinner static center size='20' />
					</div>
				)

			const DEFAULT_IMG = require('../assets/img/default.jpg')
			return (
				<img2
					clickable={this.screenUrl !== DEFAULT_IMG}
					v-tooltip={this.screenUrl === DEFAULT_IMG ? this.$t('cannot_take_screenshot') : ''}
					object_fit='contain'
					width='160'
					height='90'
					class='no-shrink'
					src={this.screenUrl}
					style='width: 160px; height: 100px; object-fit: contain; border: 1px solid lightgray; border-radius: 4px'
				/>
			)
		},

		renderReferrer() {
			let startev = lo.get(this.user, 'start_content_view')
			let $ref = null
			let $source = null
			let source = lo.get(startev, 'by.device.source')
			if (source) {
				$source = <div class={'user_info_source_badge'}>{this.$t(source)}</div>
				if (source === 'advertising') {
					$source = (
						<div class={'user_info_source_badge'} style='font-size: 12px'>
							{lo.get(startev, 'by.device.utm.name', this.$t('advertising'))}
						</div>
					)
				}
			}
			let referrer = lo.get(startev, 'by.device.source_referrer')
			if (referrer) {
				$ref = (
					<div class='flex__1 ml-2 d-flex align-items-center' style='overflow: hidden'>
						<span class='text__muted mr-2'>{this.$t('from').toLowerCase()}</span>
						<a class='link link__secondary text__truncate' href={referrer} target='_blank'>
							{this.extractHostname(referrer)}
						</a>
					</div>
				)
			}

			return (
				<div class='d-flex align-items-center mb-1'>
					{$source}
					{$ref}
				</div>
			)
		},

		renderLinkIcon() {
			return (
				<svg width='18' height='18' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect width='17' height='17' rx='5' fill='#D1D1D1' />
					<path
						d='M8 9.43144C8.16236 9.61145 8.35616 9.75446 8.57004 9.85209C8.78392 9.94971 9.01358 10 9.24557 10C9.47757 10 9.70723 9.94971 9.92111 9.85209C10.135 9.75446 10.3288 9.61145 10.4911 9.43144L12.4841 7.26656C12.8144 6.90771 13 6.421 13 5.91351C13 5.40601 12.8144 4.9193 12.4841 4.56045C12.1537 4.2016 11.7057 4 11.2385 4C10.7713 4 10.3233 4.2016 9.99292 4.56045L9.7438 4.83106'
						stroke='white'
						stroke-width='1.75'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M9 7.56856C8.83764 7.38855 8.64384 7.24554 8.42996 7.14791C8.21608 7.05029 7.98642 7 7.75443 7C7.52243 7 7.29277 7.05029 7.07889 7.14791C6.86501 7.24554 6.67121 7.38855 6.50885 7.56856L4.51593 9.73344C4.18559 10.0923 4 10.579 4 11.0865C4 11.594 4.18559 12.0807 4.51593 12.4395C4.84628 12.7984 5.29433 13 5.76151 13C6.22869 13 6.67673 12.7984 7.00708 12.4395L7.2562 12.1689'
						stroke='white'
						stroke-width='1.75'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</svg>
			)
		},

		renderClockIcon() {
			return (
				<svg
					style='position: relative; left: -2px'
					width='22'
					v-tooltip={this.$t('session_length')}
					height='22'
					viewBox='0 0 22 22'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
						fill='#D1D1D1'
					/>
					<path
						d='M11 8V11.75L13 14'
						stroke='white'
						stroke-width='1.75'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</svg>
			)
		},

		renderMonitorIcon() {
			let ua = lo.get(this.user, 'latest_content_view.by.device.user_agent')
			let browser = ua && bowser.getParser(ua)
			let platform = lo.get(browser, 'parsedResult.platform.type')

			if (platform !== 'desktop') return null
			return (
				<svg
					width='23'
					v-tooltip={this.$t('notify_web_browser') + ', ' + this.$t('device')}
					height='23'
					style='position: relative; left: -2px'
					viewBox='0 0 23 23'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M2.875 18.2085H20.125'
						stroke='#D1D1D1'
						stroke-width='1.5'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M17.2498 5.75H5.74984C5.22056 5.75 4.7915 6.17906 4.7915 6.70833V14.375C4.7915 14.9043 5.22056 15.3333 5.74984 15.3333H17.2498C17.7791 15.3333 18.2082 14.9043 18.2082 14.375V6.70833C18.2082 6.17906 17.7791 5.75 17.2498 5.75Z'
						fill='#D1D1D1'
						stroke='#D1D1D1'
						stroke-width='1.5'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</svg>
			)
		},

		renderMobileIcon() {
			let ua = lo.get(this.user, 'latest_content_view.by.device.user_agent')
			let browser = ua && bowser.getParser(ua)
			let platform = lo.get(browser, 'parsedResult.platform.type')

			if (platform !== 'mobile') return null
			return (
				<svg
					width='24'
					v-tooltip={this.$t('notify_web_browser') + ', ' + this.$t('device')}
					height='24'
					style='position: relative; left: -2px'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M16 4H8C7.44772 4 7 4.44772 7 5V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V5C17 4.44772 16.5523 4 16 4Z'
						fill='#DEDEDE'
						stroke='#DEDEDE'
						stroke-width='1.5'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path d='M11 5H13' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
					<path d='M12 17V17.01' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
				</svg>
			)
		},

		renderPinIcon() {
			return (
				<svg
					width='21'
					height='21'
					style='position: relative; top: -1px'
					v-tooltip={this.$t('location')}
					viewBox='0 0 21 21'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M15.4499 14.0497L11.7373 17.7623C11.4091 18.0901 10.9643 18.2743 10.5004 18.2743C10.0366 18.2743 9.59177 18.0901 9.26363 17.7623L5.55013 14.0497C4.5712 13.0707 3.90455 11.8235 3.63448 10.4656C3.36441 9.10774 3.50306 7.70029 4.03288 6.42123C4.5627 5.14217 5.45991 4.04894 6.61105 3.27979C7.76219 2.51063 9.11555 2.1001 10.5 2.1001C11.8845 2.1001 13.2378 2.51063 14.389 3.27979C15.5401 4.04894 16.4373 5.14217 16.9671 6.42123C17.4969 7.70029 17.6356 9.10774 17.3655 10.4656C17.0955 11.8235 16.4288 13.0707 15.4499 14.0497Z'
						fill='#D1D1D1'
					/>
					<path
						d='M10.5 11.5498C11.9497 11.5498 13.125 10.3746 13.125 8.9248C13.125 7.47506 11.9497 6.2998 10.5 6.2998C9.05025 6.2998 7.875 7.47506 7.875 8.9248C7.875 10.3746 9.05025 11.5498 10.5 11.5498Z'
						fill='white'
					/>
				</svg>
			)
		},

		renderPageViews() {
			let pageviews = lo.filter(this.events, (e) => e.type === 'content_viewed')
			pageviews = lo.orderBy(pageviews, ['created'], ['desc'])
			// let isOnline = sb.isUserOnline(this.user)
			// if (isOnline) pageviews.shift()

			if (this.events_error) {
				let $tryAgainBtn = (
					<div class='ml-2 link' vOn:click={(_) => this.loadUser(this.user.id)}>
						{this.$t('try_again')}
					</div>
				)
				if (this.loading) $tryAgainBtn = <Spinner class='ml-2' size='16' mode='blue' />
				return (
					<div class='text__muted d-flex align-items-center'>
						<AlertTriangleIcon style='margin-top:-4px' class='mr-2 text__warning' size='18' />{' '}
						{this.$t('load_user_events_error')}. {$tryAgainBtn}
					</div>
				)
			}

			if (!this.showFull) pageviews = lo.take(pageviews, limit)

			let $ret = lo.map(pageviews, (ev, index) => {
				let content = lo.get(ev, 'data.product', {})
				return (
					<a key={ev.id} href={content.url} target='_blank' class='user_events__link' v-tooltip={content.url}>
						<Icon name='external-link' stroke-width='1.5' size='16' style='flex-shrink: 0' />
						<span class='user_events__link_title'>{content.title || content.name || content.url}</span>
						<Time class='user_events__time' ago time={ev.created} />
					</a>
				)
			})
			return (
				<fragment>
					{$ret} {this.loading && <Spinner class='mt-2' size='16' mode='blue' />}
				</fragment>
			)
		},

		renderHandle() {
			if (this.loadingMore)
				return (
					<div class='profile_section__more'>
						<Spinner mode='blue' size='20' />
					</div>
				)

			let pageviews = lo.filter(this.events, (e) => e.type === 'content_viewed')

			let more = pageviews.length - limit
			if (more <= 0) return null

			if (this.showFull) {
				return (
					<div class='profile_section__more' vOn:click={this.toogleShowFull}>
						{this.$t('collapse')}
					</div>
				)
			}

			return (
				<div class='profile_section__more' vOn:click={this.toogleShowFull}>
					{this.$t('show_more_pages', [more])}
				</div>
			)
		},

		async loadEvents() {
			this.loading = true
			let uid = lo.get(this.user, 'id')
			const LIMIT_EVENTS = 10
			const MAX_RETRY = 10
			let result = []
			let startHour = 0
			let endHour = Math.floor(Date.now() / 3600000) - 72

			var err
			for (let i = 0; i < MAX_RETRY; i++) {
				if (lo.size(result) >= LIMIT_EVENTS) break
				let {events, anchor, error} = await store.fetchUserEvents2(
					this.true_user_id || this.user.id,
					startHour,
					endHour,
				)
				if (uid != lo.get(this.user, 'id')) return // outdated
				if (error) {
					err = error
					break
				}
				if (!lo.size(events)) break
				events = lo.filter(events, (ev) => ev.type === 'content_viewed')
				startHour = anchor
				result = [...result, ...events]
				if (anchor < 0) break
				if (anchor === startHour) break
			}

			if (uid != lo.get(this.user, 'id')) return // outdated
			this.loading = false
			this.events_error = false
			if (err) {
				this.events_error = true
				return
			}
			this.events = result
			this.loadingMore = true

			await sb.sleep(5000)
			result = []
			startHour = 0
			if (uid != lo.get(this.user, 'id')) return // outdated
			for (let i = 0; i < MAX_RETRY; i++) {
				if (lo.size(result) >= LIMIT_EVENTS) break
				let {events, anchor, error} = await store.fetchUserEvents2(this.true_user_id || this.user.id, startHour)
				if (uid != lo.get(this.user, 'id')) return // outdated
				if (error) return {error}
				if (!lo.size(events)) break
				events = lo.filter(events, (ev) => ev.type === 'content_viewed')
				startHour = anchor
				result = [...result, ...events]
				if (anchor < 0) break
				if (anchor === startHour) break
			}

			if (uid != lo.get(this.user, 'id')) return // outdated
			this.loadingMore = false
			this.events_error = false
			if (err) {
				this.events_error = true
				return
			}
			this.events = result
		},

		toogleShowFull() {
			this.showFull = !this.showFull
		},

		hasChannelSubiz() {
			if (lo.get(this.user, 'channel') === 'subiz') return true

			let secondaries = lo.get(this.user, 'secondaries', [])
			let result = false
			lo.each(secondaries, (profile) => {
				if (profile.channel === 'subiz') {
					result = true
					return -1 // break loop
				}
			})

			return result
		},
	},

	render() {
		if (!this.user) return null

		if (!this.hasChannelSubiz()) return null
		let channelsource = sb.getUserTextAttr(this.user, 'channel_source') || ''
		let channel = this.user.channel
		// if (channel != 'subiz' || channelsource.indexOf('fabikon') > -1 || channelsource.indexOf('zalokon') > -1) {
		// return null
		//}
		if (this.refresh) lo.noop()

		let $title = <span class='profile_section_title'>{this.$t('session')}</span>
		let $online = sb.isUserOnline(this.user) ? (
			<div class='d-flex align-items-center ml-auto text__success text__semibold'>
				<div class='dot dot__success mr-2' style='margin-top: 0; width: 10px; height: 10px' />
				{this.$t('is_viewing')}
			</div>
		) : (
			<Time class='text__muted ml-auto' time={lo.get(this.user, 'latest_content_view.created', 0)} />
		)

		let latest = lo.get(this.user, 'latest_content_view')
		if (!latest) return null

		let ua = lo.get(this.user, 'latest_content_view.by.device.user_agent')
		let browser = ua && bowser.getParser(ua)
		let platform = lo.get(browser, 'parsedResult.platform.type')

		let timeonsite = 30
		let latestcreated = lo.get(this.user, 'latest_content_view.created')
		let startcreated = lo.get(this.user, 'start_content_view.created')
		if (startcreated && latestcreated) timeonsite = (latestcreated - startcreated) / 1000
		if (timeonsite <= 0) timeonsite = 30

		let pageviews = lo.filter(this.events, (e) => e.type === 'content_viewed')

		return (
			<div class='user_trace'>
				<div class='user_profile__head'>
					{$title} {$online}
				</div>
				<div class='mr-4 ml-4 mt-2'>
					<div class='d-flex'>
						{this.renderScreenShoot()}
						<div class='ml-3' style='overflow: hidden;'>
							{this.renderReferrer()}
							<div class='d-flex align-items-center mb-1'>
								{this.renderClockIcon()}
								<Time duration style='margin-left: 8px' time={timeonsite} />
							</div>
							<div class='d-flex align-items-center mb-1'>
								<div class='no-shrink'>
									{this.renderMobileIcon()}
									{this.renderMonitorIcon()}
								</div>
								<div style='margin-left: 8px' class='text__truncate'>
									{lo.get(browser, 'parsedResult.browser.name')}, {this.$t(platform)}
								</div>
							</div>
							<div class='d-flex align-items-center'>
								<div class='no-shrink'>{this.renderPinIcon()}</div>
								<div class='ml-3 text__truncate'>{this.renderLocation()}</div>
							</div>
						</div>
					</div>

					<div class='user_events__body'>{this.renderPageViews()}</div>
					{this.renderHandle()}
				</div>
			</div>
		)
	},
}
