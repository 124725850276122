var Color = require('color')
const lo = require('lodash')
import Spinner from './spinner.js'

export default {
	name: 'user-label',
	props: ['label', 'loading', 'deletable'],

	render() {
		if (!this.label || lo.size(this.label) == 0) return null
		var cls = 'user_label '
		let tagColor = this.label.color || 'black'

		var color = new Color('black')
		try {
			color = new Color(tagColor)
		} catch (e) {}

		let bgcolor = whiten(color, 0.85)
		let bordercolor = whiten(color, 0.5)

		let $loading = null
		if (this.loading) {
			$loading = <Spinner static class='ml-2' size='12' mode='dark' />
			bgcolor = whiten(color, 0.98)
			bordercolor = whiten(color, 0.9)
			color = whiten(color, 0.5)
		}

		let $delete = null
		if (this.deletable && !this.loading) {
			$delete = (
				<span vOn:click={(_) => this.$emit('delete', this.label)} class='user_label__delete pl-1'>
					<Icon name='x' style='width: 14px; height: 14px' size='14' stroke-width='2' class='cursor__pointer' />
				</span>
			)
		}

		let style = {
			color: color,
			background: bgcolor,
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: bordercolor,
			marginTop: 0,
		}

		let title = lo.truncate(this.label.title || '', {length: 20})
		return (
			<div class={cls} vOn:click={(_) => this.$emit('click', this.label)}>
				<div class='dot dot__lg mr-2' style={style}></div>
				<span class='user_label__title' title={lo.get(this.label, 'title', '')}>
					{title}
				</span>
				{$delete}
				{$loading}
			</div>
		)
	},
}

// return the combination of color with alpha on the white background
function whiten(color, alpha) {
	let newred = 255 - (255 - color.red()) * (1 - alpha)
	let newblue = 255 - (255 - color.blue()) * (1 - alpha)
	let newgreen = 255 - (255 - color.green()) * (1 - alpha)
	return Color.rgb(newred, newgreen, newblue)
}
