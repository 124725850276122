function normalizeParamsPopupNotify(type, params) {
	let noty = {type, created: Date.now(), title: type}
	if (typeof params[0] === 'string' && typeof params[1] === 'string') {
		noty.title = params[0]
		noty.description = params[1]
		noty.timeout = params[2]
	} else if (typeof params[0] === 'string' && typeof params[1] === 'number') {
		noty.description = params[0]
		noty.timeout = params[1]
	} else if (typeof params[0] === 'string') {
		noty.description = params[0]
	}
	return noty
}

var Toast = {}

Toast.install = (Vue) => {
	Vue.prototype.$showSuccess = function(...params) {
		// params = [title, description, timeout] or [description, timeout] or [timeout]
		let filterParams = normalizeParamsPopupNotify('success', params)
		this.$root.$emit('_popup_notify', filterParams)
	}

	Vue.prototype.$showWarning = function(...params) {
		// params = [title, description, timeout] or [description, timeout] or [timeout]
		let filterParams = normalizeParamsPopupNotify('warning', params)
		this.$root.$emit('_popup_notify', filterParams)
	}

	Vue.prototype.$showError = function(...params) {
		// params = [title, description, timeout] or [description, timeout] or [timeout]
		let filterParams = normalizeParamsPopupNotify('error', params)
		this.$root.$emit('_popup_notify', filterParams)
	}

	Vue.prototype.$confirm = function() {
		this.$root.$emit('_confirm_request')

		let rs
		let responseFn = function(param) {
			// {ok, id}
			if (param.id !== id) return
			this.$root.$off('_confirm_response', responseFn)
			rs(param.ok)
		}
		this.$root.$on('_confirm_response', responseFn)
		return new Promise((resolve) => {
			rs = resolve
		})
	}
}

export default Toast
