import {VueTagsInput, createTags} from '@johmun/vue-tags-input'
import store from '@sb/store'

const emailAddresses = require('email-addresses')
const lo = require('lodash')
const sb = require('@sb/util')

//parse RFC 5322
const parseEmail = (email) => lo.get(emailAddresses.parseOneAddress(email), 'address', email)

const validation = [
	{
		classes: 'not-email',
		rule: (em) => {
			let email = lo.get(em, 'text')
			if (!email || !email.trim) return false // allow empty
			return !sb.checkEmail(lo.trim(lo.get(em, 'text')))
		},
	},
]

export default {
	name: 'email-header',
	props: ['uid', 'cid', 'initEvent', 'mini', 'noTo', 'alwayFull', 'no_cc'],
	data() {
		return {
			cc: '',
			subject: '',
			from: '',
			to: '',
			tos: null,
			froms: [],
			ccs: [],

			isFillingDefault: false,
		}
	},

	watch: {
		initEvent() {
			// reset
			//this.fillDefault()
		},
		froms(froms, old) {
			if (lo.isEqual(froms, old)) return // prevent loop
			this.$emit('changed')
		},
		ccs(css, old) {
			if (lo.isEqual(css, old)) return // prevent loop
			this.$emit('changed')
		},
		tos(tos, old) {
			if (lo.isEqual(tos, old)) return // prevent loop
			this.$emit('changed')
		},
		subject(subject, old) {
			if (lo.isEqual(subject, old)) return // prevent loop
			this.$emit('changed')
		},
	},

	created() {
		this.fillDefault()
	},

	methods: {
		async fillDefault() {
			this.isFillingDefault = true
			if (lo.get(this.initEvent, 'format')) this.toggleMini(false) // only expand when createing conversation

			let ev = {data: {message: this.initEvent}} // last ev from user

			let initFrom = sb.getMsgField(ev, 'from')
			if (initFrom && lo.size(this.initEvent)) this.froms = [{text: initFrom}]
			if (!lo.size(this.froms)) this.froms = await getFrom(this.uid, ev)

			if (!this.tos) {
				let tos = getTos(this.cid, ev)
				this.tos = []
				if (tos[0]) this.tos = [tos[0]]
			}

			if (lo.size(getFieldTo(ev)) > 0) this.tos = lo.map(getFieldTo(ev), (to) => ({text: lo.trim(to)}))

			if (lo.size(this.ccs) === 0) {
				this.ccs = lo.map(sb.getMsgField(ev, 'ccs'), (cc) => ({text: cc}))
			}

			this.subject = sb.getMsgField(ev, 'subject')
			if (!this.subject && lo.size(this.initEvent)) {
				// subject
				let subject
				if (this.cid) {
					let channel = lo.get(store.matchConvo(this.cid), 'touchpoint.channel')
					let state = lo.get(store.matchConvo(this.cid), 'state')
					if (channel !== 'email' || state === 'ended') subject = ''
					else {
						let msgs = lo.filter(store.listMessages(this.cid), (item) => item.type === 'message_sent')
						if (!lo.size(msgs)) return
						let msg = msgs[0]
						if (sb.getMsgField(msg, 'subject')) subject = sb.getMsgField(msg, 'subject')
						subject = subject || ''
						if (!subject.startsWith('Re: ')) subject = 'Re: ' + subject
					}
				}
				this.subject = subject
			}
			this.isFillingDefault = false
		},

		Reset() {
			this.tos = null
			this.ccs = []
			this.froms = []
			this.subject = ''
			this.to = ''
			this.from = ''
		},

		GetFields() {
			let fields = []
			let from = this.GetFrom()
			if (from) fields.push({value: JSON.stringify(from), key: 'from'})
			// fields.push({key: 'In-Reply-To', value: In-Reply-To
			fields.push({value: JSON.stringify(this.GetTos()), key: 'to'})
			fields.push({value: JSON.stringify(this.GetCcs()), key: 'ccs'})
			fields.push({value: JSON.stringify(this.GetSubject()), key: 'subject'})

			if (this.cid) {
				let lastmsgid = ''
				let events = store.listMessages(this.cid)
				lo.map(events, (ev) => {
					if (ev.type !== 'message_sent') return
					let byType = lo.get(ev, 'by.type')

					if (byType === 'user') {
						let msgid = sb.getMsgField(ev, 'message-smtp-id')
						if (msgid) lastmsgid = msgid
					}
				})

				if (lastmsgid) {
					fields.push({key: 'in-reply-to', value: JSON.stringify(lastmsgid)})
				}
			}
			return fields
		},

		GetFrom() {
			let froms = lo
				.filter(this.froms, (tag) => tag && tag.text && tag.text.trim() && sb.checkEmail(tag.text))
				.map((tag) => tag.text)
			if (froms.length > 0) return froms[0]
		},

		GetTos() {
			return lo
				.filter(this.tos, (tag) => tag && tag.text && tag.text.trim() && sb.checkEmail(tag.text))
				.map((tag) => tag.text)
		},

		GetSubject() {
			return this.subject
		},

		GetCcs() {
			return lo
				.get(this, 'ccs', [])
				.filter((tag) => tag && tag.text.trim() && sb.checkEmail(tag.text))
				.map((tag) => tag.text)
		},

		onToKeyup(e) {
			// HACK to make auto complete disappear on ESC
			if (e.key === 'Escape') {
				if (this.$refs.to_tag) this.$refs.to_tag.focused = false
				return
			}
			if (this.$refs.to_tag) this.$refs.to_tag.focused = true
		},

		onFromKeyup(e) {
			// HACK to make auto complete disappear on ESC
			if (e.key === 'Escape') {
				if (this.$refs.to_tag) this.$refs.from_tag.focused = false
				return
			}
			if (this.$refs.to_tag) this.$refs.to_tag.focused = true
		},

		onCcKeyup(e) {
			// HACK to make auto complete disappear on ESC
			if (e.key === 'Escape') {
				if (this.$refs.to_tag) this.$refs.cc_tag.focused = false
				return
			}
			if (this.$refs.to_tag) this.$refs.to_tag.focused = true
		},

		onToFocus() {
			this.toggleMini(false)
		},

		onCcFocus() {
			// if (!this.cc) this.cc = ' '
		},

		onFromFocus() {
			// if (!this.from) this.from = ' '
		},

		toggleMini(toggle) {
			if (toggle === false) {
				this.$nextTick(() => {
					if (this.$refs.to_tag) this.$refs.to_tag.focused = true
				})
				return this.$emit('miniChange', false)
			}
			if (toggle === true) return this.$emit('miniChange', true)
			this.$emit('miniChange', !this.mini)
		},

		onSubjectKeydown(e) {
			if ((e.key === 'Tab' && !e.shiftKey) || e.key === 'Enter') {
				this.$emit('blur')
				e.preventDefault()
			}
		},

		setTos(tgs) {
			this.tos = tgs
		},

		setFroms(tgs) {
			this.froms = tgs
		},

		setCCs(tgs) {
			this.ccs = tgs
		},
	},

	render() {
		let emails = getTos(this.cid)
		// also should take emails in to fields

		let autoCompleteTo = lo.filter(emails, (i) => lo.toLower(i.text).indexOf(lo.toLower(this.to)) !== -1)
		let autoCompleteCc = lo.filter(emails, (i) => lo.toLower(i.text).indexOf(lo.toLower(this.cc)) !== -1)

		let autoCompleteFrom = []
		let incommingEmails = lo.filter(
			sb.getAllEmail(),
			(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
		)
		incommingEmails = lo.orderBy(incommingEmails, ['address'], ['asc'])
		incommingEmails = lo.map(incommingEmails, (em) => ({text: em.address}))
		autoCompleteFrom = lo.filter(incommingEmails, (i) => lo.toLower(i.text).indexOf(lo.toLower(this.from)) !== -1)

		let $from = (
			<div class='email_header__from' vOn:keyup={this.onFromKeyup}>
				<div class='email_header__label'>{this.$t('from')}:</div>
				<div style='flex:1;'>
					<VueTagsInput
						vModel_trim={this.from}
						ref='from_tag'
						vOn:focus={this.onFromFocus}
						max-tags={1}
						validation={validation}
						autocomplete-items={autoCompleteFrom}
						autocomplete-min-length={lo.size(this.froms) ? 1 : 0}
						tags={createTags(this.froms)}
						vOn:tags-changed={this.setFroms}
						allowEditTags
						placeholder=''
					/>
				</div>
			</div>
		)

		let $subject = (
			<div class='email_header__subject'>
				<div class='email_header__label'>{this.$t('subject')}:</div>
				<input
					class='email_header__subject_input'
					vOn:keydown={this.onSubjectKeydown}
					vModel_trim={this.subject}
					type='text'
				/>
			</div>
		)

		let $cc = (
			<div class='d-flex' style='flex: 2'>
				<div class='email_header__label'>Cc:</div>
				<div style='flex:1' vOn:keyup={this.onCcKeyup}>
					<VueTagsInput
						vModel_trim={this.cc}
						ref='cc_tag'
						vOn:focus={this.onCcFocus}
						validation={validation}
						autocomplete-items={autoCompleteCc}
						tags={createTags(this.ccs)}
						vOn:tags-changed={this.setCCs}
						allow-edit-tags
						separators={[';', ',']}
						placeholder=''
					/>
				</div>
			</div>
		)
		let $miniBtn = (
			<div class='minimize-btn' vOn:click={this.toggleMini}>
				<Icon name='chevron-down' />
			</div>
		)
		let headertocls = 'email_header__to'
		if (this.mini) {
			headertocls += ' email_header__to__mini'
			$from = null
			$cc = null
			$subject = null
			$miniBtn = (
				<div class='minimize-btn' vOn:click={this.toggleMini}>
					<Icon name='chevron-up' />
				</div>
			)
		}

		let $to = null

		if (!this.noTo)
			$to = (
				<fragment>
					<div class='email_header__label'>{this.$t('to')}:</div>
					<div style='flex:3' vOn:keyup={this.onToKeyup}>
						<VueTagsInput
							vModel_trim={this.to}
							ref='to_tag'
							vOn:focus={this.onToFocus}
							validation={validation}
							autocomplete-items={autoCompleteTo}
							tags={createTags(this.tos || [])}
							vOn:tags-changed={this.setTos}
							placeholder=''
							allow-edit-tags
							separators={[';', ',']}
						/>
					</div>
				</fragment>
			)

		// if (this.noTo) $to = null

		if (this.alwayFull) $miniBtn = null
		return (
			<div class='email_header' style='position: relative;' vOn:click_stop={() => false}>
				{/* just remove click_stop of email_header. DOnt know why must have click_stop*/}
				{$miniBtn}
				{$from}
				{!this.no_cc && (
					<div class={headertocls}>
						{$to}
						{$cc}
					</div>
				)}
				{$subject}
				{this.isFillingDefault && (
					<div
						class='d-flex align-items-center justify-content-center'
						style='background: #e5e5e540; width: 100%; height: 100%; position: absolute; top: 0; left: 0;'
					>
						<Spinner size='24' mode='blue' />
					</div>
				)}
			</div>
		)
	},
}

function getFieldTo(ev) {
	let deliveredTo = sb.getMsgField(ev, 'delivered-to') || []
	if (!deliveredTo) deliveredTo = []
	else if (typeof deliveredTo === 'string') deliveredTo = [deliveredTo]
	if (deliveredTo.length > 0) return deliveredTo
	return sb.getMsgField(ev, 'to') || []
}

function getTos(cid, initEv) {
	let tos = getFieldTo(initEv)
	if (lo.size(tos) > 0) return lo.map(tos, (to) => ({text: lo.trim(to)}))

	let emails = {}
	if (cid) {
		let convo = store.matchConvo(cid)
		let users = sb.usersOfConvo(convo)
		lo.map(users, (uid) => {
			let user = store.matchUser(uid)
			let usersEmail = sb.getUserTextAttr(user, 'emails') || ''
			lo.each(usersEmail.split(','), (em) => {
				em = em.trim()
				emails[em] = true
			})
		})

		// lookup in converation old email
		let events = store.listMessages(cid)
		lo.map(events, (ev) => {
			if (ev.type !== 'message_sent') return
			let byType = lo.get(ev, 'by.type')

			if (byType === 'user') {
				let oldFrom = sb.getMsgField(ev, 'from')
				if (oldFrom) emails[parseEmail(oldFrom)] = true
				// if (oldFrom) return [{ text: parseEmail(oldFrom) }]
			}

			if (byType === 'agent') {
				let oldTos = sb.getMsgField(ev, 'to')
				lo.map(oldTos, (to) => {
					emails[parseEmail(to)] = true
				})
			}
		})
	}

	return lo.map(emails, (_, em) => ({text: lo.trim(em)})).filter((em) => em.text)
}

async function getFrom(uid, ev) {
	let froms = []
	let initFrom = sb.getMsgField(ev, 'from')

	if (initFrom && lo.size(this.initEvent)) froms = [{text: initFrom}]
	// smart detect most used email
	let allconvos = lo.map(store.matchUserConvos(uid), (convo) => {
		if (!convo.actived) convo.actived = convo.created
		return convo
	})
	allconvos = lo.filter(
		allconvos,
		(convo) => lo.get(convo, 'touchpoint.channel') === 'email' && lo.size(convo.last_message_sent),
	)
	allconvos = lo.orderBy(allconvos, ['last_message_sent.created'], ['desc'])
	let conv
	await flow.map(allconvos, 1, async (convo) => {
		if (conv) return
		let list_ev = store.listMessages(convo.id)
		if (!lo.size(list_ev)) await store.fetchMoreMessages(convo.id)
		list_ev = store.listMessages(convo.id)
		let err_ev = lo.find(list_ev, (ev) => {
			let error = lo.find(lo.get(ev, 'data.message.pongs'), (p) => {
				if (p.type !== 'ack') return false
				if (!p.ack_error) return false
				return true
			})
			return ev.type === 'message_sent' && error
		})
		if (!err_ev) conv = convo
	})

	// let convo = store.matchConvo(email_convo_id) || {}
	let msg = lo.get(conv, 'last_message_sent')
	let byType = lo.get(msg, 'by.type')
	let from = ''
	if (byType === 'user') {
		from = sb.getMsgField(msg, 'delivered-to')
	} else {
		from = sb.getMsgField(msg, 'from')
	}

	if (from && !lo.get(sb.getAllEmail()[from], `outbound_disabled`)) froms = [{text: parseEmail(from)}]
	if (!lo.size(froms)) {
		let availableFromEmail = lo.filter(
			sb.getAllEmail(),
			(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
		)
		availableFromEmail = lo.orderBy(availableFromEmail, ['address'], ['asc'])
		if (lo.size(availableFromEmail)) froms = [{text: parseEmail(lo.get(lo.first(availableFromEmail), 'address'))}]
	}

	return froms
}
