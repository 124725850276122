import store from '@sb/store'
import {getAttributeName, UNUSED_USER_ATTRIBUTES} from '../common.js'
const lo = require('lodash')
const sb = require('@sb/util')

export default {
	name: 'edit-user-primary-attr-modal',
	props: ['uid', 'attr', 'open'],

	data() {
		return {
			editAttr: {},
			saving: false,
		}
	},

	mounted() {
		this.resetData()
		document.addEventListener('keyup', this.handleCloseModalKeyup)
	},

	destroyed() {
		document.removeEventListener('keyup', this.handleCloseModalKeyup)
	},

	watch: {
		async open() {
			if (this.open) {
				this.resetData()
				this.convertPhoneNumber()
				await this.$nextTick()
				this.$refs.main_value_input && this.$refs.main_value_input.focus()
			}
		},
	},

	methods: {
		resetData() {
			this.editAttr = lo.cloneDeep(this.attr)
		},

		// try to make phone number starts with 84 => 0
		convertPhoneNumber() {
			if (this.editAttr.key !== 'phones') return
			let attr = lo.cloneDeep(this.editAttr) || {}
			attr.text = sb.displayUserPhoneNumber(attr.text)
			attr.other_values = lo.map(attr.other_values, (val) => sb.displayUserPhoneNumber(val))
			this.editAttr = attr
		},

		handleCloseModalKeyup(evt) {
			if (evt.keyCode === 27) {
				if (this.isMergeModalOpened) return
				this.$emit('close')
			}
		},

		async submit() {
			if (this.saving) return
			this.saving = true
			let attr = lo.cloneDeep(this.editAttr)
			if (attr.other_values && !lo.size(attr.other_values)) {
				attr.other_values = ['']
			}
			if (!attr.text) {
				attr.action = 'delete'
			}

			let res = await this.$updateContactAttribute({uid: this.uid, attr})
			this.saving = false
			if (res) {
				this.$emit('close')
				this.$emit('success')
			}
		},

		async addOtherValue() {
			let attr = lo.cloneDeep(this.editAttr)
			let otherValues = attr.other_values || []
			otherValues.push('')
			attr.other_values = otherValues
			this.editAttr = attr

			await this.$nextTick()
			this.$refs[`other_value_input_${otherValues.length - 1}`] &&
				this.$refs[`other_value_input_${otherValues.length - 1}`].focus()
		},

		removeOtherValue(idx) {
			let attr = lo.cloneDeep(this.editAttr)
			let otherValues = attr.other_values || []
			otherValues.splice(idx, 1)
			attr.other_values = otherValues
			this.editAttr = attr
		},

		onChangeOtherValue(idx, val) {
			let attr = lo.cloneDeep(this.editAttr)
			let otherValues = attr.other_values || []
			lo.set(otherValues, idx, val)
			attr.other_values = otherValues
			this.editAttr = attr
		},

		getAttrName() {
			let attrdef = lo.find(store.matchUserAttribute(), (a) => {
				if (!a) return null
				if (!a.key) return null
				if (!this.attr.key) return null
				return a.key.toLowerCase() === this.attr.key.toLowerCase()
			}) || {key: ''}
			let attrname = getAttributeName(attrdef) || attrdef.label
			attrname = this.$t(attrname)
			if (this.attr.key === 'emails') attrname = this.$t('Email')
			if (this.attr.key === 'phones') attrname = this.$t('phn')

			return attrname
		},
	},

	render() {
		let cls = 'modal'
		if (!this.open) cls += ' modal__hide'
		let attrname = this.getAttrName()

		return (
			<div>
				<div class={cls} style={this.isMergeModalOpened ? 'display: none' : ''}>
					<div class='modal__overlay' vOn:click={(_) => this.$emit('close')} />
					<div class='modal__container'>
						<div class='modal__background' style='overflow: hidden;'>
							<div class='modal_content' style='padding: 12px 20px; width: 480px'>
								<form vOn:submit_prevent={this.submit}>
									<div class='d-flex align-items-center'>
										<div class='text__lg text__semibold'>
											{this.$t('update_attribute')} {attrname}
										</div>
										<Icon name='x' class='btn__close ml-auto' vOn:click={() => this.$emit('close')} />
									</div>
									<div class='mt-4 text__semibold mb-2'>
										{this.$t('primary_attribute_dynamic', [attrname || this.$t('value')])}
									</div>
									<input class='form-control form-control__light' vModel={this.editAttr.text} ref='main_value_input' />
									<div class='mt-4 text__semibold'>
										{this.$t('other_attribute_dynamic', [attrname || this.$t('value')])}
									</div>
									{lo.map(this.editAttr.other_values, (value, idx) => {
										return (
											<div class={{'d-flex': true, 'align-items-center': true, 'mt-2': true}}>
												<input
													class='form-control form-control__light'
													ref={`other_value_input_${idx}`}
													value={value}
													vOn:input={(e) => this.onChangeOtherValue(idx, e.target.value)}
												/>
												<Icon
													name='circle-minus'
													size='18'
													class='x-icon ml-3'
													vOn:click={() => this.removeOtherValue(idx)}
												/>
											</div>
										)
									})}
									<div class='mt-2'>
										<a href='javascript:;' vOn:click={this.addOtherValue}>
											+ {this.$t('add_value')}
										</a>
									</div>
									<div class='mt-4 d-flex align-items-center justify-content-end'>
										<div class='link link__secondary mr-4' vOn:click={() => this.$emit('close')}>
											{this.$t('cancel')}
										</div>
										<button
											type='submit'
											class='btn btn__sm btn__primary justify-content-center'
											style='width: 100px; display: inline-block;'
											disabled={this.saving}
											vOn:click={this.submit}
										>
											{this.$t('save')}
											{this.saving && <Spinner class='ml-2' size='16' />}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},
}
