import './assets/scss/base.scss'
const sb = require('@sb/util')
const sb_browser = require('./utils/util.dashboard.js')
Object.assign(sb, sb_browser)

var api = require('../store/api.js')
import {router} from './router.js'
import too_old_browser from './too_old_browser.js'
import {changeLanguage, i18n} from './languages.js'
window.i18n = i18n
// import * as Sentry from '@sentry/vue'

const SWVERSION = 48

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
import sw from '../store/sw.js'
Vue.config.productionTip = false

import {directive as onClickaway} from './utils/clickaway'
Vue.directive('clickaway', onClickaway)

import {directive as onDblClickaway} from './utils/dblclickaway'
Vue.directive('dblclickaway', onDblClickaway)

import * as com from '@sb/com'
Vue.component('Spinner', com.Spinner)
Vue.component('Time', com.Time)
Vue.component('Input2', com.Input2)
Vue.component('Avatar', com.Avatar)
Vue.component('Emoji', com.Emoji)
Vue.component('EmojiPicker', com.EmojiPicker)
Vue.component('Fragment', com.Fragment)
Vue.component('fragment', com.Fragment)
Vue.component('Sw', com.Switch)
Vue.component('Tag', com.Tag)
Vue.component('AvatarGroup', com.AvatarGroup)
Vue.component('InputSearch', com.InputSearch)
Vue.component('EmptySearchTable', com.EmptySearchTable)
Vue.component('LocationPicker', com.LocationPicker)
Vue.component('Drawer', com.Drawer)
Vue.component('img2', com.Image)
Vue.component('HighlightText', com.HighlightText)
Vue.component('SidebarFooter', com.SidebarFooter)
Vue.component('ArrowRight', com.ArrowRight)
Vue.component('UserLabel', com.UserLabel)
Vue.component('TagInput', com.TagInput)
Vue.component('MultiRange', com.MultiRange)
Vue.component('Pagination', com.Pagination)
Vue.component('AddressInput', com.AddressInput)
Vue.component('DateRangePicker', com.DateRangePicker)
Vue.component('Icon', com.Icon)
Vue.component('DatePicker', com.DatePicker)
Vue.component('Dropdown2', com.Dropdown)
Vue.component('Dropdown', com.Dropdown)
Vue.component('MoneyInput', com.MoneyInput)
Vue.component('NotiItem', com.NotiItem)
Vue.component('HoverDropdown', com.HoverDropdown)
Vue.component('ProductAvatar', com.ProductAvatar)
Vue.component('AudioPlayer', com.AudioPlayer)
Vue.component('Numeric', com.Numeric)
Vue.component('AutoComplete', com.AutoComplete)
Vue.component('TaskNotiCounter', com.TaskNotiCounter)
Vue.component('AudioWaveform', com.AudioWaveform)
Vue.component('TimePicker', com.TimePicker)

require('./feather.js')

Vue.use(com.Toast)
Vue.use(com.ConfirmMethod)
Vue.use(com.GlobalAudioMethod)
Vue.use(com.UpdateContactMethod)
Vue.use(com.CreateUserMethod)

import tracking from './tracking.js'
tracking.load()

import store from '@sb/store'
import config from '../config/config.js'

store
	.init()
	.then(async () => {
		// must use async function to wait changeLanguage to complete
		await changeLanguage(store.me().lang)
	})
	.then(async () => {
		/*
		Sentry.init({
			Vue,
			dsn: 'https://65a70e8d1e194ae0b170fc223b24e99d@o502463.ingest.sentry.io/4504976546136064',
			integrations: [
				new Sentry.BrowserTracing({
					routingInstrumentation: Sentry.vueRouterInstrumentation(router),
					tracePropagationTargets: ['localhost', 'app.subiz.com.vn', /^\//],
				}),
			],
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
			})
			*/

		let root = new Vue({
			name: 'subiz',
			data: function () {
				return {
					key: Date.now(),
					error: '',
				}
			},
			router,
			render(h) {
				if (this.error === 'too_old_browser') return <too_old_browser />
				return <router-view key={this.key} />
			},
			methods: {
				forceUpdate() {
					this.key = Date.now()
				},
			},
			async created() {
				document.addEventListener('keydown', (e) => {
					if (e.ctrlKey && e.shiftKey && e.key == 'M') {
						this.$root.$emit('shortcut_create_note')
						e.preventDefault()
						return false
					}
				})

				var elem = document.createElement('canvas')
				let canCanvas = !!(elem.getContext && elem.getContext('2d'))

				// dont support browser without service worker
				if (!CSS.supports('flex-wrap', 'wrap') || !canCanvas) {
					this.error = 'too_old_browser'
					return
				}
				await (() => {
					import(/*webpackChunkName: "lightgallery-css" */ 'lightgallery.js/dist/css/lightgallery.min.css')
					import(/*webpackChunkName: "flagicon-css" */ 'flag-icon-css/css/flag-icon.css')
				})()

				// use to prevent index html.index error page in desktop app
				//if (process.env.ENV === 'desktop') this.$router.push('/')
			},
		})
		root.$mount('#app')

		if (process.env.ENV !== 'desktop') {
			sw.registerEventMessage((ev) => {
				let type = lo.get(ev, 'data.type')
				if (type != 'navigate') return
				var url = lo.get(ev, 'data.url', '')
				let internalchat = sb.getUrlParameter(url, 'internal_chat')
				if (internalchat) return root.$emit('showPrivateChat', internalchat)
				if (url.startsWith('/convo?')) {
					let task_id = sb.getUrlParameter(url, 'open_task_id')
					if (task_id) return root.$emit('open_tasks_modal', {task_id: task_id})

					var uid = sb.getUrlParameter(url, 'uid')
					var cid = sb.getUrlParameter(url, 'cid')
					root.$emit('showQuickview', {user_id: uid, convo_id: cid})
				} else {
					router.push({name: 'activities', params: {force: true, path: ev.data.url}})
				}
			})
			// after this, service worker will be ready
			let serviceWorker = await sw.serviceWorker()
			let onFocus = () => {
				if (!document.hasFocus()) return
				sw.closeAllNotification()
			}
			window.addEventListener('focus', onFocus)
			root.$once('hook:beforeDestroy', () => window.removeEventListener('focus', onFocus))
			let cred = api.getCred() || {}
			serviceWorker.postMessage({
				type: 'access_token',
				account_id: cred.account_id,
				agent_id: cred.agent_id,
				access_token: cred.access_token,
			})
		}
	})

console.log('MAIN.jssssssssss', document.currentScript, document.currentScript && document.currentScript.src)

// make JSON stringify doest throw eorr when objcet has circular referrence
function censor(censor) {
	var i = 0

	return function (key, value) {
		if (i !== 0 && typeof censor === 'object' && typeof value == 'object' && censor == value) return '[Circular]'

		if (i >= 29)
			// seems to be a harded maximum of 30 serialized objects?
			return '[Unknown]'

		++i // so we know we aren't using the original object anymore

		return value
	}
}
const testObj = {foo: {bar: null}}
testObj.foo.bar = testObj
console.log('pure obj: ', testObj)
console.log('json stringify: ', JSON.stringify(testObj, censor(testObj)))
