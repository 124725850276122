var lo = require('lodash')

// A key-value database with a cache layer
// db.count item
// db.clear()
// bulkput(items)
// list(limit)
// delete(key)
export default class {
	constructor() {
		this.dead = false
		this.cache = {}

		this.initialized = false
	}

	refreshCache() {} // nothing to do

	init() {
		if (this.initialized) return
		this.cache = {}

		// If the cache is full. Randomly remove half of the cache. credit to thanos
		// this is so simple since we don't need to keep track of last modified time
		// for each key. Implementing a LRU cache won't add much value.
		let allkeys = Object.keys(this.cache)
		this.initialized = true
	}

	all() {
		if (this.dead) return []
		if (!this.initialized) return []
		return this.cache
	}

	match(key) {
		if (this.dead) return
		if (!key) return
		if (!this.initialized) return
		return this.cache[key]
	}

	// keys could be a string or array
	// put('1', {id: '1', name: 'thanh'})
	// put({id: '1', name: 'thanh'}, 'id')
	// put([{id: '1', name: 'thanh'},{id: '2', name: 'van'}], 'id')
	put(key, value) {
		if (!key) return
		if (this.dead) return []
		if (!this.initialized) throw new Error('uninitialized')
		// if multiple key

		if (typeof key === 'string') {
			this.cache[key] = value
			return value
		}

		var objs = []
		let items = []
		if (!Array.isArray(key)) objs = [key]
		else objs = key

		var path = value || 'id'
		lo.map(objs, (obj) => {
			let key = lo.get(obj, path)
			this.cache[key] = obj
			items.push({key: key, value: obj})
		})

		return objs
	}

	// delete an record in a schema
	del(key) {
		if (this.dead) return
		if (!this.initialized) throw new Error('uninitialized')
		delete this.cache[key]
	}

	destroy() {
		// clear all data

		this.dead = true
		this.cache = undefined
	}
}
