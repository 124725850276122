import lo from 'lodash'
import sb from '@sb/util'
import store from '@sb/store'
import Fragment from './fragment'
import Avatar from './avatar'
import TaskDetail from './task_detail.js'
const defaultAvatar = require('../assets/img/default_avatar.png')
import {
	format,
	endOfDay,
	startOfDay,
	subDays,
	addDays,
	startOfMonth,
	endOfMonth,
	startOfWeek,
	endOfWeek,
	differenceInCalendarDays,
} from 'date-fns'

export default {
	name: 'tasks-modal',
	data() {
		return {
			open: false,

			noti_topic: '',
			keyword: '',
			loading: false,

			// show task detail
			taskId: '', // 'new' is create task, empty is not open task detail
			recentTaskId: '',

			markingDone: {},
			filter: {},

			show_more_filter: false,
			current_more: 'list_all_open',

			// custom filter
			filterCreatedHours: [],
			filterDueHours: [],
			filterAssignedId: '',
			filterWatcherId: '',
			filterCreatorId: '',
			filterStatus: '',
			extraFilters: [],

			isTaskDeleted: false,
			isShowDoneTask: false,
		}
	},

	mounted() {
		this.$root.$on('toggle_tasks_modal', this.toggleModal)
		this.$root.$on('open_tasks_modal', this.openModal)
		this.$root.$on('open_overdue_tasks', this.openOverdueTasks)

		store.onTask(this, () => this.$forceUpdate())
		store.onSortTask(this, () => {
			console.log('onSortTaskssssssssss')
			this.$forceUpdate()
		})

		// set task open when paste url
		let openTaskId = lo.get(this.$route, 'query.open_task_id')
		if (openTaskId) {
			this.open = true
			this.taskId = openTaskId
		}
		this.setDatepickerShortcuts()
	},

	destroyed() {
		this.$root.$off('toggle_tasks_modal', this.toggleModal)
		this.$root.$off('open_tasks_modal', this.openModal)
		this.$root.$off('open_overdue_tasks', this.openOverdueTasks)
	},

	methods: {
		setDatepickerShortcuts() {
			const DATE_PICKER_SHORTCUTS = [
				{
					text: this.$t('today'),
					onClick() {
						return [new Date(), new Date()]
					},
				},
				{
					text: this.$t('yesterday'),
					onClick() {
						const fromDate = subDays(new Date(), 1)
						const toDate = subDays(new Date(), 1)
						return [fromDate, toDate]
					},
				},
				{
					text: '7 ' + this.$t('days'),
					desc: this.$t('filter_last_days', ['7']),
					onClick() {
						const fromDate = subDays(new Date(), 7)
						const toDate = subDays(new Date(), 1)
						return [fromDate, toDate]
					},
				},
				{
					text: '30 ' + this.$t('days'),
					desc: this.$t('filter_last_days', ['30']),
					onClick() {
						const fromDate = subDays(new Date(), 30)
						const toDate = subDays(new Date(), 1)
						return [fromDate, toDate]
					},
				},
			]
			const DUE_PICKER_SHORTCUTS = [
				{
					text: this.$t('today'),
					onClick() {
						return [new Date(), new Date()]
					},
				},
				{
					text: this.$t('tomorrow'),
					onClick() {
						const fromDate = addDays(new Date(), 1)
						const toDate = addDays(new Date(), 1)
						return [fromDate, toDate]
					},
				},
				{
					text: this.$t('this_week'),
					onClick() {
						const fromDate = startOfWeek(new Date(), {weekStartsOn: 1})
						const toDate = endOfWeek(new Date(), {weekStartsOn: 1})
						return [fromDate, toDate]
					},
				},
				{
					text: this.$t('this_month'),
					onClick() {
						const fromDate = startOfMonth(new Date())
						const toDate = endOfMonth(new Date())
						return [fromDate, toDate]
					},
				},
				{
					text: this.$t('anytime'),
					onClick() {
						return [new Date(0), new Date(0)]
					},
				},
			]

			if (!isTodayThisWeek()) {
				DATE_PICKER_SHORTCUTS.push({
					text: this.$t('this_week'),
					onClick() {
						const fromDate = startOfWeek(new Date(), {weekStartsOn: 1})
						return [fromDate, new Date()]
					},
				})
			}

			if (!isTodayThisMonth()) {
				DATE_PICKER_SHORTCUTS.push({
					text: this.$t('this_month'),
					onClick() {
						const fromDate = startOfMonth(new Date())
						return [fromDate, new Date()]
					},
				})
			}
			DATE_PICKER_SHORTCUTS.push({
				text: this.$t('anytime'),
				onClick() {
					return [new Date(0), new Date(0)]
				},
			})
			this.DATE_PICKER_SHORTCUTS = DATE_PICKER_SHORTCUTS
			this.DUE_PICKER_SHORTCUTS = DUE_PICKER_SHORTCUTS
		},

		openModal(params) {
			this.open = true
			if (params.task_id) this.taskId = params.task_id
			if (params.isTaskDeleted === true) this.isTaskDeleted = true
			this.isShowDoneTask = false
			this.setUrlQuery()
		},

		openOverdueTasks() {
			this.open = true
			this.taskId = ''
			this.onChangeFilter('overdue')
			this.setUrlQuery()
		},

		toggleModal() {
			this.open = !this.open
			if (this.open) this.isShowDoneTask = false
			if (this.isTaskDeleted) {
				this.isTaskDeleted = false
				this.taskId = ''
			}
			this.setUrlQuery()
		},

		setUrlQuery() {
			let openTaskId = ''
			if (this.open) {
				if (this.taskId) openTaskId = this.taskId
			}
			addParamsToLocation({open_task_id: openTaskId})
		},

		hideModal() {
			this.open = false
		},

		removeKeyword() {
			this.keyword = ''
		},

		async markDone(task) {
			let tId = task.id
			let canEditTask = task.assignee === store.me().id || task.created_by === store.me().id
			if (!canEditTask) return
			this.markingDone[tId] = 'marking'
			this.$forceUpdate()
			let done = await store.markTaskDone(tId)
			delete this.markingDone[tId]
			this.$forceUpdate()
		},

		async markUndone(task) {
			let tId = task.id
			let canEditTask = task.assignee === store.me().id || task.created_by === store.me().id
			if (!canEditTask) return
			this.markingDone[tId] = 'marking'
			this.$forceUpdate()
			let done = await store.markTaskDone(tId, 'open')
			delete this.markingDone[tId]
			this.$forceUpdate()
		},

		async pinTask(tId) {
			await store.updateTask({id: tId, members: [{agent_id: store.me().id, pinned: Date.now()}]}, ['members'])
			this.$forceUpdate()
		},

		async unpinTask(tId) {
			await store.updateTask({id: tId, members: [{agent_id: store.me().id, pinned: 0}]}, ['members'])
			this.$forceUpdate()
		},

		showTaskDetail(task) {
			this.noti_topic = ''
			this.taskId = task.id
			this.setUrlQuery()

			this.currentScrollTop = this.$refs.list_wrapper ? this.$refs.list_wrapper.scrollTop : 0
		},

		renderDueAt(task) {
			if (!task.due_at) return null
			if (task.status === 'done') return null

			let now = new Date()
			let due = new Date(task.due_at)
			let isSameDate =
				now.getDate() === due.getDate() && now.getMonth() === due.getMonth() && now.getFullYear() === due.getFullYear()

			if (!task.due_at) return null
			if (now < task.due_at && isSameDate) {
				return (
					<div class='task_due_status_badge due_today'>
						<Time time={task.due_at} style='position: absolute; inset: 0; opacity: 0' />
						<Icon name='calendar' size='14' class='mr-2' stroke-width='2' />
						{this.$t('due_today')}
					</div>
				)
			}

			if (now > task.due_at) {
				return (
					<div class='task_due_status_badge overdue'>
						<Time time={task.due_at} style='position: absolute; inset: 0; opacity: 0' />
						<Icon name='calendar' size='14' class='mr-2' stroke-width='2' />
						{this.$t('overdue')}
						<Time duration time={(now - task.due_at) / 1000} style='margin-left: 3px' notooltip />
					</div>
				)
			}

			return (
				<div class='task_due_status_badge'>
					<Icon name='calendar' size='14' class='mr-2' stroke-width='2' />
					{this.$t('due_date')}: <Time time={task.due_at} short class='ml-2' />
				</div>
			)
		},

		renderTask(task) {
			if (
				task.status === 'done' &&
				Date.now() - (task.done_at || 0) > 5 * 60_000 &&
				!this.isShowDoneTask &&
				!this.keyword
			)
				return null
			let canEditTask = task.assignee === store.me().id || task.created_by === store.me().id
			task = store.matchTask(task.id) || task

			let cbDisabled = !canEditTask || this.markingDone[task.id]
			let $status = <div disabled={cbDisabled} class='task_checkbox' vOn:click_stop={() => this.markDone(task)}></div>
			if (task.status == 'done')
				$status = (
					<div
						disabled={cbDisabled}
						class='task_checkbox task_checkbox__done'
						vOn:click_stop={() => this.markUndone(task.id)}
					>
						<Icon name='check' size='14' stroke-width='3.5' />
					</div>
				)

			let pinned = lo.find(task.members, (mem) => mem.agent_id === store.me().id)
			pinned = lo.get(pinned, 'pinned', 0)
			let $pinned = (
				<img
					src={require('../../src/assets/img/no_pinned.svg')}
					style='width: 15px; height: 15px;'
					class='ml-3 task_pinned_icon task_pinned_icon__hidden'
					vOn:click_stop={() => this.pinTask(task.id)}
					v-tooltip={this.$t('pin')}
				/>
			)
			if (pinned > 0) {
				$pinned = (
					<img
						src={require('../../src/assets/img/pinned.svg')}
						style='width: 15px; height: 15px;'
						class='ml-3 task_pinned_icon'
						vOn:click_stop={() => this.unpinTask(task.id)}
						v-tooltip={this.$t('unpin')}
					/>
				)
			}

			let $last_seen = null
			let title_cls = ''

			let members = lo.get(task, 'members', [])

			let meMember = lo.find(members, (mem) => mem.agent_id === store.me().id)
			let last_seen = lo.get(meMember, 'last_seen', 0)

			if (last_seen < (task.actived || 0)) {
				$last_seen = <div class='dot dot__md dot__primary ml-1 mr-2 ' style='margin-top: 0;'></div>
				title_cls += ' task_not_seen'
			}

			if (task.status == 'done') title_cls += ' text__muted'

			let now = Date.now()
			let dueatcls = 'mr-3'
			if (lo.get(task, 'due_at') && lo.get(task, 'due_at', 0) <= now + 1800000) {
				dueatcls += ' text__danger text__semibold'
			}

			let $due_at = null
			if (lo.get(task, 'due_at') && task.status !== 'done')
				$due_at = (
					<div
						class={dueatcls}
						style='display: flex; justify-content: center; align-items: center;  border-radius: 5px; font-size: 14px;'
					>
						<Icon style='flex-shrink: 0' name='clock' class='mr-2' size='16' stroke-width='1.5' />
						<Time time={task.due_at} short />
					</div>
				)

			let associated_user = lo.get(task, 'associated_user')
			let customers = ''
			if (lo.size(associated_user) == 1) {
				customers = `• ${this.$t('customer')}: ${sb.getUserDisplayName(store.matchAgent(customers[0]))}`
			}

			if (lo.size(associated_user) > 1) {
				customers = `• ${this.$t('customer')}: `
				lo.forEach(associated_user, (user) => {
					customers += `${sb.getUserDisplayName(store.matchAgent(user))}, `
				})
				customers = lo.slice(customers, 0, lo.size(customers) - 2)
			}

			let cls = this.taskId == task.id ? 'tasks__row tasks__row__active' : 'tasks__row'
			if (task.status == 'done') {
				cls += ' task__row__done'
			}

			if (task.id === this.recentTaskId) cls += ' recent_seen_task'

			let center_header_style = 'flex: 1; flex-direction: column; overflow: hidden'
			return (
				<div data-task-id={task.id} class={cls} key={task.id} vOn:click_stop={() => this.showTaskDetail(task)}>
					<div class='no-shrink' style='margin-right: 12px'>
						{$status}
					</div>
					<div class='d-flex' style={center_header_style}>
						<div class={title_cls}>
							{lo.trim(task.title) ? lo.trim(task.title) : <i class='text__muted'>{this.$t('untitled')}</i>}
						</div>

						<div class='d-flex align-items-center' style='width: 100%;'>
							{$last_seen}
							<div style='width: 100%;'>
								<div class='text__muted text__truncate mr-2 text__sm' style='width: 100%'>
									{task.number && <Fragment>{`#${Number(task.number)} `}</Fragment>}
									{this.$t('created_at').toLowerCase()} <Time ago time={task.created} suffix /> {this.$t('by')}{' '}
									{sb.getAgentDisplayName(store.matchAgent(task.created_by))}
								</div>
							</div>
						</div>
					</div>
					<div class='d-flex align-items-center ml-3'>
						{this.renderDueAt(task)}
						{$pinned}
						<Avatar agent={store.matchAgent(task.assignee)} size='22' nodot tooltip />
					</div>
				</div>
			)
		},

		onChangeFilter(filterId) {
			this.show_more_filter = false // close dropdown
			this.filter = {id: 'noti'}
			if (filterId == 'noti') return

			let me = store.me()

			this.filter = {id: filterId}
			// reset all custom filters
			this.filterAssignedId = ''
			this.filterWatcherId = me.id
			this.filterCreatedHours = []
			this.filterStatus = ''
			this.filterDueHours = []
			this.filterCreatorId = ''

			if (filterId === 'list_me_open') {
				this.filterAssignedId = me.id
				//this.filterStatus = 'open'
			}
			if (filterId === 'due_today') {
				let hour = startOfDay(new Date())
				hour = Math.round(hour / 3600_000)
				this.filterDueHours = [hour, hour + 23]
			}
			if (filterId === 'overdue') {
				this.filterStatus = 'overdue'
			}
			return

			let availables = [
				{id: 'list_all', status: '', label: this.$t('all')},
				{id: 'list_me', status: '', assignee: me.id, label: this.$t('assign_me')},
				{id: 'list_me_open', status: 'open', assignee: me.id, label: this.$t('my_tasks')},
				{id: 'list_me_expired', is_overdue: true, status: 'open', assignee: me.id, label: this.$t('expired_task_me')},
				{id: 'list_all_open', status: 'open', watcher: me.id, label: this.$t('all_open_task')},
				{id: 'list_all_expired', is_overdue: true, status: 'open', watcher: me.id, label: this.$t('all_expired_task')},
				{id: 'due_today', status: '', watcher: me.id, label: this.$t('all')},
			]

			if (filterId == 'list_all_expired' || filterId == 'list_all_open' || filterId == 'list_me_expired')
				this.current_more = filterId

			let match = lo.find(availables, (filter) => filter.id == filterId)
			if (!match) match = availables[0]
			this.filter = match
		},

		onChangeKeyword(e) {
			this.keyword = e.target.value
		},

		async deleteTask() {
			this.loading = true
			let done = await store.deleteTask(this.taskId)
			this.loading = false
			if (this.loading) this.$forceUpdate()
		},

		draftTask() {
			this.taskId = 'new'
		},

		isTaskFilterd(task) {
			let keywordFilter = true
			if (this.keyword) {
				let agent_name = sb.getAgentDisplayName(store.matchAgent(task.assignee))
				let keyword = sb.unicodeToAscii(this.keyword).toLowerCase()
				let name = sb.unicodeToAscii(agent_name || '').toLowerCase()
				let title = sb.unicodeToAscii(task.title || '').toLowerCase()
				let displayNumber = task.number ? `#${Number(task.number)}` : ''
				keywordFilter = name.indexOf(keyword) >= 0 || title.indexOf(keyword) >= 0 || displayNumber.indexOf(keyword) >= 0
			}

			let overdueFilter = true
			if (this.filterStatus === 'overdue') {
				if (task.due_at) {
					overdueFilter = Date.now() >= task.due_at && task.status !== 'done'
				} else {
					overdueFilter = false
				}
			}

			let statusFilter = true
			if (this.filterStatus && this.filterStatus !== 'overdue') {
				statusFilter = task.status === this.filterStatus
			}

			let assigneeFilter = true
			if (this.filterAssignedId) {
				assigneeFilter = task.assignee === this.filterAssignedId
			}

			let dueFilter = true
			if (lo.size(this.filterDueHours) && !lo.isEqual(this.filterDueHours, [0, 0])) {
				if (task.due_at) {
					dueFilter =
						task.due_at >= this.filterDueHours[0] * 3600_000 &&
						task.due_at <= this.filterDueHours[1] * 3600_000 + 3600_000 - 1
				} else {
					dueFilter = false
				}
			}

			let createdFilter = true
			if (lo.size(this.filterCreatedHours) && !lo.isEqual(this.filterDueHours, [0, 0])) {
				if (task.created_at) {
					createdFilter =
						task.created_at >= this.filterCreatedHours[0] * 3600_000 &&
						task.created_at <= this.filterCreatedHours[1] * 3600_000 + 3600_000 - 1
				} else {
					createdFilter = false
				}
			}

			let watcherFilter = true
			if (this.filterWatcherId) {
				watcherFilter = lo.includes(task.watchers, this.filterWatcherId)
			}

			let creatorFilter = true
			if (this.filterCreatorId) {
				creatorFilter = task.created_by === this.filterCreatorId
			}

			return (
				keywordFilter &&
				overdueFilter &&
				dueFilter &&
				statusFilter &&
				assigneeFilter &&
				createdFilter &&
				watcherFilter &&
				creatorFilter
			)
		},

		renderTasks() {
			// always list all task, filter by custom filter
			let tasks = store._listTasks({id: 'list_all'})
			let fetchstatus = store.matchFetchStatus()

			let now = Date.now()
			tasks = lo.filter(tasks, this.isTaskFilterd)

			let doneTasks = lo.filter(
				tasks,
				(task) => task.status === 'done' && Date.now() - (task.done_at || 0) > 5 * 60_000,
			)

			let $tasks = (
				<div
					style='width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; overflow-y: scroll;'
					ref='list_wrapper'
				>
					{lo.map(tasks, (task) => this.renderTask(task))}
					{!this.keyword && !this.isShowDoneTask && lo.size(doneTasks) > 0 && (
						<div
							class='tasks__row justify-content-center'
							style='border-top: 1px solid #eee'
							vOn:click={() => (this.isShowDoneTask = true)}
						>
							<a href='javascript:;' class='text__secondary text__sm d-flex align-items-center'>
								{this.$t('view_done_tasks', [lo.size(doneTasks)])}
								<Icon name='chevron-down' size='14' stroke-width='2' class='ml-2' />
							</a>
						</div>
					)}
				</div>
			)

			if (lo.size(tasks) == 0 && fetchstatus) {
				$tasks = (
					<div class='d-flex align-items-center justify-content-center ' style='padding-top: 40px'>
						<Spinner mode='blue' size='32' />
					</div>
				)
			}

			if (lo.size(tasks) == 0 && !fetchstatus) {
				$tasks = (
					<div style='width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center'>
						<img
							src={require('../../src/assets/img/img_empty_task.png')}
							style='width: 160px; height: 120px;'
							class='mr-2'
						/>
						<div class='text__muted mt-5' style='font-size: 20px;'>
							{this.$t('no_tasks')}
						</div>
					</div>
				)
			}
			return <div class='task_modal_content'>{$tasks}</div>
		},

		renderNoti() {
			return <NotiList selected={this.noti_topic} vOn:notiClick={this.onNotiClick} show={true} />
		},

		onNotiClick(noti) {
			this.noti_topic = noti.topic + noti.created
			let data = sb.parseJSON(noti.data) || {}
			// if (noti.type == 'task_deleted') return
			this.taskId = data.task_id
		},

		openShowMoreDropdown() {
			this.show_more_filter = true
		},

		closeShowMoreDropdown() {
			this.show_more_filter = false
		},

		async onBack() {
			this.recentTaskId = this.taskId
			this.taskId = ''
			await this.$nextTick()
			if (this.$refs.list_wrapper) {
				this.$refs.list_wrapper.scrollTop = this.currentScrollTop || 0
			}
			this.setUrlQuery()
		},

		renderFilters() {
			if (lo.get(this.filter, 'id') === 'noti') return null
			let searchcls = 'form-control input_search__input order_filter_search_input'
			if (this.keyword) searchcls += ' active'

			let $add = null
			let items = [
				{
					id: 'due_at',
					label: this.$t('due_date_full'),
				},
				{
					id: 'created',
					label: this.$t('created_time'),
				},
				{
					id: 'watcher',
					label: this.$t('watcher_agents'),
				},
				{
					id: 'created_by',
					label: this.$t('creator'),
				},
			]
			items = lo.filter(items, (item) => !lo.includes(this.extraFilters, item.id))

			if (lo.size(items)) {
				$add = (
					<Dropdown
						mode='custom'
						class='mb-3'
						items={items}
						vOn:select={(item) => this.addExtraFilter(item.id)}
						dropdown_width={180}
					>
						<button
							style='display: inline-flex; height: 34px'
							class='btn btn__light btn__sm align-items-center'
							v-tooltip={this.$t('add_filter')}
						>
							<Icon name='filter' stroke-width='2' size='18' />
						</button>
					</Dropdown>
				)
			}

			return (
				<div class='task_list_filters_container'>
					<div class='input_search mr-3 mb-3' style='width: 150px'>
						<Icon style='flex-shrink: 0' name='search' stroke-width='2' size='16' class='input_search__icon_search' />
						<input
							placeholder={this.$t('search')}
							class={searchcls}
							value={this.keyword}
							style='height: 31px; padding-right: 10px; '
							vOn:input={this.onChangeKeyword}
						/>
					</div>
					{this.renderStatusFilter()}
					{this.renderAssignedFilter()}
					{lo.map(this.extraFilters, (filter) => {
						if (filter === 'created') return this.renderCreatedFilter()
						if (filter === 'watcher') return this.renderWatcherFilter()
						if (filter === 'created_by') return this.renderCreatorFilter()
						if (filter === 'due_at') return this.renderDueAtFilter()
					})}
					{$add}
				</div>
			)
		},

		async addExtraFilter(filter) {
			this.extraFilters = [...this.extraFilters, filter]
			await this.$nextTick()
			let $dropdown = this.$refs[`filter_${filter}_dropdown`]
			if (filter === 'created' || filter === 'due_at') {
				$dropdown && $dropdown.Open()
			} else {
				$dropdown && $dropdown.ToogleDropdown()
			}
		},

		onRemoveExtraFilter(filter) {
			this.extraFilters = lo.filter(this.extraFilters, (f) => f !== filter)
			if (filter === 'created') this.filterCreatedHours = []
			if (filter === 'assignee') this.filterAssignedId = ''
			if (filter === 'watcher') this.filterWatcherId = ''
			if (filter === 'creator') this.filterCreatorId = ''
			if (filter === 'due_at') this.filterDueHours = []
		},

		onChangeFilterCreatedHours(dates) {
			// clear time
			if (lo.isEqual(dates, [0, 0])) {
				this.filterCreatedHours = []
			} else if (lo.isEqual(dates, [null, null])) {
				this.filterCreatedHours = []
			} else {
				let fromDate = startOfDay(new Date(dates[0]))
				let fromHour = Math.round(fromDate.getTime() / (3600 * 1000))
				let toDate = startOfDay(new Date(dates[1]))
				let toHour = Math.round(toDate.getTime() / (3600 * 1000)) + 23

				this.filterCreatedHours = [fromHour, toHour]
			}
		},

		renderCreatedFilter() {
			let fromHour = this.filterCreatedHours[0]
			let toHour = this.filterCreatedHours[1]

			let fromDate = fromHour * 3_600_000 || null
			let toDate = toHour * 3_600_000 || null

			let cls = 'order_filter_input mr-3 mb-3'
			if (fromHour && toHour) cls += ' active'

			return (
				<div class={cls}>
					{this.$t('order_created')}:&nbsp;
					<DateRangePicker
						ref='filter_created_dropdown'
						startDate={fromDate}
						endDate={toDate}
						clearable
						style='width: auto'
						vOn:change={(e) => this.onChangeFilterCreatedHours([e.startDate, e.endDate])}
						shortcuts={this.DATE_PICKER_SHORTCUTS}
					/>
					<Icon
						name='x'
						size='16'
						stroke-width='2'
						class='ml-3 x-icon'
						vOn:click={() => this.onRemoveExtraFilter('created')}
					/>
				</div>
			)
		},

		onChangeFilterDueHours(dates) {
			// clear time
			if (lo.isEqual(dates, [0, 0])) {
				this.filterDueHours = []
			} else if (lo.isEqual(dates, [null, null])) {
				this.filterDueHours = []
			} else {
				let fromDate = startOfDay(new Date(dates[0]))
				let fromHour = Math.round(fromDate.getTime() / (3600 * 1000))
				let toDate = startOfDay(new Date(dates[1]))
				let toHour = Math.round(toDate.getTime() / (3600 * 1000)) + 23

				this.filterDueHours = [fromHour, toHour]
			}
		},

		renderDueAtFilter() {
			let fromHour = this.filterDueHours[0]
			let toHour = this.filterDueHours[1]

			let fromDate = fromHour * 3_600_000 || null
			let toDate = toHour * 3_600_000 || null

			let cls = 'order_filter_input mr-3 mb-3'
			if (fromHour && toHour) cls += ' active'

			return (
				<div class={cls}>
					{this.$t('due_date')}:&nbsp;
					<DateRangePicker
						ref='filter_due_at_dropdown'
						startDate={fromDate}
						endDate={toDate}
						clearable
						style='width: auto'
						vOn:change={(e) => this.onChangeFilterDueHours([e.startDate, e.endDate])}
						shortcuts={this.DUE_PICKER_SHORTCUTS}
					/>
					<Icon
						name='x'
						size='16'
						stroke-width='2'
						class='ml-3 x-icon'
						vOn:click={() => this.onRemoveExtraFilter('due_at')}
					/>
				</div>
			)
		},

		onChangeFilterAssignedId(id) {
			this.filterAssignedId = id
		},

		renderAssignedFilter() {
			let agentid = this.filterAssignedId

			let agents = lo.filter(store.matchAgent(), (agent) => agent.state === 'active' && agent.type === 'agent')
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: agent.fullname,
			}))
			items.unshift({id: '', img: defaultAvatar, label: this.$t('any')})

			let $agent = null
			if (!agentid) {
				$agent = this.$t('any')
			} else {
				$agent = <Avatar size={20} agent={lo.get(store.matchAgent(), agentid, {})} />
			}

			let cls = 'order_filter_input'
			if (agentid) cls += ' active'
			return (
				<Dropdown2
					mode='custom'
					ref='filter_assignee_dropdown'
					class='mr-3 mb-3'
					items={items}
					dropdown_width={200}
					vOn:select={(item) => this.onChangeFilterAssignedId(item.id)}
					selected={agentid}
				>
					<div class={cls}>
						<span class='mr-2 no-shrink'>{this.$t('assignee')}:</span>
						{$agent}
					</div>
				</Dropdown2>
			)
		},

		onChangeFilterWatcherId(id) {
			this.filterWatcherId = id
		},

		renderWatcherFilter() {
			let agentid = this.filterWatcherId

			let agents = lo.filter(store.matchAgent(), (agent) => agent.state === 'active' && agent.type === 'agent')
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: agent.fullname,
			}))
			items.unshift({id: '', img: defaultAvatar, label: this.$t('any')})

			let $agent = null
			if (!agentid) {
				$agent = this.$t('any')
			} else {
				$agent = <Avatar size={20} agent={lo.get(store.matchAgent(), agentid, {})} />
			}

			let cls = 'order_filter_input'
			if (agentid) cls += ' active'
			return (
				<Dropdown2
					mode='custom'
					class='mr-3 mb-3'
					ref='filter_watcher_dropdown'
					items={items}
					dropdown_width={200}
					vOn:select={(item) => this.onChangeFilterWatcherId(item.id)}
					selected={agentid}
				>
					<div class={cls}>
						<span class='mr-2 no-shrink'>{this.$t('watcher_short')}:</span>
						{$agent}
						<Icon
							name='x'
							size='16'
							stroke-width='2'
							class='ml-3 x-icon'
							vOn:click={() => this.onRemoveExtraFilter('watcher')}
						/>
					</div>
				</Dropdown2>
			)
		},

		onChangeFilterCreatorId(id) {
			this.filterCreatorId = id
		},

		renderCreatorFilter() {
			let agentid = this.filterCreatorId

			let agents = lo.filter(store.matchAgent(), (agent) => agent.state === 'active' && agent.type === 'agent')
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: agent.fullname,
			}))
			items.unshift({id: '', img: defaultAvatar, label: this.$t('any')})

			let $agent = null
			if (!agentid) {
				$agent = this.$t('any')
			} else {
				$agent = <Avatar size={20} agent={lo.get(store.matchAgent(), agentid, {})} />
			}

			let cls = 'order_filter_input'
			if (agentid) cls += ' active'
			return (
				<Dropdown2
					mode='custom'
					class='mr-3 mb-3'
					ref='filter_created_by_dropdown'
					items={items}
					dropdown_width={200}
					vOn:select={(item) => this.onChangeFilterCreatorId(item.id)}
					selected={agentid}
				>
					<div class={cls}>
						<span class='mr-2 no-shrink'>{this.$t('creator')}:</span>
						{$agent}
						<Icon
							name='x'
							size='16'
							stroke-width='2'
							class='ml-3 x-icon'
							vOn:click={() => this.onRemoveExtraFilter('created_by')}
						/>
					</div>
				</Dropdown2>
			)
		},

		onSelectFilterStatus(v) {
			this.filterStatus = v
		},

		renderStatusFilter() {
			return null
			let cls = 'order_filter_input'
			if (this.filterStatus) cls += ' active'

			let items = [
				{
					id: '',
					label: this.$t('any'),
				},
				{
					id: 'open',
					label: this.$t('open'),
				},
				{
					id: 'done',
					label: this.$t('done'),
				},
				{
					id: 'overdue',
					label: this.$t('overdue'),
				},
			]
			let text = this.$t('any')
			if (this.filterStatus === 'done') text = this.$t('done')
			if (this.filterStatus === 'open') text = this.$t('open')
			if (this.filterStatus === 'overdue') text = this.$t('overdue')
			return (
				<Dropdown2
					mode='custom'
					class='mb-3 mr-3'
					items={items}
					dropdown_width={200}
					vOn:select={(item) => this.onSelectFilterStatus(item.id)}
				>
					<div class={cls}>
						<span class='mr-2 no-shrink'>{this.$t('status')}:</span>
						{text}
					</div>
				</Dropdown2>
			)
		},
	},

	render() {
		let $list = null
		if (this.filter.id == 'noti') $list = this.renderNoti()
		else $list = this.renderTasks()

		let $dropdown = (
			<div class='dropdown' v-clickaway={this.closeShowMoreDropdown} style='right: 300px'>
				<div class='dropdown__item' vOn:click_stop={(_) => this.onChangeFilter('list_me_expired')}>
					{this.$t('expired_task_me')}
				</div>
				<div class='dropdown__item' vOn:click_stop={(_) => this.onChangeFilter('list_all_open')}>
					{this.$t('all_open_task')}
				</div>
				<div class='dropdown__item' vOn:click_stop={(_) => this.onChangeFilter('list_all_expired')}>
					{this.$t('all_expired_task')}
				</div>
			</div>
		)

		let filterId = lo.get(this.filter, 'id', 'list_all')
		let label = 'other'
		if (this.current_more == 'due_today') {
			label = this.$t('due_today')
		}
		if (this.current_more == 'expired') {
			label = this.$t('expired')
		}

		if (this.current_more == 'list_me_expired') {
			label = this.$t('expired_task_me')
		}

		let $btn = (
			<div key='create' style='width: 80px' class='ml-auto btn btn__sm btn__primary' vOn:click={this.draftTask}>
				{this.$t('create')}
			</div>
		)

		if (!this.show_more_filter) $dropdown = null

		let $body = <TaskDetail task_id={this.taskId} vOn:back={this.onBack} />

		if (!this.taskId) {
			let $activedItem = (
				<div class='d-flex align-items-center w_100'>
					<div class={{text__primary: !store.matchTasksSortBy()}}>{this.$t('newest_actived')}</div>
					{!store.matchTasksSortBy() && <Icon name='check' size='18' stroke-width='2' class='ml-auto text__primary' />}
				</div>
			)
			let $createdItem = (
				<div class='d-flex align-items-center w_100'>
					<div class={{text__primary: store.matchTasksSortBy() === 'created'}}>{this.$t('newest_created')}</div>
					{store.matchTasksSortBy() === 'created' && (
						<Icon name='check' size='18' stroke-width='2' class='ml-auto text__primary' />
					)}
				</div>
			)
			let $dueItem = (
				<div class='d-flex align-items-center w_100'>
					<div class={{text__primary: store.matchTasksSortBy() === 'due_at'}}>{this.$t('due_date_full')}</div>
					{store.matchTasksSortBy() === 'due_at' && (
						<Icon name='check' size='18' stroke-width='2' class='ml-auto text__primary' />
					)}
				</div>
			)
			let sortItems = [
				{
					id: '',
					html: $activedItem,
					group: this.$t('sort_by'),
				},
				{
					id: 'created',
					html: $createdItem,
					group: this.$t('sort_by'),
				},
				{
					id: 'due_at',
					html: $dueItem,
					group: this.$t('sort_by'),
				},
			]

			let sortText = ''
			if (!store.matchTasksSortBy()) sortText = this.$t('newest_actived')
			if (store.matchTasksSortBy() === 'created') sortText = this.$t('newest_created')
			if (store.matchTasksSortBy() === 'due_at') sortText = this.$t('due_date_full')

			$body = (
				<Fragment>
					<div class='task_list_header'>
						{$dropdown}
						<div class='tab'>
							<div
								class={{tab__item: true, tab__item__active: filterId === 'list_all'}}
								style='margin-left: 20px'
								vOn:click={(_) => this.onChangeFilter('list_all')}
							>
								{this.$t('all_tasks_remind')}
							</div>
							<div
								class={{tab__item: true, tab__item__active: filterId === 'list_me_open'}}
								vOn:click={(_) => this.onChangeFilter('list_me_open')}
							>
								{this.$t('my_tasks')}
							</div>
							{/*
							<div
								class={{tab__item: true, tab__item__active: filterId === 'due_today'}}
								vOn:click={(_) => this.onChangeFilter('due_today')}
							>
								{this.$t('due_today')}
							</div>
          */}
							<div
								class={{tab__item: true, tab__item__active: filterId === 'overdue'}}
								vOn:click={(_) => this.onChangeFilter('overdue')}
							>
								{this.$t('overdue')}
							</div>
							{/*
				<div
					class={{
						tab__item: true,
						tab__item__active:
							filterId === 'list_me_expired' ||
							filterId === 'list_all_expired' ||
							filterId == 'list_all_open',
					}}
					style='margin-right: 10px'
					vOn:click={(_) => this.onChangeFilter(this.current_more)}
				>
					{label}
				</div>
				<div class='tab__item' vOn:click={this.openShowMoreDropdown}>
					<Icon style='flex-shrink: 0' name='chevron-down' stroke-width='2.5' size='20' />
				</div>
*/}
						</div>
						<div>
							<div
								class='btn btn__sm  btn__outline_secondary mr-3'
								vOn:click={(_) => this.onChangeFilter('noti')}
								v-tooltip={this.$t('Activity')}
							>
								<Icon style='flex-shrink: 0' name='history' stroke-width='2' size='18' />
								<TaskNotiCounter
									extraClass='badge badge__sm badge__danger'
									extraStyle='font-size: 12px;font-weight: 500; padding: 4px 6px;'
								/>
							</div>
						</div>

						{$btn}
					</div>
					{lo.get(this.filter, 'id') !== 'noti' && (
						<div class='task_list_actions_container'>
							{this.renderFilters()}

							<Dropdown
								mode='custom'
								class='mo-shrink ml-4'
								right
								dropdown_width={200}
								items={sortItems}
								vOn:select={(item) => store.updateTasksSortBy(item.id)}
							>
								<div class='btn btn__light btn__sm align-items-center' style='height: 34px; display: inline-flex'>
									<Icon name='arrows-sort' stroke-width='2' size='18' v-tooltip={this.$t('sort_by')} />
									<div class='ml-2'>{sortText}</div>
								</div>
							</Dropdown>
						</div>
					)}
					{$list}
				</Fragment>
			)
		}

		if (this.isTaskDeleted)
			$body = (
				<div class='d-flex align-items-center justify-content-center text__danger text__center'>
					{this.$t('desc_about_deleted_task')}
				</div>
			)

		return (
			<div class={' task__modal ' + (this.open ? '' : 'task__modal__hide')} v-clickaway={() => (this.open = false)}>
				<div class='task__modal_close' vOn:click={this.toggleModal}>
					<Icon name='x' size='18' stroke-width='2' />
				</div>
				<div class='task__modal_inner'>{$body}</div>
			</div>
		)
	},
}

let NotiList = {
	name: 'noti-list',

	props: ['selected'],

	data() {
		return {
			loading: false,
			anchor: '',

			lastScrollTop: 0,
			outOfNotis: false,
			loadingMore: false,
			category: 'task',
		}
	},

	async mounted() {
		this.loading = true
		let notibody = store.matchNotis(this.category) || {}
		if (lo.size(notibody.notifications) == 0) await store.fetchNotis(this.category)
		this.loading = false
		store.onNoti(this, (cat) => {
			if (cat == this.category) this.$forceUpdate()
		})

		store.seenNotis(this.category)
		this._seenInterval = setInterval(() => this.doSeen(), 5000)
		this.$once('hook:beforeDestroy', () => clearInterval(this._seenInterval))
	},

	methods: {
		onNotiClick(noti) {
			this.$emit('notiClick', noti)
		},

		async loadMore() {
			if (this.loading) return
			if (this.outOfNotis) return
			if (this.loadingMore) return
			this.loadingMore = true

			let out = await store.fetchMoreNotis(this.category)
			if (!out) this.outOfNotis = true

			this.loadingMore = false
			await this.$nextTick()
		},

		doSeen: lo.throttle(
			function (e) {
				store.seenNotis(this.category)
			},
			1000,
			{trailing: true},
		),

		onScroll: lo.throttle(
			function (e) {
				let list = e.target
				var st = list.scrollTop
				if (st > this.lastScrollTop) {
					// downscroll code
					let distToBottom = list.scrollHeight - list.scrollTop - list.clientHeight
					if (distToBottom < 500) this.loadMore()
				} else {
					// upscroll code
				}
				this.lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
			},
			200,
			{trailing: true},
		),
	},

	render() {
		let notibody = store.matchNotis(this.category) || {}
		let notis = lo.orderBy(notibody.notifications, 'created', 'desc')
		let $notis = lo.map(notis, (noti) => {
			let unread = notibody.last_seen < noti.created
			return (
				<NotiItem
					key={noti.topic + noti.created}
					extra_class={
						'in_list_task_noti ' + (noti.topic + noti.created == this.selected ? 'in_list_task_noti__active' : '')
					}
					noti={noti}
					vOn:click={this.onNotiClick}
					unread={unread}
				/>
			)
		})

		let $loadmore = null
		if (this.loadingMore) {
			$loadmore = (
				<div class='d-flex align-items-center justify-content-center mt-4 mb-4'>
					<Spinner size='20' mode='blue' />
				</div>
			)
		}

		let $outofnoti = null
		if (this.outOfNotis) {
			$outofnoti = <div class='text__muted text-center ml-4 mr-4 pb-3 mt-3'>{this.$t('out_of_notification')}</div>
		}
		let $body = (
			<div class='task_noti___body' vOn:scroll={this.onScroll}>
				{$notis}
				{$outofnoti}
				{$loadmore}
			</div>
		)

		if (notis.length == 0) {
			$body = (
				<div
					class='instant_noti__menu_body align-items-center justify-content-center'
					style='display: flex; flex-direction: column;'
				>
					<img src={require('../assets/img/empty_1.svg')} width='100' height='100' />
					<div class='mt-4 text__muted'>Chưa có cập nhật mới</div>
				</div>
			)
		}

		if (this.loading)
			$body = (
				<div class='instant_noti__menu_body d-flex align-items-center justify-content-center'>
					<Spinner mode='blue' size='60' />
				</div>
			)

		return <div style='overflow: hidden; flex: 1; display: flex; flex-direction: column'>{$body}</div>
	},
}
function addParamsToLocation(params) {
	let url = new URL(window.location.href)
	lo.each(params, (value, key) => {
		if (value) {
			url.searchParams.set(key, value)
		} else {
			url.searchParams.delete(key)
		}
	})
	window.history.pushState({}, null, url.href)

	return
	history.pushState(
		{},
		null,
		this.$route.path +
			'?' +
			Object.keys(params)
				.map((key) => {
					return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
				})
				.join('&'),
	)
}

function isTodayThisWeek() {
	let startWeekDay = startOfWeek(new Date(), {weekStartsOn: 1})
	return format(startWeekDay, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')
}

function isTodayThisMonth() {
	let startMonthDay = startOfMonth(new Date())
	return format(startMonthDay, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')
}
