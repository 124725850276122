import NotiList from './noti_list.js'
import {OnelineMsg} from '@sb/com'
import Search from './search.js'
const lo = require('lodash')
const sb = require('@sb/util')
import store from '@sb/store'

export default {
	props: ['routerName'],
	data() {
		return {
			isHelpAvailable: false,

			previewEv: null,
			closedPreview: false,

			isMoreMenuOpened: false,

			showNoti: false,
			showSettingError: true,
		}
	},

	mounted() {
		store.onNoti(this, (cat) => this.$forceUpdate())
	},

	methods: {
		onClickNoti(noti) {
			if (!noti.data) return null
			let data = JSON.parse(noti.data) || {}

			let uid = lo.get(data, 'user.id')
			if (noti.type === 'campaign_user_converted' || noti.type === 'user_opened_email') uid = lo.get(data, 'user_id')
			// this.removePopupNoti(noti.id)

			store.readNotification('user', noti.checkpoint, noti.topic)
			if (noti.category === 'order') {
				return this.$root.$emit('showOrder', {id: data.order_id})
			}
			if (!uid) return
			this.$root.$emit('showQuickview', {user_id: uid})
		},

		settingClick() {
			setTimeout(() => this.$router.push({name: 'profile.setting-notification'}), 100)
		},

		renderNotiList() {
			return (
				<div vOn:click_prevent_stop={(_) => false} class={this.showNoti ? '' : 'd-none'}>
					<div class='instant_noti__menu' style='left: unset;  height: 500px ;right: -11px; top: 45px;'>
						<div class='instant_noti__menu_head'>
							<div class='instant_noti__menu_head__title'>{this.$t('notification')}</div>
							<div class='link link__primary' vOn:click={this.settingClick}>
								{this.$t('setting')}
							</div>
						</div>
						<NotiList vOn:notiClick={this.onClickNoti} show={this.showNoti} />
					</div>
				</div>
			)
		},

		closeMoreMenu() {
			this.isMoreMenuOpened = false
		},

		toggleMoreMenu() {
			this.isMoreMenuOpened = !this.isMoreMenuOpened
		},

		isSettingError() {
			if (!this.showSettingError) return false
			return this.isZaloError() || this.isFbError()
		},

		isZaloError() {
			let fbIntes = lo.filter(
				store.matchIntegration(),
				(inte) => inte.connector_type === 'zalo' && inte.state !== 'deleted',
			)
			fbIntes = lo.filter(fbIntes, (inte) => inte.sending_status !== 'unstable' && inte.sending_status !== 'ok')

			return lo.size(fbIntes)
		},

		isFbError() {
			let fbIntes = lo.filter(
				store.matchIntegration(),
				(inte) => inte.connector_type === 'facebook' && inte.state !== 'deleted',
			)
			fbIntes = lo.filter(fbIntes, (inte) => inte.token_status === 'failed')

			return lo.size(fbIntes)
		},

		renderSettingsError() {
			//return null
			if (!this.isSettingError()) return null

			let text = 'Fanpage'
			let routerName = 'settings.messenger'
			if (this.isFbError() && this.isZaloError()) {
				text = 'Fanpage và Zalo'
			} else if (this.isZaloError()) {
				text = 'Zalo'
				routerName = 'settings.zalo'
			}

			return (
				<div class='error_settings_dropdown'>
					<div class='d-flex align-items-center'>
						<div class='text__semibold '>
							<Icon name='alert-triangle' width='18' stroke-width='2' class='mr-3' />
							Lỗi gián đoạn {text}.{' '}
							{routerName === 'settings.messenger' ? (
								<a
									href='https://www.youtube.com/watch?v=sYfkWY1mJuI'
									target='_blank'
									class='link link__dark text__underline'
								>
									Xem ngay
								</a>
							) : (
								<router-link to={{name: routerName}} class='link link__dark text__underline'>
									Xem ngay
								</router-link>
							)}
						</div>
						<Icon
							name='x'
							stroke-width='2'
							v-tooltip={this.$t('close')}
							class='btn__close ml-auto btn__close_light'
							vOn:click={() => (this.showSettingError = false)}
						/>
					</div>
				</div>
			)
		},
	},

	render() {
		// get parent router name
		let routerName = this.routerName
		let scopes = store.me().scopes || []
		let isSupervisor = lo.get(store.me(), 'is_supervisor')

		const subscription = store.matchSubscription() || {}

		let ordercls = 'header_bar_menu_link'
		if (routerName === 'order-list') ordercls += ' active'
		let taskcls = 'header_bar_menu_link'
		if (routerName === 'task-list') taskcls += ' active'
		let onlinecls = 'header_bar_menu_link'
		if (routerName === 'liveoverview') onlinecls += ' active'
		let leadcls = 'header_bar_menu_link'
		if (routerName === 'lead') leadcls += ' active'
		let submenucls = 'header_bar_menu_link'
		if (this.isMoreMenuOpened) submenucls += ' active'
		let accmgrcls = 'header_bar_menu_link'
		if (routerName === 'accmgr') accmgrcls += ' active'
		let settingcls = 'header_bar_menu_link'
		if ((routerName || '').startsWith('settings')) settingcls += ' active'

		let $orderupdate = null
		let notibody = store.matchNotis('order') || {}
		if (lo.get(notibody, 'unread') > 0) $orderupdate = <div class='header_bar_noti_count_badge'>{notibody.unread}</div>

		let $notiupdate = null
		let usernotibody = store.matchNotis('user') || {}
		if (lo.get(usernotibody, 'unread') > 0)
			$notiupdate = <div class='header_bar_noti_count_badge'>{usernotibody.unread}</div>

		let $setting = null
		if (!scopes.includes('account_manage') && !scopes.includes('account_setting')) {
			$setting = null
		} else {
			$setting = (
				<router-link to={{name: 'settings.general'}} class={settingcls}>
					<div style='position: relative'>
						<Icon size='22' name='settings' class='header_bar_menu_link_icon mr-2' />
					</div>
					<div class='text-truncate'>{this.$t('setting')}</div>
				</router-link>
			)
		}

		let headercls = 'header_bar'
		if (subscription.churned) headercls += ' header_bar__churned'

		return (
			<div>
				<div class={headercls}>
					<div class='header_bar_left'>
						<img
							src={require('./assets/img/logo-white.svg')}
							class='header_bar_logo'
							vOn:click={(_) => this.$router.push({name: 'activities'})}
						/>
						<Search />
					</div>
					<div class='header_bar_center'>
						<MessageHeaderItem routerName={this.routerName} />
						<router-link to={{name: 'order-list'}} class={ordercls}>
							<div style='position: relative'>
								<Icon size='22' name='receipt-2' class='header_bar_menu_link_icon mr-2' />
								{$orderupdate}
							</div>
							<div class='text-truncate'>{this.$t('order')}</div>
						</router-link>

						<router-link to={{name: 'liveoverview'}} class={onlinecls}>
							<div style='position: relative'>
								<Icon size='22' name='device-desktop-analytics' class='header_bar_menu_link_icon mr-2' />
							</div>
							<div class='text-truncate'>{this.$t('Subiz Live')}</div>
						</router-link>
						<router-link to={{name: 'lead'}} class={leadcls}>
							<div style='position: relative'>
								<Icon size='22' name='user-circle' class='header_bar_menu_link_icon mr-2' />
							</div>
							<div class='text-truncate'>{this.$t('lead-2')}</div>
						</router-link>
						<router-link
							to={{
								name:
									scopes.includes('account_manage') || scopes.includes('account_setting') || isSupervisor
										? 'newreports.convo'
										: 'newreports.me',
							}}
							class={`header_bar_menu_link ${(routerName || '').startsWith('newreport') ? 'active' : ''}`}
						>
							<div style='position: relative'>
								<Icon size='22' name='timeline' class='header_bar_menu_link_icon mr-2' />
							</div>
							<div class='text-truncate'>{this.$t('report')}</div>
						</router-link>
						{$setting}
						{lo.get(store.me(), 'account.id') === 'acpxkgumifuoofoosble' && (
							<router-link to={{name: 'accmgr'}} class={accmgrcls}>
								<Icon size='22' name='circle-key' class='header_bar_menu_link_icon mr-2' />
								<div class='text-truncate'>Accmgr</div>
							</router-link>
						)}
					</div>
					<div class='header_bar_right'>
						<div
							class={submenucls}
							v-clickaway={this.closeMoreMenu}
							vOn:click={this.toggleMoreMenu}
							style='display: flex; align-items: center; justify-content: center; user-select: none'
						>
							<svg width='16' height='16' viewBox='0 0 196 196' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<rect y='148' width='48' height='48' rx='5' fill='#929292' />
								<rect x='74' y='148' width='48' height='48' rx='5' fill='#007EFF' />
								<rect x='148' y='148' width='48' height='48' rx='5' fill='#E74F41' />
								<rect y='74' width='48' height='48' rx='5' fill='#80A895' />
								<rect x='74' y='74' width='48' height='48' rx='5' fill='#FF4A75' />
								<rect x='148' y='74' width='48' height='48' rx='5' fill='#7868E6' />
								<rect width='48' height='48' rx='5' fill='#FFBB28' />
								<rect x='74' width='48' height='48' rx='5' fill='#19B600' />
								<rect x='148' width='48' height='48' rx='5' fill='#0265FF' />
							</svg>
							<div class='more_menu_dropdown' style={!this.isMoreMenuOpened ? 'display: none' : ''}>
								<div class='more_menu_items_container'>
									<router-link to={{name: 'product-list'}} class='more_menu_item'>
										<Icon name='package' stroke-width='2' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>{this.$t('product')}</div>
											<div class='more_menu_item_sub'>{this.$t('product_menu_desc')}</div>
										</div>
									</router-link>
									<router-link to={{name: 'web_plugin'}} class='more_menu_item'>
										<Icon name='browser' stroke-width='2' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>{this.$t('web_plugin')}</div>
											<div class='more_menu_item_sub'>{this.$t('web_plugin_menu_desc')}</div>
										</div>
									</router-link>
									<router-link to={{name: 'bot-list'}} class='more_menu_item'>
										<Icon name='cpu' stroke-width='2' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>{this.$t('bot')}</div>
											<div class='more_menu_item_sub'>{this.$t('bot_menu_desc')}</div>
										</div>
									</router-link>

									{[
										'acpxkgumifuoofoosble',
										'acrgwpijnddohugcufdm',
										'acqsulrowbxiugvginhw',
										'acqtwtasitcxoydziwrn',
										'acpzooihzhalzeskamky',
										'acriviayfmabzskstrpq',
									].includes(store.me().account_id) && (
										<Fragment>
											<router-link to={{name: 'campaign-list'}} class='more_menu_item'>
												<Icon name='ad' stroke-width='2' class='more_menu_item_icon' />
												<div>
													<div class='more_menu_item_header'>{this.$t('broadcast')}</div>
													<div class='more_menu_item_sub'>{this.$t('campaign_menu_desc')}</div>
												</div>
											</router-link>
											<router-link to={{name: 'telesale-list'}} class='more_menu_item'>
												<Icon name='phone' stroke-width='2' class='more_menu_item_icon' />
												<div>
													<div class='more_menu_item_header'>{this.$t('telesale')}</div>
													<div class='more_menu_item_sub'>{this.$t('telesale_menu_desc')}</div>
												</div>
											</router-link>
										</Fragment>
									)}
								</div>
							</div>
						</div>
						<div
							class='header_bar_menu_link'
							vOn:click_stop={() => this.$root.$emit('toggle_tasks_modal', {user_id: ''})}
						>
							<div style='position: relative' v-tooltip={this.$t('tasks_remind')}>
								<Icon name='circle-check' size='22' class='header_bar_menu_link_icon' />
								<TaskNotiCounter extraClass='header_bar_noti_count_badge' />
							</div>
						</div>
						<div
							class='header_bar_menu_link'
							style='margin-right: 0'
							vOn:click={(_) => {
								this.showNoti = !this.showNoti
							}}
							v-clickaway={(_) => {
								this.showNoti = false
							}}
						>
							{this.renderNotiList()}
							<div style='position: relative' v-tooltip={this.$t('notification')}>
								<Icon size='22' name='bell' class='header_bar_menu_link_icon' />
								{$notiupdate}
							</div>
						</div>
						<AgentMenu />
					</div>
				</div>
				<div style='position: relative'>{this.renderSettingsError()}</div>
			</div>
		)
	},
}

// so we dont broadcast rerender when convo changed
var MessageHeaderItem = {
	props: ['routerName'],
	created() {
		store.onConvo(this, (_) => this.$forceUpdate())
		store.onAccount(this, (_) => this.$forceUpdate())
	},

	methods: {
		countConvoUnread() {
			let seen = lo.get(store.me(), 'dashboard_setting.conversation_seen', 0)
			let topics = store.listTopics(5_000)
			return lo.filter(topics, (topic) => !topic.read && topic.actived > seen).length
		},
	},

	render() {
		let unreadcount = this.countConvoUnread()
		let $unreadTopic = null
		let routerName = this.routerName
		if (unreadcount > 0) $unreadTopic = <div class='header_bar_noti_count_badge'>{unreadcount}</div>
		if (routerName === 'activities') $unreadTopic = null
		let convocls = 'header_bar_menu_link'
		if (routerName === 'activities') convocls += ' active'

		return (
			<router-link to={{name: 'activities'}} class={convocls}>
				<div style='position: relative'>
					<Icon size='22' class='header_bar_menu_link_icon mr-2' name='message-dots' />
					{$unreadTopic}
				</div>
				<div class='text-truncate'>{this.$t('messages')}</div>
			</router-link>
		)
	},
}

var AgentMenu = {
	data() {
		return {
			profileHover: false,
		}
	},

	methods: {
		onProfileMouseOver() {
			clearTimeout(this.profileleavinghandler)
			this.profileHover = true
		},

		onProfileMouseLeave() {
			clearTimeout(this.profileleavinghandler)
			this.profileleavinghandler = setTimeout(() => {
				this.profileHover = false
			}, 150)
		},

		renderAgentMenu() {
			let fullname = lo.get(store.me(), 'fullname', '')
			let email = lo.get(store.me(), 'email', '')

			const subscription = store.matchSubscription() || {}
			const {plan, account_id} = subscription

			let style = 'display: none'
			let $paymentTag = null
			if (plan === 'free' || plan === 'trial') {
				$paymentTag = <div class='badge badge__danger ml-2'>{this.$t('upgrade_plan')}</div>
			}
			if (plan === 'standard') {
				$paymentTag = <div class='badge badge__info ml-2'>{this.$t('plan_standard')}</div>
			}
			if (plan === 'advanced') {
				$paymentTag = <div class='badge badge__success ml-2'>{this.$t('plan_advanced')}</div>
			}
			if (this.profileHover) style = 'display:block'

			let scopes = store.me().scopes || []
			let $setting = null
			if (!scopes.includes('account_manage') && !scopes.includes('account_setting')) {
				$setting = null
			} else {
				$setting = (
					<div
						class='btn btn__primary btn__sm mt-2'
						vOn:click={() => {
							this.$router.push({name: 'settings.general'})
							this.profileHover = false
						}}
						style='display: block'
					>
						<Icon name='settings' size='16' stroke-width='2' class='mr-2' />
						{this.$t('account_setting')}
					</div>
				)
			}

			return (
				<div class='nav_my_profile' style={style}>
					<div class='nav_my_profile__info'>
						<div class='d-flex' style='flex-direction: column;'>
							<div style='cursor: pointer;' vOn:click={() => this.$router.push({name: 'profile.self'})}>
								<Avatar agent={store.me()} size='md' nodot notooltip />
							</div>
							<div style='flex: 1;'></div>
						</div>
						<div class='ml-4 ' style='overflow: hidden'>
							<div class='h5 ' style='cursor: pointer' vOn:click={(_) => this.$router.push({name: 'profile.self'})}>
								{fullname}
							</div>
							<span class='text__muted' style='word-wrap: break-word' v-tooltip={email}>
								{email}
							</span>
							{$setting}
						</div>
					</div>
					<div class='dropdown__item' vOn:click={(_) => this.$router.push({name: 'profile.self'})}>
						{this.$t('agent_profile_my_profile')}
					</div>

					<div
						class='dropdown__item'
						vOn:click={(_) => {
							this.$root.$emit('agent_modal_open', '')
							this.profileHover = false
						}}
					>
						Agent
					</div>
					<div
						class='dropdown__item'
						vOn:click={(_) => {
							this.$root.$emit('message_template_modal_open', '')
							this.profileHover = false
						}}
					>
						{this.$t('message_template')}
					</div>
					<div
						class='dropdown__item'
						vOn:click={(_) => {
							this.$router.push({name: 'profile.setting-notification'})
						}}
					>
						{this.$t('agent_profile_notification')}
					</div>
					<div
						class='dropdown__item'
						vOn:click={(_) => {
							this.$router.push({name: 'referral'})
						}}
					>
						{this.$t('referral')}
					</div>
					<div
						class='dropdown__item payment__dropdown'
						vOn:click={(_) => {
							this.$router.push({name: 'subscriptions-page'})
						}}
					>
						<div>{this.$t('invoice_list_column_payment')}</div>
						{$paymentTag}
					</div>
					<a class='dropdown__item payment__dropdown' href='https://subiz.com.vn/docs/' target='_blank'>
						{this.$t('agent_profile_help')}
					</a>
					<div class='dropdown__item' vOn:click_prevent={(_) => store.logout()}>
						{this.$t('agent_profile_logout')}
					</div>
				</div>
			)
			return (
				<div
					class='sidebar__sub_item'
					vOn:mouseover={this.onProfileMouseOver}
					vOn:mouseleave={this.onProfileMouseLeave}
				>
					<div class='sidebar__sub_item_icon sidebar__sub_item_icon__profile'>
						<Avatar agent={store.me()} size='sm' nodot />
						<div class='sidebar__item_label'>{store.me().fullname}</div>
					</div>
					<div class='nav_my_profile' style={style}>
						<div class='nav_my_profile__info'>
							<Avatar agent={store.me()} size='md' />
							<div class='ml-4 text__truncate'>
								<div class='h5' v-tooltip={fullname}>
									{fullname}
								</div>
								<span class='text__muted' v-tooltip={email}>
									{email}
								</span>
							</div>
						</div>
						<div class='dropdown__item' vOn:click={(_) => this.$router.push({name: 'profile.self'})}>
							{this.$t('agent_profile_my_profile')}
						</div>

						<div
							class='dropdown__item'
							vOn:click={(_) => {
								this.$router.push({name: 'message-template'})
							}}
						>
							{this.$t('message_template')}
						</div>
						<div
							class='dropdown__item'
							vOn:click={(_) => {
								this.$router.push({name: 'profile.setting-notification'})
							}}
						>
							{this.$t('agent_profile_notification')}
						</div>
						<div
							class='dropdown__item'
							vOn:click={(_) => {
								this.$router.push({name: 'referral'})
							}}
						>
							{this.$t('referral')}
						</div>
						<div
							class='dropdown__item payment__dropdown'
							vOn:click={(_) => {
								this.$router.push({name: 'subscriptions-page'})
							}}
						>
							<div>{this.$t('invoice_list_column_payment')}</div>
							{$paymentTag}
						</div>
						<div class='dropdown__item payment__dropdown' vOn:click={(_) => window.open(helpurl, '_blank')}>
							{this.$t('agent_profile_help')}
						</div>
						<div class='dropdown__item' vOn:click_prevent={(_) => store.logout()}>
							{this.$t('agent_profile_logout')}
						</div>
					</div>
				</div>
			)
		},
	},

	created() {
		store.onAccount(this, (_) => this.$forceUpdate())
	},

	render() {
		let agent = store.me() || {}
		return (
			<div class='header_bar_agent_avatar_container' v-clickaway={(_) => (this.profileHover = false)}>
				<div style='cursor: pointer' vOn:click={() => (this.profileHover = !this.profileHover)}>
					<Avatar agent={agent} size='28' notooltip nodot />
				</div>
				{this.renderAgentMenu()}
			</div>
		)
	},
}
