const flow = require('@subiz/flow')
import lo from 'lodash'
const config = require('@sb/config')
const sb = require('@sb/util')
var api = require('./api.js')
import {initializeApp} from 'firebase/app'
import {getMessaging, getToken} from 'firebase/messaging'

// remember to add permission to your key https://console.cloud.google.com/apis/credentials/
// + Firebase Cloud Messaging API
// + Firebase Installations API
// + FCM Registration API
let app = initializeApp({
	apiKey: config.FirebaseApiKey,
	projectId: config.FirebaseProjectId,
	messagingSenderId: config.FirebaseSenderId,
	appId: config.FirebaseAppId,
})

loopRefreshToken()

let _service_worker
let _service_worker_registration
function loadServiceWorker() {
	if (!navigator.serviceWorker) return Promise.resolve()
	if (process.env.ENV === 'desktop') return Promise.resolve()
	return new Promise(async (rs) => {
		// clearing old worker
		try {
			let registrations = await navigator.serviceWorker.getRegistrations()
			for (let registration of registrations) {
				if (registration.active) {
					if (!registration.active.scriptURL.endsWith('/push_notification_sw.js')) {
						registration.unregister()
						continue
					}
				}
				registration.update()
			}
		} catch (e) {
			console.log('EE', e)
		}

		await flow.sleep(2000)
		// already registered
		let register
		try {
			register = await navigator.serviceWorker.register('/push_notification_sw.js', {scope: '/'})
		} catch (e) {
			throw e
		}

		if (!register) return rs()

		_service_worker_registration = register

		setTimeout(async () => {
			if (window.Notification.permission == 'granted') {
				let token = await tryGetToken()
				if (token) await api.updateFcmToken(token, '', 'desktop')
			}
		}, 100)

		if (register.active) {
			_service_worker = register.active
			// only allow to call these method
			_service_worker = {
				postMessage: _service_worker.postMessage.bind(_service_worker),
				addEventListener: _service_worker.addEventListener.bind(_service_worker),
				removeEventListener: _service_worker.removeEventListener.bind(_service_worker),
			}
			return rs()
		}

		// no service worker running
		// so we wait until service worker is fully activated
		const newWorker = register.installing
		console.log('SERVICE WORKER STATE', newWorker.state)
		newWorker.addEventListener('statechange', () => {
			console.log('SERVICE WORKER STATE', newWorker.state)
			// now we are done
			if (newWorker.state !== 'activated') return
			_service_worker = newWorker
			// only allow to call these method
			_service_worker = {
				postMessage: _service_worker.postMessage.bind(_service_worker),
				addEventListener: _service_worker.addEventListener.bind(_service_worker),
				removeEventListener: _service_worker.removeEventListener.bind(_service_worker),
			}
			return rs()
		})
	})
}

let messageCbs = []
function registerEventMessage(cb) {
	messageCbs.push(cb)
}

async function loopRefreshToken() {
	for (;;) {
		// no refresh token more than 1 week
		// see https://firebase.google.com/docs/cloud-messaging/manage-tokens#ensuring-registration-token-freshness
		await sb.sleep(7 * 86400000)
		let token = await tryGetToken()
		if (token) await api.updateFcmToken(token, '', 'desktop')
	}
}

async function tryGetToken() {
	try {
		let token = await getToken(getMessaging(app), {
			serviceWorkerRegistration: _service_worker_registration,
			vapidKey: config.FirebasePublichVapidKey,
		})
		return token
	} catch (e) {
		console.log('ERR', e)
		return ''
	}
}

let waitServiceWorker = loadServiceWorker()

let _pendingCall = {}
let callid = Date.now()

function callServiceWorker(name, param, option) {
	return new Promise(async (rs) => {
		await waitServiceWorker
		option = option || {}
		let timeout = option.timeout || 120000 // 2 mins
		let sw = option.sw || _service_worker

		if (!sw) return rs('err_unsuported')
		callid++
		let localcallid = callid
		_pendingCall[localcallid] = rs
		if (timeout)
			setTimeout(() => {
				if (!_pendingCall[localcallid]) return
				rs('err_timeout')
				delete _pendingCall[localcallid]
			}, timeout)
		sw.postMessage({type: 'synccall', name, param, id: localcallid})
	})
}

let defSW = {postMessage: async function () {}, addEventListener: () => {}, removeEventListener: () => {}}
let serviceWorker = async () => {
	await waitServiceWorker
	if (!_service_worker) return defSW
	return {
		postMessage: _service_worker.postMessage.bind(_service_worker),
		addEventListener: _service_worker.addEventListener.bind(_service_worker),
		removeEventListener: _service_worker.removeEventListener.bind(_service_worker),
	}
}

let requestPushNotification = async () => {
	await waitServiceWorker
	let token = await tryGetToken()
	if (token) await api.updateFcmToken(token, '', 'desktop')
}

let closeAllNotification = async () => {
	await waitServiceWorker
	if (!navigator.serviceWorker) return
	try {
		let registration = await navigator.serviceWorker.getRegistration()
		if (!registration) return
		let notifications = await registration.getNotifications()
		notifications.forEach((notification) => notification.close())
	} catch (err) {
		console.error(err)
	}
}

if (navigator.serviceWorker) {
	navigator.serviceWorker.addEventListener('controllerchange', (event) => {
		window.location.reload()
	})
	navigator.serviceWorker.addEventListener('message', (ev) => {
		messageCbs.map((cb) => cb(ev))
		if (ev.data.type === 'play_notification_sound') {
			console.log('play_notification_sound eventttt', ev)
			return playAudioThrottle(ev)
		}
		if (ev.data.type === 'credential') {
			let me = api.getCred() || {}
			if (me.agent_id !== ev.data.me.agent_id) {
				// redirect to the redirected route in url
				const urlSearchParams = new URLSearchParams(window.location.search)
				const url = urlSearchParams.get('redirect') || '/'
				location.href = url
			}
			return
		}

		if (ev.data.type !== 'synccall') return
		if (_pendingCall[ev.data.id]) {
			_pendingCall[ev.data.id](ev.data.body)
			delete _pendingCall[ev.data.id]
		}
	})
}

// load audio
let audioFile = ''
let audioSupport = document.createElement('audio')
if (audioSupport.canPlayType('audio/mpeg')) {
	audioFile = require('../src/assets/media/alert.mp3')
} else {
	audioFile = require('../src/assets/media/alert.ogg')
}
let audio = new Audio(audioFile)
const playAudioThrottle = lo.throttle((ev) => {
	let soundName = lo.get(ev, 'data.data.sound_name')
	if (soundName === 'loud') {
		audio.src = require('../src/assets/media/message_noti_loud.mp3')
		audio.load()
	}
	audio.currentTime = 0
	// cannot play audio, mostly DOMException
	audio.play().catch((e) => console.log('PLAYSOUND ERR', e))
}, 2000)

export default {
	waitServiceWorker,
	callServiceWorker,
	serviceWorker,
	closeAllNotification,
	requestPushNotification,
	registerEventMessage,
}
