import lo from 'lodash'
import sb from '@sb/util'
import store from '@sb/store'
import Fragment from './fragment'
import UserProfile from '../activities/user_profile.js'
import TaskForm from './task_form.js'

import FacebookPrivateReply from '../activities/facebook_private_reply.js'

export default {
	name: 'task-detail',
	props: ['task_id'],

	data() {
		return {
			task: {},
			fetching: true,
			fetchError: '',

			selectedUserId: '',

			isShowFacebookPrivateReply: false,
			newConvoUid: '',
			newConvoCommentId: '',
			touchpoint: {},
			convo_id: '',
		}
	},

	watch: {
		task_id() {
			if (this.task_id) {
				this.doSeen()
				this.loadTask()
			}
		},
	},

	async mounted() {
		store.onTaskHistory(this, async (ev) => {
			this.$forceUpdate()
			let $list = this.$refs.history
			if (!$list) return
			await this.$nextTick()
			$list.scrollTop = $list.scrollHeight
		})
		store.onTask(this, (taskM) => {
			if (taskM && taskM[this.task.id]) {
				// only update watchers and status
				// TODO, seen update shouldnt send to onTask
				let newWatchers = lo.get(taskM, [this.task.id, 'watchers'], [])
				let newStatus = lo.get(taskM, [this.task.id, 'status'], [])
				let doneAt = lo.get(taskM, [this.task.id, 'done_at'])
				let associatedUsers = lo.get(taskM, [this.task.id, 'associated_users'])
				this.task.watchers = newWatchers
				this.task.status = newStatus
				if (doneAt) this.task.done_at = doneAt
				this.$forceUpdate()
				this.selectedUserId = lo.last(associatedUsers)
			}
		})

		this.doSeen()
		this._seenInterval = setInterval(() => this.doSeen(), 5000)
		this.$once('hook:beforeDestroy', () => clearInterval(this._seenInterval))

		this.loadTask()
	},

	methods: {
		onCreateTask(task) {
			let associatedUsers = lo.get(task, 'associated_users', [])
			if (lo.size(associatedUsers)) this.selectedUserId = lo.last(associatedUsers)
			addParamsToLocation({open_task_id: lo.get(task, 'id')})
		},

		doSeen: lo.throttle(
			function (e) {
				if (this.task_id) store.seenTask(this.task_id)
			},
			1000,
			{trailing: true},
		),

		async loadTask() {
			this.fetching = true
			this.fetchError = ''
			if (this.task_id === 'new') {
				this.task = {
					id: '',
					created: Date.now(),
					status: 'open',
					assignee: store.me().id,
					created_by: store.me().id,
					due_at: Date.now() + 24 * 3600_000,
					type: 'todo',
					priority: 0,
					note: '',
					pinned: 0,
					supervisor: store.me().id,
				}
			} else {
				let {error} = await store.fetchTasks([this.task_id], true)
				let task = store.matchTask(this.task_id)
				if (!task || error) {
					let errCode = error || 'not_found'
					this.fetchError = errCode
					this.fetching = false
					return
				}
				task = lo.cloneDeep(task)
				this.task = task
			}

			let associatedUsers = lo.get(this.task, 'associated_users', [])
			this.selectedUserId = lo.last(associatedUsers)
			let associatedConvos = lo.get(this.task, 'associated_conversations', [])
			let promises = []
			// use to display dynamic field in description
			if (lo.size(associatedUsers)) promises.push(store.fetchUsers(associatedUsers))
			if (lo.size(associatedConvos)) promises.push(store.fetchConvos(associatedConvos))

			console.time('load associated_users')
			await Promise.all(promises)
			console.timeEnd('load associated_users')

			this.fetching = false
		},

		onClickHyperlink({uid, cid}) {
			this.selectedUserId = uid
			if (cid) {
				this.$root.$emit('showQuickview', {user_id: uid, convo_id: cid})
			}
		},

		renderContent() {
			return (
				<TaskForm
					task={this.task}
					vOn:back={() => this.$emit('back')}
					vOn:clickHyperlink={this.onClickHyperlink}
					vOn:change={(task) => (this.task = task)}
					vOn:submit={this.onCreateTask}
				/>
			)
		},

		renderEmptyUserProfile() {
			return (
				<div style='border-left: 1px solid #dee2e6; flex-shrink: 0; flex: unset; width: 350px; background: whitesmoke; padding: 10px; font-size: 14px;'>
					<div class='text__semibold'>{this.$t('customer')}</div>
					<div class='mt-2 text__muted '>
						Chưa có khách hàng nào liên quan tới nhắc việc này, để thêm khách liên quan, bạn có thể:
					</div>

					<div class='text__muted '>1. Thêm link cuộc chat của khách vào mô tả</div>
					<div class='text__muted '>2. Nhắc tới người dùng trong mô tả bằng ký tự @</div>
					<div class='text__muted '>3. Bình luận link cuộc chat của khách</div>
				</div>
			)
		},

		onUcidChange(uid, cid) {
			//return
			// UserProfile always emit 1 event when mounted
			this.$root.$emit('showQuickview', {user_id: this.selectedUserId, convo_id: cid})
		},

		async openFacebookPrivateReply(param) {
			if (!param) {
				this.isShowFacebookPrivateReply = false
				return
			}

			let uid = this.selectedUserId
			let convos = store.matchUserConvos(this.selectedUserId)
			if (convos.length == 0) {
				await store.fetchUserConvos(this.selectedUserId)
				if (this.selectedUserId !== uid) return // outdated
				convos = store.matchUserConvos(this.selectedUserId)
			}

			let connectorType = lo.get(param.touchpoint, 'channel')
			convos = lo.orderBy(convos, 'actived', 'desc')
			let openConvo = lo.find(convos, (convo) => {
				if (connectorType == 'facebook') {
					return lo.get(convo, 'touchpoint.channel') === 'facebook' && convo.state != 'ended'
				}

				if (connectorType == 'instagram') {
					return lo.get(convo, 'touchpoint.channel') === 'instagram' && convo.state != 'ended'
				}

				if (connectorType == 'zalo') {
					return lo.get(convo, 'touchpoint.channel') === 'zalo' && convo.state != 'ended'
				}

				if (connectorType == 'facebook_comment') {
					return lo.get(convo, 'touchpoint.channel') === 'facebook' && convo.state != 'ended'
				}

				if (connectorType == 'instagram_comment') {
					return lo.get(convo, 'touchpoint.channel') === 'instagram' && convo.state != 'ended'
				}
			})

			if (openConvo) {
				this.ucidChange(this.selectedUserId, openConvo.id)
				this.isShowFacebookPrivateReply = false
				return
			}

			this.convo_id = lo.get(lo.first(convos), 'id')
			this.isShowFacebookPrivateReply = true
			this.newConvoUid = this.selectedUserId
			this.newConvoCommentId = param.comment_id
			this.touchpoint = param.touchpoint || {}
		},

		renderError() {
			return (
				<div class='d-flex w_100 h_100' style='overflow: hidden'>
					<div class='h_100 d-flex flex-column flex__1'>
						<div class='task_detail_header'>
							<div
								class='btn btn__white btn__sm align-items-center'
								style='display: inline-flex'
								vOn:click={() => this.$emit('back')}
							>
								<Icon name='arrow-left' size='16' stroke-width='2' class='mr-2' />
								{this.$t('back')}
							</div>
						</div>
						<div class='task_detail_content_wrapper d-flex align-items-center justify-content-center pl-5 pr-5'>
							{this.fetchError === 'not_found' ? (
								<div class='text__danger text__center'>{this.$t('desc_about_deleted_task')}</div>
							) : (
								<div class='text__danger text__center'>
									{'Không thể tải dữ liệu nhắc việc.'}{' '}
									<a class='link link__danger text__underline' vOn:click={this.loadTask}>
										{this.$t('retry')}
									</a>
								</div>
							)}
						</div>
					</div>
					<div
						class='profile'
						style='border-left: 1px solid #dee2e6; flex-shrink: 0; flex: unset; width: 350px; background: whitesmoke;'
					/>
				</div>
			)
		},
	},

	render() {
		let ucid = (this.selectedUserId || '') + '%'

		if (this.fetching) {
			return (
				<div class='d-flex w_100 h_100' style='overflow: hidden'>
					<div class='h_100 d-flex flex-column flex__1'>
						<div class='task_detail_header'>
							<div style={`height: 31px; width: 50%; background: whitesmoke; border-radius: 4px`} />
						</div>
						<div class='task_detail_content_wrapper'>
							<div class='task_detail_main_fields'>
								<div class='d-flex align-items-center' style='height: 26.5px; margin-bottom: 15px'>
									<div style={`height: 14px; width: 50%; background: whitesmoke; border-radius: 4px`} />
								</div>
								<div
									style={`height: 20px; width: 50%; background: whitesmoke; border-radius: 4px; margin-bottom: 20px`}
								/>
								<div
									style={`height: 14px; width: 100%; background: whitesmoke; border-radius: 4px; margin-bottom: 4px`}
								/>
								<div
									style={`height: 14px; width: 100%; background: whitesmoke; border-radius: 4px; margin-bottom: 4px`}
								/>
								<div
									style={`height: 14px; width: 50%; background: whitesmoke; border-radius: 4px; margin-bottom: 4px`}
								/>
							</div>
						</div>
						<div class='task_detail_footer'>
							<div class='task_detail_footer_inner'>
								<div style={`height: 32px; width: 100%; background: whitesmoke; border-radius: 4px`} />
							</div>
						</div>
					</div>
					<div
						class='profile'
						style='border-left: 1px solid #dee2e6; flex-shrink: 0; flex: unset; width: 350px; background: whitesmoke;'
					/>
				</div>
			)
		}
		if (this.fetchError) return this.renderError()

		let $user = null
		if (this.task.id) {
			$user = this.renderEmptyUserProfile()
			if (this.selectedUserId) {
				$user = (
					<Fragment>
						<UserProfile
							dont_auto_select_convo
							ucid={ucid}
							vOn:ucidChange={this.onUcidChange}
							vOn:showNewConvo={(p) => this.openFacebookPrivateReply(p)}
							customer_header
							style='border-left: 1px solid #dee2e6; flex-shrink: 0; flex: unset; width: 350px; background: whitesmoke;'
						/>
						<FacebookPrivateReply
							show={this.isShowFacebookPrivateReply}
							user_id={this.newConvoUid}
							convo_id={this.convo_id}
							comment_id={this.newConvoCommentId}
							vOn:changeUcid={this.onUcidChange}
							vOn:close={() => (this.isShowFacebookPrivateReply = false)}
							touchpoint={this.touchpoint}
						/>
					</Fragment>
				)
			}
		}
		return (
			<div class='d-flex w_100 h_100' style='overflow: hidden'>
				{this.renderContent()}
				{$user}
			</div>
		)
	},
}

function addParamsToLocation(params) {
	let url = new URL(window.location.href)
	lo.each(params, (value, key) => {
		if (value) {
			url.searchParams.set(key, value)
		} else {
			url.searchParams.delete(key)
		}
	})
	window.history.pushState({}, null, url.href)

	return
	history.pushState(
		{},
		null,
		this.$route.path +
			'?' +
			Object.keys(params)
				.map((key) => {
					return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
				})
				.join('&'),
	)
}
