import sb from '@sb/util'
import lo from 'lodash'
import store from '@sb/store'
import Fragment from './fragment'
import MessageEditor from '../commons/privateMessageEditor.js'
import TaskCommentMessage from './task_comment_message.js'
import UserProfile from '../activities/user_profile.js'
import TextMessage from '../activities/conversation/textMessage.js'
import flow from '@subiz/flow'

export default {
	name: 'task-form',
	// mode is mini or full, default is full
	props: ['task', 'mode'],
	data() {
		return {
			loading: false,
			isShowDatePicker: false,
			initcomment: {},
			submittingcomment: false,
			loadingComments: false,

			isEditNote: false,
			initNote: {},
			isEditTitle: false,
			inputTitle: '',

			// display descript in full mode or max 4 line
			isDescriptionOverflow: false,
		}
	},

	mounted() {
		store.onTaskHistory(this, async (ev) => {
			this.$forceUpdate()
			let $list = this.$refs.history
			if (!$list) return
			await this.$nextTick()
			$list.scrollTop = $list.scrollHeight
		})
		this.setTitleInputHeight()
		this.checkDescriotonOverflow()
		this.loadComments()

		// focus title
		if (!this.task.id) {
			this.$refs.title_input && this.$refs.title_input.focus()
		}
		window.addEventListener('paste', this.onPaste)
	},

	destroyed() {
		window.removeEventListener('paste', this.onPaste)
	},

	beforeDestroy() {
		this.saveDraftTask()
	},

	async created() {
		await this.setDraftTaskValue()
		this.initNote = {
			format: 'delta',
			text: parseHyperlinkDeltaToString(this.task.note_quill_delta || ''),
		}
	},

	methods: {
		saveDraftTask() {
			if (this.task.id) return
			let task = lo.cloneDeep(this.task)
			task.note_quill_delta = this.$refs.note_input.GetMessage().text
			if (this.isClickCreate) {
				store.updateDraftTask({})
			} else {
				store.updateDraftTask(task)
			}
		},

		setDraftTaskValue() {
			if (this.task.id) return
			let task = lo.cloneDeep(this.task)
			let draftTask = store.matchDraftTask() || {}

			// when create user task, only set draft task for exactly user
			if (this.mode === 'mini' && !lo.isEqual(draftTask.associated_users, this.task.associated_users)) return

			// only set title, description and files
			task = {
				...task,
				title: draftTask.title,
				note_quill_delta: draftTask.note_quill_delta,
				files: draftTask.files,
			}
			this.$emit('change', task)
		},

		checkDescriotonOverflow() {
			let $div = this.$refs.description_text
			if (!$div) return

			let dom = $div.$el
			if (!dom || !dom.getBoundingClientRect) return

			let height = dom.getBoundingClientRect().height || 0
			const MAX_HEIGHT = 84 // 4 lines of 14px text
			this.isDescriptionOverflow = height > MAX_HEIGHT
		},

		onPaste(ev) {
			// cannot paste to description when edit task
			if (this.task.id) return
			if (!this.canEditTask()) return
			let files = lo.get(ev, 'clipboardData.files')
			if (!lo.size(files)) return
			let $main = this.$refs.main_fields
			// dont use ev.path because ClipboardEvent doesnt have path property on Windown 10
			//if (!lo.includes(ev.path, $main)) return

			let $editor = this.$refs.note_input
			let $titleInput = this.$refs.title_input
			if (!$editor || !$titleInput) return
			if (document.activeElement !== $titleInput && !$editor.$el.contains(document.activeElement)) return

			// keep editor not change when paste file
			if ($editor.$el.contains(document.activeElement)) {
				let currentContent = $editor.GetContents()
				// use setTimeout to make sure setContents after onChangeText
				setTimeout(() => {
					$editor.SetContents(currentContent)
				}, 10)
			}

			this.addLocalFiles({target: {files}})
		},

		async loadComments() {
			if (!this.task.id) return
			this.loadingComments = true
			await store.fetchTaskComments(this.task.id)
			this.loadingComments = false
			this.$forceUpdate()
		},

		async createTask() {
			store.updateDraftTask({})
			this.isClickCreate = true
			if (this.loading) return
			this.loading = true
			let task = lo.cloneDeep(this.task)
			task.note_quill_delta = this.$refs.note_input.GetMessage().text

			// append associated user and convo to description when create user task
			if (this.mode === 'mini') {
				let quilldeltas = sb.parseJSON(task.note_quill_delta) || {}
				quilldeltas = sb.trimQuillDelta(quilldeltas)
				//isDescEmpty = !lo.size(quilldeltas.ops)

				let convoUrl = window.location.href
				// if quickview is open, try assign uid, cid from quickview
				let url = new URL(window.location.href)
				let quickview = url.searchParams.get('quickview')
				if (quickview) {
					//quickview = decodeURIComponent(quickview)
					let [uid, cid] = lo.split(quickview, '%')
					console.log('VVVVVVVVVVVV', quickview, uid, cid)
					convoUrl = `https://${process.env.ENV === 'desktop' ? 'desktop.subiz.com.vn' : 'app.subiz.com.vn'}/convo?uid=${uid || ''}&cid=${cid || ''}`
				}

				if (!quilldeltas.ops) quilldeltas.ops = []
				let prefix = lo.size(quilldeltas.ops) ? '\n' : ''
				quilldeltas.ops.push({insert: prefix + convoUrl})
				task.note_quill_delta = JSON.stringify(quilldeltas)
			}
			if (!task.status) task.status = 'open'
			task = await store.createTask(task)
			this.loading = false
			this.$emit('change', task)
			this.$emit('submit', task)
		},

		setTitleInputHeight(force) {
			let $input = this.$refs.title_input
			if ($input) {
				$input.style.height = 'auto' // must set auto first because scrollHeight doesnt reset if 2line => 1line
				$input.style.height = $input.scrollHeight + 'px'
			}
		},

		canEditTask() {
			let me = store.me().id
			if (!this.task.id) return true
			return me === this.task.assignee || me === this.task.supervisor || me === this.task.created_by
		},

		onChangeDueDate(time, term) {
			let task = lo.clone(this.task)
			task.due_at = time
			this.$emit('change', task)
			this.isShowDatePicker = false
			if (this.task.id) {
				store.updateTask({id: this.task.id, due_at: task.due_at}, ['due_at'])
			}
		},

		onRemoveTaskFile(idx) {
			let task = lo.cloneDeep(this.task)
			let files = task.files || []
			files.splice(idx, 1)
			task.files = files
			this.$emit('change', task)
			if (this.task.id) {
				store.updateTask({id: this.task.id, files: files}, ['files'])
			}
		},

		renderFiles() {
			let files = lo.get(this.task, 'files', [])

			let $files = lo.map(files, (file, idx) => {
				if ((file.type || '').startsWith('image')) {
					return (
						<div class='task_detail_attachment'>
							<img2 clickable src={file.url} style='width: 100%; height: 100%; border-radius: 8px; overflow: hidden' />
							{file._loading && (
								<div
									style='z-index: 2; background: rgba(255, 255, 255, 0.7); position: absolute; inset: 0;'
									class='d-flex align-items-center justify-content-center'
								>
									<Spinner mode='blue' />
								</div>
							)}
							{!file._loading && this.canEditTask() && (
								<div class='task_detail_attachment_x' vOn:click={() => this.onRemoveTaskFile(idx)}>
									<Icon name='x' size='12' stroke-width='2' />
								</div>
							)}
						</div>
					)
				}
				return (
					<div class='task_detail_attachment'>
						<div class='text__center text__sm pl-3 pr-3'>
							<Icon name='file' size='16' stroke-width='2' class='text__secondary' />
							<br />
							<a href={file.url} target='_blank' class='link link__secondary'>
								{file.name}
							</a>
						</div>
						{file._loading && (
							<div
								style='z-index: 2; background: rgba(255, 255, 255, 0.7); position: absolute; inset: 0;'
								class='d-flex align-items-center justify-content-center'
							>
								<Spinner mode='blue' />
							</div>
						)}
						{!file._loading && this.canEditTask() && (
							<div class='task_detail_attachment_x' vOn:click={() => this.onRemoveTaskFile(idx)}>
								<Icon name='x' size='12' stroke-width='2' />
							</div>
						)}
					</div>
				)
			})
			if (!lo.size(files) && this.task.id) return null
			if (!this.task.id) {
				$files.push(
					<div
						class='task_detail_attachment add_attachment'
						style='border-width: 2px; border-style: dashed'
						v-tooltip={this.$t('add_attachments')}
						vOn:click={() => this.$refs.file_input.click()}
					>
						<Icon name='plus' size='32' stroke-width='1' />
					</div>,
				)
			}

			return <div class='d-flex flex-wrap mt-3'>{$files}</div>
		},

		async addLocalFiles(e, type) {
			let task = lo.cloneDeep(this.task)
			let efiles = lo.get(e, 'target.files', [])
			let files = this.task.files || []
			let newFiles = []
			for (let i = 0; i < efiles.length; i++) {
				let file = efiles[i]
				let id = sb.randomString(20)
				let tempUrl = await sb.getBlobUrlFromFile(file)
				newFiles.push({
					type: file.type,
					url: tempUrl,
					size: file.size,
					name: file.name,
					_loading: true,
					_local_id: id,
					_file: file,
				})
			}
			task.files = [...files, ...newFiles]
			this.$emit('change', task)

			await flow.map(newFiles, 5, async (file) => {
				let res = await store.uploadLocalFile(file._file)
				let attachmentIndex = lo.findIndex(task.files, (cFile) => cFile._local_id === file._local_id)
				if (res.error) {
					if (attachmentIndex > -1) lo.set(task, `files.${attachmentIndex}._error`, res.error)
				} else {
					if (attachmentIndex > -1) {
						lo.set(task, `files.${attachmentIndex}`, {
							type: file.type,
							mimetype: file.type,
							url: res.url,
							size: file.size,
							name: file.name,
						})
					}
				}
			})

			let error = ''
			lo.each(task.files, (file) => {
				if (file._error) {
					error = file._error
					return false // break
				}
			})

			if (error) this.$showError(this.$t(error))

			task.files = lo.filter(task.files, (file) => !file._error)
			this.$emit('change', task)

			if (this.task.id) {
				store.updateTask({id: this.task.id, files: task.files}, ['files'])
			}
		},

		async submitNote() {
			if (this.loading) return
			let task = lo.cloneDeep(this.task)
			let msg = this.$refs.note_input.GetMessage()
			task.note_quill_delta = msg.text
			this.$emit('change', task)
			await this.$nextTick()
			this.checkDescriotonOverflow()
			if (this.task.id) {
				this.toggleEditNote()
				this.loading = true
				let {note_quill_delta: delta} = await store.updateTask({id: this.task.id, note_quill_delta: msg.text}, [
					'note_quill_delta',
				])
				if (delta) {
					task.note_quill_delta = delta
					this.$emit('change', task)
					await this.$nextTick()
					this.checkDescriotonOverflow()
				}
				this.loading = false
			}
		},

		async toggleEditNote() {
			this.isEditNote = !this.isEditNote
			if (this.isEditNote) {
				this.initNote = {
					format: 'delta',
					text: parseHyperlinkDeltaToString(this.task.note_quill_delta || ''),
				}
				await this.$nextTick()
				this.$refs.note_input && this.$refs.note_input.Focus()
			}
		},

		renderNote() {
			if (!this.task.id) {
				return (
					<Fragment>
						<MessageEditor
							class='task_note_editor no_border'
							style='min-height: 42px; margin-bottom: 10px; margin-top: 15px;'
							placeholder={this.$t('add_description')}
							noTemplate
							task_description
							initEvent={this.initNote}
							submittable={false}
							connectorType={'subiz'}
							ref='note_input'
							email={false}
						/>
						{this.renderFiles()}
					</Fragment>
				)
			}
			if (this.isEditNote)
				return (
					<Fragment>
						<MessageEditor
							class='task_note_editor edit_mode'
							style='min-height: 42px'
							placeholder={this.$t('add_description')}
							noTemplate
							task_description
							initEvent={this.initNote}
							submittable={false}
							connectorType={'subiz'}
							ref='note_input'
							email={false}
						/>
						{this.renderFiles()}
						<div class='mt-3 d-flex align-items-center' style='height: 29px'>
							<div
								class='btn btn__xs btn__primary'
								style='padding-left: 8px; padding-right: 8px'
								vOn:click={this.submitNote}
							>
								{this.$t('save')}
							</div>
							<div class='ml-3 link link__secondary text__sm' vOn:click={this.toggleEditNote}>
								{this.$t('cancel')}
							</div>
						</div>
					</Fragment>
				)

			if (!this.canEditTask() && !this.task.note_quill_delta) {
				return (
					<em style='display: inline-block' class='text__sm text__muted mb-3'>
						{this.$t('no_description')}
					</em>
				)
			}
			let ev = {
				by: {
					type: 'agent',
					id: store.me().id,
				},
				data: {
					message: {
						format: 'delta',
						text: this.task.note_quill_delta,
					},
				},
			}

			let textcls = 'task_note_text_meessage mt-3 mb-2'
			if (this.isDescriptionOverflow) textcls += ' has_overflow'

			let isDescEmpty = false
			let quilldeltas = sb.parseJSON(this.task.note_quill_delta || '') || {}
			quilldeltas = sb.trimQuillDelta(quilldeltas)
			isDescEmpty = !quilldeltas || !lo.size(quilldeltas.ops)

			return (
				<Fragment>
					<TextMessage
						ref='description_text'
						ev={ev}
						class={textcls}
						vOn:clickHyperlink={({uid, cid}) => {
							this.$emit('clickHyperlink', {uid, cid})
						}}
					>
						{this.isDescriptionOverflow && (
							<div class='view_more' vOn:click={() => (this.isDescriptionOverflow = false)}>
								{this.$t('show_more')}
							</div>
						)}
					</TextMessage>
					{this.renderFiles()}
				</Fragment>
			)
		},
		// trigger change for same file
		clearFile(e) {
			e.target.value = ''
		},

		changeTitle(e) {
			let task = lo.clone(this.task)
			task.title = e.target.value
			this.$emit('change', task)
		},

		async toggleEditTitle() {
			this.isEditTitle = !this.isEditTitle
			if (this.isEditTitle) {
				this.inputTitle = lo.get(this.task, 'title', '')
				await this.$nextTick()
				this.$refs.title_input && this.$refs.title_input.focus()
			}
		},

		async onChangeAssignee(item) {
			let task = lo.cloneDeep(this.task)
			task.assignee = item.id
			this.$emit('change', task)
			if (this.task.id) {
				store.updateTask({id: this.task.id, assignee: item.id}, ['assignee'])
			}
		},

		renderAssignee() {
			let assignee = lo.get(this.task, 'assignee')
			let $assignee = <div>{this.$t('unassigned')}</div>
			if (assignee) {
				let agent = lo.get(store.matchAgent(), assignee, {})
				$assignee = (
					<div class='d-flex align-items-center'>
						<Avatar notooltip agent={agent} size='18' />
						<div class='ml-2 text__truncate' title={sb.getAgentDisplayName(agent)}>
							{sb.getAgentDisplayName(agent)}
						</div>
					</div>
				)
			}

			let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent' && agent.state === 'active')
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: sb.getAgentDisplayName(agent),
			}))
			return (
				<div class='d-flex align-items-center justify-content-between text__sm' style='overflow: hidden'>
					<div class='text__semibold mr-2 no-shrink' style='width: 120px'>
						{this.$t('assignee')}
					</div>
					{this.canEditTask() ? (
						<div vOn:click_stop={() => false}>
							<Dropdown
								mode='custom'
								items={items}
								vOn:select={this.onChangeAssignee}
								dropdown_width={200}
								style='overflow: hidden'
							>
								<div class='d-flex align-items-center clickable' ref='assignee_field'>
									{$assignee}
									<Icon name='chevron-down' stroke-width='2' size='16' class='text__muted ml-2' />
								</div>
							</Dropdown>
						</div>
					) : (
						$assignee
					)}
				</div>
			)
		},

		toggleDueDatePicker() {
			this.isShowDatePicker = !this.isShowDatePicker
			if (this.isShowDatePicker) {
				let $input = this.$refs.due_date_picker
				let rect = $input ? $input.getBoundingClientRect() : {}
				let {top = 0, bottom = 0, width = 0, left = 0} = rect

				// workaround, because datepicker dropdown is absolute, cannot get height from this
				const DROPDOWN_HEIHGT = 370
				const EDGE = 50

				let shouldPlaceTop = window.innerHeight - bottom <= DROPDOWN_HEIHGT + EDGE

				if (shouldPlaceTop) {
					this.dueDateStyle = `position: fixed; bottom: ${
						window.innerHeight - top + DROPDOWN_HEIHGT
					}px; left: ${left}px; z-index: 99`
				} else {
					this.dueDateStyle = `position: fixed; top: ${bottom}px; left: ${left}px; z-index: 99`
				}
			}
		},

		renderRemindMe() {
			let items = [
				{
					id: 0,
					label: this.$t('no_remind'),
				},
				{
					id: 15 * 60_000,
					label: this.$t('before') + ' 15 ' + this.$t('minutes'),
				},
				{
					id: 30 * 60_000,
					label: this.$t('before') + ' 30 ' + this.$t('minutes'),
				},
				{
					id: 60 * 60_000,
					label: this.$t('before') + ' 1 ' + this.$t('hour'),
				},
				{
					id: 24 * 60 * 60_000,
					label: this.$t('before') + ' 1 ' + this.$t('day'),
				},
			]
			return (
				<div class='d-flex align-items-center justify-content-between mt-2 text__sm' style='position: relative'>
					<div class='text__semibold mr-2 no-shrink' style='width: 120px'>
						{this.$t('remind_me')}
					</div>
					<div vOn:click_stop={() => false}>
						<Dropdown
							mode='link'
							extra_cls='no_border'
							disabled={!this.canEditTask()}
							dropdown_width={180}
							items={items}
							selected={this.task.reminder || 0}
							ref='remind_field'
							vOn:select={(item) => this.onSelectReminder(item.id)}
						/>
					</div>
				</div>
			)
		},

		onSelectReminder(v) {
			let task = lo.cloneDeep(this.task)
			task.reminder = v
			this.$emit('change', task)
			if (this.task.id) {
				store.updateTask({id: this.task.id, reminder: v}, ['reminder'])
			}
		},

		renderDueAt() {
			let acc = store.me().account
			let date_format = lo.get(acc, 'date_format', 'YYYY-MM-DD')
			date_format = date_format.replace('DD', 'dd').replace('YYYY', 'yyyy')
			let $due_at = <Time time={Date.now()} />

			let due_at = Date.now()
			if (this.task.due_at) {
				due_at = new Date(this.task.due_at)
				$due_at = (
					<fragment>
						<Time time={due_at} />
						<Icon name='calendar' stroke-width='1.5' size='18' class='ml-2 text__muted' />
					</fragment>
				)
			} else {
				$due_at = (
					<fragment>
						<Icon name='calendar' stroke-width='1.5' size='18' class='link link__primary mr-2' />
						<span class='link link__primary'>{this.$t('add_due')}</span>
					</fragment>
				)
			}

			return (
				<div class='d-flex text__sm align-items-center justify-content-between mt-2' style='position: relative'>
					<div class='text__semibold mr-2 no-shrink' style='width: 120px'>
						{this.$t('due_date')}
					</div>
					{this.canEditTask() ? (
						<div v-clickaway={() => (this.isShowDatePicker = false)} style=''>
							<div
								ref='due_date_picker'
								vOn:click_stop={() => false}
								style='display: flex; align-items: center; cursor: pointer'
								vOn:click={this.toggleDueDatePicker}
							>
								{$due_at}
							</div>
							{this.isShowDatePicker && (
								<div vOn:click_stop={() => false}>
									<DatePicker
										format={date_format}
										ref='due_date_picker_dropdown'
										has_time
										class={lo.includes(this.dueDateStyle, 'bottom:') ? 'calendar_wrapper__top' : ''}
										style={this.dueDateStyle}
										vOn:change={(d, sc) => this.onChangeDueDate(d, sc)}
										current={due_at}
									/>
								</div>
							)}
						</div>
					) : this.task.due_at ? (
						<Time time={this.task.due_at} />
					) : (
						<div class='text__muted'>{this.$t('unselected')}</div>
					)}
				</div>
			)
		},

		onChangeTitle(e) {
			//e.target.style.height = e.target.scrollHeight + 'px'
			let task = lo.cloneDeep(this.task)
			task.title = e.target.value
			this.$emit('change', task)
			this.setTitleInputHeight()
		},

		onInputTitleKeydown(e) {
			let keyCode = e.keyCode
			// ENTER
			if (keyCode === 13) {
				e.preventDefault()
				e.target.blur()
				return
			}
		},

		async onSaveTitle(e) {
			if (!lo.trim(this.task.title) && this.task.id) {
				// cancel blur event
				e.preventDefault()
				e.target.focus()
				return
			}
			if (!this.task.id) {
				// focus description
				this.$refs.note_input.Focus()
				return
			}
			if (this.loading) return
			this.loading = true
			if (this.task.title !== this.currentTitle) {
				await store.updateTask({id: this.task.id, title: this.task.title}, ['title'])
			}
			this.loading = false
		},

		renderTitle() {
			let cls = 'task_title_input'
			if (!this.task.id) cls += ' no_border'

			return (
				<textarea
					ref='title_input'
					readonly={!this.canEditTask()}
					class={cls}
					placeholder={this.$t('add_title')}
					rows='1'
					value={this.task.title}
					vOn:input={this.onChangeTitle}
					vOn:keydown={this.onInputTitleKeydown}
					vOn:blur={this.onSaveTitle}
					vOn:focus={() => (this.currentTitle = this.task.title)}
				></textarea>
			)
		},

		renderActivityItem(ev) {
			if (ev.event.type === 'task_created') {
				let agid = lo.get(ev, 'event.by.id')
				let agent = store.matchAgent()[agid] || {}

				return (
					<div class='task_history__entry' style='margin-bottom: 10px'>
						<div class='task_history__entry__logo'>
							<div class='task_history__vline' style='top: 25px; height: 10px' />
							<Avatar
								notooltip
								size='16'
								agent={agent}
								class='task_history__entry__logo_img'
								style='margin-top: 3px; display: block'
							/>
						</div>
						<div class='task_history_entry__body' style='font-size: 14px'>
							<span class='text__semibold'>{sb.getAgentDisplayName(agent)}</span> {this.$t('created_this_task')}{' '}
							<Time class='text__muted ml-2' time={ev.event.created} />
						</div>
					</div>
				)
			}

			if (ev.event.type === 'task_completed') {
				let agid = lo.get(ev, 'event.by.id')
				let agent = store.matchAgent()[agid] || {}

				return (
					<div class='task_history__entry' style='margin-bottom: 10px'>
						<div class='task_history__entry__logo'>
							<div class='task_history__vline' style='top: 25px; height: 10px' />
							<Avatar
								notooltip
								size='20'
								agent={agent}
								class='task_history__entry__logo_img'
								style='margin-top: 3px; display: block'
							/>
						</div>
						<div class='task_history_entry__body' style='font-size: 14px'>
							{sb.getAgentDisplayName(agent)} {this.$t('completed_task')}{' '}
							<img
								width='14'
								height='14'
								style='display: inline-block'
								src={require('../../src/assets/img/done.svg')}
								class='task_history__entry__logo_img'
							/>{' '}
							<Time class='text__muted ml-2' time={ev.event.created} />
						</div>
					</div>
				)
			}

			let comment = ev.ref_comment || ev.event
			let agid = lo.get(ev, 'event.by.id')
			let agent = store.matchAgent()[agid] || {}

			let quilldeltas = lo.get(comment, 'data.message.text')
			quilldeltas = sb.parseJSON(quilldeltas)
			quilldeltas = sb.trimQuillDelta(quilldeltas)
			let isDescEmpty = !quilldeltas || !lo.size(quilldeltas.ops)
			isDescEmpty = isDescEmpty && !lo.size(lo.get(comment, 'data.message.attachments'))
			if (isDescEmpty) return null

			let $seen = null
			if (lo.size(ev._seen_members)) {
				let seenMembers = lo.map(ev._seen_members, (agid) => store.matchAgent()[agid] || {id: agid})
				$seen = (
					<div class='d-flex justify-content-end mt-2'>
						<AvatarGroup size={5} xxs agents={seenMembers} />
					</div>
				)
			}
			return (
				<div class='task_history__entry'>
					<div class='task_history__entry__logo'>
						<div class='task_history__vline' />
						<Avatar notooltip size='28' agent={agent} class='task_history__entry__logo_img' />
					</div>
					<div class='task_history_entry__body'>
						<TaskCommentMessage ev={comment} />
						{$seen}
					</div>
				</div>
			)
		},

		async onSubmitMsg(msg) {
			let quilldeltas = msg.text
			quilldeltas = sb.parseJSON(quilldeltas)
			quilldeltas = sb.trimQuillDelta(quilldeltas)
			let isDescEmpty = !quilldeltas || !lo.size(quilldeltas.ops)
			isDescEmpty = isDescEmpty && !lo.size(msg.attachments)
			if (isDescEmpty) return
			if (this.submittingcomment) return
			this.submittingcomment = true
			let ev = {
				type: 'task_comment_added',
				data: {
					message: msg,
				},
			}
			let lastinitcomment = this.initcomment
			this.initcomment = {format: 'plaintext', text: ''}

			// upload file first
			let atts = lo.get(ev, 'data.message.attachments', [])
			for (var i = 0; i < atts.length; i++) {
				if (lo.get(atts, [i, 'type']) !== 'file') continue
				if (!atts[i]._file) continue
				let {url, error} = await store.upload(atts[i])
				if (error) {
					this.submittingcomment = false
					return this.$showError(this.$t('something_wrong_retry_again'))
				}
				atts[i].url = url
			}

			let out = await store.addTaskComment(this.task.id, ev)
			if (out.error) {
				this.submittingcomment = false
				this.initcomment = lastinitcomment
				return this.$showError(this.$t('something_wrong_retry_again'))
			}
			this.submittingcomment = false
			this.initcomment = {format: 'plaintext', text: ''}
		},

		renderActivities() {
			if (!lo.get(this.task, 'id')) return null
			let events = store.matchTaskComments(this.task.id)
			events = lo.orderBy(events, (ev) => lo.get(ev, 'event.created'), ['asc'])

			// insert task created and task done
			// events.unshift({event: {created: this.task.created, by: {id: this.task.created_by}, type: 'task_created'}})
			if (this.task.done_at && this.task.status === 'done') {
				let maxDoneAt = events.length - 1
				for (; maxDoneAt >= 0 && events[maxDoneAt].event.created > this.task.done_at; maxDoneAt--) {}
				events.splice(maxDoneAt + 1, 0, {
					event: {created: this.task.done_at, type: 'task_completed', by: {type: 'agent', id: this.task.done_by}},
				})
			}

			events = this.fixEvents(events)
			let watchers = this.task.watchers || []
			watchers = lo.filter(watchers, Boolean)
			watchers = lo.uniq(watchers)
			watchers = lo.map(watchers, (agid) => store.matchAgent()[agid] || {})

			return (
				<div class='task_activities'>
					<div class='d-flex align-items-center pb-2'>
						<div class='text__semibold text__sm'>{this.$t('Activity')}</div>
						{this.loadingComments && <Spinner mode='blue' class='ml-3' />}
						<div class='flex__1' />
						<AvatarGroup xs size={5} agents={watchers} />
					</div>
					{lo.size(events) ? (
						lo.map(events, this.renderActivityItem)
					) : (
						<em class='text__muted text__sm'>{this.$t('no_activity')}</em>
					)}
				</div>
			)
		},

		// insert _seenAgents to comment event, and handle logic should not show seenMembers if next comment has the same seen memeber
		fixEvents(events) {
			events = lo.cloneDeep(events)
			lo.each(events, (ev, idx) => {
				let comment = ev.ref_comment || ev.event
				let agid = lo.get(ev, 'event.by.id')

				let seenMembers = lo.filter(this.task.members, (mem) => {
					return mem.last_seen >= comment.created
				})
				seenMembers = lo.map(seenMembers, (mem) => mem.agent_id)
				let nextSeenMembers = []
				let nextComment = lo.get(events, [idx + 1, 'ref_comment']) || lo.get(events, [idx + 1, 'event'])
				if (nextComment) {
					nextSeenMembers = lo.filter(this.task.members, (mem) => mem.last_seen >= nextComment.created)
					nextSeenMembers = lo.map(nextSeenMembers, (mem) => mem.agent_id)
				}

				let shouldShowComment = !lo.isEqual(nextSeenMembers, seenMembers)
				//console.log('shouldShowComment', comment.id, nextSeenMembers, seenMembers)
				if (shouldShowComment) ev._seen_members = seenMembers
			})
			return events
		},

		renderCreated() {
			if (!this.task.id) return null

			let agent = store.matchAgent()[this.task.created_by] || {}

			let $actions = null
			if (this.task.id && this.mode === 'mini') {
				if (this.task.status === 'done') {
					$actions = (
						<button
							class='btn btn__xs btn__light align-items-center ml-3'
							style='display: inline-flex; font-size: 12px; padding: 3px 8px; border-radius: 3px'
							disabled={this.loading}
							vOn:click={() => this.updateTaskStatus('open')}
						>
							{this.$t('reopen_task')}
						</button>
					)
				} else {
					$actions = (
						<button
							class='btn btn__xs btn__success align-items-center ml-3'
							style='display: inline-flex; font-size: 12px; padding: 3px 8px; border-radius: 3px'
							disabled={this.loading}
							vOn:click={() => this.updateTaskStatus('done')}
						>
							{this.$t('done')}
						</button>
					)
				}
			}
			if (!this.canEditTask()) $actions = null

			let moreItems = [
				{
					id: 'edit_description',
					label: this.$t('edit_description'),
				},
				{
					id: 'add_attachments',
					label: this.$t('add_attachments'),
				},
			]
			if (this.task.created_by === store.me().id) {
				moreItems.push({
					id: 'delete_task',
					label: this.$t('delete_task'),
				})
			}
			let $more = (
				<Dropdown
					mode='custom'
					dropdown_width={180}
					items={moreItems}
					vOn:select={this.onSelectMoreItem}
					style='line-height: 1;'
				>
					<button
						class='btn btn__xs btn__white align-items-center justify-content-center'
						style='display: inline-flex; font-size: 12px; height: 20px; width: 20px; border-radius: 4px; padding: 0; border: none;'
					>
						<Icon name='dots' size='16' stroke-width='2' />
					</button>
				</Dropdown>
			)
			if (!this.canEditTask()) $more = null
			return (
				<div class='d-flex align-items-center mb-2'>
					<div class='text__muted' style='font-size: 13px'>
						{this.task.number ? `#${Number(this.task.number)}, ` : ''}
						{this.$t('created_by').toLowerCase()} {sb.getAgentDisplayName(agent)}
						{', '}
						<Time ago suffix time={this.task.created} />
					</div>
					<div class='flex__1' />
					{$more}
					{$actions}
				</div>
			)
		},

		onSelectMoreItem(item) {
			if (item.id === 'edit_description') return this.toggleEditNote()
			if (item.id === 'add_attachments') return this.$refs.file_input.click()
			if (item.id === 'delete_task') return this.onDeleteTask()
		},

		async onDeleteTask() {
			let cf = await this.$confirm({
				title: this.$t('delete_task'),
				description: this.$t('delete_task_desc'),
				style: 'danger',
				ok: this.$t('delete'),
				cancel: this.$t('cancel'),
			})
			if (cf) {
				await store.deleteTask(this.task.id)
				this.$emit('back')
				this.$emit('submit')
			}
		},

		async updateTaskStatus(value) {
			if (this.loading) return
			this.loading = true
			let body = await store.markTaskDone(this.task.id, value)
			this.loading = false
			if (body.error) {
				return this.$showError('Không thể thay đổi trạng thái nhắc việc, vui lòng thử lại')
			}
			this.$emit('change', body)
		},

		renderHeader() {
			let $actions = null
			if (this.task.id) {
				if (this.task.status === 'done') {
					$actions = (
						<div
							class='btn btn__sm btn__light align-items-center'
							style='display: inline-flex'
							vOn:click={() => this.updateTaskStatus('open')}
						>
							{this.$t('reopen_task')}
						</div>
					)
				} else {
					$actions = (
						<div
							class='btn btn__sm btn__success align-items-center'
							style='display: inline-flex'
							vOn:click={() => this.updateTaskStatus('done')}
						>
							{this.$t('done')}
						</div>
					)
				}
			}

			if (!this.canEditTask()) $actions = null

			if (this.mode === 'mini') {
				return (
					<div class='task_detail_header'>
						<div style='font-size: 16px'>{this.task.id ? this.$t('edit_task') : this.$t('create_task')}</div>
					</div>
				)
			}

			return (
				<div class='task_detail_header'>
					<div
						class='link link__secondary align-items-center'
						style='display: inline-flex'
						vOn:click={() => this.$emit('back')}
					>
						<Icon name='arrow-left' size='16' stroke-width='2' class='mr-2' />
						{this.$t('back_short')}
					</div>
					<div class='flex__1' />
					{$actions}
				</div>
			)
		},

		onClickWrapper(e) {
			let $form = this.$refs.main_fields
			let $assignee_field = this.$refs.assignee_field
			let $due_date_picker = this.$refs.due_date_picker
			let $due_date_picker_dropdown = this.$refs.due_date_picker_dropdown && this.$refs.due_date_picker_dropdown.$el
			let $remind_field = this.$refs.remind_field.$el

			// dont use e.path anymore, somehow browser on Window doest have property ev.path
			//if (lo.includes(e.path, $form)) return
			//if (lo.includes(e.path, $assignee_field)) return
			//if (lo.includes(e.path, $due_date_picker)) return
			//if (lo.includes(e.path, $due_date_picker_dropdown)) return
			//if (lo.includes(e.path, $remind_field)) return
			if (this.task.id) return

			// input is focusing
			if (document.activeElement === this.$refs.title_input) return
			if (this.$refs.note_input && this.$refs.note_input.HasFocus()) return

			let isDescEmpty = false
			let quilldeltas = this.$refs.note_input.GetMessage().text
			quilldeltas = sb.parseJSON(quilldeltas)
			quilldeltas = sb.trimQuillDelta(quilldeltas)
			isDescEmpty = !quilldeltas || !lo.size(quilldeltas.ops)

			if (!this.task.title) {
				this.$refs.title_input && this.$refs.title_input.focus()
			}
			if (this.task.title && isDescEmpty) {
				this.$refs.note_input && this.$refs.note_input.Focus()
			}
		},
	},

	render() {
		let task = this.task

		if (!task.id) {
			let uploadingFiles = false
			lo.each(this.task.files, (file) => {
				if (file._loading) {
					uploadingFiles = true
					return -1 // break loop
				}
			})
			return (
				<div
					style='display: flex; flex-direction: column; flex: 1; overflow: hidden; position: relative'
					class='task_create_form h_100'
				>
					<input type='file' ref='file_input' multiple style='display: none;' vOn:change={this.addLocalFiles} />
					{this.renderHeader()}
					<div class='task_detail_content_wrapper' vOn:click={this.onClickWrapper}>
						<div class='task_detail_content_inner'>
							<div class='task_detail_main_fields' ref='main_fields'>
								{this.renderCreated()}
								{this.renderTitle()}
								{this.renderNote()}
							</div>
						</div>
						<div class='task_detail_content_inner'>
							<div class='task_detail_main_fields'>
								{this.renderAssignee()}
								{this.renderDueAt()}
								{this.renderRemindMe()}
							</div>
						</div>
					</div>
					<div class='task_detail_footer'>
						<div class='task_detail_footer_inner' style='justify-content: flex-end;'>
							<button
								type='button'
								disabled={!this.task.title || this.loading || uploadingFiles}
								class='btn btn__success no-shrink'
								style='width: 100px'
								vOn:click={this.createTask}
							>
								{this.$t('create')}
							</button>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div
				style='display: flex; flex-direction: column; flex: 1; overflow: hidden; position: relative'
				class='h_100'
				ref='main_fields'
			>
				<input type='file' ref='file_input' multiple style='display: none;' vOn:change={this.addLocalFiles} />
				{this.renderHeader()}
				<div class='task_detail_content_wrapper' ref='history'>
					<div class='task_detail_content_inner'>
						<div class='task_detail_main_fields'>
							{this.renderCreated()}
							{this.renderTitle()}
							{this.renderNote()}
						</div>
						<div class='task_detail_main_fields' style='padding-top: 0'>
							{this.renderAssignee()}
							{this.renderDueAt()}
							{this.renderRemindMe()}
						</div>
						{this.renderActivities()}
					</div>
				</div>
				{this.task.id && (
					<div class='task_detail_footer'>
						<div class='task_detail_footer_inner'>
							<MessageEditor
								class='task_message_editor'
								placeholder={this.submittingcomment ? this.$t('sending') : this.$t('comment')}
								noTemplate
								initEvent={this.initcomment}
								submittable={false}
								connectorType={'subiz'}
								vOn:submit={this.onSubmitMsg}
								ref='comment_input'
								email={false}
								disabled={this.submittingcomment}
							/>
						</div>
					</div>
				)}
			</div>
		)
	},
}

function parseHyperlinkDeltaToString(delta) {
	delta = sb.parseJSON(delta) || {}
	let ops = delta.ops || []
	let result = []
	lo.each(ops, (op) => {
		if (lo.get(op, 'insert.hyperlink')) {
			let cid = lo.get(op, 'insert.hyperlink.conversation.id', '')
			let uid = lo.get(op, 'insert.hyperlink.user.id', '')
			result.push({
				insert: `https://app.subiz.com.vn/convo?cid=${cid}&uid=${uid}`,
			})
		} else {
			result.push(op)
		}
	})

	return JSON.stringify({ops: result})
}
