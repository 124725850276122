import ImageAttachments from './imageAttachments.js'
import LinkAttachments from './linkAttachments.js'
import AudioAttachments from './audioAttachments.js'
import PostbackAttachments from './postbackAttachments.js'
import Reaction from './reaction.js'
import FileAttachments from './fileAttachments.js'
import OrderAttachments from './orderAttachments.js'
import ButtonAttachments from './buttonAttachments.js'
import ProductAttachments from './productAttachments.js'
import FormAttachments from './formAttachments.js'
import FormSubmitAttachments from './formSubmitAttachments.js'
import GenericTemplateAttachments from './genericTemplateAttachments.js'
import VideoAttachments from './videoAttachments.js'
import TextMessage from './textMessage.js'
import InfoMessage from './infoMessage.js' // message by system like convo join, convo tag, ...
import InviteMessage from './inviteMessage.js'
import TaskMessage from './taskMessage.js'
import MessageReferralEvent from './fb_ad_message_referral_event.js'
import EmailMessageAttachment from './email_message_attachment.js'
import CallMessage from './call_text_message.js'
import UserDisplayName from '../../commons/user_display_name.js'

import ShadowDomRoot from './shadowDomRoot.js'

import {format} from 'date-fns'
import {getDateFnsLocale} from '../../languages.js'

var sb = require('@sb/util')
const lo = require('lodash')
import accounting from 'accounting'

const COMMON_DOMAIN = [
	'gmail.com',
	'outlook.com',
	'yahoo.com',
	'protonmail.com',
	'hotmail.com',
	'zoho.com',
	'aol.com',
	'mail.com',
	'gmx.com',
	'icloud.com',
]

import store from '@sb/store'

export default {
	name: 'message-event',
	props: [
		'updated',
		'followers',
		'seen_last_by',
		'receive', // ev.receive
		'mode', // ev.mode
		'show_time', // ev.show_time
		'eid',
		'cid',
		'is_private',
		'integration_connector_type',
		'integration_connector_id',
		'integration_name',
		'integration_logo_url',
		'readonly',
		'uid',
	],

	data() {
		return {
			showActionDropdown: false,
			showInternalActionDropdown: false,

			// emailMessage
			isShrinked: true,
			detectEmail: '',
			blockEmail: '',

			// email message headers modal
			isEmailInfoModalOpened: false,
			emailHeaders: {},
		}
	},

	async created() {
		store.onMessage(this, (convoid, _, ev) => {
			if (!ev) return
			if (ev.id !== this.eid) return
			this.$forceUpdate()
		})

		// fetch block email
		let ev = store.matchMessage2(this.uid, this.cid, this.eid) || {}
		let bytype = lo.get(ev, 'by.type')
		if (bytype === 'user' && this.isEmailMessage()) {
			await store.fetchBlockEmails()
			this.$forceUpdate()
		}

		// show full last email message in convo
		// this.showFullEmailMessage()

		store.onTask(this, (task) => {
			if (!task) return
			if (lo.includes(task.associated_users, this.uid)) {
				this.$forceUpdate()
			}
		})
	},

	render() {
		let ev = store.matchMessage2(this.uid, this.cid, this.eid) || {}
		let updated = this.updated
		switch (ev.type) {
			case 'content_viewed':
				return this.renderContentView(ev)
			case 'zns_sent':
				return this.renderZnsSent(ev)
			case 'zalo_call_consent_requested':
				return this.renderZaloConsentRequest(ev)
			case 'zalo_call_consent_replied':
				return this.renderZaloConsentReplied(ev)
			case 'conversation_left':
				return this.renderMessageChatLeft(ev)
			case 'conversation_invited':
				if (this.isEmailMessage()) return null
				return <InviteMessage ev={ev} mode={this.mode} followers={this.followers} />
			case 'message_sent':
				return this.renderMessageSent(ev)
			case 'message_referral':
				return <MessageReferralEvent ev={ev} />
			case 'conversation_state_updated':
			case 'bot_terminated':
			case 'conversation_rating_requested':
			case 'conversation_rated':
			case 'call_answered':
				if (this.isEmailMessage()) return null
				return <InfoMessage ev={ev} />
			case 'conversation_tagged':
			case 'conversation_untagged':
			case 'task_created':
			case 'task_updated':
				return <TaskMessage ev={ev} cid={this.cid} uid={this.uid} />
			case 'called':
				return this.renderCallEvent(ev)
			case 'payment_made':
				return this.renderPaymentMade(ev)
			case 'logged_in':
				return this.renderLoginEvent(ev)
		}
		return null
	},

	methods: {
		focus() {
			this.$refs.formAttachments.focus()
		},

		isEmailMessage() {
			return this.integration_connector_type === 'email'
		},

		async showFullEmailMessage() {
			if (!this.isEmailMessage()) return
			let events = store.listMessages(this.cid)
			events = lo.orderBy(events, ['created'], ['desc'])
			events = lo.filter(events, (ev) => ev.type === 'message_sent')
			let lastEv = lo.first(events) || {}
			if (lastEv.id === this.eid) this.isShrinked = false
		},

		renderMessageChatJoin(ev) {
			if (this.isEmailMessage()) return null
			let type = lo.get(ev, 'data.conversation.members.0.type')
			let id = lo.get(ev, 'data.conversation.members.0.id')
			if (type === 'user') {
				let user = store.getUser(this, id)
				let userName = <UserDisplayName user={user} />
				return (
					<div class='d-flex justify-content-center mt-5 ml-4 mr-4 text__muted' style='flex-wrap: wrap'>
						<Avatar class='mr-2' user={user} size='xs' /> {userName}&nbsp;{this.$t('join_the_conversation')}
					</div>
				)
			}

			if (type === 'bot' || type === 'agent') {
				let bot = store.matchAgent()[id] || {}
				let botname = sb.getAgentDisplayName(bot)
				return (
					<div class='d-flex justify-content-center mt-5 ml-4 mr-4 text__muted' style='flex-wrap: wrap'>
						<Avatar class='mr-2' agent={bot} size='xs' /> {botname}&nbsp;{this.$t('join_the_conversation')}
					</div>
				)
			}

			return null
		},

		renderMessageChatLeft(ev) {
			if (this.isEmailMessage()) return null
			let type = lo.get(ev, 'data.conversation.members.0.type', 'agent')
			let id = lo.get(ev, 'data.conversation.members.0.id')
			if (type === 'user') {
				let user = store.getUser(this, id)
				let userName = <UserDisplayName user={user} />
				return (
					<div class='d-flex justify-content-center mt-5 ml-4 mr-4 text__muted' style='flex-wrap: wrap'>
						<Avatar class='mr-2' user={user} size='xs' /> {userName}&nbsp;{this.$t('left_the_conversation')}
					</div>
				)
			}
			if (type === 'bot' || type === 'agent') {
				let bot = store.matchAgent()[id] || {}
				let botname = sb.getAgentDisplayName(bot)
				return (
					<div class='d-flex justify-content-center mt-5 ml-4 mr-4 text__muted' style='flex-wrap: wrap'>
						<Avatar class='mr-2' agent={bot} size='xs' /> {botname}&nbsp;{this.$t('left_the_conversation')}
					</div>
				)
			}
			return null
		},

		renderAvatar(ev) {
			let bytype = lo.get(ev, 'by.type')
			if (bytype === 'user') return this.renderUserAvatar(ev)
			return this.renderAgentAvatar(ev)
		},

		renderAgentAvatar(ev) {
			let bytype = lo.get(ev, 'by.type')
			let by = ev.by || {}
			let byid = lo.get(ev, 'by.display_id', lo.get(ev, 'by.id'))

			if (bytype === 'user') return null
			var senderName = 'System'
			let $avatar = <Avatar by={by} integration={{logo_url: this.integration_logo_url}} size='sm' />
			if (bytype === 'system' || bytype === 'subiz') {
				let tooltip = ''
				if (store.matchCampaign()[byid]) {
					tooltip = lo.get(store.matchCampaign(), [byid, 'name'])
					tooltip = `${this.$t('camapign')}: ${tooltip}`
				}
				$avatar = (
					<img2
						src={lo.get(store.me(), 'account.logo_url')}
						style='width: 26px; height: 26px; border-radius: 50%'
						v-tooltip={tooltip}
					/>
				)
			}
			if (bytype === 'dummy') {
				// find the integration
				senderName = this.integration_name
			}

			let $app = null
			let bybot = lo.get(store.matchAgent()[byid], 'type') == 'bot'
			let clientid = lo.get(ev, 'by.client_id')
			if (
				bytype === 'system' ||
				bytype === 'subiz' ||
				bytype === 'dummy' ||
				bytype === 'workflow' ||
				bybot ||
				clientid == 'automation' ||
				clientid == 'campaign'
			)
				$app = (
					<span
						v-tooltip={this.$t('app_message')}
						class='badge badge__secondary text__bold mr-2'
						style={{
							fontSize: '10px',
							padding: '3px 5px',
							lineHeight: 1,
						}}
					>
						APP
					</span>
				)

			if (bytype === 'workflow') {
				let automation = store.matchConvoAutomation()[byid] || {}
				senderName = automation.name || 'Workflow'
			}

			// should replace with subiz logo
			if (bytype !== 'system' && bytype !== 'dummy' && bytype !== 'workflow') {
				let agent = store.matchAgent()[byid]
				senderName = sb.getAgentDisplayName(agent, this.$t('deleted_agent'))
			}

			let $em = null
			let from = sb.getMsgField(ev, 'from')
			if (from) {
				$em = (
					<fragment>
						<strong>{from}</strong> <span>&nbsp;·&nbsp;</span>
					</fragment>
				)
			}

			let $name = (
				<div class='text_message_sender__name'>
					{$em}
					{$app}
					<span class='text__truncate' title={senderName}>
						{senderName}
					</span>
				</div>
			)

			let created = sb.getMsgField(ev, 'source_created') || ev.created
			return (
				<div class='text_message_sender text_message_sender__agent'>
					<div class='text_message_sender__avatar'>{$avatar}</div>
					{$name}
					<span class='text__muted ml-2 mr-2'>·</span>
					<Time time={created} style='font-size: 14px; position: relative; top: -1px' class='text__muted' />
				</div>
			)
		},

		renderUserAvatar(ev) {
			let byid = lo.get(ev, 'by.display_id', lo.get(ev, 'by.id'))
			//let user = store.getUser(this, byid)
			let user = store.matchUser(byid, true)
			let by = ev.by
			let senderName = <UserDisplayName user={user} />

			let $em = null
			let from = sb.getMsgField(ev, 'from')
			if (from) {
				$em = (
					<fragment>
						<span>&nbsp;·&nbsp;</span>
						<strong>{from}</strong>
					</fragment>
				)
			}

			let $name = (
				<div class='text_message_sender__name'>
					<span class='text__truncate'>{senderName}</span>
					{$em}
				</div>
			)
			let created = sb.getMsgField(ev, 'source_created') || ev.created
			let $verified = null
			if (user && user.channel == 'account') {
				$verified = (
					<img2
						src={require('../../assets/img/user_verified.svg')}
						v-tooltip={this.$t('verified')}
						width='16'
						height='16'
						style='margin-bottom: 4px; margin-left: 3px'
					/>
				)
			}

			return (
				<div class='text_message_sender text_message_sender__user'>
					<div class='text_message_sender__avatar'>
						<Avatar by={by} integration={{logo_url: this.integration_logo_url}} size='sm' is_profile />
					</div>
					{$name} {$verified}
					<span class='text__muted'>&nbsp;·&nbsp;</span>
					<Time time={created} style='font-size: 14px; position: relative; top: -1px' class='text__muted' />
				</div>
			)
		},

		renderInternalAction(ev) {
			if (this.readonly) return null
			if (!this.is_private) return null
			let byId = lo.get(ev, 'by.id')
			let $actions = (
				<div class='message_internal_actions' key={this.eid + 'actions'}>
					<Icon name='dots' size='1x' class='message_internal_action' vOn:click={this.toogleInternalActionDropdown} />
				</div>
			)

			let $bookmarkaction = (
				<bookmark-icon
					v-tooltip={this.$t('bookmark')}
					size='1x'
					class='message_internal_action'
					vOn:click={(_) => this.$emit('bookmark', ev)}
				/>
			)
			if (lo.get(ev, 'data.message.pinned')) {
				$bookmarkaction = (
					<bookmark-icon
						v-tooltip={this.$t('unbookmark')}
						size='1x'
						style='fill: #ffedb7; color: #dfcd97'
						class='message_internal_action'
						vOn:click={(_) => this.$emit('bookmark', ev)}
					/>
				)
			}

			if (this.showInternalActionDropdown) {
				let $like = (
					<Icon name='thumb-up' size='1x' class='message_internal_action' vOn:click={(_) => this.pong('like')} />
				)
				let $sad = (
					<Icon name='mood-confuzed' size='1x' class='message_internal_action' vOn:click={(_) => this.pong('sad')} />
				)

				// if already like
				let pongs = lo.get(ev, 'data.message.pongs', [])
				let alreadylike = false
				let alreadysad = false
				lo.map(pongs, (reaction) => {
					if (reaction.member_id != store.me().id) return
					if (reaction.type == 'like') alreadylike = true
					if (reaction.type == 'sad') alreadysad = true
				})

				if (alreadylike) $like = null
				if (alreadysad) $sad = null

				$actions = (
					<div
						class='message_internal_actions'
						v-clickaway={this.hideInternalActionDropdown}
						key={this.eid + 'actions'}
					>
						<Icon name='dots' size='1x' class='message_internal_action' vOn:click={this.toogleInternalActionDropdown} />
						{$bookmarkaction}
						<Icon
							name='corner-up-left'
							v-tooltip={this.$t('reply')}
							size='16'
							class='message_internal_action'
							vOn:click={(_) => this.$emit('quote', ev)}
						/>
						{$like}
						{$sad}
					</div>
				)
			}

			return $actions
		},

		renderEmailMessage(ev) {
			let bytype = lo.get(ev, 'by.type')
			if (bytype !== 'user') bytype = 'agent'
			let $avatar = null
			let senderName = ''

			let fromEmail = ''
			let to = ''

			if (bytype === 'user') {
				let userid = lo.get(ev, 'by.id')
				let user = store.matchUser(userid) || {}
				$avatar = <Avatar user={user} size='40' />
				senderName = <UserDisplayName user={user} />

				let from = sb.getMsgField(ev, 'from') || ''
				if (from.indexOf('>') > -1 && from.indexOf('<') > -1) {
					let isAppended = false
					for (let i = 0; i < from.length; i++) {
						let c = from.charAt(i)
						if (c === '<') {
							isAppended = true
							continue
						}
						if (c === '>') {
							isAppended = false
						}
						if (isAppended) fromEmail += c
					}
				} else {
					fromEmail = from
				}
				to = sb.getMsgField(ev, 'delivered-to')
				if (!to) {
					to = sb.getMsgField(ev, 'to') || {}
					if (lo.size(to) === 1) to = to[0]
				}
			} else if (bytype === 'agent') {
				let agid = lo.get(ev, 'by.id')
				let agent = store.matchAgent()[agid]
				$avatar = <Avatar agent={{}} size='40' nodot />
				senderName = sb.getMsgField(ev, 'from')
				let businessEmail = store.matchBusinessEmailAddress()[senderName]
				if (businessEmail && businessEmail.fullname) senderName = businessEmail.fullname
				if (agent) {
					$avatar = <Avatar agent={agent} size='40' nodot />
					senderName = agent.fullname
				}

				fromEmail = sb.getMsgField(ev, 'from')
				to = sb.getMsgField(ev, 'to')
				to = lo.first(to)
			}

			let $attachments = null
			let attachments = lo.get(ev, 'data.message.attachments', [])
			if (lo.size(attachments)) {
				$attachments = lo.map(attachments, (att) => {
					return <EmailMessageAttachment attachment={att} />
				})
				$attachments = <div class='email_message_card_footer'>{$attachments}</div>
			}

			if (this.isShrinked) {
				//$attachments = null
			}
			let $attIcon = null
			if (this.isShrinked && lo.size(attachments)) {
				//$attIcon = <paperclip-icon size='15' class='ml-2' />
			}

			let byType = lo.get(ev, 'by.type')
			let $extra = null
			let nOpen = 0
			let receive = false
			if (byType !== 'user') {
				lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
					if (pong.type === 'open') nOpen++
					if (pong.type === 'receive') receive = true
				})

				if (!ev.sending && ev.id && !ev.error) {
					$extra = <small class='text__success mr-2'>{this.$t('sent')}</small>
				}

				if (receive) {
					$extra = <small class='text__success mr-2'>{this.$t('email_received')}</small>
				}

				if (nOpen >= 1) {
					$extra = (
						<small class='text__success mr-2' v-tooltip={this.$t('user_open_email', {0: nOpen})}>
							{this.$t('email_opened')}
						</small>
					)
				}

				if (ev._sending) {
					$extra = <small class='text__muted mr-2'>{this.$t('sending')}...</small>
				}

				if (ev._error) {
					$extra = (
						<small class='text__danger mr-2' v-tooltip={ev._error}>
							{this.$t('send_error')}
						</small>
					)
				}
			}

			let $more = (
				<button type='button' class='btn btn__sm btn__light' disabled>
					<Icon name='dots' size='1x' />
				</button>
			)
			if (byType === 'user') {
				let domain = lo.trim(fromEmail).split('@')
				let items = []
				if (lo.get(store.matchBlockEmail(), lo.trim(fromEmail))) {
					items = [
						{
							id: 'unblock_email',
							label: this.$t('unblock_email'),
							icon: <icon name='lock-open' size='15' class='text__muted' />,
						},
					]
				} else {
					items = [
						{
							id: 'block_email',
							label: this.$t('block_email'),
							icon: <icon name='circle-off' size='15' class='text__muted' />,
						},
					]
				}
				if (lo.get(store.matchBlockEmail(), domain[1])) {
					items = [
						{
							id: 'unblock_domain',
							label: this.$t('unblock_domain', [domain[1]]),
							icon: <icon name='circle-off' size='15' class='text__muted' />,
						},
					]
				}
				items.push({
					id: 'view_email_header',
					label: this.$t('email_info'),
					icon: <Icon name='info-circle' size='15' class='text__muted' />,
				})
				let header = sb.getMsgField(ev, 'headers')
				$more = (
					<Dropdown
						mode='custom'
						items={items}
						right
						dropdown_width={lo.get(store.matchBlockEmail(), domain[1]) ? 250 : 200}
						vOn:select={(item) => this.onSelectEmailAction(item, fromEmail, domain[1], header)}
					>
						<button type='button' class='btn btn__sm btn__light'>
							<more-horizontal-icon size='1x' />
						</button>
					</Dropdown>
				)
			}

			let $app = null
			let clientid = lo.get(ev, 'by.client_id')
			let bybot = lo.get(store.matchAgent()[lo.get(ev, 'by.id')], 'type') == 'bot'
			if (
				bytype === 'subiz' ||
				bytype === 'system' ||
				bytype === 'dummy' ||
				bytype === 'workflow' ||
				bybot ||
				clientid == 'automation' ||
				clientid == 'campaign'
			) {
				$app = (
					<span class='ml-2 badge badge__secondary text__xxs text__semibold no-shrink' style='align-self: center;'>
						APP
					</span>
				)
			}

			let ccs = sb.getMsgField(ev, 'ccs') || sb.getMsgField(ev, 'cc')
			ccs = lo.filter(ccs, (cc) => cc.trim())
			let $cc = null
			if (lo.size(ccs) > 0) {
				let cc = ccs[0]
				if (lo.size(ccs) > 1) {
					for (let i = 1; i < lo.size(ccs); i++) cc = cc + ', ' + ccs[i]
				}
				$cc = (
					<small class='text__muted text__truncate' style='position: relative; top: -2px;' v-tooltip={cc}>
						cc: {cc}
					</small>
				)
			}

			let $reply_btn
			let availabelEmail = lo.filter(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
			)
			if (!lo.size(availabelEmail)) {
				let clsDetectEmail = `detect_user_info_from_message__container detect_user_info_from_message__container__user`
				if (this.detectEmail === 'email') {
					clsDetectEmail +=
						' detect_user_info_from_message__container__visible detect_user_info_from_message__container__' +
						(this.popupgoup ? 'up' : 'down')
				}
				$reply_btn = (
					<a
						class='detect_user_info_from_message'
						style='position: relative'
						vOn:mouseover={(e) => this.onDetectInfoMouseOver('email', e)}
						vOn:mouseleave={(_) => this.onDetectInfoMouseLeave()}
					>
						<div class='user_action_icon_wrapper' style='margin-right: 0; position: relative;'>
							<button disabled type='button' class='btn btn__sm btn__light mr-3'>
								<Icon name='corner-up-left' size='16' class='mr-2' style='position: relative: top: -2px' />
								{this.$t('reply')}
							</button>
							<div class='webphone_disconnected' style='z-index: 0; transform: rotate(0deg); top: -8px; right: 0'>
								!
							</div>
						</div>
						<div class={clsDetectEmail} style='left: -90px; background: rgb(54, 60, 66)'>
							<p style='line-height: 1;'>{this.$t('error_no_business_email_address_short')}</p>
							<p style='line-height: 1;'>{this.$t('solution_no_business_email_address')}</p>
							<div class='d-flex justify-content-end'>
								<button class='btn btn__sm btn__primary'>
									<router-link style='color: white;' to={{name: 'settings.email'}}>
										{this.$t('set_up_now')}
									</router-link>
								</button>
							</div>
						</div>
					</a>
				)
			} else {
				let fields = [
					{
						value: JSON.stringify([bytype === 'user' ? fromEmail : to]),
						key: 'to',
					},
				]
				let ccs = []
				if (bytype === 'user') {
					ccs = sb.getMsgField(ev, 'cc')
					ccs = lo.map(ccs, extractEmailAddressFromCC)
				} else {
					ccs = sb.getMsgField(ev, 'ccs')
				}
				if (lo.size(ccs)) {
					fields.push({
						key: 'ccs',
						value: JSON.stringify(ccs),
					})
				}
				let replyEmailMsg = {
					format: 'html',
					text: '<p></p>',
					fields,
				}
				$reply_btn = (
					<button
						type='button'
						class='btn btn__sm btn__light mr-3'
						vOn:click={() => this.$emit('reply', replyEmailMsg)}
					>
						<Icon name='corner-up-left' size='16' class='mr-2' style='position: relative: top: -2px' />
						{this.$t('reply')}
					</button>
				)
			}

			return (
				<div>
					<div class='email_message_card'>
						<div class='email_message_card_header'>
							<div class='d-flex mr-5' style='overflow: hidden'>
								{$avatar}
								<div class='ml-3' style='overflow: hidden'>
									<div style='overflow: hidden' class='d-flex align-items-center'>
										<span class='text__semibold no-shrink mr-2'>{senderName} </span>
										<small class='text__muted text__truncate' title={fromEmail}>
											{'<'}
											{fromEmail}
											{'>'}
										</small>
										{$app}
									</div>
									<small class='text__muted text__truncate' style='position: relative; top: -2px;'>
										{this.$t('to').toLowerCase()}: {to}
									</small>
									{$cc}
								</div>
							</div>
							<div class='ml-auto d-flex align-items-center no-shrink'>
								{$attIcon}
								<div class='ml-3 mr-3' style='background: #dedede; height: 24px; width: 1px;' />
								{$reply_btn}
								{$more}
							</div>
						</div>
						<div class='email_message_card_content'>
							<div class={{'mb-3': true, text__lg: true, text__semibold: sb.getMsgField(ev, 'subject')}}>
								{sb.getMsgField(ev, 'subject') || <em>this.$t('no_subject')</em>}
							</div>
							<ShadowDomRoot
								html={lo.get(ev, 'data.message.text')}
								attachments={lo.get(ev, 'data.message.attachments')}
							/>
						</div>
						{$attachments}
						<BlockEmailModal
							email={this.blockEmail}
							cid={this.cid}
							vOn:close={async () => {
								this.blockEmail = ''
								await store.fetchBlockEmails()
								this.$forceUpdate()
							}}
						/>
						<EmailHeaderModal
							open={this.isEmailInfoModalOpened}
							headers={this.emailHeaders}
							vOn:close={() => (this.isEmailInfoModalOpened = false)}
						/>
					</div>
					<div class='d-flex' style='margin: 10px 20px 10px; justify-content: flex-end;'>
						{$extra}
						<small class='text__muted'>
							{format(new Date(ev.created), 'HH:mm, d MMM, yyyy', {locale: getDateFnsLocale()})}
						</small>
					</div>
				</div>
			)
		},

		onDetectInfoMouseOver(evId, e) {
			if (document.body.scrollHeight - e.clientY < 200) {
				this.popupgoup = true
			} else {
				this.popupgoup = false
			}
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectEmail = evId
			}, 500)
		},

		onDetectInfoMouseLeave() {
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectEmail = ''
			}, 250)
		},

		async onSelectEmailAction(item, email, domain, headers) {
			if (item.id === 'view_email_header') {
				this.isEmailInfoModalOpened = true
				this.emailHeaders = headers
				return
			}
			if (item.id === 'block_email') {
				this.blockEmail = email
			}
			if (item.id === 'unblock_email') {
				let {error} = await store.removeBlockEmail(email)
				if (error) {
					this.$showError(error)
					return
				}
				await store.fetchBlockEmails()
				this.$forceUpdate()
			}
			if (item.id === 'unblock_domain') {
				let {error} = await store.removeBlockEmail(domain)
				if (error) {
					this.$showError(error)
					return
				}
				await store.fetchBlockEmails()
				this.$forceUpdate()
			}
		},

		renderZnsSent(ev) {
			let bytype = 'agent'
			let cls = 'message_container message_container__' + bytype
			let wrappercls = 'message_container_wrapper'
			let notecls = `message_event_help_text ${bytype}`

			let agentid = lo.get(ev, 'by.id')
			let agent = store.matchAgent()[agentid] || {id: agentid}

			let templateId = lo.get(ev, 'data.message.zns_template_id')
			let $sender = (
				<Fragment>
					<div class='text_message_sender__avatar'>
						<Avatar agent={agent} size='xs' />
					</div>
					<div class='text_message_sender__name' style='font-size: 14px'>
						<span class='text__truncate mr-2' title={sb.getAgentDisplayName(agent)}>
							{sb.getAgentDisplayName(agent)}
						</span>
						<span class='text__muted no-shrink'>{this.$t('sent_zns_with_template_id', [templateId])}</span>
					</div>
				</Fragment>
			)

			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px; ' class='text__muted no-shrink' />
					</div>
					<div class={cls}>
						<div class={notecls}>{this.$t('only_you_can_see_this_message')}</div>
					</div>
				</div>
			)
		},

		renderZaloConsentRequest(ev) {
			let bytype = 'agent'
			let cls = 'message_container message_container__' + bytype
			let wrappercls = 'message_container_wrapper'
			let notecls = `message_event_help_text ${bytype}`

			let agentid = lo.get(ev, 'by.id')
			let agent = store.matchAgent()[agentid] || {id: agentid}

			let $sender = (
				<Fragment>
					<div class='text_message_sender__avatar'>
						<Avatar agent={agent} size='xs' />
					</div>
					<div class='text_message_sender__name' style='font-size: 14px'>
						<span class='text__truncate mr-2' title={sb.getAgentDisplayName(agent)}>
							{sb.getAgentDisplayName(agent)}
						</span>
						<span class='text__muted no-shrink'>{this.$t('sent_zalo_call_request').toLowerCase()}</span>
					</div>
				</Fragment>
			)

			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px;' class='text__muted no-shrink' />
					</div>
					<div class={cls}>
						<div class={notecls}>{this.$t('only_you_can_see_this_message')}</div>
					</div>
				</div>
			)
		},

		renderZaloConsentReplied(ev) {
			let bytype = 'user'
			let cls = 'message_container message_container__' + bytype
			let wrappercls = 'message_container_wrapper'
			let notecls = `message_event_help_text ${bytype}`

			let userid = lo.get(ev, 'by.id')
			let user = store.matchUser(userid, true) || {}

			let text = this.$t('allow')
			let msgtext = lo.get(ev, 'data.message.text', '')
			if (!msgtext.endsWith('ALLOW')) {
				text = this.$t('decline')
			}

			let $sender = (
				<Fragment>
					<div class='text_message_sender__avatar'>
						<Avatar user={user} size='xs' />
					</div>
					<div class='text_message_sender__name' style='font-size: 14px'>
						<span class='text__truncate mr-2'>
							<UserDisplayName user={user} />
						</span>
						<span class='text__muted no-shrink'>
							{text.toUpperCase()} {this.$t('zalo_call_receive').toLowerCase()}
						</span>
					</div>
				</Fragment>
			)

			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px;' class='text__muted no-shrink' />
					</div>
				</div>
			)
		},

		renderContentView(ev) {
			if (ev._display === false) return null
			let bytype = 'user'

			var $sender = null
			$sender = <div class={'message_container message_container__' + bytype}>{this.renderAvatar(ev)}</div>

			let created = sb.getMsgField(ev, 'source_created') || ev.created
			var $time = null
			if (ev.show_time && !this.isEmailMessage()) {
				$time = (
					<div class='convo_event__time'>
						<Time time={created} short />
					</div>
				)
			}

			let cls = 'message_container message_container__' + bytype
			let wrappercls = 'message_container_wrapper'

			let uid = lo.get(ev, 'by.id')
			let user = store.matchUser(uid, true) || {id: uid}

			let content = lo.get(ev, 'data.product', {})
			let pageTitle = content.title || content.name || content.url
			let pageUrl = content.url

			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div class={cls}>
						<div class='text_message_sender text_message_sender__user' style='overflow: hidden; align-items: center'>
							<div class='text_message_sender__avatar no-shrink'>
								<Avatar user={user} size='xs' />
							</div>
							<div class='d-flex align-items-center' style='font-size: 14px; overflow: hidden;'>
								<span class='no-shrink mr-2 text__muted'>{this.$t('user_view_page')}</span>
								<a
									href={pageUrl}
									target='_blank'
									class='text__truncate message_content_viewed_link'
									style='display: block'
									title={pageTitle}
								>
									{pageTitle}
								</a>
								<div style='flex-shrink: 0' class='text__muted ml-1'>
									{ev._grouped == 2 && this.$t('and') + '1 ' + this.$t('other_page')}
									{ev._grouped > 2 && this.$t('and') + ev._grouped + ' ' + this.$t('other_pages')}
								</div>
								<div style='flex-shrink: 0' class='text__muted mr-3'>
									{' '}
									· <Time time={created} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},

		renderMessageSent(ev) {
			let bytype = lo.get(ev, 'by.type')
			if (bytype !== 'user') bytype = 'agent' // fix bug somehow event_by bot
			if (sb.getMsgField(ev, 'facebook_comment_type') === 'command') return null

			var $sender = null
			if (this.isEmailMessage()) return this.renderEmailMessage(ev)
			if (!this.isEmailMessage() && lo.get(ev, 'touchpoint.channel') === 'email') return this.renderEmailNotifyEvent(ev)
			if (this.cid !== lo.get(ev, 'data.message.conversation_id')) return this.renderCrossChannelMesssage(ev)

			if (this.mode === 'lead' || this.isEmailMessage()) {
				$sender = <div class={'message_container message_container__' + bytype}>{this.renderAvatar(ev)}</div>
			}

			let created = sb.getMsgField(ev, 'source_created') || ev.created
			var $time = null
			if (ev.show_time && !this.isEmailMessage()) {
				$time = (
					<div class='convo_event__time'>
						<Time time={created} short />
					</div>
				)
			}

			let byme = lo.get(ev, 'by.id') === store.me().id

			let $sending = null
			let $lastseen = null
			if (lo.get(ev, 'by.type') !== 'user') {
				if (ev._sending) {
					$sending = (
						<Icon
							name='circle'
							class='message_state message_state__sending'
							size='12'
							stroke-width='3'
							v-tooltip={this.$t('sending')}
						/>
					)
				} else if (this.receive === true) {
					$sending = (
						<Icon
							name='check'
							class='message_state message_state__received'
							size='10'
							stroke-width='4'
							v-tooltip={this.$t('msg_received')}
						/>
					)
				} else if (this.receive === false) {
					$sending = (
						<Icon
							name='check'
							class='message_state message_state__sent'
							size='10'
							stroke-width='4'
							v-tooltip={this.$t('sent')}
						/>
					)
				}
			}

			if (this.seen_last_by) {
				let user = store.getUser(this, this.seen_last_by)
				$sending = <Avatar class='message_state message_state__seen' user={user} is_profile size='xxs' />
			}

			if (ev._error) {
				$sending = (
					<Icon
						name='alert-triangle'
						class='message_state message_state__error'
						stroke-width='3'
						v-tooltip={this.$t(ev._error)}
					/>
				)
				$sending = null
			}

			let cls = 'message_container message_container__' + bytype
			if (ev._error) cls += ' message_container__error'

			// render quote
			let $quote = null
			let attachments = lo.get(ev, 'data.message.attachments', [])
			let quote = lo.find(attachments, (att) => att.type === 'quote')
			if (quote) {
				//lo.set(quote, 'quote.data.message.attachments', [
				//{
				//type: 'file',
				//mimetype: 'image/jpeg',
				//size: 120_090,
				//url: 'https://vcdn.subiz-cdn.com/file/firhrlmuhnqfymxisyzt-ezgif-6-33cf206aa89c.jpg',
				//},
				//])

				let isInternalChat = (this.cid || '').startsWith('csdirect')
				let quoteById = lo.get(quote, 'quote.by.id')
				let quoteByAgent = lo.get(store.matchAgent(), quoteById, {})

				let quotestyle = 'margin-left: 50px; margin-bottom: -20px; padding-bottom: 20px;'
				if (isInternalChat) {
					quotestyle =
						'color: #444; background: #f0f0f0; border-radius: 8px; padding-bottom: 10px; margin-bottom: -12px'
				}
				$quote = (
					<Fragment>
						<div class={`message_quote_sender ${isInternalChat ? 'internal-chat' : ''}`}>
							<Icon name='corner-up-left' size='16' class='mr-2' style='margin-top: 2px' />
							{this.$t('reply')}&nbsp;
							{quoteByAgent.fullname}
						</div>
						<div class={cls} style='font-size: 12px;'>
							<TextMessage is_quote ev={quote.quote} style={quotestyle} />
						</div>
					</Fragment>
				)
			}
			let $pinned = null
			let textcls = ''
			if (!this.readonly && lo.get(ev, 'data.message.pinned')) {
				textcls += ' message_text__pinned '
				let pinnedby = lo.get(ev, 'data.message.pinned_by')
				let ag = store.matchAgent()[pinnedby]
				$pinned = (
					<div class='pinned_desc'>
						<bookmark-icon size='1x' /> {this.$t('bookmark')}
						{ag ? ' ' + this.$t('by') + ' ' + sb.getAgentDisplayName(ag) : null}
					</div>
				)
			}

			let connectorType = this.integration_connector_type
			let textev = ev
			if (connectorType === 'facebook_comment' && !lo.get(ev, 'data.message.text')) {
				textev = lo.cloneDeep(textev)

				// workaround for message without text cannot render actions
				lo.set(textev, 'data.message.text', 'Đã bình luận')
			}

			let wrappercls = 'message_container_wrapper'
			if (ev._error) wrappercls += ' error'

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})

			let $errmsg = null
			if (ev._error && lo.get(ev, 'by.type') !== 'user') {
				$errmsg = (
					<div
						class='text__danger text__right mt-1'
						style='max-width: 300px; margin-right: 50px; font-size: 12px;align-self: flex-end; padding-bottom: 5px; word-wrap: break-word'
					>
						{this.$t(ev._error)}
					</div>
				)
				if (this.integration_connector_type === 'facebook') {
					let errmsg = ev._error
					if (typeof errmsg === 'string' && errmsg.startsWith('Error validating access token')) {
						$errmsg = (
							<div
								class='text__danger text__right mt-1'
								style='max-width: 300px; margin-right: 50px; font-size: 12px;align-self: flex-end; padding-bottom: 5px; word-wrap: break-word'
							>
								{'Token hết hạn, vui lòng '}
								<router-link class='link link__danger text__underline' to={{name: 'settings.messenger'}}>
									tích hợp lại Fanpage.
								</router-link>
							</div>
						)
					}
				}

				if (this.integration_connector_type == 'zalo') {
					let tryerr = sb.parseJSON(ev._error) || {}
					if (tryerr.error == -224) {
						$errmsg = (
							<div
								class='text__danger text__right mt-1'
								style='max-width: 300px; margin-right: 50px; font-size: 12px;align-self: flex-end; padding-bottom: 5px; word-wrap: break-word'
							>
								Không thể gửi tin. Bạn cần trả phí cho Zalo để tiếp tục gửi.{' '}
								<a href='https://subiz.com.vn/blog/thong-bao-loi-gui-tin-nhan-tren-kenh-zalo-oa.html' target='_blank'>
									Chi tiết
								</a>
							</div>
						)
					}
					if (tryerr.error == -216) {
						$errmsg = (
							<div
								class='text__danger text__right mt-1'
								style='max-width: 300px; margin-right: 50px; font-size: 12px;align-self: flex-end; padding-bottom: 5px; word-wrap: break-word'
							>
								{'Token hết hạn, vui lòng '}
								<router-link class='link link__danger text__underline' to={{name: 'settings.zalo'}}>
									tích hợp lại Zalo.
								</router-link>
							</div>
						)
					}
					if (tryerr.error === -201 && tryerr.message === 'token is empty!') {
						let hasVideo = lo.find(lo.get(ev, 'data.message.attachments'), (att) =>
							(att.mimetype || '').startsWith('video'),
						)
						if (hasVideo) {
							$errmsg = (
								<div
									class='text__danger text__right mt-1'
									style='max-width: 300px; margin-right: 50px; font-size: 12px;align-self: flex-end; padding-bottom: 5px; word-wrap: break-word'
								>
									{'Zalo không hỗ trợ gửi hình ảnh. Bạn có thể thử ảnh GIF thay thế'}
								</div>
							)
						}
					}
					if (tryerr.error === -201 && tryerr.message === 'Missing template_type params') {
						let hasQuickReply = lo.find(lo.get(ev, 'data.message.attachments'), (att) => att.type === 'quick_replies')
						if (hasQuickReply) {
							$errmsg = (
								<div
									class='text__danger text__right mt-1'
									style='max-width: 300px; margin-right: 50px; font-size: 12px;align-self: flex-end; padding-bottom: 5px; word-wrap: break-word'
								>
									{'Bot gửi câu trả lời gợi ý bị lỗi'}
								</div>
							)
						}
					}
				}
			}

			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					{$time}
					{$sender}
					{$pinned}
					{$quote}
					<div class={cls} title={format(new Date(ev.created), 'HH:mm:ss, d MMM, yyyy', {locale: getDateFnsLocale()})}>
						<TextMessage ev={textev} class={textcls} />
						{this.renderAction(ev)}
						{this.renderInternalAction(ev)}
					</div>
					{!isDelete && <ImageAttachments ev={ev} />}
					{!isDelete && <LinkAttachments ev={ev} />}
					{!isDelete && <PostbackAttachments ev={ev} />}
					{!isDelete && <AudioAttachments ev={ev} />}
					{!isDelete && <VideoAttachments ev={ev} />}
					{!isDelete && <FileAttachments ev={ev} />}
					{!isDelete && <OrderAttachments ev={ev} />}
					{!isDelete && <ButtonAttachments ev={ev} />}
					{!isDelete && <ProductAttachments ev={ev} />}
					{!isDelete && <FormAttachments vOn:submit={this.onFormSubmit} ref='formAttachments' ev={ev} />}
					{!isDelete && <FormSubmitAttachments ev={ev} />}
					{!isDelete && <GenericTemplateAttachments ev={ev} />}
					<div class={'message_container message_container__' + bytype}>
						<Reaction ev={ev} />
					</div>
					{$sending}
					{$errmsg}
				</div>
			)
		},

		renderCrossChannelMesssage(ev) {
			let bytype = 'user'

			let wrappercls = 'message_container_wrapper'
			let cls = 'message_container message_container__' + bytype
			let cid = lo.get(ev, 'data.message.conversation_id')
			let $sender = null
			let userid = ''

			let action = this.$t('sent_an_message').toLowerCase()
			let channel = lo.get(ev, 'touchpoint.channel')
			let source = lo.get(ev, 'touchpoint.source')
			let logo_url = ''
			let intename = ''
			let intelink = ''
			if (channel === 'facebook_comment') {
				action = this.$t('comment_on_fanpage').toLowerCase()
				let inte = store.matchIntegration()[`${store.me().account_id}.${source}.fabikon`] || {}
				logo_url = inte.logo_url
				intename = inte.name
				intelink = 'https://facebook.com/' + source
			} else if (channel === 'instagram_comment') {
				action = this.$t('comment_on_instagram_page').toLowerCase()
				let inte = store.matchIntegration()[`${store.me().account_id}.instagram_${source}.fabikon`] || {}
				logo_url = inte.logo_url
				intename = inte.name
				intelink = 'https://ww.instagram.com/' + inte.username
			}

			userid = lo.get(ev, 'by.id')
			let user = store.matchUser(userid, true) || {}
			$sender = (
				<Fragment>
					<div class='text_message_sender__avatar'>
						<Avatar user={user} size='xs' />
					</div>
					<div class='text_message_sender__name clickable' style='font-size: 14px'>
						<span class='text__truncate mr-2'>
							<UserDisplayName user={user} />
						</span>
						<span class='text__muted no-shrink'>{action}</span>
					</div>
				</Fragment>
			)

			let msgcls = `message_cross_channel_box message_cross_channel_box__${bytype}`
			let notecls = `message_event_help_text ${bytype}`
			let permalnk = sb.getMsgField(ev, 'facebook_comment_permalink_url')
			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px;' class='text__muted no-shrink' />
					</div>
					<div class={cls} title={format(new Date(ev.created), 'HH:mm:ss, d MMM, yyyy', {locale: getDateFnsLocale()})}>
						<div
							class={msgcls}
							vOn:click={() => this.$root.$emit('showQuickview', {convo_id: cid, user_id: userid || '-'})}
						>
							<div class='facebook_comment_message_wrapper first w_100'>
								<Avatar
									user={user}
									size='xxs'
									class='no-shrink'
									style='margin-right: 8px; position: relative; top: 8px;'
								/>
								<div class='facebook_comment_message user flex__1'>
									<div>
										<span class='text__semibold'>
											<UserDisplayName user={user} />
										</span>
										&nbsp;&nbsp;
										<Time ago time={ev.created} class='text__muted text__xs' />
									</div>
									<div>{lo.get(ev, 'data.message.text')}</div>
								</div>
							</div>
						</div>
						<a
							href={permalnk}
							target='_blank'
							class='link link__secondary ml-3 text__muted'
							style='position: relative; top: 2px; align-self: flex-start'
						>
							<Icon name='external-link' size='18' />
						</a>
					</div>
					<div class={cls}>
						<div class={notecls}>{this.$t('only_you_can_see_this_message')}</div>
					</div>
				</div>
			)
		},

		renderEmailNotifyEvent(ev) {
			let bytype = lo.get(ev, 'by.type')
			if (bytype !== 'user') bytype = 'agent'

			let wrappercls = 'message_container_wrapper'
			let cls = 'message_container message_container__' + bytype
			let cid = lo.get(ev, 'data.message.conversation_id')
			let to = ''
			let userid = ''

			let $sender = null
			if (bytype === 'user') {
				userid = lo.get(ev, 'by.id')
				let user = store.matchUser(userid, true) || {}
				$sender = (
					<Fragment>
						<div class='text_message_sender__avatar'>
							<Avatar user={user} size='xs' />
						</div>
						<div
							class='text_message_sender__name clickable'
							style='font-size: 14px'
							vOn:click={() => this.$root.$emit('showQuickview', {convo_id: cid, user_id: userid || '-'})}
						>
							<span class='text__truncate mr-2'>
								<UserDisplayName user={user} />
							</span>
							<span class='text__muted no-shrink'>{this.$t('sent_an_email').toLowerCase()}</span>
						</div>
					</Fragment>
				)
				to = sb.getMsgField(ev, 'delivered-to')
				if (!to) {
					to = sb.getMsgField(ev, 'to') || {}
					if (lo.size(to) === 1) to = to[0]
				}
			} else {
				let agid = lo.get(ev, 'by.id')
				let agent = store.matchAgent()[agid] || {}
				userid = lo.get(ev, 'data.message.tos.0')
				to = sb.getMsgField(ev, 'to')
				to = lo.first(to)
				$sender = (
					<Fragment>
						<div class='text_message_sender__avatar'>
							<Avatar agent={agent} size='xs' />
						</div>
						<div class='text_message_sender__name clickable' style='font-size: 14px'>
							<span class='text__truncate mr-2' title={sb.getAgentDisplayName(agent)}>
								{sb.getAgentDisplayName(agent)}
							</span>
							<span class='text__muted no-shrink'>{this.$t('sent_an_email').toLowerCase()}</span>
						</div>
					</Fragment>
				)
			}
			let ccs = sb.getMsgField(ev, 'ccs')
			if (!ccs) ccs = sb.getMsgField(ev, 'cc')
			let attachments = lo.get(ev, 'data.message.attachments')

			let msgcls = `message_email_ev_box message_email_ev_box__${bytype}`
			let notecls = `message_event_help_text ${bytype}`
			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px;' class='text__muted no-shrink' />
					</div>
					<div class={cls} title={format(new Date(ev.created), 'HH:mm:ss, d MMM, yyyy', {locale: getDateFnsLocale()})}>
						<div
							class={msgcls}
							vOn:click={() => this.$root.$emit('showQuickview', {convo_id: cid, user_id: userid || '-'})}
						>
							<div class='d-flex' style='overflow: hidden'>
								<Icon
									name='mail'
									size='18'
									class='text__danger mr-3'
									stroke-width='1.5'
									style='position: relative; top: 2px'
								/>
								<div class='flex__1' style='overflow: hidden'>
									<div class='text__muted text__truncate' title={to}>
										{this.$t('to').toLowerCase()}: {to}
									</div>
									{lo.size(ccs) > 0 && (
										<div class='text__muted text__truncate' title={lo.join(ccs, ',')}>
											{'cc'}: {lo.join(ccs, ',')}
										</div>
									)}
								</div>
							</div>
							<div class='mt-2 text__semibold'>{sb.getMsgField(ev, 'subject') || <em>this.$t('no_subject')</em>}</div>
							{lo.size(attachments) > 0 && (
								<div class='text__muted'>
									+ {lo.size(attachments)} {this.$t('attachments').toLowerCase()}
								</div>
							)}
						</div>
					</div>
					<div class={cls}>
						<div class={notecls}>{this.$t('only_you_can_see_this_message')}</div>
					</div>
				</div>
			)
		},

		renderCallEvent(ev) {
			let bytype = lo.get(ev, 'by.type')
			if (bytype !== 'user') bytype = 'agent'

			let wrappercls = 'message_container_wrapper'
			let cls = 'message_container message_container__' + bytype

			let $sender = null
			if (bytype === 'user') {
				let userid = lo.get(ev, 'by.id')
				let user = store.matchUser(userid, true) || {}
				$sender = (
					<Fragment>
						<div class='text_message_sender__avatar'>
							<Avatar user={user} size='xs' />
						</div>
						<div class='text_message_sender__name' style='font-size: 14px'>
							<span class='text__truncate mr-2'>
								<UserDisplayName user={user} />
							</span>
							<span class='text__muted no-shrink'>{this.$t('call_to_call_center').toLowerCase()}</span>
						</div>
					</Fragment>
				)
			} else {
				let agid = lo.get(ev, 'data.conversation.call.initial_by_agent')
				let agent = store.matchAgent()[agid] || {}
				$sender = (
					<Fragment>
						<div class='text_message_sender__avatar'>
							<Avatar agent={agent} size='xs' />
						</div>
						<div class='text_message_sender__name' style='font-size: 14px'>
							<span class='text__truncate mr-2' title={sb.getAgentDisplayName(agent)}>
								{sb.getAgentDisplayName(agent)}
							</span>
							<span class='text__muted no-shrink'>{this.$t('call_to_customer').toLowerCase()}</span>
						</div>
					</Fragment>
				)
			}

			let notecls = `message_event_help_text ${bytype}`
			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px' class='text__muted no-shrink' />
					</div>
					<div class={cls} title={format(new Date(ev.created), 'HH:mm:ss, d MMM, yyyy', {locale: getDateFnsLocale()})}>
						<CallMessage ev={ev} />
					</div>
					<div class={cls}>
						<div class={notecls}>{this.$t('only_you_can_see_this_message')}</div>
					</div>
				</div>
			)
		},

		renderLoginEvent(ev) {
			let bytype = 'user'
			let userid = lo.get(ev, 'by.id')
			let user = store.matchUser(userid, true)

			let $sender = (
				<Fragment>
					<div class='text_message_sender__avatar'>
						<Avatar user={user} size='xs' />
					</div>
					<div class='d-flex align-items-center' style='font-size: 14px'>
						<span class='mr-2 text__muted'>
							{this.$t('cust')} {this.$t('logged_in').toLowerCase()}
						</span>
						<Icon name='circle-key-filled' class='text__warning' size='18' stroke-width='1' />
					</div>
				</Fragment>
			)
			let wrappercls = 'message_container_wrapper'
			let cls = 'message_container message_container__' + bytype

			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px; ' class='text__muted no-shrink' />
					</div>
				</div>
			)
		},

		renderPaymentMade(ev) {
			let bytype = lo.get(ev, 'by.type')
			let trueByType = bytype || 'subiz'
			if (bytype !== 'user') bytype = 'agent'

			let wrappercls = 'message_container_wrapper'
			let cls = 'message_container message_container__' + bytype

			let $sender = null
			if (bytype === 'user') {
				let userid = lo.get(ev, 'by.id')
				let user = store.matchUser(userid, true) || {}
				$sender = (
					<Fragment>
						<div class='text_message_sender__avatar'>
							<Avatar user={user} size='xs' />
						</div>
						<div class='text_message_sender__name' style='font-size: 14px'>
							<span class='text__truncate mr-2'>
								<UserDisplayName user={user} />
							</span>
							<span class='text__muted no-shrink'>{this.$t('receive_transaction').toLowerCase()}</span>
						</div>
					</Fragment>
				)
			} else {
				if (trueByType === 'system' || trueByType === 'subiz') {
					let $avatar = (
						<img2 src={lo.get(store.me(), 'account.logo_url')} style='width: 20px; height: 20px; border-radius: 50%' />
					)
					$sender = (
						<Fragment>
							<div class='text_message_sender__avatar'>{$avatar}</div>
							<div class='text_message_sender__name' style='font-size: 14px'>
								<span class='text__truncate mr-2'>{this.$t('system')}</span>
								<span class='text__muted no-shrink'>{this.$t('receive_transaction').toLowerCase()}</span>
							</div>
						</Fragment>
					)
				} else {
					let agid = lo.get(ev, 'by.id')
					let agent = store.matchAgent()[agid] || {}
					$sender = (
						<Fragment>
							<div class='text_message_sender__avatar'>
								<Avatar agent={agent} size='xs' />
							</div>
							<div class='text_message_sender__name' style='font-size: 14px'>
								<span class='text__truncate mr-2' title={sb.getAgentDisplayName(agent)}>
									{sb.getAgentDisplayName(agent)}
								</span>
								<span class='text__muted no-shrink'>{this.$t('receive_transaction').toLowerCase()}</span>
							</div>
						</Fragment>
					)
				}
			}

			let msgcls = `message_payment_box message_payment_box__${bytype}`
			let notecls = `message_event_help_text ${bytype}`
			return (
				<div class={wrappercls} style='' data-id={ev.id} data-cls={wrappercls}>
					<div
						class={`text_message_sender text_message_sender__${bytype}`}
						style='overflow: hidden; align-items: center'
					>
						{$sender}
						<span class='text__muted no-shrink'>&nbsp;·&nbsp;</span>
						<Time time={ev.created} style='font-size: 14px;' class='text__muted no-shrink' />
					</div>
					<div class={cls} title={format(new Date(ev.created), 'HH:mm:ss, d MMM, yyyy', {locale: getDateFnsLocale()})}>
						<div class={msgcls}>
							<div class='text__center'>
								<div class='message_payment_box_icon_wrapper'>
									<Icon name='coins' class='text__white' size='16' stroke-width='2' />
								</div>
								<div class='mt-2 text__muted text__sm'>{this.$t('successfully_transaction')}</div>
								<div class='text__semibold text__lg mt-1'>
									{accounting.formatNumber(lo.get(ev, 'data.bill.amount', 0), 2)}
								</div>
							</div>
							<hr class='mt-3 mb-3 ' style='background: gray' />
							<div class='d-flex align-items-center text__sm' style='overflow: hidden'>
								<div class='text__muted' style='width: 140px'>
									{this.$t('transaction_code')}
								</div>
								<div class='text__truncate text__right flex__1 ml-3'>
									{lo.get(ev, 'data.bill.transaction_code') || <em class='text__muted'>{this.$t('none')}</em>}
								</div>
							</div>
							<div class='d-flex align-items-center text__sm mt-2' style='overflow: hidden'>
								<div class='text__muted' style='width: 140px'>
									{this.$t('note')}
								</div>
								<div class='text__right flex__1 ml-3' style='white-space: pre-wrap'>
									{lo.get(ev, 'data.bill.description')}
								</div>
							</div>
						</div>
					</div>
					<div class={cls}>
						<div class={notecls}>{this.$t('only_you_can_see_this_message')}</div>
					</div>
				</div>
			)
		},

		onFormSubmit() {
			this.$emit('formSubmit')
		},

		pong(type) {
			if (!type) return
			store.pongMessage(this.cid, this.eid, {data: {message: {pongs: [{type}]}}})
		},

		pushReaction(ev, type) {
			let message = lo.get(ev, 'data.message', {})
			if (!type) return

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})
			if (isDelete) return

			message = lo.cloneDeep(message)

			let fields = [
				{key: 'facebook_comment_type', value: JSON.stringify('command')},
				{key: 'facebook_comment_command', value: JSON.stringify(type)},
				{key: 'facebook_comment_target_id', value: JSON.stringify(this.eid)},
			]
			message.fields = fields
			message.text = type
			this.$emit('submitMsg', message)
		},

		hideInternalActionDropdown() {
			this.showInternalActionDropdown = false
		},

		toogleInternalActionDropdown() {
			this.showInternalActionDropdown = !this.showInternalActionDropdown
		},

		hideActionDropdown() {
			this.showActionDropdown = false
		},

		toogleActionDropdown() {
			this.showActionDropdown = !this.showActionDropdown
		},

		countReaction(ev) {
			let pongs = lo.get(ev, 'data.message.pongs', [])
			let countReaction = {
				like: 0,
				love: 0,
				haha: 0,
				wow: 0,
				sad: 0,
				angry: 0,
				me_liked: 0,
				hide: 0,
				delete: 0,
			}
			lo.forEach(pongs, (reaction) => {
				let memberId = lo.get(reaction, 'member_id', '').toString()
				if (reaction.type === 'like' && memberId && memberId.indexOf('us') < 0) {
					countReaction.me_liked++
				}
				countReaction[reaction.type]++
			})
			return countReaction
		},

		renderAction(ev) {
			let connectorType = this.integration_connector_type
			if (connectorType !== 'facebook_comment') return

			let byType = lo.get(ev, 'by.type')

			if (byType !== 'user') return null

			let countReaction = this.countReaction(ev)

			let fbPostLink = sb.getMsgField(ev, 'facebook_comment_permalink_url')
			let $detail = null
			if (fbPostLink) {
				$detail = (
					<div class='dropdown__item' vOn:click={(_) => window.open(fbPostLink, '_blank')}>
						{this.$t('fb_link_post')}
					</div>
				)
			}

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})

			let $like = (
				<div
					class={'message_actions__action ' + (isDelete ? 'message_actions__action__disabled' : '')}
					vOn:click={(_) => this.pushReaction(ev, 'like')}
					v-tooltip={this.$t('like')}
				>
					<Icon name='thumb-up' size='18' />
				</div>
			)

			if (countReaction.me_liked) {
				$like = (
					<div
						class={'message_actions__action ' + (isDelete ? 'message_actions__action__disabled' : '')}
						vOn:click={(_) => this.pushReaction(ev, 'unlike')}
						v-tooltip={this.$t('unlike')}
					>
						<Icon name='thumb-down' size='18' />
					</div>
				)
			}

			let $delete = (
				<div
					vOn:click={(_) => this.pushReaction(ev, 'delete')}
					class={'dropdown__item ' + (isDelete ? 'dropdown__item__disabled' : '')}
				>
					{this.$t('delete')}
				</div>
			)

			let $hide = (
				<div
					vOn:click={(_) => this.pushReaction(ev, 'hide')}
					class={'dropdown__item ' + (isDelete ? 'dropdown__item__disabled' : '')}
				>
					{this.$t('hide')}
				</div>
			)
			if (countReaction.hide) {
				$hide = (
					<div
						vOn:click={(_) => this.pushReaction(ev, 'unhide')}
						class={'dropdown__item ' + (isDelete ? 'dropdown__item__disabled' : '')}
					>
						{this.$t('unhide')}
					</div>
				)
			}

			let $dropdown = null
			if (this.showActionDropdown) {
				$dropdown = (
					<div class='dropdown' style='left: 0; right: unset; min-width: 150px'>
						{$hide}
						{$delete}
						{$detail}
					</div>
				)
			}

			let cls = 'message_actions'
			if (this.showActionDropdown) cls += ' message_actions__show'
			return (
				<div class={cls} v-clickaway={this.hideActionDropdown}>
					{$like}
					<div v-tooltip={this.$t('more_action')} class='message_actions__action' vOn:click={this.toogleActionDropdown}>
						<Icon name='dots-vertical' size='18' />
						{$dropdown}
					</div>
				</div>
			)
		},
	},
}

const EmailHeaderModal = {
	name: 'email-header-modal',
	props: ['open', 'headers'],

	render() {
		return (
			<div class={{modal: true, modal__hide: !this.open}}>
				<div class='modal__overlay' vOn:click={() => this.$emit('close')} />
				<div class='modal__container'>
					<div class='modal__background'>
						<div class='modal_content' style='width: 720px'>
							<div class='modal_content__header'>
								<div class='d-flex align-items-center'>
									<div class='text__lg'>{this.$t('email_info')}</div>
									<Icon name='x' size='20' class='btn btn__close ml-auto' vOn:click={() => this.$emit('close')} />
								</div>
							</div>
							<div class='modal_content__main' style='padding: 15px 20px; max-height: 600px; overflow: auto'>
								{lo.map(this.headers, (value, key) => {
									return (
										<div class='d-flex' style='overflow: hidden; margin-bottom: 13px'>
											<div class='text__semibold text__truncate no-shrink mr-3' style='width: 200px'>
												{key}
											</div>
											<div class='flex__1' style='word-break: break-all'>
												{Array.isArray(value) ? lo.map(value, (v) => <div>{v}</div>) : <div>{value}</div>}
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},
}

let BlockEmailModal = {
	name: 'block-email',
	props: ['email', 'cid'],

	data() {
		return {block: 'email', updating: false}
	},

	methods: {
		async blockEmail() {
			let {error} = await store.createBlockEmail({id: this.email, email_type: 'address'})
			if (error) {
				this.$showError(error)
				return
			}
			if (this.block === 'domain') {
				let domain = this.email.split('@')
				let {error} = await store.createBlockEmail({id: domain[1], email_type: 'domain'})
				if (error) {
					this.$showError(error)
					return
				}
			}
			this.closeModal()
			let convo = store.matchConvo(this.cid) || {}
			if (convo.state === 'ended') return
			store.endConvo(this.cid)
			this.$forceUpdate()
		},

		closeModal() {
			this.block = 'email'
			this.$emit('close')
		},

		toggleCheck() {
			if (this.block === 'email') this.block = 'domain'
			else this.block = 'email'
		},
	},

	render() {
		let header = this.$t('block_this_email', [this.email]) + '?'
		let domain = this.email.split('@')[1]

		let $blockDomain = (
			<div class='mt-4 d-flex'>
				<input
					type='checkbox'
					class='form-check-input'
					style='flex-shrink: 0;'
					checked={this.block === 'domain'}
					vOn:change={this.toggleCheck}
					vOn:click_stop={() => false}
				/>
				<div class='pl-2' style='cursor: pointer; flex-shrink: 0;' vOn:click={this.toggleCheck}>
					{this.$t('block_this_domain_desc')}&nbsp;
				</div>
				<div class='text__truncate' style='cursor: pointer;' vOn:click={this.toggleCheck}>
					<strong v-tooltip={domain}>{domain}</strong>
				</div>
			</div>
		)
		let style = 'height: 230px;'

		if (COMMON_DOMAIN.includes(domain)) {
			$blockDomain = null
			style = ''
		}
		return (
			<div class={{modal: true, modal__hide: !this.email}}>
				<div class='modal__overlay' vOn:click={() => this.closeModal()} />
				<div class='modal__container' style={`width: 480px; ${style}`}>
					<div class='modal__background' style='width: 100%; height: 100%; overflow: hidden;'>
						<div
							class='modal_content d-flex'
							style='width: 100%; height: 100%; flex-direction: column; justify-content: center;'
							key={this.email}
						>
							<div class='modal_content__header' style='display: inline-flex;'>
								{/* {header} */}
								<span>{this.$t('block')}</span>&nbsp;
								<span class='text__truncate' v-tooltip={this.email}>
									{this.email}
								</span>
								<span>?</span>
							</div>
							<div style='flex: 1; padding: 10px 20px; '>
								<div class='mb-2'>{this.$t('block_this_email_desc')}</div>
								{$blockDomain}
							</div>
							<div class='send_bulk_email_modal_footer pl-4 pr-4' style='height: 50px;'>
								<div class='ml-auto d-flex align-items-center' style='width: 100%;'>
									<button class='btn btn__light btn__sm mr-3' vOn:click={() => this.closeModal()}>
										{this.$t('cancel')}
									</button>
									<div style='flex: 1;'></div>
									<button class='btn btn__danger btn__sm' vOn:click={() => this.blockEmail()}>
										{this.$t('block')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},
}

function extractEmailAddressFromCC(cc = '') {
	let result = ''
	let isInsert = false
	for (let i = 0; i < cc.length; i++) {
		let chr = cc[i]
		if (chr === '<') {
			isInsert = true
			continue
		}
		if (chr === '>') {
			isInsert = false
			break
		}
		if (isInsert) {
			result += chr
		}
	}
	return result
}
