const lo = require('lodash')
const sb = require('@sb/util')
var api = require('./api.js')

import InMemKV from './inmem_kv.js'

function NewLiveStore(convoStore, realtime, pubsub) {
	let kv = new InMemKV()
	kv.init()

	var me = {}

	var livetotal = 0
	var topLocations = []
	var topSources = []
	var topPages = []
	var topUsers = {}

	// var user_filter = {}
	var last_user_filter_signature = ''

	me.destroy = () => kv.destroy()

	let startLiveLoop = async () => {
		for (;;) {
			if (!lo.get(api.getCred(), 'agent_id')) {
				await sb.sleep(7000)
				continue
			}

			// dont loop when no once listen
			if (pubsub.count('live') === 0) {
				await sb.sleep(7000)
				continue
			}
			await me.fetchLive()
			await me.fetchActiveUsers()
			await sb.sleep(7000)
		}
	}
	startLiveLoop()

	me.matchLiveTotal = () => livetotal
	me.matchTopLocations = () => topLocations
	me.matchTopSources = () => topSources
	me.matchTopPages = () => topPages
	me.matchLiveUsers = () => topUsers
	me.matchLiveUserFilter = () => {
		let filter = kv.match('user_filter') || {}
		if (
			!['leadscore.desc', 'time_on_site.asc', 'time_on_site.desc', 'viewcount.asc', 'viewcount.desc'].includes(
				filter.order_by,
			)
		) {
			filter.order_by = 'time_on_site.asc'
			kv.put('user_filter', filter)
		}
		return filter
	}
	me.setLiveUserFilter = async (filter) => {
		clean(filter)
		kv.put('user_filter', filter)
		pubsub.publish('live')

		await me.fetchActiveUsers()
		pubsub.publish('live')
	}

	me.fetchActiveUsers = async () => {
		let {body: users} = await api.filterLiveUser(
			Object.assign({signature: last_user_filter_signature, limit: 100}, me.matchLiveUserFilter()),
		)

		if (!lo.get(users, 'signature')) return // error
		last_user_filter_signature = lo.get(users, 'signature', '')

		let userids = lo.map(users.users, (user) => user.id)
		lo.map(users.users, (user) => {
			if (lo.size(user.attributes) > 0) convoStore.justUpdateUser(user)
		})

		users.users = userids
		// load convo
		let {body: convos} = await api.listUserConversations(userids.join(','), 'active')
		lo.map(userids, (uid) => {
			lo.map(lo.get(convos, 'conversations'), (convo) => {
				if (convo.state !== 'active') return
				if (!sb.usersOfConvo(convo).includes(uid)) return
				let convos = lo.get(users, ['convos', uid], [])
				convos.push(convo)
				lo.set(users, ['convos', uid], convos)
			})
		})
		topUsers = users
		pubsub.publish('live')
	}

	me.fetchLive = async () => {
		let out = await Promise.all([
			api.topLiveLocations(),
			api.topLivePages(),
			api.topLiveSources(),
			api.countLiveTotal(),
		])

		let {body: locations} = out[0]
		topLocations = lo.get(locations, 'location_metrics', [])
		let {body: pages} = out[1]
		topPages = lo.get(pages, 'page_metrics', [])
		let {body: sources} = out[2]
		topSources = lo.get(sources, 'source_metrics', [])
		let {body: total} = out[3]
		livetotal = total

		pubsub.publish('live')
	}

	me.onLive = (o, cb) => pubsub.on2(o, 'live', cb)

	return me
}

function isEmpty(obj) {
	if (!obj) return true
	return Object.keys(obj).length === 0 && obj.constructor === Object
}

function clean(obj) {
	for (var propName in obj) {
		if (obj.constructor === Object) {
			clean(obj[propName])
		}

		if (isEmpty(obj[propName])) {
			delete obj[propName]
		}
	}
}

export default NewLiveStore
