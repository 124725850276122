import store from '@sb/store'
import {PrivateMessageEditor} from '@sb/com'
const lo = require('lodash')
const sb = require('@sb/util')
const emptymsgimg = require('../assets/img/integration-help-image.png')
const notfound = require('../assets/img/startchat.svg')
const convoemptyimg = require('../assets/img/convos_empty.svg')
import Events from '../commons/Events.js'

export default {
	name: 'internal_chat',
	props: ['cid', 'showing', 'pinned'],

	created() {
		this._com = {
			fetchMoreMessages: store.fetchMoreMessages,
			listMessages: store.listMessages,
		}

		store.onConvo(this, (convoM) => {
			if (Object.keys(convoM).includes(this.cid)) this.$forceUpdate()
		})
	},

	methods: {
		onContainerClick() {
			// ignore select behaviour
			var selection = window.getSelection()
			if (selection.toString().length > 0) return
			if (this.$refs.msg_input) this.$refs.msg_input.Focus()
		},

		async handleBookmarkMessage(ev) {
			let out
			if (lo.get(ev, 'data.message.pinned')) out = await store.unpinMessage(this.cid, ev.id)
			else out = await store.pinMessage(this.cid, ev.id)
			if (out.error) return this.$showError(this.$t('update_failed'))
		},

		handleQuoteMessage(ev) {
			let mes = this.$refs.msg_input.GetMessage()
			let attachments = lo.get(mes, 'attachments', [])
			attachments = lo.filter(attachments, (att) => att.type !== 'quote')
			attachments.push({type: 'quote', quote: ev})
			mes.attachments = attachments
			this.$refs.msg_input.SetMessage(mes)
		},

		renderMessages(convo) {
			if (!convo) return null
			return (
				<Events
					cid={this.pinned ? this.cid + '&pinned' : this.cid}
					com={this._com}
					hidden={!this.showing}
					vOn:submitMsg={this.onSubmitMsg}
					vOn:quote={this.handleQuoteMessage}
					vOn:bookmark={this.handleBookmarkMessage}
				/>
			)
		},

		async onSubmitMsg(msg) {
			if (!this.cid) return
			let cid = this.cid
			msg.is_private = true
			let convo = store.matchConvo(this.cid) || {id: this.cid}
			store.sendMessage2({touchpoint: convo.touchpoint, msg, convoid: cid})
		},
	},

	render() {
		if (!this.cid) return null
		let convo = store.matchConvo(this.cid) || {id: this.cid}
		var connectorid = lo.get(convo, 'integration.connector_id')

		let agentM = store.matchAgent()
		let agents = lo.map(sb.agentsOfConvo(convo), (agid) => agentM[agid])
		agents = lo.orderBy(agents, 'id')

		let $npinned = null
		let npinned = lo.size(store.listMessages(this.cid + '&pinned'))
		if (npinned > 40) npinned = '40+'
		if (npinned === 0) $npinned = <span></span>
		else
			$npinned = (
				<span class='link link__secondary' style='cursor:pointer; ' vOn:click={(_) => this.$emit('showPinned')}>
					<bookmark-icon size='1.2x' style='margin-top: -2px' class='mr-2' /> {npinned} {this.$t('bookmark')}
				</span>
			)
		if (this.pinloading) $npinned = <span></span>

		let $content = (
			<fragment>
				<div
					class='internal_chat__subheader'
					style='justify-content: space-between; cursor: pointer'
					vOn:click={(_) => this.$root.$emit('showPrivateChat', 'chat:' + convo.id + ':detail')}
				>
					{$npinned} <AvatarGroup agents={agents} xs size={5} expanded />{' '}
				</div>
				{this.renderMessages(convo)}
				<div class='convo_input'>
					<PrivateMessageEditor
						ref='msg_input'
						key={convo.id}
						cid={convo.id}
						vOn:submit={this.onSubmitMsg}
						placeholder='Nhâp tin nhắn nội bộ'
					/>
				</div>
			</fragment>
		)

		if (this.pinned) $content = this.renderMessages(convo)

		// dont display content when agent dont have permission
		let members = lo.get(convo, 'members', [])
		let notMember = !lo.find(members, (member) => member.id === lo.get(store.me(), 'id'))
		if (notMember) {
			$content = (
				<div class='pt-5 pr-3 pl-3 text__center text__muted'>{'Bạn không có quyền xem hội thoại của người khác'}</div>
			)
		}

		return <div style='display: flex; flex-direction: column; flex: 1; overflow: hidden'>{$content}</div>
	},
}

/*&pinned*/
