export default {
	name: 'confirm-popup',
	data() {
		return {
			showing: false,
			title: '',
			description: '',
			okLabel: '',
			cancelLabel: '',
			style: '',
			hasIcon: false,
		}
	},

	methods: {
		ok() {
			if (!this.showing) return
			this.showing = false
			this.$root.$emit('_confirm_response', true)
		},

		cancel() {
			if (!this.showing) return
			this.showing = false
			this.$root.$emit('_confirm_response', false)
		},

		onKeyup(e) {
			if (e.key !== 'Escape') return
			this.cancel()
		},

		onShowConfirm(param) {
			this.showing = true
			this.description = param.description
			this.title = param.title || this.$t('confirm')
			this.okLabel = param.ok || this.$t('confirm')
			this.cancelLabel = param.cancel || this.$t('cancel')
			this.style = param.style || 'primary'
			this.hasIcon = !!param.hasIcon
		},
	},

	created() {
		this.$root.$on('_confirm_request', this.onShowConfirm)
		this.$once('hook:beforeDestroy', () => this.$root.$off('_confirm_request', this.onShowConfirm))

		document.addEventListener('keyup', this.onKeyup)
		this.$once('hook:beforeDestroy', () => document.removeEventListener('keyup', this.onKeyup))
	},

	render() {
		let cls = 'modal'
		if (!this.showing) cls += ' modal__hide'
		let $icon = null
		if (this.hasIcon && this.style === 'primary') {
			$icon = <Icon name='info-circle' class='confirm-popup__icon icon--primary' />
		}
		if (this.hasIcon && this.style === 'info') {
			$icon = <Icon name='help' class='confirm-popup__icon icon--info' />
		}
		if (this.hasIcon && this.style === 'success') {
			$icon = <Icon name='circle-check' class='confirm-popup__icon icon--success' />
		}
		if (this.hasIcon && this.style === 'danger') {
			$icon = <Icon name='circle-x' class='confirm-popup__icon icon--danger' />
		}
		if (this.hasIcon && this.style === 'warning') {
			$icon = <Icon name='alert-circle' class='confirm-popup__icon icon--warning' />
		}
		return (
			<div class={cls} style='z-index: 2147483647'>
				<div class='modal__overlay' vOn:click={this.cancel}></div>
				<div class='modal__container'>
					<div class='confirm-popup modal__background' vOn:click_stop={(_) => false}>
						<div class='confirm-popup__head'>
							<div>{this.title}</div>
							<Icon name='x' v-tooltip={this.$t('close')} class='x-icon ml-auto' vOn:click_stop={this.cancel} />
						</div>
						<div class='confirm-popup__body'>
							{$icon}
							<div>{this.description}</div>
						</div>

						<div class='confirm-popup__foot'>
							<a href='javascript:;' vOn:click_stop={this.cancel} class='link link__secondary text__semibold mr-4'>
								{this.cancelLabel}
							</a>
							<a href='javascript:;' vOn:click_stop={this.ok} class={'link text__semibold link__' + this.style}>
								{this.okLabel}
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	},
}
