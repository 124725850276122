const ajax = require('@subiz/ajax/src/ajax.js')
const lo = require('lodash')
const sb = require('@sb/util')
const bowser = require('bowser')
import {addInstrumentationHandler, resetInstrumentationHandlers} from './instrument.js'

var consoleNew
function main() {
	const logreq = ajax.setBaseUrl('https://log.subiz.net').withCredentials(true).setContentType('text/plain')
	if (document.createElement) {
		const f = document.createElement('iframe')
		f.style.display = 'none'
		document.documentElement.appendChild(f)
		consoleNew = f.contentWindow.console
	}
}

const SLEEP = 30_000 // 30s
const BATCH_LOGS_SIZE = 50
const DEBUG_EXPIRED = 2 * 24 * 3600_000 // 2 days

function LogStore({account_id, agent_id}) {
	let me = {}
	const caches = []
	let _log_inserted = false
	const browser_tab_id = 'chrome_sesssssss_' + sb.randomString(16)
	function computeConsoleLog(data) {
		// {timestamp} {scope} {level} {hostname} {account-id} {file}:{line} {content...}
		//
		let timestamp = displayUTCLogTime(new Date())
		let scope = 'dashboard'
		let level = data.level
		if (data.level === 'log') {
			level = 'LOG'
		} else if (data.level === 'debug') {
			level = 'DEBUG'
		} else if (data.level === 'error') {
			level = 'ERROR'
		} else if (data.level === 'warn') {
			level = 'WARNING'
		} else if (data.level === 'info') {
			level = 'INFO'
		} else {
			level = 'LOG'
		}
		let hostname = 'console'

		let args = data.args || []
		let content = ''
		lo.each(args, (arg) => {
			if (typeof arg === 'string') {
				content += `${arg} `
			} else if (typeof arg === 'number') {
				content += `${arg} `
			} else if (typeof arg === 'object') {
				if (arg.stack) {
					content += `${arg.stack} `
				} else {
					content += `${JSON.stringify(arg)} `
				}
			} else {
				content += `${JSON.stringify(arg)} `
			}
		})

		return `${timestamp} ${scope} ${level} ${hostname} ${account_id} ${agent_id} ${browser_tab_id}: ${content}`
	}

	function computeOnErrorLog(ev) {
		let timestamp = displayUTCLogTime(new Date())
		let scope = 'dashboard'
		let level = 'ERROR'
		let hostname = 'onError'
		let agent = parent.accStore.me() || {}
		let message = `${ev.msg} ${ev.url} ${ev.line}:${ev.column}`

		return `${timestamp} ${scope} ${level} ${hostname} ${account_id} ${agent_id} ${browser_tab_id}: ${message}`
	}

	function computeXHRLog(xhr) {
		let timestamp = displayUTCLogTime(new Date())
		let scope = 'dashboard'
		let level = 'ERROR'
		let hostname = 'xhr'
		let agent = parent.accStore.me() || {}

		let message = `${xhr.status} ${xhr.responseText}`
		return `${timestamp} ${scope} ${level} ${hostname} ${account_id} ${agent_id} ${browser_tab_id}: ${message}`
	}

	async function collectQueueLogs() {
		while (true) {
			let logs = caches.splice(0, BATCH_LOGS_SIZE)
			consoleNew.log('collectQueueLogs', logs)
			if (!lo.size(logs)) {
				await sb.sleep(SLEEP)
				continue
			}

			let base64Logs = lo.map(logs, (log) => {
				let result = ''
				try {
					// btoa cannot encode latin character, so we need this tricky
					result = btoa(unescape(encodeURIComponent(log)))
				} catch (err) {
					consoleNew.log('btoa error', err, log)
				}
				return result
			})
			base64Logs = lo.filter(base64Logs, Boolean)
			base64Logs = lo.join(base64Logs, '\n')
			await logreq.setQuery({format: 'base64'}).post('/collect', base64Logs)
			await sb.sleep(SLEEP)
		}
	}

	me.startLog = () => {
		if (_log_inserted) return
		consoleNew.log('STARTTTT LOG')
		let ua = window.navigator.userAgent
		let browserInfo = ua && bowser.getParser(ua)
		let browserName = lo.get(browserInfo, 'parsedResult.browser.name')
		let browserVer = lo.get(browserInfo, 'parsedResult.browser.version', '')
		let os = lo.get(browserInfo, 'parsedResult.os.name')
		let platform = lo.get(browserInfo, 'parsedResult.platform.type')
		browserName = browserName ? `${browserName} ${browserVer}` : 'Unknow browser'
		let resolution = `${window.screen.availWidth}x${window.screen.availHeight}`

		let sessionInfo = `${browserName} ${os} ${platform} ${resolution}`
		logreq.post(
			'/collect',
			`${displayUTCLogTime(
				new Date(),
			)} dashboard LOG START_LOG ++++++++++++++++++[${sessionInfo}]++++++++++++++++++++++`,
		)
		collectQueueLogs()
		addInstrumentationHandler('console', function (data) {
			//cannot console in this funciton because cause loop
			let log = computeConsoleLog(data)
			consoleNew.log('add Console Instrument', log, data)
			caches.push(log)
		})

		addInstrumentationHandler('error', function (ev) {
			let log = computeOnErrorLog(ev)
			consoleNew.log('add OnError Instrument', log, ev)
			caches.push(log)
		})

		addInstrumentationHandler('xhr', function (data) {
			const xhr = data.xhr || {}
			const args = data.args || []
			// ignore xhr.send() instrument
			if (!data.endTimestamp) return
			if (xhr.status >= 400 || xhr.status < 200) {
				let log = computeXHRLog(xhr)
				consoleNew.log('add XHR Instrument', log, xhr)
				caches.push(log)
			}
		})
		_log_inserted = true
	}

	me.stopLog = () => {
		if (!_log_inserted) return
		consoleNew.log('STOPPPP LOG')
		logreq.post(
			'/collect',
			`${displayUTCLogTime(
				new Date(),
			)} dashboard LOG END_LOG ===============================================================`,
		)
		resetInstrumentationHandlers()
		_log_inserted = false
	}

	return me
}

function displayUTCLogTime(date) {
	let yyyy = date.getUTCFullYear().toString()
	let yy = yyyy.substr(2, 2)
	let mm = date.getUTCMonth() + 1
	let dd = date.getUTCDate()
	let hh = date.getUTCHours()
	let MM = date.getUTCMinutes()
	let ss = date.getUTCSeconds()

	return `${yy}-${pad(mm)}-${pad(dd)} ${pad(hh)}:${pad(MM)}:${pad(ss)}`
}

function pad(v) {
	if (v < 10) {
		return `0${v}`
	}

	return `${v}`
}

main()
export default LogStore
