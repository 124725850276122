import {marked} from 'marked'
import accounting from 'accounting'
import Delta from 'quill-delta'

const LoadProductModal = () =>
	import(/*webpackChunkName: "product-modal" */ '../activities/conversation/productModal.js')

import MessageTemplates from './messageTemplate.js'
import Suggestion from './suggestion.js'
import EmailHeader from './emailHeader.js'
import GenericTemplateBuilder from '../bot/generic_template_builder.js'
import OrderCardContent from '../activities/conversation/order_card_content.js'

const lo = require('lodash')
const sb = require('@sb/util')
import pc from '../product/common.js'

import Quill from 'quill'
let Embed = Quill.import('blots/embed')

const ImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml']

const EmptyPixel =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

const EmojiList =
	'like unlike angry confused crying grinning heart-eyes neutral sleepy sad smiling tongue-out tired surprised wink'.split(
		' ',
	)

import DynamicField from '../commons/DynamicFieldEmbed'
import Fragment from './fragment.js'
DynamicField.blotName = 'dynamicField'
// tagName must be div so not error when 2 bold
// div2 to prevent copy paste cause null dynamic field error
DynamicField.tagName = 'div2'
Quill.register(DynamicField)

// define emoji blot
class EmojiImg extends Embed {
	static create(value) {
		let node = super.create()
		node.setAttribute('class', 'ml-1 mr-1 emoji emoji__' + value)
		node.setAttribute('src', EmptyPixel)
		node.setAttribute('data-code', value)
		return node
	}
	static value(element) {
		return element.getAttribute('data-code')
	}
}
EmojiImg.blotName = 'emoji'
EmojiImg.className = 'emoji'
EmojiImg.tagName = 'img'
Quill.register(EmojiImg)

// 'image/png, image/jpeg, image/gif'
// subiz, zalo, email for message template
export default {
	name: 'message-editor',
	props: [
		'initEvent',
		'is_public',
		'placeholder',
		'html',
		'acceptAttachment',
		'submittable',
		'connectorType',
		'email',
		'cid',
		'noTo',
		'alwayFull',
		'noTemplate',
		'selectProduct',
		'disabled',
		'uid', // optional

		// this props for campaign email edit
		'toolbar',
		'dynamicFieldData',
		'no_emoji',
		'no_email_cc',
		'no_email_header',
	],

	data() {
		return {
			openEmoji: false, // true if emoji window is opened
			message: {},
			expandEmailHeader: false,

			last_modal_view_id: '',
			confirmAction: null, // true if we are waiting for user to hit ento
			show_product: false,

			// promote product
			isPromoteProduct: true,
		}
	},

	watch: {
		initEvent(msg) {
			if (lo.isEqual(this.getMessage(), msg)) return // fix loop event
			this.applyMessage(msg)
		},
	},

	mounted() {
		let toolbar = null
		let theme = null
		// set promote product
		if (store.matchPromoteProduct() === false) this.isPromoteProduct = false

		this.$root.$on('conversationModalMessage', this.onConversationModalMessage)
		this.$root.$on('conversationModalMessage_send', this.onConversationModalMessageSend)
		this.$once('hook:beforeDestroy', () => {
			this.$root.$off('conversationModalMessage', this.onConversationModalMessage)
			this.$root.$off('conversationModalMessage_send', this.onConversationModalMessageSend)
		})
		if (this.alwayFull) this.expandEmailHeader = true
		if (this.html) {
			toolbar = [
				[{font: []}],
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				[{list: 'bullet'}, {list: 'ordered'}],
				[{color: []}, {background: []}], // dropdown with defaults from theme
				['link'],
				['clean'], // remove formatting button
			]
			theme = 'snow'
			if (this.toolbar) toolbar = this.toolbar
		}

		this.editor = new Quill(this.$refs.text_editor, {
			placeholder: this.placeholder || this.$t('placeholder_editor'),
			formats: this.html ? null : ['link', 'emoji', 'image', 'font'],
			modules: {
				toolbar,
				keyboard: {
					bindings: {
						esc: {key: 27, handler: (_) => this.onKeyPress(27)},
						enter: {key: 13, handler: (_) => this.onKeyPress(13)},
					},
				},
			},
			readOnly: this.disabled,
			theme,
		})
		// handle paste image
		if (!this.html) {
			this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
				if (node) {
					let orderjs = node.getAttributeNode('data-order')
					if (orderjs) {
						if (this.connectorType == 'instagram_comment') return new Delta()
						if (this.connectorType == 'facebook_comment') return new Delta()

						orderjs = orderjs.value
						let order = sb.parseJSON(decodeURI(orderjs))
						if (order.id) {
							this.message.attachments = this.message.attachments || []

							this.message.attachments = lo.filter(this.message.attachments, (att) => lo.get(att, 'order.id') !== id)
							this.message.attachments.push({
								type: 'order',
								description: node.innerText,
								order: order,
							})

							this.$emit('messageChanged', this.getMessage())
							this.$forceUpdate()
							this.editor.focus()
							return new Delta()
						}
					}
				}
				return delta
			})

			this.editor.clipboard.addMatcher('img', (node, delta) => {
				if (this.editorDisabled) return new Delta()
				if (this.connectorType == 'instagram_comment') return new Delta()
				if (
					node.src &&
					(node.src.startsWith('https://') || node.src.startsWith('http://') || node.src.startsWith('//'))
				) {
					if (this.connectorType == 'facebook_comment') {
						this.message.attachments = {
							type: 'file',
							mimetype: 'image/jpeg', // dump data
							size: 434, // dump date
							name: 'clipboard',
							url: node.src,
						}
					} else {
						this.message.attachments = this.message.attachments || []
						this.message.attachments.push({
							type: 'file',
							mimetype: 'image/jpeg', // dump data
							size: 434, // dump date
							name: 'clipboard',
							url: node.src,
						})
					}
					this.$emit('messageChanged', this.getMessage())
					this.$forceUpdate()
					this.editor.focus()
					return new Delta()
				}

				let file = dataURLtoFile(node.src, 'clipboard')
				this.uploadLocalFiles({target: {result: node.src, files: [file]}})
				return new Delta()
			})
		}

		let onEditorChange = lo.throttle((a) => {
			if (this.confirmAction) {
				this.confirmAction = null
				// must call in settimeout, should not change text directy in quill hander
				setTimeout(() => this.editor.setText(''))
			}
			this.$emit('messageChanged', this.getMessage())
			this.$forceUpdate()
		}, 50)
		this.editor.on('text-change', onEditorChange)
		this.$once('hook:beforeDestroy', () => this.editor.off('text-change', onEditorChange))

		//this.editor.keyboard.addBinding({ key: 13 }, _ => this.onKeyup(13)) // enter
		this.$refs.text_editor && this.$refs.text_editor.addEventListener('click', this.onQuillClick)

		this.message = {attachments: [], fields: []}
		this.applyMessage(this.initEvent)

		// move cursor to the end
		this.editor.setSelection(this.editor.getLength(), 0)
		this.editor.focus()
	},

	destroyed() {
		this.$refs.text_editor && this.$refs.text_editor.removeEventListener('click', this.onQuillClick)
	},

	methods: {
		Focus() {
			this.editor.focus()
		},

		onConversationModalMessage(message, viewid) {
			if (this.last_modal_view_id != viewid) return
			this.applyMessage(message)
			this.editor.focus()
		},

		onConversationModalMessageSend(message, viewid) {
			if (this.last_modal_view_id != viewid) return
			this.$emit('submit', message)
		},

		toggleEmoji() {
			this.editor.focus()
			this.openEmoji = !this.openEmoji
		},

		hideEmoji() {
			this.openEmoji = false
		},

		emojiPick(code) {
			var selection = this.editor.getSelection(true)
			this.editor.insertEmbed(selection.index, 'emoji', code, true)
			this.editor.setSelection(selection.index + 1, 0)

			this.hideEmoji()
			this.editor.focus()
		},

		getDynamicFieldName(key) {
			return lo.get(this.dynamicFieldData, [key, 'name'])
		},

		renderEmojiPopup() {
			if (!this.openEmoji) return null

			let $items = lo.map(EmojiList, (emoji) => {
				return (
					<div class='emoji_picker__item'>
						<div
							vOn:click={(_) => this.emojiPick(emoji)}
							v-tooltip={':' + emoji + ':'}
							class={'emoji emoji__preview emoji__' + emoji}
						/>
					</div>
				)
			})

			return (
				<div class='emoji_picker' v-clickaway={this.hideEmoji}>
					{$items}
				</div>
			)
		},

		productSelectClick() {
			this.isPromoteProduct = false
			store.updatePromoteProduct(false)
			this.$emit('productSelected')
			this.onProductSelected()
		},

		uploadClick() {
			this.$refs.file_input.click()
		},

		uploadLocalFiles(e) {
			this.message.attachments = this.message.attachments || []
			lo.map(e.target.files, (file) => {
				let id = sb.randomString(20) // for lookup later
				// load image
				if (ImageTypes.includes(file.type)) {
					var reader = new window.FileReader()
					reader.onload = (e) => {
						// after load all img data into the RAM, find update the corresponse attachment
						// since the attachment is very deep, we must force rerender
						lo.find(this.message.attachments, (att) => {
							if (att._local_id !== id) return false
							att.url = e.target.result
							// force rerender
							this.$set(this.message, 'attachments', lo.map(this.message.attachments))
							return true
						})
						this.$forceUpdate()
					}
					reader.readAsDataURL(file)
				}

				this.message.attachments.push({
					type: 'file',
					mimetype: file.type,
					size: file.size,
					name: file.name,
					_file: file,
					_local_id: id,
				})
			})
			this.$emit('messageChanged', this.getMessage())
			this.$forceUpdate()
			this.editor.focus()
		},

		removeOrder(id) {
			if (!id) return
			this.message.attachments = lo.filter(this.message.attachments, (att) => lo.get(att, 'order.id') !== id)
			this.$emit('messageChanged', this.getMessage())
			this.$forceUpdate()
			this.editor.focus()
		},

		removeFile(file) {
			// file is add (uploaded) by us, remove it using local ID
			if (file._local_id) {
				this.message.attachments = lo.filter(this.message.attachments, (att) => att._local_id !== file._local_id)
			} else {
				// remove using url
				this.message.attachments = lo.filter(this.message.attachments, (att) => att.url !== file.url)
			}
			this.$emit('messageChanged', this.getMessage())
			this.$forceUpdate()
			this.editor.focus()
		},

		renderFiles() {
			// find out all file attachment
			let files = lo.filter(
				this.message.attachments,
				(att) => att.type === 'file' && !ImageTypes.includes(att.mimetype),
			)

			let $files = lo.map(files, (file) => {
				return (
					<div class='message_editor__attachment'>
						<div class='message_editor__attachment_file'>
							<FileTextIcon stroke-width='1' class='message_editor__attachment_file_logo' size='2x' />
							<div class='d-flex flex-column text-truncate ml-3'>
								<div class='message_editor__attachment_file_name text-truncate'>{file.name}</div>
								<div class='message_editor__attachment_file_size text__muted'>{sb.humanFileSize(file.size)}</div>
							</div>
							<XIcon
								v-tooltip={this.$t('remove')}
								size='2x'
								stroke-width='2'
								class='message_editor__remove_file'
								vOn:click={(_) => this.removeFile(file)}
							/>
						</div>
					</div>
				)
			})

			if (lo.size(files) === 0) return null
			return <div class='message_editor__attachments__file'>{$files}</div>
		},

		renderOrders() {
			// find out all file attachment
			let orders = lo
				.filter(this.message.attachments, (att) => att.type === 'order' && att.order)
				.map((att) => att.order)
			let $orders = lo.map(orders, (order) => {
				return (
					<div class='message_editor__attachment'>
						<OrderCardContent order={order} class='message_editor__attachment_order' />
						<XIcon
							v-tooltip={this.$t('remove')}
							size='2x'
							stroke-width='2'
							class='message_editor__attachment_x'
							vOn:click={(_) => this.removeOrder(order.id)}
						/>
					</div>
				)
			})

			if (lo.size(orders) === 0) return null
			return <div class='message_editor__attachments__order'>{$orders}</div>
		},

		renderImages() {
			// find out all file attachment
			let files = lo
				.filter(this.message.attachments, (att) => att.type === 'file' && ImageTypes.includes(att.mimetype))
				.filter((f) => f.url)

			// find all photo file first
			let $imgs = lo.map(files, (file) => {
				return (
					<div class='message_editor__attachment'>
						<XIcon
							v-tooltip={this.$t('remove')}
							size='2x'
							stroke-width='2'
							class='message_editor__attachment_x'
							vOn:click={(_) => this.removeFile(file)}
						/>
						<img2 src={file.url} class='message_editor__attachment_image' />
					</div>
				)
			})

			if (lo.size(files) === 0) return null
			return <div class='message_editor__attachments__image'>{$imgs}</div>
		},

		renderAuthor() {
			let convo = store.matchConvo(this.cid)
			if (!this.cid || !convo) return null
			if (lo.get(convo, 'touchpoint.channel', 'subiz') == 'subiz') return null

			let inteid = sb.getIntegrationIdFromConvo(convo)
			let inte = store.matchIntegration()[inteid] || {}

			return (
				<div class='message_editor__author'>
					<img
						class='convo_header__source_img'
						style='width: 14px; height: 14px; border-radius: 3px'
						src={inte.logo_url}
					/>
					<a v-tooltip={this.$t('message_will_be_sent_using_this_page')} class='ml-2 text__muted text__sm'>
						{inte.name}
					</a>
				</div>
			)
		},

		renderActions() {
			let $btnSend = (
				<div class='message_editor__action_item message_editor__action_item__send' vOn:click_stop={this.onSend}>
					<div class='ml-3 btn btn__sm btn__primary'>{this.$t('send_label')}</div>
				</div>
			)

			if (!this.html) $btnSend = null
			if (!this.submittable) $btnSend = null

			let $templateBtn = null
			if (!this.noTemplate && this.connectorType) {
				$templateBtn = (
					<div
						class='message_editor__action_item'
						vOn:click_stop={this.onMgsTemplateClicked}
						v-tooltip={this.$t('title_message_template')}
					>
						<MessageSquareIcon class='message_editor__action_icon' size='18' stroke-width='2' />
					</div>
				)
			}

			let $attachmentBtn = null
			if (true) {
				$attachmentBtn = (
					<div
						class='message_editor__action_item'
						v-tooltip={this.$t('title_attachment')}
						vOn:click_stop={this.uploadClick}
					>
						<PaperclipIcon class='message_editor__action_icon' size='18' stroke-width='2' />
						<input
							type='file'
							style='display:none;'
							multiple
							vOn:change={this.uploadLocalFiles}
							ref='file_input'
							accept={this.acceptAttachment}
						/>
					</div>
				)
			}

			let $product = null
			if (this.selectProduct) {
				let $tooltip = (
					<div style='padding: 10px 15px; max-width: 280px; position: relative; padding-right: 60px'>
						<x-icon
							class='btn__close'
							style='position: absolute; top: 10px; right: 15px;'
							vOn:click={() => {
								this.isPromoteProduct = false
								store.updatePromoteProduct(false)
							}}
						/>
						<strong class='mb-3' style='font-size: 16px'>
							Gửi thẻ sản phẩm
						</strong>
					</div>
				)
				$product = (
					<HoverDropdown
						extra_dropdown_cls={'is-primary'}
						trigger='manual'
						isOpened={this.isPromoteProduct}
						dropdown_content={$tooltip}
					>
						<div
							class='message_editor__action_item btn btn__light btn__sm'
							vOn:click_stop={this.productSelectClick}
							style='position: relative'
						>
							<Icon name='shopping-cart' size='16' stroke-width='2' class='mr-2 text__muted' />
							<div class='message_editor__action_item_dot' />
							{this.$t('select_product')}
						</div>
					</HoverDropdown>
				)
				if (
					lo.get(store.me(), 'account_id') !== 'acqsulrowbxiugvginhw' &&
					lo.get(store.me(), 'account_id') !== 'acoxlchqxgdcubwf3c4d'
				) {
					$product = (
						<div
							class='message_editor__action_item'
							v-tooltip={this.$t('select_product')}
							vOn:click_stop={this.productSelectClick}
						>
							<Icon name='shopping-cart' class='message_editor__action_icon' size='18' stroke-width='2' />
						</div>
					)
				}
			}

			if (
				this.connectorType == 'facebook_comment' ||
				this.connectorType == 'instagram_comment' ||
				this.connectorType === 'email'
			) {
				$product = null
			}

			if (this.connectorType == 'instagram_comment') {
				$attachmentBtn = null
			}

			let convo = store.matchConvo(this.cid) || {}
			let channel = lo.get(convo, 'touchpoint.channel')

			let $zalo = null
			let userid = sb.usersOfConvo(convo)[0]
			let user = store.matchUser(userid) || {}
			let isFollowed = sb.getUserBooleanAttr(user, 'is_followed')

			if (channel === 'zalo' && isFollowed) {
				$zalo = (
					<div
						class='btn btn__sm btn__light message_editor__action_item'
						style='padding: 2px 8px'
						vOn:click={this.sendRequestInfo}
					>
						{this.$t('request_info')}
					</div>
				)
			}

			let dynamicFieldItems = lo.map(this.dynamicFieldData, (attr) => ({
				id: attr.key,
				label: this.$i18nText(attr.i18n_label) || attr.label || attr.name,
			}))
			return (
				<div class='message_editor__actions'>
					{$zalo}
					{$product}
					{this.renderEmojiPopup()}

					{!this.no_emoji && (
						<div class='message_editor__action_item' vOn:click_stop={this.toggleEmoji} v-tooltip={this.$t('emoji')}>
							<SmileIcon class='message_editor__action_icon' size='18' stroke-width='2' />
						</div>
					)}
					{lo.size(this.dynamicFieldData) > 0 && (
						<Dropdown
							mode='custom'
							items={dynamicFieldItems}
							dropdown_width={180}
							vOn:select={this.selectDynamicField}
							right
						>
							<div
								class='message_editor__action_item'
								v-tooltip={this.$t('add_contact_token')}
								vOn:click={this.toggleDynamicField}
							>
								<Icon name='braces' class='message_editor__action_icon' size='18' stroke-width='2' />
							</div>
						</Dropdown>
					)}
					{$attachmentBtn}
					{$templateBtn}
					{$btnSend}
				</div>
			)
		},

		toggleDynamicField() {
			this.choosenToken = null
			this.openEmoji = false
			this.openDynamicField = !this.openDynamicField
		},

		toggleUpdateDynamicField(e) {
			// settimeout to run this function after clickaway
			this.choosenToken = e.target.closest('.dynamic-field')
			setTimeout(() => {
				this.openEmoji = false
				this.openDynamicField = true
			}, 100)
		},

		selectDynamicField(attr) {
			if (this.choosenToken) {
				const delta = lo.cloneDeep(this.editor.getContents())
				const id = this.choosenToken.getAttribute('data-id')
				const selectedEmbed = delta.ops.find((op) => op.insert.dynamicField && op.insert.dynamicField.id === id)
				if (!selectedEmbed) return
				lo.set(selectedEmbed, 'insert.dynamicField.value', this.getDynamicFieldName(attr.id))
				lo.set(selectedEmbed, 'insert.dynamicField.key', `user.${attr.id}`)
				this.editor.setContents(delta)
				this.openDynamicField = false
				this.choosenToken = null
				return
			}
			return this.InsertDynamicField(attr)
		},
		// parrent may call
		InsertDynamicField(attr) {
			const id = sb.randomString(16)
			var selection = this.editor.getSelection(true)
			this.editor.insertEmbed(
				selection.index,
				'dynamicField',
				{value: this.getDynamicFieldName(attr.id), id, key: `user.${attr.id}`},
				true,
			)
			this.editor.setSelection(selection.index + 1, 0)
			this.openDynamicField = false
		},

		onQuillClick(e) {
			if (!lo.size(this.dynamicFieldData)) return
			if (
				e.target.getAttribute('data-type') === DynamicField.blotName ||
				(e.target.parentNode && e.target.parentNode.getAttribute('data-type') === DynamicField.blotName)
			) {
				this.toggleUpdateDynamicField(e)
			}
		},

		async sendRequestInfo() {
			let convo = store.matchConvo(this.cid)
			if (!convo) return
			let oa_id = lo.get(convo, 'touchpoint.source')
			let user_id = lo.get(convo, 'touchpoint.id')
			let res = await store.sendZaloRequestInfo({oa_id, user_id})
			if (res.error) {
				let code = lo.get(res, 'body.code')
				let error = 'Bạn đã gửi yêu cầu với người dùng này'
				if (code === 'invalid_integration') {
					error = 'OA không khả dụng'
				}
				this.$showError(error)
			}
		},

		onChangePublic(v) {
			this.$emit('publicChanged', v)
		},

		GetMessage() {
			return this.getMessage()
		},

		onSend() {
			this.$emit('submit', this.getMessage())
		},

		onMgsTemplateClicked() {
			if (this.$refs.message_template && this.$refs.message_template.IsShow()) {
				this.$refs.message_template.Show(false)
				this.editor.focus()
				return
			}

			// append to the end
			this.editor.insertText(this.editor.getLength() - 1, '/', true)
			let text = this.editor.getText()
			this.$refs.message_template.Show(true, text)

			var selection = this.editor.getSelection(true)
			this.editor.setSelection(selection.index + 1, 0)
			this.editor.focus()
		},

		getText() {
			let text = ''
			lo.map(this.editor.getContents().ops, (op) => {
				if (!op.insert) return
				if (typeof op.insert === 'string') text += op.insert
				if (lo.get(op, 'insert.emoji')) text += ':' + op.insert.emoji + ':'
			})
			return text
		},

		getMessage() {
			let message = {text: lo.trim(this.getText()), format: 'plaintext'}
			if (this.html) {
				message.text = lo.trim(this.editor.container.firstChild.innerHTML)
				message.format = 'html'
				message.quill_delta = JSON.stringify(this.editor.getContents())

				let $emheader = this.$refs.email_header
				if ($emheader) {
					let fields = $emheader.GetFields()
					message.fields = fields
				} else {
					message.fields = this.initEvent.fields
				}
			}

			if (lo.get(this.message, 'attachments', []).length > 0) {
				message.attachments = this.message.attachments
			}

			// ignore empty message
			if (!message.text && lo.size(message, 'attachments') === 0) return

			// this.message = { text: '' }
			if (this.$refs.file_input) this.$refs.file_input.value = ''
			return message
		},

		applySuggestion(action) {
			if (action.confirm) {
				this.editor.setText(action.text + ', hit enter to process...', true)
				// move cursor to end
				this.editor.setSelection(this.editor.getLength(), 0)
				this.editor.formatText(0, this.editor.getLength(), 'color', '#0000ff')
				this.editor.focus()

				setTimeout(() => {
					// must wait for on-text-change flow to end, before setting confirm action
					this.confirmAction = action
				}, 100) // must bigger than text-change throlle

				return
			}

			setTimeout(() => this.editor.setText(''))
			this.$emit('action', action)
		},

		tryConvertDynamicField() {
			if (!this.message.quill_delta) return
			let deltas = sb.parseJSON(this.message.quill_delta) || {ops: []}
			lo.each(deltas.ops, (op, idx) => {
				if (lo.get(op, 'insert.dynamicField.key') === 'user.fullname') {
					let user = store.matchUser(this.uid)
					if (user) return lo.set(deltas, `ops.${idx}`, {insert: sb.getUserDisplayName(user)})
					let convo = store.matchConvo(this.cid)
					let userids = sb.usersOfConvo(convo)
					user = store.matchUser(lo.first(userids)) || {}
					lo.set(deltas, `ops.${idx}`, {insert: sb.getUserDisplayName(user)})
				}
			})
			if (this.message.format === 'markdown') {
				this.message.text = sb.deltaToMarkdown(deltas.ops)
			} else if (this.message.format === 'plaintext') {
				this.message.text = sb.deltaToPlainText(deltas.ops)
			} else if (this.message.format === 'html' && this.uid || this.cid) {
				this.message.text = sb.deltaToHtml(deltas.ops)
			}
		},

		applyMessage(message) {
			if (!message) return
			this.message = lo.cloneDeep(message)

			if (this.connectorType == 'instagram_comment') {
				delete this.message.attachments // no attachment for instagram comment
			}

			this.tryConvertDynamicField()

			this.editor.setText(this.message.text || '')

			if (this.message.format === 'markdown') {
				let html = marked(this.message.text)
				this.editor.setText('', true)
				this.editor.clipboard.dangerouslyPasteHTML(0, html)
			}

			if (this.message.format === 'plaintext') {
				this.editor.setText(this.message.text || '')
			}

			if (this.message.format === 'html') {
				let html = this.message.text
				// let html = '<p>Một <strong>hai <i>ba</i> bốn </strong></p>'
				this.editor.setText('', true)
				this.editor.clipboard.dangerouslyPasteHTML(0, html)
			}

			this.$emit('messageChanged', this.getMessage())
		},

		onKeyPress(code) {
			// enter
			if (code === 13) {
				if (this.confirmAction) {
					setTimeout(() => this.editor.setText(''))
					this.$emit('action', this.confirmAction)
					return false
				}
				if (this.html) return true

				// this.editor.formatText(range, 'bold', true)
				if (this.$refs.message_template && this.$refs.message_template.IsShow()) return false
				if (this.$refs.suggestion && this.$refs.suggestion.IsShow()) return false

				this.onSend()
				return false
			}

			// esc
			if (code === 27) {
				if (this.confirmAction) {
					this.confirmAction = null
					// must call in settimeout, should not change text directy in quill hander
					setTimeout(() => this.editor.setText(''))
				}
			}
		},
		onExpandEmailHeader(mini) {
			this.expandEmailHeader = !mini
		},

		async onProductSelected() {
			this.show_product = !this.show_product
			if (!this.ProductModal) {
				this.ProductModal = await LoadProductModal()
				this.$forceUpdate()
			}
		},

		onCloseProductSelect() {
			this.show_product = false
		},

		renderProductModal() {
			let ProductModal = lo.get(this, 'ProductModal.default')
			if (!ProductModal) return null
			return <ProductModal cid={this.cid} show={this.show_product} vOn:close={this.onCloseProductSelect} />
		},

		renderGenericTemplates() {
			let attachments = lo.get(this.message, 'attachments', [])
			let att = lo.find(attachments, (attachment) => attachment.type === 'generic')
			if (!att) return null
			return (
				<GenericTemplateBuilder
					noBranching
					locale={lo.get(store.me(), 'account.locale')}
					style='width: 100%; padding-right: 10px; padding-left:10px; padding-bottom: 10px'
					message={this.message}
					vOn:change={(msg) => this.applyMessage(msg)}
					vOn:remove={this.removeGallery}
				/>
			)
		},

		removeGallery() {
			let message = lo.cloneDeep(this.message)
			let attachments = lo.get(message, 'attachments', [])
			attachments = lo.filter(attachments, (att) => att.type !== 'generic')
			message.attachments = attachments
			this.applyMessage(message)
		},
	},

	render() {
		let text = this.editor && this.editor.getText()
		let $msgTemplates = null

		if (!this.noTemplate && this.connectorType) {
			let convo = store.matchConvo(this.cid)
			$msgTemplates = (
				<MessageTemplates
					ref='message_template'
					query={text}
					vOn:modal={async (e) => {
						this.applyMessage(Object.assign({}, this.message, {text: ''}))
						let out = await store.pickConversationModal(this.cid, e.id, e.query, this.uid)
						if (out.error || !lo.get(out, 'body.url')) return this.$showError(this.$t('open_modal_failed'))
						this.last_modal_view_id = out.body.view_id
						this.$root.$emit('showConversationModal', {
							user_id: sb.usersOfConvo(convo)[0],
							view_id: out.body.view_id,
							url: out.body.url,
						})
					}}
					vOn:choose={(ev) => {
						this.applyMessage(ev)
						// move cursor to the end
						this.editor.setSelection(this.editor.getLength(), 0)
						this.editor.focus()
					}}
					connectorType={this.connectorType}
				/>
			)
		}

		let $suggestion = null
		if (this.cid) $suggestion = <Suggestion ref='suggestion' query={text} vOn:choose={this.applySuggestion} />

		if (this.html) {
			let $emheader = null
			if (this.email) {
				$emheader = (
					<EmailHeader
						ref='email_header'
						style={this.no_email_header ? 'display: none' : ''}
						initEvent={this.message}
						noTo={this.noTo}
						alwayFull={this.alwayFull}
						mini={!this.expandEmailHeader}
						vOn:miniChange={this.onExpandEmailHeader}
						vOn:changed={() => this.$emit('messageChanged', this.getMessage())}
						uid={this.uid}
						cid={this.cid}
						no_cc={this.no_email_cc}
					/>
				)
			}
			return (
				<div
					class='message_editor message_editor message_editor message_editor__html'
					style={this.disabled ? 'pointer-events: none; opacity: 0.5' : 'justify-content: flex-end;'}
					vOn:click={(_) => this.editor.focus()}
				>
					{$msgTemplates}
					{$suggestion}
					<div style='position: relative; width: 100%'>
						{this.renderActions()}
						{this.renderFiles()}
						{this.renderOrders()}
						{this.renderImages()}
						{this.renderGenericTemplates()}
					</div>
					{/* just remove click_stop, dont know why neead click_stop */}
					<div ref='text_editor' class='message_editor__input' vOn:click_stop={(_) => true}></div>
					{$emheader}
				</div>
			)
		}

		if (this.disabled) {
			return (
				<div
					class='message_editor message_editor message_editor message_editor__plain'
					style='opacity: 0.7; pointer-events: none'
				>
					<div ref='text_editor' class='message_editor__input' vOn:click_stop={(_) => true}></div>
					{this.renderActions()}
				</div>
			)
		}

		return (
			<div
				class='message_editor message_editor message_editor message_editor__plain'
				vOn:click={(_) => this.editor.focus()}
			>
				{this.renderProductModal()}
				{$msgTemplates}
				{$suggestion}
				<div ref='text_editor' class='message_editor__input' vOn:click_stop={(_) => true}></div>
				{this.renderAuthor()}
				{this.renderActions()}
				{this.renderImages()}
				{this.renderFiles()}
				{this.renderOrders()}
				{this.renderGenericTemplates()}
			</div>
		)
	},
}

// convert data:image:base64... to js Image file
function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = window.atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new window.File([u8arr], filename, {type: mime})
}
