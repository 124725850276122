// this function will try to display error messgages form error object from BE
//{
//"id": 14848802015724364000,
//"class": 400,
//"code": "email_taken,invalid_input",
//"number": "95b89110",
//"fields": {
//"email": "ducck92@gmail.com"
//},
//"_hidden": {
//"function_name": "git.subiz.net/account/core.AccountMgr.CreateAccount",
//"server_name": "account-0",
//"stack": "/root/go/src/git.subiz.net/account/core/account.go:193 | /vendor/github.com/subiz/header/service.pb.go:5321 | /vendor/github.com/subiz/header/grpc.go:252 | /vendor/github.com/subiz/header/service.pb.go:5323\n--\n/vendor/github.com/subiz/header/service.pb.go:4215 | /app/server/account_handler.go:33 | /app/server/proxy.go:552 | /app/server/log.go:21 | /app/server/gzip.go:48"
//},
//"message": {
//"En_US": "Email ducck92@gmail.com is already taken, please use another email",
//"Vi_VN": "Email ducck92@gmail.com Ä‘Ã£ Ä‘Æ°á»£c sá»­ dá»¥ng, vui lÃ²ng sá»­ dá»¥ng email khÃ¡c"
//}
//}

import lo from 'lodash'
import {getCurrentLanguage, i18n} from '../languages.js'
const {messages: ERROR_MESSAGES, format} = require('@subiz/log')

// alwayys put internal or invalid_input to last fallbackCodes
const DEFALT_CODES = ['internal', 'invalid_input']
export function computeErrorMessage(error = {}, options = {show_error_id: true}) {
	let code = error.code || ''
	if (!code) return i18n.t('internal_server_error')

	const lang = getCurrentLanguage() === 'vi' ? 'vi_VN' : 'en_US'
	let fallbackCodes = lo.split(code, ',')

	let msg = ''
	let fields = error.fields || {}
	lo.each(fields, (val, key) => {
		let value = ''
		try {
			value = JSON.parse(val)
		} catch {
			value = val
		}
		fields[key] = value
	})

	// move internal or invalid_input to the last
	let remains = []
	let lasts = []
	lo.each(fallbackCodes, (code) => {
		if (DEFALT_CODES.includes(code)) {
			lasts.push(code)
		} else {
			remains.push(code)
		}
	})
	fallbackCodes = [...remains, ...lasts]

	for (let i = 0; i < fallbackCodes.length; i++) {
		let errorCode = fallbackCodes[i]
		if (!ERROR_MESSAGES[errorCode]) {
			msg = errorCode
			continue
		}
		let puremsg = lo.get(ERROR_MESSAGES, [errorCode, lang], '')
		msg = format(puremsg, fields)
		if (options.show_error_id && error.number) msg = `(${i18n.t('error')} #${error.number}) ${msg}`

		if (puremsg) break
	}

	return msg
}
