const def = require('../assets/img/default.jpg')
import sb from '@sb/util'
import 'lightgallery.js'
import Emoji from './emoji.js'

export default {
	name: 'image2',
	// emoji_size is sm md xs
	props: ['src', 'clickable', 'showsize', 'overlay', 'object_fit', 'maxHeight', 'emoji_size', 'true_src'],
	data() {
		return {
			err: false,
			size: '',
		}
	},

	mounted() {
		if (this.clickable) lightGallery(this.$refs.image)
	},

	render() {
		if (!this.src) return <div style='background-color: #d8d8d8' vOn:click={this.onClick}></div>
		let $size = null
		let objectfit = this.object_fit || 'cover'
		if (this.showsize && this.size) {
			$size = (
				<div style='position: absolute;background: #00000085;color: white;padding: 2px 3px;font-size: 11px;bottom: 5px;right: 5px; line-height: 1; border-radius: 1px;'>
					{this.size}
				</div>
			)
		}

		if ((this.src || '').startsWith('emoji://')) {
			let emojiCode = (this.src || '').replace('emoji://', '')
			let pairs = lo.split(emojiCode, '-')
			pairs = lo.filter(pairs, Boolean)
			pairs = lo.map(pairs, (pair) => `0x${pair}`)
			emojiCode = String.fromCodePoint(...pairs)
			return (
				<Emoji
					emojiCode={emojiCode}
					sm={this.emoji_size === 'sm'}
					xs={this.emoji_size === 'xs'}
					md={this.emoji_size === 'md'}
				/>
			)
		}

		if (this.clickable) {
			let $overlay = null
			if (this.overlay) {
				$overlay = <div class='image2__overlay' vOn:click={(_) => this.$refs.img_content.click()}></div>
			}
			let style = `object-fit: ${objectfit}; width: 100%; height: 100%`
			if (this.maxHeight) style += ';max-height: ' + this.maxHeight
			return (
				<div style='position: relative; cursor: pointer' class='image2'>
					<div ref='image' style='width: 100%; height: 100%'>
						<a href={sb.replaceFileUrl(this.true_src) || sb.replaceFileUrl(this.src)} target='_blank'>
							{$size}
							<img
								ref='img_content'
								src={sb.replaceFileUrl(this.src)}
								vOn:error={this.onError}
								style={style}
								vOn:load={this.onLoad}
							/>
						</a>
					</div>
					{$overlay}
				</div>
			)
		}
		return (
			<img
				src={sb.replaceFileUrl(this.src)}
				vOn:click={this.onClick}
				vOn:error={this.onError}
				vOn:load={this.onLoad}
				style={`object-fit: ${objectfit}`}
			/>
		)
	},

	methods: {
		onError(ev) {
			// make sure we just execute one
			if (this.err) return
			this.err = true
			var $el = ev.target
			$el.src = def
			$el.style['object-fit'] = 'cover'
		},

		onLoad(ev) {
			var $el = ev.target
			this.size = $el.naturalWidth + ' × ' + $el.naturalHeight
		},
		onClick(e) {
			this.$emit('click', e)
		},
	},
}
