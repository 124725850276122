const config = require('@sb/config')

export default {
	load: () => {
		if (config.env !== 'prod') return // facebook fixel
		;(function() {
			var _fbq = window._fbq || (window._fbq = [])
			if (!_fbq.loaded) {
				var fbds = document.createElement('script')
				fbds.async = true
				fbds.src = '//connect.facebook.net/en_US/fbds.js'
				var s = document.getElementsByTagName('script')[0]
				s.parentNode.insertBefore(fbds, s)
				_fbq.loaded = true
			}
			_fbq.push(['addPixelId', '1492184727660345'])
		})()
		window._fbq = window._fbq || []
		window._fbq.push(['track', 'PixelInitialized', {}])

		// google ananytics
		;(function(i, s, o, g, r, a, m) {
			i['GoogleAnalyticsObject'] = r
			;(i[r] =
				i[r] ||
				function() {
					;(i[r].q = i[r].q || []).push(arguments)
				}),
				(i[r].l = 1 * new Date())
			;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
			a.async = 1
			a.src = g
			m.parentNode.insertBefore(a, m)
		})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga')
		window.ga('create', 'UA-37364764-1', 'auto')
		window.ga('send', 'pageview')
		// google tag
		;(function(w, d, s, l, i) {
			w[l] = w[l] || []
			w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
			var f = d.getElementsByTagName(s)[0]
			var j = d.createElement(s)
			var dl = l != 'dataLayer' ? '&l=' + l : ''
			j.async = true
			j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl
			f.parentNode.insertBefore(j, f)
		})(window, document, 'script', 'dataLayer', 'GTM-M4K76T')
	},
	trackRoute: (to) => {
		if (config.env !== 'prod') return
		Array.isArray(window._fbq) && window._fbq.push(['track', 'PixelInitialized', {url: to.path}])
		Array.isArray(window.dataLayer) && window.dataLayer.push({event: 'pageview', page: {path: to.path}})
		if (!window.ga) return
		window.ga('set', 'page', to.path)
		window.ga('send', 'pageview')
	},
}
