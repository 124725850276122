const lo = require('lodash')
const sb = require('@sb/util')
import accounting from 'accounting'
import pc from '../../product/common.js'

import OrderCardContent from './order_card_content.js'

export default {
	name: 'order-attachments',
	props: ['ev'],

	render() {
		var byType = lo.get(this.ev, 'by.type', 'agent')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		let orders = lo.filter(attachments, (att) => att.type === 'order' && att.order).map((att) => att.order)

		if (orders.length === 0) return null

		let $orders = lo.map(orders, (order, index) => {
			return <OrderCardContent order={order} class={`message_order message_order__${byType}`} />
		})

		return (
			<div class={`message_container message_container__${byType}`}>
				<div class='d-flex flex-column'>{$orders}</div>
			</div>
		)
	},
}
