import sb from '@sb/util'
import lo from 'lodash'

export default {
	name: 'global-audio-player',

	data() {
		return {
			audio: null,
			componentId: '',
			open: false,
			title: '',
		}
	},

	mounted() {
		this.$root.$on('openGlobalAudio', this.openAudio)
		this.$root.$on('pauseGlobalAudio', this.pauseAudio)
		this.$root.$on('hideGlobalAudio', this.hideAudio)
		this.$root.$on('setGlobalAudioTitle', this.setAudioTitle)
	},

	destroyed() {
		this.$root.$off('openGlobalAudio', this.openAudio)
		this.$root.$off('pauseGlobalAudio', this.pauseAudio)
		this.$root.$off('hideGlobalAudio', this.hideAudio)
		this.$root.$off('setGlobalAudioTitle', this.setAudioTitle)

		if (this.audio) {
			this.audio.removeEventListener('play', this.onPlayAudio)
			this.audio.removeEventListener('pause', this.onPauseAudio)
			this.audio.removeEventListener('ended', this.onEndAudio)
		}
	},

	watch: {
		componentId(newId, oldId) {
			this.$root.$emit('globalAudioPaused', oldId)
		},
	},

	methods: {
		setAudioTitle(title) {
			this.title = title
		},

		loadAudio(src) {
			if (!this.audio) {
				let audio = document.createElement('audio')
				let source = document.createElement('source')
				source.src = src
				audio.appendChild(source)

				this.audio = audio
				this.audio.addEventListener('play', this.onPlayAudio)
				this.audio.addEventListener('pause', this.onPauseAudio)
				this.audio.addEventListener('ended', this.onEndAudio)
			} else if (src !== this.audio.src) {
				this.audio.src = src
				this.audio.load()
				this.$forceUpdate()
			} else {
				this.audio.currentTime = 0
				this.playAudio()
			}
		},

		onPlayAudio() {
			this.$root.$emit('globalAudioPlayed', this.componentId)
			clearInterval(this.curTimeInterval)
			this.curTimeInterval = setInterval(() => {
				this.$forceUpdate()
			}, 500)
		},

		onPauseAudio() {
			this.$root.$emit('globalAudioPaused', this.componentId)
			clearInterval(this.curTimeInterval)
		},

		onEndAudio() {
			this.pauseAudio()
		},

		openAudio(src, componentId) {
			console.log('openAudio', src, componentId)
			this.open = true
			this.componentId = componentId
			this.loadAudio(src)
			this.audio.play()
		},

		pauseAudio() {
			this.audio.pause()
			this.$forceUpdate()
		},

		playAudio() {
			this.audio.play()
			this.$forceUpdate()
		},

		replayAudio() {
			this.audio.currentTime = 0
			this.playAudio()
		},

		hideAudio() {
			this.open = false
			if (this.audio) {
				this.audio.currentTime = 0
				this.pauseAudio()
			}
		},

		renderRangeInput() {
			let value = (this.audio.currentTime * 100) / this.audio.duration
			value = Math.ceil(value)
			let style = `background: linear-gradient(to right, white 0%, white ${value}%, rgba(255,255,255, 0.7) ${value}%, rgba(255, 255, 255, 0.7) 100%)`
			return (
				<div class='global_audio_slider_wrapper'>
					<input
						type='range'
						value={value}
						class='global_audio_slider'
						style={style}
						vOn:input={this.onChangeProgess}
					/>
				</div>
			)
		},

		renderProgress() {
			let $rails = this.$refs.rails
			if (!$rails) return <div class='global_audio_progress' />

			let width = (this.audio.currentTime * $rails.clientWidth) / this.audio.duration

			return <div class='global_audio_progress' style={{width: `${width}px`}} />
		},

		onChangeProgess(e) {
			let value = e.target.value
			let sec = (value * this.audio.duration) / 100
			sec = roundNumber(sec)

			this.audio.currentTime = sec
			this.$forceUpdate()
		},

		renderPlayIcon() {
			if (!this.audio) {
				return <Icon name='player-play' size='20' stroke-width='1' class='text__white clickable' />
			}

			if (this.audio.paused) {
				return (
					<Icon
						name='player-play'
						size='20'
						stroke-width='1'
						class='text__white clickable'
						vOn:click={this.playAudio}
					/>
				)
			}
			return (
				<Icon
					name='player-pause'
					size='20'
					stroke-width='1'
					class='text__white clickable icon-filled'
					vOn:click={this.pauseAudio}
				/>
			)
		},

		renderReplayIcon() {
			return (
				<Icon
					name='rotate'
					size='18'
					stroke-width='2'
					class='text__white clickable ml-2'
					vOn:click={this.replayAudio}
				/>
			)
		},
	},

	render() {
		if (!this.audio) return null
		let $title = this.title
		if (!$title) $title = <em>{this.$t('unnamed')}</em>

		let duration = lo.get(this.audio, 'duration', 0)
		if (Number.isNaN(duration)) duration = 0

		return (
			<div class={{global_audio_card: true, active: this.open}} vOn:click_stop={() => false}>
				<div class='global_audio_card_x_button' vOn:click={this.hideAudio}>
					<Icon name='x' size='15' stroke-width='1.5' />
				</div>
				<div class='global_audio_actions mr-3'>
					{this.renderPlayIcon()}
					{this.renderReplayIcon()}
				</div>
				{this.renderRangeInput()}
				<div class='global_audio_time text__truncate text__right' style='width: 85px'>
					{sb.displayClockTimer(lo.get(this.audio, 'currentTime', 0))} / {sb.displayClockTimer(duration)}
				</div>
				<div class='global_audio_time' style='line-height: 1; '>
					<a href={lo.get(this.audio, 'currentSrc', '')} target='_blank' class='text__white' style='line-height: 1'>
						<Icon name='download' size='18' stroke-width='2' />
					</a>
				</div>
			</div>
		)
	},
}

function roundNumber(num) {
	num = Number(num) // The Number() only visualizes the type and is not needed
	let roundedString = num.toFixed(3)
	return Number(roundedString)
}
