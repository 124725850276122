const sb = require('@sb/util')
const lo = require('lodash')
const defaultAvatar = require('../assets/img/default_avatar.png')
const defaultAvatarZalo = require('../assets/img/default_avatar_zalo.png')
const defaultLogo = require('../assets/img/default_logo.jpg')
const defaultBotAvatar = require('../assets/img/bot/bot-1.svg')

const userban = require('../assets/img/user/userban.png')
const user0 = require('../assets/img/user/user0.png')
const user1 = require('../assets/img/user/user1.png')
const user2 = require('../assets/img/user/user2.png')
const user3 = require('../assets/img/user/user3.png')
const user4 = require('../assets/img/user/user4.png')
const user5 = require('../assets/img/user/user5.png')
const user6 = require('../assets/img/user/user6.png')
const user7 = require('../assets/img/user/user7.png')
const userAvatars = [user0, user1, user2, user3, user4, user5, user6, user7]
import {resolveUserDisplayNameInConvo} from '../common.js'

export default {
	name: 'avatar',
	props: [
		'user',
		'by',
		'size',
		'agent',
		'integration',
		'nodot',
		'notooltip',
		'bot',
		'rounded',
		'default',
		'skeleton',
		'tooltip_suffix',
		'is_profile',
	],

	methods: {
		renderMemberAvatar(size, by) {
			let bytype = by.type
			let byid = by.display_id || by.id

			if (bytype === 'user') return this.renderUserAvatar(size, store.matchUser(byid))

			if (bytype === 'dummy') {
				// find the integration
				let logo_url = this.integration.logo_url || require('../assets/img/bubble_00.svg')
				return <Avatar agent={{avatar_url: logo_url}} size='sm' />
			}

			if (bytype === 'workflow' || byid == 'system' || byid == 'subiz') {
				let automation = store.matchConvoAutomation()[byid] || {}
				let avatarUrl = automation.avatar_url || require('../assets/img/bubble_00.svg')
				return this.renderAgentAvatar(size, {avatar_url: avatarUrl})
			}

			// should replace with subiz logo
			let agent = store.matchAgent()[byid]
			return this.renderAgentAvatar(size, agent)
		},

		renderSkeleton(size) {
			let style = `width: ${size}px; height: ${size}px; border-radius: 50%; user-select: none; display: inline-block;vertical-align: top; background: whitesmoke`
			return <div style={style} draggable='false' />
		},

		renderIntegrationAvatar(size, integration) {
			let logo = sb.replaceFileUrl(lo.get(integration, 'logo_url'))
			if (!logo) logo = defaultLogo
			logo = sb.replaceFileUrl(logo)
			let name = lo.get(integration, 'name', 'Page')
			let style = `width: ${size}px; height: ${size}px; border-radius: 50%; user-select: none; display: inline-block;vertical-align: top;`
			return <img src={logo} style={style} draggable='false' v-tooltip={name} />
		},

		renderAgentAvatar(size, agent) {
			let avatar = lo.get(agent, 'avatar_url_128') || lo.get(agent, 'avatar_url')
			if (!avatar) {
				avatar = sb.getAgentDefaultAvatarUrl(agent)
			}
			let fullname = sb.getAgentDisplayName(agent)
			let br = '50%'
			let style = `width: ${size}px; height: ${size}px; border-radius: ${br}; user-select: none; display: inline-block;vertical-align: top; object-fit: cover;`

			let tooltip = fullname
			if (this.tooltip_suffix) tooltip += this.tooltip_suffix

			if (this.notooltip) tooltip = ''
			let online = sb.now() - sb.getMs(lo.get(agent, 'last_seen.pinged')) < 30000
			if (!this.nodot && online && size > 30) {
				return (
					<div style='position: relative; display: inline-block'>
						<img src={sb.replaceFileUrl(avatar)} style={style} draggable='false' v-tooltip={tooltip} />
						<div class='avatar__online' style={`width: ${size / 4 + 1}px;height: ${size / 4 + 1}px`}></div>
					</div>
				)
			}
			return <img src={sb.replaceFileUrl(avatar)} style={style} draggable='false' v-tooltip={tooltip} />
		},

		renderUserAvatar(size, user) {
			let userid = ''
			if (user && user.id) {
				let realuser = store.matchUser(user.id, true) || user
				userid = realuser.primary_id || realuser.id
				user = store.matchUser(userid) || user // should display name of primaryuser when channel !== call and  !== email
				let isProfile = lo.get(realuser, 'channel') !== 'call' && lo.get(realuser, 'channel') !== 'email'
				if (isProfile) {
					userid = realuser.id
					user = realuser
				}
			}

			let isUnknownVisitor = !lo.get(user, 'type')
			let username = resolveUserDisplayNameInConvo(user)
			if (!username || username === 'User #') username = ' '
			let avatarurl = sb.getUserTextAttr(user, 'avatar_url')
			avatarurl = sb.replaceFileUrl(avatarurl)

			if (!avatarurl && lo.get(user, 'channel') == 'zalo' && sb.getUserBooleanAttr(user, 'is_anonymous')) {
				return (
					<img2
						class={{user_avatar_blur: isUnknownVisitor}}
						style={`width: ${size}px; height: ${size}px;border-radius: 50%;object-fit: cover; vertical-align: top;`}
						src={this.default || require('../assets/img/ano-ava.svg')}
					/>
				)
			}

			if (!avatarurl && lo.get(user, 'channel') == 'zalo') {
				return (
					<img2
						class={{user_avatar_blur: isUnknownVisitor}}
						style={`width: ${size}px; height: ${size}px;border-radius: 50%;object-fit: cover; vertical-align: top;`}
						src={defaultAvatarZalo}
					/>
				)
			}

			let tooltip = username
			if (sb.isUserBanned(user) || (user && user.deleted) > 0)
				return (
					<img2
						class={{user_avatar_blur: isUnknownVisitor}}
						style={`width: ${size}px; height: ${size}px;border-radius: 50%;object-fit: cover; vertical-align: top; user-select: none;`}
						src={userban}
						v-tooltip={tooltip}
					/>
				)
			if (this.tooltip_suffix) tooltip += this.tooltip_suffix
			if (this.notooltip) tooltip = ''

			if (!userid) {
				return (
					<img2
						class={{user_avatar_blur: isUnknownVisitor}}
						style={`width: ${size}px; height: ${size}px;border-radius: 50%;object-fit: cover; vertical-align: top;`}
						src={this.default || require('../assets/img/default_avatar.png')}
					/>
				)
			}
			if (user && user.id == 'new')
				return (
					<img2
						class={{user_avatar_blur: isUnknownVisitor}}
						style={`width: ${size}px; height: ${size}px;border-radius: 5px;object-fit: cover; vertical-align: top;`}
						src={this.default || require('../assets/img/default_avatar.png')}
					/>
				)

			if (avatarurl)
				return (
					<img2
						class={{user_avatar_blur: isUnknownVisitor}}
						style={`width: ${size}px; height: ${size}px;border-radius: 50%;object-fit: cover; vertical-align: top; user-select: none;`}
						src={avatarurl}
						v-tooltip={tooltip}
					/>
				)
			return (
				<img2
					class={{user_avatar_blur: isUnknownVisitor}}
					style={`width: ${size}px; height: ${size}px;border-radius: 50%;object-fit: cover; vertical-align: top; user-select: none;`}
					src={
						isUnknownVisitor
							? require('../assets/img/user/user0.png')
							: userAvatars[(userid.charCodeAt(userid.length - 1) || 0) % 8]
					}
					v-tooltip={tooltip}
				/>
			)
		},

		renderBotAvatar(size, bot) {
			let avatar = lo.get(bot, 'avatar_url')
			let fullname = lo.get(bot, 'fullname', 'Bot')
			if (!avatar) avatar = defaultBotAvatar
			let borderRadius = 4
			switch (size) {
				case 'md':
					borderRadius = 8
					break
				case 'lg':
					borderRadius = 10
					break
			}
			let style = `width: ${size}px; height: ${size}px; border-radius: ${borderRadius}px; user-select:none`
			let tooltip = fullname
			if (this.tooltip_suffix) tooltip += this.tooltip_suffix
			if (this.notooltip) tooltip = ''
			return <img src={sb.replaceFileUrl(avatar)} style={style} draggable='false' v-tooltip={tooltip} />
		},
	},

	mounted() {
		if (this.user || this.by) {
			let uid = lo.get(this.user, 'id')
			if (!uid) uid = lo.get(this.by, 'id')
			store.onUser(this, (userM) => {
				if (userM[uid]) {
					this.$forceUpdate()
				}
			})
		}
	},

	render() {
		let size = parseInt((this.size || 50) + '')
		switch (this.size) {
			case 'xxs':
				size = 16
				break
			case 'xs':
				size = 20
				break
			case 'sm':
				size = 26
				break
			case 'md':
				size = 40
				break
			case 'lg':
				size = 60
				break
		}
		if (this.skeleton) return this.renderSkeleton(size)
		if (this.agent) return this.renderAgentAvatar(size, this.agent)
		if (this.bot) return this.renderBotAvatar(size, this.bot)
		if (this.by) return this.renderMemberAvatar(size, this.by)
		if (this.integration) return this.renderIntegrationAvatar(size, this.integration)
		return this.renderUserAvatar(size, this.user)
	},
}
