export default {
	name: 'conversation-modal',
	props: ['show', 'user_id', 'url', 'view_id'],

	mounted() {
		window.addEventListener('message', this.handleMessage, false)
	},

	beforeDestroy() {
		window.removeEventListener('message', this.handleMessage)
	},

	methods: {
		close(e) {
			this.$root.$emit('showConversationModal', {})
		},

		handleMessage(ev) {
			var dat = ev.data
			if (!dat.type) return
			if (dat.from == 'conversation_modal') {
				switch (dat.type) {
					case 'create_order':
						console.log('CREATING ORDER', dat)
						store.createOrder(dat.order)
						break
					case 'send_message':
						this.$root.$emit('conversationModalMessage_send', dat.message, this.view_id)
						break
					case 'apply_message':
						this.$root.$emit('conversationModalMessage', dat.message, this.view_id)
						break
					case 'get_user':
						let user = store.matchUser(this.user_id)
						ev.source.postMessage({from: 'subiz', _id: dat._id, body: {user: user}}, this.url)
						break
					case 'close':
						if (true || this.view_id === dat.view_id) this.close()
						break
				}
				return
			}
			switch (dat.type) {
				case 'create_order':
					console.log('CREATING ORDER', dat)
					store.createOrder(dat.order)
					break
				case 'tag_conversation':
					store.tagConvo(dat.conversation_id, dat.tag_id)
					break
				case 'message':
					this.$root.$emit('conversationModalMessage', dat.message, this.view_id)
					break
				case 'close':
					if (true || this.view_id === dat.view_id) this.close()
					break
			}
		},
	},

	render() {
		let cls = 'modal'
		if (!this.show) cls += ' modal__hide'

		return (
			<div class={cls} style='position: absolute; z-index: 10000'>
				<div class='modal__overlay' style='position: fixed' vOn:click_stop={this.close}></div>
				<div
					class='modal__container'
					style='position: relative; padding-top: 25px;width: 800px;height: 600px;'
					vOn:click_stop={(_) => false}
				>
					<div title='Đóng' style='top:0' class='product_select_close' vOn:click_stop={this.close}>
						<XIcon size='1x' stroke-width='3' />
					</div>
					<iframe
						src={this.url}
						width='100%'
						height='100%'
						style='border: none; background: white; border-radius: 8px;'
					/>
				</div>
			</div>
		)
	},
}
