import lo from 'lodash'
import store from '@sb/store'
import sb from '@sb/util'

export default {
	name: 'noti-list',
	props: ['show'],

	data() {
		return {
			loading: false,
			anchor: '',

			lastScrollTop: 0,
			outOfNotis: false,
			loadingMore: false,
			category: 'order,user',
		}
	},

	/*let notibody = store.matchNotis('order') || {}
		let $updatecount = null
		if (lo.get(notibody, 'unread') > 0)
		$updatecount = <div class='badge__danger order_updates_count'>{notibody.unread}</div>

		*/
	async mounted() {
		this.loading = true
		let notibody = store.matchNotis(this.category) || {}
		if (lo.size(notibody.notifications) == 0) await store.fetchNotis(this.category)
		this.loading = false
		store.onNoti(this, (cat) => {
			if (cat == this.category) this.$forceUpdate()
		})

		if (this.show) this.doSeen() // store.seenNotis(this.category)
		this._seenInterval = setInterval(() => this.doSeen(), 5000)
		this.$once('hook:beforeDestroy', () => clearInterval(this._seenInterval))
	},

	watch: {
		show(show) {
			if (show) this.doSeen() // store.seenNotis(this.category)
		},
	},

	methods: {
		async onOrderNotiClick(noti) {
			if (noti.type === 'order_commented' || noti.type === 'order_assigned') {
				let data = sb.parseJSON(noti.data) || {}
				let orderid = data.order_id
				if (!orderid) return
				let order = store.matchOrder(orderid)
				if (!order) {
					await store.fetchOrders([orderid], true)
					order = store.matchOrder(orderid)
				}
				this.order = order
				this.isOpenOrderModal = true
			}
		},

		renderTab() {
			return (
				<div class='tab oreder_list_tab' style='margin-bottom: 0px; background: #f7f5f4'>
					<div
						style='margin-left: 20px'
						class={`tab__item ${this.category == 'order,user' ? 'tab__item__active' : ''}`}
						vOn:click={(_) => this.onChangeNotiCatgory('order,user')}
					>
						{this.$t('all')}
					</div>
					<div
						class={`tab__item ${this.category == 'order' ? 'tab__item__active' : ''}`}
						vOn:click={(_) => this.onChangeNotiCatgory('order')}
					>
						{this.$t('odr')}
					</div>
					<div
						class={`tab__item ${this.category == 'user' ? 'tab__item__active' : ''}`}
						vOn:click={(_) => this.onChangeNotiCatgory('user')}
					>
						{this.$t('cust')}
					</div>
				</div>
			)
		},

		async onChangeNotiCatgory(category) {
			this.category = category
			this.loading = true
			let notibody = store.matchNotis(this.category) || {}
			if (lo.size(notibody.notifications) == 0) await store.fetchNotis(this.category)
			this.loading = false
		},

		onNotiClick(noti) {
			this.$emit('notiClick', noti)
		},

		async loadMore() {
			if (this.loading) return
			if (this.outOfNotis) return
			if (this.loadingMore) return
			this.loadingMore = true

			let out = await store.fetchMoreNotis(this.category)
			if (!out) this.outOfNotis = true

			this.loadingMore = false
			await this.$nextTick()
		},

		doSeen: lo.throttle(
			function (e) {
				if (!this.show) return
				this.category.split(',').map((category) => store.seenNotis(category))
			},
			2000,
			{leading: true, trailing: true},
		),

		onScroll: lo.throttle(
			function (e) {
				let list = e.target
				var st = list.scrollTop
				if (st > this.lastScrollTop) {
					// downscroll code
					let distToBottom = list.scrollHeight - list.scrollTop - list.clientHeight
					if (distToBottom < 500) this.loadMore()
				} else {
					// upscroll code
				}
				this.lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
			},
			200,
			{trailing: true},
		),
	},

	render() {
		let notibody = store.matchNotis(this.category) || {}
		let notis = lo.orderBy(notibody.notifications, 'created', 'desc')
		let $notis = lo.map(notis, (noti) => {
			let unread = notibody.last_seen < noti.created
			return (
				<NotiItem
					key={noti.topic + noti.created}
					extra_class={'in_list_noti'}
					noti={noti}
					vOn:click={this.onNotiClick}
					unread={unread}
				/>
			)
		})

		let $loadmore = null
		if (this.loadingMore) {
			$loadmore = (
				<div class='d-flex align-items-center justify-content-center mt-4 mb-4'>
					<Spinner size='20' mode='blue' />
				</div>
			)
		}

		let $outofnoti = null
		if (this.outOfNotis) {
			$outofnoti = (
				<div class='text__muted text-center ml-4 mr-4 pt-3 mt-3' style='border-top: 2px solid #eee'>
					{this.$t('out_of_notification')}
				</div>
			)
		}
		let $body = (
			<div class='instant_noti__menu_body' vOn:scroll={this.onScroll}>
				{$notis}
				{$outofnoti}
				{$loadmore}
			</div>
		)

		if (notis.length == 0) {
			$body = (
				<div
					class='instant_noti__menu_body align-items-center justify-content-center'
					style='display: flex; flex-direction: column;'
				>
					<img src={require('./assets/img/empty_1.svg')} width='100' height='100' />
					<div class='mt-4 text__muted'>Chưa có cập nhật mới</div>
				</div>
			)
		}

		if (this.loading)
			$body = (
				<div class='instant_noti__menu_body d-flex align-items-center justify-content-center'>
					<Spinner mode='blue' size='60' />
				</div>
			)

		return (
			<div style='overflow: hidden; flex: 1; display: flex; flex-direction: column'>
				{this.renderTab()}
				{$body}
			</div>
		)
	},
}
