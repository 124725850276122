import lo from 'lodash'
import sb from '@sb/util'
import store from '@sb/store'
import {isAdmin, getPhoneDeviceModelName, getPhoneDeviceModelImage} from './com.js'
import AgentPicker from '../../commons/agents_picker'

export default {
	name: 'edit-phone-device-modal',
	props: ['device_id'],

	data() {
		return {
			device: {},
			submitting: false,
			loading: false,
		}
	},

	watch: {
		device_id(newval) {
			if (newval) this.resetData()
		},
	},

	mounted() {
		this.loadDevice()
		window.addEventListener('keyup', this.onKeyup)
		store.onAccount(this, () => {
			this.updateStaus()
		})
	},

	destroyed() {
		window.removeEventListener('keyup', this.onKeyup)
	},

	methods: {
		allowEdit() {
			if (isAdmin()) return true
			if (this.device_id === 'new') return false

			let device = store.matchPhoneDevice()[this.device_id] || {}
			if (lo.includes(device.bind_to_agents, store.me().id)) return true

			return false
		},

		updateStaus() {
			if (!this.device.id) return

			let device = store.matchPhoneDevice()[this.device.id]
			if (!device) return

			this.device.status = device.status
			this.$forceUpdate()
		},

		async loadDevice() {
			if (!this.device_id) return
			if (this.device_id === 'new') {
				let intes = lo.filter(
					store.matchIntegration(),
					(inte) => inte.connector_type === 'call' && inte.state === 'activated',
				)
				let outboundNumber = ''
				if (lo.size(intes)) {
					outboundNumber = lo.get(intes, '0.id')
					outboundNumber = lo.split(outboundNumber, '.')
					outboundNumber = lo.get(outboundNumber, '1')
				}

				this.device = {
					password: sb.generatePassword(12),
					default_outbound_number: outboundNumber,
					bind_to_agents: [store.me().id],
				}
				return
			}

			let device = store.matchPhoneDevice()[this.device_id]
			if (!device) {
				this.loading = true
				await store.fetchPhoneDevice()
				this.loading = false
				device = store.matchPhoneDevice()[this.device_id] || {}
				device = lo.cloneDeep(device)
			}
			this.device = device
		},

		onKeyup(e) {
			switch (e.keyCode) {
				case 27: // ESC
					this.$emit('close')
					// this.toggleConvertTicket(false)
					// this.toggleAddTag(false)
					break
			}
		},

		isEditing() {
			return this.device_id && this.device_id !== 'new'
		},

		resetData() {
			this.submitting = false
			this.loading = false
			this.loadDevice()
		},

		onChangeDevice(d) {
			this.device = {...this.device, ...d}
		},

		async savePhoneDevice() {
			if (this.submitting) return

			this.submitting = true
			let device = {
				...this.device,
				disabled: this.device.disabled ? Date.now() : 0,
				_update_fields: ['name', 'description', 'default_outbound_number', 'bind_to_agents', 'disabled', 'transport_protocol'],
			}
			let {error} = await store.updatePhoneDevice(device)
			this.submitting = false
			if (error) return this.$showError(error)
			this.$showSuccess('update_sucess')
			this.$emit('submit')
			this.$emit('close')
		},

		async addPhoneDevice() {
			if (this.submitting) return

			this.submitting = true
			let device = {
				...this.device,
			}
			let {error, body} = await store.createPhoneDevice(device)
			this.submitting = false
			if (error) return this.$showError(error)
			this.device = {
				...this.device,
				id: lo.get(body, 'id'),
			}
		},

		renderStepsBar() {
			if (this.isEditing()) return null

			return (
				<div class='d-flex mt-3 mb-5'>
					<div class={{call_center_modal_step_bar: true, active: !this.device.id}} />
					<div class={{call_center_modal_step_bar: true, active: this.device.id}} />
				</div>
			)
		},

		renderCreateBody() {
			return (
				<div class='modal_content__main' style='padding: 30px'>
					<div class='d-flex align-items-center'>
						<div class='call_center_modal_header'>{this.$t('call_center.add_phone')}</div>
						<x-icon class='btn__close ml-auto' size='18' vOn:click={() => this.$emit('close')} />
					</div>
					{isAdmin() ? (
						<Fragment>
							{this.renderStepsBar()}
							{this.renderCreateStep1()}
							{this.renderCreateStep2()}
						</Fragment>
					) : (
						<Fragment>
							<div class='d-flex align-items-center justify-content-center' style='height: 150px'>
								<div class='text__muted text__center'>
									<div domPropsInnerHTML={this.$t('add_phone_none_permission_1')} />
									<div>{this.$t('add_phone_none_permission_2')}</div>
								</div>
							</div>
							<div class='text__right mt-4'>
								<button type='button' class='btn btn__light' vOn:click={() => this.$emit('close')}>
									{this.$t('close')}
								</button>
							</div>
						</Fragment>
					)}
				</div>
			)
		},

		renderCreateStep1() {
			if (this.device.id) return null
			return (
				<Fragment>
					<div style='height: 350px'>{this.renderForm()}</div>
					<div class='d-flex justify-content-end'>
						<button class='btn btn__light' vOn:click={() => this.$emit('close')}>
							{this.$t('cancel')}
						</button>
						<button
							class='btn btn__primary ml-4'
							style='width: 160px'
							disabled={this.submitting}
							vOn:click={this.addPhoneDevice}
						>
							{this.$t('start_connect')}
							{this.submitting && <Spinner class='ml-2' size='16' />}
						</button>
					</div>
				</Fragment>
			)
		},

		renderCreateStep2() {
			if (!this.device.id) return null
			return (
				<Fragment>
					<div style='height: 350px'>{this.renderInfo()}</div>
					<div class='d-flex justify-content-end'>
						<button
							class='btn btn__primary'
							vOn:click={() => {
								this.$emit('close')
								this.$emit('submit')
							}}
						>
							{this.$t('close')}
						</button>
					</div>
				</Fragment>
			)
		},

		async changePassword() {
			let cf = await this.$confirm({
				title: this.$t('device_change_password_confirm_title'),
				description: this.$t('device_change_password_confirm_desc'),
				ok: this.$t('confirm'),
				style: 'danger',
			})

			if (!cf) return
			this.device.password = sb.generatePassword(12)
			this.$forceUpdate()

			store.updatePhoneDevice({...this.device, _update_fields: ['password']})
		},

		async copyPassword() {
			if (this.device_id === 'new') {
				sb.copyToClipboard(this.device.password)
				this.$showSuccess(this.$t('copied_to_clipboard'))
				return
			}

			let {body} = await store.getPhoneDeviceDetail(this.device_id)
			let password = lo.get(body, 'password')
			if (password) {
				sb.copyToClipboard(password)
				this.$showSuccess(this.$t('copied_to_clipboard'))
			} else {
				this.$showError('Bạn không thể xem mật khẩu của máy agent khác')
			}
		},

		renderInfo() {
			let authid = `${this.device.id}.${store.me().account_id}`
			let host = this.$t('undefined')
			let publicIp = ''
			if (this.device.private_host) {
				host = sb.getIpFromHost(this.device.private_host)
				//if (this.device.port) host = `${this.device.host}:${this.device.port}`

				if (this.device.host) {
					publicIp = this.device.host
					if (this.device.port) publicIp = `${publicIp}:${this.device.port}`
				}
			}
			return (
				<Fragment>
					<div class='call_center_modal_section'>
						<div class='call_center_modal_section_title'>{this.$t('connection_info')}</div>
						<div class='d-flex mt-3'>
							<div style='flex: 3; overflow: hidden' class='no-shrink mr-4'>
								<div class='text__semibold mb-1'>SIP Server</div>
								<div class='call_center_modal_info_input'>
									<div class='text__truncate text__semibold flex__1 mr-4' title={'callcenter.subiz.com.vn'}>
										callcenter.subiz.com.vn
									</div>
									<a
										href='javascript:;'
										class='link link__success'
										style='line-height: 1'
										v-tooltip={this.$t('copy')}
										vOn:click={() => {
											sb.copyToClipboard('callcenter.subiz.com.vn')
											this.$showSuccess(this.$t('copied_to_clipboard'))
										}}
									>
										<Icon name='copy' size='15' stroke-width='2' />
									</a>
								</div>
							</div>
							<div style='flex: 2; overflow: hidden' class='no-shrink'>
								<div class='text__semibold mb-1'>Outbound Proxy</div>
								<div class='call_center_modal_info_input empty'>
									<div class='text__truncate flex__1 mr-4'>{this.$t('none')}</div>
								</div>
							</div>
						</div>
						<div class='d-flex mt-3'>
							<div style='flex: 3; overflow: hidden' class='no-shrink mr-4'>
								<div class='text__semibold mb-1'>User ID / Authentication ID</div>
								<div class='call_center_modal_info_input'>
									<div class='text__truncate text__semibold flex__1 mr-4' title={authid}>
										{authid}
									</div>
									<a
										href='javascript:;'
										class='link link__success'
										style='line-height: 1'
										v-tooltip={this.$t('copy')}
										vOn:click={() => {
											sb.copyToClipboard(authid)
											this.$showSuccess(this.$t('copied_to_clipboard'))
										}}
									>
										<Icon name='copy' size='15' stroke-width='2' />
									</a>
								</div>
							</div>
							<div style='flex: 2; overflow: hidden' class='no-shrink'>
								<div class='d-flex mb-1'>
									<div class='text__semibold'>SIP Password</div>
									{this.isEditing() && (
										<div class='ml-auto link' vOn:click={this.changePassword}>
											{this.$t('change')}
										</div>
									)}
								</div>
								<div class='call_center_modal_info_input'>
									<div class='text__truncate text__semibold flex__1 mr-4' title={this.device.password}>
										{this.device.password}
									</div>
									<a
										href='javascript:;'
										class='link link__success'
										style='line-height: 1'
										v-tooltip={this.$t('copy')}
										vOn:click={this.copyPassword}
									>
										<Icon name='copy' size='15' stroke-width='2' />
									</a>
								</div>
							</div>
						</div>
						<div class='d-flex mt-3'>
							<div style='flex: 3; overflow: hidden' class='no-shrink mr-4'>
								<div class='text__semibold mb-1'>{this.$t('protocol')}</div>
								<select
									class='form-control form-control__light'
									value={this.device.transport_protocol || 'udp'}
									vOn:change={(e) => this.onChangeDevice({transport_protocol: e.target.value})}
									style='background-color: #eee'
								>
									<option value='udp'>UDP</option>
									<option value='tcp'>TCP</option>
								</select>
							</div>
							<div style='flex: 2; overflow: hidden' class='no-shrink'></div>
						</div>
					</div>
					<div class='call_center_modal_section'>
						<div class='call_center_modal_section_title mb-2'>{this.$t('connection_status')}</div>
						{!this.device.status || this.device.status === 'unavailable' ? (
							<div class='d-flex'>
								<div class='dot dot__secondary dot__lg' style='margin-top: 0; background: #ffc107;' />
								<div class='ml-3 text__muted blinking' style='position: relative; top: -6px; height: 50px'>
									{this.$t('waiting_connect')}
								</div>
							</div>
						) : (
							<div class='d-flex'>
								<div class='dot dot__success dot__lg' style='margin-top: 0' />
								<div class='ml-3' style='position: relative; top: -6px; height: 50px'>
									<div>{this.$t('connected')}</div>
									<div class='text__muted'>
										IP: {host} {publicIp && <Icon name='help' size='16' v-tooltip={`Public IP: ${publicIp}`} />}
									</div>
								</div>
							</div>
						)}
					</div>
				</Fragment>
			)
		},

		renderForm() {
			let intes = lo.filter(
				store.matchIntegration(),
				(inte) => inte.connector_type === 'call' && inte.state === 'activated',
			)
			let inteItems = lo.map(intes, (inte) => {
				let number = lo.split(inte.id, '.')
				number = lo.get(number, '1', '')

				return {
					id: number,
					label: sb.getNumberDisplayName(inte),
					img: inte.logo_url,
				}
			})
			inteItems.unshift({
				id: '',
				label: this.$t('any'),
				img: require('../../assets/img/unassigned.png'),
			})
			let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent' && agent.state === 'active')
			let picked = lo.map(this.device.bind_to_agents, (id) => lo.get(store.matchAgent(), id, {}))
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				label: sb.getAgentDisplayName(agent),
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
			}))
			return (
				<Fragment>
					<div class='call_center_modal_section'>
						<div class='call_center_modal_section_title mb-1'>{this.$t('device_name')}</div>
						<input
							class='form-control'
							value={lo.get(this.device, 'name')}
							vOn:input={(e) => this.onChangeDevice({name: e.target.value})}
						/>
					</div>
					<div class='call_center_modal_section'>
						<div class='call_center_modal_section_title'>
							{this.$t('device_phone_outbound')}
							<Icon
								name='help'
								size={15}
								class='ml-2 text__muted'
								v-tooltip={this.$t('device_phone_outbound_any_desc')}
							/>
						</div>
						<div class='call_center_modal_section_subtitle mb-1'>{this.$t('device_phone_outbound_desc')}</div>
						<Dropdown
							items={inteItems}
							mode='input'
							selected={this.device.default_outbound_number || ''}
							vOn:select={(item) => this.onChangeDevice({default_outbound_number: item.id})}
						/>
					</div>
					<div class='call_center_modal_section'>
						<div class='call_center_modal_section_title'>{this.$t('bind_to_agents')}</div>
						<div class='call_center_modal_section_subtitle mb-1'>{this.$t('bind_to_agents_desc')}</div>
						<Dropdown
							mode='input'
							items={items}
							selected={lo.get(picked, '0.id')}
							vOn:select={(item) => this.onChangeDevice({bind_to_agents: [item.id]})}
						/>
					</div>
				</Fragment>
			)
		},

		renderPhoneModel() {
			return (
				<div class='call_center_modal_section'>
					<div class='call_center_modal_section_title mb-1'>{this.$t('phone_model')}</div>
					<div class='d-flex align-items-center'>
						<img2 src={getPhoneDeviceModelImage(this.device)} style='width: 20px; height: 20px; border-radius: 4px' />
						<div class='ml-2'>{getPhoneDeviceModelName(this.device)}</div>
					</div>
				</div>
			)
		},

		renderEditBody() {
			return (
				<div class='modal_content__main' style='padding: 15px 20px'>
					<div class='d-flex align-items-center mb-4'>
						<div class='call_center_modal_header'>{this.$t('call_center.edit_phone')}</div>
						<x-icon class='btn__close ml-auto' size='18' vOn:click={() => this.$emit('close')} />
					</div>
					{this.allowEdit() ? (
						<Fragment>
							{this.renderForm()}
							{this.renderPhoneModel()}
							{this.renderInfo()}
							<div class='d-flex align-items-center'>
								<Sw
									checked={!this.device.disabled}
									green
									height={20}
									vOn:change={(checked) => this.onChangeDevice({disabled: !checked})}
								/>
								{this.device.disabled ? (
									<span class='mt-1 ml-2 text__muted'>{this.$t('off_status')}</span>
								) : (
									<span class='mt-1 ml-2 text__success'>{this.$t('on_status')}</span>
								)}
								<button type='button' class='ml-auto btn btn__light' vOn:click={() => this.$emit('close')}>
									{this.$t('cancel')}
								</button>
								<button
									type='button'
									disabled={this.submitting}
									class='ml-4 btn btn__primary'
									style='width: 140px'
									vOn:click={this.savePhoneDevice}
								>
									{this.$t('save_changes')}
									{this.submitting && <Spinner class='ml-2' size='16' />}
								</button>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div class='d-flex align-items-center justify-content-center' style='height: 100px'>
								<em class='text__muted'>{this.$t('you_cannot_edit_other_agent_device')}</em>
							</div>
							<div class='text__right mt-4'>
								<button type='button' class='btn btn__light' vOn:click={() => this.$emit('close')}>
									{this.$t('close')}
								</button>
							</div>
						</Fragment>
					)}
				</div>
			)
		},
	},

	render() {
		let cls = 'modal'
		if (!this.device_id) cls += ' modal__hide'

		let isEditing = this.isEditing()
		let $errorname = null
		if (this.error == 'name') $errorname = <small class='text__danger'>{this.$t('name_cannot_empty')}</small>
		let $errorextension = null
		if (this.error === 'extension')
			$errorextension = <small class='text__danger'>{this.$t('extension_only_number')}</small>

		let $btn = this.$t('create')
		if (isEditing) $btn = this.$t('save_changes')

		return (
			<div class={cls}>
				<div class='modal__overlay' vOn:click={() => this.$emit('close')} />
				<div class='modal__container'>
					<div class='modal__background' style='max-height: 90vh; overflow-y: auto'>
						<div class='modal_content' style='width: 500px; position: relative;'>
							{this.isEditing() ? this.renderEditBody() : this.renderCreateBody()}
						</div>
					</div>
				</div>
			</div>
		)
	},
}
