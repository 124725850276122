import Quill from 'quill'
const Embed = Quill.import('blots/embed')

export default class DynamicField extends Embed {
	static create(value) {
		let node = super.create()
		node.setAttribute('class', 'dynamic-field')
		node.setAttribute('data-value', value.value)
		node.setAttribute('data-id', value.id)
		node.setAttribute('data-key', value.key)
		node.setAttribute('data-type', DynamicField.blotName)
		// last space helps backspace delete dynamic field
		node.innerHTML = ` ${value.value} `
		return node
	}
	static value(element) {
		let id = element.getAttribute('data-id')
		let value = element.getAttribute('data-value')
		let key = element.getAttribute('data-key')
		return {id, value, key}
	}
}
