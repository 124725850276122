const lo = require('lodash')

export default {
	name: 'browsers-picker',
	props: ['picked_browsers', 'up'],

	render () {
		let pickedchannels = this.picked_browsers
		if (isAllChannel(pickedchannels)) pickedchannels = ALL_CHANNELS
		let all = lo.size(this.picked_browsers) === 0
		if (!all) all = lo.find(this.picked_browsers, ch => ch === '*' || ch === 'all')
		if (all) pickedchannels = ALL_CHANNELS
		const $channels = lo.map(ALL_CHANNELS, ch => {
			let picked = lo.includes(pickedchannels, ch)
			return this.renderChannelTypeImg(ch, picked)
		})

		return <div class="d-flex flex-wrap">{$channels}</div>
	},

	methods: {
		renderChannelTypeImg (channel, picked) {
			let $x = (
				<XIcon
					stroke-width="3"
					class="channel_picker__channel__remove_btn"
					vOn:click={_ => this.removeChannel(channel)}
				/>
			)
			let $detail = null
			let cls = 'channel_picker__channel'
			if (!picked) {
				$x = null
				cls += ' channel_picker__channel__disabled'
			}

			if (channel === 'chrome') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('chrome')}>
						{$x}
						<img
							vOn:click={_ => this.addChannel('chrome')}
							src={require('../assets/img/chrome.svg')}
							style="width: 30px;"
							class="mr-3"
						/>
					</div>
				)
			}

			if (channel === 'firefox') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('firefox')}>
						{$x}
						<img
							vOn:click={_ => this.addChannel('firefox')}
							src={require('../assets/img/firefox.svg')}
							style="width: 30px"
							class="mr-3"
						/>
					</div>
				)
			}

			if (channel === 'safari') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('safari')}>
						{$x}
						<img
							vOn:click={_ => this.addChannel('safari')}
							src={require('../assets/img/safari.svg')}
							style="width: 30px"
							class="mr-3"
						/>
					</div>
				)
			}

			if (channel === 'edge') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('edge')}>
						{$x}
						<img
							vOn:click={_ => this.addChannel('edge')}
							src={require('../assets/img/edge.svg')}
							style="width: 30px"
							class="mr-3"
						/>
					</div>
				)
			}

			if (channel === 'ie') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('ie')}>
						{$x}
						<img
							vOn:click={_ => this.addChannel('ie')}
							src={require('../assets/img/internet-explorer.svg')}
							style="width: 30px"
							class="mr-3"
						/>
					</div>
				)
			}
			return $detail
		},

		addChannel (channel) {
			if (isAllChannel(this.picked_browsers)) return
			let picked_browsers = lo.filter(this.picked_browsers, c => c !== channel)
			picked_browsers.unshift(channel)
			this.$emit('change', picked_browsers)
		},

		removeChannel (channel) {
			let picked_browsers = this.picked_browsers
			if (isAllChannel(this.picked_browsers)) picked_browsers = ALL_CHANNELS
			picked_browsers = lo.filter(picked_browsers, c => c !== channel)
			if (lo.size(picked_browsers) === 0) return
			this.$emit('change', picked_browsers)
		},
	},
}

const ALL_CHANNELS = ['chrome', 'firefox', 'ie', 'safari', 'edge']

function isAllChannel (chans) {
	let all = lo.size(chans) === 0
	if (!all) all = lo.find(chans, ch => ch === '*' || ch === 'all')
	return !!all
}
