// DEPRECATED: this file is going to be removed
// DO NOT ADD MORE CODE TO THIS FILE
// DO NOT ADD MORE CODE TO THIS FILE!
// DO NOT ADD MORE CODE TO THIS FILE!!!
// @thanh
import {format, utcToZonedTime} from 'date-fns-tz'
import lo from 'lodash'
import store from '@sb/store'
import {i18n} from './languages.js'

const sb = require('@sb/util')

export const getAttributeName = (attr) => {
	if (!attr) return ''
	let key = attr.key
	if (!key) return ''
	if (key.startsWith('attr:')) key = key.substr(5)
	return i18n.i18nText(attr.i18n_label) || attr.label || attr.name
}

//format date even in wrong format
export function tryFormat(trueUnixMs, fm, timezone) {
	if (!timezone) timezone = getLocalTimeZone()

	if (typeof trueUnixMs === 'number') trueUnixMs = sb.getMs(trueUnixMs)
	if (trueUnixMs instanceof Date) trueUnixMs = trueUnixMs.getTime()
	let dateInTz = utcToZonedTime(new Date(trueUnixMs), timezone)

	fm = fm.replace('YYYY', 'yyyy').replace('DD', 'dd')
	try {
		return format(dateInTz, fm)
	} catch (e) {
		return format(dateInTz, 'dd/MM/yyyy')
	}
}

function getLocalTimeZone() {
	var offset = new Date().getTimezoneOffset()
	var o = Math.abs(offset)
	return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2)
}

export function getTimezoneMinutes() {
	let tz = sb.getTimezone()
	let accountTimezone = lo.get(store.me(), 'account.timezone')
	let agentTimezone = lo.get(store.me(), 'timezone')

	if (accountTimezone) tz = accountTimezone
	if (agentTimezone) tz = agentTimezone

	return sb.offsetToMin(tz)
}

export const UNUSED_USER_ATTRIBUTES = [
	'last_message_sent',
	'chat_with',
	'first_interact',
	'_unsubscribed',
	'created',
	'desktop_notify_subscribed',
	'focused',
	'modified',
	'seen',
	'total_assigned_conversations',
	'total_conversations',
	'total_open_tickets',
	'total_sessions',
	'trace_city_name',
	'trace_country_code',
	'trace_country_name',
	'ga_client_id',
	'last_message_sent',
	'first_channel',
	'latest_channel',
	'first_session_source',
	'first_session_referer',
	// 'first_session_tracking_link',
	'lastest_session_source',
	'latest_session_referer',
	'latest_session_tracking_link',
	'first_channel_touchpoint',
	'latest_channel_touchpoint',
	'first_paid_order_id',
	'is_ban',
	'interacted',
	'num_orders',
	'total_order_value',
	'external_id',
]

export const LEAD_UNUSED_USER_ATTRIBUTES = [
	'chat_with',
	'first_interact',
	'_unsubscribed',
	'desktop_notify_subscribed',
	'focused',
	'modified',
	'total_open_tickets',
	'total_sessions',
	'trace_city_name',
	'trace_country_code',
	'trace_country_name',
	'ga_client_id',
	'is_ban',
	'interacted',
	'num_orders',
	'external_id',
	'first_channel',
	'latest_channel',
	'first_session_source',
	'first_session_referer',
	'first_session_tracking_link',
	'lastest_session_source',
	'latest_session_referer',
	'latest_session_tracking_link',
	'first_channel_touchpoint',
	'latest_channel_touchpoint',
	'first_paid_order_id',
]

export function isGroupIdNormal(id = '') {
	return !id.startsWith('gr84')
}

export function convertUserLabelToLabelDefination(ulabel) {
	let label = lo.cloneDeep(ulabel)
	// label do have data from server
	if (label.name) {
		// try to convert to UserLabel object
		label = {
			...label,
			title: label.name,
			id: label.label,
		}
	} else {
		label = store.matchUserLabel()[ulabel.label]
	}
	return label
}

// cid should pass to display incomming phonenumber for unknown_visitor
export function resolveUserDisplayNameInConvo(user, cid) {
	if (!user) return ''

	user = store.matchUser(user.id, true) || {}

	let shouldDisplayPrimaryName = user.channel === 'email' || user.channel === 'call'
	let contact = store.matchUser(user.primary_id)
	if (!contact) contact = user

	if (!shouldDisplayPrimaryName) return sb.getUserDisplayName(user)
	if (user.channel === 'call') {
		if (!cid) {
			return sb.getUserDisplayName(contact)
		}

		let name = sb.getUserTextAttr(contact, 'fullname') || sb.getUserTextAttr(contact, 'display_name')
		if (!name && cid) {
			let convo = store.matchConvo(cid)
			let direction = lo.get(convo, 'call.direction')
			if (direction === 'inbound') {
				name = lo.get(convo, 'call.from_number', '')
				return sb.displayPhoneNumber(name)
			} else if (direction === 'outbound') {
				name = lo.get(convo, 'call.to_number', '')
				return sb.displayPhoneNumber(name)
			}
			return sb.getUserDisplayName(contact)
		}
	}
	return sb.getUserDisplayName(contact)
}
