/**
 * @param {MediaStream} stream
 * @param {String} url: maybe Blob, if stream and url both exists, prefer stream
 * @param {String} bgColor: hex or rgb code, use to paint canvas background, default is black
 * @param {String} graphColor: hex or rgb code, use to paint canvas graph, not supported gradient yet
 */

import lo from 'lodash'
export default {
	name: 'sound-waveform',
	// input is a stream
	props: ['stream', 'url', 'bgColor', 'graphColor'],
	watch: {
		stream() {
			this.drawCanvas()
		},
	},

	beforeDestroy() {
		window.cancelAnimationFrame(this.requestAnimationFrameId)
		this.analyser = undefined
	},

	mounted() {
		this.drawCanvas()
	},

	methods: {
		drawCanvas() {
			if (!this.stream && !this.url) return

			let context = new AudioContext()
			let src = ''
			if (this.stream) {
				src = context.createMediaStreamSource(this.stream)
			} else {
				let audio = new Audio()
				audio.volume = 0.0
				if (this.url) {
					audio.src = this.url
					audio.load()
					audio.play()
				}
				src = context.createMediaElementSource(audio)
			}
			let analyser = context.createAnalyser()

			// do not set this, this would cause echo from micro
			// analyser.connect(context.destination)

			analyser.fftSize = 256
			src.connect(analyser)
			this.analyser = analyser
			this.renderFrame(analyser)
		},

		renderFrame(analyser) {
			if (!analyser) return
			let canvas = this.$refs.canvas
			// canvas.width = window.innerWidth
			// canvas.height = window.innerHeight
			let ctx = canvas.getContext('2d')
			let bufferLength = analyser.frequencyBinCount
			let dataArray = new Uint8Array(bufferLength)
			let WIDTH = canvas.width
			let HEIGHT = canvas.height
			let barWidth = WIDTH / bufferLength / 2
			//let times = 0

			const renderFrame = () => {
				if (this.analyser != analyser || !analyser) return // outdated
				//times += 1
				//if (times >= 100) return
				this.requestAnimationFrameId = window.requestAnimationFrame(renderFrame)
				let x = 0
				analyser.getByteFrequencyData(dataArray)
				ctx.fillStyle = this.bgColor || '#000'
				ctx.fillRect(0, 0, WIDTH, HEIGHT)
				for (let i = 0; i < bufferLength; i++) {
					let barHeight = dataArray[i] / 2
					let r = barHeight + 25 * (i / bufferLength)
					let g = 250 * (i / bufferLength)
					let b = 50
					ctx.fillStyle = this.graphColor || 'rgb(' + r + ',' + g + ',' + b + ')'
					ctx.fillRect(WIDTH / 2 - x / 2, (HEIGHT - barHeight) / 2, barWidth, barHeight)
					ctx.fillRect(WIDTH / 2 + x / 2, (HEIGHT - barHeight) / 2, barWidth, barHeight)
					x += barWidth + 1
				}
			}
			renderFrame()
		},
	},

	render() {
		return <canvas ref='canvas' />
	},
}
