import store from '@sb/store'
import sb from '@sb/util'
import {changeLanguage} from './languages.js'
import tracking from './tracking.js'
import VueRouter from 'vue-router'
// import Vue from 'vue'
Vue.use(VueRouter)

const SettingNotification = () => import('./profile/notification.js')
const ZaloList = () => import('./settings/zalo.js')
const Endchat = () => import('./settings/endchat.js')
const Label = () => import('./settings/label.js')
const LeadSetting = () => import('./settings/leadsetting.js')
const ListTemplate = () => import('./profile/message-template/listTemplate.js')
const Referral = () => import('./profile/referral/index.js')

import MainLayout from './main_layout.js'

const newreports = {
	path: '/new-reports',
	component: () => import('./reports/report.js'),
	meta: {menus: ['reports']},
	name: 'newreport',
	children: [
		{path: 'call', name: 'newreports.call', component: () => import('./reports/report_call.js')},
		{
			path: 'call-list',
			name: 'newreports.call-list',
			component: () => import('./reports/report_call_list.js'),
			children: [{path: 'me', name: 'newreports.my-call-list'}],
		},
		{path: 'convo', name: 'newreports.convo', component: () => import('./reports/report_convo.js')},
		{
			path: 'convo-list',
			name: 'newreports.convo-list',
			component: () => import('./reports/report_convo_list.js'),
			children: [{path: 'me', name: 'newreports.my-convo-list'}],
		},
		{
			path: 'sales',
			name: 'newreports.sale',
			component: () => import('./reports/report_sale'),
			children: [{path: 'me', name: 'newreports.my-sales'}],
		},
		{
			path: 'agent',
			name: 'newreports.agent',
			component: () => import('./reports/report_agent.js'),
			children: [{path: 'me', name: 'newreports.me'}],
		},
		{path: 'user', name: 'newreports.user', component: () => import('./reports/report_new.js')},
	],
}

const settings = {
	path: '/settings',
	component: () => import('./settings/setting_layout.js'),
	children: [
		{path: '/', name: 'settings.general', component: () => import('./settings/general.js')},
		{path: 'payment', name: 'settings.payment', component: () => import('./settings/payment.js')},
		{path: 'subscription-info', name: 'settings.subscription-info', component: () => import('./settings/payment-info.js')},
		{path: 'marketing-credit', name: 'settings.marketing-credit', component: () => import('./settings/marketing_credits.js')},
		{
			path: 'business-hours',
			name: 'settings.business-hours',
			component: () => import('./settings/business-hours/BusinessHours.vue'),
		},
		{path: 'agents', name: 'settings.agents', component: () => import('./settings/agent/agent.js')},
		{
			path: 'agents-group',
			name: 'settings.agents-group',
			component: () => import('./settings/agent/group.js'),
			children: [{path: '/settings/agent-group/new', name: 'settings.agents-group-add'}],
		},
		{
			path: 'blacklist-ips',
			name: 'settings.blacklist-ips',
			component: () => import('./settings/blacklist.js'),
			children: [
				{path: 'blacklist-ips/new', name: 'add-blacklist-ip'},
				{path: 'email', name: 'settings.blacklist-email'},
				{path: 'phone', name: 'settings.blacklist-phone'},
				{path: 'bounced-email', name: 'settings.blacklist-bounced-email'},
			],
		},
		{path: 'rule-setting', name: 'settings.rule-setting', component: () => import('./settings/rule/listRule.js')},
		{path: 'add-rule', name: 'settings.add-rule', component: () => import('./settings/rule/detailRule.js')},
		{path: 'email', name: 'settings.email', component: () => import('./settings/email/emails.js')},
		{path: 'email-add', name: 'settings.email-add', component: () => import('./settings/email/emails.js')},
		{path: 'email-dkim', name: 'settings.email-dkim', component: () => import('./settings/email/emails.js')},
		{path: 'dkim-add', name: 'settings.dkim-add', component: () => import('./settings/email/emails.js')},
		{path: 'add-email', name: 'settings.add-email', component: () => import('./settings/email/emails.js')},
		{
			path: 'website',
			name: 'settings.website',
			component: () => import('./settings/website.js'),
			children: [{path: '/website/install', name: 'site-install'}],
		},
		{path: 'messenger', name: 'settings.messenger', component: () => import('./settings/facebook.js')},
		{path: 'instagram', name: 'settings.instagram', component: () => import('./settings/instagram.js')},
		{path: 'zalo', name: 'settings.zalo', component: ZaloList},
		{path: 'google-review', name: 'settings.google', component: () => import('./settings/google_review.js')},
		{path: 'facebook-comment', name: 'settings.facebook-comment', component: () => import('./settings/facebook.js')},
		{path: 'auto-endchat', name: 'settings.auto-endchat', component: Endchat},
		{
			path: 'label',
			name: 'settings.label',
			component: Label,
			children: [{path: '/settings/label/new', name: 'add-label'}],
		},
		{path: 'lead', name: 'settings.lead', component: LeadSetting},
		{
			path: '/language',
			component: () => import('./settings/language.js'),
			children: [
				{path: '/', name: 'settings.language', component: () => import('./settings/language.js')},
				{path: 'translate', name: 'settings.language.translate', component: () => import('./settings/language.js')},
			],
		},

		{path: 'translation_def', name: 'settings.translation_def', component: () => import('./settings/language_def.js')},
		{
			path: 'tags',
			name: 'settings.tags',
			component: () => import('./settings/tags.js'),
			children: [{path: '/settings/tags/new', name: 'add-tag'}],
		},
		{
			path: 'order-tags',
			name: 'settings.order-tags',
			component: () => import('./settings/order_tags'),
			children: [{path: '/settings/order-tags/new', name: 'add-order-tag'}],
		},
		{
			path: 'user-attributes',
			name: 'settings.user-attributes',
			component: () => import('./settings/user-attribute/list.js'),
			children: [
				{path: 'new', name: 'add-attr'},
				{path: 'edit/:key', name: 'edit-attr'},
			],
		},
		{
			path: 'display-attributes',
			name: 'settings.display-attributes',
			component: () => import('./settings/custom_user_attributes.js'),
		},
		{
			path: 'webhooks',
			name: 'settings.webhook',
			component: () => import('./settings/webhook/list_webhooks.js'),
		},
		{
			path: 'webhooks/:webhookid',
			name: 'settings.webhook-detail',
			component: () => import('./settings/webhook/detail_webhook.js'),
		},
		{path: 'web-monitor', name: 'settings.web-monitor-list', component: () => import('./settings/web_monitor/list.js')},
		{
			path: 'web-monitor/:id/report',
			name: 'settings.web-monitor-report',
			component: () => import('./settings/web_monitor/report'),
		},
		{path: 'payment-methods', name: 'payment-method-list', component: () => import('./order/payment_methods')},
		{path: 'shipping-settings', name: 'shipping-settings', component: () => import('./order/shipping-settings.js')},

		{path: 'pos', name: 'pos-list', component: () => import('./product/pos.js')},
		{path: 'taxes', name: 'tax-list', component: () => import('./product/taxes.js')},
		{
			path: 'cancellation-codes',
			name: 'cancellation-codes',
			component: () => import('./settings/cancellation_code.js'),
		},
		{path: 'currencies', name: 'currency-list', component: () => import('./product/currencies.js')},
		{path: 'pipelines', name: 'pipeline-list', component: () => import('./pipeline/pipeline_list.js')},
		{path: 'pipeline-new', name: 'pipeline-new', component: () => import('./pipeline/pipeline_new.js')},
		{path: 'pipeline/:id/edit', name: 'pipeline-edit', component: () => import('./pipeline/pipeline_edit.js')},
		{
			path: 'pipeline/:pid/stage/:sid/edit',
			name: 'pipeline-stage-edit',
			component: () => import('./pipeline/pipeline_stage_edit.js'),
		},
		{
			path: 'conversation-modal',
			name: 'settings.conversation-modal',
			component: () => import('./settings/conversation_modals.js'),
		},
		{
			path: 'call-center',
			name: 'settings.call-center',
			component: () => import('./settings/call-center/call-center.js'),
		},
	],
}

const billing = {path: '/billing', name: 'billing', component: () => import('./payment/payment')}
const invoice = {path: '/invoices', name: 'invoices', component: () => import('./payment/invoices')}
const subscription = {
	path: '/subscriptions',
	name: 'subscriptions-page',
	component: () => import('./payment/subscription'),
}

const profile = {
	path: '/profile',
	component: () => import('./profile/layout.js'),
	children: [
		{path: '/', name: 'profile.self', component: () => import('./profile/self.js')},
		{path: '/security', name: 'profile.security', component: () => import('./profile/security.js')},
		{path: '/apikey', name: 'profile.apikey', component: () => import('./profile/apikey.js')},
		{
			path: '/profile/setting-notification',
			name: 'profile.setting-notification',
			component: SettingNotification,
		},
		{
			path: '/message-template',
			name: 'message-template',
			redirect: (to) => {
				return {path: '/profile', query: {message_template: true}}
			},
		},
		{path: '/referral', name: 'referral', component: Referral},
	],
}

const routes = [
	{
		path: '/welcome',
		component: () => import('./onboarding/onboarding.js'),
		children: [
			{path: '/', name: 'onboarding', component: () => import('./onboarding/onboarding.js')},
			{path: '/welcome/chatbox', name: 'onboarding.chatbox', component: () => import('./onboarding/onboarding.js')},
			{
				path: '/welcome/chatbox_install',
				name: 'onboarding.chatbox_install',
				component: () => import('./onboarding/onboarding.js'),
			},
			{path: '/chatbox_domain', name: 'onboarding.chatbox_domain', component: () => import('./live/live.js')},
			{path: '/chatbox_code', name: 'onboarding.chatbox_code', component: () => import('./live/live.js')},
			{path: '/zalo', name: 'onboarding.zalo', component: () => import('./live/live.js')},
			{path: '/messenger', name: 'onboarding.messenger', component: () => import('./live/live.js')},
		],
	},
	{
		path: '/activities/:uid?',
		redirect: {name: 'activities'}, // redirect old url
		children: [{path: 'convo/:cid', redirect: (to) => ({name: 'activities', query: to.params})}],
	},
	{
		path: '/',
		redirect: {name: 'activities'},
		name: 'main-layout',
		component: MainLayout,
		children: [
			{
				path: '/accmgr',
				component: () => import('./accmgr/accmgr.js'),
				children: [
					{path: '/', name: 'accmgr', component: () => import('./accmgr/accmgr.js')},
					{path: 'metric', name: 'accmgrmetric', component: () => import('./accmgr/accmgr.js')},
				],
			},
			{
				path: '/live',
				component: () => import('./live/live.js'),
				children: [
					{path: '/', name: 'liveoverview', component: () => import('./live/live.js')},
					{path: 'view', name: 'liveview', component: () => import('./live/live.js')},
				],
			},
			{path: 'convo', name: 'activities', component: () => import('./activities/activities.js')},
			{path: 'lead2', name: 'lead2', redirect: {name: 'lead'}},
			{path: 'lead', name: 'lead', component: () => import('./lead/leads.js')},
			{path: 'segment', name: 'segment', component: () => import('./lead/segment.js')},
			{
				path: 'campaign-list',
				name: 'campaign-list',
				component: () => import('./campaign/campaign_list.js'),
				children: [
					{
						path: 'add-campaign',
						name: 'add-campaign',
					},
				],
			},
			{
				path: 'telesale-list',
				name: 'telesale-list',
				component: () => import('./campaign/telesale_list.js'),
			},
			{
				path: 'campaign/:id/report',
				name: 'edit-campaign-report',
				component: () => import('./campaign/campaign_report.js'),
			},
			{
				path: 'telesale/:id/report',
				name: 'telesale-campaign-report',
				component: () => import('./campaign/telesale_report.js'),
			},
			{
				path: 'telesale-campaign/:id/execute',
				name: 'telesale-campaign-execute',
				component: () => import('./campaign/telesale_campaign_execute.js'),
			},
			{path: 'web_plugin', name: 'web_plugin', component: () => import('./web_plugins/web_plugins.js')},
			{path: 'web_plugin/create', name: 'web-plugin-create', component: () => import('./web_plugins/wp_create.js')},
			{
				path: 'chatbox',
				name: 'chatbox',
				component: () => import('./web_plugins/chatbox/chatbox.js'),
				children: [
					{path: 'design', name: 'chatbox-design'},
					{path: 'condition', name: 'chatbox-condition'},
				],
			},
			{
				path: 'web_plugin/popup/:id',
				name: 'popup',
				component: () => import('./web_plugins/popup/popup.js'),
				children: [
					{path: 'design', name: 'popup-design'},
					{path: 'design-2', name: 'popup-design-2'},
					{path: 'condition', name: 'popup-condition'},
					{path: 'report', name: 'popup-report'},
					{path: 'meta', name: 'popup-meta'},
				],
			},
			{
				path: 'web_plugin/notification/:id',
				name: 'notification',
				component: () => import('./web_plugins/notification/notification.js'),
				children: [
					{path: 'design', name: 'notif-design'},
					{path: 'condition', name: 'notif-condition'},
					{path: 'report', name: 'notif-report'},
					{path: 'meta', name: 'notif-meta'},
				],
			},
			{
				path: 'bot/:id',
				name: 'bot',
				component: () => import('./bot/bot.js'),
				children: [
					{path: 'build', name: 'bot-build'},
					{path: 'target', name: 'bot-target'},
					{path: 'setting', name: 'bot-setting'},
					{path: 'report', name: 'bot-report'},
					{path: 'template/edit', name: 'bot-template-edit'},
					{path: 'conditions', name: 'bot-condition'},
				],
			},
			{path: 'bots', name: 'bot-list', component: () => import('./bot/bot_list.js')},
			{path: 'bot-not-permission', name: 'bot-permission', component: () => import('./bot/bot_permission.js')},
			{path: 'bot-clone', name: 'bot-clone', component: () => import('./bot/bot_clone')},
			{path: 'bot-blank-clone', name: 'bot-blank-clone', component: () => import('./bot/bot_clone_blank')},
			settings,
			{
				path: 'products',
				name: 'product-list',
				component: () => import('./product/product_list'),
			},
			{
				path: 'product-collections',
				name: 'product-collection-list',
				component: () => import('./product/product_collection_list'),
			},
			{path: 'orders', name: 'order-list', component: () => import('./order/order_list.js')},
			profile,
			newreports,
			billing,
			invoice,
			subscription,
			//{path: 'products', name: 'product-list', component: () => import('./product/product_list.js')},
			//{path: '/product/new', name: 'product-new', component: () => import('./product/product_new.js')},
			//{path: '/products/:id', name: 'product-edit', component: () => import('./product/product_edit.js')},
		],
	},
	{path: '/logout', name: 'logout', component: () => import('./Login.js')},
	{path: '/login', name: 'login', component: () => import('./Login.js')},
	{path: '/register', name: 'register', component: () => import('./Register.js')},
	{path: '/forgot', name: 'forgot', component: () => import('./Forgot.js')},
	{path: '/recover-password', name: 'recover-password', component: () => import('./RecoverPassword.js')},
	{
		path: '/agent-accept-invitation',
		name: 'agent-accept-invitation',
		component: () => import('./AgentAcceptInvitation.js'),
	},
	{path: '/error', name: 'error', component: () => import('./error.js')},
	{path: '/delete-account', name: 'delete-account', component: () => import('./deleteAccount.js')},
	{path: '/lexical', name: 'lexical', component: () => import('./lexical-test/lexical.js')},
	{path: '*', name: 'not-found', component: () => import('./404.js')},
]

export const router = new VueRouter({routes, mode: 'history'})

const ANONYMOUSLY = [
	'login',
	'logout',
	'register',
	'register-success',
	'forgot',
	'forgot-success',
	'recover-password',
	'active-account',
	'agent-accept-invitation',
	'agent-accept-invitation-success',
	'bot-template-list',
	'bot-template-detail',
	'not-found',
	'error',
	'delete-account',
]

// keep lang query accross all routes
router.beforeEach((to, from, next) => {
	if (to.path === from.path) return next()
	if (from.query.lang && to.query.lang) return next()
	if (from.query.lang) return next({path: to.path, query: {lang: from.query.lang}})
	next()
})

router.beforeEach((to, from, next) => {
	tracking.trackRoute(to)

	if (
		sb.mobilecheck() &&
		!['error', 'not-found', 'register', 'login', 'logout', 'forgot', 'delete-account'].includes(to.name)
	)
		next({name: 'error'})

	// Dont know why have to change language each time router pass. Temporary comment this logic
	let me = store.me()
	//changeLanguage(me.lang)

	// if (!to.matched.length) return next({name: 'main-layout', }) // https://github.com/vuejs/vue-router/issues/977

	let publicPath = to.matched.some((m) => ANONYMOUSLY.indexOf(m.name) >= 0)
	if (publicPath) return next()
	if (me && me.id) {
		// already login

		// but is owner and havenot complete onboarding
		// if (!to.name.startsWith('onboarding')) return next({name: 'onboarding', query: {redirect: to.fullPath}})

		return next()
	}

	// must redirect to login if the agent is not logged in
	return next({name: 'login', query: {redirect: to.fullPath}})
})

router.beforeEach((to, from, next) => {
	if (store.beginLoadRoute) store.beginLoadRoute()
	next()
})

router.afterEach(() => store.endLoadRoute && store.endLoadRoute())
