let sb = require('@sb/util')
const lo = require('lodash')
import ListField from './listField.js'
import store from '@sb/store'

export default {
	props: ['form', 'ev'],

	render() {
		let attM = store.matchUserAttribute()
		let fields = lo.get(this.form, 'fields') || []
		let formData = lo.map(fields, (field) => {
			let is_required = field.is_required || false

			var state = sb.parseJSON(field.state) || {}
			if (!is_required) is_required = state.require || false

			return {
				name: field.name,
				answered: field.answered,
				items: field.items,
				value: sb.parseJSON(field.answer) || sb.parseJSON(field.default_answer),
				is_required,
				label: field.title,
				type: field.input_type || lo.get(attM, [field.name, 'type'], 'text'),
			}
		})

		if (lo.size(formData) === 0) return null
		let $fields = this.renderFields(formData)

		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		// dont use <form /> , hit enter on this input cause page to reloate <form><input type="text"/></form>
		// see https://stackoverflow.com/questions/1370021/why-does-forms-with-single-input-field-submit-upon-pressing-enter-key-in-input
		return (
			<div class={`message_form message_form__${byType}`} vOn:click_stop={() => {}}>
				{this.$t(this.form.title) || this.$t('capture_leads_form')}
				{$fields}
			</div>
		)
	},

	methods: {
		renderFields(formData) {
			if (lo.size(formData) === 0) return null
			let $fields = null

			$fields = lo.map(formData, (field, index) => {
				let $field = null
				if (field.type === 'text') {
					$field = (
						<div class='form-control--container'>
							<input disabled value={field.value} class='form-control' type='text' />
							<i class='form-control--check'></i>
						</div>
					)
				} else if (field.type === 'number') {
					$field = (
						<div class='form-control--container'>
							<input disabled value={field.value} class='form-control' type='number' />
							<i class='form-control--check'></i>
						</div>
					)
				} else if (field.type === 'boolean') {
					$field = (
						<div class='checkbox'>
							<input type='checkbox' id={'checkbox.' + this.ev.id + '.' + field.name} checked={field.value} />
							<label class='checkbox--label' for={'checkbox.' + this.ev.id + '.' + field.name}>
								{field.label}
							</label>
							<div class='checkbox--check'>
								<div></div>
							</div>
						</div>
					)
				} else if (field.type === 'list') {
					formData[index].value = formData[index].value || []
					let selected = formData[index].value
					let items = lo.filter(lo.get(field, 'items', []), (item) => {
						return !formData[index].value.includes(item)
					})
					$field = <ListField selected={selected} items={items} />
				} else if (field.type === 'datetime') {
					$field = <input vModel={formData[index].value} class='form-control' type='date' />
				}

				let label = field.label
				if (field.name === 'fullname') {
					label = this.$t('fullname')
				} else if (field.name === 'emails') {
					label = this.$t('email')
				} else if (field.name === 'phones') {
					label = this.$t('phones')
				}

				return (
					<div>
						<div class='label mt-2'>
							{label}
							{field.is_required ? <span class='ml-1 red'>*</span> : ''}
						</div>
						{$field}
					</div>
				)
			})
			return <div>{$fields}</div>
		},
	},
}
