// using feather icons: MoonIcon, XIcon, ... without import
import {
	AlertTriangleIcon,
	ArchiveIcon,
	ArrowDownCircleIcon,
	ArrowDownIcon,
	ArrowDownRightIcon,
	ArrowLeftCircleIcon,
	ArrowLeftIcon,
	ArrowRightCircleIcon,
	ArrowRightIcon,
	ArrowUpIcon,
	ArrowUpRightIcon,
	AtSignIcon,
	BarChart2Icon,
	BarChartIcon,
	BoldIcon,
	BookmarkIcon,
	CalendarIcon,
	CheckIcon,
	CheckSquareIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	CircleIcon,
	ClockIcon,
	ColumnsIcon,
	CopyIcon,
	DownloadIcon,
	Edit2Icon,
	EditIcon,
	ExternalLinkIcon,
	EyeIcon,
	EyeOffIcon,
	FileTextIcon,
	FilterIcon,
	GlobeIcon,
	HelpCircleIcon,
	HomeIcon,
	ImageIcon,
	InfoIcon,
	ItalicIcon,
	LifeBuoyIcon,
	Link2Icon,
	LinkIcon,
	ListIcon,
	LoaderIcon,
	LockIcon,
	MailIcon,
	MapIcon,
	MapPinIcon,
	MessageCircleIcon,
	MessageSquareIcon,
	MinusCircleIcon,
	MinusIcon,
	MonitorIcon,
	MoreHorizontalIcon,
	PaperclipIcon,
	PhoneIcon,
	PieChartIcon,
	PlayCircleIcon,
	PlayIcon,
	PlusCircleIcon,
	PlusIcon,
	PlusSquareIcon,
	PrinterIcon,
	RefreshCcwIcon,
	RefreshCwIcon,
	RotateCcwIcon,
	RotateCwIcon,
	SaveIcon,
	SearchIcon,
	SendIcon,
	SettingsIcon,
	ShuffleIcon,
	SidebarIcon,
	SmartphoneIcon,
	SmileIcon,
	TabletIcon,
	TagIcon,
	ThumbsDownIcon,
	ThumbsUpIcon,
	ToggleLeftIcon,
	ToolIcon,
	Trash2Icon,
	TrendingDownIcon,
	TrendingUpIcon,
	TriangleIcon,
	TypeIcon,
	UnderlineIcon,
	UserIcon,
	UserMinusIcon,
	UserPlusIcon,
	UsersIcon,
	XCircleIcon,
	XIcon,
} from 'vue-feather-icons'

Vue.component('AlertTriangleIcon', AlertTriangleIcon)
Vue.component('ArchiveIcon', ArchiveIcon)
Vue.component('ArrowDownCircleIcon', ArrowDownCircleIcon)
Vue.component('ArrowDownIcon', ArrowDownIcon)
Vue.component('ArrowDownRightIcon', ArrowDownRightIcon)
Vue.component('ArrowLeftCircleIcon', ArrowLeftCircleIcon)
Vue.component('ArrowLeftIcon', ArrowLeftIcon)
Vue.component('ArrowRightCircleIcon', ArrowRightCircleIcon)
Vue.component('ArrowRightIcon', ArrowRightIcon)
Vue.component('ArrowUpIcon', ArrowUpIcon)
Vue.component('ArrowUpRightIcon', ArrowUpRightIcon)
Vue.component('AtSignIcon', AtSignIcon)
Vue.component('BarChart2Icon', BarChart2Icon)
Vue.component('BarChartIcon', BarChartIcon)
Vue.component('BoldIcon', BoldIcon)
Vue.component('BookmarkIcon', BookmarkIcon)
Vue.component('CalendarIcon', CalendarIcon)
Vue.component('CheckIcon', CheckIcon)
Vue.component('CheckSquareIcon', CheckSquareIcon)
Vue.component('ChevronDownIcon', ChevronDownIcon)
Vue.component('ChevronUpIcon', ChevronUpIcon)
Vue.component('CircleIcon', CircleIcon)
Vue.component('ClockIcon', ClockIcon)
Vue.component('ColumnsIcon', ColumnsIcon)
Vue.component('CopyIcon', CopyIcon)
Vue.component('DownloadIcon', DownloadIcon)
Vue.component('Edit2Icon', Edit2Icon)
Vue.component('EditIcon', EditIcon)
Vue.component('ExternalLinkIcon', ExternalLinkIcon)
Vue.component('EyeIcon', EyeIcon)
Vue.component('EyeOffIcon', EyeOffIcon)
Vue.component('FileTextIcon', FileTextIcon)
Vue.component('FilterIcon', FilterIcon)
Vue.component('GlobeIcon', GlobeIcon)
Vue.component('HelpCircleIcon', HelpCircleIcon)
Vue.component('HomeIcon', HomeIcon)
Vue.component('ImageIcon', ImageIcon)
Vue.component('InfoIcon', InfoIcon)
Vue.component('ItalicIcon', ItalicIcon)
Vue.component('LifeBuoyIcon', LifeBuoyIcon)
Vue.component('Link2Icon', Link2Icon)
Vue.component('LinkIcon', LinkIcon)
Vue.component('ListIcon', ListIcon)
Vue.component('LoaderIcon', LoaderIcon)
Vue.component('LockIcon', LockIcon)
Vue.component('MailIcon', MailIcon)
Vue.component('MapIcon', MapIcon)
Vue.component('MapPinIcon', MapPinIcon)
Vue.component('MessageCircleIcon', MessageCircleIcon)
Vue.component('MessageSquareIcon', MessageSquareIcon)
Vue.component('MinusCircleIcon', MinusCircleIcon)
Vue.component('MinusIcon', MinusIcon)
Vue.component('MonitorIcon', MonitorIcon)
Vue.component('MoreHorizontalIcon', MoreHorizontalIcon)
Vue.component('PaperclipIcon', PaperclipIcon)
Vue.component('PhoneIcon', PhoneIcon)
Vue.component('PieChartIcon', PieChartIcon)
Vue.component('PlayCircleIcon', PlayCircleIcon)
Vue.component('PlayIcon', PlayIcon)
Vue.component('PlusCircleIcon', PlusCircleIcon)
Vue.component('PlusIcon', PlusIcon)
Vue.component('PlusSquareIcon', PlusSquareIcon)
Vue.component('PrinterIcon', PrinterIcon)
Vue.component('RefreshCcwIcon', RefreshCcwIcon)
Vue.component('RefreshCwIcon', RefreshCwIcon)
Vue.component('RotateCcwIcon', RotateCcwIcon)
Vue.component('RotateCwIcon', RotateCwIcon)
Vue.component('SaveIcon', SaveIcon)
Vue.component('SearchIcon', SearchIcon)
Vue.component('SendIcon', SendIcon)
Vue.component('SettingsIcon', SettingsIcon)
Vue.component('ShuffleIcon', ShuffleIcon)
Vue.component('SidebarIcon', SidebarIcon)
Vue.component('SmartphoneIcon', SmartphoneIcon)
Vue.component('SmileIcon', SmileIcon)
Vue.component('TabletIcon', TabletIcon)
Vue.component('TagIcon', TagIcon)
Vue.component('ThumbsDownIcon', ThumbsDownIcon)
Vue.component('ThumbsUpIcon', ThumbsUpIcon)
Vue.component('ToggleLeftIcon', ToggleLeftIcon)
Vue.component('ToolIcon', ToolIcon)
Vue.component('Trash2Icon', Trash2Icon)
Vue.component('TrendingDownIcon', TrendingDownIcon)
Vue.component('TrendingUpIcon', TrendingUpIcon)
Vue.component('TriangleIcon', TriangleIcon)
Vue.component('TypeIcon', TypeIcon)
Vue.component('UnderlineIcon', UnderlineIcon)
Vue.component('UserIcon', UserIcon)
Vue.component('UserMinusIcon', UserMinusIcon)
Vue.component('UserPlusIcon', UserPlusIcon)
Vue.component('UsersIcon', UsersIcon)
Vue.component('XCircleIcon', XCircleIcon)
Vue.component('XIcon', XIcon)
