const lo = require('lodash')
const {getCurrentLanguage, i18n} = require('../languages.js')

function getProductPropName(product, seperator = ' - ') {
	let options = lo.get(product, 'options', [])
	let props = lo.get(product, 'props', [])
	let multiPropsOptions = lo.filter(options, (option) => lo.size(option.values) > 1)
	let names = []
	lo.each(multiPropsOptions, (option) => {
		let prop = lo.find(props, (prop) => prop.key === option.key) || {}
		if (prop.value) names.push(prop.value)
	})
	return names.join(seperator)
}

function getProductDisplayName(product = {}) {
	let locale = lo.get(store.me(), 'account.locale', 'vi-VN')
	locale = locale.replace('-', '_')
	return lo.get(product, `i18n_name.${locale}`) || product.name || i18n.t('no_name_product')
}

function getProductDisplayDescription(product) {
	let locale = lo.get(store.me(), 'account.locale', 'vi-VN')
	locale = locale.replace('-', '_')
	return lo.get(product, `i18n_description.${locale}`) || product.description || i18n.t('no_description')
}

function getProductNameWithProp(product) {
	return getProductDisplayName(product) + ` ` + getProductPropName(product)
}

async function calculateGHTKFee(shipping, all) {
	if (!shipping) return []
	shipping = lo.cloneDeep(shipping)
	shipping.shipping_provider = 'ghtk'
	if (!lo.get(shipping, 'data.ghtk')) shipping.data = {ghtk: {pickup_option: 'cod', deliver_option: 'none'}}

	let ghtkShops = lo.get(store.matchShopSetting(), 'addresses', [])
	ghtkShops = lo.filter(ghtkShops, (pos) => pos.from_shipping_provider === 'ghtk')
	if (ghtkShops.length == 0 && all)
		ghtkShops = ghtkShops.concat(lo.filter(ghtkShops, (pos) => !pos.from_shipping_provider))

	let reses = await Promise.all(
		lo.map(ghtkShops, (shop) => {
			let data = {...shipping, pickup_address: shop}
			if (!data.weight) data.weight = 1000

			return store.getShippingFee(data)
		}),
	)

	let fees = {}
	lo.each(reses, (res) => {
		let shopId = lo.get(res, 'body.pickup_address.id')
		let fee = lo.get(res, 'body.fee', 0)
		let insurance_fee = lo.get(res, 'body.insurance_fee', 0)
		let error = res.error
		if (shopId) fees[shopId] = {fee, insurance_fee, error}
	})
	return fees
}

async function calculateGHNFee(shipping, all) {
	if (!shipping) return []
	shipping = lo.cloneDeep(shipping)
	shipping.shipping_provider = 'ghn'
	// service_type_id = 2 is standard
	if (!lo.get(shipping, 'data.ghn')) shipping.data = {ghn: {service_type_id: 2}}

	let ghnShops = lo.get(store.matchShopSetting(), 'addresses', [])
	ghnShops = lo.filter(ghnShops, (pos) => pos.from_shipping_provider === 'ghn')
	if (ghnShops.length == 0 && all) ghnShops = ghnShops.concat(lo.filter(ghnShops, (pos) => !pos.from_shipping_provider))

	let reses = await Promise.all(
		lo.map(ghnShops, (shop) => {
			let data = {...shipping, pickup_address: shop}
			if (!data.weight) data.weight = 1000

			return store.getShippingFee(data)
		}),
	)
	let fees = {}
	lo.each(reses, (res) => {
		let shopId = lo.get(res, 'body.pickup_address.id')
		let fee = lo.get(res, 'body.fee', 0)
		let insurance_fee = lo.get(res, 'body.insurance_fee', 0)
		let error = res.error
		if (shopId) fees[shopId] = {fee, insurance_fee, error}
	})
	return fees
}

function isAdmin() {
	let scopes = lo.get(store.me(), 'scopes')
	return lo.includes(scopes, 'view_others')
}

function canViewOrder(order = {}) {
	if (isAdmin()) return true
	if (order.salesperson === store.me().id) return true
	let stages = lo.get(order, 'stages', [])
	return lo.find(order.stages, (stage) => stage.agent_id === store.me().id)
}

function getDefaultPOS() {
	let addresses = lo.get(store.matchShopSetting(), 'addresses', [])
	addresses = lo.orderBy(addresses, ['created'], ['asc'])
	return addresses[0] || {}
}

function getOrderDisplayName(order = {}) {
	return order.number || order.id || ''
}

function displayOrderShippingAddress(order, isShorten) {
	let addr = lo.get(order, 'shipping.address', {})
	let fulladdr = [addr.address, addr.ward, addr.district, addr.province]
		.filter((a) => a)
		.join(', ')
		.trim()
	if (isShorten) fulladdr = shortenAddr(fulladdr)
	return fulladdr
}

function shortenAddr(str) {
	str = str || ''
	str = str.replace(/Hà Nội/gi, 'HN')
	str = str.replace(/Hồ Chí Minh/gi, 'HCM')
	str = str.replace(/Thành Phố/gi, 'TP')

	return str
}

function calcOrderTotal(order) {
	// compute subtotal first, since it doesn't depend on tax or gloal discount
	let subtotal = 0
	let beforeTaxDiscount = 0
	let ordervalue = 0
	lo.map(order.items, (item) => {
		if (!item.product) return 0
		let price = lo.get(item, 'product.price', 0) * (item.quantity || 0)
		ordervalue += price

		// some products that are discounted before tax
		// the discount amount is calculated in product price for
		// simpler calucation in future
		if (item.discount_type == 'percentage') {
			if (item.discount_percentage > 0) {
				beforeTaxDiscount += (price * item.discount_percentage) / 10000
				price = price * (1 - (item.discount_percentage || 0) / 10000)
			}
		} else if (item.discount_type == 'amount') {
			if (item.discount_amount > 0) {
				beforeTaxDiscount += item.discount_amount
				price = price - (item.discount_amount || 0)
				if (price <= 0) price = 0
			}
		}
		item.total = price
		subtotal += price
	})

	// TAX
	let taxM = {}
	lo.map(order.items, (item, i) => {
		if (!item.product) return
		let tax = item.product.tax
		if (!tax) return
		if (!tax.id) return
		let taxprice = (item.total * tax.percentage) / 10000
		item.total_tax = taxprice
		taxM[i] = {tax: tax, taxprice: taxprice}
	})

	let totaltax = 0
	lo.map(taxM, (t) => (totaltax += t.taxprice))

	let computed_discount = 0
	if (order.discount_type == 'percentage' && order.discount_percentage > 0) {
		computed_discount = ((subtotal + totaltax) * order.discount_percentage) / 10000
	} else if (order.discount_type == 'amount') {
		computed_discount = order.discount_amount || 0
	}

	// SHIP
	let shippingfee = lo.get(order, 'shipping.nominal_fee', 0)
	if (shippingfee > 0) {
		if (lo.get(order, 'shipping.tax.id')) {
			let tax = order.shipping.tax
			let taxprice = shippingfee * (tax.percentage / 10000)
			taxM['ship'] = {tax: tax, taxprice: taxprice}
			order.shipping.total_tax = taxprice
		}
	}

	totaltax = 0
	lo.map(taxM, (t) => (totaltax += t.taxprice))
	let total = subtotal + totaltax - computed_discount + shippingfee

	// add adjustment
	total += order.adjustment || 0
	if (total < 0) total = 0
	order.subtotal = subtotal
	order._computed_discount = computed_discount
	order._taxM = taxM
	order.total = total
	order.total_tax = totaltax
	order._discount_before_tax = beforeTaxDiscount
	order._order_value = ordervalue
}

// return pick_amount and fee_payer
function computeGHTKData(order) {
	let fee_payer = 'shop'
	if (lo.get(order, 'shipping.nominal_fee')) fee_payer = 'user'

	//console.log('SSSSSSSSSSSSSSSS', order.subtotal, order.totaltax, order._computed_discount, order.adjustment)
	let pick_amount =
		order.subtotal + order.total_tax - order._computed_discount + (order.adjustment || 0) - (order.payment_made || 0)
	if (fee_payer === 'user') {
		pick_amount =
			pick_amount +
			lo.get(order, 'shipping.insurance_fee', 0) +
			lo.get(order, 'shipping.nominal_fee', 0) -
			lo.get(order, 'shipping.fee', 0)
	}

	return {fee_payer, pick_amount}
}

function getDefaultPipeline() {
	let pipelines = lo.map(store.matchPipeline(), (pipeline) => {
		if (!pipeline.preselected) return { ...pipeline, preselected: 0 }
		return pipeline
	})
	let hasPreselect = false
	lo.each(store.matchPipeline(), (pipeline) => {
		if (pipeline.preselected) {
			hasPreselect = true
			return -1 // break loop
		}
	})

	if (!hasPreselect) return store.matchPipeline()['default'] || {}
	pipelines = lo.orderBy(pipelines, ['preselected'], ['desc'])
	return pipelines[0] || {}
}

module.exports = {
	getProductNameWithProp,
	getProductPropName,
	getProductDisplayName,
	getProductDisplayDescription,
	calculateGHNFee,
	calculateGHTKFee,
	isAdmin,
	canViewOrder,
	getDefaultPOS,
	getOrderDisplayName,
	displayOrderShippingAddress,
	calcOrderTotal,
	computeGHTKData,
	getDefaultPipeline,
}
