import {OnelineMsg, AvatarGroup} from '@sb/com'
import store from '@sb/store'
const lo = require('lodash')
const sb = require('@sb/util')
import ChatAvatar from './chat_avatar.js'

export default {
	name: 'chat-item',
	props: ['convoid', 'is_read', 'updated', 'event'],

	created() {
		let thisforceUpdate = () => this.$forceUpdate()
		if (this.convoid.startsWith('csinternalcs')) {
			let refconvoid = this.convoid.substr(10)
			store.onConvo2(this, refconvoid, (_) => this.$forceUpdate())
			store.onUser(this, (uM) => {
				let convo = store.matchConvo(refconvoid) || {}
				lo.find(sb.usersOfConvo(convo), (userid) => {
					if (uM[userid]) {
						this.$forceUpdate()
						return true
					}
				})
			})
		}
		store.onConvo2(this, this.convoid, thisforceUpdate)
	},

	methods: {
		onClick() {
			this.$emit('selected', this.convoid)
		},
	},

	render() {
		// if (this.refresh) lo.noop()
		let convo = store.matchConvo(this.convoid) || {}
		// if (!convo.watch) return null
		let cls = 'convos__convo convos__convo__internal'
		let agids = lo.filter(convo.members, (m) => m.id != store.me().id).map((m) => m.id)

		let username = ''
		if (agids.length == 0) username = this.$t('me')
		if (agids.length > 0) username = sb.getAgentDisplayName(store.matchAgent()[agids[0]])
		let $username = <span class='text-truncate'>{convo.subject || username}</span>
		if (this.convoid.startsWith('csinternalcs')) {
			let refconvoid = this.convoid.substr(10)
			let refconvo = store.matchConvo(refconvoid) || {id: refconvoid}
			let userid = sb.usersOfConvo(refconvo)[0] || '' // render avatar of the first user
			username = sb.getUserDisplayName(store.matchUser(userid))
			$username = <span style='background: #EEE; border-radius: 3px; color: #EEE'>LoadingLoading</span>
			if (username)
				$username = (
					<span class='text-truncate'>
						{refconvo.subject || username} <Link2Icon size='1x' class='ml-2 text__muted' />
					</span>
				)
		}

		if (this.convoid === 'csinternalall') {
			$username = <span class='text-truncate'>{this.$t('general')}</span>
		}

		let $mute = null
		if (convo.mute) {
			$mute = <BellOffIcon size='14' style='flex-shrink: 0' class='text__muted mr-2' />
		}

		let msgcls = 'internal_convos__subject'

		let $unread = null
		if (!this.is_read) $unread = <div class='dot dot__lg dot__danger'></div>

		let lastmsgsent = lo.get(convo, 'last_message_sent')
		if (this.event) lastmsgsent = this.event

		if (this.convoid === 'csinternalall' && !convo.last_message_sent)
			lastmsgsent = {by: {id: ''}, data: {message: {text: 'Gửi tin nhắn tới tất cả mọi người'}}}
		// display subject instead of last message for email channel
		let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')
		let itemcls = 'convos__info_container'
		return (
			<li attr-convo-id={this.convoid} class={cls} vOn:click={this.onClick} key={this.convoid}>
				<ChatAvatar id={this.convoid} />
				<div class={itemcls} style='margin-left: 10px; margin-top: 0; align-self :unset'>
					<div class='convos__line' a={this.updated}>
						<div class='internal_convos__name'>{$username}</div>
						<div class='convos__time'>
							<Time time={this.updated} ago />
						</div>
					</div>

					<div class='convos__line'>
						<OnelineMsg ev={lastmsgsent} class={msgcls} placeholder={lo.get(convo, 'subject')} hasAuthor />
						{$mute}
						{$unread}
					</div>
				</div>
			</li>
		)
	},
}
