import lo from 'lodash'
import sb from '@sb/util'
import BotTextEditor from './message_template_editor.js'
import {MessageEditor} from '@sb/com'
import LexicalEditor from '../commons/lexical-editor.js'

const OLD_TEMPLATE_TIME_STAMP = 1689724800000000000

export default {
	name: 'message-template-edit-modal',
	props: ['message_template'],
	data() {
		return {
			saving: false,
			fileUploading: false,

			errorKey: '',
			errorContent: '',
			errorAttachment: '',

			// initContent
			initMessage: null,
			compareMessage: null,
		}
	},

	watch: {
		async message_template(newTmpl, oldTmpl) {
			// workaround for old message template only have text but doesnt have quill_delta

			let text = lo.get(this.message_template, 'message.text')
			let quill = lo.get(this.message_template, 'message.quill_delta')
			this.clearError()

			//if (!text) return
			if (['subiz', 'zalo', 'facebook'].includes(this.message_template.channel_type) && !quill) {
				let extracts = sb.extractText(text || '')

				let ops = lo.map(extracts, (extract) => {
					if (extract.type === 'text') return {insert: extract.text}
					if (extract.type === 'emoji') return {insert: {emoji: extract.text.replace(/:/g, '')}}
					if (extract.type === 'link') return {insert: extract.text, attributes: {link: extract.text}}
					if (extract.type === 'phone') return {insert: extract.text}
					if (extract.type === 'email') return {insert: extract.text}
				})

				let quill = JSON.stringify({ops})
				let msgtmpl = lo.cloneDeep(this.message_template)
				lo.set(msgtmpl, 'message.quill_delta', quill)
				this.$emit('change', msgtmpl)
			}

			if (this.message_template.channel_type === 'email') {
				// detect when open new message template reset initMessage
				if (newTmpl.id !== oldTmpl.id && newTmpl.id) {
					this.initMessage = null
				}
				// use for email, convert delta to html
				let delta = lo.get(this.message_template, 'message.quill_delta', '')
				delta = sb.parseJSON(delta) || {}
				let lexicalHtml = await sb.deltaToLexicalHtml(delta)

				// the old emaol template doesnt have quill delta
				if (!lo.size(delta)) lexicalHtml = lo.get(this.message_template, 'message.text', '')

				let msgtmpl = lo.cloneDeep(this.message_template)
				let message = lo.get(msgtmpl, 'message', {})
				if (!message.is_template) {
					message.text = lexicalHtml
				}
				this.initMessage = lo.cloneDeep(message)
				this.compareMessage = lo.cloneDeep(this.initMessage)
			} else {
				// detect when open new message template reset initMessage
				if (newTmpl.id !== oldTmpl.id && newTmpl.id) {
					this.initMessage = null
				}

				await this.$nextTick()
				let message = lo.get(newTmpl, 'message', {})
				message = lo.cloneDeep(message)
				if (!message.is_template) {
					let text = sb.plainTextToLexicalHtml(message.text)
					let delta = lo.get(newTmpl, 'message.quill_delta')
					if (delta) {
						delta = sb.parseJSON(delta) || {}
						text = await sb.deltaToLexicalHtml(delta)
					}
					message.text = text
				}
				this.initMessage = message
				this.compareMessage = lo.cloneDeep(this.initMessage)
			}
		},
	},

	methods: {
		onChange(obj) {
			let msgtmpl = {...this.message_template, ...obj}
			this.$emit('change', msgtmpl)
		},

		onChangeKeys(e) {
			let text = e.target.value
			let keys = lo.split(text, ',')
			this.onChange({keys})
		},

		clearError() {
			this.errorKey = ''
			this.errorContent = ''
			this.errorAttachment = ''
		},

		isAvailabelOnFacebook() {
			// TODO
			let text = lo.get(this.message_template, 'message.text', '')
			if (!text) return false

			return true
		},

		isAvailabelOnZalo() {
			// TODO
			let text = lo.get(this.message_template, 'message.text', '')
			if (!text) return false

			return true
		},

		validateMessageTemplate() {
			let result = true
			if (!lo.size(this.message_template.keys)) {
				result = false
				this.errorKey = this.$t('cannot_be_empty')
			}
			let text = lo.get(this.message_template, 'message.text', '')
			let attachments = lo.get(this.message_template, 'message.attachments', [])
			if ((!text || text === '<p><br></p>') && !lo.size(attachments)) {
				result = false
				this.errorContent = this.$t('cannot_be_empty')
			}

			//if (lo.find(attachments, (item) => item.size > 1024 * 1024)) {
			//result = false
			//this.errorAttachment = this.$t('unavailable_attachment')
			//}

			return result
		},

		async convertImgToPublishUrl() {
			let deltas = sb.parseJSON(this.message_template.message.quill_delta) || {ops: []}
			let new_deltas = {ops: []}
			for (let op of deltas.ops) {
				let ins = op
				let img = lo.get(op, 'insert.image')
				if (img && img.startsWith('data:image/png;base64')) {
					let url = lo.get(op, 'insert.image')
					let file = dataURLtoFile(url, `image_copy.png`)
					let res = await store.uploadLocalFile(file)
					if (res.error) return this.$showError(this.$t(res.error))
					ins = {
						insert: {
							image: res.url,
						},
					}
				}
				new_deltas.ops.push(ins)
			}
			return new_deltas
		},

		async submit() {
			if (this.saving) return
			if (this.fileUploading) return
			if (this.message_template.id !== 'new' && this.message_template.creator !== lo.get(store.me(), 'id')) {
				this.$showError(this.$t('only_creator_can_edit_message_template'))
				return
			}
			if (!this.validateMessageTemplate()) return

			this.clearError()
			let successtext = ''

			this.saving = true
			let err = ''
			console.log('message_template_edit_modal onSubmit', this.message_template)

			if (this.message_template.id === 'new') {
				successtext = this.$t('create_message_template_successfully')
				let {error} = await store.addMessageTemplate(this.message_template)
				err = error
			} else {
				successtext = this.$t('edit_message_template_successfully')
				let {error} = await store.updateMessageTemplate(this.message_template)
				err = error
			}
			this.saving = false
			if (err) {
				this.$showError(err)
				return
			}
			this.$showSuccess(successtext)
			this.$emit('success')
		},

		isEditPrivacyAllowed() {
			if (this.message_template.id === 'new') return true
			return lo.get(store.me(), 'id') === this.message_template.creator
		},

		renderSubizContent() {
			if (this.message_template.channel_type === 'email') return null
			let message = lo.get(this.message_template, 'message', {})

			const DYNAMIC_FIELDS = {
				fullname: {key: 'fullname', name: this.$t('user_name')},
			}

			return (
				<Fragment>
					<div class='mt-3 mb-1'>{this.$t('content')}</div>
					{this.initMessage ? (
						<LexicalEditor
							plaintext
							dynamicFields={DYNAMIC_FIELDS}
							initMessage={this.initMessage}
							no_preview_mode
							vOn:change={(message) => {
								message = lo.cloneDeep(message)
								message.is_template = true
								message.format = 'plaintext'
								this.onChange({message})
							}}
						/>
					) : (
						<div class='text__muted'>{this.$t('loading_editor')}</div>
					)}
					{/*
					<BotTextEditor
						actions={['emoji', 'dynamicField', 'attachments', 'gallery']}
						actions_always_show
						message={message}
						class={this.errorContent ? 'is-invalid' : ''}
						dynamicFieldData={DYNAMIC_FIELDS}
						vOn:change={(message) => this.onChange({message})}
						vOn:changeFileUploading={(v) => (this.fileUploading = v)}
					/>
          */}
					{this.errorContent ? (
						<div class='mt-1'>
							<small class='text__danger'>{this.errorContent}</small>
						</div>
					) : (
						<div class='mt-1'>
							<div class='d-flex align-items-center'>
								<img
									src={
										this.isAvailabelOnFacebook()
											? require('../assets/img/done.svg')
											: require('../assets/img/done_gray.svg')
									}
									class='mr-2'
									style='width: 12px; height: 12px; object-fit: cover'
								/>
								<small class={this.isAvailabelOnFacebook() ? 'text__dark' : 'text__muted'}>
									{this.$t('available_channel_type', [this.$t('facebook')])}
								</small>
							</div>
							<div class='d-flex align-items-center'>
								<img
									src={
										this.isAvailabelOnFacebook()
											? require('../assets/img/done.svg')
											: require('../assets/img/done_gray.svg')
									}
									class='mr-2'
									style='width: 12px; height: 12px; object-fit: cover'
								/>
								<small class={this.isAvailabelOnZalo() ? 'text__dark' : 'text__muted'}>
									{this.$t('available_channel_type', [this.$t('zalo')])}
								</small>
							</div>
						</div>
					)}
				</Fragment>
			)
		},

		renderEmailContent() {
			if (this.message_template.channel_type !== 'email') return null
			let fields = lo.get(this.message_template, 'message.fields', [])
			let subjectField = lo.find(fields, (field) => field.key === 'subject') || {}
			let subject = subjectField.value || '""'
			subject = sb.parseJSON(subject)

			const DYNAMIC_FIELDS = {
				fullname: {key: 'fullname', name: this.$t('user_name')},
			}

			return (
				<Fragment>
					{this.renderEmailFrom()}
					<div class='mt-3 mb-1'>{this.$t('email_subject')}</div>
					<input class='form-control' value={subject} vOn:change={this.onChangeEmailSubject} />
					<div class='mt-3 mb-1'>{this.$t('content')}</div>
					{/*
					<MessageEditor
						style='height: 410px; flex-wrap: nowrap; overflow-y: auto;'
						initEvent={this.message_template.message || {}}
						dynamicFieldData={DYNAMIC_FIELDS}
						html
						vOn:messageChanged={(message) => this.onChange({message})}
					/>
          */}
					{this.initMessage ? (
						<LexicalEditor
							class='message_template_edit_modal'
							initMessage={this.initMessage}
							dynamicFields={DYNAMIC_FIELDS}
							html
							vOn:change={(message) => {
								message = lo.cloneDeep(message)
								message.is_template = true
								message.format = 'html'
								this.onChange({message})
							}}
						/>
					) : (
						<div class='text__muted'>{this.$t('loading_editor')}</div>
					)}
					{this.errorContent && (
						<div class='mt-1'>
							<small class='text__danger'>{this.errorContent}</small>
						</div>
					)}
					{this.errorAttachment && (
						<div class='mt-1'>
							<small class='text__danger'>{this.errorAttachment}</small>
						</div>
					)}
				</Fragment>
			)
		},

		onChangeEmailFrom(e) {
			let value = e.target.value

			let message = lo.get(this.message_template, 'message', {})
			let fields = message.fields || []
			let idx = lo.findIndex(fields, (field) => field.key === 'from')
			if (idx < 0) {
				fields.push({key: 'from', value: JSON.stringify(value)})
			} else {
				lo.set(fields, `${idx}.value`, JSON.stringify(value))
			}
			message.fields = fields

			this.onChange({message})
		},

		onChangeEmailSubject(e) {
			let value = e.target.value

			let message = lo.get(this.message_template, 'message', {})
			let fields = message.fields || []
			let idx = lo.findIndex(fields, (field) => field.key === 'subject')
			if (idx < 0) {
				fields.push({key: 'subject', value: JSON.stringify(value)})
			} else {
				lo.set(fields, `${idx}.value`, JSON.stringify(value))
			}
			message.fields = fields

			this.onChange({message})
		},

		renderEmailFrom() {
			let fromEmails = lo.filter(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
			)
			fromEmails = lo.orderBy(fromEmails, ['address'], ['asc'])
			if (!lo.size(fromEmails)) return null
			let $options = lo.map(fromEmails, (item) => <option value={item.address}>{item.address}</option>)

			let fields = lo.get(this.message_template, 'message.fields', [])
			let fromField = lo.find(fields, (field) => field.key === 'from') || {}
			let value = fromField.value || '""'
			value = sb.parseJSON(value)

			return (
				<Fragment>
					<div class='mt-3 mb-1'>{this.$t('from_email')}</div>
					<select class='form-control' value={value} vOn:change={this.onChangeEmailFrom}>
						{$options}
					</select>
				</Fragment>
			)
		},

		renderEmailSubject() {
			return <Fragment></Fragment>
		},

		async closeModal() {
			let isMessageTemplateChanged = false
			if (this.message_template.id === 'new') {
				let text = lo.get(this.message_template, 'message.text', '')
				let attachments = lo.get(this.message_template, 'message.attachments', [])
				if (lo.size(this.message_template.keys) || lo.size(attachments) || (text && text !== '<p><br></p>'))
					isMessageTemplateChanged = true
			} else {
				let message = lo.get(store.matchMessageTemplate()[this.message_template.id], 'message')
				if (!lo.isEqual(this.initMessage, this.compareMessage)) isMessageTemplateChanged = true
			}
			if (isMessageTemplateChanged) {
				let confirm = await this.$confirm({
					ok: this.$t('discard'),
					cancel: this.$t('continue_edit'),
					title: this.$t('are_you_sure'),
					description: this.$t('unsave_changed'),
					style: 'danger',
				})
				if (!confirm) return
			}

			this.$emit('close')
		},
	},

	render() {
		let cls = 'modal'
		if (!this.message_template.id) cls += ' modal__hide'

		let title = this.$t('edit_message_template')
		let btntext = this.$t('save_changes')
		if (this.message_template.id === 'new') {
			title = this.$t('create_message_template')
			btntext = this.$t('create')
		}

		let message = lo.get(this.message_template, 'message', {})

		let keys = this.message_template.keys || []
		keys = lo.join(keys, ',')

		let $creator = null
		let isCreator = true
		if (this.message_template.id !== 'new' && this.message_template.creator !== lo.get(store.me(), 'id')) {
			let creator = lo.get(store.matchAgent(), `${this.message_template.creator}.fullname`)
			isCreator = false
			$creator = (
				<div class='text__muted' style='font-size: 12px'>
					{this.$t('shared_by')} {creator}
				</div>
			)
		}
		let disabled = this.saving || this.fileUploading || !isCreator

		return (
			<div class={cls} vOn:click_stop={(_) => false}>
				<div class='modal__overlay' vOn:click={(_) => this.closeModal()} />
				<div class='modal__container'>
					<div class='modal__background'>
						<div
							class='message_template_modal_content'
							style={this.message_template.channel_type === 'email' ? 'width: 615px' : ''}
						>
							<div class='message_template_modal_content_header'>
								<div
									style='font-size: 18px;'
									class='text__semibold'
									vOn:click={() => sb.copyToClipboard(this.message_template.id)}
								>
									{title}
								</div>
								<x-icon class='x-icon ml-auto' size='24' vOn:click={(_) => this.closeModal()} />
							</div>

							<div class='message_template_modal_content_body'>
								<div class='mb-1 d-flex align-items-center'>
									{this.$t('shortcut')}
									<help-circle-icon size='14' class='ml-2 text__muted' v-tooltip={this.$t('shortcut_explain')} />
								</div>
								<input
									class={`form-control ${this.errorKey ? 'is-invalid' : ''}`}
									value={keys}
									vOn:change={this.onChangeKeys}
								/>
								<div class='mt-1'>{this.errorKey && <small class='text__danger'>{this.errorKey}</small>}</div>

								{this.renderSubizContent()}
								{this.renderEmailContent()}
							</div>

							<div class='message_template_modal_content_footer'>
								{$creator}
								<div class='d-flex align-items-center' style='flex: 1;'>
									{this.isEditPrivacyAllowed() && (
										<div class='form-check'>
											<input
												type='checkbox'
												name='message_template_privacy'
												id='message_template_privacy_public'
												class='form-check-input form-check-input--bold'
												checked={this.message_template.is_public}
												vOn:change={(e) => this.onChange({is_public: e.target.checked})}
											/>
											<label class='form-check-label' for='message_template_privacy_public' style='user-events: none'>
												{this.$t('share_message_template_for_co_workers')}
											</label>
										</div>
									)}
									<div style='flex: 1;'></div>
									<button class='btn btn__light mr-3' vOn:click={(_) => this.$emit('close')}>
										{this.$t('cancel')}
									</button>
									<button
										class='btn btn__primary'
										vOn:click={this.submit}
										v-tooltip={!isCreator ? this.$t('only_creator_can_edit_message_template') : ''}
										style={disabled ? 'cursor: auto; opacity: 0.6' : ''}
									>
										{btntext}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},
}

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = window.atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new window.File([u8arr], filename, {type: mime})
}
