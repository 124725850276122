export default {
	name: 'InputSearch',
	props: ['placeholder', 'value', 'loading'],

	methods: {
		search(e) {
			this.$emit('change', e.target.value)
		},
		clearSearch() {
			this.$emit('change', '')
			this.$refs.input.focus()
		},

		Focus() {
			this.$refs.input.focus()
			setTimeout(() => this.$refs.input.focus(), 200)
		},
	},

	render() {
		let $closeIcon = null
		let $loading = null
		if (this.value) {
			$closeIcon = <XIcon class='input_search__icon_close' vOn:click={this.clearSearch} size='1x' />
		}
		let cls = 'form-control input_search__input'
		if (this.value) cls += ' input_search__input__active'
		if (this.loading) {
			$closeIcon = null
			$loading = <Spinner size='15' class='input_search__icon_loading' mode='blue' />
		}
		return (
			<div class='input_search'>
				<SearchIcon class='input_search__icon_search' size='1.0x' />
				<input
					ref='input'
					value={this.value}
					vOn:input={this.search}
					maxlength='100'
					type='text'
					class={cls}
					placeholder={this.placeholder}
				/>
				{$closeIcon}
				{$loading}
			</div>
		)
	},
}
