import InstantNoti from './instant_noti.js'
import Quickview from './quickview.js'
import ConversationModal from './conversation_modal.js'
const LoadOrderModal = () => import(/*webpackChunkName: "order-modal" */ './product/order_modal.js') // very heavy
import AskNotification from './ask_notification.js'
const lo = require('lodash')
const sb = require('@sb/util')
import sw from '../store/sw.js'
import store from '@sb/store'

import Toaster from './commons/toaster.js'
import Confirm from './commons/confirm.js'
import CallModal from './call_modal.js'
import GlobalAudioPlayer from './commons/global_audio.js'

import InternalChat from './internal_chat/internal_chat.js'

import MessageTemplateEditModal from './activities/message_template_edit_modal.js'
import TaskDetailModal from './commons/task_modal.js'

const MESSAGE_TEMPLATES_LIMIT = 100
import HeaderBar from './header.js'
import TasksModal from './commons/tasks_modal.js'
import OnboardingModal from './onboarding_modal.js'
import Fragment from '@sb/com/fragment.js'
import UpdateContact from './commons/update_contact.js'
import SendEmailWindow from './commons/send_email_window.js'
import Icon from '@sb/com/icon.js'

export default {
	name: 'mainlayout',
	data() {
		return {
			notifies: {},

			isShowQuickview: false,
			isShowConversationModal: false,

			quickviewUcid: '',
			quickviewMessage: '',

			// manage message_template_edit_modal
			editMessageTemplate: {},
			listMsgTemplateChannelType: '',
			isMessageTemplateManageModalOpenned: false,
			msgTemplateKeyword: '',
			msgTemplateTab: 'all_template',
			page: 1,

			forceCloseHelloBar: '',
			helloBarContent: '',
			helloBarMode: 'warning',
			is83ModalOpen: false,
			isWarningModalOpen: true,
			manuallyCloseWarningModalOpen: false,
		}
	},

	computed: {
		shouldShowHelloBar() {
			return this.helloBarContent && this.forceCloseHelloBar !== this.helloBarContent
		},
	},

	mounted() {
		this.$root.$on('message_template_modal_open', this.openManageMessageTemplateModal)
		this.set83Modal()

		this.$root.$on('hello_bar_updated', async () => {
			let {body, error} = await store.get_hellobar(true)
			if (!error) {
				this.helloBarContent = body.content
				this.helloBarMode = body.mode || 'warning'
			}
		})

		setTimeout(async () => {
			let {body, error} = await store.get_hellobar()
			if (!error) {
				this.helloBarContent = body.content
				this.helloBarMode = body.mode || 'warning'
			}
			for (;;) {
				await sb.sleep(90000)
				let {body, error} = await store.get_hellobar()
				if (!error) {
					this.helloBarContent = body.content
					this.helloBarMode = body.mode || 'warning'
				}
			}
		})

		let internalchat = sb.getUrlParameter(document.location.href, 'internal_chat')
		if (internalchat) this.$root.$emit('showPrivateChat', internalchat)

		let quickview = sb.getUrlParameter(document.location.href, 'quickview')
		if (quickview) {
			let user_id = quickview.split('%')[0]
			let convo_id = quickview.split('%')[1]
			let create_new = quickview.split('%')[2]
			this.onShowQuickview({show: true, user_id, convo_id, create_new})
		}
		if (lo.get(this.$route, 'query.message_template')) this.isMessageTemplateManageModalOpenned = true
		store.onConvo(this, (_) => this.changeDocumentTitle())
		store.onRoute(this, () => this.$forceUpdate())

		this.isWarningModalOpen = this.shouldShowWarningModal()
	},

	watch: {
		$route(to, from) {
			if (this.notifies.error) {
				this.notifies = {}
			}
			this.isWarningModalOpen = this.shouldShowWarningModal()
			this.changeDocumentTitle()
		},
	},

	methods: {
		countConvoUnread() {
			let seen = lo.get(store.me(), 'dashboard_setting.conversation_seen', 0)
			let topics = store.listTopics(5_000)
			return lo.filter(topics, (topic) => !topic.read && topic.actived > seen).length
		},

		set83Modal() {
			if (window.localStorage.getItem('83_modal_closed_4')) {
				this.is83ModalOpen = false
				return
			}

			let now = new Date()
			if (now.getDate() >= 10 && now.getDate() <= 15 && now.getMonth() === 1) this.is83ModalOpen = true
		},

		changeDocumentTitle() {
			let title = this.$t(lo.get(this.$route, 'meta.title', 'Subiz'))
			if (!store.me().id) {
				document.title = title
				return
			}
			let count = this.countConvoUnread()
			// let internaltopics = store.listInternalConvos()
			// lo.map(internaltopics, (topic) => {
			//if (!store.isConvoRead(topic.convoid)) count++
			// })

			var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
			link.type = 'image/x-icon'
			link.rel = 'shortcut icon'
			link.href = '/favicon.ico'
			if (count > 0) {
				title = this.$t('unread_convo_and_users', [count])
				link.href = '/favicon_unread.ico'
			}
			document.getElementsByTagName('head')[0].appendChild(link)
			document.title = title
		},

		onShowConversationModal(param) {
			if (!param.url) {
				this.isShowConversationModal = false
				this.conversationModalUrl = ''
				this.conversationModalViewId = undefined
				this.conversationModalUserId = undefined
				return
			}

			this.isShowConversationModal = true
			this.conversationModalUrl = param.url
			this.conversationModalViewId = param.view_id
			this.conversationModalUserId = param.user_id
		},

		onShowQuickview(param) {
			if (param.show === false) {
				this.isShowQuickview = false
				this.quickviewUcid = ''
				return
			}

			let create_new = param.create_new == true || param.create_new == 'true'
			this.quickviewUcid = (param.user_id || '') + '%' + (param.convo_id || '') + '%' + create_new
			this.quickviewMessage = param.message || ''
			this.isShowQuickview = true
		},

		async onShowQuickOrderView(param) {
			let currentRouter = lo.get(this.$router, 'currentRoute', {})
			if (currentRouter.name === 'order-list') return
			return this.$showOrder({id: param.id})
		},

		removeToaster(type) {
			this.notifies[type] = {}
		},

		async onLogout() {
			if (process.env.ENV !== 'app') {
				let serviceWorker = await sw.serviceWorker()
				await serviceWorker.postMessage({type: 'logout'})
			}
			const currentRouterName = lo.get(this.$route, 'path', '/')
			this.$router.push({name: 'login', query: {redirect: currentRouterName}})
		},

		renderPopup() {
			let $notifies = lo.map(this.notifies, (notify) => {
				if (lo.isEmpty(notify)) return null
				return (
					<div class='popup_notification__item'>
						<Toaster
							type={notify.type}
							title={notify.title}
							description={notify.description}
							timeout={notify.timeout}
							action={notify.action}
							vOn:onClose={this.removeToaster}
						/>
					</div>
				)
			})
			return <div class='popup_notification'>{$notifies}</div>
		},

		addParamsToLocation(params) {
			let url = new URL(window.location.href)
			lo.each(params, (value, key) => {
				if (value) {
					url.searchParams.set(key, value)
				} else {
					url.searchParams.delete(key)
				}
			})
			window.history.pushState({}, null, url.href)

			return
			history.pushState(
				{},
				null,
				this.$route.path +
					'?' +
					Object.keys(params)
						.map((key) => {
							return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
						})
						.join('&'),
			)
		},

		openManageMessageTemplateModal(channelType) {
			this.isMessageTemplateManageModalOpenned = true
			let query = lo.get(this.$route, 'query', {})
			this.addParamsToLocation({...query, message_template: true})
			this.listMsgTemplateChannelType = channelType
		},

		closeManageMessageTemplateModal() {
			this.isMessageTemplateManageModalOpenned = false
			let currentQuery = lo.get(this.$route, 'query', {})
			currentQuery.message_template = ''
			this.addParamsToLocation(currentQuery)
		},

		async onDeleteMessageTemplate(id) {
			let cf = await this.$confirm({
				title: this.$t('delete_message_template'),
				description: this.$t('delete_message_template_desc'),
				style: 'danger',
				ok: this.$t('delete'),
				cancel: this.$t('cancel'),
			})
			if (!cf) return
			let {error} = await store.removeMessageTemplate(id)
			if (error) {
				this.$showError(this.$t('something_wrong_retry_again'))
			}
			this.$forceUpdate()
		},

		onSelectCreateMessageTemplate(item) {
			this.editMessageTemplate = {
				id: 'new',
				channel_type: item.id,
				is_public: true,
			}
		},

		isFullMessageTemplate() {
			return this.page * MESSAGE_TEMPLATES_LIMIT >= lo.size(this.getFilteredMessageTemplates())
		},

		loadMoreMessageTemplates() {
			this.page = this.page + 1
		},

		getFilteredMessageTemplates() {
			let connectorType = this.listMsgTemplateChannelType
			return lo.filter(store.matchMessageTemplate(), (msgtmpl) => {
				let allow = msgtmpl.is_public || msgtmpl.creator === lo.get(store.me(), 'id')
				let shortcut = lo.get(msgtmpl, 'keys', []).join(',')
				let content = lo.get(msgtmpl, 'message.text')
				if (msgtmpl.channel_type === 'email') {
					let fields = lo.get(msgtmpl, 'message.fields', [])
					let subject = lo.find(fields, (f) => f.key == 'subject') || {}
					content = subject.value
				}
				let match =
					sb
						.unicodeToAscii(shortcut + content)
						.toLowerCase()
						.indexOf(sb.unicodeToAscii(this.msgTemplateKeyword).toLowerCase()) >= 0
				if (connectorType) {
					return msgtmpl.channel_type === connectorType && allow && match
				} else {
					return allow && match
				}
			})
		},

		renderMessageTemplatesTab() {
			let connectorType = this.listMsgTemplateChannelType

			let messageTemplates = this.getFilteredMessageTemplates()
			let msg_template = lo.filter(messageTemplates, (temp) => temp.channel_type != 'email')
			let email_template = lo.filter(messageTemplates, (temp) => temp.channel_type == 'email')

			let $create = (
				<div
					class='ml-auto link'
					vOn:click={(_) =>
						(this.editMessageTemplate = {
							id: 'new',
							channel_type: connectorType,
							is_public: true,
						})
					}
				>
					+ {this.$t('create_new')}
				</div>
			)
			if (!connectorType) {
				$create = (
					<Dropdown
						mode='link'
						placeholder={`${this.$t('create_new')}`}
						class='ml-auto'
						extra_cls={'link'}
						dropdown_width={250}
						right
						vOn:select={this.onSelectCreateMessageTemplate}
						items={[
							{id: 'subiz', label: 'Mẫu tin nhắn subiz, facebook, zalo'},
							{id: 'email', label: 'Mẫu tin nhắn email'},
						]}
					/>
				)
			}
			return (
				<div class='tab' style='width: 100%; margin-bottom: 0; padding: 0 10px;'>
					<div
						class={{tab__item: true, tab__item__active: this.msgTemplateTab === 'all_template'}}
						vOn:click={() => (this.msgTemplateTab = 'all_template')}
					>
						<div class='text__semibold'>
							{this.$t('all_template')} ({lo.size(messageTemplates)})
						</div>
					</div>

					<div
						class={{tab__item: true, tab__item__active: this.msgTemplateTab === 'message_template'}}
						vOn:click={() => (this.msgTemplateTab = 'message_template')}
					>
						<div class='text__semibold'>
							{this.$t('message_template')} ({lo.size(msg_template)})
						</div>
					</div>
					<div
						class={{tab__item: true, tab__item__active: this.msgTemplateTab === 'email_template'}}
						vOn:click={() => (this.msgTemplateTab = 'email_template')}
					>
						<div class='text__semibold'>
							{this.$t('email_template')} ({lo.size(email_template)})
						</div>
					</div>

					<div style='flex: 1'></div>
					<div class='d-flex align-items-center'>{$create}</div>
				</div>
			)
		},

		renderMessageTeplatesManageModal() {
			if (!this.isMessageTemplateManageModalOpenned) return null

			let messageTemplates = this.getFilteredMessageTemplates()
			if (this.msgTemplateTab == 'message_template')
				messageTemplates = lo.filter(messageTemplates, (temp) => temp.channel_type != 'email')
			if (this.msgTemplateTab == 'email_template')
				messageTemplates = lo.filter(messageTemplates, (temp) => temp.channel_type == 'email')
			messageTemplates = lo.orderBy(messageTemplates, ['created'], ['desc'])
			let displayMessageTemplates = lo.take(messageTemplates, this.page * MESSAGE_TEMPLATES_LIMIT)
			let cls = 'modal'

			return (
				<div class={cls} style='cursor: auto'>
					<div class='modal__overlay' vOn:click={this.closeManageMessageTemplateModal} />
					<div class='modal__container'>
						<div class='modal__background'>
							<div class='modal_content' style='padding: 20px; width: 720px'>
								<div class='d-flex mb-4'>
									<div>
										<div style='font-size: 18px;' class='text__semibold'>
											{this.$t('message_template')}
										</div>
										<small style='font-size: 14px;' class='text__muted'>
											{this.$t('message_template_des')}
										</small>
									</div>
									<x-icon class='x-icon ml-auto' size='24' vOn:click={this.closeManageMessageTemplateModal} />
								</div>

								<div class='input_search mb-4' style='width: 300px'>
									<SearchIcon class='input_search__icon_search' size='1.0x' />
									<input
										style='	min-height: 30px;padding-top: 0;padding-bottom: 0'
										class='form-control input_search__input'
										placeholder={this.$t('find_by_keyword')}
										ref='search_input'
										vModel={this.msgTemplateKeyword}
									/>
									{this.msgTemplateKeyword && (
										<x-icon class='input_search__icon_close' vOn:click={(_) => (this.msgTemplateKeyword = '')} />
									)}
								</div>
								<div class='message_template_modal_box'>
									<div class='message_template_modal_box_head'>{this.renderMessageTemplatesTab()}</div>
									<div class='message_template_modal_box_content'>
										{lo.map(displayMessageTemplates, (msgtmpl) => {
											let $img = null
											let attachments = lo.get(msgtmpl, 'message.attachments', [])
											let images = lo.filter(attachments, (att) => (att.mimetype || '').startsWith('image'))
											let files = lo.filter(
												attachments,
												(att) => !(att.mimetype || '').startsWith('image') && att.type === 'file',
											)
											let generics = lo.filter(attachments, (att) => att.type === 'generic')
											let eles = []
											lo.each(generics, (gen) => {
												lo.each(gen.elements, (el) => eles.push(el))
											})

											if (lo.size(files) && !lo.size(images)) {
												$img = (
													<div class='text__muted'>
														<paperclip-icon size='15' />{' '}
														<em>
															{lo.size(files)} {this.$t('title_attachment').toLowerCase()}
														</em>
													</div>
												)
											}
											if (lo.size(images) && !lo.size(files)) {
												$img = (
													<div class='d-flex align-items-center'>
														<div class='message_template_modal_box_item_img'>
															<img2 src={lo.get(images, '0.url')} style='width: 40px; height: 40px' clickable />
														</div>
														{lo.size(images) > 1 && <span class='text__muted ml-2'>+ {lo.size(images) - 1}</span>}
													</div>
												)
											}
											if (lo.size(images) && lo.size(files)) {
												$img = (
													<div class='d-flex align-items-center'>
														<div class='message_template_modal_box_item_img'>
															<img2 src={lo.get(images, '0.url')} style='width: 40px; height: 40px' clickable />
														</div>
														<div class='text__muted ml-2'>
															{lo.size(images) > 1 && (
																<span class='text__muted ml-2'>
																	+ {lo.size(images) - 1} {this.$t('image').toLowerCase()}
																</span>
															)}{' '}
															{this.$t('and')} {lo.size(files)} {this.$t('title_attachment').toLowerCase()}
														</div>
													</div>
												)
											}
											if (lo.size(generics)) {
												$img = (
													<div class='text__muted'>
														<Icon
															style='position: relative; top: -1px'
															name='layout-columns'
															size='15'
															stroke-width='2'
														/>{' '}
														<em>
															{lo.size(eles)} {this.$t('attach_products').toLowerCase()}
														</em>
													</div>
												)
											}

											let subject = lo.get(msgtmpl, 'message.fields', [])
											subject = lo.find(subject, (field) => field.key === 'subject') || {}
											subject = subject.value || '""'
											subject = sb.parseJSON(subject)

											let $creator = null
											if (msgtmpl.creator !== lo.get(store.me(), 'id')) {
												let creator = lo.get(store.matchAgent(), `${msgtmpl.creator}.fullname`)
												if (!creator) creator = this.$t('agent_was_deleted')
												$creator = (
													<div class='text__muted mt-2' style='font-size: 12px'>
														{this.$t('shared_by')} {creator}
													</div>
												)
											}

											let text = lo.get(msgtmpl, 'message.text')
											let isTemplate = lo.get(msgtmpl, 'message.is_template')
											let $text = (
												<div class='text__truncate' style='white-space: pre-wrap'>
													{isTemplate ? sb.lexicalToPlainText(text, {is_show_dynamic_field_token: true}) : text}
												</div>
											)
											if (lo.get(msgtmpl, 'message.format') === 'markdown') {
												let delta = lo.get(msgtmpl, 'message.quill_delta')
												delta = sb.parseJSON(delta) || {}
												delta = sb.deltaToPlainText(delta)
												$text = (
													<div class='text__truncate' style='white-space: pre-wrap'>
														{delta}
													</div>
												)
											}
											if (subject) $text = <div class='text__truncate'>{subject}</div>
											return (
												<div class='message_template_modal_box_item'>
													<div style='overflow: hidden; flex: 1;'>
														<div class='d-flex mb-2 align-items-center'>
															<div class='d-flex flex__1 flex-wrap' style='overflow: hidden'>
																{lo.map(msgtmpl.keys, (key) => (
																	<div class='message_template_modal_box_item_key'>/{key}</div>
																))}
															</div>
															<div class='message_template_modal_box_item_actions'>
																<div class='link' vOn:click={(_) => (this.editMessageTemplate = lo.cloneDeep(msgtmpl))}>
																	{this.$t('edit')}
																</div>
																<div class='dot dot--sm dot__secondary mr-2 ml-2' style='margin-top: 0' />
																<trash-2-icon
																	size='14'
																	class='link link__secondary'
																	vOn:click={(_) => this.onDeleteMessageTemplate(msgtmpl.id)}
																/>
															</div>
														</div>
														{$text}
														{$img}
														{$creator}
													</div>
												</div>
											)
										})}
										{!lo.size(displayMessageTemplates) && (
											<div class='text__secondary text__center' style='padding-top: 80px; font-size: 16px;'>
												{this.msgTemplateKeyword ? (
													this.$t('message_template_not_found')
												) : (
													<Fragment>
														{this.$t('you_dont_have_any_message_template')}
														<br />
														<a
															href='javascript:;'
															vOn:click={() => {
																this.editMessageTemplate = {
																	id: 'new',
																	channel_type: this.msgTemplateTab === 'email_template' ? 'email' : 'subiz',
																	is_public: true,
																}
															}}
														>
															{this.$t('create_new')}
														</a>
													</Fragment>
												)}
											</div>
										)}
										{!this.isFullMessageTemplate() && (
											<div
												class='message_template_modal_box_item justify-content-center'
												vOn:click={this.loadMoreMessageTemplates}
											>
												<div class='link text__center'>{this.$t('load_more_message_template')}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},

		onSubmitMessageTemplate() {
			this.editMessageTemplate = {}
			this.$forceUpdate()
		},

		close83Modal() {
			this.is83ModalOpen = false
			window.localStorage.setItem('83_modal_closed_4', Date.now())
		},

		renderHappyWomenDayModal() {
			let cls = 'modal'
			if (!this.is83ModalOpen) cls += ' modal__hide'
			return (
				<div class={cls}>
					<div class='modal__overlay' vOn:click={this.close83Modal}></div>
					<div class='modal__container' style='width: 800px; position: relative'>
						<Icon
							name='x'
							size='18'
							class='btn__close'
							style='width: 28px; height: 28px; background: white; top: 20px; right: 20px; position: absolute;'
							stroke-width='2'
							vOn:click={this.close83Modal}
						/>
						<img src={require('./assets/img/happy-new-year-2024.png')} class='w_100 h_100' />
					</div>
				</div>
			)
		},

		onCloseWarningModal() {
			this.manuallyCloseWarningModalOpen = true
			this.isWarningModalOpen = this.shouldShowWarningModal()
		},

		shouldShowWarningModal() {
			if (this.manuallyCloseWarningModalOpen) return false
			let routerName = lo.get(this.$route, 'name', '')
			if (routerName === 'subscriptions-page' || routerName === 'invoices' || routerName === 'billing') return false
			const subscription = store.matchSubscription() || {}
			// let acc = store.me().account
			return subscription.churned
		},

		renderWarningModal() {
			let cls = 'modal'
			if (!this.isWarningModalOpen) cls += ' modal__hide'
			let owner = lo.find(store.matchAgent(), (ag) => ag.is_owner)
			return (
				<div class={cls}>
					<div class='modal__overlay'></div>
					<div class='modal__container'>
						<div class='modal__background'>
							<div class='warning_modal_wrapper'>
								<div class='warning_modal_header d-flex'>
									<Icon name='alert-triangle-filled' size={100} />
								</div>
								<div class='warning_modal_content_wrapper'>
									<div class='warning_modal_content_title text__center'>{this.$t('your_account_will_be_deleted')}</div>
									<div class='warning_modal_content text__center'>
										{!store.me().is_owner
											? this.$t('churned_warning_for_agent', [
													lo.get(store.me(), 'account.name', this.$t('you')),
													lo.get(owner, 'email', 'support@subiz.com'),
												])
											: this.$t('churned_warning_for_owner', [lo.get(store.me(), 'account.name', this.$t('you'))])}
									</div>
								</div>
								<div class='d-flex mt-4' style={{flexDirection: 'column', alignItems: 'center'}}>
									{store.me().is_owner && (
										<router-link
											class='btn btn__primary align-items-center flex__1 justify-content-center'
											style={{display: 'inline-flex', width: '370px', fontWeight: '700', textTranform: 'uppercase'}}
											to={{name: 'subscriptions-page'}}
										>
											{this.$t('upgrade_plan')}
											<Icon name='arrow-narrow-right' size='18' stroke-width='2' class='ml-2' />
										</router-link>
									)}
									<div class='link link__secondary mt-3 mb-4' vOn:click={this.onCloseWarningModal}>
										{this.$t('upgrade_later')}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},
	},

	created() {
		store.onLogout(this, () => this.onLogout())
		this.changeDocumentTitle()
		this.$root.$on('_popup_notify', (notify) => {
			this.$set(this.notifies, notify.type, notify)
		})
		this.$root.$on('showQuickview', this.onShowQuickview)
		this.$root.$on('showConversationModal', this.onShowConversationModal)
		this.$root.$on('showOrder', this.onShowQuickOrderView)

		setTimeout(() => {
			store.onDocument(this, 'keydown', (e) => {
				if (e.key !== 'Escape') return
				if (this.isShowQuickview) return this.onShowQuickview({show: false})
				if (this.isShowConversationModal) return this.onShowConversationModal({url: ''})
			})
		}, 100)
	},

	beforeDestroy() {
		this.$root.$off('showQuickview', this.onShowQuickview)
		this.$root.$off('showConversationModal', this.onShowConversationModal)
		this.$root.$off('showOrder', this.onShowQuickOrderView)
	},

	render() {
		let routeloading = store.matchRouteLoading()
		let $content = (
			<keep-alive include='lead'>
				<router-view></router-view>
			</keep-alive>
		)
		if (routeloading) $content = <Spinner size='20' class='mt-4 ml-4' mode='blue' />

		let $globalEmail = null
		let availableFromEmail = lo.find(
			sb.getAllEmail(),
			(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
		)
		if (availableFromEmail) {
			$globalEmail = (
				<div
					class='global_email_button'
					vOn:click={() => this.$root.$emit('show_global_email', {subject: '', from: '', to: ''})}
				>
					<Icon name='mail-filled' size='20' />
				</div>
			)
		}

		return (
			<div class='root'>
				{this.renderHappyWomenDayModal()}
				<div class='root__header' style={this.shouldShowHelloBar ? 'height: 64px' : ''}>
					<AskNotification />
					{this.shouldShowHelloBar && (
						<div class={'hello-bar text-center bg-' + this.helloBarMode}>
							<span domPropsInnerHTML={this.helloBarContent}></span>
							<x-icon
								class='clickable'
								size='16'
								style='position: absolute; top: 4px; right: 10px;'
								vOn:click={() => (this.forceCloseHelloBar = this.helloBarContent)}
							/>
						</div>
					)}
					<HeaderBar routerName={this.$route.name} ref='header_bar' />
				</div>
				<InstantNoti />
				<div class='root__body'>
					{this.renderPopup()}
					<Confirm />
					<UpdateContact />
					<div class='root_content'>
						{$content}
						<Quickview show={this.isShowQuickview} ucid={this.quickviewUcid} />
						<ConversationModal
							show={this.isShowConversationModal}
							url={this.conversationModalUrl}
							view_id={this.conversationModalViewId}
							user_id={this.conversationModalUserId}
						/>
						<OrderModalComponent cid={this.cid} />
					</div>
					<InternalChat quickviewUcid={this.quickviewUcid} isShowQuickview={this.isShowQuickview} />
				</div>
				{this.renderMessageTeplatesManageModal()}
				<MessageTemplateEditModal
					message_template={this.editMessageTemplate}
					vOn:close={(_) => (this.editMessageTemplate = {})}
					vOn:change={(msgtmpl) => (this.editMessageTemplate = msgtmpl)}
					vOn:success={this.onSubmitMessageTemplate}
				/>
				{$globalEmail}
				<CallModal />
				<GlobalAudioPlayer />
				<AgentsModal />
				<TasksModal />
				<TaskDetailModal />
				{this.isWarningModalOpen ? null : <OnboardingModal />}
				<SendEmailWindow />
				{this.renderWarningModal()}
			</div>
		)
	},
}

var AgentsModal = {
	data() {
		return {
			isAgentModalOpened: false,
			agent_keyword: '',
			waiting: false,
			call_to: '',
		}
	},

	mounted() {
		this.$root.$on('agent_modal_open', this.openAgentModal)
		store.onAccount(this, (_) => this.$forceUpdate())
	},

	methods: {
		openAgentModal() {
			this.isAgentModalOpened = true
		},

		closeAgentModal() {
			this.isAgentModalOpened = false
		},

		renderAgents() {
			let agents = this.getFilteredAgents()

			let $item = []
			lo.map(agents, (item) => {
				$item.push(<div>{this.renderAgentContent(item)}</div>)
			})

			return $item
		},

		async makeCall(number) {
			this.waiting = true
			await store.makeWebCall(number)
			this.waiting = false
			if (!this.waiting) this.closeAgentModal()
		},

		renderAgentContent(item) {
			let $dot_status = <div class='status_agent_dot call_online_dot' style='bottom: -2px; left: 30px'></div>
			let $status = <span style='color: #19B600'>{this.$t('status_online')}</span>
			let lastPing = lo.get(item, 'last_seen.pinged', 0)
			if (Date.now() - lastPing > 30000) {
				$dot_status = <div class='status_agent_dot call_unavailable_dot' style='bottom: -2px; left: 30px'></div>
				$status = <Time ago has_suffix time={lastPing} />
			}
			let $call_icon_btn = (
				<div style='width: 60px; height: 32px;'>
					<Icon name='phone' class='phone_fill_icon' stroke-width='2' size='16' />
					<span style="font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; color: #FFFFFF; margin-left: 5px;">
						{this.$t('call_short')}
					</span>
				</div>
			)

			if (this.waiting && this.call_to == item.id)
				$call_icon_btn = (
					<div style='height: 32px'>
						<Spinner size='18' mode='light' />
					</div>
				)

			let avatar_url = item.avatar_url
			if (!item.avatar_url) avatar_url = sb.getAgentDefaultAvatarUrl(item)

			let bg_cls = 'width: 62px; padding: 0;'
			let btn_tooltip = ''
			if (item.dashboard_setting.on_call != '' && item.dashboard_setting.on_call) {
				bg_cls += 'background: #ffc107; border-color: #ffc107;'
				btn_tooltip = this.$t('on_call')
			}

			return (
				<div class='d-flex align-items-center' style='margin-bottom: 5px; height: 52px'>
					<div style='position: relative;'>
						<img2 src={avatar_url} class='dropdown_item_img' style='width: 40px; height: 40px; border-radius: 50%' />
						{$dot_status}
					</div>
					<div class='ml-3 dropdown_item_text' style='flex: 1; color: black; flex-direction: column'>
						<div class='text__truncate' style='width: 105px; font-size: 16px; font-weight: 500'>
							{item.fullname}
						</div>
						<div class='text__truncate text__muted' style='font-size: 14px; font-weight: 400'>
							{$status}
						</div>
					</div>
					<div style='margin-right: 5px; margin-left: 15px;'>
						<span style='font-size: 16px; font-weight: 400;'>{item.extension}</span>
					</div>
					<div>
						<button
							type='button'
							class='btn btn__lg btn__success ml-3'
							vOn:click={() => {
								this.call_to = item.id
								this.makeCall(item.extension)
							}}
							style={bg_cls}
							disabled={this.waiting}
							v-tooltip={btn_tooltip}
						>
							{$call_icon_btn}
						</button>
					</div>
				</div>
			)
		},

		onChangeKeyword(val) {
			this.agent_keyword = val
		},

		renderAgentFilter() {
			let searchcls = 'form-control input_search__input order_filter_search_input'
			return (
				<div class='input_search lead_mgr_filter_wrapper' style='margin: 10px 15px;'>
					<search-icon size='1x' class='input_search__icon_search' />
					<input
						placeholder={this.$t('find_by_keyword')}
						ref='search_input'
						class={searchcls}
						style='padding-left: 35px;'
						value={this.agent_keyword}
						vOn:input={(e) => this.onChangeKeyword(e.target.value)}
					/>
					{this.agent_keyword && (
						<div
							class='order_filter_x'
							vOn:click={() => this.onChangeKeyword('')}
							style='position: absolute; right: 10px'
						>
							&times;
						</div>
					)}
				</div>
			)
		},

		getFilteredAgents() {
			let me = store.me()
			let agents = lo.filter(
				store.matchAgent(),
				(agent) => agent.type === 'agent' && agent.state === 'active' && agent.id != me.id,
			)
			agents = lo.orderBy(agents, ['created'], ['desc'])
			agents = lo.filter(agents, (item) => {
				let keywordFilter = true
				if (this.agent_keyword) {
					let keyword = sb.unicodeToAscii(this.agent_keyword).toLowerCase()
					let name = sb.unicodeToAscii(item.fullname || '').toLowerCase()
					let extension = sb.unicodeToAscii(item.extension || '')
					keywordFilter = name.indexOf(keyword) >= 0 || extension.indexOf(keyword) >= 0
				}
				return keywordFilter
			})
			return agents
		},
	},

	render() {
		if (!this.isAgentModalOpened) return null
		let totalAgent = lo.size(
			lo.filter(
				store.matchAgent(),
				(agent) => agent.type === 'agent' && agent.state === 'active' && agent.id != store.me().id,
			),
		)

		let totalFilteredAgent = lo.size(this.getFilteredAgents())
		let result = `${totalFilteredAgent}/${totalAgent}`
		if (!this.agent_keyword) result = totalAgent

		let $content = (
			<div style='overflow-y: scroll; height: 272px; padding-left: 15px; padding-right: 6px;'>
				{this.renderAgents()}
			</div>
		)
		if (totalFilteredAgent == 0)
			$content = (
				<div class='text__muted' style={'display: flex; height: 272px; justify-content: center; align-items: center'}>
					<p>{this.$t('no_agents')}</p>
				</div>
			)

		return (
			<div
				class='modal'
				style='cursor: autop; padding-top: 44px; padding-right: 30px; align-items: flex-start; justify-content: flex-end;'
			>
				<div class='modal__overlay' vOn:click={this.closeAgentModal} />
				<div class='modal__container'>
					<div class='modal__background'>
						<div class='modal_content' style='padding-top: 15px; width: 325px; height: 365px;'>
							<div class='d-flex' style='padding-left: 15px; padding-right: 15px;'>
								<div style="flex: 1; font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 22px;">
									{this.$t('agent')} ({result})
								</div>
								<x-icon class='x-icon ml-auto' size='24' vOn:click={this.closeAgentModal} />
							</div>
							{this.renderAgentFilter()}
							{$content}
						</div>
					</div>
				</div>
			</div>
		)
	},
}

var LiveDot = {
	data() {
		return {
			play: true,
		}
	},

	mounted() {
		this._interval = setInterval(() => {
			this.play = !this.play
		}, 5000)
	},

	destroyed() {
		clearInterval(this._interval)
	},

	render() {
		return <span class={'sidebar__icon_active_subiz_live ' + (this.play ? 'play' : '')}></span>
	},
}

function isConvoSeen(convo, memberid) {
	if (convo.state === 'ended') return true
	let lastmsgsent = convo.last_message_sent
	if (!lastmsgsent) return true
	if (lo.get(lastmsgsent, 'by.id') === memberid) return true
	let mem = lo.find(convo.members, (m) => m.id === memberid)
	if (!mem) return true
	return sb.getMs(lastmsgsent.created) < sb.getMs(mem.seen_at)
}

var OrderModalMethod = {}

let g_register = false
var _order = {}
var _showing = null
var _option = {}

OrderModalMethod.install = (Vue) => {
	Vue.prototype.$isShowOrder = () => !!_showing
	Vue.prototype.$order = () => _order
	Vue.prototype.$orderOption = () => _option
	Vue.prototype.$showOrder = function (param, option) {
		if (!g_register) {
			this.$root.$on('order_modal_updated', (order) => {
				if (order == undefined) _order = {id: _order.id}
				else _order = Object.assign({}, _order, order)
			})

			this.$root.$on('order_modal_closed', () => {
				if (_showing) _showing(_order)
				_showing = null
			})
		}
		g_register = true

		// close the opening order first
		if (_showing) _showing(_order)

		_order = param || {}
		_option = option
		_showing = null

		this.$root.$emit('order_modal_open')
		if (!param) return Promise.resolve()
		return new Promise((rs) => (_showing = rs))
	}
}

Vue.use(OrderModalMethod)
var OrderModalComponent = {
	props: ['cid'],
	created() {
		this.$root.$on('order_modal_open', () => this.$forceUpdate())
		setTimeout(async () => {
			this.OrderModal = await LoadOrderModal()
			this.$forceUpdate()
		})
	},

	methods: {
		onChangeOrder(order) {
			this.$root.$emit('order_modal_updated', order)
			this.$forceUpdate()
		},

		onModalClose(e) {
			this.$root.$emit('order_modal_closed')
			this.$forceUpdate()
		},
	},

	render() {
		let OrderModal = lo.get(this.OrderModal, 'default', null)
		return (
			<OrderModal
				cid={this.cid}
				no_change_user={lo.get(this.$root.$orderOption(), 'no_change_user', false)}
				show={this.$root.$isShowOrder()}
				vOn:submit={this.onModalClose}
				order={this.$root.$order()}
				vOn:change={this.onChangeOrder}
				vOn:close={this.onModalClose}
			/>
		)
	},
}
