const sb = require('@sb/util')
const lo = require('lodash')

export default {
	name: 'postback-attachments',
	props: ['ev'],
	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		if (lo.get(this.ev, 'data.message.text')) return null
		// only show postback for empty message

		var postbacks = []
		lo.each(attachments, (att, i) => {
			if (!att || att.type !== 'postback') return
			postbacks.push(att)
		})
		if (postbacks.length === 0) return null

		var $postbacks = lo.map(postbacks, (postback, index) => {
			let payload = lo.get(postback, 'postback.payload')
			payload = sb.parseJSON(payload) || {}
			if (byType === 'user' && payload.type === 'bot_jump') {
				let text = ''
				lo.each(payload.i18n_title, (val) => {
					if (val) {
						text = val
						return -1
					}
				})
				return (
					<div class={`message_container message_container__${byType}`}>
						<div key={index} class={`message_text message_text__${byType}`}>
							{text || payload.title}
						</div>
					</div>
				)
			}
			return (
				<div class={`message_container message_container__${byType}`}>
					<div key={index} class={`message_postback message_postback__${byType}`}>
						<small class='text__muted'>
							<Icon name='database' size='14' /> Dữ liệu nhận về
						</small>
						<br />
						{postback.postback.payload}
					</div>
				</div>
			)
		})
		return <div>{$postbacks}</div>
	},
}
