import lo from 'lodash'
const SEPERATOR_KEYS = [',', ';', '\n']

export default {
	name: 'tag-input',
	props: ['tags', 'placeholder', 'allow_duplicate', 'submit_on_blur'],

	data() {
		return {
			value: '',
			isFocusing: false,
			isBackspace: false,
		}
	},

	methods: {
		focus() {
			this.isFocusing = true
			this.$refs.input.focus()
		},

		blur() {
			this.isFocusing = false
			if (this.submit_on_blur) {
				this.onAddTag()
			} else {
				this.value = ''
			}
			this.$refs.input.blur()
		},

		onRemoveTag(idx) {
			let tags = lo.cloneDeep(this.tags)
			tags.splice(idx, 1)
			this.$emit('change', tags)
		},

		checkDuplicate() {
			if (this.allow_duplicate) return false

			return lo.includes(this.tags, this.value)
		},

		onAddTag() {
			if (!lo.trim(this.value)) return
			if (this.checkDuplicate()) return
			let tags = lo.cloneDeep(this.tags)
			tags.push(this.value)
			this.$emit('change', tags)
			this.value = ''
		},

		onDeleteLastTag() {
			if (this.isBackspace) this.isBackspace = true
			if (this.value) return
			if (!lo.size(this.tags)) return
			this.onRemoveTag(lo.size(this.tags) - 1)
		},

		onKeyPress(e) {
			switch (e.keyCode) {
				case 13: // ENTER
					if (this.resetBackspaceTimeout) clearTimeout(this.resetBackspaceTimeout)
					this.isBackspace = false
					this.onAddTag()
					break
				case 188: // COMMA
					if (this.resetBackspaceTimeout) clearTimeout(this.resetBackspaceTimeout)
					this.isBackspace = false
					e.preventDefault()
					this.onAddTag()
					break
				case 8: // BACKSPACE
					if (this.isBackspace) {
						this.onDeleteLastTag()
						if (this.resetBackspaceTimeout) clearTimeout(this.resetBackspaceTimeout)
						this.isBackspace = false
					} else {
						if (!this.value) {
							this.isBackspace = true
							this.resetBackspaceTimeout = setTimeout(() => {
								this.isBackspace = false
							}, 2000)
						}
					}
					break
				default:
					if (this.resetBackspaceTimeout) clearTimeout(this.resetBackspaceTimeout)
					this.isBackspace = false
					break
			}
		},

		async onPasteValue(e) {
			let paste = (event.clipboardData || window.clipboardData).getData('text')

			// detect seperator
			let seperator = lo.find(SEPERATOR_KEYS, (key) => lo.includes(paste, key))
			if (!seperator) return

			console.log('SEPERATOR_KEYS', seperator, seperator === '\n')

			let values = lo.split(paste, seperator)
			values = lo.map(values, (value) => lo.trim(value))
			if (!this.allow_duplicate) {
				values = lo.uniq(values)
			}
			let newTags = []
			let remainTags = []
			lo.each(values, (tag) => {
				if (!tag) return // continue loep
				if (lo.includes(this.tags, tag) && !this.allow_duplicate) {
					remainTags.push(tag)
				} else {
					newTags.push(tag)
				}
			})

			if (lo.size(newTags)) {
				e.preventDefault()
				let tags = [...this.tags, ...newTags]
				this.$emit('change', tags)
			}

			console.log('SDWDADWA', this.$refs.input, this.$refs.input.value, 'SSSSSSSS', this.value)
			if (lo.size(remainTags)) {
				this.value = remainTags.join(seperator)
			} else {
				this.value = ''
			}
			await this.$nextTick()
			//setTimeout(() => {

			//}, 0)
		},

		onBlur() {
			this.onAddTag()
			this.value = ''
		},
	},

	render() {
		let containercls = 'taginput_container'
		if (this.isFocusing) containercls += ' focus'

		let $tags = lo.map(this.tags, (tag, idx) => {
			let cls = 'taginput_tag'
			if (this.isBackspace && idx === lo.size(this.tags) - 1) cls += ' deletable'
			return (
				<span class={cls}>
					<div class='taginput_tag_text' title={tag}>
						{tag}
					</div>
					<x-icon class='x-icon ml-2' size='14' vOn:click={(_) => this.onRemoveTag(idx)} />
				</span>
			)
		})

		let defaultPlaceholder = ''
		if (!lo.size(this.tags)) defaultPlaceholder = this.$t('press_enter_or_comma_to_add_value')
		return (
			<div class={containercls} ref='container' vOn:click={this.focus} v-clickaway={this.blur}>
				{$tags}
				<div class='taginput_input_wrapper'>
					<input
						ref='input'
						value={this.value}
						class='taginput_input'
						vOn:paste={this.onPasteValue}
						vOn:input={(e) => (this.value = e.target.value)}
						vOn:keydown={this.onKeyPress}
						vOn:blur={this.onBlur}
						placeholder={this.placeholder || defaultPlaceholder}
					/>
				</div>
			</div>
		)
	},
}
