const lo = require('lodash')
const sb = require('@sb/util')
var api = require('./api.js')
const flow = require('@subiz/flow')
import KV from './kv.js'
import InMemKV from './inmem_kv.js'
import NewObjectStore from './object_store.js'
const config = require('@sb/config')
import common from './common.js'

// parent {api, realtime, insync, pubsub}
function NewProductStore(realtime, pubsub) {
	let kv = new InMemKV()
	kv.init()

	let orderCacheKv = new KV(config.db_prefix + 'order_cache')
	orderCacheKv.init()

	let me = {}
	realtime.onEvent(async (ev) => {
		if (!ev || !ev.type) return
		switch (ev.type) {
			case 'order_history_updated':
				let orderid = lo.get(ev, 'data.order_history_entry.order_id', '')
				if (!orderid) return
				let hist = kv.match('order_history_' + orderid)
				if (!hist) return
				let entry = lo.get(ev, 'data.order_history_entry')
				hist.push(entry)
				kv.put('order_history_' + orderid, hist)
				// todo becareful for updating comment (which is not supported yet)
				pubsub.publish('order')
				break
			case 'order_created':
			case 'order_updated':
				var order = lo.get(ev, 'data.order')
				db.put(order.id, order)
				pubsub.publish('order', order)
				return
			case 'order_deleted':
				var order = lo.get(ev, 'data.order', {})
				if (!order.id) return
				if (!db.has(order.id)) return
				db.del(order.id)
				pubsub.publish('order', order)
				return
			case 'product_updated':
				var newproduct = lo.get(ev, 'data.product', {})
				if (!product.id) return
				if (!productdb.has(newproduct.id)) return
				var product = productdb.match(newproduct.id) // remember to merge with old fields
				product = Object.assign(product, newproduct)
				pubsub.publish('product', product.id)
				return productdb.put(product.id, product)

			// tell client to update
			case 'product_deleted':
				productdb.del(ev.data.product.id)
				pubsub.publish('product', ev.data.product.id)
				return
			// tell client to update
		}
	})

	realtime.subscribe([
		'order_created',
		'order_updated',
		'order_deleted',
		'shop_setting_updated',
		'order_history_updated',
	]) // ignore result

	let db = NewObjectStore(realtime, pubsub, 'order', async (orders) => {
		let ids = lo.map(orders, 'id')
		let last_modifieds = lo.map(orders, (order) => order.updated || 0)
		let {body, code, error} = await api.matchOrders(ids, last_modifieds)
		if (error || code !== 200) return {error}
		let out = lo.get(body, 'orders', [])

		let os = []
		// reorder
		lo.map(out, (order) => {
			let i = lo.findIndex(ids, (id) => order.id == id)
			if (i == -1) return
			if (lo.size(order) < 2) delete order.id // no updates, must delete id to tell object store
			os[i] = order
		})

		return {data: os}
	}, [], true)

	let _shopSetting = {}
	me.matchShopSetting = () => _shopSetting
	me.fetchShopSetting = async () => {
		let out = await api.getShopSetting()
		if (out.error) return {error: out.error}
		_shopSetting = out.body
	}

	me.updateShopSetting = (setting) => api.updateShopSetting(setting)
	me.makeOrderPayment = (orderid, method, desc, amount, pic) =>
		api.make_order_payment(orderid, method, desc, amount, pic)

	me.matchOrderEvents = (orderid) => {
		return kv.match('order_history_' + orderid) || []
	}

	me.fetchOrderEvents = async (orderid) => {
		let out = await api.list_order_events(orderid)
		if (out.error) return {error: out.error}
		let hist = out.body.entries || []
		kv.put('order_history_' + orderid, hist)
		return hist
	}

	me.commentOrder = (orderid, ev) => {
		return api.comment_order(orderid, ev)
	}

	me.download_order = (order, template, locale, cd) =>
		api.download_order(
			order,
			template || {
				tagline: '',
				primary_color: '#779302',
				primary_background: 'black',
				ps: '',
				font_family: 'Roboto',
				number_font_family: 'Roboto Mono',
			},
			locale,
			cd,
		)

	me.updateOrder = async (o, fields) => {
		var {error, body: order} = await api.update_order(o, fields)
		if (error) return {error}
		me.clearOrderCache(o.id)
		db.put(order.id, order)
		return order
	}

	me.createOrder = async (o) => {
		var {error, body: order} = await api.create_order(o)
		if (error) return {error}
		db.put(order.id, order)
		return order
	}

	me.deleteOrder = async (id) => {
		var {error, body: order} = await api.delete_order(id)
		if (error) return {error}
		db.del(order.id)
		return
	}

	me.fetchUserOrders = async (uid) => {
		let [out1, out2] = await Promise.all([
			api.list_orders({user_id: uid, limit: 100, is_draft: false}),
			api.list_orders({user_id: uid, limit: 100, is_draft: true}),
		])
		if (out1.error) return {error: out1.error}
		if (out2.error) return {error: out2.error}
		let orders = lo.get(out1, 'body.orders', []).concat(lo.get(out2, 'body.orders', []))
		return lo.orderBy(orders, ['created', 'updated'], ['desc', 'desc'])
	}

	me.fetchOrders = (ids, force) => db.fetch(ids, force)
	me.fetchOrder = async (id) => {
		var {body: order, error} = await api.get_order(id)
		if (error) return {error}
		if (order.id === id) db.put(id, order)
		pubsub.publish('order')
	}

	me._viewingOrder = ''
	me.getViewingOrder = () => me._viewingOrder
	me.setViewingOrder = (id) => {
		me._viewingOrder = id
		let order = me.matchOrder(id)
		if (order && !order.is_draft) me.seenOrder(id)
		pubsub.publish('order')
	}

	me.cacheOrder = (order) => orderCacheKv.put(order.id, order)
	me.listCacheOrders = () => orderCacheKv.all()
	me.matchCachedOrder = (id) => orderCacheKv.match(id)
	me.clearOrderCache = (id) => orderCacheKv.del(id)

	me.seenOrder = (orderid) => orderid && api.seen_order(orderid)

	me.matchOrder = (id) => {
		if (!id || id === '-') return undefined
		return db.match(id)
	}

	me.listOrders = async (query) => {
		let out = await api.list_orders(query)
		lo.map(lo.get(out, 'body.orders', []), (order) => {
			db.put(order.id, order)
		})
		pubsub.publish('order')
		return out
	}

	me.countOrders = api.count_orders
	me.countOrders2 = api.count_orders_2
	me.onOrder = (o, cb) => pubsub.on2(o, 'order', cb)

	me.check_shipping_policy = (order) => api.check_shipping_policy(order)

	me.matchProduct = (id) => {
		if (!id || id === '-') return undefined
		return productdb.match(id)
	}

	let productdb = NewObjectStore(realtime, pubsub, 'product', async (products) => {
		let ids = lo.map(products, 'id')
		let last_modifieds = lo.map(products, (product) => product.updated || 0)
		let {body, code, error} = await api.list_products({ids, last_modifieds})
		if (error || code !== 200) return {error}
		let out = lo.get(body, 'products', [])

		last_modifieds = lo.map(out, (product) => product.updated || 0)
		ids = lo.map(out, 'id')
		var ps = []
		lo.map(out, (product) => {
			let i = lo.findIndex(ids, (id) => product.id == id)
			if (i == -1) return
			if (lo.size(product) < 2) delete product.id // no updates, must delete id to tell object store
			ps[i] = product
		})

		return {data: ps}
	}, [], true)

	me.fetchShippingAddress = (uid) => api.list_shipping_address(uid)
	me.onShippingAddress = (o, cb) => pubsub.on2(o, 'shipping_address', cb)
	me.updateShippingAddress = (addr) => api.update_shipping_address(addr)
	me.createShippingAddress = (addr) => api.create_shipping_address(addr)
	me.deleteShippingAddress = (userid, id) => api.delete_shipping_address(userid, id)
	me.makeDefaultShippingAddress = (userid, id) => api.make_default_shipping_address(userid, id)

	me.uploadSingleProduct = api.upload_single_product
	me.uploadBulkProducts = api.upload_bulk_products
	me.uploadBulkOrders = api.upload_bulk_orders
	me.deleteProduct = api.delete_product

	me.listProducts = async (query) => {
		let out = await api.list_products(query)
		if (out.error) return {error: out.error}
		return out.body || {}
	}

	me.listProductCategories = api.list_product_categories

	me.fetchProducts = (ids, force) => productdb.fetch(ids, force)

	me.createProduct = async (product) => {
		let {product: newProduct, error: err} = await replaceProductBase64Media(product)
		if (err) return {error: err}
		product = newProduct
		var {body, error} = await api.create_product(product)
		if (error) return {error}
		if (!body || !body.id) return {error: 'invalid user id'}
		product = body
		await productdb.put(product.id, product)
		pubsub.publish('product')
		return product
	}

	me.updateProduct = async (product) => {
		let {product: newProduct, error: err} = await replaceProductBase64Media(product)
		if (err) return {error: err}
		product = newProduct
		var {body, error} = await api.update_product(product)
		if (error) return {error}
		product = body
		await productdb.put(product.id, product)
		pubsub.publish('product')
		return product
	}

	async function replaceProductBase64Media(product) {
		product = lo.cloneDeep(product)
		let blobs = []

		if (isBlobUrl(product.image) || isDataUrl(product.image)) {
			blobs.push({path: 'image', url: product.image})
		}
		lo.each(product.images, (url, idx) => {
			if (isBlobUrl(url) || isDataUrl(url)) {
				blobs.push({path: 'images.' + idx, url})
			}
		})
		lo.each(product.additional_images, (url, idx) => {
			if (isBlobUrl(url) || isDataUrl(url)) {
				blobs.push({path: 'additional_images.' + idx, url})
			}
		})
		lo.each(product.videos, (url, idx) => {
			if (isBlobUrl(url) || isDataUrl(url)) {
				blobs.push({path: 'videos.' + idx, url})
			}
		})
		lo.each(product.additional_videos, (url, idx) => {
			if (isBlobUrl(url) || isDataUrl(url)) {
				blobs.push({path: 'additional_videos.' + idx, url})
			}
		})
		let error = ''
		await flow.map(blobs, 5, async (blob) => {
			let {url, path} = blob
			let file
			if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
			if (isDataUrl(url)) file = await sb.dataURLToFile(url)
			let res = await common.uploadLocalFile(file)
			if (res.error) {
				error = res.error
			}
			let newUrl = res.url
			lo.set(product, path, newUrl)
		})
		console.log('GGGGGGGGGG', product)

		return {product, error}
	}

	function isBlobUrl(url = '') {
		return url.startsWith('blob')
	}

	function isDataUrl(url = '') {
		return url.startsWith('data:')
	}

	me.onProduct = (o, cb) => pubsub.on2(o, 'product', cb)

	// try to fetch some products
	// me.searchProduct()
	me.fetchShopSetting()
	me.tagOrder = api.tagOrder
	me.untagOrder = api.untagOrder

	me.getShopeeAuth = () => api.get_shopee_auth_url()
	me.authorizeShopee = (p) => api.authorize_shopee(p)
	me.deleteShopeeShop = (shopid) => api.delete_shopee_shop(shopid)
	me.syncShopee = (p) => api.sync_shopee(p)
	me.destroy = () => orderCacheKv.destroy()

	return me
}

export default NewProductStore
