import sb from '@sb/util'
import lo from 'lodash'
import {format} from 'date-fns'
import TaskForm from './task_form.js'

export default {
	name: 'task-detail-modal',
	data() {
		return {
			taskData: {},
			open: false,
			fetching: false,
			fetchError: '',
		}
	},

	mounted() {
		this.$root.$on('open_user_task_modal', this.openModal)
	},

	destroyed() {
		this.$root.$off('open_user_task_modal', this.openModal)
	},

	methods: {
		openModal(task, options = {}) {
			this.open = true
			this.loadTask(task)
		},

		async loadTask(task) {
			this.fetchError = ''
			this.fetching = true
			if (task.id) {
				let {error} = await store.fetchTasks([task.id])
				let storetask = store.matchTask(task.id)
				if (!storetask || error) {
					let errCode = error || 'not_found'
					this.fetchError = errCode
					this.fetching = false
					this.taskData = {id: task.id}
					return
				}
				storetask = lo.cloneDeep(storetask)
				this.taskData = storetask
			} else {
				this.taskData = task
			}
			this.fetching = false
		},

		closeModal() {
			this.open = false
		},

		onClickHyperlink({uid, cid}) {
			this.$root.$emit('showQuickview', {user_id: uid, convo_id: cid})
		},

		renderLoading() {
			return (
				<div class='h_100 d-flex flex-column flex__1'>
					<div class='task_detail_header'>
						<div style={`height: 24px; width: 50%; background: whitesmoke; border-radius: 4px`} />
					</div>
					<div class='task_detail_content_wrapper'>
						<div class='task_detail_main_fields'>
							<div class='d-flex align-items-center' style='height: 26.5px; margin-bottom: 15px'>
								<div style={`height: 14px; width: 50%; background: whitesmoke; border-radius: 4px`} />
							</div>
							<div
								style={`height: 20px; width: 50%; background: whitesmoke; border-radius: 4px; margin-bottom: 20px`}
							/>
							<div
								style={`height: 14px; width: 100%; background: whitesmoke; border-radius: 4px; margin-bottom: 4px`}
							/>
							<div
								style={`height: 14px; width: 100%; background: whitesmoke; border-radius: 4px; margin-bottom: 4px`}
							/>
							<div style={`height: 14px; width: 50%; background: whitesmoke; border-radius: 4px; margin-bottom: 4px`} />
						</div>
					</div>
					<div class='task_detail_footer'>
						<div class='task_detail_footer_inner'>
							<div style={`height: 32px; width: 100%; background: whitesmoke; border-radius: 4px`} />
						</div>
					</div>
				</div>
			)
		},

		renderError() {
			return (
				<div class='h_100 d-flex flex-column flex__1'>
					<div class='task_detail_header'>
						<div style='height: 24px' />
					</div>
					<div class='task_detail_content_wrapper d-flex align-items-center justify-content-center pr-5 pl-5'>
						{this.fetchError === 'not_found' ? (
							<div class='text__danger text__center'>{this.$t('desc_about_deleted_task')}</div>
						) : (
							<div class='text__danger text__center'>
								{'Không thể tải dữ liệu nhắc việc.'}{' '}
								<a class='link link__danger text__underline' vOn:click={() => this.loadTask(this.taskData)}>
									{this.$t('retry')}
								</a>
							</div>
						)}
					</div>
					<div class='task_detail_footer'>
						<div class='task_detail_footer_inner'>
							<div style='height: 32px' />
						</div>
					</div>
				</div>
			)
		},
	},

	render() {
		if (!this.open) return null

		let style = 'width: 420px; position: relative; height: 550px;'

		let $content = (
			<TaskForm
				task={this.taskData}
				vOn:change={(task) => (this.taskData = task)}
				vOn:clickHyperlink={this.onClickHyperlink}
				mode='mini'
				vOn:close={this.closeModal}
				vOn:submit={this.closeModal}
			/>
		)

		if (this.fetchError) $content = this.renderError()
		if (this.fetching) $content = this.renderLoading()

    // z index 200 so over quickview
		return (
			<div class={'modal'} style='z-index: 200'>
				<div class='modal__overlay' vOn:click={this.closeModal} />
				<div class='modal__container' style='overflow: unset'>
					<div class='modal__background'>
						<div class='modal_content' style={style}>
							<div class='task__modal_close' vOn:click={this.closeModal}>
								<Icon name='x' stroke-width='2' size='18' />
							</div>
							{$content}
						</div>
					</div>
				</div>
			</div>
		)
	},
}
