import Conversation from './activities/conversation/conversation.js'
import UserProfile from './activities/user_profile.js'

export default {
	name: 'quickview',
	props: ['show', 'ucid'],

	watch: {
		show(show) {
			this.updateURL()
		},
		ucid() {
			this.updateURL()
		},
	},

	methods: {
		updateURL() {
			if (this.show) {
				this.$router.push({query: Object.assign({}, this.$route.query, {quickview: this.ucid})})
			} else {
				this.$router.push({query: Object.assign({}, this.$route.query, {quickview: undefined})})
			}
		},

		closeQuickview(e) {
			this.$root.$emit('showQuickview', {show: false})
		},

		onUcidChange(uid, cid) {
			let nuid = this.ucid.split('%')[0]
			let ncid = this.ucid.split('%')[1]
			let create_new = this.ucid.split('%')[2]

			if (cid !== undefined) ncid = cid
			if (uid !== undefined) nuid = uid
			this.updateURL()
			this.$root.$emit('showQuickview', {user_id: nuid, convo_id: ncid, create_new})
		},
	},

	render() {
		let cls = 'modal modal__right'
		if (!this.show) cls += ' modal__hide'
		let uid = this.ucid.split('%')[0]
		let cid = this.ucid.split('%')[1]
		let create_new = this.show && this.ucid.split('%')[2]
		if (this._create_new != create_new) {
			if (create_new == 'true' || create_new == true) {
				setTimeout(() => {
					this.$refs.conversation &&
						this.$refs.conversation.OnShowFacebookPrivateReply({
							user_id: uid,
							touchpoint: {id: uid, source: 'web', channel: 'subiz'},
						})
				}, 100)
			}
		}
		this._create_new = create_new

		if (!uid) return null
		return (
			<div class={cls} style='position: absolute;'>
				<div class='modal__overlay' vOn:click_stop={this.closeQuickview}></div>
				{/* remove click_stop in quickview so can close select user labels, ban user, ... Dont know why add click_stop before */}
				<div class='quickview'>
					<div
						style='flex: 2; min-width: 200px; max-width: 400px; position: relative;'
						vOn:click_stop={this.closeQuickview}
					>
						<div class='quickview__btn_close' vOn:click_stop={this.closeQuickview}>
							<XIcon v-tooltip={this.$t('close')} class='quickview__btn_close__icon' size='24' stroke-width='2' />
						</div>
					</div>
					<Conversation
						cid={cid}
						vOn:ucidChange={this.onUcidChange}
						uid={uid}
						create_new={create_new}
						ref='conversation'
					/>
					<UserProfile
						ucid={this.ucid}
						vOn:ucidChange={this.onUcidChange}
						vOn:showNewConvo={(p) => this.$refs.conversation && this.$refs.conversation.OnShowFacebookPrivateReply(p)}
					/>
				</div>
			</div>
		)
	},
}
