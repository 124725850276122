var sb = require('@sb/util')
const lo = require('lodash')
import store from '@sb/store'

function getRateLang(rating) {
	if (!rating) return 'poor'
	if (rating < 2) return 'poor'
	if (rating < 4) return 'average'
	return 'great'
}

export default {
	name: 'info-message',
	props: ['ev'],

	render() {
		let $avatar = null
		let by = lo.get(this.ev, 'by', {})
		let user = {}
		let agent = {}
		if (by.type === 'user') {
			user = store.matchUser(by.id)
			$avatar = <Avatar user={user} size='xs' />
		} else {
			agent = store.matchAgent()[by.id] || {}
			if (by.type === 'subiz') agent = {fullname: 'System', avatar_url: require('../../assets/img/subiz2.svg')}
			$avatar = <Avatar agent={agent} size='xs' />
		}

		if (this.ev.type === 'bot_terminated') {
			let createdtime = new Date(sb.unixSec(this.ev.created) * 1000)
			let name = sb.getAgentDisplayName(agent)
			return (
				<div class='message__info'>
					<div class='mt-4'>
						{$avatar}
						{' ' + name + ' '}
						<span v-tooltip={createdtime.toLocaleDateString() + ' ' + createdtime.toLocaleTimeString()}>
							{this.$t('bot_terminated').toLowerCase()}
						</span>
					</div>
				</div>
			)
		}

		if (this.ev.type === 'call_answered') {
			let device = lo.get(this.ev, 'data.call_info.answered_device')
			device = lo.get(store.matchPhoneDevice(), device, '')
			return (
				<div class='message__info'>
					<div class='convo_event__time'>
						<Time time={this.ev.created} short />
					</div>
					<div class='mt-2'>
						{device.name} {this.$t('phone_pickup').toLowerCase()}
					</div>
				</div>
			)
		}

		if (this.ev.type === 'conversation_state_updated') {
			var state = lo.get(this.ev, 'data.conversation.state')
			if (state === 'ended') {
				return (
					<div class='message__info'>
						<div class='convo_event__time'>
							<Time time={this.ev.created} short />
						</div>
						<div class='mt-2'>
							{this.$t('convo_ended').toLowerCase()} {this.$t('by')} {$avatar}
						</div>
					</div>
				)
			}
		}

		// if (this.ev.type === 'conversation_rating_requested') return <div class="info-message"></div>
		if (this.ev.type === 'conversation_rated') {
			var rating = lo.get(this.ev, 'data.conversation.ratings.0', {no_rating: true, rating: 0})
			if (rating.no_rating) return null
			let fullname = sb.getUserDisplayName(user)
			let $dropdown_content = null
			if (rating.comment)
				$dropdown_content = (
					<div style='padding: 10px 15px; white-space: pre-wrap; max-width: 200px; text-align: left'>
						{rating.comment}
					</div>
				)
			return (
				<div class='message__info'>
					<strong>{fullname}</strong> {this.$t('rated_as') + ' ' + this.$t(getRateLang(rating.rating)).toLowerCase()}.{' '}
					{rating.comment && (
						<HoverDropdown dropdown_content={$dropdown_content} style='display: inline-block'>
							<span class='clickable text__underline'> {this.$t('view_comment')}</span>
						</HoverDropdown>
					)}
				</div>
			)
		}

		return null
	},
}
