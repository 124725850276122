import {$wrapNodeInElement, mergeRegister} from '@lexical/utils'
import {
	$createParagraphNode,
	$insertNodes,
	$isRootOrShadowRoot,
	COMMAND_PRIORITY_EDITOR,
	createCommand,
	LexicalCommand,
} from 'lexical'

import {$createImageNode, ImageNode} from './lexical-image-node.js'

export const INSERT_IMAGE_COMMAND = createCommand('INSERT_IMAGE_COMMAND')

export function registerImagePlugin(editor, options = {captionsEnabled: true}) {
	if (!editor.hasNodes([ImageNode])) {
		throw new Error('ImagesPlugin: ImageNode not registered on editor')
	}

	return mergeRegister(
		editor.registerCommand(
			INSERT_IMAGE_COMMAND,
			(payload) => {
				const imageNode = $createImageNode(payload)
				$insertNodes([imageNode])
				if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
					$wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
				}

				return true
			},
			COMMAND_PRIORITY_EDITOR,
		),
	)
}
