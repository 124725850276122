import lo from 'lodash'
import sb from '@sb/util'
import store from '@sb/store'
import UserConversations from '../user_conversations.js'

export default {
	name: 'call-convo-card',
	props: ['cid'],

	watch: {
		cid() {
			this.resetInterval()
		},
	},

	async mounted() {
		this.loadPhoneDevice()
		this.loadConvoEvents()
		this.resetInterval()
		store.onConvo(this, (convoM) => {
			if (Object.keys(convoM).includes(this.cid)) {
				this.$forceUpdate()
				this.resetInterval()
			}
		})
	},

	methods: {
		convoSelect() {
			console.log('CLICK')
		},

		startInterval() {
			clearInterval(this.durationInterval)
			this.durationInterval = setInterval(() => {
				this.$forceUpdate()
			}, 1000)
		},

		clearDurationInterval() {
			clearInterval(this.durationInterval)
		},

		resetInterval() {
			let convo = store.matchConvo(this.cid) || {}
			if (convo.state !== 'ended') {
				this.startInterval()
			} else {
				this.clearDurationInterval()
			}
		},

		getDisplayDevice(deviceId) {
			let device = store.matchPhoneDevice()[deviceId] || {}
			let agentIds = device.bind_to_agents || []
			if (!lo.size(agentIds)) {
				return `${device.name} (${device.extension})`
			} else {
				let agent = store.matchAgent()[agentIds[0]]
				return (
					<div class='d-flex align-items-center'>
						<Avatar size={20} agent={agent} nodot />
						<div class='ml-2'>{sb.getAgentDisplayName(agent)}</div>
					</div>
				)
			}
		},

		async loadConvoEvents() {
			if (lo.size(store.listMessages(this.cid))) return
			await store.fetchMoreMessages(this.cid)
			this.$forceUpdate()
		},

		async loadPhoneDevice() {
			if (lo.size(store.matchPhoneDevice())) return
			await store.fetchPhoneDevice()
			this.$forceUpdate()
		},

		renderAnsweredAgents(agents) {
			const AGENTS_LIMIT = 2
			let $other = null
			if (lo.size(agents) > AGENTS_LIMIT) {
				let remains = lo.takeRight(agents, lo.size(agents) - AGENTS_LIMIT)
				let $dropdownContent = (
					<div style='padding: 10px'>
						{lo.map(remains, (agent, idx) => (
							<div class={{'d-flex': true, 'align-items-center': true, 'mb-2': idx < lo.size(remains) - 1}}>
								<Avatar size={20} agent={agent} nodot />
								<div class='ml-2'>{sb.getAgentDisplayName(agent)}</div>
							</div>
						))}
					</div>
				)
				$other = (
					<div class='ml-2'>
						{this.$t('and')}
						<HoverDropdown dropdown_content={$dropdownContent} style='display: inline-block; border-bottom: 1px dashed'>
							{lo.size(remains)} {this.$t('other_members')}
						</HoverDropdown>
					</div>
				)
			}

			let displayAgents = lo.take(agents, AGENTS_LIMIT)
			return (
				<div class='d-flex align-items-center'>
					{lo.map(displayAgents, (agent, idx) => {
						let hasComma = idx < lo.size(displayAgents) - 1
						let text = sb.getAgentDisplayName(agent)
						if (hasComma) text += ', '
						return (
							<Fragment>
								<Avatar size={20} agent={agent} nodot />
								<div class={{'ml-2': true, 'mr-2': hasComma}}>{text}</div>
							</Fragment>
						)
					})}
					{$other}
				</div>
			)
		},

		renderHangupReason(convo) {
			if (!convo.call.is_missed) return null

			return (
				<div class='call_convo_card_info_row'>
					<div class='call_convo_card_info_row_label d-flex align-items-center'>
						{this.$t('reason')}
						<Icon
							name='help'
							class='ml-2 text__muted'
							size='16'
							stroke-width='2'
							v-tooltip={this.$t('call_failed_reason')}
						/>
					</div>
					<div class='call_convo_card_info_row_content'>{this.$t('hangup_' + convo.call.hangup_code)}</div>
				</div>
			)
		},

		onClickAnsweredAt(sec) {
			let $audio = this.$refs.audio_player
			if (!$audio) return

			$audio.jump(sec)
		},
	},

	render() {
		let convo = store.matchConvo(this.cid) || {}
		let direction = lo.get(convo, 'call.direction')
		let isMissed = lo.get(convo, 'call.is_missed')

		let uid = lo.get(sb.usersOfConvo(convo), 0)
		let user = store.matchUser(uid) // could return primary
		uid = lo.get(user, 'id', uid)

		// header
		let $icon = null
		let $badge = null
		let number = ''
		if (direction === 'inbound') {
			$icon = (
				<Icon size='20' name='phone-incoming' stroke-width='2' class={isMissed ? 'text__danger' : 'text__success'} />
			)
			if (isMissed) $badge = <span class='ml-3 badge badge__danger text__semibold'>{sb.getCallStatusText(convo)}</span>
			number = lo.get(convo, 'call.from_number', '')
		} else if (direction === 'outbound') {
			$icon = (
				<Icon size='20' name='phone-outgoing' stroke-width='2' class={isMissed ? 'text__muted' : 'text__success'} />
			)
			if (isMissed)
				$badge = <span class='ml-3 badge badge__secondary text__semibold'>{sb.getCallStatusText(convo)}</span>
			number = lo.get(convo, 'call.to_number', '')
		}

		// body
		let callnumber = lo.get(convo, 'touchpoint.source', '')
		let inteid = `${store.me().account_id}.${callnumber}.call`
		let inte = store.matchIntegration()[inteid] || {}
		let $number = (
			<div class='d-flex align-items-center'>
				<img2 src={inte.logo_url} />
				<div class='ml-2'>{sb.getNumberDisplayName(inte)}</div>
			</div>
		)
		let $answer = <div class='text__muted'>{this.$t('no_answer')}</div>
		let $answerAt = <div class='text__muted'>{this.$t('no_answer')}</div>
		let answered = lo.get(convo, 'call.answered')
		let answerDevice = lo.get(convo, 'call.answered_device')
		let answeredAgents = lo.filter(convo.members, (member) => member.type === 'agent' && member.call_answered)
		if (answered) {
			answered = answered - lo.get(convo, 'call.started', 0)
			answered = answered / 1000 // milisecs => sec
			if (answered < 0) answered = lo.get(convo, 'call.duration', 0)
			if (answered < 0) answered = 0
			$answerAt = (
				<span class='clickable' vOn:click={() => this.onClickAnsweredAt(answered)}>
					{sb.displayClockTimer(answered)}
				</span>
			)
			//$answerAt = Math.floor(answered || 0) + ' ' + this.$t('second')
		}
		if (lo.size(answeredAgents)) {
			answeredAgents = lo.map(answeredAgents, (ag) => store.matchAgent()[ag.id] || {})
			$answer = this.renderAnsweredAgents(answeredAgents)
		}

		let callByAgent
		if (direction === 'outbound') {
			callByAgent = lo.find(convo.members, (member) => member.type === 'agent' && member.call_answered)
		}
		let $callBy = <div class='text__muted'>{this.$t('undefined')}</div>
		if (callByAgent) {
			callByAgent = store.matchAgent()[callByAgent.id] || {}
			$callBy = (
				<div class='d-flex align-items-center'>
					<Avatar size={20} agent={callByAgent} nodot />
					<div class='ml-2'>{sb.getAgentDisplayName(callByAgent)}</div>
				</div>
			)
		}

		let keyPress = lo.find(store.listMessages(this.cid), (ev) => ev.type === 'message_sent')
		let $keyPress = <div class='text__muted'>{this.$t('none')}</div>
		if (keyPress) {
			let text = lo.get(keyPress, 'data.message.text')
			if (text) {
				$keyPress = text
			}
		}

		// footer
		let footercls = 'call_convo_card_footer'
		let audio = lo.get(convo, 'call.recorded_audio', {})
		let duration = lo.get(convo, 'call.ended', 0) - lo.get(convo, 'call.started', 0)
		if (duration < 0) duration = 0
		duration = duration / 1000
		let $record = (
			<div class='call_convo_card_record_audio'>
				<div class='text__semibold d-flex align-items-center mb-2'>
					{this.$t('call_record_audio')}
					{audio.url && (
						<a href={audio.url} target='_blank' class='ml-2 text__white'>
							<Icon name='download' stroke-width='2' size='16' />
						</a>
					)}
				</div>
				{audio.url ? (
					<AudioPlayer
						ref='audio_player'
						highlight_suffix={lo.get(convo, 'call.duration_sec')}
						src={audio.url}
						graphs={audio.audio_waveform}
						show_duration
					/>
				) : (
					<div class='text__light' style='line-height: 1; opacity: 0.8'>
						{this.$t('invalid_record_audio')}
					</div>
				)}
			</div>
		)

		if (convo.state !== 'ended') {
			footercls += ' ongoing'
			duration = Date.now() - lo.get(convo, 'call.started')
			if (duration < 0) duration = 0
			duration = duration / 1000

			let call_card_state = this.$t('call_card.calling')
			if (convo.state == 'dialing') call_card_state = this.$t('call_card.dialing')
			$record = (
				<div class='call_record_audio'>
					<div class='mt-auto'>{call_card_state}...</div>
				</div>
			)
		}

		// if (isMissed) $duration = null

		let $footer = (
			<div class={footercls} style='justify-content: space-between;'>
				{$record}
			</div>
		)

		let is_missed = lo.get(convo, 'call.is_missed')
		if ((convo.state == 'ended' && !audio.url) || is_missed) $footer = null

		let durationSec = lo.get(convo, 'call.duration_sec')
		return (
			<div class='call_convo_card'>
				<div class='call_convo_card_header'>
					{$icon}
					<div class='ml-3 text__semibold' style='font-size: 20px'>
						{sb.displayPhoneNumber(number)}
					</div>
					{$badge}
					<Time class='text__muted ml-auto' time={lo.get(convo, 'call.started')} full />
				</div>
				<div class='call_convo_card_body'>
					<div class='call_convo_card_info_row'>
						<div class='call_convo_card_info_row_label'>{this.$t('call_center.numbers')}</div>
						<div class='call_convo_card_info_row_content'>{$number}</div>
					</div>
					{direction === 'inbound' ? (
						<fragment>
							<div class='call_convo_card_info_row'>
								<div class='call_convo_card_info_row_label'>{this.$t('answer_by')}</div>
								<div class='call_convo_card_info_row_content'>{$answer}</div>
							</div>
							<div class='call_convo_card_info_row'>
								<div class='call_convo_card_info_row_label'>{this.$t('duration')}</div>
								<div class='call_convo_card_info_row_content'>
									{durationSec && <Time duration time={durationSec} />}
								</div>
							</div>
							<div class='call_convo_card_info_row'>
								<div class='call_convo_card_info_row_label'>{this.$t('answer_at')}</div>
								<div class='call_convo_card_info_row_content'>{$answerAt}</div>
							</div>
							<div class='call_convo_card_info_row'>
								<div class='call_convo_card_info_row_label'>{this.$t('call_input_btn')}</div>
								<div class='call_convo_card_info_row_content'>{$keyPress}</div>
							</div>
						</fragment>
					) : (
						<fragment>
							<div class='call_convo_card_info_row'>
								<div class='call_convo_card_info_row_label'>{this.$t('outgoing_call_by')}</div>
								<div class='call_convo_card_info_row_content'>{$callBy}</div>
							</div>
							<div class='call_convo_card_info_row'>
								<div class='call_convo_card_info_row_label'>{this.$t('duration')}</div>
								<div class='call_convo_card_info_row_content'>
									{durationSec && <Time duration time={durationSec} />}
								</div>
							</div>
							{this.renderHangupReason(convo)}
						</fragment>
					)}
					<div class='call_convo_card_info_row'>
						<div class='call_convo_card_info_row_label'>{this.$t('conversations_history')}</div>
					</div>
				</div>
				<div style='overflow-y: auto; max-height:300px; border-top: 1px solid #e5e5e5'>
					<UserConversations
						uid={uid}
						cid={this.cid}
						vOn:convoSelect={this.convoSelect}
						dont_auto_select_convo={true}
						no_title
					/>
				</div>
				{$footer}
			</div>
		)
	},
}
