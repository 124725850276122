import sb from '@sb/util'
import lo from 'lodash'

const fakeGraps = lo.times(64, () => lo.random(10, 100) / 100)
const GRAPH_RATIO = 25 // max height og graph is 25px

export default {
	name: 'audio-player',
	props: ['src', 'graphs', 'simple', 'selected', 'title', 'highlight_suffix', 'show_duration'],

	data() {
		return {
			audio: null,
			componentId: '', //

			// for simple style
			playing: false,
		}
	},

	watch: {
		src() {
			this.loadAudio()
		},
	},

	created() {
		this.setComponentId()
	},

	mounted() {
		this.loadAudio()

		this.$root.$on('globalAudioPlayed', this.playSimpleAudio)
		this.$root.$on('globalAudioPaused', this.pauseSimpleAudio)
		if (this.audio) {
			this.audio.addEventListener('play', this.onPlayAudio)
			this.audio.addEventListener('pause', this.onPauseAudio)
			this.audio.addEventListener('ended', this.onEndAudio)
			this.audio.addEventListener('loadeddata', this.onLoadDone)
		}
	},

	destroyed() {
		this.$root.$off('globalAudioPlayed', this.playSimpleAudio)
		this.$root.$off('globalAudioPaused', this.pauseSimpleAudio)
		if (this.audio) {
			this.audio.removeEventListener('play', this.onPlayAudio)
			this.audio.removeEventListener('pause', this.onPauseAudio)
			this.audio.removeEventListener('ended', this.onEndAudio)
			this.audio.removeEventListener('loadeddata', this.onLoadDone)
			// reset audio
			this.audio.currentTime = 0
			this.audio.pause()
		}
	},

	methods: {
		onPlayAudio(e) {
			this.$forceUpdate()
			if (this.simple) return
			this.curTimeInterval = setInterval(() => {
				this.$forceUpdate()
			}, 500)
		},

		async onLoadDone(e) {
			this.$forceUpdate()
		},

		setComponentId() {
			this.componentId = sb.randomString(16)
		},

		onPauseAudio(e) {
			this.$forceUpdate()
			if (this.curTimeInterval) clearInterval(this.curTimeInterval)
		},

		onEndAudio() {
			this.audio.currentTime = 0
			this.audio.pause()
			this.$forceUpdate()
		},

		loadAudio() {
			let audio = document.createElement('audio')
			let source = document.createElement('source')

			source.src = this.src
			audio.appendChild(source)

			this.audio = audio
			this.$forceUpdate()
		},

		togglePlay() {
			if (!this.audio) return

			if (this.audio.paused) {
				this.audio.play()
			} else {
				this.audio.pause()
			}
			this.$forceUpdate()
		},

		toggleSimplePlay() {
			this.$emit('click')
			this.playing = !this.playing
			if (this.playing) {
				this.$openGlobalAudio(this.src, this.componentId)
				this.$setGlobalAudioTitle(this.title || '')
			} else {
				this.$pauseGlobalAudio()
			}
		},

		jump(sec) {
			this.audio.currentTime = sec
			this.audio.play()
			this.$forceUpdate()
		},

		renderGraphs() {
			if (!this.audio) return null
			let duration = this.audio.duration
			let currentTime = this.audio.currentTime
			let graphs = this.graphs || fakeGraps
			let maxGraph = lo.max(graphs)

			return (
				<div class='audio_player_graphs'>
					{lo.map(graphs, (graph, idx) => {
						let cls = 'audio_player_graph_wrapper'
						let height = Math.round((graph * GRAPH_RATIO) / maxGraph)
						if (height < 4) height = 4
						let style = `height: ${height}px;`
						let time = (duration / lo.size(graphs)) * idx
						if (currentTime > time) cls += ' active'
						let highlight = ''
						if (this.highlight_suffix > 0) if (time > duration - this.highlight_suffix) highlight = 'highlight'
						return (
							<div class={cls} key={idx} vOn:click={() => this.jump(time)}>
								<div class={'audio_player_graph ' + highlight} style={style} />
							</div>
						)
					})}
					{this.show_duration && <div style='margin-left: 10px'>{sb.displayClockTimer(duration)}</div>}
				</div>
			)
		},

		onClickAway() {
			this.$hideGlobalAudio()
		},

		renderSimpleAudio() {
			let cls = 'audio_player_wrapper simple'
			if (this.playing) cls += ' active'
			if (this.selected) cls += ' active'

			let timer = lo.get(this.audio, 'duration', 0)
			if (Number.isNaN(timer)) timer = 0

			return (
				<div class={cls} vOn:click_stop={this.toggleSimplePlay} v-clickaway={this.onClickAway}>
					<div class='audio_player_btn'>
						{!this.playing ? (
							<Icon name='player-play' size='12' />
						) : (
							<Icon name='player-pause' size='12' class='icon-filled' />
						)}
					</div>
					<div class='ml-3'>{sb.displayClockTimer(timer)}</div>
				</div>
			)
		},

		playSimpleAudio(componentId) {
			if (this.componentId === componentId) this.playing = true
		},

		pauseSimpleAudio(componentId) {
			if (this.componentId === componentId) this.playing = false
		},

		Play() {
			if (this.simple) this.toggleSimplePlay()
			else this.audio.play()
		},
	},

	render() {
		if (this.simple) return this.renderSimpleAudio()
		if (!this.audio) return null

		return (
			<div class='audio_player_wrapper' vOn:click={() => this.$emit('click')}>
				<div class='audio_player_btn' vOn:click={this.togglePlay}>
					{this.audio.paused ? <Icon name='player-play' size='20' /> : <Icon name='player-pause' size='20' />}
				</div>
				{this.renderGraphs()}
			</div>
		)
	},
}
