import store from '@sb/store'
import {MessageEditor} from '@sb/com'
import ConversationHeader from './conversation_header.js'
import MessageEvent from './messageEvent.js'
import Events from '../../commons/Events.js'
const lo = require('lodash')
const sb = require('@sb/util')
const emptymsgimg = require('../../assets/img/integration-help-image.png')
const convoemptyimg = require('../../assets/img/convos_empty.svg')

import FacebookPrivateReply from '../facebook_private_reply.js'
import UserActivities from './user_activities.js'
import Fragment from '@sb/com/fragment.js'
import EmailMessageEditor from './convo_email_editor.js'
import LexicalEditor from '../../commons/lexical-editor.js'

export default {
	name: 'conversation',
	props: ['cid', 'uid'],

	data() {
		return {
			initmsg: {},
			isEditorFocused: false,

			isShowFacebookPrivateReply: false,
			newConvoUid: '',
			newConvoCommentId: '',
			touchpoint: {},
			mode: 'convo', // convo, activity
			blockEmail: '',
			isShowEditor: false,

			// when reply email, need touchpoint of exactly email to reply, logic same as facebook_private_reply.js
			replyEmailMsg: {},
		}
	},

	computed: {
		com() {
			return {
				fetchMoreMessages: store.fetchMoreMessages,
				fetchMoreMessages2: store.fetchMoreMessages2,
				listMessages2: store.listMessages2,
				listMessages: store.listMessages,
				MessageEvent: MessageEvent,
			}
		},
	},

	mounted() {
		this.loadConvo(this.cid)

		store.onConvo(this, (convoM) => {
			if (Object.keys(convoM).includes(this.cid)) {
				this.$forceUpdate()
			}
		})

		store.onMessage(this, (convoid) => {
			if (convoid === this.cid) this.$forceUpdate()
		})

		store.onUserEvent(this, (userid) => {
			if (userid === this.uid) this.$forceUpdate()
		})

		store.onTyping(this, (convoid) => {
			if (this.cid !== convoid) return
			this.$forceUpdate()
		})

		this.seeingInternal = setInterval(() => {
			// let isbottom = this.distanceBottom < 100
			// if (isbottom && document.hasFocus() && !this.hidden) store.markReadConvo(this.cid)
			store.markSeeingConvo(this.cid)
		}, 10000)
		store.onDocument(this, 'keydown', (e) => {
			if (e.key !== 'Escape') return
			if (this.isShowFacebookPrivateReply) return this.OnShowFacebookPrivateReply()
		})
	},

	beforeDestroy() {
		store.markSeeingConvo('')
		clearInterval(this.seeingInternal)
	},

	watch: {
		cid(cid) {
			store.markSeeingConvo(cid)
			this.isShowEditor = false
			this.loadConvo(cid)

			this.initmsg = store.getConvoCacheMessage(this.cid) || {}
		},
	},

	methods: {
		ChangeMode(mode) {
			this.mode = mode
		},

		onCloseNewConvo() {
			this.isShowFacebookPrivateReply = false
		},

		async OnShowFacebookPrivateReply(param) {
			if (!param) {
				this.isShowFacebookPrivateReply = false
				return
			}

			let uid = param.user_id || this.uid
			this.currentUid = uid
			let convos = store.matchUserConvos(uid)
			if (convos.length == 0) {
				await store.fetchUserConvos(uid)
				if (this.currentUid !== uid) return // outdated
				convos = store.matchUserConvos(uid)
			}

			let connectorType = lo.get(param.touchpoint, 'channel')
			convos = lo.orderBy(convos, 'actived', 'desc')
			let openConvo = lo.find(convos, (convo) => {
				if (connectorType == 'facebook') {
					return lo.get(convo, 'touchpoint.channel') === 'facebook' && convo.state != 'ended'
				}

				if (connectorType == 'instagram') {
					return lo.get(convo, 'touchpoint.channel') === 'instagram' && convo.state != 'ended'
				}

				if (connectorType == 'zalo') {
					return lo.get(convo, 'touchpoint.channel') === 'zalo' && convo.state != 'ended'
				}

				if (connectorType == 'facebook_comment') {
					return lo.get(convo, 'touchpoint.channel') === 'facebook' && convo.state != 'ended'
				}

				if (connectorType == 'instagram_comment') {
					return lo.get(convo, 'touchpoint.channel') === 'instagram' && convo.state != 'ended'
				}
			})

			if (openConvo) {
				this.$emit('ucidChange', uid, openConvo.id, param.focus_editor_when_open_convo)
				this.isShowFacebookPrivateReply = false
				return
			}

			this.isShowFacebookPrivateReply = true
			this.newConvoUid = param.user_id
			this.newConvoCommentId = param.comment_id
			this.touchpoint = param.touchpoint || {}
		},

		JustFocus() {
			if (this.$refs.editor) this.$refs.editor.Focus()

			clearTimeout(this.focusEditorTimeout)
			this.isEditorFocused = true
			this.focusEditorTimeout = setTimeout(() => {
				this.isEditorFocused = false
			}, 800)
		},

		async loadConvo(cid) {
			if (!cid) return
			await store.subConvoRealtime([cid])
		},

		onStartNewConvo(param) {
			if (param) return this.OnShowFacebookPrivateReply(param)
			let convo = store.matchConvo(this.cid)
			var users = sb.usersOfConvo(convo)
			let touchpoint = convo.touchpoint || {}

			// create convo for subiz, must blank touchpoint id
			if (!lo.size(touchpoint) || touchpoint.channel === 'subiz') {
				touchpoint = {id: '', source: 'web', channel: 'subiz'}
			}
			this.OnShowFacebookPrivateReply({user_id: users[0], touchpoint: touchpoint})
		},

		async onSubmitAction(action) {
			if (action.action === 'tag') {
				let {error} = await store.tagConvo(this.cid, action.tag_id)
				if (error) return this.$showError(this.$t('cannot_tag'), 1000)
			}

			if (action.action === 'invite') {
				let {error} = await store.assignAgent(this.cid, {id: action.agent_id, type: 'agent'})
				if (error) return this.$showError(this.$t('cannot_assign'), 1000)
			}

			if (action.action === 'done') {
				let {error} = await store.endConvo(this.cid)
				if (error) return this.$showError(this.$t('cannot_complete'), 1000)
			}
		},

		async convertImgToPublishUrl(msg) {
			let deltas = sb.parseJSON(msg.quill_delta) || {ops: []}
			let new_deltas = {ops: []}
			for (let op of deltas.ops) {
				let ins = op
				let img = lo.get(op, 'insert.image')
				if (img && img.startsWith('data:image/png;base64')) {
					let url = lo.get(op, 'insert.image')
					let file = dataURLtoFile(url, `image_copy.png`)
					let res = await store.uploadLocalFile(file)
					if (res.error) return this.$showError(this.$t(res.error))
					ins = {
						insert: {
							image: res.url,
						},
					}
				}
				new_deltas.ops.push(ins)
			}
			return new_deltas
		},

		onSubmitEmailReply() {
			let msg = this.$refs.editor.GetMessage()
			console.log('onSubmitEmailReply', msg)
			let convo = store.matchConvo(this.cid)
			let touchpoint = lo.get(convo, 'touchpoint') || {}

			// auto add from for email channel
			let fields = msg.fields || []
			let fromField = lo.find(fields, (field) => field.key === 'from')
			if (!fromField) {
				fields.push({key: 'from', value: JSON.stringify(`${store.me().account_id}@mail.subiz.com`)})
				msg = lo.cloneDeep(msg)
				msg.fields = fields
			}
			store.sendMessage2({touchpoint, msg, convoid: this.cid, userids: [this.uid]})
		},

		async onSubmitMsg(msg) {
			if (!this.cid) return
			let convo = store.matchConvo(this.cid)
			let touchpoint = lo.get(convo, 'touchpoint') || {}

			if (msg.format === 'html') {
				//let delta = await this.convertImgToPublishUrl(msg)
				//let quill = JSON.stringify(delta)
				//msg.quill_delta = quill
				//msg.text = sb.deltaToHtml(delta.ops)
			}

			// auto add from for email channel
			if (touchpoint.channel === 'email') {
				let fields = msg.fields || []
				let fromField = lo.find(fields, (field) => field.key === 'from')
				if (!fromField) {
					fields.push({key: 'from', value: JSON.stringify(`${store.me().account_id}@mail.subiz.com`)})
					msg = lo.cloneDeep(msg)
					msg.fields = fields
				}
			}

			store.sendMessage2({touchpoint, msg, convoid: this.cid, userids: [this.uid]})
			this.initmsg = {format: 'plaintext', text: ''}
			this.saveToMemCache(this.initmsg)
		},

		renderTyping() {
			let convo = store.matchConvo(this.cid) || {}
			let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')
			if (convo && convo.state === 'ended' && connectorType !== 'email') return null
			if (sb.isUserBanned(this.user)) return null
			let typingMembers = lo.filter(
				convo.members,
				(member) => store.isMemberTyping(this.cid, member, 'holla') && member.id !== lo.get(store.me(), 'id'),
			)
			let member = lo.get(typingMembers, '0') || {}
			let typingstyle =
				'display: flex; align-items: center; font-size: 12px; padding: 2px 10px; height: 22px; overflow: hidden'
			if (!member.id) {
				return <div class='d-flex align-items-center' style={typingstyle}></div>
			}
			let name = ''
			if (member.type === 'user') name = this.$t('cust')
			else {
				let ag = store.matchAgent()[member.id]
				if (ag) {
					name = sb.getAgentDisplayName(ag)
				} else {
					name = this.$t('someone')
				}
			}

			return (
				<div style={typingstyle}>
					<div class='dot-typing' />
					<div class='text__muted text__truncate ml-4'>{name + ' ' + this.$t('is_typing').toLowerCase()}</div>
				</div>
			)
		},

		saveToMemCache(msg) {
			store.setConvoCacheMessage(this.cid, msg)
		},

		renderEditor(convo) {
			let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')
			if (!convo) connectorType = this.cid.split('.')[1]
			//if (!connectorType) return null

			if (lo.get(convo, 'assigned_to.strategy') === 'first_reply') {
				// first active agent
				let agentMembers = lo.filter(convo.members, (member) => member.type == 'agent')
				if (!lo.find(agentMembers, (m) => m.id === store.me().id)) {
					return (
						<div style='border-top: 1px solid #d5d5d5; padding: 10px'>
							<div class='text__muted text__center'>Hội thoại đã được xử lý bởi một người khác</div>
						</div>
					)
				}
			}
			if (convo && convo.state === 'ended' && connectorType !== 'email') return null
			if (sb.isUserBanned(this.user)) return null
			//if (convo && !lo.some(convo.members, mem => mem.id == store.me().id)) return null // not agent

			let acceptAttachment = connectorType === 'zalo' ? 'image/png, image/jpeg, image/gif' : undefined
			let isemail = connectorType === 'email'
			let html = isemail

			let canSend = true
			let cls = 'convo_input'
			if (connectorType === 'facebook_comment') {
				let events = lo.orderBy(store.listMessages(convo.id), ['created'], 'asc')
				let messagesSent = lo.filter(events, (event) => event.type === 'message_sent')
				if (messagesSent.length < 2) return null
				let isDelete = lo.get(messagesSent, '1.data.message.pongs', []).some((p) => p.type === 'delete' && !p.ack_error)
				if (isDelete) return <div class='text__muted text__center'>{this.$t('comment_has_been_deleted')}</div>
				cls += ' convo_input__comment'
			}

			if (connectorType === 'google_review') {
				let isRemoved = lo.get(convo, 'google_review.is_removed')
				if (isRemoved) return <div class='text__muted text__center'>{this.$t('review_has_been_deleted')}</div>
				return
			}

			let availabelEmail = lo.filter(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
			)
			if (!lo.size(availabelEmail) && isemail) {
				return (
					<div style='border-radius: 7px; background-color: #e8e8e8; width: 100%; padding: 10px; text-align: center;'>
						<span style='opacity: 0.7; pointer-events: none'>{this.$t('error_no_business_email_address')}</span>&nbsp;
						<span
							class='link'
							vOn:click={(_) => {
								this.$router.push({name: 'settings.email'})
							}}
						>
							{this.$t('set_up_now')}
						</span>
					</div>
				)
			}
			let acc_id = store.me().account_id
			let fblinks = true
			if (connectorType === 'facebook_comment' || connectorType === 'facebook' || connectorType === 'instagram')
				fblinks = false
			if (acc_id !== 'acpxkgumifuoofoosble' && acc_id !== 'acqsulrowbxiugvginhw') fblinks = false

			let $editor = (
				<MessageEditor
					ref='editor'
					style={this.isEditorFocused ? 'background-color: rgba(255, 233, 166, 0.5)' : ''}
					selectProduct={true}
					key={this.cid}
					cid={this.cid}
					uid={this.uid}
					initEvent={this.initmsg}
					html={html}
					acceptAttachment={acceptAttachment}
					submittable={canSend}
					connectorType={connectorType}
					vOn:submit={this.onSubmitMsg}
					email={isemail}
					vOn:action={this.onSubmitAction}
				/>
			)

			// if you want to revert to previous editor, comment this declaration
			$editor = (
				<LexicalEditor
					plaintext
					ref='editor'
					autofocus
					class={{just_focus: this.isEditorFocused, lexical_input__white: true}}
					placeholder={this.$t('placeholder_editor')}
					initMessage={this.initmsg}
					vOn:change={(msg) => {
						this.initmsg = msg
						this.saveToMemCache(msg)
						this.onMessageChanged(msg)
					}}
					submit_on_enter
					has_like_button
					useTemplate
					transform_dynamic_field
					has_template_manage_btn
					no_preview_mode
					useSuggestion
					useProduct
					cid={this.cid}
					uid={this.uid}
					template_connector_type='subiz'
					vOn:submit={this.submitMesage}
					vOn:action={this.onSubmitAction}
					fblinks={fblinks}
				/>
			)
			if (html)
				$editor = (
					<EmailMessageEditor
						ref='editor'
						class={{just_focus: this.isEditorFocused, convo_email_editor: true}}
						key={this.cid}
						cid={this.cid}
						uid={this.uid}
						has_submit_btn
						initMessage={this.replyEmailMsg}
						vOn:change={(msg) => (this.replyEmailMsg = msg)}
						submittable={canSend}
						connectorType={connectorType}
						vOn:submit={this.onSubmitEmailReply}
						email={isemail}
						vOn:action={this.onSubmitAction}
					/>
				)

			if (isemail && !this.isShowEditor) {
				$editor = null
			}

			if (connectorType === 'form') {
				$editor = null
			}

			return (
				<div class={cls} key={this.cid}>
					{$editor}
				</div>
			)
		},

		openManageModal() {
			this.isMessageTemplateManageModalOpenned = true
		},

		onMessageChanged: lo.throttle(function (message) {
			if (!message.text) return
			if (message.text === '<p></p>' || message.text === '<p><br></p>') return
			let text = sb.lexicalToPlainText(message.text)
			if (!text) return
			if ((this.cid || '').startsWith('create')) return
			store.sendTyping(this.cid)
		}, 800),

		onReply(msg) {
			let convo = store.matchConvo(this.cid)
			this.isShowEditor = true
			this.replyEmailMsg = msg
			return this.JustFocus()
		},

		renderMessages() {
			return (
				<Events
					uid={this.uid}
					cid={this.cid}
					com={this.com}
					vOn:submitMsg={this.onSubmitMsg}
					vOn:startNewConvo={this.onStartNewConvo}
					vOn:reply={this.onReply}
					vOn:blockEmail={(e) => (this.blockEmail = e)}
				/>
			)
		},

		submitMesage() {
			let msg = lo.cloneDeep(this.initmsg)
			console.log('submitttttttttteeeeeee', sb.lexicalToPlainText(msg.text, {use_old_emoji: true}))
			//msg.is_template = true
			msg.text = sb.lexicalToPlainText(msg.text, {use_old_emoji: true})
			msg.format = 'plaintext'
			this.onSubmitMsg(msg)
		},
	},

	render() {
		if (!this.cid) {
			return (
				<div class='messages justify-content-center align-items-center'>
					<img2 src={require('../../assets/img/empty_1.svg')} />
					<p class='badge badge__secondary mt-5 '>{this.$t('you_dont_have_conversation')}</p>
					<FacebookPrivateReply
						show={this.isShowFacebookPrivateReply}
						user_id={this.newConvoUid}
						comment_id={this.newConvoCommentId}
						vOn:changeUcid={(uid, cid) => this.$emit('ucidChange', uid, cid)}
						vOn:close={this.onCloseNewConvo}
						touchpoint={this.touchpoint}
					/>
				</div>
			)
		}

		let convo = store.matchConvo(this.cid)
		if (!convo)
			return (
				<div class='messages justify-content-center align-items-center'>
					<img src={require('../../assets/img/empty_1.svg')} />
					<FacebookPrivateReply
						show={this.isShowFacebookPrivateReply}
						user_id={this.newConvoUid}
						comment_id={this.newConvoCommentId}
						vOn:changeUcid={(uid, cid) => this.$emit('ucidChange', uid, cid)}
						vOn:close={this.onCloseNewConvo}
						touchpoint={this.touchpoint}
					/>
				</div>
			)

		let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')
		let $content = (
			<fragment>
				{this.renderMessages()}
				<div class={`convo_input_wrapper ${connectorType}`}>
					{this.renderEditor(convo)}
					{this.renderTyping()}
				</div>
			</fragment>
		)

		// dont display content when agent dont have permission
		let scopes = lo.get(store.me(), 'scopes', [])
		let notPerm = !scopes.includes('view_others')
		let members = lo.get(convo, 'members', [])
		let notMember =
			!lo.find(members, (member) => member.id === lo.get(store.me(), 'id')) ||
			lo.find(members, (mem) => mem.id === store.me().id && mem.membership === 'left')

		if (notPerm && notMember) {
			$content = (
				<div class='pt-5 pr-3 pl-3 text__center text__muted'>
					<Icon name='lock' size='40' stroke-width='1' />
					<div class='mt-2'>{this.$t('you_dont_have_permission_to_view_other_convo')}</div>
					<div>{this.$t('you_dont_have_permission_to_view_other_convo_cta_text')}</div>
				</div>
			)
		}

		let cls = 'messages ' + connectorType
		return (
			<div class={cls}>
				<FacebookPrivateReply
					show={this.isShowFacebookPrivateReply}
					user_id={this.newConvoUid}
					convo_id={this.cid}
					comment_id={this.newConvoCommentId}
					vOn:changeUcid={(uid, cid) => this.$emit('ucidChange', uid, cid)}
					vOn:close={this.onCloseNewConvo}
					touchpoint={this.touchpoint}
				/>
				<ConversationHeader conversation={convo} cid={convo.id} vOn:newConvo={this.onStartNewConvo} />
				{$content}
			</div>
		)
	},
}

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = window.atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new window.File([u8arr], filename, {type: mime})
}
