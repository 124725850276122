import ImageAttachments from '../activities/conversation/imageAttachments.js'
import LinkAttachments from '../activities/conversation/linkAttachments.js'
import AudioAttachments from '../activities/conversation/audioAttachments.js'
import Reaction from '../activities/conversation/reaction.js'
import FileAttachments from '../activities/conversation/fileAttachments.js'
import TextMessage from '../activities/conversation/textMessage.js'

var sb = require('@sb/util')
const lo = require('lodash')

import store from '@sb/store'

export default {
	name: 'comment-event',
	props: ['ev'],

	data() {
		return {
			showActionDropdown: false,
			showInternalActionDropdown: false,
		}
	},

	render() {
		let ev = Object.assign({}, this.ev)
		ev.by = Object.assign({}, this.ev.by)
		ev.by.type = 'comment'
		if (sb.getMsgField(ev, 'facebook_comment_type') === 'command') return null

		var connectorId = this.integration_connector_id
		let $lastseen = null

		// render quote
		let $quote = null
		let attachments = lo.get(ev, 'data.message.attachments', [])

		let cls = 'message_container message_container__comment'

		let textcls = ''
		return (
			<div class='d-flex flex-column' style='position: relative; max-width: 100%'>
				<div class={cls}>
					<TextMessage ev={ev} connectorId={connectorId} class={textcls} task_comment />
					{this.renderAction(ev)}
					{this.renderInternalAction(ev)}
				</div>
				<div style='max-width: 200px'>
					<ImageAttachments ev={ev} />
					<LinkAttachments ev={ev} />
					<AudioAttachments ev={ev} />
					<FileAttachments ev={ev} />
				</div>
				<div class={'message_container message_container__comment'}>
					<Reaction ev={ev} />
				</div>
			</div>
		)
	},

	methods: {
		renderInternalAction(ev) {
			if (this.readonly) return null
			if (!this.is_private) return null
			let byId = lo.get(ev, 'by.id')
			let $actions = (
				<div class='message_internal_actions' key={this.eid + 'actions'}>
					<more-horizontal-icon
						size='1x'
						class='message_internal_action'
						vOn:click={this.toogleInternalActionDropdown}
					/>
				</div>
			)

			let $bookmarkaction = (
				<bookmark-icon
					v-tooltip={this.$t('bookmark')}
					size='1x'
					class='message_internal_action'
					vOn:click={(_) => this.$emit('bookmark', ev)}
				/>
			)
			if (lo.get(ev, 'data.message.pinned')) {
				$bookmarkaction = (
					<bookmark-icon
						v-tooltip={this.$t('unbookmark')}
						size='1x'
						style='fill: #ffedb7; color: #dfcd97'
						class='message_internal_action'
						vOn:click={(_) => this.$emit('bookmark', ev)}
					/>
				)
			}

			if (this.showInternalActionDropdown) {
				let $like = <thumbs-up-icon size='1x' class='message_internal_action' vOn:click={(_) => this.pong('like')} />
				let $sad = <frown-icon size='1x' class='message_internal_action' vOn:click={(_) => this.pong('sad')} />

				// if already like
				let pongs = lo.get(ev, 'data.message.pongs', [])
				let alreadylike = false
				let alreadysad = false
				lo.map(pongs, (reaction) => {
					if (reaction.member_id != store.me().id) return
					if (reaction.type == 'like') alreadylike = true
					if (reaction.type == 'sad') alreadysad = true
				})

				if (alreadylike) $like = null
				if (alreadysad) $sad = null

				$actions = (
					<div
						class='message_internal_actions'
						v-clickaway={this.hideInternalActionDropdown}
						key={this.eid + 'actions'}
					>
						<more-horizontal-icon
							size='1x'
							class='message_internal_action'
							vOn:click={this.toogleInternalActionDropdown}
						/>
						{$bookmarkaction}
						<Icon name="corner-up-left"
							v-tooltip={this.$t('reply')}
							size='16'
							class='message_internal_action'
							vOn:click={(_) => this.$emit('quote', ev)}
						/>
						{$like}
						{$sad}
					</div>
				)
			}

			return $actions
		},

		pong(type) {
			if (!type) return
			store.pongMessage(this.cid, this.eid, {data: {message: {pongs: [{type}]}}})
		},

		pushReaction(ev, type) {
			let message = lo.get(ev, 'data.message', {})
			if (!type) return

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})
			if (isDelete) return

			message = lo.cloneDeep(message)

			let fields = [
				{key: 'facebook_comment_type', value: JSON.stringify('command')},
				{key: 'facebook_comment_command', value: JSON.stringify(type)},
				{key: 'facebook_comment_target_id', value: JSON.stringify(this.eid)},
			]
			message.fields = fields
			message.text = type
			this.$emit('submitMsg', message)
		},

		hideInternalActionDropdown() {
			this.showInternalActionDropdown = false
		},

		toogleInternalActionDropdown() {
			this.showInternalActionDropdown = !this.showInternalActionDropdown
		},

		hideActionDropdown() {
			this.showActionDropdown = false
		},

		toogleActionDropdown() {
			this.showActionDropdown = !this.showActionDropdown
		},

		countReaction(ev) {
			let pongs = lo.get(ev, 'data.message.pongs', [])
			let countReaction = {
				like: 0,
				love: 0,
				haha: 0,
				wow: 0,
				sad: 0,
				angry: 0,
				me_liked: 0,
				hide: 0,
				delete: 0,
			}
			lo.forEach(pongs, (reaction) => {
				let memberId = lo.get(reaction, 'member_id', '').toString()
				if (reaction.type === 'like' && memberId && memberId.indexOf('us') < 0) {
					countReaction.me_liked++
				}
				countReaction[reaction.type]++
			})
			return countReaction
		},

		renderAction(ev) {
			let connectorType = this.integration_connector_type
			if (connectorType !== 'facebook_comment') return

			let byType = lo.get(ev, 'by.type')

			if (byType !== 'user') return null

			let countReaction = this.countReaction(ev)

			let fbPostLink = sb.getMsgField(ev, 'facebook_comment_permalink_url')
			let $detail = null
			if (fbPostLink) {
				$detail = (
					<div class='dropdown__item' vOn:click={(_) => window.open(fbPostLink, '_blank')}>
						{this.$t('fb_link_post')}
					</div>
				)
			}

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})

			let $like = (
				<div
					class={'message_actions__action ' + (isDelete ? 'message_actions__action__disabled' : '')}
					vOn:click={(_) => this.pushReaction(ev, 'like')}
					v-tooltip={this.$t('like')}
				>
					<Icon name="thumb-up" size='18' />
				</div>
			)

			if (countReaction.me_liked) {
				$like = (
					<div
						class={'message_actions__action ' + (isDelete ? 'message_actions__action__disabled' : '')}
						vOn:click={(_) => this.pushReaction(ev, 'unlike')}
						v-tooltip={this.$t('unlike')}
					>
						<Icon name="thumb-down" size='18' />
					</div>
				)
			}

			let $delete = (
				<div
					vOn:click={(_) => this.pushReaction(ev, 'delete')}
					class={'dropdown__item ' + (isDelete ? 'dropdown__item__disabled' : '')}
				>
					{this.$t('delete')}
				</div>
			)

			let $hide = (
				<div
					vOn:click={(_) => this.pushReaction(ev, 'hide')}
					class={'dropdown__item ' + (isDelete ? 'dropdown__item__disabled' : '')}
				>
					{this.$t('hide')}
				</div>
			)
			if (countReaction.hide) {
				$hide = (
					<div
						vOn:click={(_) => this.pushReaction(ev, 'unhide')}
						class={'dropdown__item ' + (isDelete ? 'dropdown__item__disabled' : '')}
					>
						{this.$t('unhide')}
					</div>
				)
			}

			let $dropdown = null
			if (this.showActionDropdown) {
				$dropdown = (
					<div class='dropdown'>
						{$hide}
						{$delete}
						{$detail}
					</div>
				)
			}

			let cls = 'message_actions'
			if (this.showActionDropdown) cls += ' message_actions__show'
			return (
				<div class={cls} v-clickaway={this.hideActionDropdown}>
					{$like}
					<div v-tooltip={this.$t('more_action')} class='message_actions__action' vOn:click={this.toogleActionDropdown}>
						<Icon name="dots-vertical" size='18' />
						{$dropdown}
					</div>
				</div>
			)
		},
	},
}
