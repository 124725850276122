import store from '@sb/store'
import ConvoTags from '../convoTags.js'
const lo = require('lodash')
const sb = require('@sb/util')
import EditConvoSubject from './edit_convo_subject.js'

export default {
	name: 'convo-header',
	props: ['conversation', 'cid'],
	data() {
		return {
			showMembers: false,

			tagLoadings: {},
			inviteAgentLoadings: {},

			ratingFailed: false,
			updatingConvo: false,

			showDropdown: false,

			updatingMute: false,

			completing: false,

			askingRate: false,
			ratingAsked: false,
			isEditSubject: false,

			privateChatView: '', // do not reset
		}
	},

	watch: {
		cid() {
			this.showMembers = false
			this.tagLoadings = {}
			this.inviteAgentLoadings = {}
			this.ratingFailed = false
			this.updatingConvo = false
			this.showDropdown = false
			this.updatingMute = false
			this.completing = false
			this.askingRate = false
			this.ratingAsked = false
		},
	},
	mounted() {
		this.$root.$on('showPrivateChat', this.onShowPrivateChat)
		this.$once('hook:beforeDestroy', () => this.$root.$off('showPrivateChat', this.onShowPrivateChat))

		store.onDocument(this, 'keyup', this.onKeyup)
		store.onUser(this, (user) => {
			let userids = sb.usersOfConvo(this.conversation)
			if (!userids.find((id) => id === user.id)) return
			this.$forceUpdate()
		})

		store.onMessage(this, (convoid) => {
			if (!this.conversation) return
			let supconvoid = 'csinternal' + this.conversation.id
			if (convoid === this.conversation.id || convoid === supconvoid) this.$forceUpdate()
		})

		store.onConvo(this, (convoM) => {
			if (!this.conversation) return
			let supconvoid = 'csinternal' + this.conversation.id
			if (Object.keys(convoM).includes(supconvoid)) this.$forceUpdate()
			if (Object.keys(convoM).includes(this.conversation.id)) this.$forceUpdate()
		})
	},

	methods: {
		onShowPrivateChat(v) {
			this.privateChatView = v
		},

		async muteConvo() {
			this.hideDropdown()
			if (this.updatingMute) return
			this.updatingMute = true
			let {error} = await store.muteConvo(this.conversation.id)
			this.updatingMute = false

			if (error) return this.$showError(this.$t('muted_convo_fail'))
			this.$showSuccess(this.$t('mute_convo'), 1500)
		},

		async unwatchConvo() {
			this.hideDropdown()
			let {error} = await store.markAsSpam(this.conversation.id)

			if (error) return this.$showError(this.$t('unwatch_convo_fail'))
			this.$showSuccess(this.$t('success'), 1500)
		},

		async rewatchConvo() {
			this.hideDropdown()
			let {error} = await store.unmarkSpam(this.conversation.id)

			if (error) return this.$showError(this.$t('rewatch_convo_fail'))
			this.$showSuccess(this.$t('success'), 1500)
		},

		async unmuteConvo() {
			this.hideDropdown()
			if (this.updatingMute) return
			this.updatingMute = true
			let {error} = await store.unmuteConvo(this.conversation.id)
			this.updatingMute = false

			if (error) return this.$showError(this.$t('unmuted_convo_fail'))
			this.$showSuccess(this.$t('unmute_convo'), 1500)
		},

		hideDropdown() {
			this.showDropdown = false
		},

		toogleDropdown() {
			this.showDropdown = !this.showDropdown
		},

		async requestRating() {
			this.hideDropdown()
			if (this.askingRate || this.ratingAsked) return
			this.askingRate = true
			let {error} = await store.sendConvoEv(this.conversation.id, {type: 'conversation_rating_requested'})
			this.askingRate = false
			if (error) return this.$showError(error)
			this.ratingAsked = true
		},

		renderConvoDropdown() {
			if (!this.showDropdown) return null

			let $loadingMute = null
			if (this.updatingMute) $loadingMute = <Spinner class='mr-4' mode='dark' />
			let $muteItem = (
				<div class='dropdown__item' vOn:click={this.muteConvo}>
					<Icon name='bell-off' size='18' class='convo_header__dropdown_icon' /> {$loadingMute} {this.$t('mute_convo')}
				</div>
			)
			if (this.conversation.mute) {
				$muteItem = (
					<div class='dropdown__item' vOn:click={this.unmuteConvo}>
						<Icon name='bell' size='18' class='convo_header__dropdown_icon' /> {$loadingMute} {this.$t('unmute_convo')}
					</div>
				)
			}

			let $unwatchitem = null
			let topic = store.getTopic(this.cid)
			let convo = store.matchConvo(this.cid) || {}
			if (convo.marked_as_spam) {
				$unwatchitem = (
					<div class='dropdown__item' vOn:click={this.rewatchConvo}>
						<Icon name='inbox' size='18' class='convo_header__dropdown_icon' stroke-width='2' />
						{this.$t('unmark_spam')}
					</div>
				)
			} else {
				$unwatchitem = (
					<div class='dropdown__item' vOn:click={this.unwatchConvo}>
						<Icon name='archive' size='18' class='convo_header__dropdown_icon' stroke-width='2' />
						{this.$t('mark_as_spam')}
					</div>
				)
			}

			let $rateitem = null

			let ratings = lo.get(this.conversation, 'ratings', [])
			let rejected = lo.filter(ratings, (e) => e.no_rating).length > 0

			if (!rejected && !this.ratingAsked) {
				$rateitem = (
					<div class='dropdown__item' vOn:click={this.requestRating}>
						<Icon name='star' size='18' class='convo_header__dropdown_icon' />
						{this.$t('request_rating')}
					</div>
				)
			}

			let connectorType = lo.get(this.conversation, 'touchpoint.channel', 'subiz')
			if (connectorType !== 'subiz') {
				$rateitem = (
					<div class='dropdown__item dropdown__item__disabled' v-tooltip={this.$t('rating_disabled')}>
						<Icon name='star' size='18' class='convo_header__dropdown_icon' />
						{this.$t('request_rating')}
					</div>
				)
			}

			return (
				<div class='dropdown' style='right: 10px;'>
					<div class='dropdown__item' vOn:click={() => (this.isEditSubject = true)}>
						<Icon name='edit' size='18' class='convo_header__dropdown_icon' />
						{this.$t('edit_convo_subject')}
					</div>
					{$muteItem}
					{/*
					<div class='dropdown__item' vOn:click={this.toggleAssignAgent}>
						<UserIcon size='18' class='convo_header__dropdown_icon' /> {this.$t('add_agents')}
					</div>
            */}
					{$unwatchitem}
					{$rateitem}
					{/*<div class="dropdown__item">
						<InfoIcon size="18" class="convo_header__dropdown_icon" />
						Details
					</div>
				 */}
				</div>
			)
		},

		onKeyup(e) {
			switch (e.keyCode) {
				case 27: // ESC
					this.toggleAssignAgent(false)
					// this.toggleConvertTicket(false)
					// this.toggleAddTag(false)
					break
			}
		},

		async updateSubject(subject) {
			this.updatingConvo = true
			await store.updateConvo({id: this.conversation.id, subject})
			this.updatingConvo = false
		},

		renderRating() {
			// user rejected rating
			let ratings = lo.get(this.conversation, 'ratings', [])
			let rejected = lo.filter(ratings, (e) => e.no_rating).length > 0
			// get the last rating
			let rating = lo.orderBy(
				lo.filter(ratings, (e) => !e.no_rating),
				'rated',
				'desc',
			)[0]

			// user rejected
			if (rejected && !rating) return // this.$t('user_refused_to_rate')

			if (!rating) return null
			let r = this.$t('great')
			let $ratingImg = <img class='convo_header__rating__img' src={require('../../assets/img/greating.svg')} />
			let point = parseInt(rating.rating) || 0
			if (point < 2) {
				$ratingImg = <img class='convo_header__rating__img' src={require('../../assets/img/bad_feedback.svg')} />
				r = this.$t('poor')
			} else if (point < 4) {
				$ratingImg = (
					<img
						v-tooltip={this.$t('average')}
						class='convo_header__rating__img'
						src={require('../../assets/img/normal_feedback.svg')}
					/>
				)
				r = this.$t('average')
			}

			return (
				<div class='convo_header__rating mr-4' v-tooltip={rating.comment || r}>
					{$ratingImg}
				</div>
			)
		},

		async onUntagConvo(tag) {
			this.tagLoadings[tag.id] = true
			this.$forceUpdate()
			let ret = await store.untagConvo(this.conversation.id, tag.id)
			this.tagLoadings[tag.id] = false
			this.$forceUpdate()
		},

		async onTagConvo(tag) {
			this.tagLoadings[tag.id] = true
			this.$forceUpdate()
			let ret = await store.tagConvo(this.conversation.id, tag.id)
			this.tagLoadings[tag.id] = false
			this.$forceUpdate()
		},

		renderTags() {
			let taggeds = lo.map(lo.get(this.conversation, 'tags'))
			let alltags = lo.map(store.matchTag())
			alltags = lo.filter(alltags, (tag) => !tag.type)
			return (
				<div class='convo_header__tags'>
					<ConvoTags
						tags={taggeds}
						allTags={alltags}
						editable
						vOn:delete={this.onUntagConvo}
						loadingTags={this.tagLoadings}
						vOn:add={this.onTagConvo}
						dropdownFull={lo.size(taggeds) < 3}
					/>
				</div>
			)
		},

		renderMembers(members, nagent) {
			let agentM = store.matchAgent()
			return lo.map(members, (mem) => {
				let agent = agentM[mem.id]
				if (!agent) return null
				let $removebtn = null
				if (agent.id == store.me().id) {
					$removebtn = (
						<button class='btn btn__sm btn__danger' vOn:click={() => this.left(agent.id)}>
							{this.$t('left_conversation')}
						</button>
					)
					if (nagent == 1) {
						$removebtn = (
							<button disabled class='btn btn__sm btn__danger' v-tooltip={this.$t('you_are_the_last_agent')}>
								{this.$t('left_conversation')}
							</button>
						)
					}
				} else if (store.me().is_supervisor) {
					$removebtn = (
						<button class='btn btn__sm btn__danger' vOn:click={() => this.left(agent.id)}>
							{this.$t('left_conversation')}
						</button>
					)
					if (nagent == 1) {
						$removebtn = (
							<button disabled class='btn btn__sm btn__danger' v-tooltip={this.$t('you_are_the_last_agent')}>
								{this.$t('left_conversation')}
							</button>
						)
					}
				}

				let $super = null
				if (agent.is_supervisor)
					$super = (
						<Icon
							name='shield'
							stroke-width='2'
							size='16'
							style='margin-top: -2px'
							v-tooltip={this.$t('supervisor')}
							class='text__secondary'
						/>
					)
				let $last_seen = <div class='assign_agent__member_email'>{this.$t('unread')}</div>
				let last_seen = mem.seen_at
				if (mem.last_sent > last_seen) last_seen = mem.last_sent
				if (last_seen)
					$last_seen = (
						<div class='assign_agent__member_email'>
							{this.$t('last_view_at')}&nbsp;
							<Time time={last_seen} />
						</div>
					)
				let $sent = null
				if (mem.last_sent) {
					$sent = (
						<fragment>
							<span class='text__semibold text__primary'>{this.$t('sent')}</span>
							&nbsp;·&nbsp;
						</fragment>
					)
				}

				if (lo.get(this.conversation, 'touchpoint.channel') == 'call') {
					$last_seen = null
					$sent = null
				}

				if (mem.call_rang && !mem.call_answered) {
					$last_seen = <div class='assign_agent__member_email text__danger'>{this.$t('no_pickup')}</div>
				}

				if (mem.call_answered) {
					if (!mem.call_rang) {
						$last_seen = <div class='assign_agent__member_email text__primary'>{this.$t('phone_pickup')}</div>
					} else {
						let del = Math.floor((mem.call_answered - mem.call_rang) / 1000)
						if (del <= 1)
							$last_seen = <div class='assign_agent__member_email text__primary'>{this.$t('pickup_quickly')}</div>
						else
							$last_seen = (
								<div class='assign_agent__member_email text__primary'>
									{this.$t('answer_after')}&nbsp;{del}&nbsp;{this.$t('second')}
								</div>
							)
					}
				}

				return (
					<div class='assign_agent__member'>
						<Avatar class='assign_agent__member_avatar' agent={agent} size='md' online />
						<div class='assign_agent__member_info'>
							<div class='assign_agent__member_name'>
								{sb.getAgentDisplayName(agent, store.me())} {$super}
							</div>
							<div class='d-flex align-items-center'>
								{$sent}
								{$last_seen}
							</div>
						</div>
						{$removebtn}
					</div>
				)
			})
		},

		renderAssignAgents() {
			let users = lo.filter(this.conversation.members, (m) => m.type === 'user')

			let $users = lo.map(users, (mem) => {
				let user = store.matchUser(mem.id)
				let $last_seen = null
				let last_seen = mem.seen_at
				if (mem.last_sent > last_seen) last_seen = mem.last_sent
				if (last_seen)
					$last_seen = (
						<div class='assign_agent__member_email'>
							{this.$t('last_view_at')}&nbsp;
							<Time time={last_seen} />
						</div>
					)

				if (lo.get(this.conversation, 'touchpoint.channel') == 'call') {
					$last_seen = null
				}

				return (
					<div class='assign_agent__member'>
						<Avatar class='assign_agent__member_avatar' user={user} size='md' />
						<div class='assign_agent__member_info'>
							<div class='assign_agent__member_name'>{sb.getUserDisplayName(user)}</div>
							{$last_seen}
						</div>
					</div>
				)
			})

			let agentids = sb.agentsOfConvo(this.conversation)
			let agents = lo.filter(this.conversation.members, (m) => m.type === 'agent')
			let agentM = store.matchAgent()

			let superAgents = lo.filter(agents, (mem) => {
				let agent = lo.get(agentM, mem.id)
				if (!agent) return null
				if (!agent.is_supervisor) return
				return true
				//if (this.conversation.state === 'ended') $removebtn = null
			})

			let nagent = lo.filter(this.conversation.members, (mem) => {
				if (mem.type != 'agent') return
				if (mem.membership === 'left') return
				if (!agentM[mem.id]) return
				return true
			}).length

			let $superAgents = this.renderMembers(superAgents, nagent)

			let sentAgents = lo.filter(agents, (mem) => {
				let agent = lo.get(agentM, mem.id)
				if (!agent) return null
				if (agent.is_supervisor) return
				if (mem.membership === 'left') return
				if (!mem.last_sent) return
				return true
			})
			let $sentAgents = this.renderMembers(sentAgents, nagent)

			let invitedAgents = lo.filter(agents, (mem) => {
				let agent = lo.get(agentM, mem.id)
				if (!agent) return null
				if (mem.last_sent) return
				if (mem.membership === 'left') return
				if (agent.is_supervisor) return
				return true
			})
			let $invitedAgents = this.renderMembers(invitedAgents, nagent)
			let uninvitedAgents = lo.filter(agentM, (ag) => !agentids.includes(ag.id) && ag.state === 'active')
			uninvitedAgents = lo.filter(uninvitedAgents, (ag) => ag.type === 'agent')
			let $invite = null
			if (uninvitedAgents.length > 0) {
				let $uninvitedAgents = lo.map(uninvitedAgents, (agent) => {
					let $inviteAgentBtn = (
						<div class='btn btn__sm btn__primary no-shrink' vOn:click={(_) => this.inviteAgent(agent)}>
							<Icon name='plus' style='margin-top: -2px' stroke-width='2' size='16' /> {this.$t('invite')}
						</div>
					)
					if (this.inviteAgentLoadings[agent.id]) {
						$inviteAgentBtn = <Spinner class='mr-2' mode='blue' />
					}

					return (
						<div class='assign_agent__member'>
							<Avatar class='assign_agent__member_avatar' agent={agent} size='md' online />
							<div class='assign_agent__member_info'>
								<div class='assign_agent__member_name'>{sb.getAgentDisplayName(agent, store.me())}</div>
								<div class='assign_agent__member_email'>{agent.email}</div>
							</div>
							{$inviteAgentBtn}
						</div>
					)
				})
				$invite = (
					<div>
						<div class='assign_agent__label'>{this.$t('invite_more_agents')}</div>
						{$uninvitedAgents}
					</div>
				)
			}

			let modalcls = 'modal'
			if (!this.showMembers) modalcls += ' modal__hide'

			return (
				<div>
					<div class={modalcls}>
						<div class='modal__overlay' vOn:click={() => this.toggleAssignAgent(false)}></div>
						<div class='modal__container'>
							<div class='assign_agent modal__background'>
								<div class='assign_agent__header'>
									<div>{this.$t('members')}</div>
									<Icon
										name='x'
										v-tooltip={this.$t('close')}
										class='btn__close'
										vOn:click_stop={this.toggleAssignAgent}
									/>
								</div>
								<div class='assign_agent__body'>
									{$users}
									{$sentAgents}
									{$invitedAgents}
									{$superAgents}
									{$invite}
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},

		toogleInternalChat(val) {
			this.$root.$emit('showPrivateChat', 'chat:csinternal' + this.conversation.id)
		},

		toggleAssignAgent(val) {
			this.hideDropdown()
			if (val === true || val === false) this.showMembers = val
			else this.showMembers = !this.showMembers
		},

		async left(agentid) {
			let confirm = await this.$confirm({
				style: 'danger',
				ok: this.$t('left_conversation'),
				cancel: this.$t('back'),
				title: this.$t('leave_this_conversation'),
				description: this.$t('leave_this_conversation_desc'),
			})
			if (!confirm) return
			let out = await store.unassignAgent(this.cid, {id: agentid})
			if (out.error) return this.$showError(this.$t('something_wrong_retry_again'))
			this.toggleAssignAgent(false)
		},

		async inviteAgent(agent) {
			this.inviteAgentLoadings[agent.id] = true
			this.$forceUpdate()
			let {error} = await store.assignAgent(this.conversation.id, {id: agent.id, type: 'agent'})
			this.inviteAgentLoadings[agent.id] = false
			this.$forceUpdate()
			if (error) return this.$showError(this.$t('something_wrong_retry_again'))
		},

		renderSource(convo) {
			let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')
			if (connectorType === 'facebook_comment') {
				let pageid = lo.get(convo, 'touchpoint.source', '')
				let link = 'https://facebook.com/' + pageid

				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}
				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{this.$t('facebook_comment_short')}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={inte.logo_url} />
						<a class='ml-2 convo_header__source_link' href={link} target='_blank' v-tooltip={inte.name}>
							{inte.name}
						</a>
					</div>
				)
			}

			if (connectorType === 'form') {
				let wp = store.matchWebPlugin()[convo.touchpoint.source]
				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{this.$t('Form')}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={require('../../assets/img/form.svg')} v-tooltip={'Form'} />
						{wp ? (
							<a
								class='ml-2 convo_header__source_link'
								href={'/web_plugin/popup/' + convo.touchpoint.source + '/design'}
								target='_blank'
							>
								{this.$t('Form')} {wp.name}
							</a>
						) : (
							<span class='ml-2 text__muted'>{this.$t('Form')}</span>
						)}
					</div>
				)
			}
			if (connectorType === 'instagram_comment') {
				let pageid = lo.get(convo, 'touchpoint.source', '')
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{this.$t('instagram_comment_short')}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={inte.logo_url} />
						<a
							class='ml-2 convo_header__source_link'
							href={`https://www.instagram.com/${lo.get(inte, 'username')}`}
							target='_blank'
							v-tooltip={inte.name}
						>
							{inte.name}
						</a>
					</div>
				)
			}

			if (connectorType === 'instagram') {
				let pageid = lo.get(convo, 'touchpoint.source', '')
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{this.$t('instagram')}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={inte.logo_url} />
						<a
							class='ml-2 convo_header__source_link'
							href={`https://www.instagram.com/${lo.get(inte, 'username')}`}
							target='_blank'
							v-tooltip={inte.name}
						>
							{inte.name}
						</a>
					</div>
				)
			}

			if (connectorType === 'facebook') {
				let pageid = lo.get(convo, 'touchpoint.source', '')
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}
				let link = 'https://m.me/' + pageid

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{this.$t('Facebook')}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={inte.logo_url} />
						<a class='ml-2 convo_header__source_link' href={link} target='_blank' v-tooltip={inte.name}>
							{inte.name}
						</a>
					</div>
				)
			}

			if (connectorType === 'subiz') {
				let source = lo.get(convo, 'source', '')
				source = source.replace(/(^\w+:|^)\/\//, '')
				source = source.split('/')[0] // get domain only
				let site = store.matchSite()[`web:${source}`] || {}

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{this.$t('website')}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img
							class='convo_header__source_img'
							src={site.image_url || 'https://www.google.com/s2/favicons?domain=' + source}
							alt=''
						/>
						<a class='ml-2 convo_header__source_link' href={lo.get(convo, 'source')} target='_blank' v-tooltip={source}>
							{source}
						</a>
					</div>
				)
			}

			if (connectorType === 'zalo') {
				let pageid = lo.get(convo, 'touchpoint.source', '')
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}
				let link = 'https://zalo.me/' + pageid
				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{this.$t('zalo')}</div>
						<Icon name='chevron-right' size='12' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={inte.logo_url} />
						<a class='ml-2 convo_header__source_link' href={link} target='_blank' v-tooltip={inte.name}>
							{inte.name}
						</a>
					</div>
				)
			}

			if (connectorType === 'email') {
				return (
					<div class='d-flex align-items-center'>
						<img
							class='convo_header__source_img'
							src={require('../../assets/img/email_channel.svg')}
							v-tooltip={this.$t('Email')}
						/>
						<a class='ml-2 convo_header__source_link' href='javascript:;'>
							{this.$t('Email')}
						</a>
					</div>
				)
			}

			if (connectorType === 'call') {
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_link'>{this.$t('call_center')}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img2 class='convo_header__source_img' src={inte.logo_url} />
						<div class='ml-2 text__muted' href='javascript:;'>
							{sb.getNumberDisplayName(inte)}
						</div>
					</div>
				)
			}

			if (connectorType === 'google_review') {
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{'Google Review'}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={require('../../assets/img/google_review_channel.svg')} />
						<span class='ml-2 convo_header__source_link'>{inte.name || this.$t('unknown')}</span>
					</div>
				)
			}

			if (connectorType === 'google_message') {
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{'Google Message'}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={require('../../assets/img/google_message_channel.svg')} />
						<span class='ml-2 convo_header__source_link'>{inte.name || this.$t('unknown')}</span>
					</div>
				)
			}

			if (connectorType === 'google_question') {
				let inteid = sb.getIntegrationIdFromConvo(convo)
				let inte = store.matchIntegration()[inteid] || {}

				return (
					<div class='convo_header__source'>
						<div class='convo_header__source_channel'>{'Google Question'}</div>
						<Icon name='chevron-right' size='14' class='ml-2 mr-2 text__muted' />
						<img class='convo_header__source_img' src={require('../../assets/img/google_question_channel.svg')} />
						<span class='ml-2 convo_header__source_link'>{inte.name || this.$t('unknown')}</span>
					</div>
				)
			}
		},

		async onDoneClick() {
			if (this.completing) return
			this.completing = true
			await store.endConvo(this.conversation.id)
			await sb.sleep(100)
			this.completing = false
		},

		renderInternalChatButton() {
			let $unread = null
			let cls = ''
			let supconvoid = 'csinternal' + this.conversation.id
			let supconvo = store.matchConvo(supconvoid) || {id: supconvoid}
			if (!store.isConvoRead(supconvoid)) {
				cls = 'text__danger'
				$unread = (
					<div
						class='dot dot__danger'
						style='border: 2px solid white; position: absolute; right: -4px; bottom: -4px; width: 12px; height: 12px'
					></div>
				)
			} else {
				if (lo.get(supconvo, 'last_message_sent.created') > 0)
					$unread = (
						<div
							class='dot dot__secondary'
							style='border: 2px solid white; position: absolute; right: -4px; bottom: -4px; width: 12px; height: 12px'
						></div>
					)
			}

			if (lo.startsWith(this.privateChatView, 'chat:' + supconvoid)) {
				cls = 'text__primary'
			}
			return (
				<div class='convo_header__rating mr-4' style='position: relative' vOn:click={this.toogleInternalChat}>
					<Icon name='message-dots' size='18' class={cls} />
					{$unread}
				</div>
			)
		},

		renderRateButton() {
			let $rateitem = null
			let connectorType = lo.get(this.conversation, 'touchpoint.channel', 'subiz')
			if (connectorType !== 'subiz') {
				return (
					<div class='convo_header__rating convo_header__rating__disabled mr-4' v-tooltip={this.$t('rating_disabled')}>
						<Icon name='star' size='18' />
					</div>
				)
			}

			let ratings = lo.get(this.conversation, 'ratings', [])
			let rejected = lo.filter(ratings, (e) => e.no_rating).length > 0
			// get the last rating
			let rating = lo.orderBy(
				lo.filter(ratings, (e) => !e.no_rating),
				'rated',
				'desc',
			)[0]

			if (!rejected && !this.ratingAsked) {
				$rateitem = (
					<Icon
						name='star'
						size='18'
						v-tooltip={this.$t('request_rating')}
						vOn:click={this.requestRating}
						class='convo_header__rating mr-4'
					/>
				)
			}

			if (this.ratingAsked) {
				$rateitem = <Icon name='star' size='18' class='mr-4 text__muted' v-tooltip={this.$t('waiting_for_user')} />
			}

			if (rating) return this.renderRating()
			return $rateitem
		},

		renderConvoTime() {
			let last = lo.get(this.conversation, 'last_message_sent.created') || this.conversation.actived

			return (
				<span class='text__muted text__truncate'>
					<Time short time={this.conversation.created} /> {' ' + this.$t('in') + ' '}
					<Time duration time={(last - this.conversation.created) / 1000} />
				</span>
			)
		},

		renderAgentSelectButton() {
			let agentM = store.matchAgent()

			let convo = this.conversation
			let assigneds = lo.get(convo, 'assigned_to.agent_ids', []) || []

			let agents = lo
				.filter(convo.members, (m) => m.type === 'agent' || m.type === 'bot')
				.filter((m) => {
					if (m.type == 'bot') return m.membership == 'active'
					let ag = agentM[m.id]
					if (!ag) return false
					if (m.membership === 'left') return false
					// call convo doesnt have assigned_to
					if (lo.get(convo, 'touchpoint.channel') === 'call') return ag
					if (assigneds.indexOf(m.id) != -1) return true
					if (m.last_sent) return true
					if (ag.is_supervisor) return false
					return true
				})
				.map((m) => agentM[m.id])

			agents = lo.orderBy(agents, 'id')
			let $avatars = <AvatarGroup agents={agents} size={3} xs rounded />

			if (agents.length == 0) {
				$avatars = this.$t('unassigned')
			}
			return (
				<button
					type='button'
					class='btn btn__sm btn__white d-flex align-items-center mr-3'
					style='display: flex; height: 32px'
					vOn:click={() => (this.showMembers = true)}
				>
					{$avatars}
					<Icon name='chevron-down' size='14' stroke-width='2' class='ml-2 text__muted' />
				</button>
			)
		},

		renderCallStatus() {
			return null
			let $dot = null
			let text = ''

			if (this.conversation.state === 'dialing') {
				$dot = <span class='dot dot__warning dot__lg mr-2' style='margin-top: 0' />
				text = this.$t('dialing')
			} else if (this.conversation.state === 'ended') {
				$dot = <div class='convo_header__done_icon mr-2' style='width: 12px; height: 12px' />
				text = this.$t('done')
			} else if (this.conversation.state === 'missed') {
				$dot = <span class='dot dot__danger dot__success mr-2' style='margin-top: 0' />
				text = this.$t('minssed_call')
			}
			return (
				<div class='btn btn__white d-flex align-items-center mr-3' style='display: flex; height: 32px'>
					{$dot}
					{text}
				</div>
			)
		},

		renderConvoStatusButton() {
			let connectorType = lo.get(this.conversation, 'touchpoint.channel', 'subiz')

			if (connectorType === 'call') return this.renderCallStatus()
			if (
				connectorType === 'facebook_comment' ||
				connectorType === 'instagram_comment' ||
				connectorType === 'google_review' ||
				connectorType === 'google_question'
			) {
				return (
					<button
						type='button'
						class='btn btn__sm btn__white mr-3 align-items-center'
						style='display: flex; height: 32px; opacity: 0.8; cursor: auto; width: 125px;'
					>
						<span class='dot dot__danger dot__lg mr-2' style='margin-top: 0;' />
						{this.$t('public')}
						<Icon
							name='help'
							size='14'
							class='text__muted ml-2'
							v-tooltip={this.$t('complete_conversation_is_disabled')}
						/>
					</button>
				)
			}
			if (this.conversation.state === 'ended') {
				return (
					<Dropdown2
						vOn:select={this.onSelectCompleteConvo}
						right
						dropdown_width={200}
						mode='custom'
						selected={'complete_convo_done'}
						extra_item_cls={'dropdown_item__bold'}
						items={[
							{
								id: 'complete_convo_done',
								label: this.$t('completed'),
								icon: (
									<img
										src={require('../../assets/img/done.svg')}
										style='width: 16px; height: 16px; position: relative;'
									/>
								),
								desc: this.$t('complete_convo_desc'),
								no_truncate: true,
								checked: true,
							},
							{
								id: 'create_convo',
								label: this.$t('create_conversation'),
								icon: (
									<Icon
										name='circle-plus'
										stroke-width='2'
										size='16'
										class='text__success'
										style='position: relative;'
									/>
								),
								desc: this.$t('create_convo_desc'),
								no_truncate: true,
							},
						]}
					>
						<button
							type='button'
							class='btn btn__white btn__sm mr-3 align-items-center'
							style='display: flex; height: 32px; width: 130px;'
						>
							{this.completing ? (
								<Spinner mode='dark' size='12' static class='mr-2' />
							) : (
								<div class='convo_header__done_icon mr-2' style='width: 12px; height: 12px' />
							)}
							{this.$t('completed')}
							<Icon name='chevron-down' style='flex-shrink: 0' stroke-width='2' class='text__muted ml-2' size='14' />
						</button>
					</Dropdown2>
				)
				return (
					<button
						type='button'
						class='btn btn__sm btn__white mr-3 align-items-center'
						disabled
						style='display: flex; height: 32px; opacity: 0.8; cursor: auto; width: 125px;'
					>
						<div class='convo_header__done_icon mr-2' style='width: 12px; height: 12px' />
						{this.$t('completed')}
					</button>
				)
			}

			if (lo.get(this.conversation, 'assigned_to.strategy') === 'first_reply') {
				let joined = lo.find(this.conversation.members, (member) => {
					return member.id === store.me().id
				})

				if (!joined) return null
			}

			//if (this.completing) return <Spinner mode='blue' size='20' static />
			return (
				<Dropdown2
					vOn:select={this.onSelectCompleteConvo}
					right
					dropdown_width={200}
					extra_item_cls={'dropdown_item__bold'}
					selected={'opening_convo'}
					mode='custom'
					items={[
						{
							id: 'opening_convo',
							label: this.$t('opening_convo'),
							icon: <div class='dot dot__success dot__lg' style='top: 9px; position: relative;' />,
							desc: this.$t('opening_convo_desc'),
							checked: true,
							no_truncate: true,
						},
						{
							id: 'complete_convo',
							label: this.$t('completed'),
							icon: (
								<img
									src={require('../../assets/img/done_gray.svg')}
									style='width: 12px; height: 12px; position: relative;'
								/>
							),
							desc: this.$t('complete_convo_desc'),
							no_truncate: true,
						},
					]}
				>
					<button
						type='button'
						class='btn btn__white btn__sm mr-3 align-items-center'
						style='display: flex; height: 32px; width: 130px;'
					>
						{this.completing ? (
							<Spinner mode='dark' size='12' static class='mr-2' />
						) : (
							<span class='dot dot__success dot__lg mr-2' style='margin-top: 0;' />
						)}
						{this.$t('opening_convo')}
						<Icon name='chevron-down' stroke-width='2' style='flex-shrink: 0' class='text__muted ml-auto' size='14' />
					</button>
				</Dropdown2>
			)
			return (
				<div vOn:click={this.onDoneClick} v-tooltip={this.$t('complete_convo')}>
					<div class='convo_header__done_btn' />
				</div>
			)
		},

		onSelectCompleteConvo(item) {
			if (item.id === 'complete_convo') this.onDoneClick()
			if (item.id === 'create_convo') this.$emit('newConvo')
		},

		renderConvoTitle() {
			let $trash = null
			let title
			let $title
			if (lo.get(this.conversation, 'touchpoint.channel') === 'email') {
				lo.map(store.listMessages(this.conversation.id), (ev) => {
					if (ev.type !== 'message_sent') return
					if (sb.getMsgField(ev, 'subject')) title = sb.getMsgField(ev, 'subject')
					title = title || ''
				})

				$title = (
					<div class='convo_header__title_edit text__truncate' v-tooltip={title}>
						{title}
					</div>
				)
			} else {
				$title = (
					<Input2
						v-tooltip_bottom-start={this.$t('title')}
						class='convo_header__title_edit'
						key={this.conversation.id}
						type='text'
						value={this.conversation.subject}
						v-tooltip={this.conversation.subject}
						vOn:change={this.updateSubject}
						loading={this.updatingConvo}
					/>
				)
			}

			return (
				<div class='convo_header__title'>
					{$trash}
					{$title}
				</div>
			)
		},
	},

	render() {
		if (!this.conversation) return null

		return (
			<div style='position: relative'>
				<EditConvoSubject cid={this.cid} vOn:close={() => (this.isEditSubject = false)} open={this.isEditSubject} />
				<div class='convo_header'>
					<div class='flex__1 mr-4' style='overflow: hidden'>
						<div class='d-flex align-items-center' style='overflow: hidden'>
							{this.renderSource(this.conversation)}
							{this.conversation.marked_as_spam && (
								<div class='ml-2 badge badge__danger text__semibold no-shrink' style='padding: 3px 8px'>
									spam
								</div>
							)}
						</div>
						{this.renderTags()}
					</div>
					<div class='convo_header__actions'>
						{this.renderAgentSelectButton()}
						{this.renderConvoStatusButton()}
						<div class='convo_header__dropdown_container' v-clickaway={this.hideDropdown}>
							<div class='btn btn__sm btn__white mr-3' vOn:click={this.toogleDropdown} style='height: 32px'>
								<Icon
									name='dots'
									size='18'
									v-tooltip={this.$t('more')}
									stroke-width='2'
									class='convo_header__clickable'
								/>
							</div>
							{this.renderConvoDropdown()}
						</div>
						{this.renderAssignAgents()}
					</div>
				</div>
			</div>
		)
	},
}
