var lo = require('lodash')
import DB from './lsdb.js'

let KvMaxSize = 500
// A key-value database with a cache layer
// db.count item
// db.clear()
// bulkput(items)
// list(limit)
// delete(key)
export default class {
	constructor (schema) {
		this.name = schema
		schema += '_kv'
		this.dead = false
		this.db = new DB(schema)
		this.cache = {}

		this.initialized = false
	}

	refreshCache () {
		if (this.dead) return
		let inmemItemM = {}
		let allitems = this.db.all('items')
		lo.map(allitems, item => {
			inmemItemM[item.key] = item.value
		})
		this.cache = inmemItemM
	}

	init () {
		if (this.initialized) return
		let inmemItemM = {}

		console.time('KV' + this.name)
		let allitems = this.db.all('items')
		lo.map(allitems, item => {
			inmemItemM[item.key] = item.value
		})
		this.cache = inmemItemM

		// If the cache is full. Randomly remove half of the cache. credit to thanos
		// this is so simple since we don't need to keep track of last modified time
		// for each key. Implementing a LRU cache won't add much value.
		let allkeys = Object.keys(this.cache)
		if (allkeys.length > KvMaxSize) {
			let liveChance = KvMaxSize / 2 / allkeys.lenth

			for (var i = 0; i < KvMaxSize; i++) {
				let live = Math.random() <= liveChance
				if (live) continue

				let key = allkeys[i]
				delete this.cache[key]
				this.db.removes('items', [key])
			}
		}

		this.initialized = true
		console.timeEnd('KV' + this.name)
	}

	all () {
		if (this.dead) return []
		if (!this.initialized) return []
		return this.cache
	}

	match (key) {
		if (this.dead) return
		if (!key) return
		if (!this.initialized) return
		return this.cache[key]
	}

	// keys could be a string or array
	// put('1', {id: '1', name: 'thanh'})
	// put({id: '1', name: 'thanh'}, 'id')
	// put([{id: '1', name: 'thanh'},{id: '2', name: 'van'}], 'id')
	put (key, value) {
		if (!key) return
		if (this.dead) return []
		if (!this.initialized) throw new Error('uninitialized')
		// if multiple key

		if (typeof key === 'string') {
			this.cache[key] = value
			this.db.put('items', key, { key, value })
			return value
		}

		var objs = []
		let items = []
		if (!Array.isArray(key)) objs = [key]
		else objs = key

		var path = value || 'id'
		lo.map(objs, obj => {
			let key = lo.get(obj, path)
			this.cache[key] = obj
			items.push({ key: key, value: obj })
		})

		lo.map(items, item => this.db.put('items', item.key, { key: item.key, value: item.value }))
		return objs
	}

	// delete an record in a schema
	del (key) {
		if (this.dead) return
		if (!this.initialized) throw new Error('uninitialized')
		delete this.cache[key]
		this.db.removes('items', [key])
	}

	destroy () {
		// clear all data
		// this.db.clear('items')

		this.dead = true
		this.db = undefined
		this.cache = undefined
	}
}
