import lo from 'lodash'
import sb from '@sb/util'
import store from '@sb/store'

export default {
	name: 'task-noti-counter',
	props: ['extraClass', 'extraStyle'],

	mounted() {
		let notibody = store.matchNotis('task') || {}
		if (lo.size(notibody.notifications) == 0) store.fetchNotis('task')
		store.onNoti(this, (cat) => {
			if (cat == 'task') this.$forceUpdate()
		})
	},

	render() {
		let notibody = store.matchNotis('task') || {}
		if (!lo.get(notibody, 'unread')) return null
		return (
			<div class={this.extraClass} style={this.extraStyle}>
				{notibody.unread}
			</div>
		)
	},
}
