import Delta from 'quill-delta'
const sb = require('@sb/util')
import store from '@sb/store'
const lo = require('lodash')

import Quill from 'quill'

// 'image/png, image/jpeg, image/gif'
// subiz, zalo, email for message template
export default {
	name: 'private-message-editor',
	props: ['placeholder', 'acceptAttachment', 'initEvent', 'task_description'],

	data() {
		return {
			openEmoji: false, // true if emoji window is opened
			message: {},

			currentSelectedIndex: 0,
			isShowing: false,
			forceShow: false, // when agent click on message template icon
			filteredActions: [],
			start: '@',
			mentionQuery: '',
		}
	},

	watch: {
		initEvent(msg) {
			this.applyMessage(msg)
		},
	},

	mounted() {
		let toolbar = null
		let theme = null

		let bindings = {
			enter: {key: 13, handler: (_) => this.onKeyPress(13)},
		}
		if (this.task_description) bindings = {}
		this.editor = new Quill(this.$refs.text_editor, {
			placeholder: this.placeholder || this.$t('placeholder_editor'),
			formats: this.task_description ? ['mention'] : ['bold', 'mention', 'emoji', 'image'],
			modules: {
				toolbar,
				keyboard: {
					bindings,
				},
			},
			theme,
		})

		// handle paste image
		this.editor.clipboard.addMatcher('img', (node, delta) => {
			// temporary disable matcher, use onPaste clipboard instead,
			return new Delta()
			if (this.editorDisabled) return

			if (
				node.src &&
				(node.src.startsWith('https://') || node.src.startsWith('http://') || node.src.startsWith('//'))
			) {
				this.message.attachments = this.message.attachments || []
				this.message.attachments.push({
					type: 'file',
					mimetype: 'image/jpeg', // dump data
					size: 434, // dump date
					name: 'clipboard',
					url: node.src,
				})
				this.doMessageChange()
				this.$forceUpdate()
				this.editor.focus()
				return new Delta()
			}

			let file = dataURLtoFile(node.src, 'clipboard')
			this.uploadLocalFiles({target: {result: node.src, files: [file]}})
			return new Delta()
		})
		let onEditorChange = lo.throttle((a) => this.doMessageChange(), 50)
		this.editor.on('text-change', onEditorChange)
		this.$once('hook:beforeDestroy', () => this.editor.off('text-change', onEditorChange))

		//this.editor.keyboard.addBinding({ key: 13 }, _ => this.onKeyup(13)) // enter
		this.message = {attachments: [], fields: []}
		// move cursor to the end
		this.editor.setSelection(this.editor.getLength(), 0)
		this.editor.focus()

		this.updateFuse()
		store.onAccount(this, () => {
			this.updateFuse()
			this.$forceUpdate()
		})

		document.body.addEventListener('keyup', this.onKeyup)
		document.body.addEventListener('keydown', this.onKeydown)
		window.addEventListener('paste', this.onPaste)
		this.applyMessage(this.initEvent)
	},

	destroyed() {
		document.body.removeEventListener('keyup', this.onKeyup)
		document.body.removeEventListener('keydown', this.onKeydown)
		window.removeEventListener('paste', this.onPaste)
	},

	methods: {
		SetMessage(msg) {
			this.applyMessage(msg)
		},

		async onPaste(ev) {
			if (this.task_description) return
			let files = lo.get(ev, 'clipboardData.files')
			if (!lo.size(files)) return
			let $editor = this.$refs.text_editor
			// dont use ev.path because ClipboardEvent doesnt have path property on Windown 10
			//if (!lo.includes(ev.path, $editor)) return

			//if (!$editor.$el.contains(document.activeElement)) return
			if (!$editor.contains(document.activeElement)) return

			let currentContet = this.editor.getContents()

			let localId = sb.randomString(16)
			let file = files[0]
			let attachments = []
			let tempUrl = await sb.getBlobUrlFromFile(file)
			this.message.attachments = this.message.attachments || []
			this.message.attachments.push({
				_local_id: localId,
				type: 'file',
				mimetype: file.type, // dump data
				size: file.size, // dump date
				name: file.name,
				url: tempUrl,
				_file: file,
			})
			console.log('privateMessageEditor onPaste 3333333333333333', this.message)
			this.doMessageChange()
			this.$forceUpdate()
			this.editor.setContents(currentContet)
		},

		GetContents() {
			return this.editor.getContents()
		},

		SetContents(content) {
			this.editor.setContents(content)
		},

		updateFuse() {
			let actions = []
			let agents = store.matchAgent()
			lo.map(agents, (ag) => {
				if (ag.state !== 'active') return
				if (ag.type !== 'agent') return
				actions.push({text: sb.getAgentDisplayName(ag), action: 'mention', agent_id: ag.id})
			})

			this.allActions = actions
		},

		async doSearchUser(query) {
			if (!this.task_description) return
			let searching = Date.now()
			this.searching = searching

			let res = await store.search('user', query, '', 15, ['contact.fullname', 'contact.email', 'contact.phone'])
			if (this.searching !== searching) return this.foundUsers
			this.searching = 0
			this.foundUsers = lo.get(res, 'body.hits', [])
			console.log('doSearchUser Doneeeeeeeeeeee', query, this.foundUsers)
			await store.fetchUsers(lo.map(this.foundUsers, (hit) => hit.document_id))
			return this.foundUsers
		},

		async onQueryChange(quill_delta) {
			return
			quill_delta = sb.trimQuillDelta(quill_delta)
			if (!quill_delta) return
			let last = quill_delta.ops[quill_delta.ops.length - 1]
			let query = ''
			if (!last || !last.insert || typeof last.insert != 'string') query = ''
			else query = last.insert

			let qs = query.split(' ')
			query = qs[qs.length - 1]
			if (!query.startsWith(this.start)) {
				//			if (!query.startsWith(this.start)) {
				this.isShowing = false
				this.filteredActions = []
				return
			}
			this.isShowing = true
			// clean this.start if user has deleted it
			if (this.forceShow) this.isShowing = true
			this.forceShow = false
			query = query.substr(this.start.length, query.length - this.start.length)
			// smart trigger
			//
			let users = await this.doSearchUser(query)

			this.filteredActions = lo.filter(this.allActions, (action) => {
				let text = sb.unicodeToAscii(action.text || '').toLowerCase()
				let helpText = sb.unicodeToAscii(action.help_text || '').toLowerCase()
				let search = sb.unicodeToAscii(query).toLowerCase()
				return text.indexOf(search) > -1 || helpText.indexOf(search) > -1
			})
			lo.each(this.foundUsers, (hit) => {
				this.filteredActions.push({
					text: hit.name,
					action: 'mention',
					user_id: hit.document_id,
				})
			})

			if (this.filteredActions.length === 0) this.isShowing = false
			this.currentSelectedIndex = 0
		},

		onKeydown(e) {
			//if (!this.isShowing) return

			//insert ascii character and spacebar to mentionQuer
			switch (e.keyCode) {
				case 38: // UP
					if (!this.isShowing) return
					this.currentSelectedIndex--
					if (this.currentSelectedIndex < 0) this.currentSelectedIndex = lo.size(this.filteredActions) - 1
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				case 40: // DOWN
					if (!this.isShowing) return
					this.currentSelectedIndex++
					if (this.currentSelectedIndex >= lo.size(this.filteredActions)) this.currentSelectedIndex = 0
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				case 50: // @
					if (!this.editor.hasFocus()) break
					if (e.shiftKey) {
						let nearastAt = this.editor.getSelection() ? this.editor.getSelection().index : -1
						if (nearastAt < 0) break
						// only show mention when previous character is backspace or @ is first character
						if (nearastAt > 0 && this.editor.getText(nearastAt - 1, 1) !== ' ') break
						this.mentionQuery = '@'
						this.nearastAt = this.editor.getSelection() ? this.editor.getSelection().index : -1
					} else {
						this.mentionQuery += e.key
					}
					this.toggleFilteredActions()
					break
				case 8: // BACKSpACE
					if (!this.editor.hasFocus()) break
					if (this.mentionQuery) {
						this.mentionQuery = this.mentionQuery.substring(0, this.mentionQuery.length - 1)
					}
					this.toggleFilteredActions()
					break
				case 27: // ESC
					if (!this.editor.hasFocus()) break
					this.clearMentions()
					break
				case 13: // ENTER
					if (!this.editor.hasFocus()) break
					var action = this.filteredActions[this.currentSelectedIndex]
					if (action) this.applySuggestion(action)
					this.clearMentions()
					break
				default:
					// ascii character
					if (e.keyCode >= 48 || e.keyCode <= 90) {
						this.mentionQuery += e.key
						this.toggleFilteredActions()
					} else {
						this.clearMentions()
					}
					break
			}
		},

		async toggleFilteredActions() {
			let query = this.mentionQuery
			if (!query.startsWith(this.start)) {
				//			if (!query.startsWith(this.start)) {
				this.isShowing = false
				this.filteredActions = []
				return
			}
			this.isShowing = true
			// clean this.start if user has deleted it
			if (this.forceShow) this.isShowing = true
			this.forceShow = false
			query = query.substr(this.start.length, query.length - this.start.length)
			// smart trigger
			//
			let users = await this.doSearchUser(query)

			this.filteredActions = lo.filter(this.allActions, (action) => {
				let text = sb.unicodeToAscii(action.text || '').toLowerCase()
				let helpText = sb.unicodeToAscii(action.help_text || '').toLowerCase()
				let search = sb.unicodeToAscii(query).toLowerCase()
				return text.indexOf(search) > -1 || helpText.indexOf(search) > -1
			})
			lo.each(this.foundUsers, (hit) => {
				this.filteredActions.push({
					text: hit.name,
					action: 'mention',
					user_id: hit.document_id,
				})
			})

			if (this.filteredActions.length === 0) this.isShowing = false
		},

		clearMentions() {
			this.isShowing = false
			this.filteredActions = []
		},

		onKeyup(e) {
			// ESC and ENTER was handled by
			return
			if (!this.isShowing) return
			switch (e.keyCode) {
				case 27: // ESC
					this.hideSuggestion()
					break
				case 13: // ENTER
					if (!this.isShowing) return
					var action = this.filteredActions[this.currentSelectedIndex]
					this.applySuggestion(action)
			}
		},

		hideSuggestion() {
			if (!this.isShowing) return
			this.isShowing = false
		},

		toggleEmoji() {
			this.editor.focus()
			this.openEmoji = !this.openEmoji
		},

		hideEmoji() {
			this.openEmoji = false
		},

		emojiPick(code) {
			var selection = this.editor.getSelection(true)
			this.editor.insertEmbed(selection.index, 'emoji', code, true)
			this.editor.setSelection(selection.index + 1, 0)

			this.hideEmoji()
			this.editor.focus()
		},

		renderEmojiPopup() {
			if (!this.openEmoji) return null

			let $items = lo.map(EmojiList, (emoji) => {
				return (
					<div class='emoji_picker__item'>
						<div
							vOn:click={(_) => this.emojiPick(emoji)}
							v-tooltip={':' + emoji + ':'}
							class={'emoji emoji__preview emoji__' + emoji}
						/>
					</div>
				)
			})

			return (
				<div class='emoji_picker' v-clickaway={this.hideEmoji}>
					{$items}
				</div>
			)
		},

		uploadClick() {
			this.$refs.file_input.click()
		},

		Focus() {
			let length = this.editor.getLength()
			this.editor.focus()
			this.editor.setSelection(length, 0)
		},

		HasFocus() {
			return this.editor.hasFocus()
		},

		uploadLocalFiles(e) {
			this.message.attachments = this.message.attachments || []
			lo.map(e.target.files, (file) => {
				let id = sb.randomString(20) // for lookup later
				// load image
				if (ImageTypes.includes(file.type)) {
					var reader = new window.FileReader()
					reader.onload = (e) => {
						// after load all img data into the RAM, find update the corresponse attachment
						// since the attachment is very deep, we must force rerender
						lo.find(this.message.attachments, (att) => {
							if (att._local_id !== id) return false
							att.url = e.target.result
							// force rerender
							this.$set(this.message, 'attachments', lo.map(this.message.attachments))
							return true
						})
						this.$forceUpdate()
					}
					reader.readAsDataURL(file)
				}

				this.message.attachments.push({
					type: 'file',
					mimetype: file.type,
					size: file.size,
					name: file.name,
					_file: file,
					_local_id: id,
				})
			})
			this.doMessageChange()
			this.$forceUpdate()
			this.editor.focus()
		},

		removeFile(file) {
			// file is add (uploaded) by us, remove it using local ID
			if (file._local_id) {
				this.message.attachments = lo.filter(this.message.attachments, (att) => att._local_id !== file._local_id)
			} else {
				// remove using url
				this.message.attachments = lo.filter(this.message.attachments, (att) => att.url !== file.url)
			}
			this.doMessageChange()
			this.$forceUpdate()
			this.editor.focus()
		},

		renderFiles() {
			// find out all file attachment
			let files = lo.filter(
				this.message.attachments,
				(att) => att.type === 'file' && !ImageTypes.includes(att.mimetype),
			)

			let $files = lo.map(files, (file) => {
				return (
					<div class='message_editor__attachment'>
						<div class='message_editor__attachment_file'>
							<FileTextIcon stroke-width='1' class='message_editor__attachment_file_logo' size='2x' />
							<div class='d-flex flex-column text-truncate ml-3'>
								<div class='message_editor__attachment_file_name text-truncate'>{file.name}</div>
								<div class='message_editor__attachment_file_size text__muted'>{sb.humanFileSize(file.size)}</div>
							</div>
							<XIcon
								v-tooltip={this.$t('remove')}
								size='2x'
								stroke-width='2'
								class='message_editor__remove_file'
								vOn:click={(_) => this.removeFile(file)}
							/>
						</div>
					</div>
				)
			})

			if (lo.size(files) === 0) return null
			return <div class='message_editor__attachments__file'>{$files}</div>
		},

		renderImages() {
			// find out all file attachment
			let files = lo
				.filter(this.message.attachments, (att) => att.type === 'file' && ImageTypes.includes(att.mimetype))
				.filter((f) => f.url)

			// find all photo file first
			let $imgs = lo.map(files, (file) => {
				return (
					<div class='message_editor__attachment'>
						{!file._loading && (
							<XIcon
								v-tooltip={this.$t('remove')}
								size='2x'
								stroke-width='2'
								class='message_editor__attachment_x'
								vOn:click={(_) => this.removeFile(file)}
							/>
						)}
						<img src={file.url} class='message_editor__attachment_image' style='width: 60px' />
						{file._loading && (
							<div
								style='position: absolute; z-index: 5; inset: 0; background-color: rgba(255, 255, 255, 0.6)'
								class='d-flex align-items-center justify-content-center'
							>
								<Spinner mode='blue' size='16' />
							</div>
						)}
					</div>
				)
			})

			if (lo.size(files) === 0) return null
			return <div class='message_editor__attachments__image'>{$imgs}</div>
		},

		renderActions() {
			if (this.task_description) return null
			let $attachmentBtn = null
			if (true) {
				$attachmentBtn = (
					<div
						class='message_editor__action_item'
						v-tooltip={this.$t('title_attachment')}
						vOn:click_stop={this.uploadClick}
					>
						<PaperclipIcon class='message_editor__action_icon' size='18' stroke-width='1.5' />
						<input
							type='file'
							style='display:none;'
							multiple
							vOn:change={this.uploadLocalFiles}
							ref='file_input'
							accept={this.acceptAttachment}
						/>
					</div>
				)
			}

			return (
				<div class='message_editor__actions'>
					{this.renderEmojiPopup()}
					<div class='message_editor__action_item' vOn:click_stop={this.toggleEmoji} v-tooltip={this.$t('emoji')}>
						<div class='emoji emoji__sm emoji__wink' />
					</div>
					{$attachmentBtn}
				</div>
			)
		},

		onSend() {
			this.$emit('submit', this.getMessage())
		},

		GetMessage() {
			return this.getMessage()
		},

		getMessage() {
			let quill_delta = JSON.stringify(this.editor.getContents())
			let message = {text: quill_delta, format: 'delta'}
			if (lo.get(this.message, 'attachments', []).length > 0) {
				message.attachments = this.message.attachments
			}

			// ignore empty message
			if (!message.text && lo.size(message, 'attachments') === 0) return

			// this.message = { text: '' }
			if (this.$refs.file_input) this.$refs.file_input.value = ''
			return message
		},

		applySuggestion(action) {
			this.hideSuggestion()
			if (action.confirm) {
				this.editor.setText(action.text + ', hit enter to process...', true)
				// move cursor to end
				this.editor.setSelection(this.editor.getLength(), 0)
				this.editor.formatText(0, this.editor.getLength(), 'color', '#0000ff')
				this.editor.focus()

				return
			}

			if (action.action === 'mention') {
				//let quill_delta = lo.cloneDeep(sb.trimQuillDelta(this.editor.getContents()))

				//let last = quill_delta.ops[quill_delta.ops.length - 1]
				//let query = last.insert
				//let qs = query.split(this.start)
				//qs.pop()
				//last.insert = qs.join(this.start)
				//this.editor.setContents(quill_delta.ops)
				// var selection = this.editor.getSelection(true)
				//store.matchAgent(action.id)

				this.editor.deleteText(this.nearastAt, this.mentionQuery.length)
				this.editor.insertEmbed(
					this.nearastAt,
					'mention',
					{id: action.user_id || action.agent_id, fullname: action.text, type: action.user_id ? 'user' : ''},
					true,
				)
				// set cursor right after mention embeded
				this.editor.setSelection(this.nearastAt + 1)
				this.editor.focus()
				return
			}

			setTimeout(() => this.editor.setText(''))
			//	this.$emit('action', action)
		},

		applyMessage(message) {
			if (!message) return
			this.message = message
			if (message.format != 'delta') return
			let quilldeltas = []
			try {
				quilldeltas = JSON.parse(message.text).ops
			} catch (e) {}
			this.editor.setContents(quilldeltas)
			this.doMessageChange()
		},

		doMessageChange() {
			this.onQueryChange(this.editor.getContents())
		},

		onKeyPress(code) {
			// enter
			if (code === 13) {
				if (this.isShowing) return false
				this.onSend()
				this.editor.setText('')
				this.message.attachments = []
				return false
			}
		},

		closeQuote() {
			let message = lo.cloneDeep(this.message)
			let attachments = lo.get(message, 'attachments', [])
			attachments = attachments.filter((att) => att.type !== 'quote')
			message.attachments = attachments
			this.message = message
		},

		renderQuote() {
			let attachments = lo.get(this.message, 'attachments', [])
			let quote = lo.find(attachments, (att) => att.type === 'quote')
			let cls = 'message_editor_quote'
			if (!quote) cls += ' message_editor_quote_hide'

			let agentId = lo.get(quote, 'quote.by.id')
			let agent = agentId ? lo.get(store.matchAgent(), agentId, {}) : {}
			let content = lo.get(quote, 'quote.data.message.text')
			if (lo.get(quote, 'quote.data.message.format') === 'delta') {
				let delta = ''
				try {
					delta = JSON.parse(content)
				} catch (e) {}
				content = sb.deltaToPlainText(sb.trimQuillDelta(delta))
			}
			if (!content && lo.get(quote, 'quote.data.message.attachments')) content = 'Đã gửi file đính kèm'

			return (
				<div class={cls}>
					<div class='message_editor_quote_content'>
						<div>
							{this.$t('repling')}
							<strong>{agent.fullname}</strong>
						</div>
						<div class='message_editor_quote_text'>{content}</div>
					</div>
					<x-icon class='x-icon ml-auto' size='1x' vOn:click={this.closeQuote} />
				</div>
			)
		},

		renderSuggestion() {
			let $help = (
				<div class='suggestion__item__help'>
					<Icon name='corner-down-left' size='16' /> to apply
				</div>
			)
			let $actions = lo.map(this.filteredActions, (action, i) => {
				let active = i === this.currentSelectedIndex
				let cls = 'suggestion__item'
				if (active) cls += ' suggestion__item__active'

				let $content = null
				if (action.action === 'mention') {
					let agent = store.matchAgent()[action.agent_id]
					let user = store.matchUser(action.user_id)
					if (action.agent_id) {
						$content = (
							<div class='suggestion__item__text'>
								<Avatar online agent={agent} size='sm' />
								<span class='ml-2'>{sb.getAgentDisplayName(agent)}</span>
							</div>
						)
						if (this.task_description) {
							$content = (
								<div class='suggestion__item__text'>
									<Avatar online agent={agent} size='sm' />
									<div class='ml-3'>
										<div class='text__sm'>{sb.getAgentDisplayName(agent)}</div>
										<div class='text__sm text__muted'>Agent</div>
									</div>
								</div>
							)
						}
					}
					if (action.user_id) {
						let phone = sb.getUserTextAttr(user, 'phones')
						$content = (
							<div class='suggestion__item__text'>
								<Avatar user={user} size='sm' />
								<span class='ml-2'>
									{sb.getUserDisplayName(user)} {phone && `(${phone})`}
								</span>
							</div>
						)
						if (this.task_description) {
							$content = (
								<div class='suggestion__item__text'>
									<Avatar user={user} size='sm' />
									<div class='ml-3'>
										<div class='text__sm'>
											{sb.getUserDisplayName(user)} {phone && `(${phone})`}
										</div>
										<div class='text__sm text__muted'>{this.$t('customer')}</div>
									</div>
								</div>
							)
						}
					}
				}
				return (
					<div class={cls} ref={`item${i}`} vOn:click={(_) => this.applySuggestion(action)}>
						{$content} {$help}
					</div>
				)
			})

			let cls = 'suggestion'
			if (!this.isShowing) cls += ' d-none'
			let style = ''
			if (this.task_description)
				style = `top: ${this.$refs.wrapper ? this.$refs.wrapper.clientHeight : 0}px; bottom: unset;`
			return (
				<div class={cls} v-clickaway={this.hideSuggestion} style={style}>
					<div>{$actions}</div>
				</div>
			)
		},
	},

	render() {
		let $suggestion = null
		return (
			<div
				class='message_editor message_editor message_editor message_editor__plain'
				ref='wrapper'
				vOn:click={(_) => this.editor.focus()}
			>
				{this.renderSuggestion()}
				{this.renderQuote()}
				<div ref='text_editor' class='message_editor__input' vOn:click_stop={(_) => true}></div>
				{this.renderActions()}
				{this.renderImages()}
				{this.renderFiles()}
			</div>
		)
	},
}

// convert data:image:base64... to js Image file
function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = window.atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new window.File([u8arr], filename, {type: mime})
}

if (!Element.prototype.scrollIntoViewIfNeeded) {
	Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
		centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded

		var parent = this.parentNode
		var parentComputedStyle = window.getComputedStyle(parent, null)
		var parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'))
		var parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'))
		var overTop = this.offsetTop - parent.offsetTop < parent.scrollTop
		var overBottom =
			this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth >
			parent.scrollTop + parent.clientHeight
		var overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft
		var overRight =
			this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth >
			parent.scrollLeft + parent.clientWidth
		var alignWithTop = overTop && !overBottom

		if ((overTop || overBottom) && centerIfNeeded) {
			parent.scrollTop =
				this.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + this.clientHeight / 2
		}

		if ((overLeft || overRight) && centerIfNeeded) {
			parent.scrollLeft =
				this.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + this.clientWidth / 2
		}

		if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
			this.scrollIntoView(alignWithTop)
		}
	}
}

let Embed = Quill.import('blots/embed')
const ImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml']

const EmptyPixel =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

const EmojiList =
	'like unlike angry confused crying grinning heart-eyes neutral sleepy sad smiling tongue-out tired surprised wink'.split(
		' ',
	)

// define mention blot
class Mention extends Embed {
	static create(value) {
		let node = super.create()
		node.setAttribute('class', 'mention')
		node.setAttribute('style', 'display: inline-block')
		node.setAttribute('data-agent-id', value.id)
		node.setAttribute('data-agent-fullname', value.fullname)
		node.setAttribute('data-type', value.type)
		node.innerText = '@' + value.fullname
		return node
	}

	static value(element) {
		return {
			fullname: element.getAttribute('data-agent-fullname'),
			id: element.getAttribute('data-agent-id'),
			type: element.getAttribute('data-type'),
		}
	}
}

Mention.className = 'mention'
Mention.blotName = 'mention'
Mention.tagName = 'div'
Quill.register(Mention)

