const lo = require('lodash')
var sb = require('@sb/util')
import store from '@sb/store'

function inc(a) {
	a = a || 0
	return a + 1
}

function dec(a) {
	a = a || 0
	return a - 1
}

export default {
	props: ['ev'],

	methods: {
		reaction(type, has) {
			if (!type) return
			let message = lo.get(this.ev, 'data.message', {})
			message = lo.cloneDeep(message)
			var convoid = sb.convoOfEv(this.ev)

			if (has) type = 'remove_' + type
			store.pongMessage(convoid, this.ev.id, {
				data: {message: {pongs: [{type}]}},
			})
		},

		getAgentNames(agids) {
			let names = lo.map(agids, (_, id) => {
				if ((id || '').startsWith('ag')) return sb.getAgentDisplayName(store.matchAgent(id))

				let inteid = `${store.me().account_id}.${id}.fabikon`
				let inte = store.matchIntegration()[inteid] || {}
				return inte.name
			})
			return names.join(', ')
		},
	},

	render() {
		let pongs = lo.get(this.ev, 'data.message.pongs', [])
		if (lo.size(pongs) === 0) return null
		let countReaction = {}
		let whoReaction = {}

		let hasReaction = false
		lo.forEach(pongs, (reaction) => {
			let memberId = lo.get(reaction, 'member_id', '') + ''

			if (reaction.type === 'like' && memberId && memberId.indexOf('us') < 0) {
				countReaction.me_liked = inc(countReaction.me_liked)
			}

			countReaction[reaction.type] = inc(countReaction[reaction.type])
			whoReaction[reaction.type] = whoReaction[reaction.type] || {}
			whoReaction[reaction.type][reaction.member_id] = true
		})

		let $like = null
		if (countReaction.like) {
			hasReaction = true
			let cls = 'message_reactions__reaction_counter'
			if (whoReaction.like[store.me().id]) cls += ' message_reactions__reaction_counter__active'
			$like = (
				<div
					class='message_reactions__reaction'
					v-tooltip={this.getAgentNames(whoReaction.like)}
					vOn:click={(_) => this.reaction('like', whoReaction.like[store.me().id])}
				>
					<Emoji xs emojiCode='like' />
					<span class={cls}>{countReaction.like}</span>
				</div>
			)
		}

		let $love = null
		if (countReaction.love) {
			hasReaction = true
			let cls = 'message_reactions__reaction_counter'
			if (whoReaction.love[store.me().id]) cls += ' message_reactions__reaction_counter__active'

			$love = (
				<div class='message_reactions__reaction' v-tooltip={this.getAgentNames(whoReaction.love)}>
					<Emoji xs emojiCode='heart-eyes' />
					<span class={cls}>{countReaction.love}</span>
				</div>
			)
		}

		let $haha = null
		if (countReaction.haha) {
			hasReaction = true
			let cls = 'message_reactions__reaction_counter'
			if (whoReaction.haha[store.me().id]) cls += ' message_reactions__reaction_counter__active'
			$haha = (
				<div class='message_reactions__reaction' v-tooltip={this.getAgentNames(whoReaction.haha)}>
					<Emoji xs emojiCode='smiling' />
					<span class={cls}>{countReaction.haha}</span>
				</div>
			)
		}

		let $wow = null
		if (countReaction.wow) {
			hasReaction = true

			let cls = 'message_reactions__reaction_counter'
			if (whoReaction.wow[store.me().id]) cls += ' message_reactions__reaction_counter__active'
			$wow = (
				<div class='message_reactions__reaction' v-tooltip={this.getAgentNames(whoReaction.wow)}>
					<Emoji xs emojiCode='surprised' />
					<span class={cls}>{countReaction.wow}</span>
				</div>
			)
		}

		let $sad = null
		if (countReaction.sad) {
			hasReaction = true
			let cls = 'message_reactions__reaction_counter'
			if (whoReaction.sad[store.me().id]) cls += ' message_reactions__reaction_counter__active'

			$sad = (
				<div
					class='message_reactions__reaction'
					v-tooltip={this.getAgentNames(whoReaction.sad)}
					vOn:click={(_) => this.reaction('sad', whoReaction.sad[store.me().id])}
				>
					<Emoji xs emojiCode='sad' />
					<span class={cls}>{countReaction.sad}</span>
				</div>
			)
		}

		let $angry = null
		if (countReaction.angry) {
			hasReaction = true

			let cls = 'message_reactions__reaction_counter'
			if (whoReaction.angry[store.me().id]) cls += ' message_reactions__reaction_counter__active'

			$angry = (
				<div class='message_reactions__reaction' v-tooltip={this.getAgentNames(whoReaction.angry)}>
					<Emoji xs emojiCode='angry' />
					<span class={cls}>{countReaction.angry}</span>
				</div>
			)
		}

		if (!hasReaction) return null
		let byType = lo.get(this.ev, 'by.type')
		return (
			<div class={'message_reactions message_reactions__' + byType}>
				{$like}
				{$love}
				{$haha}
				{$wow}
				{$sad}
				{$angry}
			</div>
		)
	},
}
