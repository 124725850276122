// tODO: trigger dropdownonfocus
const lo = require('lodash')
import sb from '@sb/util'

import AppendToBody from './append_to_body.js'

// items: {key: 'id', label: 'this is an item', group: 'a group', img: '', icon: '', desc}
// selected: id of the selected items
// mode: 'input', 'link', 'suggestion', 'custom'

export default {
	name: 'dropdown',
	props: [
		'items',
		'selected',
		'placeholder',
		'disabled',
		'mode',
		'extra_cls',
		'extra_item_cls',
		'no_filter',
		'dropdown_width',
		'right',
		'toggle_always_open',
		'no_close_when_select',
		'no_open_when_items_empty',
		'empty_content', // use to override when selected items is empty
		'input_value', // use with mode suggestion
	],

	data() {
		return {
			isDropdownOpen: false,
			filter: '',
			softSelected: '',
		}
	},

	watch: {
		items(items) {
			if (lo.size(items) === 1) {
				this.softSelected = lo.get(items, '0.id')
			} else {
				this.softSelected = ''
			}
			let $dropdown = this.$refs.dropdown_container
			if (!$dropdown) return
			$dropdown.scrollTop = 0
		},
		selected(selected) {
			this.softSelected = selected
		},
	},

	mounted() {
		document.body.addEventListener('keyup', this.onKeyup)
		document.body.addEventListener('keydown', this.onKeydown)

		this.$root.$on('closeDropdown2', this.closeDropdown)
		this.softSelected = this.selected
	},

	destroyed() {
		document.body.removeEventListener('keyup', this.onKeyup)
		document.body.removeEventListener('keydown', this.onKeydown)
		this.$root.$off('closeDropdown2', this.closeDropdown)
	},

	methods: {
		hasFilter() {
			if (this.no_filter) return false
			return lo.size(this.items) > 5
		},

		onKeydown(e) {
			if (!this.isDropdownOpen) return
			switch (e.keyCode) {
				case 38: {
					// UP
					let items = this.getFilteredItems()
					let idx = lo.findIndex(items, (item) => item.id === this.softSelected)
					idx--
					if (idx < 0) idx = lo.size(items) - 1
					this.softSelected = items[idx].id
					// this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()

					// check to scroll
					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-id="${this.softSelected}"]`)
					$element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
				case 40: {
					// DOWN
					let items = this.getFilteredItems()
					let idx = lo.findIndex(items, (item) => item.id === this.softSelected)
					idx++
					if (idx >= lo.size(items)) idx = 0
					this.softSelected = items[idx].id

					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-id="${this.softSelected}"]`)
					$element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
			}
		},

		onKeyup(e) {
			if (!this.isDropdownOpen) return
			switch (e.keyCode) {
				case 27: // ESC
					if (this.filter) {
						this.filter = ''
						e.preventDefault()
						e.stopPropagation()
						break
					}

					this.closeDropdown()
					e.preventDefault()
					e.stopPropagation()
					break
				case 13: // ENTER
					var item = lo.find(this.items, (item) => item.id === this.softSelected)
					this.onSelectItem(item)
					break
			}
		},

		onMouseOver(item) {
			this.softSelected = item.id
		},

		ToogleDropdown() {
			this.toggleDropdown()
		},

		async toggleDropdown() {
			if (this.disabled) return
			this.isDropdownOpen = this.toggle_always_open ? true : !this.isDropdownOpen
			if (this.isDropdownOpen) {
				this.softSelected = this.selected
				this.$root.$emit('closeDropdown2')
				this.isDropdownOpen = true
			}
			this.filter = ''
			if (this.isDropdownOpen) {
				this.$emit('openDropdown')
				await this.$nextTick()
				let $filterInput = this.$refs.filter_input
				if ($filterInput) $filterInput.focus()
			}
		},

		getFilteredItems() {
			return lo.filter(this.items, (item) => {
				if (this.no_filter) return true
				if (this.mode === 'suggestion') return true
				let tag = lo.get(item, 'tag.title', '')
				tag = sb.unicodeToAscii(tag).toLowerCase()
				let text = item.label + ';' + (item.metadata || '')
				let label = sb.unicodeToAscii(text).toLowerCase()
				let filter = sb.unicodeToAscii(this.filter).toLowerCase()
				return label.indexOf(filter) > -1 || tag.indexOf(filter) > -1
			})
		},

		renderItems() {
			let items = this.getFilteredItems()
			if (!lo.size(items)) {
				if (this.empty_content) return <div>{this.empty_content}</div>
				return (
					<div class='w_100 h_100 d-flex align-items-center justify-content-center pl-4 pr-4 pt-4 pb-4'>
						<div class='text__muted text-center'>{this.$t('no_result')}</div>
					</div>
				)
			}

			// sort by group
			let groupM = {}
			lo.each(items, (item) => {
				let group = item.group ? item.group : ''
				groupM[group] = groupM[group] || []
				groupM[group].push(item)
			})

			let $items = []
			lo.map(groupM, (items, group) => {
				if (group) $items.push(<div class='dropdown__item dropdown__item_group'>{group}</div>)
				lo.map(items, (item) => {
					let cls = 'dropdown_item'
					if (this.softSelected === item.id) cls += ' active'
					if (this.extra_item_cls) cls += ` ${this.extra_item_cls}`
					if (item.checked) cls += ' checked'
					if (item.disabled) cls += ' dropdown_item__disabled'
					if (item.extra_cls) cls += ` ${item.extra_cls}`

					if (item.seperator && !item.id) {
						$items.push(<div class='dropdown_item__seperator' />)
					} else {
						$items.push(
							<div
								data-id={item.id}
								class={cls}
								vOn:click_stop={(_) => this.onSelectItem(item)}
								vOn:mouseover={(_) => this.onMouseOver(item)}
							>
								{this.renderItemContent(item)}
							</div>,
						)
					}
				})
			})
			return $items
		},

		renderItemContent(item) {
			if (item.tag) {
				return <Tag tag={item.tag} />
			}

			if (item.html && !item.label) {
				return item.html
			}

			if (item.extension && item.img && item.label) {
				let status = <div class='status_agent_dot call_online_dot'></div>
				let cls = 'dropdown_item_subtext'
				if (!item.status) {
					status = <div class='status_agent_dot call_unavailable_dot'></div>
					cls += ' item_unavailable'
				}

				return (
					<div class='d-flex align-items-center' style='height: 32px'>
						<img2 src={item.img} class='dropdown_item_img' />
						{status}
						<div class='text__truncate ml-3 dropdown_item_text' style='color: black; flex: 3'>
							{item.label}
						</div>
						<div class={cls} style='flex: 1'>
							{item.extension}
						</div>
					</div>
				)
			}

			if (item.img && item.desc) {
				return (
					<div class='d-flex align-items-center'>
						<img2 src={item.img} class='dropdown_item_img' />
						<div class='text__truncate ml-3' title={`${item.label}\&#10;${item.desc}`}>
							<div style='font-size: 14px' class='text__truncate dropdown_item_text'>
								{item.label}
							</div>
							<div style='font-size: 14px; line-height: 16px' class='dropdown_item_subtext text__truncate'>
								{item.desc}
							</div>
						</div>
					</div>
				)
			}
			if (item.img) {
				let cls = 'text__truncate ml-3 dropdown_item_text '
				let status = null
				if (item.status === true) {
					status = <div class='status_agent_dot call_online_dot'></div>
				}
				if (item.status === false) {
					status = <div class='status_agent_dot call_unavailable_dot'></div>
					cls += ' text__muted'
				}
				return (
					<div class='d-flex align-items-center'>
						<img2 src={item.img} class='dropdown_item_img' />
						{status}
						<div class={cls} title={item.label}>
							{item.label}
						</div>
					</div>
				)
			}
			if (item.icon && item.desc && item.no_truncate) {
				return (
					<div class='d-flex'>
						<div style='flex-shrink: 0'>{item.icon}</div>
						<div class='ml-3'>
							<div class='text__semibold dropdown_item_text'>{item.label}</div>
							<div style='font-size: 14px; white-space: pre-wrap' class='dropdown_item_subtext'>
								{item.desc}
							</div>
						</div>
					</div>
				)
			}
			if (item.icon) {
				return (
					<div class='d-flex align-items-center'>
						<div style='flex-shrink: 0'>{item.icon}</div>
						<div class='text__truncate ml-2 dropdown_item_text' title={item.label}>
							{item.label}
						</div>
					</div>
				)
			}
			if (item.desc) {
				return (
					<div class='text__truncate' title={`${item.label}\u000A${item.desc}`}>
						<div class='text__truncate dropdown_item_text'>{item.label}</div>
						<div style='font-size: 14px; line-height: 16px' class='dropdown_item_subtext text__truncate'>
							{item.desc}
						</div>
					</div>
				)
			}

			if (item.hasCheckbox) {
				return (
					<div class='d-flex align-items-center'>
						<input type='checkbox' class='form-check-input' checked={item.checked} />
						<div class='text__truncate dropdown_item_text ml-3' title={item.label}>
							{item.label}
						</div>
					</div>
				)
			}

			return (
				<div class='text__truncate dropdown_item_text' title={item.label}>
					{item.label}
				</div>
			)
		},

		onSelectItem(item) {
			this.$emit('select', item)
			if (!this.no_close_when_select) this.isDropdownOpen = false
		},

		focus() {
			if (this.mode === 'input') this.$refs.input.click()
			if (this.mode === 'suggestion') this.$refs.suggestion_input.focus()
		},

		clearFilterInput() {
			this.filter = ''
			this.$emit('input', '')
		},

		renderDropdown() {
			let $wrapper = this.$refs.container
			let rect = $wrapper ? $wrapper.getBoundingClientRect() : {}
			let {top = 0, left = 0, width = 0, height = 0, right = 0} = rect
			if (this.dropdown_width) width = this.dropdown_width

			let isBottom = document.body.offsetHeight - top < 400
			let topStyle = `top: ${top + height + 2}px`
			let bottomStyle = `bottom: ${document.body.offsetHeight - top + 2}px; top: unset`

			let leftStyle = `left: ${left}px`
			let rightStyle = `left: unset; right: ${document.body.offsetWidth - right}px`
			let style = `position: fixed; z-index: 9999; ${isBottom ? bottomStyle : topStyle}; ${
				this.right ? rightStyle : leftStyle
			};`
			style += `min-width: ${width}px; max-width: ${width}px;`
			if (!this.isDropdownOpen) style += 'display: none;'
			if (this.no_open_when_items_empty && !lo.size(this.items)) style += 'display: none;'

			return (
				<AppendToBody>
					<div vOn:click_stop={(_) => false} style={style} class='d-flex flex-column dropdown' ref='dropdown'>
						{this.hasFilter() && (
							<div class='dropdown_filter_container' vOn:click_stop={(_) => false}>
								<div class='dropdown_filter_input_container'>
									<icon name='search' class='dropdown_filter_input_search_icon' size='13' />
									<input
										class='form-control dropdown_filter_input'
										placeholder={this.$t('search')}
										value={this.filter}
										ref='filter_input'
										vOn:input={this.onInput}
									/>
									{this.filter && (
										<Icon
											stroke-width='2'
											name='x'
											size='13'
											class='dropdown_filter_input_x_icon'
											vOn:click={this.clearFilterInput}
										/>
									)}
								</div>
							</div>
						)}
						{this.mode === 'suggestion' && !this.hasFilter() && (
							<div class='dropdown_filter_container'>
								<div class='d-flex align-items-center'>
									<div class='text__muted text__semibold' style='font-size: 14px'>
										{this.$t('suggestion')}
									</div>

									<icon name='x' size='18' class='x-icon ml-auto' vOn:click={() => this.closeDropdown()} />
								</div>
							</div>
						)}
						<div class='dropdown_item_cotainer'>{this.renderItems()}</div>
					</div>
				</AppendToBody>
			)
		},

		onInput(e) {
			this.filter = e.target.value
			this.$emit('input', e.target.value)
		},

		renderInput() {
			let cls = 'dropdown_input'
			if (this.extra_cls) cls += ` ${this.extra_cls}`
			if (this.disabled) cls += ' disabled'

			let item = lo.find(this.items, (item) => item.id === this.selected)
			return (
				<div class={cls} ref='input' vOn:click={this.toggleDropdown}>
					{item ? (
						item.desc || item.hasCheckbox ? (
							<div class='text__truncate' title={item.label}>
								{item.label}
							</div>
						) : (
							this.renderItemContent(item)
						)
					) : (
						<div class='dropdown_input_placeholder text__truncate'>{this.placeholder || this.$t('select_an_item')}</div>
					)}
				</div>
			)
		},

		renderLink() {
			let cls = 'dropdown_link_selected'
			if (this.extra_cls) cls += ` ${this.extra_cls}`

			let item = lo.find(this.items, (item) => item.id === this.selected) || {}
			let text = item.selected_label || item.label || this.placeholder || this.$t('select_an_item')
			if (this.disabled) {
				return (
					<div class={cls} style='opacity: 0.7; pointer-events: none'>
						{text}
					</div>
				)
			}
			return (
				<div class={cls} vOn:click={this.toggleDropdown}>
					{text}{' '}
					<Icon
						stroke-width='2'
						name='chevron-down'
						size='15'
						stroke='currentColor'
						class='ml-1'
						style='flex-shrink: 0'
					/>
				</div>
			)
		},

		onFocusSuggestionInput() {
			this.$root.$emit('closeDropdown2')
			this.isDropdownOpen = true
			this.$emit('focus')
		},

		onInputSuggestionInput(e) {
			this.$emit('input', e)
			this.isDropdownOpen = true
		},

		getDropdownIsOpenned() {
			if (this.no_open_when_items_empty && !lo.size(this.items)) return false
			return this.isDropdownOpen
		},

		renderSuggestionInput() {
			let cls = 'form-control'
			if (this.extra_cls) cls += ` ${this.extra_cls}`

			return (
				<input
					class={cls}
					vOn:input={this.onInputSuggestionInput}
					vOn:focus={this.onFocusSuggestionInput}
					vOn:click={this.onFocusSuggestionInput}
					value={this.input_value}
					ref='suggestion_input'
				/>
			)
		},

		closeDropdown() {
			this.isDropdownOpen = false
		},
	},

	render() {
		let $content = null
		if (this.mode === 'input') $content = this.renderInput()
		else if (this.mode === 'link') $content = this.renderLink()
		else if (this.mode === 'suggestion') $content = this.renderSuggestionInput()
		else $content = <div vOn:click={this.toggleDropdown}>{this.$slots.default}</div>

		return (
			<div class='dropdown_container' ref='container' v-clickaway={this.closeDropdown}>
				{$content}
				{this.renderDropdown()}
			</div>
		)
	},
}
