import Quill from 'quill'
const Embed = Quill.import('blots/embed')
const ImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml']
import GenericTemplateBuilder from './generic_template_builder.js'

const EmojiList =
	'like unlike angry confused crying grinning heart-eyes neutral sleepy sad smiling tongue-out tired surprised wink'.split(
		' ',
	)

import lo from 'lodash'
import sb from '@sb/util'
import store from '@sb/store'
import {getAttributeName, UNUSED_USER_ATTRIBUTES} from '../common'

import DynamicField from '../commons/DynamicFieldEmbed'

DynamicField.blotName = 'dynamicField'
// tagName must be div so not error when 2 bold
// div2 to prevent copy paste cause null dynamic field error
DynamicField.tagName = 'div2'
Quill.register(DynamicField)

export default {
	name: 'botTextEditor',
	props: ['message', 'actions', 'placeholder', 'dynamicFieldData', 'actions_always_show'],

	data() {
		return {
			openEmoji: false,
			openDynamicField: false,
			editor: null,

			choosenToken: null,

			isBold: false,
			isItalic: false,
			isUnderline: false,
		}
	},

	watch: {
		message: function (newVal, oldVal) {
			if (newVal.quill_delta !== JSON.stringify(this.editor.getContents())) {
				this.updateEditor()
			}
		},
	},

	methods: {
		renderGallery() {
			let att = lo.get(this.message, 'attachments.0') || {}
			if (att.type !== 'generic') return null
			return (
				<GenericTemplateBuilder
					noBranching
					locale={lo.get(store.me(), 'account.locale')}
					style='max-width: 100%; padding-right: 10px; padding-left:10px; padding-bottom: 10px'
					message={this.message}
					vOn:change={(msg) => this.$emit('change', msg)}
					vOn:remove={this.removeGallery}
				/>
			)
		},

		removeGallery() {
			let message = lo.cloneDeep(this.message)
			message.attachments = []
			this.$emit('change', message)
			this.focus()
		},

		renderEmojiPopup() {
			if (!this.openEmoji) return null

			return (
				<div
					class='emoji_picker'
					style='top: calc(100% + 5px); right: -20px; bottom: unset;'
					v-clickaway={(_) => (this.openEmoji = false)}
				>
					{lo.map(EmojiList, (emoji) => (
						<div class='emoji_picker__item' v-tooltip={':' + emoji + ':'}>
							<div vOn:click={(_) => this.emojiPick(emoji)} class={'emoji emoji__preview emoji__' + emoji} />
						</div>
					))}
				</div>
			)
		},

		matchUserAttribute() {
			if (this.dynamicFieldData) return this.dynamicFieldData

			return lo
				.filter(store.matchUserAttribute(), (att) => UNUSED_USER_ATTRIBUTES.indexOf(att.key) < 0 && !att.archived)
				.map((att) => Object.assign(att, {name: this.$t(getAttributeName(att))}))
		},

		toggleEmoji() {
			this.openEmoji = !this.openEmoji
			this.openDynamicField = false
		},

		toggleDynamicField() {
			this.choosenToken = null
			this.openEmoji = false
			this.openDynamicField = !this.openDynamicField
			if (this.openDynamicField) {
				var selection = this.editor.getSelection(true)
				this.previousSelection = selection
			}
		},

		async toggleUpdateDynamicField(e) {
			e.stopPropagation()
			// settimeout to run this function after clickaway
			this.choosenToken = e.target.closest('.dynamic-field')
			await this.$nextTick()
			let $token = this.$refs.custom_token_dropdown
			$token.ToogleDropdown()
			setTimeout(() => {
				this.openEmoji = false
				this.openDynamicField = true
			}, 100)
		},

		renderChoosenTokenDropdown() {
			if (!this.choosenToken) return null
			let rect = this.choosenToken.getBoundingClientRect() || {}
			let attributes = lo.map(this.matchUserAttribute(), (attr) => ({id: attr.key, label: attr.name}))

			let style = `z-index: 10;background: transparent;width: ${rect.width}px; height: ${rect.height}px; position: fixed; top:${rect.top}px;left:${rect.left}px`
			return (
				<Dropdown2
					ref='custom_token_dropdown'
					style={style}
					mode='custom'
					items={attributes}
					dropdown_width={220}
					vOn:select={this.selectDynamicField}
					right
				>
					<div ref='custom_token' style={style} />
				</Dropdown2>
			)
		},

		emojiPick(code) {
			var selection = this.editor.getSelection(true)
			this.editor.insertEmbed(selection.index, 'emoji', code, true)
			this.editor.setSelection(selection.index + 1, 0)

			this.openEmoji = false
		},

		// parrent may call
		InsertDynamicField(attr) {
			const id = sb.randomString(16)
			var selection = this.previousSelection || this.editor.getSelection(true)

			this.editor.insertEmbed(
				selection.index,
				'dynamicField',
				{value: this.getDynamicFieldName(attr.id), id, key: `user.${attr.id}`},
				true,
			)
			this.editor.setSelection(selection.index + 1, 0)
			this.openDynamicField = false
		},

		selectDynamicField(attr) {
			if (this.choosenToken) {
				const delta = lo.cloneDeep(this.editor.getContents())
				const id = this.choosenToken.getAttribute('data-id')
				const selectedEmbed = delta.ops.find((op) => op.insert.dynamicField && op.insert.dynamicField.id === id)
				if (!selectedEmbed) return
				lo.set(selectedEmbed, 'insert.dynamicField.value', this.getDynamicFieldName(attr.id))
				lo.set(selectedEmbed, 'insert.dynamicField.key', `user.${attr.id}`)
				this.editor.setContents(delta)
				this.openDynamicField = false
				this.choosenToken = null
				return
			}
			return this.InsertDynamicField(attr)
		},

		toggleStyle(style) {
			if (style === 'bold') this.isBold = !this.isBold
			if (style === 'italic') this.isItalic = !this.isItalic
			if (style === 'underline') this.isUnderline = !this.isUnderline
		},

		formatTextStyle(style) {
			let selection = this.editor.getSelection(true)
			if (!selection.length) {
				this.toggleStyle(style)
				let isActive = false
				if (style === 'bold') isActive = this.isBold
				if (style === 'italic') isActive = this.isItalic
				if (style === 'underline') isActive = this.isUnderline
				this.editor.format(style, isActive, 'user') // must set format type is user to work
			}
			if (selection.length) {
				let format = this.editor.getFormat(selection.index, selection.length)
				let currentStyle = format[style] || false
				this.editor.formatText(selection.index, selection.length, style, !currentStyle)
				if (style === 'bold') this.isBold = !currentStyle
				if (style === 'italic') this.isItalic = !currentStyle
				if (style === 'underline') this.isUnderline = !currentStyle
			}
		},

		useGallery() {
			let att = lo.get(this.message, 'attachments.0') || {}
			if (att.type === 'generic') return
			let message = lo.cloneDeep(this.message)
			let locale = lo.get(store.me(), 'account.locale')
			locale = locale.replace('-', '_')

			message.attachments = [
				{
					type: 'generic',
					elements: [
						{
							title: 'Sản phẩm 1',
							i18n_title: {[locale]: 'Sản phẩm 1'},
							image_url: 'https://file-subiz.com/fiqxarzhhfhtkixrqlug-5.png',
							subtitle: 'Mô tả về sản phẩm',
							i18n_subtitle: {[locale]: 'Mô tả về sản phẩm'},
							buttons: [
								{
									type: 'url_button',
									title: 'Xem trên website',
									i18n_title: {[locale]: 'Xem trên website'},
									url: '',
								},
								{
									type: 'call_button',
									title: 'Liên hệ ngay',
									i18n_title: {[locale]: 'Liên hệ ngay'},
									phone_number: '',
								},
							],
						},
						{
							title: 'Sản phẩm 2',
							i18n_title: {[locale]: 'Sản phẩm 2'},
							image_url: 'https://file-subiz.com/fiqxarymkkbsyykitvju-2.png',
							subtitle: 'Mô tả về sản phẩm',
							i18n_subtitle: {[locale]: 'Mô tả về sản phẩm'},
							buttons: [
								{
									type: 'url_button',
									title: 'Xem trên website',
									i18n_title: {[locale]: 'Xem trên website'},
									url: '',
								},
								{
									type: 'call_button',
									title: 'Liên hệ ngay',
									i18n_title: {[locale]: 'Liên hệ ngay'},
									phone_number: '',
								},
							],
						},
					],
				},
			]
			this.$emit('change', message)
		},

		renderActions() {
			let actions = ['bold', 'italic', 'underline', 'emoji', 'dynamicField']
			if (lo.size(this.actions) > 0) actions = this.actions

			let $gallery = null
			if (actions.indexOf('gallery') >= 0) {
				let active = lo.get(this.message, 'attachments.0.type') === 'generic'

				$gallery = (
					<div
						class={`bot-textarea__action-item ${active && 'item--active'}`}
						v-tooltip={this.$t('gallery')}
						vOn:click={this.useGallery}
					>
						<Icon name='columns' class='bot-textarea__action-icon' stroke-width='2' size='16' />
					</div>
				)
			}

			let $single_img = null
			if (actions.indexOf('singleImage') >= 0) {
				let url = lo.get(this.message, 'attachments.0.url')
				$single_img = (
					<div class={`bot-textarea__action-item ${url && 'item--active'}`}>
						<Icon
							name='camera'
							class='bot-textarea__action-icon'
							size='16'
							v-tooltip={url ? 'Thay đổi ảnh' : 'Thêm ảnh'}
							vOn:click_stop={(_) => this.$refs.img_upload.click()}
						/>
						<input
							type='file'
							ref='img_upload'
							style='display: none;'
							vOn:change={this.uploadSingleImage}
							accept='image/*'
						/>
					</div>
				)
			}

			let $bold = null
			if (actions.indexOf('bold') >= 0) {
				$bold = (
					<div
						class={`bot-textarea__action-item ${this.isBold && 'item--active'}`}
						v-tooltip={this.$t('bold')}
						vOn:click_stop={(_) => this.formatTextStyle('bold')}
					>
						<Icon name='bold' class='bot-textarea__action-icon' stroke-width='2' size='16' />
					</div>
				)
			}

			let $italic = null
			if (actions.indexOf('italic') >= 0) {
				$italic = (
					<div
						class={`bot-textarea__action-item ${this.isItalic && 'item--active'}`}
						v-tooltip={this.$t('italic')}
						vOn:click_stop={(_) => this.formatTextStyle('italic')}
					>
						<Icon name='italic' class='bot-textarea__action-icon' stroke-width='2' size='16' />
					</div>
				)
			}

			let $underline = null
			if (actions.indexOf('underline') >= 0) {
				$underline = (
					<div
						class={`bot-textarea__action-item ${this.isUnderline && 'item--active'}`}
						v-tooltip={this.$t('underline')}
						vOn:click_stop={(_) => this.formatTextStyle('underline')}
					>
						<Icon name='underline' class='bot-textarea__action-icon' stroke-width='2' size='16' />
					</div>
				)
			}

			let $emoji = null
			if (actions.indexOf('emoji') >= 0) {
				$emoji = (
					<div style='position: relative'>
						{this.renderEmojiPopup()}
						<div
							class={`bot-textarea__action-item ${this.openEmoji && 'item--active'}`}
							v-tooltip={this.$t('text_editor_emoji')}
							vOn:click_stop={this.toggleEmoji}
						>
							<Icon name='mood-smile' class='bot-textarea__action-icon' stroke-width='2' size='16' />
						</div>
					</div>
				)
			}

			let $dynamicField = null
			if (actions.indexOf('dynamicField') >= 0) {
				let attributes = lo.map(this.matchUserAttribute(), (attr) => ({id: attr.key, label: attr.name}))
				$dynamicField = (
					<Dropdown2
						ref='dropdown_1'
						mode='custom'
						items={attributes}
						dropdown_width={220}
						vOn:select={this.selectDynamicField}
						right
					>
						<div
							class={`bot-textarea__action-item ${this.openDynamicField && 'item--active'}`}
							vOn:click={this.toggleDynamicField}
							v-tooltip={this.$t('add_contact_token')}
						>
							{'{ }'}
						</div>
					</Dropdown2>
				)
			}

			// force visible when open dropdowns
			let style = ''
			if (this.openEmoji || this.openDynamicField) style = 'opacity: 1'
			if (this.actions_always_show) style = 'opacity: 1'

			return (
				<div class='bot-textarea__actions' style={style} vOn:click={(_) => this.focus()}>
					{$bold}
					{$italic}
					{$underline}
					{$emoji}
					{$dynamicField}
					{$single_img}
					{$gallery}
				</div>
			)
		},

		updateEditor() {
			let delta = this.message.quill_delta || '{ "ops": [] }'
			delta = sb.parseJSON(delta)
			let selection = this.editor.getSelection()
			this.editor.setContents(delta)
			if (selection && selection.index) {
				this.editor.setSelection(selection) // update cursor
			}
		},

		handleTextChange: lo.throttle(function () {
			let deltas = this.editor.getContents().ops
			let message = lo.cloneDeep(this.message)

			let hasAttrs = lo.find(
				deltas,
				(delta) =>
					lo.get(delta, 'attributes.bold') ||
					lo.get(delta, 'attributes.italic') ||
					lo.get(delta, 'attributes.underline') ||
					lo.get(delta, 'insert.emoji'),
			)
			// has bold, italic or underline text, we must use markdown
			if (hasAttrs) {
				message.text = sb.deltaToMarkdown(deltas)
				message.format = 'delta'
			} else {
				// use simple plaintext format
				message.text = lo.trim(sb.deltaToPlainText(deltas))
				message.format = 'plaintext'
			}

			message.quill_delta = JSON.stringify({ops: deltas})
			this.$emit('change', message)
		}, 200),

		handleSelectionChange() {
			const selection = this.editor.getSelection()
			if (!selection) return
			const format = this.editor.getFormat(selection.index, selection.length)
			this.isBold = !!format.bold
			this.isItalic = !!format.italic
			this.isUnderline = !!format.underline
		},

		onQuillClick(e) {
			if (
				e.target.getAttribute('data-type') === DynamicField.blotName ||
				(e.target.parentNode && e.target.parentNode.getAttribute('data-type') === DynamicField.blotName)
			) {
				this.toggleUpdateDynamicField(e)
			}
		},

		getDynamicFieldName(key) {
			let atts = this.matchUserAttribute()
			let found = lo.find(atts, (att) => att.key === key)
			return lo.get(found, 'name')
		},

		focus() {
			let length = this.editor.getLength()
			this.editor.setSelection(length, 0)
			this.editor.focus()
		},

		renderSingleImage() {
			let url = lo.get(this.message, 'attachments.0.url')
			if (!url) return null
			return (
				<div style='position: relative;' class='ml-3'>
					<Icon
						name='x'
						v-tooltip={this.$t('remove')}
						size='1.5x'
						stroke-width='2'
						class='message_editor__attachment_x'
						vOn:click={this.removeSingleImage}
					/>
					<img2 src={url} class='message_editor__attachment_image' style='max-width: 200px; max-height: 70px' />
				</div>
			)
		},

		removeSingleImage() {
			let message = lo.cloneDeep(this.message)
			delete message.attachments
			this.$emit('change', message)
			this.focus()
		},

		uploadClick() {
			this.$refs.file_input.click()
		},

		async uploadSingleImage(e) {
			let MAXIMUM_SIZE = 5 * 1024 * 1024
			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (file.size > MAXIMUM_SIZE) {
				return this.$showError(this.$t('error_file_is_too_large'))
			}
			let tempUrl = await sb.getBlobUrlFromFile(file)
			let message = lo.cloneDeep(this.message)
			let id = Date.now()
			message.attachments = [{type: 'file', url: tempUrl, _loading: true, _id: id}]
			this.$emit('change', message)
			let res = await store.uploadLocalFile(file)
			if (lo.get(this.message, 'attachments.0._id') !== id) return // outdated

			message = lo.cloneDeep(this.message)
			message.attachments = [{type: 'file', url: res.url}]
			this.$emit('change', message)
			this.focus()
		},
	},

	async mounted() {
		this.editor = new Quill(this.$refs.text_editor, {
			placeholder: this.placeholder || this.$t('type_a_message'),
			modules: {},
		})

		// update content by props
		this.updateEditor()
		this.attachments = lo.cloneDeep(this.message.attachments)

		this.$refs.text_editor && this.$refs.text_editor.addEventListener('click', this.onQuillClick)

		// check selection format
		this.editor.on('selection-change', this.handleSelectionChange)
		this.editor.on('text-change', this.handleTextChange)
	},

	destroyed() {
		this.$refs.text_editor && this.$refs.text_editor.removeEventListener('click', this.onQuillClick)
		this.editor.off('selection-change', this.handleSelectionChange)
		this.editor.off('text-change', this.handleTextChange)
	},

	render() {
		return (
			<div class='bot-textarea'>
				<div ref='text_editor' class='bot-textarea__input' />
				{this.renderSingleImage()}
				{this.renderGallery()}
				{this.renderActions()}
				{this.$slots.default}
				{this.renderChoosenTokenDropdown()}
			</div>
		)
	},
}
