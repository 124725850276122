export default {
	mounted() {
		document.querySelector('body').appendChild(this.$el)
	},

	beforeDestroy() {
		document.querySelector('body').removeChild(this.$el)
	},

	render() {
		return this.$slots.default
	},
}
