var sb = require('@sb/util')
const LoadWidget = () => import('@subiz/widget-v4/compiled_src/lib.js')
import ChatItem from './chat_item.js'
const config = require('@sb/config')
import {OnelineMsg} from '@sb/com'
const lo = require('lodash')
import store from '@sb/store'
import Chat from './chat.js'
import ChatAvatar from './chat_avatar.js'
import ChatDetail from './chat_detail.js'
import Search from './search.js'

export default {
	name: 'internal-chat',
	props: ['quickviewUcid', 'isShowQuickview'],
	data() {
		return {
			cid: '',
			closed: true,
			view: 'list', //chat, list

			groupName: '',
			selectedMembers: {},
			cid: '',

			creatingGroup: false,

			outOfChat: false,
			loadingMore: false,

			history: [],
			pinned: false,

			showMsg: {},
		}
	},

	async mounted() {
		return
		store.onAccount(this, () => this.$forceUpdate())
		store.onWebPhoneStatus(this, (e) => this.$forceUpdate())

		// store.onUser(this, () => this.$forceUpdate())
		// store.onRecent(this, () => this.$forceUpdate())
		this.showMsgTimeout = {}

		/*
		store.onRecentMsg(this, (msg) => {
			if (this.$route.name === 'activities') return
			let convoid = msg.convo_id
			let userid = msg.user_id
			if (!userid || !convoid) return
			if (this.showMsgTimeout[userid]) clearTimeout(this.showMsgTimeout[userid])
			this.showMsg[userid] = {ev: msg.by_event, created: Date.now()}
			this.showMsgTimeout[userid] = setTimeout(() => {
				delete this.showMsg[userid]
				delete this.showMsgTimeout[userid]
				this.$forceUpdate()
			}, 6000)
			this.$forceUpdate()
			})
			*/

		this.$root.$on('showPrivateChat', this.onShowPrivateChat)
		store.onInternalConvo(this, (_) => this.$forceUpdate())
		store.onConvo(this, (convoM) => {
			if (lo.find(Object.keys(convoM), (cid) => this.isInInternal(cid))) this.$forceUpdate()
		})
		store.onMessage(this, (convoid, _, ev) => {
			if (!this.isInInternal(convoid)) return
			if (convoid != this.cid && ev && ev.type === 'message_sent' && ev.by.id !== store.me().id) {
				if (this.showMsgTimeout[convoid]) clearTimeout(this.showMsgTimeout[convoid])
				this.showMsg[convoid] = {created: Date.now()}
				this.showMsgTimeout[convoid] = setTimeout(() => {
					delete this.showMsg[convoid]
					delete this.showMsgTimeout[convoid]
					this.$forceUpdate()
				}, 10000)
			}
			this.$forceUpdate()
		})

		let chats = store.listInternalConvos()
		if (lo.size(chats) === 0) {
			let {end} = await store.fetchMoreInternalConvos()

			this.outOfChat = !!end
		}
		if (lo.size(chats) < 19) setTimeout(() => store.fetchMoreInternalConvos())
	},

	beforeDestroy() {
		this.$root.$off('showPrivateChat', this.onShowPrivateChat)
	},

	methods: {
		listClick() {
			this.$root.$emit('showPrivateChat', 'list', true)
		},

		sidebarClick() {
			if (this.closed) this.$root.$emit('showPrivateChat')
			else this.$root.$emit('showPrivateChat', 'close')
		},

		onInternalChatClick(convoid) {
			this.$root.$emit('showPrivateChat', 'chat:' + convoid, true)
		},

		renderUser(recentUser) {
			let convo = store.matchConvo(recentUser.conversation_id) || {}

			if (!recentUser.id || !recentUser.id.startsWith('us')) {
				recentUser.id = sb.usersOfConvo(convo)[0] || '' // render avatar of the first user
			}
			let user = store.matchUser(recentUser.id)

			let lastev = {}
			let cls = 'recent_users__popover'
			if (this.showMsg[recentUser.id]) {
				cls += ' recent_users__popover__show'
				lastev = this.showMsg[recentUser.id].ev
			}

			return (
				<div class='recent_users__user' key={recentUser.id} vOn:click={(_) => this.onUserClick(recentUser)}>
					<Avatar user={user} class='recent_users__user_avatar' notooltip size='sm' />
					<div class={cls}>
						<div class='recent_users__popover__content'>
							<div class='recent_users__popover__arrow'></div>
							<div class='recent_users__popover__arrow_cover'></div>
							<div class='recent_users__popover__content_content'>
								<div class='recent_users__user_name'>
									{this.renderOnline(user)}
									{sb.getUserDisplayName(user)}
								</div>
								<OnelineMsg ev={lastev} class='convos__subject' placeholder={lo.get(convo, 'subject')} />
							</div>
						</div>
					</div>
				</div>
			)
		},

		renderInternalConvo(item) {
			let convo = store.matchConvo(item.convoid)
			if (!convo) return null

			let cls = 'recent_users__popover'
			if (this.showMsg[item.convoid] && item.convoid != this.cid) {
				cls += ' recent_users__popover__show'
			}

			let agids = lo.filter(convo.members, (m) => m.id != store.me().id).map((m) => m.id)
			let username = ''
			if (agids.length == 0) username = this.$t('me')
			if (agids.length > 0) username = sb.getAgentDisplayName(store.matchAgent()[agids[0]])
			let $username = <span class='text-truncate'>{convo.subject || username}</span>
			if (item.convoid.startsWith('csinternalcs')) {
				let refconvoid = item.convoid.substr(10)
				let refconvo = store.matchConvo(refconvoid) || {id: refconvoid}
				let userid = sb.usersOfConvo(refconvo)[0] || '' // render avatar of the first user
				username = sb.getUserDisplayName(store.matchUser(userid))
				$username = <span style='background: #EEE; border-radius: 3px; color: #EEE'>LoadingLoading</span>
				if (username)
					$username = (
						<span class='text-truncate'>
							{refconvo.subject || username} <Icon name='link' size='16' class='ml-2 text__muted' />
						</span>
					)
			}

			if (this.convoid === 'csinternalall') {
				$username = <span class='text-truncate'>{this.$t('general')}</span>
			}

			let unread = !store.isConvoRead(item.convoid)

			// dont unread for the current open convo
			if (this.cid === item.convoid) unread = false
			return (
				<div class='recent_users__user' key={item.convoid} vOn:click={(_) => this.onInternalChatClick(item.convoid)}>
					<ChatAvatar sm id={item.convoid} unread={unread} style='cursor: pointer' />
					<div class={cls}>
						<div class='recent_users__popover__content'>
							<div class='recent_users__popover__arrow'></div>
							<div class='recent_users__popover__arrow_cover'></div>
							<div class='recent_users__popover__content_content'>
								<div class='recent_users__user_name' style='font-weigth: normal'>
									<div class='internal_convos__name'>{$username}</div>
								</div>
								<div>
									<OnelineMsg
										ev={convo.last_message_sent}
										class='text__muted text__truncate'
										placeholder={lo.get(convo, 'subject')}
										hasAuthor
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},

		renderHomeIcon() {
			return (
				<svg
					aria-hidden='true'
					focusable='false'
					data-prefix='fad'
					data-icon='home-lg-alt'
					role='img'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 576 512'
					style='width: 24px; height: 26px;'
					class='svg-inline--fa fa-home-lg-alt fa-w-18 fa-5x'
				>
					<g class='fa-group'>
						<path
							fill='#dbc6ff'
							d='M352 496V368a16 16 0 0 0-16-16h-96a16 16 0 0 0-16 16v128a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V311.07c1.78-1.21 3.85-1.89 5.47-3.35L288 115l218.74 192.9c1.54 1.38 3.56 2 5.26 3.2V496a16 16 0 0 1-16 16H368a16 16 0 0 1-16-16z'
							class='fa-secondary'
						></path>
						<path
							fill='#9176c1'
							d='M527.92 283.91L298.6 81.64a16 16 0 0 0-21.17 0L48.11 283.92a16 16 0 0 1-22.59-1.21L4.1 258.89a16 16 0 0 1 1.21-22.59l256-226a39.85 39.85 0 0 1 53.45 0l255.94 226a16 16 0 0 1 1.22 22.59l-21.4 23.82a16 16 0 0 1-22.6 1.2z'
							class='fa-primary'
						></path>
					</g>
				</svg>
			)
		},

		renderRecentUsers() {
			// render top 4 chat
			let topics = store.listInternalConvos()
			let items = lo.map(topics, (topic) => {
				var item = {}
				item.convoid = topic.convoid // store.matchConvo(topic.convoid)
				item.updated = sb.getMs(topic.updated)
				item.updated_min = Math.floor(sb.getMs(topic.updated) / 30000)
				return item
			})

			let totalUnread = {}
			items = lo.orderBy(items, ['updated_min', 'convoid'], ['desc', 'desc'])
			lo.map(items, (item) => {
				if (!store.isConvoRead(item.convoid)) totalUnread[item.convoid] = true
			})

			items = lo.take(items, 3)
			items.unshift({convoid: 'csinternalall', updated: Date.now(), updated_min: Math.floor(Date.now() / 30000)})
			lo.map(items, (item) => {
				if (!store.isConvoRead(item.convoid)) delete totalUnread[item.convoid]
			})
			let $convos = lo.map(items, (item) => this.renderInternalConvo(item))

			let me = store.me()
			let $agents = []
			let agents = lo.filter(store.matchAgent(), (ag) => ag.type === 'agent' && ag.state == 'active')
			agents = lo.filter(agents, (ag) => {
				let online = sb.now() - sb.getMs(lo.get(ag, 'last_seen.pinged')) < 120000
				if (!online) return false

				let chatid = 'csdirect' + me.id + '_' + ag.id
				if (me.id > ag.id) chatid = 'csdirect' + ag.id + '_' + me.id
				return !lo.find(items, (item) => item.convoid === chatid)
			})
			agents = lo.take(agents, 5)
			lo.map(agents, (ag) => {
				let chatid = 'csdirect' + me.id + '_' + ag.id
				if (me.id > ag.id) chatid = 'csdirect' + ag.id + '_' + me.id
				if (!store.isConvoRead(chatid)) delete totalUnread[chatid]
				$agents.push(
					this.renderInternalConvo({
						convoid: chatid,
						updated: Date.now(),
						updated_min: Math.floor(Date.now() / 30000),
					}),
				)
			})

			let sidebarcls = 'recent_users__icon'
			if (!this.closed) {
				sidebarcls += ' recent_users__icon__active'
			}
			let $unread = null
			if (lo.size(totalUnread) > 0) {
				$unread = (
					<div
						class='dot dot__danger'
						style='border: 3px solid white; position: absolute; right: -4px; bottom: 2px; width: 16px; height: 16px'
					></div>
				)
			}

			return (
				<div class='recent_users'>
					<div class='recent_users__container'>
						<div
							v-tooltip_left={this.$t('internal_communication')}
							class={sidebarcls}
							key={'list'}
							vOn:click={this.sidebarClick}
							style='height: 45px; padding-top: 6px; border-bottom: 1px solid #d5d5d5; margin-bottom: 2px'
						>
							<Icon name='layout-sidebar' size='18' />
						</div>
						<div
							class='recent_users__user'
							style='cursor: pointer'
							key={'all'}
							vOn:click={this.listClick}
							v-tooltip_left={this.$t('conversation_lists')}
						>
							{this.renderHomeIcon()}
							{$unread}
						</div>
						{$convos}
						{$agents}
						<div
							class='recent_users__user'
							key={'search'}
							vOn:click={(_) => this.$root.$emit('showPrivateChat', 'search', true)}
						>
							<Icon name='search' class='x-icon' size='16' />
						</div>
					</div>
				</div>
			)
		},

		countUnread() {
			let topics = store.listInternalConvos()
			let count = 0
			lo.map(topics, (topic) => {
				if (topic.convoid === this.cid) return
				if (!store.isConvoRead(topic.convoid)) count++
			})
			return count
		},

		isInInternal(id) {
			if (id === 'csinternalall') return true
			let topics = store.listInternalConvos()
			return lo.find(topics, (topic) => topic.convoid === id)
		},

		async createGroup() {
			if (this.creatingGroup) return
			this.creatingGroup = true
			this.$forceUpdate()

			let members = lo.map(this.selectedMembers, (_, id) => {
				return {id: id, type: 'agent', membership: 'viewer'}
			})
			members.push({id: store.me().id, type: 'agent', membership: 'viewer'})
			let out = await store.startConvo({members: members, state: 'active', is_private: true, subject: this.groupName})
			this.groupName = ''
			this.creatingGroup = false
			this.selectedMembers = {}

			if (out.error) return
			let convo = out.convo || {}
			this.$root.$emit('showPrivateChat', 'chat:' + convo.id)
		},

		selectChat(cid) {
			this.$root.$emit('showPrivateChat', 'chat:' + cid)
		},

		onChangeGroupName(e) {
			this.groupName = e.target.value
		},

		fixHistory() {
			for (var i = 0; i < this.history.length - 1; i++) {
				var a = this.history[i]
				var b = this.history[i + 1]
				if (compareHistoryLevel(a, b) <= 0) {
					// invalid
					let lastv = this.history[this.history.length - 1]
					this.history = [lastv]
					if (lastv != 'list') this.history = ['list', lastv]
				}
			}
		},

		onShowPrivateChat(v, clean) {
			if (v === 'close') {
				this.closed = true
				this.$router.push({query: Object.assign({}, this.$route.query, {internal_chat: undefined})})
				return
			}
			if (!v) v = this.history[this.history.length - 1]
			if (clean) this.history = []

			if (v === 'back') {
				this.history.pop()
				v = this.history[this.history.length - 1]
				// do not push back
			} else {
				if (this.history[this.history.length - 1] !== v) {
					this.history.push(v)
				}
			}

			if (!v) v = 'list'
			this.fixHistory()

			clearTimeout(this.clearCidtimeout)
			if (v == 'list' || v == 'search') {
				this.clearCidtimeout = setTimeout(() => {
					this.cid = ''
					// delay set cid to empty so the animation can finished
				}, 200)
			}

			this.closed = false
			if (!v || typeof v !== 'string') return
			let cid = v.split(':')[1]
			this.view = v
			if (cid) this.cid = cid

			this.pinned = v.split(':')[0] === 'chat' && v.split(':')[2] === 'pinned'
			this.$router.push({query: Object.assign({}, this.$route.query, {internal_chat: v})})
		},

		renderListHeader() {
			return (
				<div class='internal_chat__header'>
					<div class='ml-4 text-truncate' style='flex: 1; font-size: 18px'>
						{this.$t('internal_communication')}
					</div>
					<a href='#' class='ml-2' vOn:click={(_) => this.$root.$emit('showPrivateChat', 'search')}>
						<Icon name='search' size='18' class='x-icon' />
					</a>

					<a href='#' class='ml-4 mr-4' vOn:click={(_) => this.$root.$emit('showPrivateChat', 'add_group')}>
						<Icon name='folder-plus' size='18' stroke-width={2} class='x-icon' />
					</a>
				</div>
			)
		},

		onBack() {
			this.$root.$emit('showPrivateChat', 'back')
		},

		renderBackBtn() {
			let unread = this.countUnread()
			let $unread = null
			if (unread > 10) unread = '9+'
			if (unread)
				$unread = (
					<div style='position: absolute; right: -17px; top: -6px' class='badge badge__danger'>
						{unread}
					</div>
				)
			return (
				<a href='#' class='mr-4 ml-4' vOn:click={this.onBack} style='position: relative;'>
					{$unread}
					<Icon name='arrow-left' size='18' class='x-icon' />
				</a>
			)
		},

		renderChatHeader() {
			let convo = store.matchConvo(this.cid)
			if (!convo) {
				// loading
				return (
					<div class='internal_chat__header'>
						{this.renderBackBtn()}
						<Spinner size='20' mode='blue' />
						<div style='flex: 1'></div>
					</div>
				)
			}

			let agids = lo.filter(convo.members, (m) => m.id != store.me().id).map((m) => m.id)

			let username = ''
			if (agids.length == 0) username = this.$t('me')
			if (agids.length > 0) username = sb.getAgentDisplayName(store.matchAgent()[agids[0]])
			let $username = (
				<span class='text-truncate' style='flex: 1; font-size: 18px'>
					{convo.subject || username}
				</span>
			)

			if (convo.id.startsWith('csinternalcs')) {
				let refconvoid = convo.id.substr(10)
				let refconvo = store.matchConvo(refconvoid) || {id: refconvoid}
				let userid = sb.usersOfConvo(refconvo)[0] || '' // render avatar of the first user
				username = sb.getUserDisplayName(store.matchUser(userid))
				$username = <span style='background: #EEE; border-radius: 3px; color: #EEE'>LoadingLoading</span>

				let $help = (
					<div
						vOn:click={(_) => this.$root.$emit('showQuickview', {convo_id: refconvoid, user_id: userid})}
						class='link link__primary d-flex align-items-center'
					>
						<Icon name='external-link' size='14' class='mr-1' />
						<small class='text-truncate'>{this.$t('original_conversation')}</small>
					</div>
				)

				// check if agent is seeing the conversation
				let curview = this.$route.name === 'activities' && this.$route.query.cid === refconvoid
				if (this.isShowQuickview) curview = this.quickviewUcid.indexOf('%' + refconvoid) != -1
				if (curview) {
					$help = (
						<div class='d-flex align-items-center' style='overflow: hidden'>
							<div class='dot dot__primary mr-2' style='margin-top: 0'></div>
							<small class='text_primary text-truncate'>{this.$t('showing')}</small>
						</div>
					)
				}

				if (username)
					$username = (
						<div style='overflow: hidden; flex: 1'>
							<div class='text-truncate'>{refconvo.subject || username}</div>
							{$help}
						</div>
					)
			}

			if (convo.id === 'csinternalall') {
				$username = (
					<span class='text-truncate' style='flex: 1; font-size: 18px;'>
						{this.$t('general')}
					</span>
				)
			}

			if (this.pinned) {
				return (
					<div class='internal_chat__header'>
						{this.renderBackBtn()}
						<ChatAvatar id={convo.id} pinned sm style='margin-right: 10px' />
						<span style='font-size: 16px'>{this.$t('bookmark')}: </span> {$username}
					</div>
				)
			}

			return (
				<div class='internal_chat__header'>
					{this.renderBackBtn()}
					<ChatAvatar id={convo.id} style='margin-right: 10px' />
					{$username}
					<a
						href='#'
						class='ml-3'
						vOn:click={(_) => this.$root.$emit('showPrivateChat', 'chat:' + this.cid + ':search')}
					>
						<Icon name='search' size='18' class='x-icon' />
					</a>
					<a
						href='#'
						class='ml-3 mr-4'
						vOn:click={(_) => this.$root.$emit('showPrivateChat', 'chat:' + this.cid + ':detail')}
					>
						<Icon name='dots-vertical' size='18' class='x-icon' />
					</a>
				</div>
			)
		},

		renderDetailHeader() {
			let convo = store.matchConvo(this.cid)
			if (!convo) return null
			let agids = lo.filter(convo.members, (m) => m.id != store.me().id).map((m) => m.id)

			let username = ''
			if (agids.length == 0) username = this.$t('me')
			if (agids.length > 0) username = sb.getAgentDisplayName(store.matchAgent()[agids[0]])
			let $username = <span class='text-truncate'>{convo.subject || username}</span>

			if (convo.id.startsWith('csinternalcs')) {
				let refconvoid = convo.id.substr(10)
				let refconvo = store.matchConvo(refconvoid) || {id: refconvoid}
				let userid = sb.usersOfConvo(refconvo)[0] || '' // render avatar of the first user
				username = sb.getUserDisplayName(store.matchUser(userid))
				$username = <span style='background: #EEE; border-radius: 3px; color: #EEE'>LoadingLoading</span>

				let $help = (
					<div
						vOn:click={(_) => this.$root.$emit('showQuickview', {convo_id: refconvoid, user_id: userid})}
						class='link link__primary d-flex align-items-center'
					>
						<small>{this.$t('original_conversation')}</small>
						<Icon name='external-link' size='14' class='ml-1' />
					</div>
				)

				// check if agent is seeing the conversation
				let curview = this.$route.name === 'activities' && this.$route.query.cid === refconvoid
				if (this.isShowQuickview) curview = this.quickviewUcid.indexOf('%' + refconvoid) != -1
				if (curview) {
					$help = (
						<div class='d-flex align-items-center ' style='oveflow: hidden'>
							<div class='dot dot__primary mr-2' style='margin-top: 0'></div>
							<small class='text_primary'>{this.$t('showing')}</small>
						</div>
					)
				}

				if (username)
					$username = (
						<div style='overflow: hidden'>
							<div class='text-truncate'>{refconvo.subject || username}</div>
							{$help}
						</div>
					)
			}

			if (convo.id === 'csinternalall') {
				$username = (
					<span class='text-truncate' style='font-size: 18px'>
						{this.$t('general')}
					</span>
				)
			}

			return (
				<div class='internal_chat__header'>
					<ChatAvatar id={convo.id} style='margin-left: 20px; margin-right: 10px' />
					{$username}
					<div style='flex: 1'></div>
					<a href='#' class='mr-3' vOn:click={(_) => this.$root.$emit('showPrivateChat', 'chat:' + this.cid)}>
						<Icon name='x' size='18' class='ml-4 x-icon' />
					</a>
				</div>
			)
		},

		onScroll: lo.throttle(
			function (e) {
				let list = e.target
				let distToBottom = list.scrollHeight - list.scrollTop - list.clientHeight
				if (distToBottom < 500) this.loadMore()
				// this.scrollTop = e.target.scrollTop
			},
			200,
			{trailing: true},
		),

		async loadMore() {
			if (this.outOfChat) return
			if (this.loadingMore) return
			this.loadingMore = true
			let {end} = await store.fetchMoreInternalConvos()
			this.outOfChat = !!end
			this.loadingMore = false
		},

		renderList() {
			let topics = store.listInternalConvos()
			let meid = 'csdirect' + store.me().id + '_' + store.me().id
			topics = lo.filter(topics, (t) => t.convoid !== meid)
			if (!lo.size(topics)) {
				return (
					<div class='flex-grow-1 d-flex align-items-center justify-content-center flex-column' key='list'>
						<div class='text__muted mt-4'>{this.$t('empty')}</div>
						<a
							class='link link__primary mt-2'
							href={`https://widget.subiz.com.vn/?account_id=${store.me().account_id}`}
							target='_blank'
						>
							{this.$t('test_chat')}
						</a>
					</div>
				)
			}

			let items = lo.map(topics, (topic) => {
				var item = {}
				item.convoid = topic.convoid // store.matchConvo(topic.convoid)
				item.updated = sb.getMs(topic.updated)
				item.is_read = store.isConvoRead(topic.convoid)
				item.updated_min = Math.floor(sb.getMs(topic.updated) / 30000)
				return item
			})

			let $loadmore = null
			if (this.loadingMore) {
				$loadmore = (
					<div class='d-flex align-items-center justify-content-center mt-4 mb-4'>
						<Spinner size='20' mode='blue' />
					</div>
				)
			}

			items = lo.orderBy(items, ['updated_min', 'convoid'], ['desc', 'desc'])
			let all = store.matchConvo('csinternalall')
			let updated = lo.get(all, 'last_message_sent.created')

			items.unshift({
				convoid: 'csinternalall',
				updated: updated,
				updated_min: Math.floor(updated / 30000),
				is_read: store.isConvoRead('csinternalall'),
			})

			let meconvo = store.matchConvo(meid)
			items.unshift({
				convoid: meid,
				updated: lo.get(meconvo, 'last_message_sent.created'),
				updated_min: Math.floor(updated / 30000),
				is_read: store.isConvoRead(meid),
			})

			return (
				<div class='convos__list' key='list' vOn:scroll={this.onScroll}>
					{lo.map(items, (item) => (
						<ChatItem
							convoid={item.convoid}
							is_read={item.is_read}
							vOn:selected={this.selectChat}
							updated={item.updated}
							key={item.convoid}
						/>
					))}
					{$loadmore}
				</div>
			)
		},

		selectMember(agid) {
			if (this.selectedMembers[agid]) delete this.selectedMembers[agid]
			else this.selectedMembers[agid] = true
			this.$forceUpdate()
		},

		renderAddGroup() {
			let cls = 'internal_chat__add_group'
			if (this.view !== 'add_group') cls += ' internal_chat__add_group__hidden'

			let agents = lo
				.map(store.matchAgent(), (ag) => (ag.type == 'agent' && ag.state === 'active' ? ag : null))
				.filter((ag) => ag)
				.filter((ag) => ag.id !== store.me().id)

			let $agents = lo.map(agents, (ag) => {
				return (
					<div
						style='display: flex; align-items: center; margin-bottom: 10px; cursor: pointer'
						vOn:click={(_) => this.selectMember(ag.id)}
					>
						<Avatar class='assign_agent__member_avatar' agent={ag} size='sm' />
						<div style='flex: 1;' class='ml-3 text__truncate'>
							{this.selectedMembers[ag.id] ? <b>{sb.getAgentDisplayName(ag)}</b> : sb.getAgentDisplayName(ag)}
						</div>
						<input
							type='checkbox'
							class='form-check-input form-check-input--bold'
							style='margin-top: -2px;'
							checked={this.selectedMembers[ag.id]}
						/>
					</div>
				)
			})

			let $createbtn = (
				<div class='btn btn__primary' style='width: 100px'>
					<spinner size='14' />
				</div>
			)
			if (!this.creatingGroup)
				$createbtn = (
					<div class='btn btn__primary' style='width: 100px' vOn:click={this.createGroup}>
						{this.$t('create')}
					</div>
				)

			return (
				<div class={cls} key='addgroup' vOn:click={(_) => this.$root.$emit('showPrivateChat', 'list')}>
					<div class='internal_chat__add_group__body' vOn:click_stop={(_) => true}>
						<div class='ml-4 mt-3' style='font-size: 18px; '>
							{this.$t('create_group')}
						</div>
						<div>
							<div style='padding: 0 20px;'>
								<div class='internal_chat__label' style='margin-bottom: 5px; margin-top: 10px'>
									{this.$t('name')}
								</div>
								<input class='form-control' type='text' vOn:input={this.onChangeGroupName} value={this.groupName} />
							</div>

							<div style='padding: 0 20px 0 20px'>
								<div class='internal_chat__label'>Members</div>
								<div style='max-height: 250px; overflow: auto; padding-right: 10px'>{$agents}</div>
							</div>
							<div style='display: flex; justify-content: flex-end; padding: 10px 20px'>
								<div class='btn  btn__light mr-4' vOn:click={(_) => this.$root.$emit('showPrivateChat', 'list')}>
									{this.$t('cancel')}
								</div>
								{$createbtn}
							</div>
						</div>
					</div>
				</div>
			)
		},

		onShowPinned() {
			this.$root.$emit('showPrivateChat', 'chat:' + this.cid + ':pinned')
		},

		renderChat() {
			let cls = 'internal_chat__chat'
			let viewsplit = this.view.split(':')
			if (viewsplit[0] !== 'chat') cls += ' internal_chat__chat__hidden'
			let cid = viewsplit[1]

			let convo = store.matchConvo(cid) || {id: cid}
			return (
				<div class={cls} key='chat' style='position: absolute'>
					{this.renderChatHeader()}
					<Chat showing={viewsplit[0] === 'chat'} cid={cid} vOn:showPinned={this.onShowPinned} pinned={this.pinned} />
				</div>
			)
		},

		renderChatSearch() {
			let cls = 'internal_chat__chat'
			let viewsplit = this.view.split(':')
			let issearch = viewsplit[0] != 'chat' || viewsplit[2] != 'search'
			if (issearch) cls += ' internal_chat__chat__hidden'
			let cid = viewsplit[1]
			return <Search key='chat-search' showing={issearch} class={cls} cid={cid} />
		},

		renderChatDetail() {
			let cls = 'internal_chat__chat'
			let viewsplit = this.view.split(':')
			if (viewsplit[0] != 'chat' || viewsplit[2] != 'detail') cls += ' internal_chat__chat__hidden'
			let cid = viewsplit[1]
			let convo = store.matchConvo(cid) || {id: cid}
			return (
				<div class={cls} key='chat-detail' style='position: absolute'>
					{this.renderDetailHeader()}
					<ChatDetail cid={cid} />
				</div>
			)
		},

		onSearchQueryChange(query) {
			this.searchQuery = query
		},

		renderSearch() {
			let hasSearch = lo.find(this.history, (v) => {
				let viewsplit = v.split(':')
				return viewsplit[0] === 'search'
			})
			let cls = 'internal_chat__chat'
			if (!hasSearch) cls += ' internal_chat__chat__hidden'

			return <Search key='search' showing={hasSearch} class={cls} />
		},

		onUserClick(recentUser) {
			if (sb.getSelectionText()) return
			this.$root.$emit('showQuickview', {convo_id: recentUser.conversation_id, user_id: recentUser.id})
		},

		renderOnline(user) {
			if (!sb.isUserOnline(user)) return null
			return <div class='online-dot ml-2'></div>
		},

		scrollBottom() {
			let $list = this.$refs.msg_container
			if (!$list) return
			setTimeout(() => {
				$list.scrollTop = $list.scrollHeight
			}, 100)
		},
	},

	render() {
		return (
			<div class='d-flex' style='flex-shrink: 0; width: 45px'>
				<div style='position: relative'>
					<div class='recent_users' style='width: 45px' />
					<HelpButton />
				</div>
			</div>
		)
		let cls = 'internal_chat'
		if (this.closed) cls += ' internal_chat__hidden'
		return (
			<div class='d-flex' style='flex-shrink: 0; width: 50px'>
				<div style='position: relative'>
					{this.renderRecentUsers()}
					<HelpButton />
				</div>
				<div class={cls}>
					{this.renderListHeader()}
					{this.renderList()}
					{this.renderSearch()}
					{this.renderChat()}
					{this.renderChatDetail()}
					{this.renderChatSearch()}
					{this.renderAddGroup()}
				</div>
			</div>
		)
	},
}

let HelpButton = {
	name: 'help-button',

	data() {
		return {
			unread: 0,
			isHelpAvailable: false,
			previewEv: null,
			closedPreview: false,
			previewClass: 'help_wave',
		}
	},

	mounted() {
		this.wginterval = setInterval(async () => {
			if (!this.widget) return
			let unread = await this.widget.countUnreadMessages()
			if (this.unread != unread) this.unread = unread
		}, 2000)

		this.loadWidget()
		store.onAccount(this, () => this.$forceUpdate())
	},

	beforeDestroy() {
		this.widget && this.widget.logout()
		this.widget && this.widget.unEvent(this.onWidgetEvent)
		clearInterval(this.wginterval)
	},

	methods: {
		async loadWidget() {
			if (this.widget) return
			let Widget = (await LoadWidget()).default
			let widget = new Widget(config.ApiURL, config.FileUrl, config.RealtimeURL, true, false)
			widget.setTrackPageView(false)

			window._widget = widget
			let accid = store.me().account_id
			let {body, error} = await store.fetchWidget()
			if (!body || error) {
			}

			store.setSubizAgent(body.agents)

			let chatbox = lo.find(body.plugins, (plugin) => plugin.id == 'wpwidget' || plugin.type == 'chatbox')
			if (!chatbox) return

			chatbox.account_id = 'acpxkgumifuoofoosble'
			lo.set(chatbox, 'chatbox.desktop_button.hidden', true)
			lo.set(chatbox, 'chatbox.height', 500)
			lo.set(chatbox, 'chatbox.width', 350)
			lo.set(chatbox, 'chatbox.enabled', true)
			lo.set(chatbox, 'chatbox.desktop_button.position', 'right')
			lo.set(chatbox, 'chatbox.desktop_button.margin_x', 0)
			lo.set(chatbox, 'chatbox.desktop_button.margin_y', 90)
			lo.set(chatbox, 'triggers', undefined)
			lo.set(chatbox, 'initiative_frequency', undefined)
			lo.set(chatbox, 'conditions', undefined)
			lo.set(chatbox, '_no_auto_expand', true)
			widget._overridePlugin([chatbox])

			// this.isHelpAvailable = lo.get(chatbox, 'chatbox.is_online') ||
			this.isHelpAvailable = lo.get(body, 'is_in_business_hour')
			await widget.login(
				'acpxkgumifuoofoosble',
				store.me().id,
				async () => {
					let out = await store.createUserAccessToken()
					return out.body
				},
				this.$refs.widget,
			)

			widget.onEvent(this.onWidgetEvent)
			setTimeout(() => {
				this.widget = widget
				let convos = lo.map(widget.store.matchConvo('*'))
				// if (lo.size(convos) > 0) this.closeHelpWave()
				let convo = lo.maxBy(convos, function (convo) {
					return lo.get(convo, 'last_message_sent.created', convo.created)
				})
				if (convo) widget.store.setLastview('chat', {cid: convo.id})

				// show preview if has the last convo
				let redconvo = lo.find(convos, (convo) => !isConvoSeen(convo, widget.store.matchUser().id))
				if (redconvo && redconvo.last_message_sent) {
					this.showWidget('chat', redconvo.id)
				}
				this.$forceUpdate()
			}, 3000)
		},

		shouldRenderPreview() {
			if (!this.previewEv) return false
			if (this.closedPreview) return false
			if (Date.now() - (parseInt(store.matchCloseHelpWave()) || 0) < 5 * 60 * 1000) return false
			return true
		},

		renderPreview() {
			if (!this.shouldRenderPreview()) return null

			let ag = store.matchSubizAgent(lo.get(this.previewEv, 'by.id')) || {}
			let name = ag.fullname || 'Subiz'

			let convoid = sb.convoOfEv(this.previewEv)
			return (
				<div class={this.previewClass} vOn:click={(_) => this.showWidget(undefined, convoid)}>
					<div class='d-flex' style='align-items: flex-start; overflow: hidden;'>
						<div style='margin-left: 10px; overflow: hidden; flex: 1'>
							<div class='text__semibold' style='font-size: 14px'>
								{name}
							</div>
							<OnelineMsg class='text-truncate' ev={this.previewEv} />
						</div>
						<Icon
							name='x'
							stroke-width='2'
							v-tooltip={this.$t('close')}
							class='btn__close btn__close_light'
							vOn:click_stop={this.manuallyCloseHelpWave}
							style='flex-shrink: 0'
						/>
					</div>
				</div>
			)
		},

		onWidgetEvent(ev) {
			if (ev.type !== 'message_sent') return
			if (ev.by.type === 'user') return

			// show on bot message that start conversation
			let convoid = lo.get(ev, 'data.message.conversation_id')
			if (!this.widget) return
			if (lo.get(this.widget, 'store.matchConvo')) {
				let convo = this.widget.store.matchConvo(convoid)
				if (ev.created - convo.created < 3000) {
					// new convo
					this.showWidget('chat', convoid)
					this.setPreviewEvent()
					return
				}
			}
			this.setPreviewEvent(ev)
		},

		manuallyCloseHelpWave() {
			// close for 5 min
			this.closedPreview = true
			store.closeHelpWave(Date.now())
			this.$forceUpdate()
		},

		showWidget(_, convoid) {
			this.closedPreview = true
			store.closeHelpWave(0) // reset close
			this.$forceUpdate()

			if (!this.widget) return
			if (!convoid) {
				this.widget.showWidget()
			} else {
				this.widget.showWidget('chat', convoid)
			}
		},

		async setPreviewEvent(ev) {
			this._setPreviewIndex = this._setPreviewIndex || 0
			let _setPreviewIndex = this._setPreviewIndex
			this.previewClass = 'help_wave help_wave__hidden'
			this.closedPreview = false

			await sb.sleep(200)
			let cv = await this.widget.getWidgetCurrentView()
			if (_setPreviewIndex != this._setPreviewIndex) return // outdated
			let isWidgetOpen = lo.get(cv, 'is_show_widget', false)
			if (isWidgetOpen) this.previewEv = null
			else this.previewEv = ev

			if (_setPreviewIndex != this._setPreviewIndex) return // outdated
			this.previewClass = 'help_wave'
		},

		onSelectChatOption(item) {
			if (item.id === 'chat') return this.showWidget()
			if (item.id === 'call') return this.$root.$emit('show_call_modal', {call_to: '02473021368'})
		},
	},

	render() {
		if (!this.widget) {
			return (
				<div style='opacity: 0.5;' key='bg'>
					<div class='global_help_button' v-tooltip={this.$t('chat_with_subiz')}>
						{this.$t('loading')}...
					</div>
				</div>
			)
		}

		// let $icon = <Emoji emojiCode='wave' md class="ml-2" style='transform: rotate(90deg)' />
		// let $icon = <Icon name='message' stroke-width='2' size='18' class='ml-2' />
		let $icon = (
			<div style='width: 18px; height: 18px;' class='ml-2 d-flex align-items-center justify-content-center'>
				<div style='width: 11px; height: 11px; background: #00000030; border-radius: 50%'></div>
			</div>
		)
		if (this.isHelpAvailable) {
			$icon = (
				<div style='width: 18px; height: 18px;' class='ml-2 d-flex align-items-center justify-content-center'>
					<div style='width: 11px; height: 11px; background: #19b600; border-radius: 50%'></div>
				</div>
			)
		}

		if (this.shouldRenderPreview()) {
			let ag = store.matchSubizAgent(lo.get(this.previewEv, 'by.id')) || {}
			$icon = <Avatar agent={ag} nodot size='18' class='ml-2' style='transform: rotate(90deg);' />
		} else {
			if (this.unread > 0)
				$icon = (
					<div
						style='background: #EA3D2F; width: 18px; height: 18px; border-radius: 50%; padding: 2px; overflow: hidden; color: white; font-size: 11px;  transform: rotate(90deg); font-weight: 700'
						class='ml-2 d-flex align-items-center justify-content-center'
					>
						{this.unread > 9 ? '*' : this.unread}
					</div>
				)
		}
		return (
			<div key='bg' style='position: relative;'>
				<div
					class='global_help_button global_help_button__enabled'
					vOn:click={this.showWidget}
					v-tooltip={{content: this.$t('chat_with_subiz'), placement: 'left'}}
				>
					{this.$t('support_247')} {$icon}
				</div>
				{this.renderPreview()}
			</div>
		)
	},
}

function compareHistoryLevel(a, b) {
	if (!a) a = ''
	if (!b) b = ''
	let weight = {
		'': 0,
		list: -1,
		search: -2,
		chat: -3,
	}
	let va = a.split(':')[0]
	let vb = b.split(':')[0]
	if (weight[va] < weight[vb]) return -1
	if (weight[va] === weight[vb]) {
		if (a.split(':').length < b.split(':').length) return +1
		if (a.split(':').length > b.split(':').length) return 0
		return -1
	}
	return +1
}

function isConvoSeen(convo, memberid) {
	if (convo.state === 'ended') return true
	let lastmsgsent = convo.last_message_sent
	if (!lastmsgsent) return true
	if (lo.get(lastmsgsent, 'by.id') === memberid) return true
	let mem = lo.find(convo.members, (m) => m.id === memberid)
	if (!mem) return true
	return sb.getMs(lastmsgsent.created) < sb.getMs(mem.seen_at)
}
