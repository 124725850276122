const sb = require('@sb/util')
var api = require('./api.js')
const flow = require('@subiz/flow')
const config = require('@sb/config')
var Realtime = require('@subiz/wsclient/realtime.js')
import lo from 'lodash'

import SubizLog from '../log/main.js'
import NewAccountStore from './account_store.js'
import NewConvoStore from './convo_store.js'
import NewLiveStore from './live_store.js'
// import NewRecentStore from './recent_store.js'
import NewOrderStore from './order_store.js'
import NewTaskStore from './task_store.js'
import NewCallEntryStore from './outbound_call_entry_store.js'

let immemlocalStorage = require('./inmem_localstorage.js')
let localStorage = window.localStorage
try {
	window.localStorage.setItem('%', '%')
	window.localStorage.removeItem('%')
} catch (e) {
	localStorage = immemlocalStorage
	window.localStorage = localStorage
}

function Store() {
	let dead = false
	let pubsub = new sb.Pubsub()
	this.pubsub = pubsub
	let alreadyInit = false
	let realtime
	this.me = () => ({})
	this.init = async () => {
		if (alreadyInit) return
		// registering service worker
		let me = api.getCred() || {}
		if (!me.account_id) return // not login
		console.log('INIT STORE')
		console.time('init store')

		// make sure db_version in localstorage is alway correct
		if (!window.localStorage.getItem('db_version')) window.localStorage.setItem('db_version', config.db_version)
		if (window.localStorage.getItem('db_version') !== config.db_version) window.localStorage.clear()
		window.localStorage.setItem('db_version', config.db_version)
		dead = false
		realtime = new Realtime(config.RealtimeURL, {getAccessToken: api.makeAccessToken})
		// realtime = {onEvent: ()=> {}, stop: () => {}, subscribe: () => {}, onInterrupted: () => {}}

		let rtsdb = {}
		realtime.onEvent((ev) => {
			rtsdb[ev.type] = rtsdb[ev.type] || 0
			rtsdb[ev.type]++
			this.pubsub.publish('rt', ev)
		})
		this.showRealtimeStatistic = () => rtsdb

		this.convoStore = NewConvoStore(realtime, this.pubsub)
		this.accStore = NewAccountStore(realtime, this.pubsub, this)
		this.liveStore = NewLiveStore(this.convoStore, realtime, this.pubsub)
		// this.recentStore = NewRecentStore(this.convoStore, realtime, pubsub)
		this.orderStore = NewOrderStore(realtime, this.pubsub)
		this.taskStore = NewTaskStore(realtime, this.pubsub)
		this.callEntryStore = NewCallEntryStore(realtime, this.pubsub)

		// run log
		const DEBUG_EXPIRED = 2 * 24 * 3600_000 // 2 days
		let sbz_log = SubizLog({account_id: me.account_id, agent_id: me.agent_id})
		let debugCollected = lo.get(me, 'dashboard_setting.debug_log_collected', 0)
		if (debugCollected && Date.now() - debugCollected < DEBUG_EXPIRED) {
			sbz_log.startLog()
		}
		this.accStore.onAccount2((data) => {
			if (lo.get(data, 'object_type') === 'agent') {
				let agent = lo.get(data, 'data') || {}
				//consoleNew.log('agentttt', agent)
				let debugCollected = lo.get(agent, 'dashboard_setting.debug_log_collected', 0)
				if (debugCollected && Date.now() - debugCollected < DEBUG_EXPIRED) {
					sbz_log.startLog()
				} else {
					sbz_log.stopLog()
				}
			}
		})

		Object.assign(this, this.convoStore)
		Object.assign(this, this.accStore)
		Object.assign(this, this.liveStore)
		// Object.assign(this, this.recentStore)
		Object.assign(this, this.orderStore)
		Object.assign(this, this.taskStore)
		Object.assign(this, this.callEntryStore)

		this.accStore.fetchIntegrations()
		this.convoStore.fetchRecentCalls()
		this.accStore.fetchSubscription()
		this.accStore.fetchCredit()
		setTimeout(() => this.accStore.fetchTags(), 5000)
		this.accStore.fetchUserAttributes()
		this.accStore.fetchUserLabels()
		setTimeout(() => this.accStore.fetchFacebookSetting(), 5000)
		setTimeout(() => this.accStore.fetchGoogleSetting(), 5000)
		setTimeout(() => this.accStore.fetchWidgetSetting(), 6000)

		alreadyInit = true
		// should list agent right away
		if (lo.size(this.accStore.matchAgent()) > 0) {
			this.accStore.fetchAgents()
		} else {
			await this.accStore.fetchAgents()
		}
		console.timeEnd('init store')
	}

	let destroy = async () => {
		if (dead) return
		dead = true

		// clear database
		window.localStorage.clear()

		// this.recentStore && this.recentStore.destroy()
		this.liveStore && this.liveStore.destroy()
		this.accStore && this.accStore.destroy()
		this.orderStore && this.orderStore.destroy()
		this.convoStore && this.convoStore.destroy()
		realtime && realtime.stop()
	}

	this.getCred = api.getCred
	this.login = async (us, pw) => {
		// new store
		await destroy()
		let {cred, error} = await api.login(us, pw)
		if (error) return {error}

		await this.init()
		return {cred}
	}

	api.onLogout = () => this.logout()

	// only to be called by main layout
	this.logout = async () => {
		this.pubsub.publish('logout')
		await api.logout()
		await destroy()
	}
	;(async () => {
		while (true) {
			try {
				if (!dead && this.fetchPresences) await this.fetchPresences()
			} catch (e) {}
			await flow.sleep(120000)
		}
	})()

	// sub
	this.now = (_) => sb.now()
	this.onNumberInfo = (o, cb) => this.pubsub.on2(o, 'number_info', cb)
	this.onLogout = (o, cb) => this.pubsub.on2(o, 'logout', cb)
	this.onRealtime = (o, cb) => this.pubsub.on2(o, 'rt', cb)
	this.un = (o) => this.pubsub.un2(o)

	this.importBusinessHours = api.importBusinessHours
	this.register = api.register
	this.makeAccessToken = api.makeAccessToken
	this.confirmation = api.confirmation
	this.fetchWidget = api.fetchWidget
	this.updateReferals = api.updateReferals
	this.listReferals = api.listReferals
	this.paidLogs = api.paidLogs
	this.fetchReferreds = api.fetchReferreds
	this.fetchBills = api.fetchBills

	this.fetchUsedCategory = () => api.list_product_categories()

	this.inviteAgent = api.inviteAgent

	this.browserVisibility = 'visible'
	onVisibilityChange((val) => {
		this.pubsub.publish('document_focus', val)
		this.browserVisibility = val
	})

	this.onDocumentFocus = (o, cb) => this.pubsub.on2(o, 'document_focus', cb)

	this.addGroup = api.addGroup

	this.getUrlUpload = api.getUrlUpload

	this.resetPassword = api.resetPassword
	this.updatePasswordViaToken = api.updatePasswordViaToken
	this.updatePassword = api.updatePassword
	this.forgotPassword = api.forgotPassword
	this.updateAgentInvitation = api.updateAgentInvitation

	this.getTagReport = api.getTagReport
	this.reportConvoReplied = api.reportConvoReplied
	this.reportAvailability = api.reportAvailability

	this.searchLocations = api.searchLocations

	this.exportInvoice = api.exportInvoice
	this.exportInvoiceHtml = api.exportInvoiceHtml
	this.calculateInvoice = api.calculateInvoice
	this.upgradeSubscription = api.upgrade_subscription
	this.upgradeSub = (accid, sub) => api.upgrade_sub(accid, sub)
	this.updateSub = (sub) => api.update_sub(sub)
	this.paidInvoice = (invoice) => api.subscriptionPay(invoice)
	this.downgradeSubscription = api.update_subscription
	this.searchLeads = api.searchLeads
	this.exportLeads = api.exportLeads
	this.listBotConversations = api.listBotConversations
	this.testSendHttp = api.test_bot_action
	this.listFacebookPosts = api.list_facebook_posts
	this.listCountryCodes = api.listCountryCodes
	this.onDocument = (vue, ev, handler) => {
		document.body.addEventListener(ev, handler)
		vue.$once('hook:beforeDestroy', (_) => document.body.removeEventListener(ev, handler))
	}
	this.getUserReport = api.getUserReport
	this.getConvoReport = api.getConvoReport
	this.getConvoIds = api.getConvoIds
	this.getAgentReport = api.getAgentReport
	this.getListTouchpoints = api.getListTouchpoints
	this.getOrderReport = api.get_order_report
	this.getCallReport = api.getCallReport
	this.getCallIds = api.getCallIds
	this.getReport = api.getReport

	let callInfoM = {}
	this.matchNumberInfo = (number) => {
		if (!number) return {info: {}}
		let number_format = ''
		for (let i = 0; i < number.length; i++) {
			if (number[i] >= '0' && number[i] <= '9') number_format += number[i]
		}
		if (callInfoM[number_format]) return callInfoM[number_format]
		callInfoM[number_format] = {state: 'loading', info: {}}
		setTimeout(async () => {
			let out = await this.fetchCallInfo(number_format)
			callInfoM[number_format] = {state: 'ready', info: out}
			this.pubsub.publish('number_info', {number: number_format, state: 'ready', info: out})
		})
		return callInfoM[number_format]
	}

	this.collect = api.collect

	this.error = (message, line) => {
		// 24-01-11 04:07:05 app ERROR api-8948967b6-qtpfx subiz /app/vendor/github.com/subiz/log/error.go:271 {"id":141534190473829746,"class":500,"code":"locked_account,internal","number":"8f2b97e9","fields":{"ip":"\"171.224.84.93\"","no_report":"true","user_agent":"\"Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Mobile Safari/537.36\""}}
		let ts = displayUTCLogTime(new Date())
		let accid = this.me().account_id
		let msg = JSON.stringify({
			id: Date.now(),
			class: 400,
			code: 'frontend_error',
			number: line
				.split('')
				.map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
				.join(''),
			fields: {
				message: JSON.stringify(message),
				agent_id: JSON.stringify(this.me().id),
				url: JSON.stringify(location.href),
			},
		})

		api.collectLog(`${ts} app ERROR dashboard ${accid} ${line} ${msg}`)
	}

	// see account_store.lookupCallUser
	this.fetchCallInfo = async (number) => {
		number = number || ''
		if (number.startsWith('ph') || number.startsWith('piph')) {
			let us = number.split('.')
			if (us[1] == this.me().account_id) {
				// device
				if (us[0].startsWith('piph')) us[0] = us[0].substr(2)
				let dev = this.matchPhoneDevice()[us[0]]
				if (!dev) {
					await this.fetchPhoneDevice(true)
					dev = this.matchPhoneDevice()[us[0]]
				}
				if (!dev) return {type: 'agent', fullname: '', number, avatar_url: ''}

				let agid = lo.get(dev, 'bind_to_agents.0')
				let ag = this.matchAgent()[agid]
				if (!ag) return {type: 'agent', fullname: '', number, avatar_url: ''}
				return {
					type: 'agent',
					id: agid,
					fullname: sb.getAgentDisplayName(ag),
					number: ag.extension,
					avatar_url: ag.avatar_url,
				}
			}
		}

		let ags = this.matchAgent()
		if (number.startsWith('ag')) {
			let found = lo.find(ags, (ag) => ag.id == number)
			if (found)
				return {
					fullname: found.fullname,
					is_internal: true,
					avatar_url: found.avatar_url,
					type: 'agent',
					id: found.id,
					number: found.extension || number,
				}

			// other account agent
			let {body: ag, error} = api.get_agent(number)
			if (!ag || error) return {number, fullname: '', avatar_url: '', type: 'user'}

			await this.fetchUser('us' + ag.account_id + '_' + ag.id)
			let user = this.matchUser('us' + ag.account_id + '_' + ag.id)
			let fullname = sb.getUserDisplayName(user)
			let avatar_url = sb.getUserTextAttr(user, 'avatar_url')
			avatar_url = sb.replaceFileUrl(avatar_url)
			return {number, id: user.id, fullname, avatar_url, type: 'user'}
		}

		// our extension
		let found = lo.find(ags, (ag) => ag.state == 'active' && ag.extension == number)
		if (found)
			return {
				fullname: found.fullname,
				is_internal: true,
				avatar_url: found.avatar_url,
				type: 'agent',
				id: found.id,
				number: found.extension || number,
			}

		let phones = this.matchPhoneDevice()
		found = lo.find(phones, (phone) => phone.id == number)
		if (found) {
			let bounds = found.bind_to_agents || []
			let ag = lo.find(ags, (ag) => ag.state == 'active' && bounds.indexOf(ag.id) > -1)
			if (ag)
				return {
					fullname: ag.fullname,
					is_internal: true,
					avatar_url: ag.avatar_url,
					type: 'agent',
					id: ag.id,
					number: ag.extension || number,
				}
			return {number, fullname: '', is_internal: true, avatar_url: '', type: 'agent'}
		}

		if ((number || '').startsWith('0')) number = '84' + number.substr(1)
		let user = await this.fetchUserByProfile('call', 'call', number)
		if (user.error) return {number, fullname: '', avatar_url: '', type: 'user'}
		let fullname = sb.getUserDisplayName(user)
		let avatar_url = sb.getUserTextAttr(user, 'avatar_url')
		avatar_url = sb.replaceFileUrl(avatar_url)
		return {number, id: user.id, fullname, avatar_url, type: 'user'}
	}
}

function onVisibilityChange(cb) {
	if (!window.addEventListener) return // mobile

	var hidden = 'hidden'

	window.addEventListener('focus', onchange)
	window.addEventListener('blur', onchange)

	// Standards:
	if (hidden in document) document.addEventListener('visibilitychange', onchange)
	else if ((hidden = 'mozHidden') in document) document.addEventListener('mozvisibilitychange', onchange)
	else if ((hidden = 'webkitHidden') in document) document.addEventListener('webkitvisibilitychange', onchange)
	else if ((hidden = 'msHidden') in document) document.addEventListener('msvisibilitychange', onchange)
	// IE 9 and lower:
	else if ('onfocusin' in document) document.onfocusin = document.onfocusout = onchange
	// All others:
	else window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange

	function onchange(evt) {
		var v = 'visible'
		var h = 'hidden'
		var evtMap = {focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h}

		evt = evt || window.event
		if (evt.type in evtMap) cb(evtMap[evt.type])
		else cb(this[hidden] ? 'hidden' : 'visible')
	}

	// set the initial state (but only if browser supports the Page Visibility API)
	if (document[hidden] !== undefined) onchange({type: document[hidden] ? 'blur' : 'focus'})
}

let store = new Store()
window.store = store
window.flow = flow
window.lo = lo

function displayUTCLogTime(date) {
	let yyyy = date.getUTCFullYear().toString()
	let yy = yyyy.substr(2, 2)
	let mm = date.getUTCMonth() + 1
	let dd = date.getUTCDate()
	let hh = date.getUTCHours()
	let MM = date.getUTCMinutes()
	let ss = date.getUTCSeconds()

	return `${yy}-${pad(mm)}-${pad(dd)} ${pad(hh)}:${pad(MM)}:${pad(ss)}`
}

function pad(v) {
	if (v < 10) {
		return `0${v}`
	}

	return `${v}`
}

export default store
