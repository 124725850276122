const lo = require('lodash')
const sb = require('@sb/util')

export default {
	name: 'file-attachments',
	props: ['ev'],

	methods: {
		downloadFile(file) {
			sb.downloadURI(sb.replaceFileUrl(file.url), file.name)
		},
	},

	render() {
		var byType = lo.get(this.ev, 'by.type', 'agent')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var files = []
		lo.each(attachments, (att) => {
			if (!att || !att.url) return
			if (att.type !== 'file') return
			if (
				!lo.includes(att.mimetype, 'video') &&
				!lo.includes(att.mimetype, 'audio') &&
				!lo.includes(att.mimetype, 'image')
			) {
				files.push(att)
			}
		})

		if (files.length === 0) return null

		var $files = lo.map(files, (file, index) => {
			return (
				<div key={index} class={`message_file message_file__${byType}`}>
					<div class='message_file__link' vOn:click={(_) => this.downloadFile(file)} target='_blank'>
						<Icon name='file' stroke-width='2' class='message_file__download' size='24' />
						<div style='overflow:hidden'>
							<div class='message_file__filename' v-tooltip={file.name}>
								{file.name}
							</div>
							<div class='message_file__size'>{sb.humanFileSize(file.size)}</div>
						</div>
					</div>
				</div>
			)
		})

		return (
			<div class={`message_container message_container__${byType}`}>
				<div class='d-flex flex-column' style='max-width: 100%'>
					{$files}
				</div>
			</div>
		)
	},
}
