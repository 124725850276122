import store from '@sb/store'
import sb from '@sb/util'

export default {
	name: 'user-display-name',
	props: ['user'],

	mounted() {
		store.onUser(this, (userM) => {
			if (userM[lo.get(this.user, 'id')]) {
				this.$forceUpdate()
			}
		})
	},

	render() {
		let user = this.user
		let userid = ''
		if (user && user.id) {
			let realuser = store.matchUser(user.id, true) || user
			userid = realuser.primary_id || realuser.id
			user = store.matchUser(userid) || user // should display name of primaryuser when channel !== call and  !== email
			let isProfile = lo.get(realuser, 'channel') !== 'call' && lo.get(realuser, 'channel') !== 'email'
			if (isProfile) {
				userid = realuser.id
				user = realuser
			}
		}

		return <span title={sb.getUserDisplayName(user)}>{sb.getUserDisplayName(user)}</span>
	},
}
